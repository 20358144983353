import LinkDevice from './LinkDevice';
import SwitchInfo from '../AssetInfo/SwitchInfo';

class Switch extends LinkDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'Switch';
    this.icon = 'switch';
    this.displayName = this.class;
  }

  get isBalanced() {
    return Object.keys(this._switchPhases).length === 0;
  }

  get closed() {
    if (this.isBalanced) {
      return this.closedBalanced;
    }

    // Null means unconnected on the phase, so we return true so that
    // a single phase switch that is closed on it's only phase is reported
    // as closed from an icon perspective
    return Object.values(this.closedUnbalanced).every(val => val === null || val === true);
  }

  // Determine the open status of the switch for each phase
  get closedBalanced() {
    return this.attributes['Switch.open'] === false;
  }

  get closedUnbalanced() {
    const closed = {};

    try {
      const phases = this.phase.split('');
      if (Object.keys(this._switchPhases).length > 0) {
        phases.forEach(phase => {
          const switchPhase = this._switchPhases[phase];
          closed[phase] = switchPhase.attributes['SwitchPhase.closed'] === true;
        });
      }

      return closed;
    } catch (err) {
      return closed;
    }
  }

  get perPhaseRatedCurrent() {
    try {
      if (!this._switchInfo.ratedCurrent) {
        return null;
      }
      return {
        A: this._switchInfo.ratedCurrent,
        B: this._switchInfo.ratedCurrent,
        C: this._switchInfo.ratedCurrent,
      };
    } catch (err) {
      return null;
    }
  }

  get ratedS() {
    try {
      if (this._switchInfo.ratedCurrent && this._switchInfo.ratedVoltage) {
        const { ratedCurrent, ratedVoltage } = this._switchInfo;
        const ratedS = ratedCurrent * ratedVoltage;
        return this.phase.split('').reduce((lu, phase) => {
          lu[phase] = ratedS;
          return lu;
        }, {});
      }
      return {};
    } catch (err) {
      return {};
    }
  }

  get isUnganged() {
    try {
      return this._switchInfo.isUnganged;
    } catch (err) {
      return false;
    }
  }

  get _switchInfo() {
    const datasheetID = this.references['PowerSystemResource.AssetDatasheet'];
    if (datasheetID) {
      return new SwitchInfo(datasheetID, this.cimDict);
    }
    return {};
  }

  /* Helpers properties */
  get _switchPhases() {
    const status = {};

    try {
      const phaseList = this.references['Switch.SwitchPhase'];
      if (!phaseList) {
        return status;
      }
      const phaseData = phaseList.reduce((lookup, id) => {
        const phase = this.cimDict[id].attributes['SwitchPhase.phaseSide1'];
        return { ...lookup, [phase]: { ...this.cimDict[id], id } };
      }, {});
      return phaseData;
    } catch (err) {
      return status;
    }
  }
}

export default Switch;
