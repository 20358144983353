import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { useImportsContext } from 'routes/ImportCIM/modules/importsContext';
import { ThemeProp } from 'types';

import IconButton from '../IconButton';
import ExpandableSection from '../ExpandableSection';
import Tooltip from '../Tooltip';
import PermissionDisabledTip from '../PermissionDisabledTip';

import './FileUploads.scss';

type ImportJob = {
  id: string;
  file_type: string;
  status: string;
  file_names: [];
  created_time: string;
  uploaded: any;
};

type JobDisplayProps = {
  importJob: ImportJob;
  theme?: ThemeProp;
  deleteImport: (value: string) => void;
  permissions: Set<string> | { has: () => boolean };
};

const JobDisplay: FunctionComponent<JobDisplayProps> = ({
  importJob,
  theme = 'light',
  deleteImport,
  permissions,
}) => {
  const names = importJob.file_names.map((name: string) => (
    <Tooltip
      content={
        <p
          style={{
            wordBreak: 'break-word',
          }}
        >
          {name}
        </p>
      }
      key={name}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: '223px',
          fontWeight: 'bold',
        }}
      >
        {name}
      </div>
    </Tooltip>
  ));
  const now = moment(new Date()); // todays date
  const duration = moment.duration(moment(importJob.created_time).diff(now));
  const uploaded = duration.humanize(true);
  const canDelete = permissions.has('import_network');

  return (
    <div className="job" key={importJob.uploaded}>
      <div className="files-delete">
        <div>{names}</div>
        <div>
          <PermissionDisabledTip
            title="Delete"
            hide={canDelete}
            placement="top"
            theme={theme}
            message="You don't have permission to access this feature."
          >
            <IconButton
              className="delete"
              icon="delete"
              onClick={() => deleteImport(importJob.id)}
              tooltip={canDelete ? 'Delete' : null}
              theme={theme}
              id={importJob.id}
              disabled={!canDelete}
            />
          </PermissionDisabledTip>
        </div>
      </div>
      <div className="type-date-status">
        Type:
        {importJob.file_type}
      </div>
      <div className="type-date-status">
        Uploaded:
        {uploaded}
      </div>
      <div className="type-date-status">
        Status:
        {importJob.status}
      </div>
    </div>
  );
};

type FileUploadsProps = {
  theme?: ThemeProp;
  permissions: Set<string> | { has: () => boolean };
};

const FileUploads: FunctionComponent<FileUploadsProps> = ({ theme = 'light', permissions }) => {
  const {
    imports: { pending, done },
    deleteImport,
  } = useImportsContext();
  const imports = [...pending, ...done];

  return imports.length > 0 ? (
    <ExpandableSection
      sectionName="Network Imports"
      className="container-section lone-feeders"
      open
      id="network-imports"
    >
      {imports.map((importJob: ImportJob) => (
        <JobDisplay
          importJob={importJob}
          theme={theme}
          deleteImport={deleteImport}
          key={importJob.created_time}
          permissions={permissions}
        />
      ))}
    </ExpandableSection>
  ) : null;
};

export default FileUploads;
