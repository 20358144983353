import React, { isValidElement, ReactElement, FunctionComponent } from 'react';
import Tippy from '@tippy.js/react';
import { ThemeProp } from '../../types/index';
import './Tooltip.scss';

const DEFAULT_TOOLTIP_EXPIRY = 1500; // http://redmine.vpn.opusonesolutions.com/issues/6891

const getClassName = (className: string, isElem: boolean) =>
  `${className ? `${className}` : ''} ${isElem ? 'custom-tip' : ''}`;

type TooltipProps = {
  content: string | ReactElement;
  className?: string;
  theme?: ThemeProp;
  children?: ReactElement;
  arrow?: boolean;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  enabled?: boolean;
  persist?: boolean;
  expires?: boolean;
};

const Tooltip: FunctionComponent<TooltipProps> = ({
  content,
  className = '',
  children = null,
  arrow = false,
  placement = 'top',
  enabled = true,
  persist = false,
  expires = false,
  ...props
}) => {
  let expiryTimer: any;
  return content ? (
    <Tippy
      {...props}
      content={content}
      allowHTML
      arrow={arrow}
      isEnabled={enabled}
      className={getClassName(className, isValidElement(content))}
      placement={placement}
      showOnInit={persist}
      interactive
      flip
      {...(persist ? { isVisible: true, hideOnClick: false } : {})}
      onShown={({ hide }) => {
        if (expires && !persist) {
          expiryTimer = setTimeout(() => {
            hide();
          }, DEFAULT_TOOLTIP_EXPIRY);
        }
      }}
      onHide={() => {
        if (expiryTimer) {
          clearTimeout(expiryTimer);
        }
      }}
    >
      <span className={`${className} tip-span`}>{children}</span>
    </Tippy>
  ) : (
    children
  );
};

export default Tooltip;
