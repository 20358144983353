import React, { FunctionComponent } from 'react';

type SeriesCompensatorProps = {
  expanded: boolean;
};

// Creates a value template for SeriesCompensator instances
const SeriesCompensator: FunctionComponent<SeriesCompensatorProps> = () => (
  <div className="series-compensator" />
);

export default SeriesCompensator;
