import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import useResetableState from 'hooks/useResetableState';
import { settings, FeederSummary, optionType } from 'types/edit';
import GridLayout from 'layouts/GridLayout';
import Select from 'components/Select';
import Modal from 'components/Modal';
import ResultsCard from 'components/ResultsCard';
import Icons from 'components/Icons';
import CustomCheckbox from 'components/CustomCheckbox';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { getPercent, isDefined, isDefinedNonZero, isDefinedNonEmpty } from 'helpers/utils';
import Tooltip from 'components/Tooltip';
import NumberInput from 'components/NumberInput';
import BulkAssetPVSettings from './BulkAssetPVSettings';
import BulkAssetEVSettings from './BulkAssetEVSettings';

type BulkAssetModalProps = {
  handleBulkAssetModal: () => void;
  handleBulkAssetLoading: (loading: boolean) => void;
  feederList?: { [key: string]: string }[];
  workspace: string;
  branch: string;
  editBranch: string;
  loadNetworkData: (workspace: string, branch: string, feeders: string[]) => void;
  selectedProject: string | null;
};
type customerType = { [key: string]: { [key: string]: number } };

type asset = {
  [key: string]: {
    assetClass: string;
    requiredValues: string[];
  };
};
const BulkAssetModal: FunctionComponent<BulkAssetModalProps> = ({
  handleBulkAssetModal,
  handleBulkAssetLoading,
  feederList = [],
  workspace,
  branch,
  editBranch,
  loadNetworkData,
  selectedProject = null,
}) => {
  const assetTypes: asset = {
    pv: {
      assetClass: 'PV',
      requiredValues: ['asset_info', 'unit_info'],
    },
    ev: {
      assetClass: 'ElectricVehicleChargingStation',
      requiredValues: ['asset_info', 'ev_charger_type', 'rated_power'],
    },
  };
  const assetTypeOptions = [
    { value: 'pv', label: 'Photovoltaic panels' },
    { value: 'ev', label: 'EV Charging stations' },
  ];
  const [selectedFeeders, setSelectedFeeders, resetSelectedFeeders] = useResetableState<
    optionType[]
  >([]);
  const [selectedAsset, setSelectedAsset] = useState<string>(assetTypeOptions[0].value);
  const [assetSettings, setAssetSettings, resetAssetSettings] = useResetableState<settings>({});
  const [assetsToAllNodes, setAssetsToAllNodes] = useState(false);
  const [loadCountByType, setLoadCountByType] = useState<customerType>({});
  const { TargetIcon, ControlSettingsIcon, PencilEditorIcon } = Icons;
  useEffect(() => {
    resetSelectedFeeders();
    resetAssetSettings();
    setLoadCountByType({});
    // excluded reset states intentionally from  dependancy array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editBranch, workspace, branch]);
  const {
    makeRequest: fetchFeederSummary,
    data: FeederSummaryData,
    reset,
    loading,
  } = useRequest<FeederSummary>(
    `/api/workspace/${workspace}/branch/${editBranch}/feeder-content/summary`,
  );
  const fetchNodeCountByFeeders = async () => {
    if (workspace && editBranch && selectedFeeders && selectedFeeders?.length) {
      await fetchFeederSummary({
        method: 'get',
        params: {
          feeder: selectedFeeders.map((x: optionType) => x.value),
        },
        onSuccess: data => {
          if (data?.feeder_summary?.customer_type) {
            let newType: customerType = {};
            if (assetsToAllNodes) {
              Object.keys(data?.feeder_summary?.customer_type || {}).forEach(key => {
                newType[key] = { customer_count: 1 };
              });
            } else {
              newType = data?.feeder_summary?.customer_type;
            }
            setLoadCountByType(newType);
          }
        },
        onError: () => {
          setLoadCountByType({});
          resetAssetSettings();
        },
      });
    } else {
      reset();
    }
  };
  const feederSummary = FeederSummaryData?.feeder_summary || {};
  const customerTypes = useMemo(() => {
    if (feederSummary.customer_type && !assetsToAllNodes) {
      return Object.keys(feederSummary.customer_type).filter(
        key => loadCountByType[key] && loadCountByType[key].customer_count,
      );
    }
    return ['all'];
  }, [feederSummary.customer_type, assetsToAllNodes, loadCountByType]);
  const settingsHasInvalidValues = customerTypes.some(
    type =>
      !assetSettings[type] ||
      (assetSettings[type] &&
        assetTypes[selectedAsset].requiredValues.some(val => !assetSettings[type][val])),
  );
  const { makeRequest: sendReqToAddBulkAsset, loading: bulkAssetLoading } = useRequest(
    `/api/workspace/${workspace}/branch/${editBranch}/editor/bulk`,
  );
  const saveBulkAssets = async () => {
    try {
      if (workspace && editBranch && selectedFeeders?.length && !settingsHasInvalidValues) {
        handleBulkAssetLoading(true);
        const feederArr = selectedFeeders.map((x: optionType) => x.value);
        const assetsToCreate = assetsToAllNodes
          ? [
              {
                asset_class: assetTypes[selectedAsset].assetClass,
                ...assetSettings.all,
              },
            ]
          : customerTypes.map(type => ({
              asset_class: assetTypes[selectedAsset].assetClass,
              customer_type: type,
              number_to_create: loadCountByType[type].customer_count,
              ...assetSettings[type],
            }));
        await sendReqToAddBulkAsset({
          method: 'post',
          body: {
            feeder: feederArr,
            assets_to_create: assetsToCreate,
            project_id: selectedProject,
          },
          onSuccess: () => {
            loadNetworkData(workspace, editBranch, feederArr);
            handleBulkAssetModal();
          },
          toast: {
            error: 'Could not create bulk PV assets.',
            settings: {
              autoDismiss: true,
            },
          },
        });
        handleBulkAssetLoading(false);
      }
    } catch (error) {}
  };
  const totalCusTypeLoadCount =
    (assetsToAllNodes
      ? feederSummary.customer_count
      : Object.values(loadCountByType).reduce((sum, type) => sum + type.customer_count, 0)) ?? 0;
  const validTotalCounts = totalCusTypeLoadCount > 0 && Number.isInteger(totalCusTypeLoadCount);
  const loadCountsInValid =
    !validTotalCounts ||
    (!assetsToAllNodes &&
      Object.entries(loadCountByType).some(
        ([, type]) =>
          !isDefinedNonEmpty(type?.customer_count) ||
          type.customer_count > 10000 ||
          !Number.isInteger(type.customer_count),
      ));
  const exceedingLimitCustomer = assetsToAllNodes && totalCusTypeLoadCount > 10000;
  let tooltip = '';
  if (!(selectedFeeders && selectedFeeders.length)) {
    tooltip = 'Please select at least one Feeder';
  } else if (loadCountsInValid) {
    tooltip = 'Incorrect number for one or more customer types';
  } else if (exceedingLimitCustomer) {
    tooltip = 'Total Customers should not exceed more than 10,000';
  } else if (settingsHasInvalidValues) {
    tooltip = 'Please select all asset types under Advanced settings';
  }
  return (
    <div className="bulk-asset-modal">
      <Modal
        active
        title="Bulk Asset Upload"
        onConfirm={() => {
          saveBulkAssets();
        }}
        onCancel={() => handleBulkAssetModal()}
        disableConfirm={
          !selectedAsset ||
          !(selectedFeeders && selectedFeeders.length) ||
          bulkAssetLoading ||
          settingsHasInvalidValues ||
          loadCountsInValid ||
          exceedingLimitCustomer
        }
        modalType="primary"
        width="800px"
        height="700px"
        labels={{
          confirm: bulkAssetLoading ? (
            <i className="material-icons rotate">refresh</i>
          ) : (
            <Tooltip content={tooltip} placement="top">
              <>Submit</>
            </Tooltip>
          ),
          cancel: 'Discard',
        }}
        disabledOnClickOverlay
        scrollBody
      >
        <GridLayout>
          <div className="grid-columns auto-fit margin-10">
            <div className="single-column header flex-spaced-row flex-vertical-center">
              <div>Feeders and asset type</div>
              <TargetIcon />
            </div>
            <div className="single-column">
              <Select
                label="Asset type"
                onChange={e => {
                  setSelectedAsset(e.value);
                  setAssetSettings({});
                }}
                id="select-asset"
                options={assetTypeOptions}
                value={selectedAsset}
                type="secondary"
                clearable={false}
              />
            </div>
            <div className="single-column">
              <Select
                label="Select Feeders"
                onChange={(option: optionType[]) => {
                  setSelectedFeeders(option);
                }}
                onMenuClose={() => {
                  fetchNodeCountByFeeders();
                }}
                id="select-feeders"
                options={feederList?.map(feeder => ({ value: feeder.id, label: feeder.name }))}
                value={selectedFeeders}
                type="secondary"
                clearable={false}
                multiSelectType="checkbox"
                isMulti
              />
              {loading && <LoadingSkeleton width={100} className="text-light" />}
              {isDefined(feederSummary.customer_count) && !loading && (
                <div className="text-light nodes-count">
                  {`${feederSummary.customer_count} customers in the selected feeders`}
                </div>
              )}
            </div>
          </div>
          <div className="grid-columns one-two margin-10">
            <div className="single-column header flex-spaced-row">
              <div>Asset placement</div>
              <ControlSettingsIcon />
            </div>
            <div className="single-column">
              <ResultsCard withBorder={false}>
                {loading ? (
                  <LoadingSkeleton width={100} className="text-light" />
                ) : (
                  <div className="flex-vertical-center" style={{ marginBottom: '15px' }}>
                    <CustomCheckbox
                      id="#assets-to-all-nodes"
                      checked={assetsToAllNodes}
                      type="primary"
                      onClick={e => {
                        setAssetsToAllNodes((e.target as HTMLInputElement)?.checked);
                        if ((e.target as HTMLInputElement).checked) {
                          const newType: customerType = {};
                          Object.keys(feederSummary.customer_type || {}).forEach(key => {
                            newType[key] = {
                              customer_count:
                                feederSummary.customer_type?.[key]?.customer_count === 0 ? 0 : 1,
                            };
                          });
                          setLoadCountByType(newType);
                        }
                        setAssetSettings({});
                      }}
                      disabled={!selectedFeeders?.length}
                    />
                    <span>Place assets at all customers</span>
                  </div>
                )}
                <div className="grid-columns one-one">
                  {isDefinedNonZero(feederSummary.customer_count) &&
                    Object.entries(loadCountByType).map(([key, cType]) => (
                      <div className="customer-types" key={key}>
                        <NumberInput
                          className="input-load-count"
                          id={`input-${key}`}
                          label={key === '' ? 'Unspecified customers' : key}
                          value={cType.customer_count}
                          onChange={(e: any) =>
                            setLoadCountByType(prevState => ({
                              ...prevState,
                              [key]: { ...cType, customer_count: e.value === '' ? null : e.value },
                            }))
                          }
                          inputStyle="primary"
                          disabled={
                            assetsToAllNodes ||
                            feederSummary.customer_type?.[key]?.customer_count === 0
                          }
                          inputInfo={
                            !assetsToAllNodes
                              ? `of ${feederSummary.customer_type?.[key]?.customer_count} customers`
                              : 'per customer'
                          }
                          required
                          posInteger
                          ge={0}
                          le={10000}
                        />
                      </div>
                    ))}
                </div>
                {isDefinedNonZero(feederSummary.customer_count) &&
                  selectedFeeders?.length > 0 &&
                  validTotalCounts && (
                    <p className="flex-vertical-center margin-10" id="total-assets-to-add">
                      {`Total assets to add = ${totalCusTypeLoadCount}`}
                      <span className="text-light" style={{ margin: '0px 5px' }}>
                        {`(${getPercent(
                          feederSummary.customer_count,
                          totalCusTypeLoadCount,
                        )} of customers)`}
                      </span>
                    </p>
                  )}
              </ResultsCard>
            </div>
          </div>
          <div className="grid-columns one-two margin-10">
            <div className="single-column header flex-spaced-row">
              <div>Advanced settings</div>
              <PencilEditorIcon />
            </div>
            <div className="single-column">
              <ResultsCard withBorder={false}>
                <div className="grid-columns">
                  {loading && <LoadingSkeleton width={100} height={25} className="text-light" />}
                  {!loading &&
                    isDefinedNonZero(feederSummary.customer_count) &&
                    selectedFeeders?.length > 0 && (
                      <div>
                        {selectedAsset === 'pv' ? (
                          <BulkAssetPVSettings
                            customerTypesofLoads={customerTypes}
                            assetSettings={assetSettings}
                            setAssetSettings={setAssetSettings}
                            invInfos={feederSummary.inverter_infos}
                            pvInfos={feederSummary.photo_voltaic_unit_infos}
                          />
                        ) : (
                          <BulkAssetEVSettings
                            customerTypesofLoads={customerTypes}
                            assetSettings={assetSettings}
                            setAssetSettings={setAssetSettings}
                            evInfos={feederSummary.ev_unit_infos}
                          />
                        )}
                      </div>
                    )}
                </div>
              </ResultsCard>
            </div>
          </div>
        </GridLayout>
      </Modal>
    </div>
  );
};

export default BulkAssetModal;
