import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import asyncStates from 'helpers/asyncActionStates';
import ThemeContext from 'helpers/ThemeContext';

import InstanceInfo from './InstanceInfo';

// Creates a value template for ConnectivityNode instances
const ConnectivityNode = ({
  asset: node,
  workspace,
  branch,
  inEditMode,
  canEditNetwork,
  selected: { id },
  expanded,
  toggleFeederPanel,
  editActions,
  applyDifferenceModelRequest,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'node', id, body);

  return (
    <div
      className={classNames({
        'asset-panel-values': true,
        'asset-panel-values--expanded': expanded,
      })}
    >
      <InstanceInfo
        key={id}
        asset={node}
        assetId={id}
        toggleFeederPanel={toggleFeederPanel}
        branch={branch}
        workspace={workspace}
        disabled={!canEditNetwork || saving}
        inEditMode={inEditMode}
        handleSave={handleSave}
        theme={theme}
      />
    </div>
  );
};

ConnectivityNode.propTypes = {
  asset: PropTypes.object.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  editActions: PropTypes.shape({
    editSingleEquipment: PropTypes.func,
  }).isRequired,
  branch: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ConnectivityNode;
