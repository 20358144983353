/* eslint-disable indent */
import React, { FunctionComponent, useState, useContext, useMemo } from 'react';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomScrollBar from 'components/CustomScrollBar';
import SearchInput from 'components/SearchInput';
import ThemedTabs from 'components/ThemedTabs';
import asyncActionStates from 'helpers/asyncActionStates';
import { userCanImport } from 'routes/WorkspaceLayout/routes/Network/helpers/EditHelpers';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { ReactSVG } from 'react-svg';
import IconButton from 'components/IconButton';
import { CimProperty } from 'types/cim';
import AttachmentsProvider from 'contexts/AttachmentsContext';
import InstanceDropdown from './InstanceDropdown';
import ContainerTab from './ContainerTab';
import AttachmentsLayerToggle from './AttachmentsLayerToggle';
import './NetworkLeftRail.scss';

type NetworkLeftRailProps = {
  leftRailPanel: string | null;
  panelData?: string[];
  workspace: string;
  selectedAssetID: string | null;
  selectedAssetViewModelClass: null | string;
  containerTree: [];
  containerList: [];
  filter: string;
  branch: string;
  setSelectedContainer: (arg: string | null) => void;
  selectedFeeders: { [key: string]: string }[];
  requestStatus: { [key: string]: number };
  feedersPending: Set<string>;
  permissions: Set<string>;
  isAuthEnabled: boolean;
  theme: string;
  networkGeoJSON: {
    [assetClass: string]: { [id: string]: { type?: string; properties: CimProperty } };
  };
  inEditMode: boolean;
  openCreateContainerModal: () => void;
  assetTypeVisibility: { [key: string]: boolean };
  actions: {
    setActiveLeftPanel: (arg: string) => void;
    setHoveredObjectID: () => void;
    createContainer: () => void;
    setAssetTypeVisibility: (type: string, visible: boolean) => void;
    updateContainerFilter: () => void;
    deleteContainer: () => void;
    setSelectedAssetID: () => void;
  };
  showSearch?: boolean;
  deleteStatus?: number;
  setLeftPanelExpanded: (arg: boolean) => void;
  leftPanelExpanded: boolean;
};

/**
 * Creates the left rail on the Network view. Contains details about instances of
 * all assets we are currently rendering
 */

const ASSET = 'asset';
const NETWORK = 'network';
const NetworkLeftRail: FunctionComponent<NetworkLeftRailProps> = ({
  leftRailPanel,
  workspace,
  selectedAssetID,
  selectedAssetViewModelClass,
  containerTree,
  containerList,
  filter,
  branch,
  setSelectedContainer,
  selectedFeeders,
  requestStatus,
  permissions,
  isAuthEnabled,
  theme,
  networkGeoJSON,
  inEditMode,
  openCreateContainerModal,
  assetTypeVisibility,
  actions,
  panelData = [],
  feedersPending = null,
  showSearch = true,
  deleteStatus = asyncActionStates.INITIAL,
  setLeftPanelExpanded,
  leftPanelExpanded,
}) => {
  const [filterString, setFilterString] = useState('');
  const [showOnlyVisible, setShowOnlyVisible] = useState(false);
  const { settings } = useContext(WorkspaceSettingsContext);
  const [openAssetTiles, setOpenAssetTiles] = useState({});
  /**
   * Set the correct menu state on menu tile click.
   * @param  {String} type CIM class type
   */

  const extractAssets = () => {
    const lookup: {
      [key: string]: { properties: CimProperty; id: string; name: string }[];
    } = {};
    if (networkGeoJSON) {
      const assetsArr = [
        ...Object.values(networkGeoJSON.linkIcons),
        ...Object.values(networkGeoJSON.nodeIcons),
        ...Object.values(networkGeoJSON.nodes),
        ...Object.values(networkGeoJSON.lines),
      ];
      assetsArr.forEach(asset => {
        if (!lookup[asset.properties.display_type]) {
          lookup[asset.properties.display_type] = [];
        }
        lookup[asset.properties.display_type].push({
          ...asset,
          id: asset.properties?.id,
          name: asset.properties?.name,
        });
      });
    }
    // return a list of objects sorted by type
    return Object.keys(lookup)
      .filter(x => lookup[x].length > 0)
      .sort()
      .map(k => ({
        name: k,
        children: lookup[k],
        id: lookup[k][0]?.properties?.asset_type,
      }));
  };
  const assetInstances = useMemo(
    () => extractAssets(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [networkGeoJSON],
  );
  const handlePanelNavClick = (filterStringArg: string, type: string) => {
    setFilterString(filterStringArg);
    actions.setActiveLeftPanel(type);
  };

  const handleSearchChange = (e: { persist: () => void; target: { value: string } }) => {
    e.persist();
    setFilterString(e.target.value);
  };

  const selectTab = (tabIndex: number) => {
    if (!leftPanelExpanded) {
      setLeftPanelExpanded(true);
    }
    const type = tabIndex === 0 ? NETWORK : ASSET;
    handlePanelNavClick(filterString, type);
  };

  /* Handle Events */
  const handleSearchFilterToggle = () => setShowOnlyVisible(!showOnlyVisible);
  const canImportNetwork =
    (!isAuthEnabled || userCanImport(permissions, branch)) &&
    (branch !== 'master' || !settings.automaticImport);
  let selectedTabIndex = leftRailPanel === NETWORK ? 0 : 1;
  if (!leftPanelExpanded) selectedTabIndex = -1;
  return (
    <AttachmentsProvider workspace={workspace} branch={branch} assetID={null}>
      <div
        className={`network-left-rail ${leftPanelExpanded ? '--expanded' : '--collapsed'}`}
        id="network-left-rail"
      >
        <ThemedTabs
          theme={theme}
          className="network-left-rail"
          selectedIndex={selectedTabIndex}
          onSelect={selectTab}
          underline="line"
          tabs={[
            {
              disabled: false,
              name: 'Network',
              nameRow: <ReactSVG className="tab-icon network-icon" src="/diverge.svg" />,
            },
            {
              disabled: selectedFeeders.length === 0,
              name: 'Asset',
              nameRow: <ReactSVG className="tab-icon asset-icon" src="/energy.svg" />,
            },
          ]}
        >
          {TabPanel => [
            <TabPanel className="left-rail-tab-panel" key="tab-panel-1">
              <div className="tab-title">Topology</div>
              <ContainerTab
                containerTree={containerTree}
                containerList={containerList}
                handleContainerClick={setSelectedContainer}
                deleteContainer={actions.deleteContainer}
                openCreateContainerModal={openCreateContainerModal}
                feedersPending={!!feedersPending}
                requestStatus={requestStatus}
                selectedFeeders={selectedFeeders}
                deleteStatus={deleteStatus}
                inEditMode={inEditMode}
                theme={theme}
                canImportNetwork={canImportNetwork}
                workspace={workspace}
                branch={branch}
                permissions={permissions}
                setFilter={actions.updateContainerFilter}
                filter={filter}
                fullLeftRail={showSearch}
              />
            </TabPanel>,
            <TabPanel className="left-rail-tab-panel asset-panel" key="tab-panel-2">
              <div className="tab-title">Assets</div>
              {showSearch && (
                <div className="asset-search-header">
                  <SearchInput
                    onChange={handleSearchChange}
                    value={filterString}
                    placeholder="Search..."
                    theme={theme}
                  />
                  <div className="search-filter-row">
                    <CustomCheckbox
                      id="filter-search-by-visible"
                      checked={showOnlyVisible}
                      onClick={handleSearchFilterToggle}
                      type="primary"
                    />
                    <p className="caption-text">See assets based on map view</p>
                  </div>
                  <AttachmentsLayerToggle />
                </div>
              )}
              <div className="asset-selection">
                <CustomScrollBar alwaysShow={false} className="asset-selection-scrollbar">
                  {assetInstances.map(assetInstance => (
                    <InstanceDropdown
                      assetInstance={assetInstance}
                      filterString={filterString}
                      assetsInView={panelData}
                      showOnlyVisible={showOnlyVisible}
                      key={assetInstance.id}
                      feeders={selectedFeeders}
                      selectedAssetViewModelClass={selectedAssetViewModelClass}
                      inEditMode={inEditMode}
                      assetTypeVisibility={assetTypeVisibility}
                      setAssetTypeVisibility={actions.setAssetTypeVisibility}
                      selectedAssetID={selectedAssetID}
                      onInstanceHover={actions.setHoveredObjectID}
                      setSelectedAssetID={actions.setSelectedAssetID}
                      toggleAssetTile={type =>
                        setOpenAssetTiles(prevState => ({ ...prevState, ...type }))
                      }
                      openAssetTiles={openAssetTiles}
                    />
                  ))}
                </CustomScrollBar>
              </div>
            </TabPanel>,
          ]}
        </ThemedTabs>
        {leftPanelExpanded && (
          <div className="collapse-icon" key="collapse-icon">
            <IconButton
              icon="chevron_left"
              onClick={() => setLeftPanelExpanded(false)}
              tooltip="Collapse"
              id="collapse-tab-btn"
            />
          </div>
        )}
      </div>
    </AttachmentsProvider>
  );
};

export default NetworkLeftRail;
