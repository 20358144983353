import { connect } from 'react-redux';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import VoltagePUHistogram from '../components/VoltagePUHistogram';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapStateToProps = state => ({
  nodes: Object.keys(state.network.networkInstance.connectivityNodes),
  theme: state.global.theme,
  resultsRequest: state.requests.qstsValues,
  aggType: state.network.timeBarZoomLevel,
  timeRange: state.network.timeRange,
  expanded: state.network.panelOptions.expanded,
  results: state.network.results,
});

export default connect(mapStateToProps)(VoltagePUHistogram);
