import AsynchronousMachine from './AsynchronousMachine';

class PhotoVoltaic extends AsynchronousMachine {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'photovoltaic';
    this._class = 'PhotoVoltaic';
    this.displayName = 'Solar';
  }
}

export default PhotoVoltaic;
