import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  Legend,
  ComposedChart,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Scatter,
} from 'recharts';
import ThemeContext from 'helpers/ThemeContext';
import Card from 'components/Card';

const data = [
  {
    name: '1',
    value: 1,
  },
  {
    name: '3',
    value: 3,
  },
  {
    name: '5',
    value: 5,
  },
];
const holdFirstData = [
  { index: '0', indexY: '2', lineValue: 2 },
  { index: '4', indexY: '6', lineValue: 6 },
  { index: '8', indexY: '10', lineValue: 10 },
  { index: '12', indexY: '14', lineValue: 14 },
  { index: '2', indexY: '4', value: 1 },
  { index: '6', indexY: '8', value: 1 },
  { index: '10', indexY: '12', value: 1 },
];

const weightedAvgData = [
  { index: '0', indexY: '2', lineValue: 2 },
  { index: '4', indexY: '6', lineValue: 4 },
  { index: '8', indexY: '10', lineValue: 8 },
  { index: '12', indexY: '14', lineValue: 12 },
  { index: '14', indexY: '16', lineValue: 16 },
  { index: '2', indexY: '4', value: 1 },
  { index: '6', indexY: '8', value: 1 },
  { index: '10', indexY: '12', value: 1 },
];

const SimulationExampleGraph = ({ interpolationType, samplingType }) => {
  const theme = useContext(ThemeContext);
  return (
    <div>
      <p className="example-label">Example</p>
      <div className="example-section" style={{ display: 'flex' }}>
        <Card hideTitle className="data-interpolation-example" theme={theme}>
          <LineChart width={200} height={220} data={data}>
            <Line
              name={interpolationType.label}
              type={interpolationType.value}
              dataKey="value"
              stroke="#639DD1"
              strokeWidth={2}
              isAnimationActive={false}
            />
            <Legend align="left" verticalAlign="top" />
          </LineChart>
        </Card>
        <Card hideTitle className="data-interpolation-example sampling-chart" theme={theme}>
          <ComposedChart
            width={230}
            height={220}
            data={samplingType.value === 'holdFirstValue' ? holdFirstData : weightedAvgData}
            padding={{
              top: 0,
              right: 0,
              bottom: 0,
              left: -30,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" vertical={false} stroke="#f5f5f5" />
            <XAxis dataKey="index" type="number" />
            <YAxis dataKey="indexY" type="number" orientation="left" />
            <ZAxis range={[25, 225]} />
            <Line
              name={samplingType.label}
              dataKey="lineValue"
              stroke="#639DD1"
              strokeWidth={2}
              type={samplingType.value === 'holdFirstValue' ? 'stepAfter' : 'stepBefore'}
              dot={false}
              isAnimationActive={false}
            />
            <Scatter
              dataKey="value"
              fill="#fff"
              stroke="#639DD1"
              strokeWidth={2}
              r={2}
              legendType="none"
              isAnimationActive={false}
            />
            <Legend align="left" verticalAlign="top" />
          </ComposedChart>
        </Card>
      </div>
    </div>
  );
};

SimulationExampleGraph.propTypes = {
  interpolationType: PropTypes.object.isRequired,
  samplingType: PropTypes.object.isRequired,
};

export default SimulationExampleGraph;
