/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import browserCheck from 'helpers/browserCheck';
import './DragDrop.scss';

/**
 * Creates a file upload area that accepts keyboard and mouse events for manual
 * file upload and for files to be dragged into it and processed.
 */
const DragDrop = ({ accept, disabled, handleFileSelection, theme }) => {
  // Number of files dragged over the form.
  const [numFiles, setNumFiles] = useState(null);
  const fileInput = useRef(null);

  const setTotalFiles = e => {
    if (disabled) return;
    e.stopPropagation();
    e.preventDefault();
    const files = e.dataTransfer.items;
    // IE11 doesn't have a way of accessing the files on drag over. Placeholder
    // text has been added in place of the number of files in that case.
    const total = files ? files.length : 'Selected';
    setNumFiles(total);
  };

  const clearFiles = e => {
    e.stopPropagation();
    e.preventDefault();
    setNumFiles(null);
  };

  const handleSelection = e => {
    if (disabled) return;
    handleFileSelection(e);
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };

  const filesString = () => {
    if (numFiles === 'Selected') return 'Files';
    return numFiles > 1 ? 'Files' : 'File';
  };

  return (
    <div className={`drag-drop ${disabled ? 'drag-drop--disabled' : ''} ${theme}`}>
      <form
        onDragOver={setTotalFiles}
        onDragLeave={clearFiles}
        onChange={handleSelection}
        onDrop={e => {
          // Only trigger onDrop if IE11
          if (browserCheck.isIE) {
            handleSelection(e);
          }
          setNumFiles(null);
        }}
        encType="multipart/form-data"
        className="drag-container"
        tabIndex={disabled ? 0 : -1}
        onKeyDown={e => {
          if (e.key !== 'Tab') {
            fileInput.current.click();
          }
        }}
        disabled={disabled}
      >
        <input
          ref={fileInput}
          className="drag-input"
          type="file"
          name="files[]"
          id="file"
          multiple
          accept={accept}
          title="Select Files"
          tabIndex={disabled ? 1 : -1}
          disabled={disabled}
        />
        <div className="drag-message-container">
          {numFiles && (
            <div id="drag-over-message" className="drag-target-container">
              <i className="material-icons add-file-icon">add_circle</i>
              <p>{`Add ${numFiles} ${filesString()}`}</p>
            </div>
          )}

          {!numFiles && (
            <div className="drag-target-container">
              <i className="material-icons add-file-icon">add_to_photos</i>
              <p>
                Drag and drop or&nbsp;
                <span className="pseudo-link">browse</span>
                &nbsp;your files
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

DragDrop.defaultProps = {
  accept: '.xml',
  disabled: false,
};

DragDrop.propTypes = {
  accept: PropTypes.string,
  handleFileSelection: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default DragDrop;
