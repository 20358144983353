import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ThemeContext from 'helpers/ThemeContext';
import NumberInput from 'components/NumberInput';
import TextInput from 'components/TextInput';

import PerPhaseSelect from '../../Inputs/PerPhaseSelect';

const SELECT = 'select';
const NUMBER = 'number';
const TEXT = 'text';

const PerPhaseRow = props => {
  const {
    label,
    values,
    step,
    unit,
    divisor,
    getTotal,
    onChange,
    editable,
    edited,
    id,
    type,
    options,
    defaultValue,
    phases,
    precision,
    min,
    max,
    required,
    customValidation,
    onBlur,
    disabled,
    violations,
    timeRange,
    rowInput,
  } = props;
  const timepoint =
    (timeRange && timeRange?.start && moment.utc(timeRange.start).format('YYYY-MM-DD HH:mm')) ??
    null;
  if (values === null) {
    return null;
  }
  function applyValidation() {
    return customValidation ? customValidation(values, { ...props }) : { valid: {}, message: '' };
  }

  /**
   * Create a single phase cell with value in units provided
   * @param  {Number|String} val   Value of per phase property
   * @param  {String}        phase Phase of the displayed value
   * @param  {String}        key  The type of value displayed
   * @return {JSX}                 Single value for row
   */
  function createColumn(value, phase, valid, theme) {
    const hasPhase = phases && phases.includes(phase);
    const violating =
      hasPhase &&
      violations?.[label]
        ?.map(v => v.phases.includes(phase.toLowerCase()) && v.timepoint === timepoint)
        .includes(true);
    if (hasPhase) {
      if (type === NUMBER) {
        return (
          <NumberInput
            key={`${id}${phase}`}
            {...{
              value: value[phase],
              edited: edited && edited[phase],
              type,
              id,
              onChange,
              inputStyle: 'panel',
              theme,
              onBlur,
              name: id,
              disabled: disabled || !editable,
              gt: min,
              lt: max,
              required,
              invalid: valid[phase] !== undefined ? !valid[phase] : false,
              step,
              divisor,
              precision,
              phase,
              inputColor: violating ? '#FF3A00' : null,
            }}
            id={`${id}${phase}`}
          />
        );
      }
      if (type === TEXT) {
        return (
          <TextInput
            key={`${id}${phase}`}
            {...{
              value: value[phase],
              id,
              onChange,
              inputStyle: 'panel',
              theme,
              onBlur,
              name: id,
              disabled: disabled || !editable,
              required,
              invalid: valid[phase] !== undefined ? !valid[phase] : false,
              phase,
              rowInput,
              inputColor: violating ? '#FF3A00' : null,
            }}
          />
        );
      }
      if (type === SELECT) {
        return (
          <PerPhaseSelect
            key={`${id}${phase}`}
            {...{
              phase,
              value,
              editable,
              edited,
              type,
              divisor,
              id,
              onChange,
              options,
              defaultValue,
              theme,
              disabled,
            }}
          />
        );
      }
    }
    return <div key={`${id}${phase}-empty`} className="per-phase-row-placeholder" />;
  }

  const displayTotal = (value, theme) => (
    <NumberInput
      key={`${id}-total`}
      inputStyle="panel"
      edited={edited}
      value={value}
      type="number"
      id={`${id}-total`}
      theme={theme}
      disabled
      precision
      phase="ABC"
    />
  );

  const total = getTotal ? getTotal(values, divisor, precision) : null;
  const { valid, message } = applyValidation();
  const phaseEdited = !disabled && edited && Object.values(edited).some(val => val);

  return (
    <ThemeContext.Consumer>
      {theme => (
        <span>
          <div className="per-phase-row">
            <p className={`per-phase-row-label ${!total ? 'no-total-label' : ''}`}>{label}</p>
            <div
              className={
                `per-phase-row-total ${!total ? 'no-total-value' : ''} ` +
                `${phaseEdited ? 'per-phase-row-total--edited' : ''}`
              }
            >
              {total && displayTotal(total, theme)}
            </div>
            {['A', 'B', 'C'].map(phase => createColumn(values, phase, valid, theme))}
            <p className="per-phase-row-unit">{unit}</p>
          </div>
          {message && <div className="validation-message">{message}</div>}
        </span>
      )}
    </ThemeContext.Consumer>
  );
};

PerPhaseRow.defaultProps = {
  unit: null,
  divisor: 1,
  precision: null,
  onChange: null,
  editable: false,
  edited: null,
  options: [],
  defaultValue: '',
  phases: '',
  values: null,
  step: 'any',
  getTotal: null,
  min: null,
  max: null,
  required: false,
  customValidation: null,
  onBlur: null,
  disabled: false,
  violations: {},
  timeRange: {},
  rowInput: false,
};

PerPhaseRow.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  divisor: PropTypes.number,
  precision: PropTypes.number,
  values: PropTypes.object,
  step: PropTypes.string,
  getTotal: PropTypes.func,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  edited: PropTypes.object,
  options: PropTypes.array,
  defaultValue: PropTypes.any,
  phases: PropTypes.string,
  id: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  required: PropTypes.bool,
  customValidation: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  violations: PropTypes.object,
  timeRange: PropTypes.object,
  rowInput: PropTypes.bool,
};

export default PerPhaseRow;
