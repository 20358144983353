import React, { FunctionComponent, ReactChild, ReactNode } from 'react';
import './FileForm.scss';

type FileFormProps = {
  accept?: string;
  onChange: (e: any) => void | null | Promise<void>;
  createRef?: () => void | null;
  id: string;
  className?: string | null;
  disabled?: boolean | null;
  children: ReactChild | ReactNode;
};

// Create a button like file form that wraps a custom element in the label.
const FileForm: FunctionComponent<FileFormProps> = ({
  accept = '*',
  createRef = null,
  onChange,
  className = '',
  disabled = false,
  id = '',
  children,
}) => (
  <form
    encType="multipart/form-data"
    className={`file-form-btn ${className} ${disabled && 'file-form-btn--disabled'}`}
    onChange={onChange}
  >
    <input
      ref={createRef}
      className="file-form-input"
      type="file"
      name="files[]"
      id={id}
      accept={accept}
    />
    <label className="file-form-input__label" htmlFor={id}>
      {children}
    </label>
  </form>
);

export default FileForm;
