import Switch from './Switch';

class Breaker extends Switch {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'breaker';
    this._class = 'Breaker';
    this.displayName = this.class;
  }
}

export default Breaker;
