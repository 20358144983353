import ThemeContext from 'helpers/ThemeContext';
import { pu } from 'helpers/units';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { SimulationOptions } from 'types/analysis';
import NumberInput from '../NumberInput';

type VoltageLimitProps = {
  simulationOptions: SimulationOptions;
  setSimulationOptions: React.Dispatch<React.SetStateAction<SimulationOptions>>;
  disableButton: (disable: boolean) => void;
};

const VoltageLimits: FunctionComponent<VoltageLimitProps> = ({
  simulationOptions,
  setSimulationOptions,
  disableButton,
}) => {
  const theme = useContext(ThemeContext);

  const lower = simulationOptions.voltage_lower_limit ?? 0.94;
  const upper = simulationOptions.voltage_upper_limit ?? 1.06;

  const lowerInvalid = lower >= upper || lower <= 0.6;
  const upperInvalid = lower >= upper || upper >= 1.5;

  useEffect(() => {
    disableButton(lowerInvalid || upperInvalid);
  }, [lowerInvalid, upperInvalid, disableButton]);

  return (
    <div style={{ padding: '0 15px' }}>
      <NumberInput
        step="0.001"
        id="voltage_lower_limit"
        unit={pu}
        value={lower}
        onChange={evt =>
          setSimulationOptions(opts => ({
            ...opts,
            voltage_lower_limit: evt.value,
          }))
        }
        label="Lower voltage limit"
        disabled={simulationOptions.dont_constrain_voltages === false}
        theme={theme}
        invalid={lowerInvalid}
        gt={0.6}
        lt={upper}
        required
        validationMessage={`Voltage limit must be between 0.6 and ${upper}`}
      />
      <NumberInput
        step="0.001"
        id="voltage_upper_limit"
        unit={pu}
        value={upper}
        onChange={evt =>
          setSimulationOptions(opts => ({
            ...opts,
            voltage_upper_limit: evt.value,
          }))
        }
        label="Upper voltage limit"
        disabled={simulationOptions.dont_constrain_voltages === false}
        theme={theme}
        invalid={upperInvalid}
        required
        gt={lower}
        lt={1.5}
        validationMessage={`Voltage limit must be between ${lower} and 1.5`}
      />
    </div>
  );
};

export default VoltageLimits;
