import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ThemeContext from 'helpers/ThemeContext';
import ActionButtons from 'components/ActionButtons';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import OPFOptions from './OPFOptions';
import './HostingCapacityMenu.scss';

const NodalAnalysisInfo = {
  [ANALYSIS_TYPES.HOSTING_CAPACITY]: { type: 'generation', title: 'Hosting Capacity' },
  [ANALYSIS_TYPES.EV_CAPACITY]: { type: 'load', title: 'EV Capacity' },
};

const HostingCapacityMenu = ({
  onSubmit,
  onPreviousClick,
  simulationOptions,
  setSimulationOptions,
  selectedAnalysisType,
  substations,
  disableSubmit,
  errorMessage,
  toggleModal,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const theme = useContext(ThemeContext);

  const buttonState = state => setButtonDisabled(state);

  const handleSubmit = () => {
    onSubmit(NodalAnalysisInfo[selectedAnalysisType].type);
  };

  return (
    <div className="hosting-capacity-menu config-panel">
      <OPFOptions
        simulationOptions={simulationOptions}
        setSimulationOptions={setSimulationOptions}
        theme={theme}
        // Todo: Support multi substation analysis
        substationId={substations && substations.length ? substations[0].id : undefined}
        disableButton={buttonState}
        selectedAnalysisType={selectedAnalysisType}
      />
      <div className="menu-footer">
        <ActionButtons
          className="analysis-action-btns"
          onClickBackBtn={onPreviousClick}
          disableSubmit={disableSubmit || buttonDisabled}
          onClickSubmitBtn={handleSubmit}
          toggleModal={toggleModal}
          title={errorMessage}
          submitLabel="Run Analysis"
          submitBtnId="submit-qsts"
        />
      </div>
    </div>
  );
};

HostingCapacityMenu.defaultProps = {
  substations: [],
  errorMessage: '',
};

HostingCapacityMenu.propTypes = {
  selectedAnalysisType: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  simulationOptions: PropTypes.object.isRequired,
  setSimulationOptions: PropTypes.func.isRequired,
  substations: PropTypes.array,
  disableSubmit: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
};

export default HostingCapacityMenu;
