import React from 'react';
import PropTypes from 'prop-types';

const Tick = ({ tick, count, tickFormatter, missingData, onClick, skipOne }) => {
  // Format the tick if a formatter is provided.
  const formatTick = value => {
    if (tickFormatter) {
      return tickFormatter(value);
    }
    return value;
  };

  const formattedTick = () => {
    if (skipOne) return tick.value % 2 === 0 ? formatTick(tick.value) : null;

    return formatTick(tick.value);
  };

  return (
    <div
      className={`tick-container ${missingData ? 'tick-container--missing-data' : ''}`}
      id="tick-container"
      style={{ width: `${100 / count}%` }}
    >
      <div className="simple-range-slider-tick-line" style={{ left: `${tick.percent}%` }} />
      <button
        className="simple-range-slider-tick-value"
        onClick={() => onClick(tick.value)}
        style={{
          left: `${tick.percent}%`,
          width: `${100 / count}%`,
        }}
        type="button"
      >
        {formattedTick()}
      </button>
    </div>
  );
};

Tick.defaultProps = {
  tickFormatter: null,
  missingData: false,
  onClick: () => null,
};

Tick.propTypes = {
  tick: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  tickFormatter: PropTypes.func,
  missingData: PropTypes.bool,
  onClick: PropTypes.func,
  skipOne: PropTypes.bool.isRequired,
};

export default Tick;
