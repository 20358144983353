import React, { FunctionComponent } from 'react';
import FileSaver from 'file-saver';
import moment from 'moment';

import { useRequest } from '@opusonesolutions/gridos-app-framework';
import Card from '../Card';
import IconButton from '../IconButton';
import { ThemeProp } from '../../types/index';

type FileCardProps = {
  createdTime: string;
  disabled: boolean;
  onDelete: () => void;
  path: string;
  permissions: Set<string> | { has: () => boolean };
  theme: ThemeProp;
};

const FileCard: FunctionComponent<FileCardProps> = ({
  createdTime,
  disabled,
  onDelete,
  path,
  permissions,
  theme,
}) => {
  const { makeRequest: runDownload, loading: downloading } = useRequest<Blob>(`/api/files${path}`);
  const downloadFile = async () => {
    await runDownload({
      method: 'get',
      onSuccess: data => {
        if (data) {
          const fileName = path.split('/').reverse()[0];
          FileSaver.saveAs(data, fileName);
        }
      },
      toast: {
        error: 'Could not download file.',
        settings: {
          autoDismiss: true,
        },
      },

      // Axios Options
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
      responseType: 'blob',
    });
  };

  const parts = path.split('/');
  const fileName = parts[parts.length - 1];
  const canDelete = permissions.has('delete_asset_attachments');
  const getMenuButtons = () => (
    <span className="button-container">
      <IconButton
        className="download-file"
        disabled={disabled || downloading}
        icon="get_app"
        onClick={downloadFile}
        theme={theme}
        tooltip="Download file"
      />
      <IconButton
        className="delete-file"
        disabled={!canDelete || disabled || downloading}
        icon="delete"
        onClick={onDelete}
        theme={theme}
        tooltip={canDelete !== true ? '' : 'Delete File'}
      />
    </span>
  );

  return (
    <Card
      buttonGroup={getMenuButtons()}
      className="file-card"
      secondaryTitle={`Created ${moment(createdTime).fromNow()}`}
      title={fileName}
      theme={theme}
    />
  );
};

export default FileCard;
