import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import CustomCheckbox from 'components/CustomCheckbox';
import EquipmentBadge from './EquipmentBadge';
import './EquipmentTypeTile.scss';

/**
 * Creates the left rail on the EquipmentLibrary
 */
const EquipmentTypeTile = props => {
  const { selected, bulkSelected, equipmentInfoItem } = props;
  const dataSelected = selected ? 'equipment-select-row__selected' : '';

  return (
    <div className={`equipment-select-row ${dataSelected}`}>
      <CustomCheckbox
        id="bulk-selected"
        name="checkbox"
        checked={bulkSelected}
        className="equipment-checkbox"
        onClick={() => props.handleSelectEquipment()}
      />
      <button
        title={equipmentInfoItem.name}
        className="equipment-type-tile"
        onClick={props.selectEquipment}
        data-selected={selected}
        type="button"
      >
        <p className="equipment-type-label" title={equipmentInfoItem.name}>
          {equipmentInfoItem.name}
        </p>
        <EquipmentBadge equipmentInfoItem={equipmentInfoItem} />
      </button>
      <div className="in-use-button">
        {equipmentInfoItem.inUse && (
          <IconButton onClick={() => {}} icon="done" tooltip="Equipment in use" />
        )}
      </div>
    </div>
  );
};

EquipmentTypeTile.defaultProps = {
  selectEquipment: null,
  selected: false,
  bulkSelected: false,
  handleSelectEquipment: null,
};

EquipmentTypeTile.propTypes = {
  equipmentInfoItem: PropTypes.object.isRequired,
  selectEquipment: PropTypes.func,
  selected: PropTypes.bool,
  bulkSelected: PropTypes.bool,
  handleSelectEquipment: PropTypes.func,
};

export default EquipmentTypeTile;
