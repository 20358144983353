import ShuntDevice from './ShuntDevice';

class Inverter extends ShuntDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'Inverter';
    this.icon = 'inverter';
    this.displayName = this.class;
  }

  _attributeValue(object, attribute) {
    return object[attribute];
  }

  get ratedS() {
    return this.attributes['PowerElectronicsConnection.ratedS'];
  }

  get initialP() {
    return this.attributes['PowerElectronicsConnection.p'];
  }

  static validationSchema = {
    minP: {
      customValidation: value => ({
        valid: value <= 0,
        message: 'Value must be greater than or equal to 0',
      }),
    },
    maxP: { min: 0 },
    minQ: {
      customValidation: value => ({
        valid: value <= 0,
        message: 'Value must be greater than or equal to 0',
      }),
    },
    maxQ: { min: 0 },
    ratedS: { min: 0 },
    ratedPowerFactor: { min: 0, max: 1 },
  };
}

export default Inverter;
