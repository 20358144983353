import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';

import { watts, kV, kVA, scaleFactorForUnit } from 'helpers/units';
import SelectRow from 'routes/WorkspaceLayout/routes/EquipmentLibrary/components/SelectRow';
import Helpers from 'routes/WorkspaceLayout/routes/EquipmentLibrary/helpers/EquipmentLibraryHelpers';
import { isDefined } from 'helpers/utils';
import {
  areAssetModelPropertiesChanged,
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
  defaultEmergencyRatings,
  isValidEmergencyRatings,
  emergencyRatingsUpdated,
  updateEmRatings,
} from 'routes/WorkspaceLayout/routes/EquipmentLibrary/helpers/assetModelHelpers';

import DescriptionEditor from '../DescriptionEditor';
import CostEditor from '../CostEditor';
import ReliabilityMetrics from '../ReliabilityMetrics';
import PanelTabs from '../PanelTabs';
import ImpedancePanel from './ImpedancePanel';
import EmergencyRatings from '../../EmergencyRatings';

import '../common.scss';

const connectionKinds = [
  { value: 'D', label: 'D' },
  { value: 'Yn', label: 'Yn' },
  { value: 'Y', label: 'Y' },
  { value: 'Zn', label: 'Zn' },
  { value: 'Z', label: 'Z' },
];

const supportedEMFields = ['emApparentPower'];

const getNewTransformer = () => ({
  id: 'add',
  name: '',
  description: '',
  primaryEnd: {
    id: '',
    connectionKind: '',
    R: '0',
    R0: '0',
    X: '0',
    X0: '0',
    starImpedanceId: '',
    ratedU: '',
    ratedS: '',
    noLoadLossId: '',
    noLoadLoss: '0',
  },
  secondaryEnd: {
    id: '',
    connectionKind: '',
    phaseAngle: '0',
    ratedU: '',
    ratedS: '',
  },
  assetModel: { ...defaultAssetModel },
  emergencyRatings: { ...defaultEmergencyRatings(supportedEMFields) },
});

class TransformerPanel extends Component {
  state = {
    ...getNewTransformer(),
    invalidAngle: false,
  };

  constructor(props) {
    super(props);
    this.state = { ...this.extractFormValues(this.props.selected) };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ ...this.extractFormValues(this.props.selected) });
    }
  }

  definedOrDefault = (value, defaultValue) => (isDefined(value) ? `${value}` : defaultValue);

  createEndObject = (endInfo, defaultEndInfo) => {
    const {
      id,
      starImpedance,
      NoLoadTest,
      phaseAngleClock,
      ratedU,
      ratedS,
      endNumber,
      connectionKind,
    } = endInfo;
    let end = {};
    let R = '';
    let R0 = '';
    let X = '';
    let X0 = '';
    let noLoadLoss = '';
    let phaseAngle = '0';
    let starImpedanceId;
    let noLoadLossId;
    if (endNumber === 1) {
      if (isDefined(starImpedance) && Object.keys(starImpedance).length > 0) {
        starImpedanceId = starImpedance.id;
        R = this.definedOrDefault(
          starImpedance.attributes['TransformerStarImpedance.r'],
          defaultEndInfo.R,
        );
        R0 = this.definedOrDefault(
          starImpedance.attributes['TransformerStarImpedance.r0'],
          defaultEndInfo.R0,
        );
        X = this.definedOrDefault(
          starImpedance.attributes['TransformerStarImpedance.x'],
          defaultEndInfo.X,
        );
        X0 = this.definedOrDefault(
          starImpedance.attributes['TransformerStarImpedance.x0'],
          defaultEndInfo.X0,
        );
      } else {
        R = defaultEndInfo.R;
        R0 = defaultEndInfo.R0;
        X = defaultEndInfo.X;
        X0 = defaultEndInfo.X0;
      }
      if (isDefined(NoLoadTest)) {
        noLoadLossId = NoLoadTest.id;
        noLoadLoss = this.definedOrDefault(NoLoadTest.loss, defaultEndInfo.noLoadLoss);
      } else {
        noLoadLoss = defaultEndInfo.noLoadLoss;
      }
      end = {
        starImpedanceId,
        R,
        R0,
        X,
        X0,
        noLoadLossId,
        noLoadLoss,
      };
    } else {
      phaseAngle = (phaseAngleClock * 30 || 0).toString();
      end = {
        phaseAngle,
      };
    }
    return {
      ...end,
      id,
      connectionKind,
      ratedU: this.definedOrDefault(
        Number.isNaN(ratedU) ? ratedU : ratedU / 1000,
        defaultEndInfo.ratedU,
      ),
      ratedS: this.definedOrDefault(
        Number.isNaN(ratedS) ? ratedS : ratedS / 1000,
        defaultEndInfo.ratedS,
      ),
      endNumber,
    };
  };

  extractFormValues = instance => {
    let asset = { ...getNewTransformer(), isValidShiftFactor: true };
    if (instance) {
      const { name, description, id, EndInfos, AssetModel } = instance;
      let primaryEnd;
      let secondaryEnd;
      // I wrote it this way so that if endNumber doesn't have a value,
      // it defaults to endInfo[0] being the primary end
      if (EndInfos[0].endNumber === 2) {
        [secondaryEnd, primaryEnd] = EndInfos;
      } else {
        [primaryEnd, secondaryEnd] = EndInfos;
      }

      asset = {
        ...asset,
        primaryEnd: this.createEndObject(primaryEnd, asset.primaryEnd),
        secondaryEnd: this.createEndObject(secondaryEnd, asset.secondaryEnd),
        name,
        description,
        id,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        emergencyRatings: {
          longTermEmergency: {
            apparentPowerLimit:
              primaryEnd?.alternateLimitSet?.longTermEmergency?.['ApparentPowerLimit.value'] ??
              null,
          },
          shortTermEmergency: {
            apparentPowerLimit:
              primaryEnd?.alternateLimitSet?.shortTermEmergency?.['ApparentPowerLimit.value'] ??
              null,
          },
        },
      };
    }
    return asset;
  };

  handleConnectionChange = (e, i) => {
    let endName = 'primaryEnd';
    if (i === 1) {
      endName = 'secondaryEnd';
    }

    const { value } = e;
    this.setState(prevState => ({
      [endName]: {
        ...prevState[endName],
        connectionKind: value,
      },
    }));
  };

  handleInputChange = ({ id, value }, i) => {
    let endName = 'primaryEnd';
    if (i === 1) {
      endName = 'secondaryEnd';
    }

    this.setState(prevState => ({
      [endName]: {
        ...prevState[endName],
        [id]: value,
      },
    }));
  };

  handleImpedanceChange = values => {
    this.setState(prevState => ({
      primaryEnd: {
        ...prevState.primaryEnd,
        ...values,
      },
    }));
  };

  createEndDisplayObjects = endInfo => ({
    connectionKind: Helpers.createDisplayObject(
      'Winding',
      'connectionKind',
      endInfo.connectionKind,
    ),
    phaseAngle: Helpers.createDisplayObject(
      'Phase Angle (Lagging)',
      'phaseAngle',
      endInfo.phaseAngle,
      '°',
    ),
    noLoadLoss: Helpers.createDisplayObject(
      'Magnetizing Loss',
      'noLoadLoss',
      endInfo.noLoadLoss,
      watts,
    ),
    ratedU: Helpers.createDisplayObject('Rated Voltage', 'ratedU', endInfo.ratedU, kV),
    ratedS: Helpers.createDisplayObject('Rated Apparent Power', 'ratedS', endInfo.ratedS, kVA),
  });

  handleCreate = () => {
    const { primaryEnd, secondaryEnd, name, description, emergencyRatings } = this.state;
    const diffModel = {
      TransformerTankInfos: [
        {
          name,
          description,
          TransformerEndInfos: [
            {
              TransformerStarImpedance: {
                r: parseFloat(primaryEnd.R),
                r0: parseFloat(primaryEnd.R0),
                x: parseFloat(primaryEnd.X),
                x0: parseFloat(primaryEnd.X0),
              },
              endNumber: 1,
              connectionKind: primaryEnd.connectionKind,
              ratedS: parseFloat(primaryEnd.ratedS) * 1000,
              ratedU: parseFloat(primaryEnd.ratedU) * 1000,
              EnergisedEndNoLoadTests: [
                {
                  loss: parseFloat(primaryEnd.noLoadLoss) / 1000,
                },
              ],
              EmergencyRatings: emergencyRatings,
            },
            {
              endNumber: 2,
              connectionKind: secondaryEnd.connectionKind,
              ratedS: parseFloat(secondaryEnd.ratedS) * 1000,
              ratedU: parseFloat(secondaryEnd.ratedU) * 1000,
              phaseAngleClock: secondaryEnd.phaseAngle / 30 || 0,
            },
          ],
          AssetModel: { ...this.state.assetModel },
        },
      ],
    };
    if (this.state.id === 'add') {
      this.props.handleCreate('power_transformer_info', diffModel, 'TransformerTankInfo');
    } else {
      const originalValues = this.extractFormValues(this.props.selected);
      // Only create difference model for values that have changed
      const { selected } = this.props;
      diffModel.TransformerTankInfos[0].UUID = selected.id;
      const hasEndInfo1 = selected.EndInfos && selected.EndInfos[0].id.length > 0;
      const hasEndInfo2 = selected.EndInfos && selected.EndInfos[1].id.length > 0;
      const hasStarImpedance =
        selected.EndInfos[0].starImpedance &&
        selected.EndInfos[0].starImpedance.id &&
        selected.EndInfos[0].starImpedance.id.length > 0;
      const hasNoLoadTest =
        selected.EndInfos[0].NoLoadTest &&
        selected.EndInfos[0].NoLoadTest.id &&
        selected.EndInfos[0].NoLoadTest.id.length > 0;

      diffModel.TransformerTankInfos[0].TransformerEndInfos[0].UUID = hasEndInfo1
        ? selected.EndInfos[0].id
        : undefined;
      diffModel.TransformerTankInfos[0].TransformerEndInfos[0].TransformerStarImpedance.UUID = hasStarImpedance
        ? selected.EndInfos[0].starImpedance.id
        : undefined;
      diffModel.TransformerTankInfos[0].TransformerEndInfos[0].EnergisedEndNoLoadTests.UUID = hasNoLoadTest
        ? selected.EndInfos[0].NoLoadTest.id
        : undefined;
      diffModel.TransformerTankInfos[0].TransformerEndInfos[1].UUID = hasEndInfo2
        ? selected.EndInfos[1].id
        : undefined;

      const [Primary, Secondary] = diffModel.TransformerTankInfos[0].TransformerEndInfos;
      if (diffModel.TransformerTankInfos[0].name === originalValues.name) {
        delete diffModel.TransformerTankInfos[0].name;
      }
      if (
        areAssetModelsEqual(
          getAssetModelProperties(selected.AssetModel),
          diffModel.TransformerTankInfos[0].AssetModel,
        )
      ) {
        delete diffModel.TransformerTankInfos[0].AssetModel;
      }
      if (
        !emergencyRatingsUpdated(
          selected.EndInfos[0]?.alternateLimitSet,
          emergencyRatings,
          supportedEMFields,
        )
      ) {
        delete diffModel.TransformerTankInfos[0].TransformerEndInfos[0].EmergencyRatings;
      }
      if (Primary.connectionKind === originalValues.primaryEnd.connectionKind) {
        delete Primary.connectionKind;
      }
      if (Primary.ratedS / 1000 === parseFloat(originalValues.primaryEnd.ratedS)) {
        delete Primary.ratedS;
      }
      if (Primary.ratedU / 1000 === parseFloat(originalValues.primaryEnd.ratedU)) {
        delete Primary.ratedU;
      }
      if (
        Primary.EnergisedEndNoLoadTests[0].loss === parseFloat(originalValues.primaryEnd.noLoadLoss)
      ) {
        delete Primary.EnergisedEndNoLoadTests;
      }
      if (Primary.TransformerStarImpedance.r === parseFloat(originalValues.primaryEnd.R)) {
        delete Primary.TransformerStarImpedance.r;
      }
      if (Primary.TransformerStarImpedance.r0 === parseFloat(originalValues.primaryEnd.R0)) {
        delete Primary.TransformerStarImpedance.r0;
      }
      if (Primary.TransformerStarImpedance.x === parseFloat(originalValues.primaryEnd.X)) {
        delete Primary.TransformerStarImpedance.x;
      }
      if (Primary.TransformerStarImpedance.x0 === parseFloat(originalValues.primaryEnd.X0)) {
        delete Primary.TransformerStarImpedance.x0;
      }
      if (Secondary.connectionKind === originalValues.secondaryEnd.connectionKind) {
        delete Secondary.connectionKind;
      }
      if (Secondary.ratedS / 1000 === parseFloat(originalValues.secondaryEnd.ratedS)) {
        delete Secondary.ratedS;
      }
      if (Secondary.ratedU / 1000 === parseFloat(originalValues.secondaryEnd.ratedU)) {
        delete Secondary.ratedU;
      }
      if (Secondary.phaseAngleClock === parseFloat(originalValues.secondaryEnd.phaseAngle) / 30) {
        delete Secondary.phaseAngleClock;
      }
      this.props.handleEdit(selected.id, diffModel.TransformerTankInfos[0]);
    }
  };

  valuesUpdated = selected => {
    if (!selected) return true;

    const {
      name,
      description,
      primaryEnd,
      secondaryEnd,
      assetModel,
      emergencyRatings,
    } = this.state;
    const originalValues = this.extractFormValues(selected);
    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selected.AssetModel),
      assetModel,
    );
    return (
      originalValues.name !== name ||
      originalValues.description !== description ||
      originalValues.primaryEnd.R !== primaryEnd.R ||
      originalValues.primaryEnd.R0 !== primaryEnd.R0 ||
      originalValues.primaryEnd.X !== primaryEnd.X ||
      originalValues.primaryEnd.X0 !== primaryEnd.X0 ||
      originalValues.primaryEnd.noLoadLoss !== primaryEnd.noLoadLoss ||
      originalValues.primaryEnd.connectionKind !== primaryEnd.connectionKind ||
      originalValues.primaryEnd.ratedU !== primaryEnd.ratedU ||
      originalValues.primaryEnd.ratedS !== primaryEnd.ratedS ||
      originalValues.secondaryEnd.phaseAngle !== secondaryEnd.phaseAngle ||
      originalValues.secondaryEnd.connectionKind !== secondaryEnd.connectionKind ||
      originalValues.secondaryEnd.ratedU !== secondaryEnd.ratedU ||
      originalValues.secondaryEnd.ratedS !== secondaryEnd.ratedS ||
      assetModelUpdated ||
      emergencyRatingsUpdated(
        selected.EndInfos[0]?.alternateLimitSet,
        emergencyRatings,
        supportedEMFields,
      )
    );
  };

  shiftFactorValid = (isShiftFactorValid = false) =>
    this.setState({ isValidShiftFactor: isShiftFactorValid });

  formValid = () => {
    const { name, primaryEnd, secondaryEnd } = this.state;
    return (
      this.valuesUpdated(this.props.selected) &&
      name.trim().length > 0 &&
      parseFloat(primaryEnd.R) >= 0 &&
      parseFloat(primaryEnd.R0) >= 0 &&
      parseFloat(primaryEnd.X) >= 0 &&
      parseFloat(primaryEnd.X0) >= 0 &&
      // No load loss is optional
      (primaryEnd.noLoadLoss === undefined || parseFloat(primaryEnd.noLoadLoss) >= 0) &&
      primaryEnd.connectionKind &&
      parseFloat(primaryEnd.ratedS) > 0 &&
      parseFloat(primaryEnd.ratedU) > 0 &&
      secondaryEnd.connectionKind &&
      parseFloat(secondaryEnd.ratedS) > 0 &&
      parseFloat(secondaryEnd.ratedU) > 0 &&
      [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330].includes(
        parseFloat(secondaryEnd.phaseAngle),
      ) &&
      isAssetModelValid(this.state.assetModel) &&
      this.state.isValidShiftFactor &&
      isValidEmergencyRatings(this.state.emergencyRatings)
    );
  };

  validAngle = ({ id, value }, i) => {
    this.handleInputChange({ id, value }, i);
    let invalidAngle = false;
    if (value < 0 || value > 330 || value % 30 > 0) {
      invalidAngle = true;
    }
    this.setState({
      invalidAngle,
    });
  };

  updateAssetModel = prop =>
    this.setState(prevState => ({
      assetModel: {
        ...prevState.assetModel,
        ...prop,
      },
    }));

  render() {
    const { permissions, isAuthEnabled, match, createInstanceReq } = this.props;

    const isDisabled =
      isAuthEnabled &&
      ((this.state.id === 'add' && !permissions.has('create_equipment_type')) ||
        (this.state.id !== 'add' && !permissions.has('edit_equipment_type')) ||
        (match.params.branch === 'master' && !permissions.has('modify_network_as_built')));
    const { name, primaryEnd, secondaryEnd } = this.state;

    const ends = [
      this.createEndDisplayObjects(primaryEnd),
      this.createEndDisplayObjects(secondaryEnd),
    ];

    const defaultProps = {
      required: true,
      disabled: isDisabled,
    };

    return (
      <PanelTabs
        createInstanceReq={createInstanceReq}
        tabs={[
          'General',
          'Impedance',
          'Description',
          'Costs',
          'Reliability Metrics',
          'Emergency Ratings',
        ]}
        showSave
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleCreate}
        assetID={this.state.id}
      >
        {[
          <div className="equipment-info-container" key={this.state.id} id="general-tab">
            <div className="right-panel">
              <div className="transformer-name">
                <TextInput
                  id="name"
                  label="Name"
                  value={name}
                  onChange={e => this.setState({ name: e.target.value })}
                  required
                  disabled={isDisabled}
                  inputWidth="225px"
                />
              </div>
              <div className="end-info-panels">
                <div id="primaryEnd" key="primaryEnd" className="end-panel end-panel-first">
                  <h2>Primary End</h2>
                  <SelectRow
                    {...ends[0].connectionKind}
                    options={connectionKinds}
                    disabled={isDisabled}
                    onChange={e => this.handleConnectionChange(e, 0)}
                    isRequired
                  />
                  <NumberInput
                    disabled={isDisabled}
                    {...ends[0].noLoadLoss}
                    onChange={({ id, value }) => {
                      this.handleInputChange({ id, value }, 0);
                    }}
                    ge={0}
                    inputStyle="eq-lib"
                  />
                  <NumberInput
                    {...defaultProps}
                    {...ends[0].ratedU}
                    onChange={({ id, value }) => {
                      this.handleInputChange({ id, value }, 0);
                    }}
                    nonZero
                    required
                    inputStyle="eq-lib"
                  />
                  <NumberInput
                    {...defaultProps}
                    {...ends[0].ratedS}
                    onChange={({ id, value }) => {
                      this.handleInputChange({ id, value }, 0);
                    }}
                    nonZero
                    required
                    inputStyle="eq-lib"
                  />
                </div>
                <div id="secondaryEnd" key="secondaryEnd" className="end-panel">
                  <h2>Secondary End</h2>
                  <SelectRow
                    {...ends[1].connectionKind}
                    options={connectionKinds}
                    disabled={isDisabled}
                    onChange={e => this.handleConnectionChange(e, 1)}
                    isRequired
                  />
                  <NumberInput
                    {...defaultProps}
                    {...ends[1].phaseAngle}
                    onChange={({ id, value }) => {
                      this.validAngle({ id, value }, 1);
                    }}
                    step="30"
                    invalid={this.state.invalidAngle}
                    validationMessage="Value must be a multiple of 30 in the range [0, 330]"
                    inputStyle="eq-lib"
                  />
                  <NumberInput
                    {...defaultProps}
                    {...ends[1].ratedU}
                    onChange={({ id, value }) => {
                      this.handleInputChange({ id, value }, 1);
                    }}
                    nonZero
                    required
                    inputStyle="eq-lib"
                  />
                  <NumberInput
                    {...defaultProps}
                    {...ends[1].ratedS}
                    onChange={({ id, value }) => {
                      this.handleInputChange({ id, value }, 1);
                    }}
                    nonZero
                    required
                    inputStyle="eq-lib"
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown escapeHtml source={decodeURIComponent(this.state.description)} />
              </div>
            </div>
          </div>,
          <div
            className="equipment-info-container"
            key={`${this.state.id}-impedance`}
            id="impedance-tab"
          >
            <ImpedancePanel
              disabled={isDisabled}
              endInfo={primaryEnd}
              onImpedanceChange={values => this.handleImpedanceChange(values)}
            />
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={
              areAssetModelPropertiesChanged(
                this.state.assetModel,
                getAssetModelProperties(this.props.selected?.AssetModel),
              )[1]
            }
            key={`${this.state.id}-costs`}
            onChange={prop =>
              this.setState(prevState => ({
                assetModel: { ...prevState.assetModel, ...prop },
              }))
            }
            isDisabled={isDisabled}
          />,
          <ReliabilityMetrics
            assetModel={this.state.assetModel || null}
            probabilityOfFailureEquation={
              this.state.assetModel.AssetFailureInfo.probabilityOfFailureEquation || null
            }
            mTTR={this.state.assetModel.AssetFailureInfo.mTTR}
            key={`${this.state.id}-reliability`}
            onChange={this.updateAssetModel}
            isDisabled={isDisabled}
            saveLibraryError={this.props.saveLibraryError}
            shiftFactorValid={this.shiftFactorValid}
          />,
          <EmergencyRatings
            key={`${this.state.id}-emergency-ratings`}
            defaultProps={defaultProps}
            onChange={(arg, limitField) =>
              this.setState(prevState =>
                updateEmRatings(arg, limitField, prevState, scaleFactorForUnit(kVA)),
              )
            }
            emergencyRatings={this.state.emergencyRatings}
            supportedFields={['emApparentPower']}
          />,
        ]}
      </PanelTabs>
    );
  }
}

TransformerPanel.defaultProps = {
  selected: undefined,
  saveLibraryError: {},
};

TransformerPanel.propTypes = {
  selected: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  saveLibraryError: PropTypes.object,
};

export default TransformerPanel;
