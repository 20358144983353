import IdentifiedObject from '../IdentifiedObject';

class AssetModel extends IdentifiedObject {
  get AssetInfo() {
    const assetInfoId = this.references['AssetModel.AssetInfo'];
    return assetInfoId ? this.cimDict[assetInfoId] : null;
  }
}

export default AssetModel;
