import CIMObject from './CIMObject';

class IdentifiedObject extends CIMObject {
  get description() {
    return this.attributes['IdentifiedObject.description'] || '';
  }

  get name() {
    const name = this.attributes['IdentifiedObject.name'];
    return name || this.id;
  }
}

export default IdentifiedObject;
