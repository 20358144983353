import SynchronousMachine from './SynchronousMachine';

class CHP extends SynchronousMachine {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'CHP';
    this._class = 'CHP';
    this.displayName = 'Combined Heat & Power';
  }
}

export default CHP;
