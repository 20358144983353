import React, { ReactChild, FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';
import { ThemeProp } from '../../types/index';

import './Button.scss';

type ButtonProps = {
  disabled?: boolean;
  type?: 'primary' | 'secondary' | 'text' | 'text-blue' | 'tour' | 'info' | 'hollow';
  width?: string;
  height?: string;
  loading?: boolean;
  label?: string;
  circular?: boolean;
  square?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void | null | Promise<void>;
  title?: string;
  arrowType?: 'up' | 'down';
  className?: string;
  theme?: ThemeProp;
  children?: ReactChild | ReactNode;
  id?: string;
};

const Button: FunctionComponent<ButtonProps> = ({
  onClick,
  disabled = false,
  type = 'primary',
  width = '',
  height = '',
  loading = false,
  className = '',
  label = '',
  children = null,
  circular = false,
  square = true,
  title = '',
  arrowType,
  id = '',
}) => (
  <button
    className={classNames(
      'custom-btn',
      `${type}-btn`,
      className,
      { 'circular-type': circular },
      { 'square-type': square },
    )}
    style={{ width, height }}
    disabled={disabled}
    onClick={onClick}
    title={title}
    id={id}
    type="button"
  >
    {label || children}
    {loading && <i className="material-icons rotate"> autorenew </i>}
    {arrowType === 'down' && <i className="material-icons arrow_down">keyboard_arrow_down </i>}
    {arrowType === 'up' && <i className="material-icons arrow_up">keyboard_arrow_up </i>}
  </button>
);

export default Button;
