import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { powerflowActions } from '../modules/powerflow';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ViolationsPanel from '../components/SlidingInfoPanel/ViolationsPanel/ViolationsPanel';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(powerflowActions, dispatch),
});

const mapStateToProps = state => ({
  violationsSelected: state.powerflow.violationsSelected,
  violations: state.powerflow.GISViolations,
  violationDisplayMin: state.powerflow.violationDisplayMin,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViolationsPanel);
