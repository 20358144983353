import React from 'react';
import PropTypes from 'prop-types';

const AlertMessage = ({ alertMessage, clearAlert }) => {
  const handleConfirm = () => {
    if (alertMessage.onConfirm) {
      alertMessage.onConfirm();
    }
    clearAlert();
  };

  return (
    <div className="alert-message">
      <div className="alert-message-header">
        <h2 className="alert-message-title">{alertMessage.title}</h2>
      </div>
      <div className="alert-message-contents">
        <p>{alertMessage.message}</p>
      </div>
      <div className="alert-message-footer">
        {alertMessage.onRetry && (
          <button
            className="retry-btn"
            onClick={() => {
              alertMessage.onRetry();
              clearAlert();
            }}
            type="button"
          >
            Retry
          </button>
        )}
        <button className="dismiss-btn" onClick={handleConfirm} type="button">
          OK
        </button>
      </div>
    </div>
  );
};

AlertMessage.propTypes = {
  alertMessage: PropTypes.object.isRequired,
  clearAlert: PropTypes.func.isRequired,
};

export default AlertMessage;
