import React from 'react';
import PropTypes from 'prop-types';
import './EquipmentTypeTile.scss';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';

const AddEquipmentTile = props => {
  const { selected, addEquipment, equipmentType, disableCreate } = props;
  const dataSelected = selected ? 'equipment-select-row__selected' : '';
  return (
    <div className={`equipment-select-row ${dataSelected}`}>
      <Tooltip
        content={
          disableCreate
            ? 'Unable to add new type on As built version when automatic Import is enabled'
            : ''
        }
      >
        <Button
          title="Add"
          className="equipment-type-tile"
          onClick={() => addEquipment(equipmentType, 'add')}
          disabled={disableCreate}
          type="text"
        >
          <span className="add-info-icon">
            <i className="material-icons">add</i>
          </span>
          <p className="equipment-type-label">Add New</p>
        </Button>
      </Tooltip>
    </div>
  );
};

AddEquipmentTile.defaultProps = {
  addEquipment: null,
  selected: false,
};

AddEquipmentTile.propTypes = {
  equipmentType: PropTypes.string.isRequired,
  addEquipment: PropTypes.func,
  selected: PropTypes.bool,
  disableCreate: PropTypes.bool.isRequired,
};
export default AddEquipmentTile;
