import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import { isDefined } from 'helpers/utils';

import Helpers from '../../helpers/EquipmentLibraryHelpers';
import SelectRow from '../SelectRow';
import DescriptionEditor from './DescriptionEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

const newCustomerClass = {
  id: 'add',
  name: '',
  description: '',
  loadModel: 'exponentModel',
  pVoltageExponent: '0.0',
  qVoltageExponent: '0.0',
  pConstantCurrent: '25.0',
  pConstantImpedance: '25.0',
  pConstantPower: '50.0',
  qConstantCurrent: '0.0',
  qConstantImpedance: '0.0',
  qConstantPower: '0.0',
};

const loadModelTypes = [
  { value: 'exponentModel', label: 'Exponent Model' },
  { value: 'compositeModel', label: 'Composite Model' },
];

class CustomerClassPanel extends Component {
  state = { ...newCustomerClass };

  UNSAFE_componentWillMount() {
    this.setState({ ...this.extractFormValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.setState({ ...this.extractFormValues(nextProps.selected) });
    }
  }

  extractFormValues = instance => {
    let asset = { ...newCustomerClass };
    if (instance) {
      const {
        name,
        description,
        id,
        exponentModel,
        pVoltageExponent,
        qVoltageExponent,
        pConstantCurrent,
        pConstantImpedance,
        pConstantPower,
      } = instance;
      asset = {
        ...newCustomerClass,
        loadModel: exponentModel ? 'exponentModel' : 'compositeModel',
        pVoltageExponent: isDefined(pVoltageExponent)
          ? `${pVoltageExponent}`
          : asset.pVoltageExponent,
        qVoltageExponent: isDefined(qVoltageExponent)
          ? `${qVoltageExponent}`
          : asset.qVoltageExponent,
        pConstantCurrent: isDefined(pConstantCurrent)
          ? `${pConstantCurrent * 100}`
          : asset.pConstantCurrent,
        pConstantImpedance: isDefined(pConstantImpedance)
          ? `${pConstantImpedance * 100}`
          : asset.pConstantImpedance,
        pConstantPower: isDefined(pConstantPower)
          ? `${pConstantPower * 100}`
          : asset.pConstantPower,
        name,
        description,
        id,
      };
    }
    return asset;
  };

  handleInputChange = ({ id, value }) => this.setState({ [id]: value });

  handleSelectChange = (id, value) => this.setState({ [id]: value });

  handleCreate = () => {
    const {
      name,
      description,
      loadModel,
      pVoltageExponent,
      qVoltageExponent,
      pConstantCurrent,
      pConstantImpedance,
      pConstantPower,
    } = this.state;
    const diffModel = {
      exponentModel: loadModel === 'exponentModel',
      pVoltageExponent: parseFloat(pVoltageExponent),
      qVoltageExponent: parseFloat(qVoltageExponent),
      pConstantCurrent: parseFloat(pConstantCurrent) / 100,
      pConstantImpedance: parseFloat(pConstantImpedance) / 100,
      pConstantPower: parseFloat(pConstantPower) / 100,
      qConstantCurrent: parseFloat(pConstantCurrent) / 100,
      qConstantImpedance: parseFloat(pConstantImpedance) / 100,
      qConstantPower: parseFloat(pConstantPower) / 100,
      name,
      description,
    };

    if (this.state.id === 'add') {
      this.props.handleCreate(
        'load_response_characteristic',
        diffModel,
        'LoadResponseCharacteristic',
      );
    } else {
      // Only create difference model for values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        if (diffModel[key] !== selected[key]) {
          diff[key] = diffModel[key];
        }
        return diff;
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  };

  valuesUpdated = selected => {
    if (!selected) return true;

    const {
      name,
      description,
      pVoltageExponent,
      qVoltageExponent,
      pConstantCurrent,
      pConstantImpedance,
      pConstantPower,
    } = this.state;

    return (
      selected.name !== name || selected.description !== description,
      selected.pVoltageExponent !== parseFloat(pVoltageExponent) ||
        selected.qVoltageExponent !== parseFloat(qVoltageExponent) ||
        selected.pConstantCurrent !== parseFloat(pConstantCurrent) / 100 ||
        selected.pConstantImpedance !== parseFloat(pConstantImpedance) / 100 ||
        selected.pConstantPower !== parseFloat(pConstantPower) / 100
    );
  };

  compositeValidationMsg = () => {
    if (this.state.loadModel === 'compositeModel') {
      const iValid =
        parseFloat(this.state.pConstantCurrent) >= 0.0 &&
        parseFloat(this.state.pConstantCurrent) <= 100.0;
      const zValid =
        parseFloat(this.state.pConstantImpedance) >= 0.0 &&
        parseFloat(this.state.pConstantImpedance) <= 100.0;
      const pValid =
        parseFloat(this.state.pConstantPower) >= 0.0 &&
        parseFloat(this.state.pConstantPower) <= 100.0;

      const compositeSum =
        parseFloat(this.state.pConstantCurrent) +
        parseFloat(this.state.pConstantImpedance) +
        parseFloat(this.state.pConstantPower);
      const validSum = compositeSum === 100;

      const sumValidationMsg = validSum ? null : 'Sum of %Z, %I and %PQ must be 100.';
      const rangeValidationMsg = 'Value must be between 0 and 100.';

      return {
        pConstantCurrent: iValid ? sumValidationMsg : rangeValidationMsg,
        pConstantImpedance: zValid ? sumValidationMsg : rangeValidationMsg,
        pConstantPower: pValid ? sumValidationMsg : rangeValidationMsg,
      };
    }
    return null;
  };

  compositeValid = () => {
    if (this.state.loadModel === 'compositeModel') {
      const isValid =
        !this.compositeValidationMsg().pConstantCurrent &&
        !this.compositeValidationMsg().pConstantImpedance &&
        !this.compositeValidationMsg().pConstantPower;
      return isValid;
    }
    return true;
  };

  exponentValid = () => {
    if (this.state.loadModel === 'exponentModel') {
      const isValid =
        parseFloat(this.state.pVoltageExponent) >= 0.0 &&
        parseFloat(this.state.qVoltageExponent) >= 0.0;
      return isValid;
    }
    return true;
  };

  formValid = () =>
    this.state.name.trim().length > 0 &&
    this.valuesUpdated(this.props.selected) &&
    this.exponentValid() &&
    this.compositeValid();

  render() {
    const isDisabled =
      this.props.isAuthEnabled &&
      ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type')) ||
        (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type')) ||
        (this.props.match.params.branch === 'master' &&
          !this.props.permissions.has('modify_network_as_built')));
    const defaultProps = {
      onChange: this.handleInputChange,
      disabled: isDisabled,
      inputWidth: '225px',
    };

    // 0 for these values is not valid
    const loadModel = Helpers.createDisplayObject('Model', 'loadModel', this.state.loadModel);
    const pVoltageExponent = Helpers.createDisplayObject(
      'P Exponent',
      'pVoltageExponent',
      this.state.pVoltageExponent,
    );
    const qVoltageExponent = Helpers.createDisplayObject(
      'Q Exponent',
      'qVoltageExponent',
      this.state.qVoltageExponent,
    );
    const pConstantCurrent = Helpers.createDisplayObject(
      '% Constant-I',
      'pConstantCurrent',
      this.state.pConstantCurrent,
    );
    const pConstantImpedance = Helpers.createDisplayObject(
      '% Constant-Z',
      'pConstantImpedance',
      this.state.pConstantImpedance,
    );
    const pConstantPower = Helpers.createDisplayObject(
      '% Constant-PQ',
      'pConstantPower',
      this.state.pConstantPower,
    );

    const invalidComposite = this.compositeValidationMsg();

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleCreate}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id} id="general-tab">
            <div className="right-panel">
              <TextInput
                {...defaultProps}
                onChange={({ target }) =>
                  this.handleInputChange({ id: target.id, value: target.value })
                }
                id="name"
                label="Name"
                value={this.state.name}
                required
              />
              <SelectRow
                {...loadModel}
                {...defaultProps}
                options={loadModelTypes}
                disabled={isDisabled}
                onChange={e => this.handleSelectChange(loadModel.id, e.value)}
              />
              {this.state.loadModel === 'exponentModel' && (
                <>
                  <NumberInput
                    {...defaultProps}
                    {...pVoltageExponent}
                    ge={0}
                    required
                    inputStyle="eq-lib"
                  />
                  <NumberInput
                    {...defaultProps}
                    {...qVoltageExponent}
                    ge={0}
                    required
                    inputStyle="eq-lib"
                  />
                </>
              )}
              {this.state.loadModel === 'compositeModel' && (
                <>
                  <NumberInput
                    {...defaultProps}
                    {...pConstantCurrent}
                    invalid={!!invalidComposite.pConstantCurrent}
                    validationMessage={invalidComposite.pConstantCurrent}
                    inputStyle="eq-lib"
                  />
                  <NumberInput
                    {...defaultProps}
                    {...pConstantImpedance}
                    invalid={!!invalidComposite.pConstantImpedance}
                    validationMessage={invalidComposite.pConstantImpedance}
                    inputStyle="eq-lib"
                  />
                  <NumberInput
                    {...defaultProps}
                    {...pConstantPower}
                    invalid={!!invalidComposite.pConstantPower}
                    validationMessage={invalidComposite.pConstantPower}
                    inputStyle="eq-lib"
                  />
                </>
              )}
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown escapeHtml source={decodeURIComponent(this.state.description)} />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
        ]}
      </PanelTabs>
    );
  }
}

CustomerClassPanel.defaultProps = {
  selected: undefined,
};

CustomerClassPanel.propTypes = {
  selected: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default CustomerClassPanel;
