import React, { FunctionComponent, useContext } from 'react';
import { ThemeProp } from 'types/index';
import Select from 'components/Select';
import Button from 'components/Button';
import { useAuthContext, useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import ScenarioSelect from 'routes/WorkspaceLayout/NetworkTopNav/ScenarioSelect';
import Schedules from './Schedules';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import { views, modalTypes, ExternalScenarioDataType } from '../helpers/ScenarioGenerationHelpers';
import AssetSchedules from './AssetSchedules';
import ExternalSourceModal from './ExternalSourceModal';
import SeasonalRatings from './SeasonalRatings';
import PrioritySelector from './PrioritySelector';

type ScenarioSchedulesProps = {
  workspace: string;
  branch: string;
  theme: ThemeProp;
};

const ScenarioSchedules: FunctionComponent<ScenarioSchedulesProps> = ({
  theme,
  workspace,
  branch,
}) => {
  const {
    selectedScenario,
    setSelectedScenario,
    feedersInScenario,
    view,
    setView,
    selectedContainer,
    setSelectedContainer,
    scenarios,
    resetScenario,
    setModalActiveType,
    scenariosLoading,
    modalActiveType,
  } = useContext(ScenarioGenerationContext);
  const { analyticsConfig, settings } = useContext(WorkspaceSettingsContext);
  const FeederOptions =
    feedersInScenario?.map(container => ({ value: container.id, label: container.name })) || [];
  const { permissions } = useAuthContext();
  const {
    data: externalScenarioData,
    refetch: refetchExternalScenarioData,
    loading: loadingExternalSceData,
  } = useRequestEffect<ExternalScenarioDataType>({
    url: `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${selectedScenario?.value}/schedule-source`,
    method: 'get',
    refetchOnChange: [workspace, branch, selectedScenario],
    blockRequest: () => !(workspace && branch && selectedScenario),
  });
  return (
    <div data-test="scenario-schedules" className={`scenario-schedules ${theme}`}>
      {view !== views.scenario && (
        <>
          <Button
            type="text"
            onClick={() => setView(views.scenario)}
            className="pink-underline-section mb-10"
          >
            Back to view scenarios
          </Button>
        </>
      )}
      <div className="flex-vertical-center scenario-header">
        <ScenarioSelect
          className="scenario-select"
          scenario={selectedScenario?.value ?? null}
          scenarios={scenarios}
          onScenarioChange={scenarioId => {
            if (scenarioId) {
              const sc = scenarios.find(({ value: val }) => val === scenarioId);
              if (sc) {
                setSelectedScenario(sc);
              }
            } else {
              resetScenario();
            }
            setSelectedContainer(null);
          }}
          theme={theme}
          type="secondary"
          label="Scenario"
          loading={scenariosLoading}
          disableToSelectScenario={branch === 'master' && settings.automaticImport}
        />
        {view !== views.scenario && (
          <Select
            theme={theme}
            options={FeederOptions}
            onChange={(e: { value: string; label: string } | null) =>
              setSelectedContainer(e?.value ?? null)
            }
            value={selectedContainer}
            label="Feeder"
            type="secondary"
            width={240}
          />
        )}
        {permissions.has('view_qsts_scenarios') && permissions.has('alter_qsts_scenarios') && (
          <>
            <PrioritySelector
              externalScenarioData={externalScenarioData}
              refetchExternalScenarioData={refetchExternalScenarioData}
              loadingExternalSceData={loadingExternalSceData}
              workspace={workspace}
              branch={branch}
            />
            <div className="flex-vertical-center" style={{ justifyContent: 'flex-end' }}>
              <Button
                theme={theme}
                label="Update external URL"
                type="info"
                onClick={() => setModalActiveType(modalTypes.externalSource)}
                disabled={!selectedScenario || !analyticsConfig?.schedule_sources}
                className="external-schedule"
              />
            </div>
          </>
        )}
      </div>
      {modalActiveType === modalTypes.externalSource && (
        <ExternalSourceModal
          externalScenarioData={externalScenarioData}
          workspace={workspace}
          branch={branch}
        />
      )}
      {selectedScenario && (
        <div>
          {view !== views.scenario && (
            <div className="section-title">
              <div className="flex-row">
                <Button
                  type="text"
                  onClick={() => setView(views.assetSchedule)}
                  className={`${view === views.assetSchedule && 'pink-underline-section'}`}
                >
                  Asset Schedules
                </Button>
                <Button
                  type="text"
                  onClick={() => setView(views.seasonalRatings)}
                  className={`${view === views.seasonalRatings && 'pink-underline-section'}`}
                >
                  Seasonal ratings
                </Button>
              </div>
            </div>
          )}
          <div>
            {view === views.assetSchedule && selectedContainer && (
              <AssetSchedules
                workspace={workspace}
                branch={branch}
                theme={theme}
                selectedContainer={selectedContainer}
              />
            )}
            {view === views.scenario && (
              <Schedules workspace={workspace} branch={branch} theme={theme} />
            )}
            {view === views.seasonalRatings && selectedContainer && (
              <SeasonalRatings theme={theme} workspace={workspace} branch={branch} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ScenarioSchedules;
