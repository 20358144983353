import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appStateReducer from 'store/appState';
import { networkEditActions } from 'routes/WorkspaceLayout/routes/Network/modules/networkEdit';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import EquipmentInfoSelector from '../EquipmentInfoSelector';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...appStateReducer.actions,
      ...networkEditActions,
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  inEditMode: state.edit.inEditMode,
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentInfoSelector);
