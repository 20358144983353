import IdentifiedObject from './IdentifiedObject';
import { getConnectivityNodeLocation } from './locationHelpers';

// Base class for all JSCIM assets with terminals (displayed assets)
class ConductingEquipment extends IdentifiedObject {
  get phase() {
    return this.cimDict[this.terminals[0]].attributes['Terminal.phases'];
  }

  get terminals() {
    const sequenceNumber = 'ACDCTerminal.sequenceNumber';
    const terminalList = this.references['ConductingEquipment.Terminals'];
    if (!terminalList || !terminalList.length) return [];
    return terminalList.sort((a, b) => {
      const aSeqNum = this.cimDict[a].attributes[sequenceNumber];
      const bSeqNum = this.cimDict[b].attributes[sequenceNumber];
      if (aSeqNum < bSeqNum) {
        return -1;
      }
      if (aSeqNum > bSeqNum) {
        return 1;
      }
      return 0;
    });
  }

  // SV ID is a string with the terminal ids sorted alphabetically separated by
  // a `/`
  get linkDeviceTerminalID() {
    const terminalList = this.references['ConductingEquipment.Terminals'];
    if (!terminalList || !terminalList.length) return null;
    const sortedIDs = terminalList.sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    return sortedIDs.join('/');
  }

  get hasReducedTopology() {
    const topologicalNodes = this.terminals
      .map(t => this.cimDict[t])
      .map(terminal => terminal.references['Terminal.TopologicalNode']);

    if (topologicalNodes.length === 0 || topologicalNodes.some(x => x === undefined)) {
      // If no topological nodes, OR if any are not defined then we don't have full topology
      // and thus cannot give an answer
      return false;
    }

    // If reduced, then all topological nodes are the same
    return topologicalNodes.every(tn => tn === topologicalNodes[0]);
  }

  get nodes() {
    const terms = this.terminals;
    return terms.map(term => {
      const node = this.cimDict[term].references['Terminal.ConnectivityNode'];
      if (Array.isArray(node)) {
        return node[0];
      }
      return node;
    });
  }

  get feeder() {
    let container = this.references['Equipment.EquipmentContainer'];
    // We need to also handle the difference model case where the feeder
    // id is new / has been updated
    if (container && Array.isArray(container)) {
      [container] = container;
    }
    return this.cimDict[container] ? container : undefined;
  }

  // Sort position points by their sequence numbers
  sortPositionPoints(points) {
    const sequenceKey = 'PositionPoint.sequenceNumber';
    const firstPoint = points.filter(p => !p[sequenceKey]);
    const sequencePoints = points.filter(p => p[sequenceKey]);
    if (sequencePoints.length > 1) {
      const sortedPoints = sequencePoints.sort((a, b) => a[sequenceKey] - b[sequenceKey]);
      return firstPoint.concat(sortedPoints);
    }
    return firstPoint.concat(sequencePoints);
  }

  // Get a list containing the IDs of the asset's position points
  get _positionPoints() {
    const connectivityNodes = this.terminals.map(
      terminal => this.cimDict[terminal].references['Terminal.ConnectivityNode'],
    );
    const locations = connectivityNodes.map(node =>
      getConnectivityNodeLocation(node, this.cimDict),
    );
    const positions = locations.map(location => location.references['Location.PositionPoints'][0]);
    return positions;
  }
}

export default ConductingEquipment;
