import React, { useEffect, useState, FunctionComponent, useContext } from 'react';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import DropdownMenu from 'components/DropdownMenu';
import Button from 'components/Button';
import asyncActionStates from 'helpers/asyncActionStates';
import DelayedModal from 'components/DelayedModal';
import { MODAL_TYPES, NEW_MODAL_TYPES } from 'helpers/EditMode';
import browserHistory from 'routes/history';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { ImportsContext } from 'routes/ImportCIM/modules/importsContext';
import { ACTIVITY_LOG_STATUS } from 'routes/WorkspaceLayout/routes/Network/helpers/NetworkHelpers';
import { userCanEdit } from 'routes/WorkspaceLayout/routes/Network/helpers/EditHelpers';
import IconButton from 'components/IconButton';
import IconMessage from 'components/IconMessage';
import { disableMasterWithImportON } from 'helpers/utils';
import { Analysis } from 'types/analysis';

import BranchSection from './BranchSection';
import EditModeRibbon from './containers/EditModeRibbonContainer';
import ImportWizardNav from './ImportWizardNav';
import ScenarioSection from './containers/ScenarioSectionContainer';
import AnalysisSection from './containers/AnalysisSectionContainer';
import WorkspaceSection from './WorkspaceSection';
import ProjectSection from './ProjectSection';

import './NetworkTopNav.scss';
import HamburgerMenu from './HamburgerMenu';

type NetworkTopNavProps = {
  params: {
    workspace: string;
    branch: string;
    equipmentId: string;
    equipmentType: string;
    scenario?: string;
    analysis?: string;
  };
  actions: {
    fetchBranches: (wp: string) => void;
    displayAlertMessage: (title: string, body: string) => void;
    setSaveModal: (
      arg1: boolean,
      type: string | null,
      id?: string | null,
      link?: string | null,
    ) => void;
    checkCanMergeBranch: () => void;
    setView: (id: string) => void;
    toggleEditMode: () => void;
    createBranch: (wp: string, editbranch: string, branch: string, notes?: string) => void;
    updateSelectedBranch: (branch: string) => void;
    deleteBranch: (wp: string, br: string) => void;
    setSelectedProject: (id: string) => void;
    updateSelectedScenario: (
      wp: string,
      branch: string,
      scenario: string,
      type: string | null,
      analysis: string | null,
      permissions: Set<string> | { has: () => boolean },
    ) => void;
    setSelectedAnalysis: (
      arg: Analysis | string | null,
      permissions: Set<string> | { has: () => boolean },
    ) => void;
  };
  permissions: Set<string>;
  isAuthEnabled: boolean;
  branches: [];
  newBranchReq: number;
  theme: string;
  inEditMode: boolean;
  addAssetRequest: number;
  editAssetRequest: number;
  editBranchReq: number;
  saveEditBranchReq: number;
  location: { pathname: string };
  view: string;
  selectedScenario: string;
  selectedAnalysis: string | null;
  scenarios: { value: string }[];
  selectedProject: number | null;
  saveModalActive: boolean;
  saveModalType: string | null;
  newView: string | null;
  newLink: string | null;
  canMergeBranch: boolean;
  displayBranch: string;
};
const { PENDING, RUNNING, CANCELING, POSTPROCESSING } = ACTIVITY_LOG_STATUS;

const NetworkTopNav: FunctionComponent<NetworkTopNavProps> = ({
  actions,
  params,
  view,
  location,
  inEditMode,
  branches = [],
  newBranchReq = 0,
  scenarios = [],
  addAssetRequest = 0,
  editAssetRequest = 0,
  editBranchReq = 0,
  saveEditBranchReq = 0,
  selectedScenario = '',
  canMergeBranch = true,
  permissions,
  isAuthEnabled,
  theme,
  selectedProject,
  saveModalActive,
  saveModalType,
  newView,
  newLink,
  displayBranch,
}) => {
  const [exitModalActive, setExitModalActive] = useState(false);
  const [saveAsOptionsOpen, setSaveAsOptionsOpen] = useState(false);
  const [selectedSaveAsOption, setSelectedSaveAsOption] = useState<string | null>(null);
  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [newBranchName, setNewBranchName] = useState(null);
  const [newWorkspaceName, setNewWorkspaceName] = useState(null);
  const { ERROR } = asyncActionStates;

  const { logEntries } = useContext(ActivityLogContext);
  const { settings } = useContext(WorkspaceSettingsContext);
  const {
    imports: { pending },
    initialized,
  } = useContext(ImportsContext);

  const getRoutes = (): { [key: string]: string } => {
    const branch = params.branch || 'master';
    return {
      gis: `/${params.workspace}/${branch}/gis`,
      apiDocs: '/api-docs',
      cim: `/${params.workspace}/${branch}/cim`,
      import: `/${params.workspace}/${branch}/import`,
      library: `/${params.workspace}/${branch}/library`,
      workspaces: `/?canGoBack=true&currentWorkspace=${params.workspace}`,
      comparison: `/${params.workspace}/${branch}/comparison`,
      overview: `/${params.workspace}/workspace_overview`,
      scenario: `/${params.workspace}/${branch}/scenario`,
    };
  };
  const setDefaultView = () => {
    let currentView = Object.keys(getRoutes()).find(route => location.pathname.includes(route));
    if (currentView === undefined) currentView = 'overview';
    actions.setView(currentView);
  };

  useEffect(() => {
    actions.fetchBranches(params.workspace);
    if (!view) {
      setDefaultView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, params.workspace]);

  useEffect(() => {
    if (newBranchReq === ERROR) {
      actions.displayAlertMessage(
        'Version Creation Failed',
        'An error occured while creating a new network version. Please try again.',
      );
    }
  }, [ERROR, actions, newBranchReq]);

  useEffect(() => {
    if (editBranchReq === asyncActionStates.ERROR && exitModalActive) {
      setExitModalActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editBranchReq]);

  useEffect(() => {
    if (
      params.scenario &&
      (!selectedScenario || selectedScenario === '') &&
      scenarios.length > 0 &&
      scenarios?.map(sc => sc.value)?.includes(params.scenario)
    ) {
      actions.updateSelectedScenario(
        params.workspace,
        params.branch,
        params.scenario,
        null,
        params.analysis ?? null,
        permissions,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.workspace, params.branch, scenarios]);

  const hasPendingImports = () => pending?.length || !initialized;

  const disabledMessage = (analysesInProgress: boolean) => {
    let disabledMsg = 'You do not have edit permissions';
    if (hasPendingImports()) {
      disabledMsg = 'You cannot enter edit mode while there are pending imports in the workspace';
    }
    if (analysesInProgress) {
      disabledMsg = 'You cannot enter edit mode while analyses are in progress';
    }
    return disabledMsg;
  };

  const saveOptions = () => [
    {
      contents: 'Overwrite current version',
      id: NEW_MODAL_TYPES.OVERWRITE,
      type: 'standard',
      disabled:
        !canMergeBranch ||
        (displayBranch === 'master' && !permissions.has('modify_network_as_built')),
    },
    {
      contents: 'Save as new network version',
      id: NEW_MODAL_TYPES.SAVE_NEW_VERSION,
      type: 'standard',
      disabled: newBranchName === 'master' && !permissions.has('modify_network_as_built'),
    },
  ];

  const handleMenuSelection = (id: string) => {
    if (!inEditMode) {
      handleSwitchViews(id);
      actions.setSaveModal(false, null, id);
    } else {
      actions.setSaveModal(true, MODAL_TYPES.VIEW, id);
      actions.checkCanMergeBranch();
    }
    return true;
  };

  const handleSwitchViews = (id: string, link = null) => {
    const newViewLink = link || getRoutes()[id];
    browserHistory.push(newViewLink);
    actions.setView(id);
  };

  const handleSaveModalOpen = (modalType: string, newBrName = null, newWPName = null) => {
    setNewBranchName(newBrName);
    setNewWorkspaceName(newWPName);
    actions.setSaveModal(true, modalType);
    actions.checkCanMergeBranch();
  };

  const handleSelectSaveAs = (option: string | null) => {
    setSelectedSaveAsOption(option);
  };

  const closeSave = (cancel = false) => {
    actions.setSaveModal(false, null, newView, newLink);

    if (cancel) actions.setSaveModal(false, null, view);
  };

  const enterEditor = () => {
    if (!inEditMode) actions.toggleEditMode();
  };

  const canEnterEditMode = (analysesInProgress: boolean) => {
    const hasAlreadyPendingImports = hasPendingImports();
    const { branch } = params;
    const hasEditPermissions = !isAuthEnabled || userCanEdit(permissions, branch);
    const editingAvailableForBranch = branch !== 'master' || !settings.automaticImport;
    return (
      hasEditPermissions &&
      editingAvailableForBranch &&
      !hasAlreadyPendingImports &&
      !analysesInProgress
    );
  };
  const analysesInProgress =
    logEntries.filter(entry => [PENDING, RUNNING, CANCELING, POSTPROCESSING].includes(entry.status))
      .length > 0;
  const canEdit = canEnterEditMode(analysesInProgress);
  if (view === 'none') {
    return <div>loading</div>;
  }
  return (
    <div className="gis-top-nav">
      <HamburgerMenu
        closeMenu={() => setHamburgerMenuOpen(false)}
        onViewChange={value => handleMenuSelection(value)}
        currentView={view}
        menuActive={hamburgerMenuOpen}
      />
      <div className="gis-top-nav--container">
        <div className="gis-top-nav--left">
          <IconButton
            onClick={() => setHamburgerMenuOpen(true)}
            className="hamburger-icon"
            icon="menu"
          />
          <WorkspaceSection
            actions={actions}
            permissions={permissions}
            isAuthEnabled={isAuthEnabled}
            theme={theme}
            workspace={params.workspace}
            inEditMode={inEditMode}
            saveModalOpen={handleSaveModalOpen}
            handleMenuSelection={handleMenuSelection}
            params={params}
            view={view}
          />
          {['gis', 'library', 'import', 'scenario'].includes(view) && (
            <BranchSection
              workspace={params.workspace}
              branch={params.branch}
              branches={branches}
              newBranchReq={newBranchReq}
              createBranch={actions.createBranch}
              updateSelectedBranch={actions.updateSelectedBranch}
              deleteBranch={actions.deleteBranch}
              permissions={permissions}
              theme={theme}
              toggleEditMode={actions.toggleEditMode}
              displayAlertMessage={actions.displayAlertMessage}
              view={view}
              inEditMode={inEditMode}
              saveModalOpen={handleSaveModalOpen}
              title="Network Version"
              disableToEditMaster={inEditMode && settings.automaticImport}
            />
          )}
          {view === 'gis' && !inEditMode && (
            <div className="scenario-analysis" id="scenario-analysis">
              <ScenarioSection
                workspace={params.workspace}
                canCreate={
                  (!isAuthEnabled || permissions.has('alter_qsts_scenarios')) &&
                  !(params.branch === 'master' && settings.automaticImport)
                }
                canDelete={
                  (!isAuthEnabled || permissions.has('delete_qsts_scenarios')) &&
                  !analysesInProgress
                }
                canEdit={
                  (!isAuthEnabled || permissions.has('alter_qsts_scenarios')) && !analysesInProgress
                }
                view={view}
                scenario={selectedScenario}
                scenarios={scenarios}
                disableToSelectScenario={params.branch === 'master' && settings.automaticImport}
              />
              <AnalysisSection />
            </div>
          )}
          {view === 'gis' && inEditMode && (
            <div className="scenario-analysis" id="scenario-analysis">
              <ProjectSection
                workspace={params.workspace}
                selectedProject={selectedProject}
                setSelectedProject={actions.setSelectedProject}
              />
            </div>
          )}
          {view === 'import' && (
            <ImportWizardNav
              branch={params.branch}
              path={location.pathname}
              workspace={params.workspace}
            />
          )}
        </div>
        <div id="edit-mode-toggle" className="edit-mode-toggle">
          {['gis', 'scenario', 'library'].includes(view) &&
            disableMasterWithImportON(params.branch, settings.automaticImport) && (
              <div className="auto-import-notify">
                <IconMessage message="Automatic network ingestion is on" />
              </div>
            )}
          {view === 'gis' && (
            <PermissionDisabledTip
              key="editor-mode"
              title="Editor Mode"
              hide={canEdit}
              theme={theme}
              message={disabledMessage(analysesInProgress)}
            >
              {!inEditMode && (
                <Button
                  type="info"
                  onClick={enterEditor}
                  label="Edit network model"
                  disabled={!canEdit}
                  id="edit-network-model"
                />
              )}
              {inEditMode && (
                <div className="flex-spaced-row">
                  <Button
                    type="hollow"
                    onClick={() => handleSelectSaveAs(NEW_MODAL_TYPES.DISCARD_CHANGES)}
                    label="Cancel"
                    id="discard-changes-btn"
                  />
                  <DropdownMenu
                    dropDownIcon={false}
                    id="expanded-nav-menu"
                    className="nav-menu"
                    open={saveAsOptionsOpen}
                    contentPosition={{ right: '0px', top: '36px', zIndex: 1001 }}
                    onClick={id => handleSelectSaveAs(id as string)}
                    onToggle={(openState: boolean) => setSaveAsOptionsOpen(openState)}
                    menuOptions={saveOptions()}
                  >
                    {(onClick: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void) => (
                      <Button
                        type="info"
                        onClick={onClick}
                        label="Save"
                        arrowType={saveAsOptionsOpen ? 'up' : 'down'}
                      />
                    )}
                  </DropdownMenu>
                </div>
              )}
            </PermissionDisabledTip>
          )}
        </div>
      </div>
      {inEditMode && (
        <EditModeRibbon
          workspace={params.workspace}
          addingAsset={addAssetRequest === asyncActionStates.LOADING}
          savingAssetEdit={editAssetRequest === asyncActionStates.LOADING}
          editBranchLoading={editBranchReq === asyncActionStates.LOADING}
          editBranchError={editBranchReq === asyncActionStates.ERROR}
          saveEditBranchError={saveEditBranchReq === asyncActionStates.ERROR}
          saveEditBranchLoading={saveEditBranchReq === asyncActionStates.LOADING}
          saveModalActive={saveModalActive}
          exitModalActive={exitModalActive}
          closeSave={closeSave}
          modalType={saveModalType}
          switchToBranch={newBranchName}
          changeToView={newView}
          switchToView={handleSwitchViews}
          switchToWorkspace={newWorkspaceName}
          selectedSaveAsOption={selectedSaveAsOption}
          handleSelectSaveAs={handleSelectSaveAs}
        />
      )}
      <DelayedModal
        active={!inEditMode && editBranchReq === asyncActionStates.LOADING}
        width="250px"
        theme={theme}
        showFooter={false}
        showHeader={false}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p>Entering Edit Mode... </p>
          <i className="material-icons rotate">refresh</i>
        </div>
      </DelayedModal>
    </div>
  );
};

export default NetworkTopNav;
