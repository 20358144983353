import React, { FunctionComponent, ReactNode, ReactChild, useContext } from 'react';
import { ThemeProp } from 'types/index';
import Button from 'components/Button';
import ThemeContext from 'helpers/ThemeContext';

type ActionButtonsProps = {
  className?: string;
  headerContents?: ReactChild | ReactNode;
  onClickBackBtn?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void | null;
  onClickNextBtn?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void | null;
  onClickSubmitBtn?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void | null;
  disableSubmit?: boolean;
  disableNext?: boolean;
  title?: string;
  toggleModal: (modalOpen: boolean) => void;
  submitLabel?: string;
  submitBtnId?: string;
};
const ActionButtons: FunctionComponent<ActionButtonsProps> = ({
  className = '',
  onClickBackBtn = null,
  onClickNextBtn = null,
  onClickSubmitBtn = null,
  disableSubmit = false,
  title = '',
  toggleModal,
  disableNext,
  submitLabel = 'Submit',
  submitBtnId = 'submit-btn',
}) => {
  const theme = useContext(ThemeContext) as ThemeProp;
  return (
    <div className={`action-buttons ${className} ${theme}`} data-test="action-buttons">
      <Button
        className="cancel-btn"
        id="cancel-btn"
        type="secondary"
        onClick={() => toggleModal(false)}
        label="Cancel"
      />
      {onClickBackBtn && (
        <Button
          className="back-btn"
          id="back-btn"
          type="secondary"
          onClick={onClickBackBtn}
          label="Back"
        />
      )}
      {onClickNextBtn && (
        <Button
          className="next-btn"
          id="next-btn"
          type="primary"
          onClick={onClickNextBtn}
          label="Next"
          disabled={disableNext}
        />
      )}
      {onClickSubmitBtn && (
        <Button
          className="submit-btn"
          label={submitLabel}
          id={submitBtnId}
          type="primary"
          theme={theme}
          disabled={disableSubmit}
          onClick={onClickSubmitBtn}
          title={title}
        />
      )}
    </div>
  );
};

export default ActionButtons;
