import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import ThemeContext from 'helpers/ThemeContext';
import useTrackingState from 'hooks/useTrackingState';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import ExpandableSection from 'components/ExpandableSection';
import Tooltip from 'components/Tooltip';
import Select from 'components/Select';

import './LifecycleSection.scss';

const healthMetricOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

const LifecycleSection = props => {
  const {
    lifecycle,
    healthMetric,
    onHealthMetricChange,
    inServiceOnly,
    disabled,
    onChange,
  } = props;
  const theme = useContext(ThemeContext);
  const [healthMetricState, setHealthMetricState] = useTrackingState(healthMetric);

  const onLifecycleChange = (key, value) => {
    lifecycle[key] = value.format('YYYY-MM-DD');

    onChange(lifecycle);
  };

  const { inServiceDate, retiredDate } = lifecycle;

  const tooltipMessage = type => {
    let message;
    switch (type) {
      case 'age_in_service':
        message = inServiceOnly
          ? 'The first day that this asset is in-service. This will not affect whether the asset is included in simulations.'
          : 'The first day that this asset is in-service. Simulations before this date will not include the asset.';
        break;
      case 'age_retirement':
        message =
          'The first day that this asset is out of service. Simulations on this day, and after, will not include the asset.';
        break;
      case 'health':
        message = (
          <>
            <p>1 - Excellent Operating Condition</p>
            <p>5 - Heavily Deteriorated Condition</p>
          </>
        );
        break;
      default:
        message = null;
    }
    return message;
  };

  return (
    <ExpandableSection sectionName="Reliability Planning Information" className="lifecycle-section">
      <div className="lifecycle-section-row">Asset Age</div>
      <div className="lifecycle-section-row">
        <span className="lifecycle-section-label">In-Service Date</span>
        <Tooltip expires={false} placement="left" content={tooltipMessage('age_in_service')}>
          <i className="material-icons help-icon">help_outline</i>
        </Tooltip>
        <DatePicker
          useUTC
          date={moment(inServiceDate || '1900-01-01')}
          disabled={disabled}
          onChange={value => onLifecycleChange('inServiceDate', value)}
          showArrows={false}
          theme={theme}
        />
      </div>
      {!inServiceOnly && (
        <>
          <div className="lifecycle-section-row">
            <span className="lifecycle-section-label">Retirement Date</span>
            <Tooltip expires={false} placement="left" content={tooltipMessage('age_retirement')}>
              <i className="material-icons help-icon">help_outline</i>
            </Tooltip>
            <DatePicker
              useUTC
              date={moment(retiredDate || '9999-12-31')}
              disabled={disabled}
              onChange={value => onLifecycleChange('retiredDate', value)}
              showArrows={false}
              theme={theme}
            />
          </div>
        </>
      )}
      {onHealthMetricChange && (
        <>
          <div className="lifecycle-section-row">Asset Health</div>
          <div className="lifecycle-section-health">
            <span className="lifecycle-section-label">Health Metric</span>
            <Tooltip expires={false} placement="left" content={tooltipMessage('health')}>
              <i className="material-icons help-icon">help_outline</i>
            </Tooltip>
          </div>
          <Select
            disabled={disabled}
            options={healthMetricOptions}
            value={healthMetricState}
            theme={theme}
            onChange={e => {
              const val = e === null ? 0 : e.value;
              setHealthMetricState(e === null ? null : e.value);
              onHealthMetricChange({ reliability_info: { healthMetric: val } });
            }}
            menuPlacement="top"
          />
        </>
      )}
    </ExpandableSection>
  );
};

LifecycleSection.defaultProps = {
  disabled: false,
  inServiceOnly: false,
  healthMetric: null,
  onHealthMetricChange: null,
};

LifecycleSection.propTypes = {
  disabled: PropTypes.bool,
  lifecycle: PropTypes.shape({
    inServiceDate: PropTypes.string,
    retiredDate: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  inServiceOnly: PropTypes.bool,
  healthMetric: nullable(PropTypes.number),
  onHealthMetricChange: PropTypes.func,
};

export default LifecycleSection;
