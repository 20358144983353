import AssetInfo from './AssetInfo';
import { extractAlternateLimitSet } from '../helpers/cimHelpers';

class SwitchInfo extends AssetInfo {
  get breakingCapacity() {
    return this.attributes['SwitchInfo.breakingCapacity'];
  }

  get isSinglePhase() {
    return this.attributes['SwitchInfo.isSinglePhase'] || false;
  }

  get isUnganged() {
    return this.attributes['SwitchInfo.isUnganged'] || false;
  }

  get ratedCurrent() {
    return this.attributes['SwitchInfo.ratedCurrent'];
  }

  get ratedVoltage() {
    return this.attributes['SwitchInfo.ratedVoltage'];
  }

  get alternateLimitSet() {
    return extractAlternateLimitSet(this);
  }
}

export default SwitchInfo;
