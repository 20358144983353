import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from 'components/ExpandableSection';
import RadioButtonSelectGroup from 'components/RadioButtonSelectGroup';
import { getMultiplier } from 'helpers/utils';
import {
  createRangeBuckets,
  createLabels,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import RangeSelector from './RangeSelector';
import RangeSlider from './RangeSlider';
import LayerSelectionButton from './LayerSelectionButton';

class HostingCapacitySection extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      powerGradient: this.getBreaks(this.props.layerOptions),
      editId: '',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldOptions = this.props.layerOptions;
    const newOptions = nextProps.layerOptions;
    if (
      oldOptions.colors !== newOptions.colors ||
      oldOptions.rangeBreaks !== newOptions.rangeBreaks
    ) {
      this.setState({ powerGradient: this.getBreaks(newOptions), editId: '' });
    }
  }

  getBreaks = options => {
    const { rangeBreaks, colors } = options;
    const multipliers = rangeBreaks.map(val => getMultiplier(val));
    const adjustedRange = rangeBreaks.map(
      (val, index) => `${(val / multipliers[index].value).toFixed(2)} ${multipliers[index].letter}`,
    );
    return createRangeBuckets(colors, createLabels(adjustedRange, 'W'));
  };

  updateLayer = update => this.props.updateLayerOptions(update);

  changePhaseType = mode => this.updateLayer({ mode });

  changePhase = (section, phase) => this.updateLayer({ phase });

  unCheckRange = e => {
    const index = parseInt(e.target.value, 10);

    const newSelection = [...this.props.layerOptions.selected];
    newSelection[index] = !newSelection[index];
    this.updateLayer({ selected: newSelection });
  };

  toggleEditPanel = id => {
    this.setState({ editId: id });
  };

  _renderPhaseSelector = () => (
    <div className="radio-mode hc-radio-mode">
      <RadioButtonSelectGroup
        id={`${this.props.layerID}-phase-selector`}
        value={this.props.layerOptions.mode}
        listType="column"
        theme={this.props.theme}
        onSelectChange={this.changePhase}
        onChange={this.changePhaseType}
        options={[
          {
            id: 'balanced',
            label: 'Three-Phase Symmetric',
          },
          {
            id: 'unbalanced',
            label: 'Single-Phase',
            selectOptions: [
              { value: 'A', label: 'A' },
              { value: 'B', label: 'B' },
              { value: 'C', label: 'C' },
            ],
            selectValue: this.props.layerOptions.phase,
          },
        ]}
      />
    </div>
  );

  handleLayerUpdate = updates => {
    this.props.updateLayerOptions(updates);
  };

  render() {
    const button = () => (
      <LayerSelectionButton
        selected={this.props.selected}
        option={{ value: this.props.layerID, label: this.props.layerName }}
        group="results"
        setSelectedLayer={this.props.setSelectedLayer}
      />
    );

    return (
      <ExpandableSection
        className={classNames({
          'layer-expandable-section': true,
          'selected-layer': this.props.selected,
          'layer-expandable-section--expanded': this.props.expanded,
        })}
        renderHeaderContent={button}
        showToggle={false}
        open={this.props.selected}
      >
        <div className="layer-section">
          {this._renderPhaseSelector()}
          <RangeSlider
            domain={[0, this.props.layerOptions.rangeBreaks[0] * 1.1]}
            values={this.props.layerOptions.rangeBreaks.slice().reverse()}
            colors={this.props.layerOptions.colors.slice().reverse()}
            format={d => `${d / 1000} kW`}
            onUpdate={e => {
              this.handleLayerUpdate({ rangeBreaks: e.reverse() });
            }}
          />
          <div className="layer-checkbox-group">
            {this.state.powerGradient.length &&
              this.state.powerGradient.map(checkbox => (
                <RangeSelector
                  key={checkbox.id}
                  settings={checkbox}
                  selected={this.props.layerOptions.selected[parseInt(checkbox.id, 10)]}
                  onClick={this.unCheckRange}
                  id={parseInt(checkbox.id, 10)}
                  withEdit
                  toggleEdit={this.toggleEditPanel}
                  showEditPanel={checkbox.id === this.state.editId}
                  layerOptions={this.props.layerOptions}
                  handleUpdate={this.handleLayerUpdate}
                  editDisabled={!!this.state.editId}
                  rangeMax={1000000000000}
                  rangeMin={0}
                  useMultiplier
                  editColor
                  editRange
                  unit="W"
                  ascending
                />
              ))}
          </div>
        </div>
      </ExpandableSection>
    );
  }
}

HostingCapacitySection.propTypes = {
  layerOptions: PropTypes.shape({
    selected: PropTypes.array,
    mode: PropTypes.string,
    phase: PropTypes.string,
    colors: PropTypes.array,
    rangeBreaks: PropTypes.array,
  }).isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  layerID: PropTypes.string.isRequired,
  layerName: PropTypes.string.isRequired,
};

export default HostingCapacitySection;
