import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import Icons from 'components/Icons';

import './PanelHeader.scss';

const { CloseIcon } = Icons;
/**
 * Creates the name header for the workspace and feeder detail panels.
 */
const PanelHeader = props => (
  <div className={`panel-header ${props.className}`}>
    <div className="panel-header-top">
      <h3 className="panel-header__h3">{props.header}</h3>
      {props.showClose && (
        <button onClick={props.onClose} className="close-btn" type="button">
          <CloseIcon color="#949899" />
        </button>
      )}
    </div>
    <div className="panel-header-container">
      <div className="panel-icon-container">
        <ReactSVG src={props.icon} className="svg-icon" />
      </div>
      <div className="panel-info-container">
        <div className="panel-info__p panel-name">
          <div className="panel-info__p">
            <b>{props.type}</b>
          </div>
          &nbsp;<div>{props.name || props.id}</div>
        </div>
      </div>
    </div>
    <hr className="panel-header__hr" />
  </div>
);

PanelHeader.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  icon: PropTypes.string,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
};

PanelHeader.defaultProps = {
  name: '',
  type: '',
  icon: '',
  header: '',
  showClose: false,
  onClose: null,
  className: '',
  id: '',
};

export default PanelHeader;
