import { alphabetize } from 'helpers/utils';
import IdentifiedObject from './IdentifiedObject';
import { getConnectivityNodeLocation } from './locationHelpers';

class ConnectivityNode extends IdentifiedObject {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'ConnectivityNode';
    this.displayName = 'Node';
  }

  get baseVoltage() {
    try {
      return this._baseVoltage.attributes['BaseVoltage.nominalVoltage'];
    } catch (err) {
      return null;
    }
  }

  get phase() {
    try {
      const totalTerms = this.references['ConnectivityNode.Terminals'].length;

      const phaseSet = this.references['ConnectivityNode.Terminals'].reduce((phases, term) => {
        const terminalInstance = this.cimDict[term];
        const eq = this.cimDict[terminalInstance.references['Terminal.ConductingEquipment']];
        // Junction terminals do not matter here if there is more than 1 terminal

        if (totalTerms > 1 && eq && (eq.class === 'Junction' || eq.class === 'BusbarSection')) {
          return phases;
        }

        const termPhases = terminalInstance.attributes['Terminal.phases'];

        if (termPhases) {
          termPhases.split('').forEach(phase => {
            if (phase.toLowerCase() !== 'n') {
              phases.add(phase);
            }
          });
        }
        return phases;
      }, new Set([]));
      return alphabetize([...phaseSet]).join('');
    } catch (err) {
      return '';
    }
  }

  // Extract the node's coordinates
  get coordinates() {
    return this.cimDict[this._positionPoint].attributes;
  }

  get rawCoordinates() {
    const point = this.cimDict[this._positionPoint].attributes;
    return {
      longitude: parseFloat(point['PositionPoint.xPosition']),
      latitude: parseFloat(point['PositionPoint.yPosition']),
    };
  }

  get feeder() {
    let containerID = this.references['ConnectivityNode.ConnectivityNodeContainer'];
    // We need to also handle the difference model case where the feeder
    // id is new / has been updated
    if (containerID && Array.isArray(containerID)) {
      [containerID] = containerID;
    }

    const container = this.cimDict[containerID];

    if (!container) {
      return undefined;
    }

    if (container.class !== 'ConnectivityNodeContainer') {
      return containerID;
    }

    let terminal;
    let feeder;
    const terminals = this.references['ConnectivityNode.Terminals'] || [];
    terminals.forEach(terminal_id => {
      if (!terminal && this.cimDict[terminal_id]) {
        terminal = this.cimDict[terminal_id];
      }
      if (terminal === undefined) {
        return undefined;
      }
      const equipment = this.cimDict[terminal.references['Terminal.ConductingEquipment']];
      if (equipment === undefined) {
        return undefined;
      }
      const potential_feeder_id = Array.isArray(
        equipment.references['Equipment.EquipmentContainer'],
      )
        ? equipment.references['Equipment.EquipmentContainer'][0]
        : equipment.references['Equipment.EquipmentContainer'];

      // if a node is between two feeders, we'll return the last one.
      if (this.cimDict[potential_feeder_id] && this.cimDict[potential_feeder_id].class === 'Line') {
        feeder = potential_feeder_id;
      }

      // if we get here, no way of getting the EquipmentContainer or the
      // container is not a feeder
      return undefined;
    });
    return feeder;
  }

  get location() {
    return getConnectivityNodeLocation(this.id, this.cimDict);
  }

  get connectedEquipment() {
    const terminals = this.references['ConnectivityNode.Terminals'];
    if (terminals) {
      return terminals.reduce((list, term) => {
        const termInstance = this.cimDict[term];
        if (termInstance && termInstance.references['Terminal.ConductingEquipment']) {
          const eqInstance = this.cimDict[termInstance.references['Terminal.ConductingEquipment']];
          if (
            eqInstance &&
            !(eqInstance.class === 'Junction' || eqInstance.class === 'BusbarSection')
          ) {
            return list.concat(eqInstance);
          }
        }
        return list;
      }, []);
    }
    return [];
  }

  get _positionPoint() {
    const position = this.location.references['Location.PositionPoints'][0];
    return position;
  }

  /* SV Helpers */

  get _topologicalNode() {
    return this.references['ConnectivityNode.TopologicalNode'];
  }

  get _baseVoltage() {
    return this.extractReference(this._topologicalNode, 'TopologicalNode.BaseVoltage');
  }

  getIdsToClear(diffModel) {
    const { set } = diffModel;
    const positionID = this._positionPoint;

    if (set && set[positionID]) {
      const terminals = this.extractReferenceList(this.id, 'ConnectivityNode.Terminals');
      return [this.id, ...terminals.map(t => t.references['Terminal.ConductingEquipment'])];
    }

    return [];
  }
}

export default ConnectivityNode;
