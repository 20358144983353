import IdentifiedObject from '../IdentifiedObject';

class TransformerStarImpedance extends IdentifiedObject {
  constructor(id, cimDict) {
    super(id, cimDict);

    this.endInfo = this.extractReference(id, 'TransformerStarImpedance.TransformerEndInfo');
  }

  get EndInfo() {
    return this.endInfo;
  }

  get r() {
    return this.attributes['TransformerStarImpedance.r'];
  }

  get r0() {
    return this.attributes['TransformerStarImpedance.r0'];
  }

  get x() {
    return this.attributes['TransformerStarImpedance.x'];
  }

  get x0() {
    return this.attributes['TransformerStarImpedance.x0'];
  }
}

export default TransformerStarImpedance;
