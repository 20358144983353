import { checkPropTypes } from 'prop-types';

/**
 * Makes a prop type nullable. Meaning the prop must be specified (ie no undefined)
 * but can be null.
 * @param {*} propType The prop type to allow null for.
 */
function nullable(propType) {
  const checkProperty = (propTypeValidator, props, propName, componentName) => {
    if (props[propName] !== null) {
      checkPropTypes({ [propName]: propTypeValidator }, props, 'prop', componentName);
    }
  };
  const nullableChecker = (...props) => checkProperty(propType, ...props);
  nullableChecker.isRequired = (...props) => checkProperty(propType.isRequired, ...props);
  return nullableChecker;
}

export default nullable;
