import React, { FunctionComponent, useMemo } from 'react';
import { ThemeProp } from 'types/index';
import { Analysis } from 'types/analysis';
import { Moment } from 'moment';
import CostSummary from './Costs';
import ReliabilitySummary from './Reliability';
import EnergySummary from './Energy';
import Violations from './Violations';
import EmissionsSummary from './Emissions';
import NetPresentValueSummary from './NetPresentValue';
import { Views, SummaryRequestParams } from '../helpers/ResultsComparisonHelpers';

type ResultsSummaryProps = {
  theme: ThemeProp;
  violationLimit?: number | null;
  workspace: string | null;
  branch: string | null;
  maxRange: {
    start?: Moment | null;
    end?: Moment | null;
  };
  scenario: string | null;
  analysis: Analysis | null;
  violationType: string;
  setViolationType: (value: string) => void;
  isBaseline: boolean;
  viewType: Views;
};

const ResultsSummary: FunctionComponent<ResultsSummaryProps> = ({
  theme,
  violationLimit = 0,
  workspace,
  branch,
  maxRange,
  scenario,
  analysis,
  violationType,
  setViolationType,
  isBaseline,
  viewType,
}) => {
  // create an update trigger (a unique symbol that will be generated each time a change occurs)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const analysisDataUpdateTrigger = useMemo(() => Symbol('dataUpdate'), [
    workspace,
    branch,
    scenario,
    analysis?.name,
    analysis?.containers,
    maxRange.start,
    maxRange.end,
  ]);

  // don't render anything is there is no scenario or analysis
  if (
    !workspace ||
    !branch ||
    !scenario ||
    !maxRange.start ||
    !maxRange.end ||
    !analysis?.name ||
    !analysis?.containers?.length
  ) {
    return <></>;
  }

  const requestParams: SummaryRequestParams = {
    feeder: analysis.containers,
    start_date: maxRange.start.toISOString(),
    end_date: maxRange.end.toISOString(),
    scenario_id: scenario,
    analysis_name: analysis.name,
  };

  const commonProps = {
    workspace,
    branch,
    theme,
  };
  const analysisProps = {
    ...commonProps,
    isBaseline,
    analysisDataUpdateTrigger,
    requestParams,
  };

  return (
    <>
      <Violations
        {...analysisProps}
        violationLimit={violationLimit}
        violationType={violationType}
        setViolationType={setViolationType}
        viewType={viewType}
      />
      {viewType === Views.overview && (
        <>
          <EmissionsSummary {...analysisProps} />
          <NetPresentValueSummary {...commonProps} />
          <ReliabilitySummary {...analysisProps} />
          <EnergySummary {...analysisProps} />
          <CostSummary {...analysisProps} />
        </>
      )}
    </>
  );
};

export default ResultsSummary;
