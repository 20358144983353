import PowerTransformer from './PowerTransformer';

class Regulator extends PowerTransformer {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'regulator';
    this._class = 'Regulator';
    this.displayName = this.class;
  }
}

export default Regulator;
