import React, { useState, useContext, useEffect, useCallback, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import Analytics from 'helpers/Analytics';
import { viewOptions } from 'helpers/Constants';
import DocumentationLink from 'components/DocumentationLink';
import RadioButtonGroup from 'components/RadioButtonGroup';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import About from './About';

type SettingsMenuProps = {
  showTour: () => void;
  view: string;
  inEditMode: boolean;
  workspace?: string;
};
const SettingsMenu: FunctionComponent<SettingsMenuProps> = ({
  showTour,
  workspace = null,
  view,
  inEditMode,
}) => {
  const [settingsMenu, setSettingsMenu] = useState(false);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const { settings, setSettings, saveSettings, settingsModified } = useContext(
    WorkspaceSettingsContext,
  );
  const { isAuthEnabled, logout } = useAuthContext();

  const handleLogoutClick = () => {
    logout();
    Analytics.logEvent('Log Out', 'Settings Menu');
  };

  const closeMenu = useCallback(() => {
    setSettingsMenu(false);
  }, []);

  const showReactTour = () => {
    localStorage.setItem('tour', 'show');
    showTour();
    Analytics.logEvent('Guided Tour', 'Settings Menu');
  };

  useEffect(() => {
    // we want to save the mapMode if it changes since there is no save button
    if (settingsModified.mapMode) {
      // save will silently fail as its async
      saveSettings();
    }
  }, [saveSettings, settingsModified]);

  useEffect(() => {
    // add a screenwide click handler so that the menu closes
    // anytime a click occurs
    if (settingsMenu) {
      document.addEventListener('click', closeMenu);
    } else {
      document.removeEventListener('click', closeMenu);
    }
  }, [settingsMenu, closeMenu]);

  const mapModeOptions = [
    { id: 'off', label: 'Off' },
    { id: '2d', label: 'Normal' },
    { id: '3d', label: '3D' },
  ];
  if (settings.mapboxSource?.length) {
    mapModeOptions.push({ id: 'custom', label: 'Custom' });
  }

  return (
    <>
      <div className="settings">
        <IconButton
          className={`settings__btn ${settingsMenu ? 'settings__btn--active' : ''} ${
            inEditMode ? 'settings__icon-dark' : 'settings__icon-light'
          }`}
          onClick={() => setSettingsMenu(!settingsMenu)}
          tooltip="Settings"
        />
        <div className={`settings-container ${settingsMenu ? 'settings-container--active' : ''}`}>
          <div className="settings-body">
            <div className="settings-tile settings-tile--service">
              <Link
                className="settings-link"
                onClick={() => {
                  Analytics.logEvent('Analysis Settings', 'Settings Menu');
                }}
                to={`${workspace ? `/settings/${workspace}` : '/settings'}`}
              >
                Account settings
              </Link>
            </div>
            <div className="settings-tile settings-tile--help">
              <DocumentationLink
                className="settings-link"
                onClick={() => Analytics.logEvent('Product Manual', 'Settings Menu')}
              >
                User manual
              </DocumentationLink>
            </div>
            {!inEditMode &&
              Object.values(viewOptions)
                .map(option => option.value)
                ?.includes(view) && (
                <div className="settings-tile settings-tile--apidocs">
                  <Link
                    className="settings-link"
                    id="api-docs"
                    onClick={() => Analytics.logEvent('Api Docs', 'Settings Menu')}
                    to="/api-docs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    API Documentation
                  </Link>
                </div>
              )}
            <div className="settings-tile">
              <PermissionDisabledTip
                hide={view === 'gis' && !inEditMode}
                title="Guided Tour"
                message={
                  inEditMode
                    ? 'You must leave edit mode to start the tour'
                    : 'You must be in Analysis View to start the tour'
                }
              >
                <button
                  id="tour"
                  className="settings-tour__btn"
                  onClick={showReactTour}
                  disabled={view !== 'gis' || inEditMode}
                  type="button"
                >
                  Guided Tour
                </button>
              </PermissionDisabledTip>
            </div>
            <div className="settings-tile settings-tile--service">
              <a
                className="settings-link"
                href="https://opusonesolutions.atlassian.net/servicedesk/customer/portal/5"
                onClick={() => {
                  Analytics.logEvent('Report an Issue', 'Settings Menu');
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                Report an Issue
              </a>
            </div>
            <div className="settings-tile settings-tile--service">
              <button
                id="about-btn"
                type="button"
                className="settings-tour__btn"
                onClick={() => setAboutModalOpen(true)}
              >
                About
              </button>
            </div>
            <>
              {workspace && (
                // only show the mapMode selector in a workspace
                <div className="settings-tile-theme">
                  <h3 className="settings-tile-title">Map Mode</h3>
                  <RadioButtonGroup
                    options={mapModeOptions}
                    id="map-selector"
                    value={settings.mapMode}
                    listType="column"
                    onChange={async ({ target }: any) => {
                      setSettings({ mapMode: target.value });
                      Analytics.logEvent('Change Map Mode', 'Settings Menu', target.value);
                    }}
                    radioType="primary"
                  />
                </div>
              )}
            </>
            {isAuthEnabled && (
              <>
                <div className="settings-separator" />
                <div className="settings-tile flex-centered">
                  <Button
                    className="settings-logout__btn"
                    onClick={handleLogoutClick}
                    type="hollow"
                    width="120px"
                    height="25px"
                  >
                    Log Out
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {aboutModalOpen && <About onCancel={() => setAboutModalOpen(false)} />}
    </>
  );
};

export default SettingsMenu;
