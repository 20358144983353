import AssetInfo from './AssetInfo';
import TransformerStarImpedance from './TransformerStarImpedance';
import NoLoadTest from './NoLoadTest';
import { extractAlternateLimitSet } from '../helpers/cimHelpers';

class TransformerEndInfo extends AssetInfo {
  get NoLoadTest() {
    const noLoadTestRef = this.references['TransformerEndInfo.EnergisedEndNoLoadTests'];
    return noLoadTestRef && noLoadTestRef.length > 0
      ? new NoLoadTest(noLoadTestRef[0], this.cimDict)
      : { loss: 0 };
  }

  get starImpedance() {
    const starImpedanceRef = this.references['TransformerEndInfo.TransformerStarImpedance'];
    return starImpedanceRef ? new TransformerStarImpedance(starImpedanceRef, this.cimDict) : {};
  }

  get TankInfo() {
    return this.tankInfo;
  }

  get connectionKind() {
    return this.attributes['TransformerEndInfo.connectionKind'];
  }

  get endNumber() {
    return this.attributes['TransformerEndInfo.endNumber'];
  }

  get ratedS() {
    return this.attributes['TransformerEndInfo.ratedS'];
  }

  get ratedU() {
    return this.attributes['TransformerEndInfo.ratedU'];
  }

  get phaseAngleClock() {
    return this.attributes['TransformerEndInfo.phaseAngleClock'];
  }

  get alternateLimitSet() {
    return extractAlternateLimitSet(this);
  }
}

export default TransformerEndInfo;
