import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import asyncActionStates from 'helpers/asyncActionStates';
import Select from 'components/Select';

import { amps, kVA, kW, kVAr, kVln } from 'helpers/units';
import valueCheck from 'routes/WorkspaceLayout/routes/Network/components/SlidingInfoPanel/AssetPanel/helpers/valueCheck';
import { calculateSum } from 'routes/WorkspaceLayout/routes/Network/components/SlidingInfoPanel/AssetPanel/helpers/valueExtractors';
import PerPhaseContainer from '../../templates/partials/PerPhaseContainer';
import PerPhaseRow from '../../templates/partials/PerPhaseRow';
import ProbabilityOfFailure from './ProbabilityOfFailure';
import ContinuousTimeSeriesChart from './ContinuousTimeSeriesChart';
import EnergyTimeSeriesChart from './EnergyTimeSeriesChart';
import OperationCostChart from './OperationCostChart';
import OperationCostTable from './OperationCostTable';
import DERProgramCostTable from './DERProgramCostTable';
import StateOfChargeChart from './StateOfChargeChart';
import assetClassLookup, {
  shouldInvertRealPower,
  shouldInvertReactivePower,
} from '../../helpers/assetClass';

import './ResultsSection.scss';
import CommGenCostTable from './CommGenCostTable';

// Information about each variable that is displayed
const VARIABLES = {
  apparentPower: {
    label: 'Apparent Power',
    key: 'apparentPower',
    unit: kVA,
    divisor: 1000,
    type: 'number',
    getTotal: calculateSum,
  },
  i: {
    label: 'Current',
    key: 'i',
    unit: amps,
    type: 'number',
    getTotal: calculateSum,
  },
  actualP: {
    label: 'Real Power',
    key: 'actualP',
    unit: kW,
    divisor: 1000,
    type: 'number',
    getTotal: calculateSum,
  },
  actualQ: {
    label: 'Reactive Power',
    key: 'actualQ',
    unit: kVAr,
    divisor: 1000,
    type: 'number',
    getTotal: calculateSum,
  },
  pLosses: {
    label: 'Real Losses',
    key: 'pLosses',
    unit: kW,
    divisor: 1000,
    type: 'number',
    getTotal: calculateSum,
  },
  qLosses: {
    label: 'Reactive Losses',
    key: 'qLosses',
    unit: kVAr,
    divisor: 1000,
    type: 'number',
    getTotal: calculateSum,
  },
  powerFactor: {
    label: 'Power Factor',
    key: 'powerFactor',
    type: 'number',
    getTotal: () => '',
  },
  voltages: {
    key: 'voltages',
    divisor: 1000 * Math.sqrt(3),
    getTotal: () => '',
    label: 'Voltage',
    type: 'number',
    unit: kVln,
  },
};

const linkVariables = [
  'i',
  'apparentPower',
  'actualP',
  'actualQ',
  'pLosses',
  'qLosses',
  'voltages',
  'powerFactor',
];
const shuntVariables = ['apparentPower', 'actualP', 'actualQ', 'powerFactor'];
// Map of CIM class to variables to display
const TABLE_VARIABLES = {
  ACLineSegment: [...linkVariables],
  AsynchronousMachine: [...shuntVariables],
  Battery: [...shuntVariables],
  Breaker: [...linkVariables],
  CHP: [...shuntVariables],
  Cut: [...linkVariables],
  Disconnector: [...linkVariables],
  EnergyConsumer: [...shuntVariables],
  EnergySource: [...shuntVariables],
  EquivalentSubstation: [...shuntVariables],
  Fuse: [...linkVariables],
  InverterPV: [...shuntVariables], // New style PV
  Jumper: [...linkVariables],
  LinearShuntCompensator: [...shuntVariables],
  PV: [...shuntVariables], // Old style PV
  Recloser: [...linkVariables],
  RunOfRiverHydro: [...shuntVariables],
  Sectionaliser: [...linkVariables],
  Switch: [...linkVariables],
  SynchronousMachine: [...shuntVariables],
  Wind: [...shuntVariables],
  ElectricVehicleChargingStation: [...shuntVariables],
};

const pqGraph = ['p', 'q'];
const lossGraph = ['p_loss', 'q_loss'];
const costGraph = ['cost'];
const socGraph = ['soc'];

const GRAPH_VARIABLES = {
  ACLineSegment: [...pqGraph, ...lossGraph],
  AsynchronousMachine: [...pqGraph, ...costGraph],
  Battery: [...pqGraph, 'energy', ...costGraph],
  Breaker: [...pqGraph, ...lossGraph],
  CHP: [...pqGraph, ...costGraph],
  Cut: [...pqGraph, ...lossGraph],
  Disconnector: [...pqGraph, ...lossGraph],
  EnergyConsumer: [...pqGraph, ...costGraph],
  EnergySource: [...pqGraph, ...costGraph],
  EquivalentSubstation: [...pqGraph],
  Fuse: [...pqGraph, ...lossGraph],
  InverterPV: [...pqGraph, ...costGraph],
  Jumper: [...pqGraph, ...lossGraph],
  LinearShuntCompensator: ['q', ...costGraph],
  PV: [...pqGraph], // Old style PV
  Recloser: [...pqGraph, ...lossGraph],
  RunOfRiverHydro: [...pqGraph, ...costGraph],
  Sectionaliser: [...pqGraph, ...lossGraph],
  Switch: [...pqGraph, ...lossGraph],
  SynchronousMachine: [...pqGraph, ...costGraph],
  Wind: [...pqGraph, ...costGraph],
  ElectricVehicleChargingStation: [...pqGraph, ...socGraph],
};
const GRAPH_LABELS = {
  energy: 'Energy',
  p: 'Real Power',
  p_loss: 'Real Power Losses',
  q: 'Reactive Power',
  q_loss: 'Reactive Power Losses',
  cost: 'Cost of Operation',
  soc: 'State of Charge',
};
const GRAPH_DATA = {
  p: {
    title: 'Real Power',
    tooltipTitle: 'Real Power',
    unit: kW,
  },
  p_loss: {
    title: 'Real Power Losses',
    tooltipTitle: 'Real Power Losses',
    unit: kW,
  },
  q: {
    title: 'Reactive Power',
    tooltipTitle: 'Reactive Power',
    unit: kVAr,
  },
  q_loss: {
    title: 'Reactive Power Losses',
    tooltipTitle: 'Reactive Power Losses',
    unit: kVAr,
  },
};

const ResultsSection = ({
  analysisSettings,
  assetID,
  assetClass,
  asset,
  timeRange,
  maxRange,
  expanded,
  newPanelValues,
  pqTimeSeriesData,
  pqTimeSeriesStatus,
  costData,
  costDataRequestStatus,
  DERProgramCostData,
  commGenCostData,
  theme,
  workspace,
  branch,
  scenarioID,
  analysisName,
  downloadOperationCostReport,
  operationCostReportStatus,
  violations,
  results,
  timeBarZoomLevel,
  subHourInterval,
}) => {
  const graphVariables = GRAPH_VARIABLES[assetClass];
  const tableVariables = TABLE_VARIABLES[assetClass];

  const [selectedGraphVariable, setSelectedGraphVariable] = useState(graphVariables[0]);
  const isSingleInterval =
    timeRange.end &&
    timeRange.start &&
    timeRange.start.clone().add(subHourInterval, 'minutes') > timeRange.end;
  const hideTable = !isSingleInterval || !valueCheck.hasValues(Object.keys(VARIABLES), results);

  useEffect(() => setSelectedGraphVariable(graphVariables[0]), [assetClass, graphVariables]);

  const computeRatings = () => {
    const ratings = {};
    if (assetClass === 'Battery') {
      ratings.maxP = newPanelValues.maxP;
      ratings.minP = newPanelValues.minP;
      ratings.maxQ = newPanelValues.maxQ;
      ratings.minQ = newPanelValues.minQ;
    } else if (assetClass === 'InverterPV') {
      ratings.minQ = newPanelValues.minQ;
      ratings.maxQ = newPanelValues.maxQ;
      ratings.minP = Math.max(-newPanelValues.ratedS, newPanelValues.pvUnitMinP);
    }

    return ratings;
  };
  const isShuntDevice = assetClassLookup.shunt.includes(assetClass);
  const invertPTableData = shouldInvertRealPower(assetClass, analysisSettings);
  const invertQTableData = shouldInvertReactivePower(assetClass, analysisSettings);
  return (
    <div className="asset-panel-values results-section">
      <h4>Analysis Results</h4>
      {isShuntDevice && (
        <div className="results-legend">
          <span className="results-legend-item">
            <span className="results-legend-label">Real Power: </span>
            {invertPTableData ? 'Load (-) / Generation (+)' : 'Load (+) / Generation (-)'}
          </span>
          <span className="results-legend-item">
            <span className="results-legend-label">Reactive Power: </span>
            {invertQTableData ? 'Inductive (-) / Capacitive (+)' : 'Inductive (+) / Capacitive (-)'}
          </span>
        </div>
      )}
      {!hideTable && (
        <PerPhaseContainer showTotal>
          {tableVariables.map(key => {
            const variable = VARIABLES[key];
            const values = {};
            ['A', 'B', 'C'].forEach(phase => {
              values[phase] = results?.[variable.key]?.[`${phase}_avg`];
              if (isShuntDevice && key === 'actualP' && invertPTableData && values[phase]) {
                values[phase] = -values[phase];
              } else if (
                isShuntDevice &&
                ['actualQ', 'powerFactor'].includes(key) &&
                invertQTableData &&
                values[phase]
              ) {
                values[phase] = -values[phase];
              }
            });
            return (
              <div key={`div-${variable.key}`}>
                <PerPhaseRow
                  id={`${variable.key}`}
                  key={`${variable.key}`}
                  violations={violations}
                  {...{
                    ...variable,
                    phases: newPanelValues.phase,
                    values,
                  }}
                  timeRange={timeRange}
                />
              </div>
            );
          })}
        </PerPhaseContainer>
      )}
      {['Switch', 'ACLineSegment'].includes(assetClass) && isSingleInterval && (
        <ProbabilityOfFailure
          maxRange={maxRange}
          workspace={workspace}
          branch={branch}
          feeder={asset.container.id}
          scenario={scenarioID}
          analysisName={analysisName}
          assetID={assetID}
        />
      )}
      {costData.datapoints.length > 0 && (
        <OperationCostTable
          highlightRange={timeRange}
          costData={costData}
          downloadOperationCostReport={downloadOperationCostReport}
          operationCostReportStatus={operationCostReportStatus}
        />
      )}
      {commGenCostData.datapoints.length > 0 && (
        <CommGenCostTable highlightRange={timeRange} commGenCostData={commGenCostData} />
      )}
      {(DERProgramCostData.maxRange.datapoints?.totals ||
        DERProgramCostData.selectedRange.datapoints?.totals) && (
        <DERProgramCostTable costData={DERProgramCostData} />
      )}
      <>
        <h4>Choose parameter to visualize over time</h4>
        <Select
          clearable={false}
          name="graphValue"
          value={selectedGraphVariable}
          options={graphVariables.map(value => ({ label: GRAPH_LABELS[value], value }))}
          searchable={false}
          theme={theme}
          width={215}
          onChange={({ value }) => setSelectedGraphVariable(value)}
        />
        {!['energy', 'cost', 'soc'].includes(selectedGraphVariable) && (
          <ContinuousTimeSeriesChart
            dataKey={selectedGraphVariable}
            highlightRange={timeRange}
            maxRange={maxRange}
            pqTimeSeriesData={pqTimeSeriesData}
            {...GRAPH_DATA[selectedGraphVariable]}
            {...computeRatings()}
            expanded={expanded}
            loading={pqTimeSeriesStatus === asyncActionStates.LOADING}
            timeBarZoomLevel={timeBarZoomLevel}
          />
        )}
        {selectedGraphVariable === 'energy' && (
          <EnergyTimeSeriesChart
            highlightRange={timeRange}
            maxRange={maxRange}
            maxEnergy={newPanelValues.maxEnergy}
            minEnergy={newPanelValues.minEnergy}
            workspace={workspace}
            branch={branch}
            scenario={scenarioID}
            analysisName={analysisName}
            batteryInverter={asset}
            expanded={expanded}
            loading={false} // TODO
            timeBarZoomLevel={timeBarZoomLevel}
          />
        )}
        {selectedGraphVariable === 'cost' && (
          <OperationCostChart
            highlightRange={timeRange}
            maxRange={maxRange}
            scenario={scenarioID}
            expanded={expanded}
            costData={costData}
            costDataRequestStatus={costDataRequestStatus}
            timeBarZoomLevel={timeBarZoomLevel}
          />
        )}
        {selectedGraphVariable === 'soc' && (
          <StateOfChargeChart
            workspace={workspace}
            branch={branch}
            feeder={asset.container.id}
            scenario={scenarioID}
            analysisName={analysisName}
            ev_id={assetID}
            maxRange={maxRange}
            highlightedRange={timeRange}
            expanded={expanded}
            timeBarZoomLevel={timeBarZoomLevel}
          />
        )}
        {assetClass === 'Battery' && (
          <div className="battery-legend">
            <h6 className="battery-legend-text">
              * Positive values = charging, negative values = discharging
            </h6>
          </div>
        )}
      </>
      <hr className="section-divider" />
    </div>
  );
};

ResultsSection.defaultProps = {
  violations: {},
};

ResultsSection.propTypes = {
  downloadOperationCostReport: PropTypes.func.isRequired,
  operationCostReportStatus: PropTypes.number.isRequired,
  analysisSettings: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired, // GET API for the asset
  assetID: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  newPanelValues: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  pqTimeSeriesData: PropTypes.array.isRequired,
  pqTimeSeriesStatus: PropTypes.number.isRequired,
  costData: PropTypes.shape({
    datapoints: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
  }).isRequired,
  costDataRequestStatus: PropTypes.number.isRequired,
  DERProgramCostData: PropTypes.shape({
    maxRange: PropTypes.shape({
      datapoints: PropTypes.object.isRequired,
      error: PropTypes.string.isRequired,
    }),
    selectedRange: PropTypes.shape({
      datapoints: PropTypes.object.isRequired,
      error: PropTypes.string.isRequired,
    }),
  }).isRequired,
  commGenCostData: PropTypes.shape({
    datapoints: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  scenarioID: PropTypes.string.isRequired,
  analysisName: PropTypes.string.isRequired,
  violations: PropTypes.object,
  results: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  subHourInterval: PropTypes.number.isRequired,
};

export default ResultsSection;
