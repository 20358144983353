import React, { FunctionComponent } from 'react';
import CustomCheckbox from '../CustomCheckbox';

import './LeftRailExpandableSection.scss';

// Creates a section in the left rail with a title
// When clicked, the section opens and the children are shown

type LeftRailExpandableSectionProps = {
  children?: Array<string> | Record<string, unknown> | null;
  title?: string;
  addCheckbox: boolean;
  selectCategory?: boolean;
  categoryDash?: boolean;
  handleSelectCategory?: (event: React.MouseEvent) => void;
  handleExpandCategory?: () => void;
  emptyList?: boolean;
  open?: boolean;
};

const LeftRailExpandableSection: FunctionComponent<LeftRailExpandableSectionProps> = ({
  children = [],
  title = '',
  addCheckbox = false,
  selectCategory = false,
  categoryDash = false,
  handleSelectCategory,
  handleExpandCategory,
  emptyList = false,
  open = false,
}) => {
  const handleClick = () => {
    if (handleExpandCategory) {
      handleExpandCategory();
    }
  };

  const openedStyle = open ? 'asset-type-tile-contents--open' : '';

  return (
    <div className="left-rail-expandable-section">
      <div className="left-rail-title">
        {addCheckbox && (
          <CustomCheckbox
            id={`${title}-checkbox`}
            name="checkbox"
            checked={selectCategory}
            dash={categoryDash}
            onClick={handleSelectCategory}
            disabled={emptyList}
          />
        )}
        <button
          className="expand-button"
          onClick={() => handleClick()}
          disabled={emptyList}
          type="button"
        >
          <div className={`expand-button-contents ${openedStyle}`}>
            <p className="expand-button-title">{title}</p>
            <i className="material-icons">{open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
          </div>
        </button>
      </div>
      {open && children}
    </div>
  );
};

export default LeftRailExpandableSection;
