import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import asyncStates from 'helpers/asyncActionStates';
import ThemeContext from 'helpers/ThemeContext';
import JSCIM from 'helpers/JSCIM';
import { BETA_PERMISSION } from 'helpers/permissions';
import AssetSchedule from '../partials/AssetSchedule';
import LifecycleSection from '../partials/LifecycleSection';
import InstanceInfo from './InstanceInfo';
import TapChangerSection from './TapChangerSection';
import EditableControlMode from '../partials/EditableControlMode';
import LinkDeviceHeader from '../partials/LinkDeviceHeader';
import './PowerTransformer.scss';
import { transposePhaseAttributes } from '../../helpers/valueExtractors';
import SeasonalRatingsViewer from '../partials/SeasonalRatingsViewer';

const controlHelp = {
  globallyOptimized:
    "During powerflow and timeseries powerflow, regulators set to 'global' will operate according to their 'fixed-tap' configuration.",
  uncontrolled:
    "During powerflow and timeseries powerflow, regulators set to 'fixed' will operate at a constant step value.",
};

const { validationSchema } = JSCIM.PowerTransformer;

const controlPanelValues = {
  uncontrolled: [
    {
      label: 'Tap Position',
      id: 'normalStep',
      step: '1',
      precision: 1,
      type: 'number',
      customValidation: validationSchema.normalStep.customValidation,
    },
  ],
  locallyControlled: [
    {
      label: 'Regulation Voltage',
      id: 'targetValue',
      unit: 'p.u.',
      type: 'number',
      step: '0.001',
      precision: 3,
      customValidation: validationSchema.targetValue.customValidation,
    },
    {
      label: 'Bandwidth',
      id: 'targetDeadband',
      unit: '%',
      type: 'number',
      step: '0.001',
      precision: 3,
      customValidation: validationSchema.targetDeadband.customValidation,
    },
  ],
};

const useMemoizedControlPanelValues = xfmr => {
  const xfmrClone = { ...xfmr };
  const originalPhase = xfmrClone.phases;
  if (xfmrClone.phases.length > 1) {
    xfmrClone.phases = xfmrClone.phases.replace('N', '');
    xfmr.phases = xfmrClone.phases;
  }

  if (
    Object.keys(xfmrClone.ratio_tap_changer_attributes)[0]?.length > 1 &&
    originalPhase.includes('N')
  ) {
    const updatedPhase = xfmrClone.phases;
    delete Object.assign(xfmrClone.ratio_tap_changer_attributes, {
      [updatedPhase]: xfmrClone.ratio_tap_changer_attributes[originalPhase],
    })[originalPhase];
    xfmr = xfmrClone;
  }
  // need to memoize this otherwise controlpanel keeps rerendering
  const [original, setOriginal] = useState(xfmr);
  const [transposed, setTransposed] = useState(
    xfmr
      ? {
          ...transposePhaseAttributes(xfmr.ratio_tap_changer_attributes),
          ...transposePhaseAttributes(xfmr.tap_changer_control_attributes),
        }
      : {},
  );

  if (JSON.stringify(xfmr) !== JSON.stringify(original) && xfmr) {
    setOriginal(xfmr);
    setTransposed(
      xfmr
        ? {
            ...transposePhaseAttributes(xfmr.ratio_tap_changer_attributes),
            ...transposePhaseAttributes(xfmr.tap_changer_control_attributes),
          }
        : {},
    );
  }

  return [transposed];
};

const PowerTransformer = ({
  asset: xfmr,
  workspace,
  branch,
  displayBranch,
  selected: { id, class: assetClass },
  applyDifferenceModelRequest,
  toggleFeederPanel,
  canEditNetwork,
  inEditMode,
  loadForecast: { selectedScenario, selectedScenarioType, selectedAnalysis },
  setSelectedAssetID,
  editActions,
  expanded,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  isAuthEnabled,
  permissions,
}) => {
  const theme = useContext(ThemeContext);
  // this needs to be cached in state so we don't rerender the EditableControlMode everytime
  const [controlModePerPhaseAttributes] = useMemoizedControlPanelValues(xfmr);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body =>
    editActions.editSingleEquipment(workspace, branch, 'power_transformer', id, body);

  const controlMode = xfmr.analysis_control?.mode;
  const isRegulator = Object.values(xfmr.transformer_ends_info ?? {}).reduce(
    (prev, curr) => prev && curr['1'].ratedU === curr['2'].ratedU,
    true,
  );
  const hasTapChanger = Object.keys(xfmr.ratio_tap_changer_attributes ?? {}).length > 0;

  return (
    <div
      className={classNames({
        'asset-panel-values': true,
        'asset-panel-values--expanded': expanded,
        'energy-consumer': true,
      })}
    >
      <LinkDeviceHeader
        asset={xfmr}
        disabled={!canEditNetwork || saving}
        toggleFeederPanel={toggleFeederPanel}
        handleSave={handleSave}
        inEditMode={inEditMode}
        setSelectedAssetID={setSelectedAssetID}
        iconName={isRegulator ? 'regulator' : 'transformer'}
        theme={theme}
      />

      <InstanceInfo
        asset={xfmr}
        assetId={id}
        handleSave={handleSave}
        displayBranch={displayBranch}
        workspace={workspace}
        disabled={!canEditNetwork || saving}
        inEditMode={inEditMode}
        theme={theme}
        expanded={expanded}
      />
      <SeasonalRatingsViewer
        workspace={workspace}
        branch={branch}
        assetId={id}
        scenarioId={selectedScenario}
      />

      <hr className="section-divider" />
      <TapChangerSection
        asset={xfmr}
        assetId={id}
        handleSave={handleSave}
        isRegulator={isRegulator}
        branch={branch}
        workspace={workspace}
        disabled={!canEditNetwork || saving}
        inEditMode={inEditMode}
        theme={theme}
      />

      {!inEditMode && hasTapChanger && permissions.has(BETA_PERMISSION) && (
        <>
          <hr className="section-divider" />
          <AssetSchedule
            id="power-transformer-cost"
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Cost"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        </>
      )}

      {hasTapChanger && (
        <>
          <hr className="section-divider" />
          <EditableControlMode
            controlMode={controlMode}
            help={controlHelp[controlMode]}
            asset={assetClass}
            phases={xfmr.phases}
            balanced={xfmr.balanced}
            editablePerPhaseValues={controlPanelValues[controlMode]}
            perPhaseAttributes={controlModePerPhaseAttributes}
            attributeTypeMapping={{
              normalStep: 'ratio_tap_changer_attributes',
              targetValue: 'tap_changer_control_attributes',
              targetDeadband: 'tap_changer_control_attributes',
            }}
            disabled={saving || !canEditNetwork}
            onSave={handleSave}
            theme={theme}
            inEditMode={inEditMode}
            selectedAnalysis={selectedAnalysis}
            selectedAssetId={id}
            workspace={workspace}
            branch={branch}
          >
            {controlMode === 'scheduled' && !inEditMode && (
              <AssetSchedule
                id="power-transformer-normal"
                workspace={workspace}
                branch={branch}
                scenario={selectedScenario}
                scenarioType={selectedScenarioType}
                asset={{ id, class: assetClass }}
                scheduleType="Normal"
                timeRange={timeRange}
                maxRange={maxRange}
                // panelValues={{ ...controlModeAttributes }} TODO FIXME
                timeBarZoomLevel={timeBarZoomLevel}
                editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
                expanded={expanded}
              />
            )}
          </EditableControlMode>
        </>
      )}

      <LifecycleSection
        disabled={saving || !inEditMode || !canEditNetwork}
        lifecycle={xfmr.lifecycle || {}}
        onChange={lifecycle => handleSave({ lifecycle })}
        inServiceOnly
        healthMetric={xfmr.reliability_info?.healthMetric}
        onHealthMetricChange={handleSave}
      />
    </div>
  );
};

PowerTransformer.propTypes = {
  asset: PropTypes.object.isRequired,
  applyDifferenceModelRequest: PropTypes.number.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
    selectedAnalysis: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  displayBranch: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  editActions: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default PowerTransformer;
