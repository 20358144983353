import React, { FunctionComponent } from 'react';
import IconButton from 'components/IconButton';
import Icons from 'components/Icons';
import Button from 'components/Button';
import { BETA_PERMISSION } from 'helpers/permissions';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import { viewOptions } from 'helpers/Constants';
import './HamburgerMenu.scss';

type HamburgerMenuProps = {
  closeMenu: () => void;
  onViewChange: (val: string) => void;
  currentView: string;
  menuActive: boolean;
};

const HamburgerMenu: FunctionComponent<HamburgerMenuProps> = ({
  closeMenu,
  onViewChange,
  currentView,
  menuActive,
}) => {
  const { CloseIcon } = Icons;
  const { permissions } = useAuthContext();
  const { overview, scenario, gis, comparison, library, cim, import: importValidate } = viewOptions;
  const navigationButton = (viewObj: { [key: string]: string }, iconName = '', outlined = true) => (
    <Button
      type="text"
      onClick={() => onViewChange(viewObj.value)}
      className={currentView === viewObj.value ? 'active' : ''}
    >
      <i className={`material-icons${outlined ? '-outlined' : ''}`}>{iconName}</i> {viewObj.title}
    </Button>
  );
  return (
    <div className={`hamburger-menu ${menuActive ? 'active' : ''}`}>
      <div className="overlay" onMouseDown={closeMenu} aria-hidden="true" />
      <div className={`hamburger-menu-navbar ${menuActive ? 'active' : ''}`}>
        <IconButton onClick={closeMenu} className="close-icon">
          <CloseIcon color="#ffffff" />
        </IconButton>
        <div className="nav-links">
          <p>
            <strong>Planning</strong>
          </p>
          {navigationButton(overview, 'home')}
          {navigationButton(gis, 'map')}
          {navigationButton(comparison, 'trending_up')}
          <Button type="text" onClick={() => {}}>
            <i className="material-icons-outlined">edit_calendar</i> Manage
          </Button>
          <div className="nav-links-sub">
            {navigationButton(scenario, 'fiber_manual_record', false)}
            {navigationButton(library, 'fiber_manual_record', false)}
            {permissions.has(BETA_PERMISSION) &&
              navigationButton(importValidate, 'fiber_manual_record', false)}
          </div>
          {navigationButton(cim, 'storage')}
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
