import React, { FunctionComponent, useState } from 'react';
import ActionButtons from 'components/ActionButtons';

import { SimulationOptions } from 'types/analysis';
import OPFOptions from './OPFOptions';

type OperationalEnvelopeProps = {
  substations: { id: string }[];
  simulationOptions: SimulationOptions;
  setSimulationOptions: React.Dispatch<React.SetStateAction<SimulationOptions>>;
  selectedAnalysisType: string;
  onSubmit: () => void;
  onPreviousClick: () => void;
  disableSubmit: boolean;
  errorMessage: string;
  toggleModal: (modalOpen: boolean) => void;
};

const OperationalEnvelopeMenu: FunctionComponent<OperationalEnvelopeProps> = ({
  onSubmit,
  onPreviousClick,
  simulationOptions,
  setSimulationOptions,
  selectedAnalysisType,
  substations,
  disableSubmit,
  errorMessage,
  toggleModal,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  return (
    <div className="operation-envp-menu config-panel">
      <OPFOptions
        simulationOptions={simulationOptions}
        setSimulationOptions={setSimulationOptions}
        substationId={substations && substations.length ? substations[0].id : undefined}
        disableButton={setButtonDisabled}
        selectedAnalysisType={selectedAnalysisType}
      />
      <div className="menu-footer">
        <ActionButtons
          className="analysis-action-btns"
          onClickBackBtn={onPreviousClick}
          disableSubmit={disableSubmit || buttonDisabled}
          onClickSubmitBtn={() => onSubmit()}
          toggleModal={toggleModal}
          title={errorMessage}
          submitLabel="Run Analysis"
          submitBtnId="submit-qsts"
        />
      </div>
    </div>
  );
};

export default OperationalEnvelopeMenu;
