import React, { FunctionComponent, useContext, useState } from 'react';
import Modal from 'components/Modal';
import GridLayout from 'layouts/GridLayout';
import Select from 'components/Select';
import { isDefined } from 'helpers/utils';
import TargetIcon from 'components/Icons/TargetIcon';
import {
  useAuthContext,
  useRequest,
  useRequestEffect,
} from '@opusonesolutions/gridos-app-framework';
import ResultsCard from 'components/ResultsCard';
import { useToasts } from 'react-toast-notifications';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { Scenario } from 'helpers/scenarios';
import useTrackingState from 'hooks/useTrackingState';
import IconButton from 'components/IconButton';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import { ExternalScenarioDataType } from '../helpers/ScenarioGenerationHelpers';

type ExternalSourceModalProps = {
  externalScenarioData?: ExternalScenarioDataType;
  workspace: string;
  branch: string;
};

type ExternalSchedulesData = {
  id: string;
  name: string;
}[];

const ExternalSourceModal: FunctionComponent<ExternalSourceModalProps> = ({
  externalScenarioData = [],
  workspace,
  branch,
}) => {
  const { addToast } = useToasts();
  const { permissions } = useAuthContext();
  const { resetModalType, selectedScenario, resetScenario, setSelectedScenario } = useContext(
    ScenarioGenerationContext,
  );
  const { analyticsConfig } = useContext(WorkspaceSettingsContext);
  const sourceOptions = Object.entries(
    (analyticsConfig?.schedule_sources as { [key: string]: { [key: string]: string } }) ?? [],
  ).map(([id, source]) => ({
    value: parseInt(id, 10),
    label: `${source.name} - ${source.url}`,
  }));
  const [source, setSource] = useTrackingState<number | null>(
    externalScenarioData[0]?.schedule_source_id ?? null,
  );
  const [selectedSchedule, setSelectedSchedule] = useTrackingState<string | null>(
    externalScenarioData[0]?.external_scenario || null,
  );
  const [externalSourceToDel, setExternalSourceToDel] = useState<number | null>(null);
  const { makeRequest: externalScheduleRequest, loading } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${selectedScenario?.value}/schedule-source`,
  );
  const generateExternalSchedule = async () => {
    try {
      const currentScenario = selectedScenario;
      await externalScheduleRequest({
        method: 'put',
        body: {
          schedule_source_id: source,
          priority: 10,
          external_scenario: selectedSchedule,
        },
        toast: {
          error: 'Request to external server failed',
          settings: {
            autoDismiss: true,
          },
        },
        onSuccess: () => {
          resetModalType();
          resetScenario();
          setSelectedScenario(currentScenario as Scenario);
        },
      });
    } catch (error) {}
  };
  const {
    data: externalSchedules,
    loading: getSourceListLoading,
  } = useRequestEffect<ExternalSchedulesData>({
    url: `/api/workspace/${workspace}/analytics-config/schedule-source/${source}/external-scenarios`,
    method: 'get',
    refetchOnChange: [workspace, source],
    blockRequest: () => !(workspace && isDefined(source)),
    onError: err => {
      let errorMSg = 'Failed to get external schedules from selected source';
      if (
        err?.response?.status &&
        [502].includes(err?.response?.status) &&
        err?.response?.data?.message
      ) {
        errorMSg = err?.response?.data?.message;
      }
      addToast(errorMSg, { appearance: 'error' });
    },
  });
  const { makeRequest: runDeleteExternalSchedule, loading: deleting } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${selectedScenario?.value}/schedule-source/${externalSourceToDel}`,
  );
  const deleteExternalSchedule = async () => {
    try {
      const currentScenario = selectedScenario;
      await runDeleteExternalSchedule({
        method: 'delete',
        toast: {
          error: 'Could not disconnect external source',
        },
        onSuccess: () => {
          setExternalSourceToDel(null);
          resetModalType();
          resetScenario();
          setSelectedScenario(currentScenario as Scenario);
        },
      });
    } catch (err) {}
  };
  return (
    <Modal
      active
      title="Update external schedule"
      onConfirm={() =>
        isDefined(externalSourceToDel) && !isDefined(source)
          ? deleteExternalSchedule()
          : generateExternalSchedule()
      }
      onCancel={() => resetModalType()}
      modalType="primary"
      width="600px"
      height="350px"
      labels={{
        confirm: loading || deleting ? <i className="material-icons rotate">refresh</i> : 'Done',
        cancel: 'Cancel',
      }}
      scrollBody
      disableConfirm={
        !(
          ((isDefined(source) && selectedSchedule) || isDefined(externalSourceToDel)) &&
          !loading &&
          !deleting
        )
      }
    >
      <GridLayout>
        <div className="grid-columns one-two margin-10 schedule-details">
          <div className="header flex-end margin-10">
            <div style={{ marginRight: '10px' }}>Schedule details</div>
            <TargetIcon />
          </div>
          <div>
            <ResultsCard withBorder={false} className="schedule-details-card">
              <div className="flex-row">
                <div>
                  <Select
                    label="Select URL"
                    onChange={e => {
                      setSource(e.value);
                      setSelectedSchedule(null);
                    }}
                    id="select-asset"
                    options={sourceOptions}
                    value={source}
                    type="secondary"
                    clearable={false}
                  />
                  <div className="margin-10">
                    {getSourceListLoading ? (
                      <LoadingSkeleton template="line" width={100} />
                    ) : (
                      <Select
                        label="Select schedule"
                        onChange={e => setSelectedSchedule(e.value)}
                        id="select-schedule"
                        options={
                          externalSchedules?.map(sce => ({ label: sce.name, value: sce.id })) || []
                        }
                        value={selectedSchedule}
                        type="secondary"
                        clearable={false}
                        disabled={!isDefined(source) || getSourceListLoading}
                        width={300}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <IconButton
                    icon="delete"
                    onClick={() => {
                      setSource(null);
                      setSelectedSchedule(null);
                      setExternalSourceToDel(source);
                    }}
                    tooltip="Disconnect external source"
                    disabled={
                      !(
                        isDefined(externalScenarioData[0]?.schedule_source_id) &&
                        isDefined(source) &&
                        externalScenarioData[0]?.schedule_source_id === source &&
                        externalScenarioData[0]?.external_scenario &&
                        permissions.has('alter_qsts_scenarios')
                      )
                    }
                  />
                </div>
              </div>
            </ResultsCard>
            <p className="text-bold">Note: You can add URL to the list in your admin panel</p>
          </div>
        </div>
      </GridLayout>
    </Modal>
  );
};

export default ExternalSourceModal;
