import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const QualifiedNameIcon: FunctionComponent<IconPropsSchema> = ({ color, height, width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 13.2 10.8"
    height={height}
    width={width}
  >
    <g>
      <path
        fill={color}
        d="M4.8,3c0-0.7-0.2-1.3-0.6-1.6C3.8,1,3.3,1,3.3,1H0.6v1l2.7,0c0,0,0.1,0,0.2,0.1C3.7,2.3,3.8,2.6,3.8,3v1.3H1.5
        C0.7,4.3,0,5,0,5.8V9c0,0.8,0.7,1.5,1.5,1.5h3.3V4.3h0V3z M3.8,9.5H1.5C1.2,9.5,1,9.3,1,9V5.8c0-0.3,0.2-0.5,0.5-0.5h2.3V9.5z"
      />
      <circle fill={color} cx="6.1" cy="10.1" r="0.8" />
      <path
        fill={color}
        d="M12.2,4.8c0.3-0.3,0.5-0.8,0.5-1.2V1.9c0-1-0.9-1.9-1.9-1.9H7.6v4.5v1v5.1l3.6,0c1.1,0,2-1,2-2.1V6.6
        C13.2,5.8,12.8,5.2,12.2,4.8z M8.6,1h2.2c0.5,0,0.9,0.4,0.9,0.9v1.7c0,0.5-0.4,0.9-0.9,0.9H8.6V1z M12.2,8.5c0,0.6-0.4,1.1-1,1.1
        l-2.6,0V5.5l2.6,0c0.6,0,1,0.5,1,1.1V8.5z"
      />
    </g>
  </svg>
);

export default QualifiedNameIcon;
