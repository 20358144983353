import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withAuth from 'helpers/withAuth';
import * as appStateReducer from 'store/appState';
import { actions } from '../modules/network';
import { feederActions } from '../modules/feeders';
import { powerflowActions } from '../modules/powerflow';
import { loadForecastActions } from '../modules/loadForecast';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import Network from '../components/Network';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...actions,
      ...feederActions,
      ...powerflowActions,
      ...appStateReducer.actions,
      ...loadForecastActions,
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  network: state.network,
  feeders: state.feeders,
  edit: state.edit,
  theme: state.global.theme,
  view: state.global.view,
  fixersRunning: state.validation.fixersRunning,
  loadForecast: state.loadForecast,
  jobQueued: state.powerflow.jobQueued,
  inEditMode: state.edit.inEditMode,
  layerOptions: state.powerflow.layerOptions,
  layerOptionsUpdated: state.powerflow.layerOptionsUpdated,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Network));
