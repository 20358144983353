import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import RadioButtonGroup from 'components/RadioButtonGroup';
import { Scenario } from 'helpers/scenarios';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import { ExternalScenarioDataType } from '../helpers/ScenarioGenerationHelpers';

type PrioritySelectorProps = {
  externalScenarioData?: ExternalScenarioDataType;
  refetchExternalScenarioData: () => void;
  loadingExternalSceData?: boolean;
  workspace: string;
  branch: string;
};

const PrioritySelector: FunctionComponent<PrioritySelectorProps> = ({
  externalScenarioData = [],
  refetchExternalScenarioData,
  loadingExternalSceData = false,
  workspace,
  branch,
}) => {
  const { selectedScenario, resetScenario, setSelectedScenario } = useContext(
    ScenarioGenerationContext,
  );
  const [selectedPriority, setSelectedPriority] = useState(0);
  useEffect(() => {
    if (externalScenarioData[0]?.priority !== selectedPriority)
      setSelectedPriority(externalScenarioData[0]?.priority);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalScenarioData]);
  const { makeRequest: updatePriorityRequest, loading: sendingPriorityReq } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${selectedScenario?.value}/schedule-source`,
  );
  const updatePriorityforSchedule = async (prioritySel: number) => {
    const currentScenario = selectedScenario;
    try {
      await updatePriorityRequest({
        method: 'put',
        body: {
          schedule_source_id: externalScenarioData[0]?.schedule_source_id,
          priority: prioritySel,
          external_scenario: externalScenarioData[0]?.external_scenario,
        },
        toast: {
          error: 'Priority failed to update.',
          settings: {
            autoDismiss: true,
          },
        },
        onError: () => refetchExternalScenarioData(),
        onSuccess: () => {
          resetScenario();
          setSelectedScenario(currentScenario as Scenario);
        },
      });
    } catch (error) {}
  };
  const loadingData = loadingExternalSceData || sendingPriorityReq;
  return (
    <>
      {loadingData && <LoadingSkeleton template="square" width={50} height={30} />}
      {externalScenarioData.length > 0 && !loadingData && (
        <div className="priority-selector">
          <div className="label-light header">Prioritize</div>
          <RadioButtonGroup
            options={[
              { id: 10, label: 'External' },
              { id: -10, label: 'Internal' },
            ]}
            id="priority-selector"
            value={selectedPriority}
            listType="row"
            radioType="primary"
            onChange={({ target }: any) => {
              setSelectedPriority(target.value);
              if (externalScenarioData?.[0]?.priority !== target.value)
                updatePriorityforSchedule(target.value);
            }}
          />
        </div>
      )}
    </>
  );
};

export default PrioritySelector;
