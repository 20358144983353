import React, { useEffect, useState, useContext } from 'react';
import { useRequest } from '@opusonesolutions/gridos-app-framework';
import { IntlContext } from 'contexts/IntlContext';
import PropTypes from 'prop-types';
import { isDefined } from 'helpers/utils';

const ProbabilityOfFailure = ({
  workspace,
  branch,
  scenario,
  analysisName,
  maxRange,
  feeder,
  assetID,
}) => {
  const { valueFormatter } = useContext(IntlContext);
  const [probabilityValue, setProbabilityValue] = useState(null);
  const { makeRequest: fetchProbabilityOfFailureRequest } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/power-flow-results/reliability`,
  );
  const fetchProbabilityOfFailure = async () => {
    let probabilityOfFailure = null;
    if (
      workspace &&
      branch &&
      scenario &&
      analysisName &&
      maxRange.start &&
      maxRange.end &&
      feeder &&
      assetID
    ) {
      await fetchProbabilityOfFailureRequest({
        method: 'get',
        params: {
          feeder,
          scenario_id: scenario,
          start_date: maxRange.start.toISOString(),
          end_date: maxRange.end.toISOString(),
          analysis_name: analysisName,
          asset_id: assetID,
        },
        onSuccess: data => {
          probabilityOfFailure = data?.probability_of_failure[0]?.value;
        },
        onError: () => {
          probabilityOfFailure = null;
        },
        headers: {
          'Cache-Control': 'no-cache, no-store',
          Pragma: 'no-cache',
          Expires: '0',
        },
        responseType: 'json',
      });
    }
    setProbabilityValue(probabilityOfFailure);
  };
  useEffect(() => {
    fetchProbabilityOfFailure();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, scenario, analysisName, maxRange, feeder, assetID]);
  return (
    <div className="propability-section">
      <span className="probability-label">Annual Probability of Failure</span>
      <span className="propability-value">
        {isDefined(probabilityValue) ? `${valueFormatter.format(probabilityValue)} %` : '-'}
      </span>
    </div>
  );
};
ProbabilityOfFailure.defaultProps = {
  analysisName: '',
  scenario: '',
  maxRange: {},
};
ProbabilityOfFailure.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  scenario: PropTypes.string,
  analysisName: PropTypes.string,
  maxRange: PropTypes.object,
  feeder: PropTypes.string.isRequired,
  assetID: PropTypes.string.isRequired,
};
export default ProbabilityOfFailure;
