import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../Icons';

const { CheckmarkIcon, WarningIcon } = Icons;
/**
 * Creates a statusbar component with configurable shape, color and progress text.
 * @class StatusBar
 */
const StatusBar = props => {
  const { backgroundColor, barColor } = props;
  // Border radius for shape types
  const shapes = {
    round: '20px',
    square: '0',
  };

  // Create the error symbol. Displayed in place of progress
  const errorSymbol = <WarningIcon color="red" height="20px" width="20px" />;

  /**
   * Determine what to show in the progress area.
   * There are 3 options :
   *  - checkmark for complete
   *  - error symbol for uploads
   *  - percent value when loading
   *  @param  {String}  status    Current upload status value
   *  @param  {Number}  progress  Upload progress value (percent value)
   *  @param  {Boolean} complete  If the file has been uploaded and processed
   *  @return {JSX}               Styled container with the correct progress value
   */
  const getFeedbackType = (status, progress, complete) => {
    let feedback;
    if (status === 'error') {
      feedback = errorSymbol;
    } else if (complete) {
      feedback = <CheckmarkIcon height={15} width={15} color="green" />;
    } else if (progress) {
      feedback = `${progress} %`;
    }
    return (
      <div className="status-bar-percent-sides" style={{ color: barColor }}>
        {feedback}
      </div>
    );
  };

  /**
   * Determine what to show under the status bar as a label.
   *  @param  {String}  status      Current upload status value
   *  @param  {Boolean} processing  If the file has been uplaoded  but still processing
   *  @param  {Boolean} complete    If the file has been uploaded and processed
   *  @return {JSX}                 Styled container with the correct progress label
   */
  const getLabel = (status, processing, complete) => {
    if (status === 'error') {
      return null;
    }

    let label;
    if (processing) {
      label = 'Processing...';
    } else if (complete) {
      label = 'Complete';
    } else {
      label = 'Waiting...';
    }

    return <div className="status-bar-label">{label}</div>;
  };

  // Hide if progress is undefined or null
  const display = props.progress === null ? 'none' : 'inline-block';
  const height = props.showPercent === 'center' ? '24px' : '9px';
  const { status, processing, complete, progress, showLabel, showPercent, cornerType } = props;
  return (
    <div>
      <div className="status-details">
        {showPercent === 'left' && getFeedbackType(status, progress, complete)}
        <div
          style={{
            borderRadius: shapes[cornerType],
            display,
            height,
            backgroundColor,
          }}
          className="status-bar-container"
        >
          <div
            className="status-bar"
            style={{ backgroundColor: barColor, width: `${progress}%`, textAlign: 'center' }}
          >
            {processing && <div className="status-bar-stripes" />}
          </div>
          {showPercent === 'center' && getFeedbackType(status, progress, complete)}
        </div>
        {showPercent === 'right' && getFeedbackType(status, progress, complete)}
      </div>
      {showLabel && getLabel(status, processing, complete)}
    </div>
  );
};

StatusBar.defaultProps = {
  barColor: '#5A9CDE',
  backgroundColor: '#D4D4D4',
  cornerType: 'square',
  progress: null,
  showPercent: null,
  showLabel: false,
  processing: false,
  complete: false,
  status: null,
};

StatusBar.propTypes = {
  /*-*/ progress: PropTypes.number,
  /*-*/ cornerType: PropTypes.oneOf(['round', 'square']),
  /*-*/ barColor: PropTypes.string,
  /*-*/ backgroundColor: PropTypes.string,
  /*-*/ showPercent: PropTypes.oneOf(['left', 'right', 'center']),
  /*-*/ showLabel: PropTypes.bool,
  /*-*/ processing: PropTypes.bool,
  /*-*/ complete: PropTypes.bool,
  /*-*/ status: PropTypes.string,
};

export default StatusBar;
