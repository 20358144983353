/**
 * Determine if a transformer is a regulator or not.
 * Regulators are determined using the following logic:
 *  The transformer has a `RatioTapChanger`
 *  The `RatioTapChanger` references a `TapChangerControl`
 *  The `ratedU` on both sides of the transformer is the same
 * @param  {Object} transformer Instance of a transformer
 * @param  {Object} lookup      Lookup of all CIM instances
 * @return {Object}             Regulator and Tap Changer details if they exist.
 */
const isRegulator = (transformer, lookup) => {
  let regulator;
  const ratedVoltages = new Set();

  if (transformer.references['PowerTransformer.TransformerTanks']) {
    const tanks = transformer.references['PowerTransformer.TransformerTanks'].map(
      ref => lookup[ref],
    );
    const tankEnds = tanks.map(tank =>
      tank.references['TransformerTank.TransformerTankEnds'].map(ref => lookup[ref]),
    );
    const tankInfos = tanks.map(
      tank => lookup[tank.references['PowerSystemResource.AssetDatasheet']],
    );

    tankEnds.forEach(end => {
      const ratio = end.filter(e => e.references['TransformerEnd.RatioTapChanger']);

      if (ratio.length > 0) {
        const ratioInstance = ratio.map(
          r => lookup[r.references['TransformerEnd.RatioTapChanger']],
        );
        const hasControl = ratioInstance.find(r => r.references['TapChanger.TapChangerControl']);

        if (hasControl) {
          regulator = transformer;
        }
      }
    });

    tankInfos.forEach(tankInfo => {
      if (tankInfo) {
        const endInfos = tankInfo.references['TransformerTankInfo.TransformerEndInfos'].map(
          ref => lookup[ref],
        );
        endInfos.forEach(endInfo => {
          if (endInfo && 'TransformerEndInfo.ratedU' in endInfo.attributes) {
            ratedVoltages.add(endInfo.attributes['TransformerEndInfo.ratedU']);
          }
        });
      }
    });
  }

  if (ratedVoltages.size > 1) {
    // Multiple voltage levels. This is a transformer even if there is an LTC
    regulator = undefined;
  }

  return regulator;
};

const getTapChangers = (transformer, lookup) => {
  let tapChanger;

  if (transformer.references['PowerTransformer.TransformerTanks']) {
    const tanks = transformer.references['PowerTransformer.TransformerTanks'].map(
      ref => lookup[ref],
    );
    const tankEnds = tanks.map(tank =>
      tank.references['TransformerTank.TransformerTankEnds'].map(ref => lookup[ref]),
    );

    tankEnds.forEach(end => {
      const ratio = end.filter(e => e.references['TransformerEnd.RatioTapChanger']);

      if (ratio.length > 0) {
        const ratioInstance = ratio.map(
          r => lookup[r.references['TransformerEnd.RatioTapChanger']],
        );
        const hasControl = ratioInstance.find(r => r.references['TapChanger.TapChangerControl']);

        if (hasControl) {
          tapChanger = ratioInstance;
        }
      }
    });
  }
  return tapChanger;
};

/**
 * Filter transformers into regulators and normal transformers
 * @param  {Array}  transformers  List of PowerTransformer ids
 * @param  {Object} lookup        Lookup of all CIM objects
 * @return {Object}               Lists of regulator and transformer IDs.
 */
const filterTransformers = (transformers, lookup) => {
  const transformerInstances = transformers.map(transformer => {
    const instance = lookup[transformer];
    instance.id = transformer;
    return instance;
  });
  const regulators = [];
  transformerInstances.forEach(transformer => {
    const regulator = isRegulator(transformer, lookup);
    if (regulator && !regulators.find(reg => reg.id === regulator.id)) {
      regulators.push(transformer);
    }
  });
  const powerTransformers = transformerInstances.filter(
    transformer => !regulators.includes(transformer),
  );
  const powerTransformerIds = powerTransformers.map(pt => pt.id);
  const regulatorIds = regulators.map(r => r.id);
  return { regulators: regulatorIds, transformers: powerTransformerIds };
};

export { filterTransformers, getTapChangers, isRegulator };
