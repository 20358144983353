import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';

// Create a dropdown input for a single phase of a value type
const PerPhaseSelect = props => {
  const { phase, value, edited, id, onChange, options, defaultValue } = props;

  const formattedValue = value[phase] ? value[phase].toString() : defaultValue;
  const editedValue = edited && !!edited[phase];

  return (
    <Select
      onChange={({ value: val }) => onChange(val, id, phase)}
      value={formattedValue}
      className={`per-phase-row-select ${editedValue ? 'per-phase-row-select--edited' : ''}`}
      options={options}
      clearable={false}
      searchable={false}
      theme={useContext(ThemeContext)}
    />
  );
};

PerPhaseSelect.defaultProps = {
  defaultValue: '',
  edited: null,
  onChange: null,
};

PerPhaseSelect.propTypes = {
  phase: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  edited: PropTypes.object,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
};

export default PerPhaseSelect;
