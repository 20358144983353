import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import Modal from 'components/Modal';
import Select from 'components/Select';
import StatusBar from 'components/StatusBar';
import ThemeContext from 'helpers/ThemeContext';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import { useRequestEffect, Request } from '@opusonesolutions/gridos-app-framework';

import getBadgedAnalysisOption from '../routes/Network/components/getBadgedAnalysisOption';
import './DropdownSections.scss';
import AnalysisModal from '../routes/Network/containers/AnalysisModalContainer';
import {
  getAnalyses,
  ACTIVITY_LOG_STATUS,
  ANALYSIS_TYPES,
} from '../routes/Network/helpers/NetworkHelpers';
import SelectType from './helpers';
import ActionMenu from './ActionMenu';

const modalModes = Object.freeze({
  hidden: 'hidden',
  delete: 'delete',
  create: 'create',
  edit: 'edit',
  complete: 'complete',
  confirm: 'confirm',
});

const AnalysisSection = ({
  workspace,
  branch,
  selectedScenario,
  selectedAnalysis,
  actions: { setSelectedAnalysis, setActivePanel },
  permissions,
}) => {
  const { logEntries, analysisActive } = useContext(ActivityLogContext);
  const [analyses, setAnalyses] = useState([]);
  const [modalMode, setModalMode] = useState(false);
  const [scheduleInProgress, setScheduleInProgress] = useState(false);
  const theme = useContext(ThemeContext);
  const [selectedType, setSelectedType] = useState(SelectType.NONE);

  const { data: migrationNeeded } = useRequestEffect({
    url: `/api/workspace/${workspace}/branch/${branch}/asset_schedule/migration_needed`,
    method: 'get',
    params: {
      scenario_id: selectedScenario,
    },
    refetchOnChange: [workspace, branch, selectedScenario, scheduleInProgress],
    blockRequest: () => !selectedScenario,
  });
  // seems to be a strange bug with useCallback here that causes issues for the
  // tests so using useMemo instead
  const fetchAnalyses = useMemo(
    () => async cancellationToken => {
      const analysesList = await getAnalyses(
        workspace,
        branch,
        selectedScenario,
        permissions,
        cancellationToken,
      );
      setAnalyses(analysesList);
    },
    [workspace, branch, selectedScenario, permissions],
  );

  async function deleteAnalysis() {
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/analysis/${selectedAnalysis.id}`,
    );
    try {
      await request.delete();
      setSelectedAnalysis(null, permissions);
      setModalMode(modalModes.hidden);
    } catch {
      // TODO: handle delete errors
    }
  }
  const upgradeAssetSchedule = async () => {
    setScheduleInProgress(true);
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset_schedule/upgrade`,
    );
    try {
      await request.post({
        scenario_id: selectedScenario,
      });
    } catch (error) {
      setScheduleInProgress(false);
    }
  };

  useEffect(() => {
    const cancellationToken = { cancelled: false };
    fetchAnalyses(cancellationToken);
    return () => {
      cancellationToken.cancelled = true;
    };
  }, [selectedScenario, selectedAnalysis, fetchAnalyses]);

  useEffect(() => {
    // if the workspace, branch or scenario change clear the selected analysis
    setSelectedAnalysis(null, permissions);
    setScheduleInProgress(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, selectedScenario]);

  useEffect(() => {
    const preAggEntry = logEntries.find(
      x =>
        x.branch === branch &&
        x.scenario_id === selectedScenario &&
        [
          ANALYSIS_TYPES.ASSET_SCHEDULE_PREAGG_MIGRATION,
          ANALYSIS_TYPES.BULK_SCHEDULE_GENERATION,
        ].includes(x.activity_type),
    );
    if (!scheduleInProgress && preAggEntry?.status === ACTIVITY_LOG_STATUS.RUNNING) {
      setScheduleInProgress(true);
    } else if (
      (modalMode === modalModes.confirm || scheduleInProgress) &&
      preAggEntry?.status === ACTIVITY_LOG_STATUS.COMPLETED
    ) {
      setModalMode(modalModes.create);
      setScheduleInProgress(false);
    }
  }, [branch, selectedScenario, logEntries, scheduleInProgress, modalMode]);

  const usedAnalysisNames = analyses.map(x => x.name);
  const permissionToCreateAnalysis = () =>
    permissions.has('run_powerflow') ||
    permissions.has('run_powerflow_qsts') ||
    permissions.has('run_powerflow_qsts_day') ||
    permissions.has('run_powerflow_qsts_month') ||
    permissions.has('run_hosting_capacity') ||
    permissions.has('run_hosting_capacity_month') ||
    permissions.has('run_network_hosting_capacity') ||
    permissions.has('run_network_hosting_capacity_month');

  const getCreateAnalysisToolTip = () => {
    let createAnalysisTooltip = 'Create Analysis';
    if (!permissionToCreateAnalysis) {
      createAnalysisTooltip = 'You do not have permission to create analysis'; // permission disabled tooltip shows
    } else if (!selectedScenario) {
      createAnalysisTooltip = 'Select a scenario to create an analysis';
    } else if (scheduleInProgress) {
      createAnalysisTooltip = 'Schedule Generation/Upgrade in progress';
    }
    return createAnalysisTooltip;
  };

  const getDeleteAnalysisToolTip = () => {
    const allowedToDeleteAnalysis = permissions.has('delete_analysis');
    let deleteAnalysisTooltip = 'Delete Analysis';
    if (!allowedToDeleteAnalysis) {
      deleteAnalysisTooltip = 'You do not have permission to delete analysis'; // permission disabled tooltip shows
    } else if (
      !selectedScenario ||
      !selectedAnalysis ||
      analysisActive(branch, selectedScenario, selectedAnalysis?.name)
    ) {
      deleteAnalysisTooltip = 'Select a finished analysis to delete it';
    }
    return deleteAnalysisTooltip;
  };

  const getActionMenuOptions = () => [
    {
      id: 'addAnalysis',
      contents: 'Create Analysis',
      tooltip: getCreateAnalysisToolTip(),
      disabledMessage: 'You do not have permisison to create an analysis',
      disabled: !selectedScenario || !permissionToCreateAnalysis || scheduleInProgress,
      type: 'standard',
    },
    {
      id: 'deleteAnalysis',
      contents: 'Delete Analysis',
      tooltip: getDeleteAnalysisToolTip(),
      disabled:
        !permissions.has('delete_analysis') ||
        !selectedScenario ||
        !selectedAnalysis ||
        analysisActive(branch, selectedScenario, selectedAnalysis?.name),
      type: 'standard',
    },
  ];

  const handleActionMenuItemOnClick = id => {
    if (id === 'addAnalysis') {
      migrationNeeded ? setModalMode(modalModes.confirm) : setModalMode(modalModes.create);
    } else if (id === 'editAnalysis') {
      setModalMode(modalModes.edit);
    } else {
      setModalMode(modalModes.delete);
    }
  };
  return (
    <div className="analysis-scenario-section analysis-section" id="analysis-selector-container">
      <div className="select-container">
        <div className="select-top-row">
          <p className="select-label">Analysis</p>
        </div>
        <div className="select-control-wrapper">
          <Select
            theme={theme}
            options={analyses.map(opt => {
              const isAnalysisActive = analysisActive(branch, selectedScenario, opt.name);
              return getBadgedAnalysisOption(opt, isAnalysisActive);
            })}
            value={selectedAnalysis?.id}
            onChange={e => {
              const analysis = e ? e.analysis : null;
              setSelectedAnalysis(analysis, permissions);
            }}
            onMenuOpen={() => {
              setSelectedType(SelectType.MENU);
            }}
            onMenuClose={() => {
              setSelectedType(SelectType.NONE);
            }}
            clearable
            id="analysis-selector"
            disabled={!selectedScenario}
            tooltip={!selectedScenario ? 'Select a scenario to view an analysis' : ''}
          />
          <ActionMenu
            dropdownId="analysis"
            dropdownOnClick={id => {
              handleActionMenuItemOnClick(id);
            }}
            menuOptions={getActionMenuOptions()}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        </div>
      </div>

      {modalMode === modalModes.delete && (
        <Modal
          title="Delete Analysis"
          active
          width="300px"
          onCancel={() => setModalMode(modalModes.hidden)}
          onConfirm={deleteAnalysis}
          labels={{
            confirm: 'Confirm',
          }}
          reverseFooterButtons
          theme={theme}
        >
          <p className="modal-message__p">
            Once an analysis has been deleted, it cannot be restored.
          </p>
          <p className="modal-message__p">Would you like to permanently remove this analysis?</p>
        </Modal>
      )}
      {modalMode === modalModes.edit && (
        <AnalysisModal
          modalActive
          hasResults
          toggleModal={() => setModalMode(modalModes.hidden)}
          usedAnalysisNames={usedAnalysisNames}
          analyses={analyses}
        />
      )}
      {modalMode === modalModes.create && (
        <AnalysisModal
          modalActive
          hasResults={false}
          toggleModal={(isModalActive = true, isAnalysisComplete = false) => {
            setModalMode(
              isAnalysisComplete && !isModalActive ? modalModes.complete : modalModes.hidden,
            );
          }}
          usedAnalysisNames={usedAnalysisNames}
          analyses={analyses}
        />
      )}
      {modalMode === modalModes.complete && (
        <Modal
          title="Battery Analysis Execution"
          active
          width="300px"
          onCancel={() => setModalMode(modalModes.hidden)}
          onConfirm={() => {
            setActivePanel('activityLog');
            setModalMode(modalModes.hidden);
          }}
          labels={{
            confirm: 'Show Activity Log',
          }}
          theme={theme}
        >
          <p className="modal-message__p">Check Activity Log to view status of Battery Analysis</p>
        </Modal>
      )}
      {modalMode === modalModes.confirm && (
        <Modal
          title="Upgrade scheduled data"
          active
          width="400px"
          onCancel={() => setModalMode(modalModes.hidden)}
          onConfirm={() => {
            setActivePanel('activityLog');
            upgradeAssetSchedule();
          }}
          labels={{
            confirm: scheduleInProgress ? <i className="material-icons rotate">refresh</i> : 'yes',
            cancel: 'Discard',
          }}
          theme={theme}
          disableConfirm={scheduleInProgress}
          className="upgrade-modal"
        >
          <div className="modal-message__p">
            {scheduleInProgress ? (
              <>
                <span>Update in progress. This will take couple of minutes.</span>
                <span>
                  You can check the status in activity log and come back later to continue with your
                  analysis
                </span>
                <StatusBar barColor="#06AFA8" progress={100} processing cornerType="round" />
              </>
            ) : (
              <span>
                Your scenario data is outdated. In order to continue creating a new analysis, it
                needs to be updated. Would you like to proceed?
              </span>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

AnalysisSection.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedAnalysis: nullable(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  permissions: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    setSelectedAnalysis: PropTypes.func.isRequired,
    analysisActive: PropTypes.func.isRequired,
    setActivePanel: PropTypes.func.isRequired,
  }).isRequired,
};

export default AnalysisSection;
