import ReactGA from 'react-ga';
import browserHistory from 'routes/history';

/**
 * Use provided blacklist to filter out certain pageviews
 * @param  {String} path The url of the current page
 */
function filterPageviews(path) {
  const blacklist = ['/oauth-callback'];
  if (!blacklist.some(pattern => path.includes(pattern))) {
    ReactGA.pageview(path);
  }
}

function isInitialized() {
  return !!localStorage.getItem('analyticsID');
}

function getGAUserId(email, userId) {
  const totallyGDPRCompliantEmail = email && email.match(/^(.*)@/)[1];
  return totallyGDPRCompliantEmail ? `${totallyGDPRCompliantEmail}_${userId}` : undefined;
}

/**
 * If GA id is available, initialize tracking
 * @param {String}  analyticsID   Google Analytics Tracking ID
 * @param {Object} user The user
 */
function initialize(analyticsID, user) {
  if (analyticsID) {
    localStorage.setItem('analyticsID', analyticsID);
    const userId = getGAUserId(user.email, user.user_id);
    ReactGA.initialize([{ trackingId: analyticsID, gaOptions: { userId } }], {
      debug: localStorage.getItem('app-environment') === 'dev',
    });
    filterPageviews(window.location.pathname + window.location.search);
    browserHistory.listen(({ pathname }) => filterPageviews(pathname));
  }
}

/**
 * Log a custom event to google analytics
 * @param {String}        action     Description of the Action
 * @param {String}        category   Category of the Event
 * @param {String}        label      (Optional) Additional label/info
 * @param {String | Number} value      (Optional) Value associated with event
 */
function logEvent(action, category = 'Interaction', label = undefined, value = undefined) {
  if (localStorage.getItem('analyticsID')) {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  }
}

function setTrackedUser(userEmail, userId) {
  if (isInitialized()) {
    ReactGA.set({ userId: getGAUserId(userEmail, userId) });
  }
}

const analytics = {
  initialize,
  logEvent,
  filterPageviews,
  isInitialized,
  setTrackedUser,
};
export default analytics;
