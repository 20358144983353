import React, { FunctionComponent, useState, useContext, ReactElement, useEffect } from 'react';
import { ThemeProp } from 'types/index';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { useRequest, Request } from '@opusonesolutions/gridos-app-framework';
import { useToasts } from 'react-toast-notifications';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import GridLayout from 'layouts/GridLayout';
import IconButton from 'components/IconButton';
import CustomScrollBar from 'components/CustomScrollBar';
import { isEmptyObject, isDefined } from 'helpers/utils';

type confirmModalConfigType = {
  [key: string]: string | (() => void) | boolean | ReactElement | number;
};
type ScheduleSourcePanelProps = {
  theme: ThemeProp;
  workspace: string;
  disabled: boolean;
  setConfirmModalConfig: (arg: confirmModalConfigType | null) => void;
  onTabClick: () => void;
};

type SorceDataType = {
  id?: string;
  name?: string;
  clientID?: string;
  clientSecret?: string;
  url?: string;
  validUrl?: boolean;
};

type scheduleSourceType = {
  [key: string]: { name: string; url: string; client_name: string; client_secret: string };
};

const ScheduleSourcePanel: FunctionComponent<ScheduleSourcePanelProps> = ({
  theme,
  workspace,
  disabled,
  setConfirmModalConfig,
  onTabClick,
}) => {
  const [sourceData, setSourceData] = useState<SorceDataType>({});
  const [updateSourceData, setUpdateSourceData] = useState<SorceDataType | null>(null);
  const { analyticsConfig, refetchAnalyticsConfig } = useContext(WorkspaceSettingsContext);
  const scheduleSource: scheduleSourceType = analyticsConfig?.schedule_sources ?? {};
  const { makeRequest: putScheduleSourceReq, loading } = useRequest(
    `/api/workspace/${workspace}/analytics-config/schedule-source`,
  );
  useEffect(() => {
    onTabClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleSource, updateSourceData]);
  const { addToast } = useToasts();
  const saveScheduleSource = async (type: 'add' | 'update') => {
    const dataObj = type === 'add' ? sourceData : updateSourceData ?? {};
    await putScheduleSourceReq({
      method: 'put',
      body: {
        name: dataObj.name,
        url: dataObj.url,
        ...(dataObj.clientID && dataObj.clientID !== '' ? { client_name: dataObj.clientID } : {}),
        ...(dataObj.clientSecret && dataObj.clientSecret !== ''
          ? { client_secret: dataObj.clientSecret }
          : {}),
      },
      onSuccess: () => {
        type === 'add' ? setSourceData({}) : setUpdateSourceData(null);
        addToast('Saved source url successfully', { appearance: 'success' });
        refetchAnalyticsConfig();
      },
      onError: data => {
        let errorMSg = 'Failed to save source url';
        if (data?.response?.status && [502, 421].includes(data?.response?.status)) {
          errorMSg = 'Remote validation failed for URL';
        }
        addToast(errorMSg, { appearance: 'error' });
      },
    });
  };
  const deleteScheduleSource = async (sourceID: string) => {
    const request = new Request(
      `/api/workspace/${workspace}/analytics-config/schedule-source/${sourceID}`,
    );
    try {
      await request.delete();
      // Mark as success and close the modal
      setConfirmModalConfig(null);
      refetchAnalyticsConfig();
    } catch (error) {}
  };
  const isNameAlreadyExists =
    isDefined(sourceData.name) &&
    Object.values(scheduleSource ?? {}).filter(obj => obj.name?.trim() === sourceData.name?.trim())
      ?.length > 0;
  const invalidName =
    isDefined(sourceData.name) &&
    (sourceData.name?.length === 0 ||
      sourceData.name?.match(/[^A-Za-z0-9-_]/g) !== null ||
      isNameAlreadyExists);
  return (
    <>
      <div className="workspace-card-tab-panel" id="schedule-source-panel">
        <div className="gis-tab">
          <TextInput
            label="Name"
            value={sourceData.name}
            id="name"
            onChange={e => {
              setSourceData({ ...sourceData, name: e?.target?.value || '' });
            }}
            theme={theme}
            disabled={disabled}
            invalid={invalidName}
            validationMessage={isNameAlreadyExists ? 'Name already exists' : 'Invalid name'}
          />
          <TextInput
            label="Url"
            value={sourceData.url}
            id="url"
            onChange={e => {
              const urlValue = e?.target?.value || '';
              let validUrl = true;
              try {
                const parsedUrl = new URL(urlValue);
                validUrl = parsedUrl.searchParams.entries().next().done ?? true;
              } catch (error) {
                validUrl = false;
              }
              setSourceData({ ...sourceData, url: urlValue, validUrl });
            }}
            theme={theme}
            invalid={!isEmptyObject(sourceData) && !sourceData.validUrl}
            disabled={disabled}
          />
          <TextInput
            label="Client ID"
            value={sourceData.clientID}
            id="clientID"
            onChange={e => {
              setSourceData({ ...sourceData, clientID: e?.target?.value || '' });
            }}
            theme={theme}
            disabled={disabled}
          />
          <TextInput
            label="Client secret"
            value={sourceData.clientSecret}
            id="clientSecret"
            onChange={e => {
              setSourceData({ ...sourceData, clientSecret: e?.target?.value || '' });
            }}
            theme={theme}
            disabled={disabled}
          />
          <Button
            label="Apply Changes"
            className="workspace-card-button"
            id="auth-button"
            onClick={() => saveScheduleSource('add')}
            theme={theme}
            type="primary"
            disabled={
              !sourceData.validUrl || !sourceData.name || !sourceData.url || disabled || invalidName
            }
            loading={loading}
          />
          {scheduleSource && !isEmptyObject(scheduleSource) && (
            <GridLayout>
              <div className="grid-columns one-three-one">
                <span>Name</span>
                <div className="grid-columns auto-fit">
                  <span>URL</span>
                  <span>Client ID</span>
                  <span>Client Secret</span>
                </div>
              </div>
              <div className="source-list-scrollable margin-10">
                <CustomScrollBar alwaysShow={false} className="">
                  {Object.entries(scheduleSource).map(([id, sourceObj]) => (
                    <div className="grid-columns one-three-one items-centered list-row" key={id}>
                      <span>{sourceObj.name}</span>
                      <span className="source-url">
                        {updateSourceData?.id === id ? (
                          <div>
                            <TextInput
                              value={updateSourceData.url}
                              id="updateUrl"
                              onChange={e => {
                                const urlValue = e?.target?.value || '';
                                let validUrl = true;
                                try {
                                  const parsedUrl = new URL(urlValue);
                                  validUrl = parsedUrl.searchParams.entries().next().done ?? true;
                                } catch (error) {
                                  validUrl = false;
                                }
                                setUpdateSourceData({
                                  ...updateSourceData,
                                  url: urlValue,
                                  validUrl,
                                });
                              }}
                              theme={theme}
                              invalid={updateSourceData && !updateSourceData.validUrl}
                              disabled={disabled}
                            />
                            <TextInput
                              value={updateSourceData.clientID}
                              id="sourceClientID"
                              onChange={e => {
                                setUpdateSourceData({
                                  ...updateSourceData,
                                  clientID: e?.target?.value || '',
                                });
                              }}
                              theme={theme}
                              disabled={disabled}
                              placeholder="Client ID"
                            />
                            <TextInput
                              value={updateSourceData.clientSecret}
                              id="sourceClientSecret"
                              onChange={e => {
                                setUpdateSourceData({
                                  ...updateSourceData,
                                  clientSecret: e?.target?.value || '',
                                });
                              }}
                              theme={theme}
                              disabled={disabled}
                              placeholder="Client secret"
                            />
                          </div>
                        ) : (
                          <div className="grid-columns auto-fit source-values">
                            <span>{sourceObj.url}</span>
                            <span>{sourceObj.client_name ?? ''}</span>
                            <span>{sourceObj.client_secret ?? ''}</span>
                          </div>
                        )}
                      </span>
                      <span className="flex-end" style={{ marginRight: '10px' }}>
                        {updateSourceData?.id === id && (
                          <>
                            <IconButton
                              id={`save-button-${id}`}
                              icon="save"
                              disabled={
                                disabled ||
                                loading ||
                                (updateSourceData.url === sourceObj.url &&
                                  updateSourceData.clientID === (sourceObj.client_name ?? '') &&
                                  updateSourceData.clientSecret ===
                                    (sourceObj.client_secret ?? '')) ||
                                !updateSourceData?.validUrl
                              }
                              onClick={() => saveScheduleSource('update')}
                            />
                            <IconButton
                              id={`discard-button-${id}`}
                              icon="close"
                              disabled={disabled || loading}
                              onClick={() => setUpdateSourceData(null)}
                              tooltip="Discard changes"
                            />
                          </>
                        )}
                        <IconButton
                          id="edit-button"
                          icon="edit"
                          theme={theme}
                          disabled={disabled || loading}
                          onClick={() =>
                            setUpdateSourceData({
                              id,
                              name: sourceObj.name,
                              url: sourceObj.url,
                              clientID: sourceObj.client_name ?? '',
                              clientSecret: sourceObj.client_secret ?? '',
                              validUrl: true,
                            })
                          }
                        />
                        <IconButton
                          id="delete-button"
                          icon="delete"
                          theme={theme}
                          disabled={disabled || loading}
                          onClick={() => {
                            setConfirmModalConfig({
                              modalActive: true,
                              closeModal: () => setConfirmModalConfig(null),
                              deleteItem: () => deleteScheduleSource(id),
                              title: 'Remove schedule source ?',
                              modalBody: (
                                <>
                                  <p className="modal-message__p">
                                    Once a source has been deleted, it cannot be restored.
                                  </p>
                                  <p className="modal-message__p">
                                    <span>Would you like to permanently remove </span>
                                    <span className="source-info">
                                      source
                                      <b>{` ${sourceObj.name} `}</b>
                                      from workspace
                                      <b>{` ${workspace} `}</b>
                                    </span>
                                    ?
                                  </p>
                                </>
                              ),
                              theme,
                            });
                          }}
                        />
                      </span>
                    </div>
                  ))}
                </CustomScrollBar>
              </div>
            </GridLayout>
          )}
        </div>
      </div>
    </>
  );
};

export default ScheduleSourcePanel;
