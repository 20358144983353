import React, { FunctionComponent, useState, useEffect, useContext, useMemo } from 'react';
import { Gitgraph, templateExtend, TemplateName, Orientation } from '@gitgraph/react';
import { useParams } from 'react-router';
import ExpandableSection from 'components/ExpandableSection';
import GridLayout from 'layouts/GridLayout';
import { Request } from '@opusonesolutions/gridos-app-framework';
import asyncActionStates from 'helpers/asyncActionStates';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ScenarioTypes } from 'helpers/scenarios';
import ThemeContext from 'helpers/ThemeContext';
import ActivityLogContextProvider, { ActivityLogContext } from 'contexts/ActivityLogContext';
import Button from 'components/Button';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import SelectBadgeItem from 'components/SelectBadgeItem';
import Tooltip from 'components/Tooltip';
import { isEmptyObject, displayAsBuilt } from 'helpers/utils';
import { useInterval } from 'helpers/hooks';
import _ from 'lodash';
import NetworkTopNav from '../../../containers/NetworkTopNavContainer';
import { getBadgeForAnalysis } from '../../Network/components/getBadgedAnalysisOption';

import './WorkspaceOverview.scss';

type AnalysisType = {
  id: string;
  analysis_type: string;
  name: string;
  scenario_id?: string;
  isActive?: boolean;
};

type ScenarioType = {
  id: string;
  name: string;
  start_date: string | null;
  end_date: string | null;
  scenario_type: string;
};

type NetworkVersionType = {
  name: string;
  displayName: string;
  notes?: string;
  scenarios: { [key: string]: ScenarioType };
  parent: string;
  children: string[];
  forked_from?: string;
};

type ChangesetType = {
  branches: string[];
  id: number;
  parent: number;
  workspace: string;
};

const INTERVAL = 5000;

const WorkspaceOverview: FunctionComponent = () => {
  const theme = useContext(ThemeContext);
  const [networkVersions, setNetworkVersions] = useState<{ [key: string]: NetworkVersionType }>({});
  const [networkChangeset, setNetworkChangeset] = useState<ChangesetType[]>([]);
  const [arrowInfo, setArrowInfo] = useState<{ [key: string]: { [key: string]: boolean } }>({});
  const [versionLoading, setVersionLoading] = useState(asyncActionStates.INITIAL);
  const [scenarioLoading, setScenarioLoading] = useState<{ [key: string]: number }>({});
  const [analysisLoading, setAnalysisLoading] = useState<{ [key: string]: number }>({});
  const [versionsOpen, setVersionsOpen] = useState<{ [key: string]: boolean }>({});
  const [allAnalyses, setAllAnalyses] = useState<{
    [key: string]: { [key: string]: AnalysisType };
  }>({});

  const { workspace } = useParams<{ workspace: string; branch: string }>();
  const options = {
    orientation: Orientation.Horizontal,
    template: templateExtend(TemplateName.Metro, {
      commit: {
        message: {
          display: false,
        },
        dot: {
          size: 10,
          strokeWidth: 4,
        },
      },
      branch: {
        spacing: 80,
        lineWidth: 4,
      },
    }),
  };
  const fetchNetworkVersions = useMemo(
    () => async () => {
      // get list of network versions
      const versionRequest = new Request(`/api/workspace/${workspace}/branch`);
      const changesetRequest = new Request(`/api/workspace/${workspace}/changeset`);
      setVersionLoading(asyncActionStates.LOADING);
      setNetworkChangeset([]);
      let versions = [];
      let vOpen = {};
      let changeset = [];
      try {
        const res = await versionRequest.get();
        const resChangeset = await changesetRequest.get();
        const { data } = res;
        changeset = resChangeset?.data;
        versions = data.reduce(
          (versionObj: { [key: string]: any }, version: NetworkVersionType) => {
            versionObj[version.name] = {
              name: version.name,
              displayName: displayAsBuilt(version.name),
              notes: version.notes || '',
              scenarios: {},
              parent: version.forked_from ?? 'no-parent',
              children: data
                .filter((ver: NetworkVersionType) => ver.forked_from === version.name)
                .map((ver: NetworkVersionType) => ver.name),
            };
            return versionObj;
          },
          {},
        );
        vOpen = Object.keys(versions).reduce((obj: { [key: string]: boolean }, v: string) => {
          obj[v] = false;
          return obj;
        }, {});
        setVersionLoading(asyncActionStates.SUCCESS);
      } catch (err) {
        setVersionLoading(asyncActionStates.ERROR);
      }
      setNetworkVersions(versions);
      setNetworkChangeset(changeset);
      setVersionsOpen(vOpen);
      setArrowInfo({});
    },
    [workspace],
  );

  useEffect(() => {
    if (workspace) {
      fetchNetworkVersions();
    }
  }, [workspace, fetchNetworkVersions]);
  const sortParentChild = (
    arr: NetworkVersionType[],
    parentId = 'no-parent',
    result: string[] = [],
  ) => {
    arr.forEach((el: NetworkVersionType) => {
      if (el.parent === parentId) {
        result.push(el.name);
        sortParentChild(arr, el.name, result);
      }
    });
    return result;
  };
  const sortedVersions = sortParentChild(Object.values(networkVersions))?.sort(
    (x: string, y: string) => {
      if (x === 'master') return -1;
      if (y === 'master') return 1;
      return 0;
    },
  );
  const updateArrows = useMemo(
    () => () => {
      sortedVersions.forEach(version => {
        const children =
          networkVersions[version].children.sort((x: string, y: string) => {
            const x_index = sortedVersions.findIndex(v => v === x);
            const y_index = sortedVersions.findIndex(v => v === y);
            return x_index < y_index ? 1 : -1;
          }) ?? [];
        children.forEach(child => {
          const parentIndex = sortedVersions.findIndex(v => v === version);
          const childIndex = sortedVersions.findIndex(v => networkVersions[v].name === child);
          const versionsbetweenParentChild = sortedVersions.slice(parentIndex, childIndex + 1);
          const isChild = versionsOpen[version] === true;
          const isParent =
            networkVersions[child].children.length !== 0 && versionsOpen[child] === true;
          setArrowInfo(prevState => ({
            ...prevState,
            ...versionsbetweenParentChild?.reduce((obj, ver) => {
              obj = {
                ...obj,
                [ver]: {
                  ...prevState[ver],
                  betweenParentAndChild:
                    versionsOpen[version] === true && ver !== version && ver !== child,
                },
              };
              return obj;
            }, {}),
            [version]: {
              ...prevState[version],
              isParent:
                networkVersions[version].children.length !== 0 && versionsOpen[version] === true,
            },
            [child]: {
              ...prevState[child],
              isParent,
              isChild: isChild && !isParent,
            },
          }));
        });
      });
    },
    [networkVersions, versionsOpen, sortedVersions],
  );

  // this updates the object with the arrow info
  const fetchScenarios = async (branch: string) => {
    let scenarioList: { [key: string]: ScenarioType };

    // get list of scenarios
    const scenarioRequest = new Request(
      `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`,
    );

    if (scenarioLoading[branch] !== asyncActionStates.LOADING) {
      if (scenarioLoading[branch] !== asyncActionStates.SUCCESS) {
        setScenarioLoading(prevState => ({
          ...prevState,
          [branch]: asyncActionStates.LOADING,
        }));
      }
      try {
        const res = await scenarioRequest.get<ScenarioType[]>();
        const { data } = res;
        scenarioList = data.reduce((scenObj: { [key: string]: any }, scenario: ScenarioType) => {
          scenObj[scenario.id] = {
            id: scenario.id,
            name: scenario.name,
            // forcing extra spacing into date format for readability, as per design
            start_date: scenario.start_date
              ? moment.utc(scenario.start_date).format('DD MMM, YYYY[\xa0\xa0\xa0\xa0]HH:mm')
              : null,
            end_date: scenario.end_date
              ? moment.utc(scenario.end_date).format('DD MMM, YYYY[\xa0\xa0\xa0\xa0]HH:mm')
              : null,
            scenario_type: scenario.scenario_type,
          };
          return scenObj;
        }, {});
        setScenarioLoading(prevState => ({
          ...prevState,
          [branch]: asyncActionStates.SUCCESS,
        }));
      } catch (err) {
        setScenarioLoading(prevState => ({
          ...prevState,
          [branch]: asyncActionStates.ERROR,
        }));
      }

      setVersionsOpen(prevState => ({
        ...prevState,
        [branch]: true,
      }));
      setNetworkVersions(prevState => ({
        ...prevState,
        [branch]: {
          ...prevState[branch],
          scenarios: scenarioList,
        },
      }));
    }
  };

  const fetchAnalyses = async (branch: string) => {
    let analysisList: { [key: string]: AnalysisType } = {};
    // get list of analyses
    const analysisRequest = new Request(`/api/workspace/${workspace}/branch/${branch}/analysis`);

    try {
      setAnalysisLoading(prevState => ({
        ...prevState,
        [branch]: asyncActionStates.LOADING,
      }));
      const res = await analysisRequest.get();
      const { data } = res;
      analysisList = data.reduce(
        (analysisObj: { [key: string]: AnalysisType }, analysis: AnalysisType) => {
          analysisObj[analysis.id] = {
            id: analysis.id,
            name: analysis.name,
            analysis_type: analysis.analysis_type,
            scenario_id: analysis.scenario_id,
          };
          return analysisObj;
        },
        {},
      );

      setAnalysisLoading(prevState => ({
        ...prevState,
        [branch]: asyncActionStates.SUCCESS,
      }));
    } catch (err) {
      setAnalysisLoading(prevState => ({
        ...prevState,
        [branch]: asyncActionStates.ERROR,
      }));
    }
    setAllAnalyses(prevState => ({
      ...prevState,
      [branch]: analysisList,
    }));
  };

  useInterval(async () => {
    if (!isEmptyObject(allAnalyses)) {
      try {
        Object.keys(allAnalyses).forEach(version => {
          const updatedAnalyses =
            allAnalyses[version] &&
            Object.values(allAnalyses[version]).reduce(
              (analysisObj: { [key: string]: AnalysisType }, analysis) => {
                analysisObj[analysis.id] = {
                  ...allAnalyses[version][analysis.id],
                };
                return analysisObj;
              },
              {},
            );
          setAllAnalyses(prevState => ({
            ...prevState,
            [version]: updatedAnalyses,
          }));
        });
      } catch (err) {}
    }
  }, INTERVAL);
  return (
    <div className="overview-with-nav">
      <NetworkTopNav params={useParams()} />
      <div className="workspace-overview">
        <div className="page-header-row">
          <div className="header-row">
            <GridLayout>
              <div className="one-seven grid-columns">
                <div className="single-column header">Versions</div>
                <div className="single-column header">Notes</div>
              </div>
            </GridLayout>
          </div>
          <Button
            label="Refresh page"
            className="refresh-button"
            onClick={fetchNetworkVersions}
            loading={versionLoading === asyncActionStates.LOADING}
          />
        </div>
        {[asyncActionStates.INITIAL, asyncActionStates.LOADING].includes(versionLoading) && (
          <LoadingSkeleton
            className="loading-skeleton-new-theme"
            template="line"
            width={100}
            theme={theme}
            count={3}
          />
        )}
        {versionLoading === asyncActionStates.SUCCESS &&
          sortedVersions.map((version: string) => {
            const { isParent, isChild, betweenParentAndChild } = arrowInfo[version] || {};
            return (
              <ActivityLogContextProvider workspace={workspace} branch={version} key={version}>
                <div className={`arrow-row row-${version}`}>
                  <div className="vertical-lines">
                    <div
                      className={`vertical-div-top ${
                        isChild || betweenParentAndChild ? 'vertical-div-border' : ''
                      }`}
                    />
                    <div
                      className={`vertical-div-bottom ${
                        isParent || betweenParentAndChild ? 'vertical-div-border' : ''
                      }`}
                    />
                  </div>
                  <div className="version-row">
                    <div className="center-arrows">
                      <div
                        className={`horizontal-div ${
                          isParent || isChild ? 'horizontal-div-color' : ''
                        }`}
                      />
                      <div className={`arrow-right ${isChild ? 'arrow-right-color' : ''}`} />
                      <ExpandableSection
                        id={version}
                        className="version-expandable-header version-section"
                        iconType="expandCollapseIcon"
                        toggleClick={async () => {
                          if (!versionsOpen[version]) {
                            if (
                              !networkVersions[version].scenarios ||
                              isEmptyObject(networkVersions[version].scenarios)
                            ) {
                              await fetchScenarios(version);
                              await fetchAnalyses(version);
                            }
                            versionsOpen[version] = true;
                          } else {
                            versionsOpen[version] = false;
                          }
                          updateArrows();
                        }}
                        open={versionsOpen[version]}
                        sectionName={
                          <GridLayout>
                            <div className="one-seven grid-columns version-expandable-text">
                              <div className="single-column workspace-name">
                                <span className="dot" />
                                {networkVersions[version].displayName}
                              </div>
                              <Tooltip
                                content={networkVersions[version].notes || ''}
                                theme={theme}
                                placement="left"
                              >
                                <div className="single-column notes">
                                  {networkVersions[version].notes}
                                </div>
                              </Tooltip>
                            </div>
                          </GridLayout>
                        }
                      >
                        {[asyncActionStates.INITIAL, asyncActionStates.LOADING].includes(
                          scenarioLoading[version],
                        ) && (
                          <LoadingSkeleton
                            className="loading-skeleton-new-theme"
                            template="line"
                            width={100}
                            theme={theme}
                            count={3}
                          />
                        )}
                        {scenarioLoading[version] === asyncActionStates.SUCCESS && (
                          <>
                            <GridLayout>
                              <div className="one-two-five grid-columns header-row">
                                <div className="single-column header scenario-info">Scenarios</div>
                                <div className="single-column header scenario-info">
                                  <div className="header scenario-dates">
                                    <ReactSVG src="/calendar.svg" />
                                  </div>
                                  <div className="header scenario-dates">Start date</div>
                                  <div className="header scenario-dates">End date</div>
                                </div>
                                <div className="header scenario-info">Analyses</div>
                              </div>
                            </GridLayout>
                            {Object.keys(networkVersions[version].scenarios)?.map((id: string) => {
                              const scenario = networkVersions[version].scenarios[id];
                              return (
                                <div key={scenario.name} className="scenario-row">
                                  <div className="scenario-section">
                                    <GridLayout>
                                      <div className="one-two-five grid-columns">
                                        <div className="single-column scenario-info">
                                          <SelectBadgeItem
                                            item={scenario}
                                            badgeInfos={[
                                              {
                                                tooltip:
                                                  scenario.scenario_type ===
                                                  ScenarioTypes.timeseries
                                                    ? 'Timeseries'
                                                    : 'Snapshot',
                                                key: id ?? 'badge',
                                                label:
                                                  scenario.scenario_type ===
                                                  ScenarioTypes.timeseries
                                                    ? 'T'
                                                    : 'S',
                                                noHover: true,
                                              },
                                            ]}
                                          />
                                          <div className="scenario-name">{scenario.name}</div>
                                        </div>
                                        <div className="single-column scenario-info">
                                          <div
                                            className={
                                              scenario.start_date
                                                ? 'scenario-dates calendar-icon'
                                                : 'scenario-dates badge-empty'
                                            }
                                          >
                                            <ReactSVG src="/calendar.svg" />
                                          </div>
                                          {scenario.start_date ? (
                                            <>
                                              <div className="scenario-dates date-badge">
                                                {scenario.start_date}
                                              </div>
                                              {scenario.scenario_type ===
                                                ScenarioTypes.timeseries &&
                                                scenario.end_date && (
                                                  <div className="scenario-dates date-badge">
                                                    {scenario.end_date}
                                                  </div>
                                                )}
                                            </>
                                          ) : (
                                            <div className="scenario-dates scenario-dates-empty">
                                              No associated forecast
                                            </div>
                                          )}
                                        </div>
                                        <ActivityLogContext.Consumer>
                                          {({ analysisActive }) => (
                                            <div className="analysis-section single-column">
                                              {analysisLoading[version] &&
                                                [
                                                  asyncActionStates.INITIAL,
                                                  asyncActionStates.LOADING,
                                                ].includes(analysisLoading[version]) && (
                                                  <LoadingSkeleton
                                                    className="loading-skeleton-new-theme"
                                                    template="line"
                                                    width={100}
                                                    theme={theme}
                                                    count={1}
                                                  />
                                                )}
                                              {analysisLoading[version] ===
                                                asyncActionStates.SUCCESS &&
                                                allAnalyses[version] &&
                                                Object.values(allAnalyses[version])
                                                  ?.filter(analysis => analysis.scenario_id === id)
                                                  .map(a => {
                                                    const badgeObj = {
                                                      ...a,
                                                      type: a.analysis_type,
                                                    };
                                                    const {
                                                      tooltip,
                                                      key,
                                                      label,
                                                    } = getBadgeForAnalysis(badgeObj);
                                                    const badges = [
                                                      {
                                                        tooltip,
                                                        key,
                                                        label,
                                                        noHover: true,
                                                      },
                                                    ];
                                                    const isAnalysisActive = analysisActive(
                                                      version,
                                                      id,
                                                      a.name,
                                                    );
                                                    if (isAnalysisActive) {
                                                      badges.push({
                                                        tooltip: 'Running',
                                                        key: `${id}=running`,
                                                        label: 'R',
                                                        noHover: true,
                                                      });
                                                    }
                                                    return (
                                                      <div className="info-badge" key={a.name}>
                                                        <SelectBadgeItem
                                                          item={a}
                                                          badgeInfos={badges}
                                                        />
                                                        <div className="analysis-name">
                                                          {a.name}
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                              {((analysisLoading[version] ===
                                                asyncActionStates.SUCCESS &&
                                                allAnalyses[version] &&
                                                Object.values(allAnalyses[version])?.filter(
                                                  analysis => analysis.scenario_id === id,
                                                ).length === 0) ||
                                                analysisLoading[version] ===
                                                  asyncActionStates.ERROR) && (
                                                <div className="analyses-empty">No analyses</div>
                                              )}
                                            </div>
                                          )}
                                        </ActivityLogContext.Consumer>
                                      </div>
                                    </GridLayout>
                                  </div>
                                </div>
                              );
                            })}
                            {isEmptyObject(networkVersions[version].scenarios) && (
                              <div className="no-scenarios">
                                No scenarios for this network version
                              </div>
                            )}
                          </>
                        )}
                      </ExpandableSection>
                    </div>
                  </div>
                </div>
              </ActivityLogContextProvider>
            );
          })}
        {versionLoading === asyncActionStates.ERROR && (
          <div className="version-row">There was an error loading network versions</div>
        )}
        <div className="page-header-row" style={{ margin: '20px 0 10px 20px' }}>
          <div className="header-row">
            <GridLayout>
              <div className="single-column header">Version hierarchy</div>
            </GridLayout>
          </div>
        </div>
        <ExpandableSection
          className="version-expandable-header branch_hierarchy"
          id="branch_hierarchy"
          iconType="expandCollapseIcon"
          sectionName={
            <GridLayout>
              <div className="one-seven grid-columns version-expandable-text">
                <div className="single-column workspace-name">View</div>
              </div>
            </GridLayout>
          }
          open
        >
          <div>
            {versionLoading === asyncActionStates.SUCCESS &&
              networkChangeset &&
              networkChangeset.length !== 0 && (
                <Gitgraph options={options} key={workspace}>
                  {gitgraph => {
                    const style = {
                      label: {
                        font: '14px Roboto',
                      },
                    };
                    const network = gitgraph.branch({ name: 'Network', style }).commit();
                    const networkSetBranches = networkChangeset?.filter(
                      (set: ChangesetType) => set?.branches?.length !== 0,
                    );
                    networkSetBranches.forEach(set => {
                      let parentBranch = null;
                      let currentParent = set.parent;
                      if (set.parent === null) {
                        parentBranch = network;
                      }
                      while (parentBranch === null) {
                        const newObj = _.find(networkChangeset, { id: currentParent });
                        if (newObj?.branches?.length !== 0) {
                          parentBranch = gitgraph.branch(
                            newObj?.branches
                              .map(name => displayAsBuilt(name))
                              ?.join(', ')
                              .toString() ?? '',
                          );
                        } else if (newObj?.parent === null) {
                          parentBranch = network;
                        } else {
                          currentParent = newObj?.parent;
                        }
                      }
                      gitgraph
                        .branch({
                          name: set.branches.map(name => displayAsBuilt(name)).join(', '),
                          style,
                          from: parentBranch,
                        })
                        .commit(set.id.toString());
                    });
                  }}
                </Gitgraph>
              )}
          </div>
        </ExpandableSection>
      </div>
    </div>
  );
};

export default WorkspaceOverview;
