import IdentifiedObject from '../IdentifiedObject';

class Substation extends IdentifiedObject {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'Substation';
    this.nodes = [];
    this._energySource = null;
  }

  get feeders() {
    return this.references['Substation.NormalEnergizedFeeder'] || [];
  }

  get voltageLimits() {
    const { energySource } = this;
    const limitSetId =
      energySource &&
      energySource.references['Equipment.OperationalLimitSet'] &&
      energySource.references['Equipment.OperationalLimitSet'].length
        ? energySource.references['Equipment.OperationalLimitSet'][0]
        : undefined;

    const limitIds = limitSetId
      ? this.cimDict[limitSetId].references['OperationalLimitSet.OperationalLimitValue']
      : undefined;

    const limits = limitIds ? limitIds.map(id => this.cimDict[id]) : undefined;

    const getLimitValue = name =>
      limits
        ? limits.find(limit => limit.attributes['IdentifiedObject.name'] === name).attributes[
            'VoltageLimit.value'
          ]
        : undefined;

    const voltageLimits = {
      voltage_upper_limit: getLimitValue('voltage_upper_limit') || 1.06,
      voltage_lower_limit: getLimitValue('voltage_lower_limit') || 0.94,
    };
    return voltageLimits;
  }

  set energySource(energySource) {
    this._energySource = energySource;
  }

  get energySource() {
    return this._energySource;
  }

  addNode(node) {
    this.nodes = [...this.nodes, node];
  }
}

export default Substation;
