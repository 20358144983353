/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

// Creates clickable tile (button) with a checkbox showing if tile is selected
const LayerSelectionButton = ({ option, selected, setSelectedLayer }) => {
  const { value, label } = option;

  return (
    <button
      key={`${value}`}
      className="layer-selection-button"
      onClick={() => setSelectedLayer(value, true)}
      type="button"
    >
      <input
        className="radio-btn-input"
        type="radio"
        value={option.value}
        id={`layer-option-${option.value}`}
        name="layer-option"
        defaultChecked={selected}
      />
      <label htmlFor={`layer-option-${option.value}`}>
        <div className="option-icon-group">
          <div className={`radio-btn ${selected ? 'radio-btn--selected' : ''}`}>
            <div className="radio-btn-fill" />
          </div>
        </div>
      </label>
      <p>{label}</p>
    </button>
  );
};

LayerSelectionButton.propTypes = {
  option: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
};

export default LayerSelectionButton;
