import { isDefined } from 'helpers/utils';
import IdentifiedObject from '../IdentifiedObject';

class NoLoadTest extends IdentifiedObject {
  get loss() {
    // The loss is stored in kW for some reason
    return isDefined(this.attributes['NoLoadTest.loss'])
      ? this.attributes['NoLoadTest.loss'] * 1000
      : 0;
  }
}

export default NoLoadTest;
