import CableInfo from './CableInfo';

class TapeShieldCableInfo extends CableInfo {
  get tapeLap() {
    return this.attributes['TapeShieldCableInfo.tapeLap'];
  }

  get tapeThickness() {
    return this.attributes['TapeShieldCableInfo.tapeThickness'];
  }
}

export default TapeShieldCableInfo;
