import React from 'react';
import PropTypes from 'prop-types';
import asyncActionStates from 'helpers/asyncActionStates';
import IconButton from 'components/IconButton';
import StatusBar from 'components/StatusBar';

const FileUploadStatus = ({
  details,
  file,
  fileExtension,
  theme,
  removeFromFileList,
  uploading,
  errorMessage,
}) => {
  const progress = details.status === 'invalid' ? 0 : details.uploadProgress;
  const processing =
    details.uploadProgress === 100 && details.loadingState === asyncActionStates.LOADING;
  const complete =
    details.uploadProgress === 100 && details.loadingState === asyncActionStates.SUCCESS;
  const isError = details.status === 'error' || details.status === 'invalid';
  return (
    <div key={file} className="file-upload-status">
      <div className="file-row">
        <p style={{ margin: '0' }}>{file}</p>
        {!uploading && !details.processed && (
          <IconButton
            onClick={() => removeFromFileList(file)}
            icon="delete"
            theme={theme}
            tooltip="Remove File"
          />
        )}
      </div>
      {details.processed && (
        <StatusBar
          progress={progress}
          cornerType="round"
          barColor={isError ? '#ff0000' : '#07aba7'}
          backgroundColor="#151515"
          showPercent="right"
          status={isError ? 'error' : 'loading'}
          processing={processing}
          complete={complete}
          showLabel
        />
      )}
      {details.status === 'invalid' && (
        <p className="upload-status-error">
          {`Only files with ${fileExtension} extension(s) can be uploaded. Remove invalid file to start upload`}
        </p>
      )}
      {details.status === 'error' && (
        <p className="upload-status-error">Error Processing File: {errorMessage}</p>
      )}
    </div>
  );
};

FileUploadStatus.defaultProps = {
  fileExtension: '.xml',
  theme: 'light',
  uploading: false,
  errorMessage: '',
};

FileUploadStatus.propTypes = {
  details: PropTypes.object.isRequired,
  file: PropTypes.string.isRequired,
  fileExtension: PropTypes.string,
  removeFromFileList: PropTypes.func.isRequired,
  theme: PropTypes.string,
  uploading: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default FileUploadStatus;
