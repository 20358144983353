import React, { FunctionComponent, useMemo } from 'react';
import { getDetailsOfNodeToBeDeleted } from 'helpers/JSCIM/extractorHelpers';
import classNames from 'classnames';
import IconButton from 'components/IconButton';
import asyncStates from 'helpers/asyncActionStates';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import NetworkCIMMapping from 'helpers/NetworkCIMMapping';
import ListValue from './ListValue';
import NodeDisplayContainer from './NodeDisplayContainer';
import AssetSelector from './AssetSelector';
import AssetPanelIcon from '../../AssetPanelIcon';
import { TYPE_MAP } from '../../../../../helpers/NetworkHelpers';

type AssetDetailsHeaderProps = {
  inEditMode: boolean;
  workspace: string;
  branch: string;
  canEditNetwork: boolean;
  asset?: {
    connected_assets: [];
    nodes: { [key: string]: string }[];
    node: { id: string };
    container: { id: string; name: string };
    usage_points?: { [key: string]: { [key: string]: string } }[];
  } | null;
  selectedNode: {
    id: string;
    class: string;
    displayName: string;
    icon: string;
    closed?: boolean;
    instance?: { icon: any };
    isShunt?: boolean;
  };
  showModal: (assetType: string, nodeDetails: { nodeCount?: number; nodeName?: string }) => void;
  editActions: {
    duplicateAsset: (nodeID: string, nodeClass: string) => void;
    editSingleEquipment: (
      workspace: string,
      branch: string,
      assetType: string,
      id: string,
      body: { [key: string]: any },
    ) => void;
  };
  actions: {
    setFlyToAsset: (selectedNodeValues: { [key: string]: string }) => void;
    setSelectedAssetID: (id: string | null) => void;
  };
  editValues: number;
  panelOptions: { [key: string]: boolean };
  loadingState: number;
  newPanelValues: { [key: string]: string };
  errors?: { [key: string]: string | boolean };
  edited: { [key: string]: string };
  setNewPanelValues: (newValues: { [key: string]: string }) => void;
  setEdited: (editedFieldValues: { [key: string]: boolean }) => void;
  handleSaveEdit: (editValues: { [key: string]: string | boolean }) => void;
  toggleFeederPanel: (containerID: string) => void;
};
const SWITCH_ICON_CLASSES = ['Switch', 'Disconnector'];

const AssetDetailsHeader: FunctionComponent<AssetDetailsHeaderProps> = ({
  inEditMode,
  canEditNetwork,
  asset,
  selectedNode,
  showModal,
  editActions,
  actions,
  editValues,
  panelOptions,
  loadingState,
  newPanelValues,
  errors,
  edited,
  setNewPanelValues,
  setEdited,
  handleSaveEdit,
  toggleFeederPanel,
  workspace,
  branch,
}) => {
  const { LOADING, SUCCESS, NOTALLOWED } = asyncStates;
  const icon = useMemo(() => {
    let headerIcon = selectedNode.icon;
    if (
      SWITCH_ICON_CLASSES.indexOf(selectedNode.class) !== -1 &&
      selectedNode.closed !== undefined
    ) {
      headerIcon = selectedNode.closed ? 'switch_closed' : selectedNode?.icon;
    }
    if (asset?.usage_points?.some(value => value.demandResponseProgram?.program_type !== 'none')) {
      headerIcon = 'load_dr';
    }
    return headerIcon;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode, loadingState, asset]);
  const { isAuthEnabled, permissions } = useAuthContext();
  const assetTypeMap = TYPE_MAP as { [assetClass: string]: { [key: string]: string } };
  const mapping = NetworkCIMMapping as (
    assetID: string,
    lookup: [],
  ) => { [key: string]: { category: string } };
  const saving = editValues === LOADING;
  const getDeleteAssetProps = () => {
    const deleteProps = { disabled: false, tooltip: <span>Delete Asset</span> };
    const canDelete = !isAuthEnabled || permissions.has('delete_asset');
    if (!inEditMode || !canEditNetwork || !canDelete || !asset) {
      deleteProps.disabled = true;
    }

    if (!deleteProps.disabled) {
      if (
        selectedNode.class === 'ConnectivityNode' &&
        asset &&
        asset.connected_assets?.length > 0
      ) {
        deleteProps.disabled = true;
        deleteProps.tooltip = (
          <div className="delete-asset-tooltip">
            <p>The following equipment must be removed to delete this node:</p>
            <ul>
              {asset?.connected_assets.slice(0, 5).map((eq: { [key: string]: string }) => (
                <li key={eq.id}>{eq.name}</li>
              ))}
              {asset && asset.connected_assets.length > 5 && (
                <li key="hidden">{`+ ${asset.connected_assets.length - 5} others`}</li>
              )}
            </ul>
          </div>
        );
      }
    }
    return deleteProps;
  };
  const handleDeleteClick = () => {
    showModal('Asset', getDetailsOfNodeToBeDeleted(asset));
  };

  const handleDuplicateClick = () =>
    editActions.duplicateAsset(selectedNode.id, selectedNode.class);

  const handleLocateClick = () => {
    // we are intentionally creating a new object here as that will force
    // equality checks to fail so you can zoom an asset twice
    actions.setFlyToAsset({
      id: selectedNode.id,
      class: selectedNode.class,
    });
  };

  const handleCloseClick = () => actions.setSelectedAssetID(null);
  const loadingIcon = editValues === LOADING && (
    <span className="loading-placeholder">
      <i className="material-icons rotate">refresh</i>
    </span>
  );
  const getDuplicateAssetProps = () => {
    const duplicateProps = { disabled: false, tooltip: 'Duplicate Asset' };
    if (!inEditMode || !canEditNetwork || !permissions.has('duplicate_asset') || !asset) {
      duplicateProps.disabled = true;
    }

    if (!duplicateProps.disabled) {
      if (selectedNode.class === 'ACLineSegment') {
        duplicateProps.disabled = asset?.nodes?.length !== 2;
      } else {
        duplicateProps.disabled = true;
      }
    }
    return duplicateProps;
  };
  const handleSaveAssetInfo = (body: { [key: string]: any }) =>
    editActions.editSingleEquipment(
      workspace,
      branch,
      assetTypeMap?.[selectedNode.class].assetType,
      selectedNode.id,
      body,
    );
  const isSeriesCompensator = selectedNode?.class === 'SeriesCompensator';
  return (
    <>
      <div className="asset-title-bar">
        <p>Asset details</p>
        <IconButton
          className="clear-asset"
          icon="close"
          onClick={handleCloseClick}
          tooltip="Clear Selection"
        />
      </div>
      <div
        className={classNames({
          'asset-panel-header': true,
          'asset-panel-header--expanded': panelOptions.expanded,
        })}
      >
        {loadingState === SUCCESS && (
          <div className="asset-panel-header-contents header-row">
            <AssetPanelIcon type={selectedNode.class} icon={icon} />
            <div className="asset-type-container">
              <p>{selectedNode.displayName}</p>
            </div>
            <input
              className={`asset-panel-name ${edited.name ? 'asset-panel-name--edited' : ''}`}
              value={newPanelValues?.name}
              onChange={e => {
                setNewPanelValues({ ...newPanelValues, name: e.target.value });
                setEdited({ name: true });
              }}
              onBlur={() =>
                handleSaveEdit({
                  assetType: assetTypeMap?.[selectedNode.class].assetType,
                  field: 'name',
                  isAttribute: true,
                })
              }
              title={newPanelValues?.name}
              disabled={!canEditNetwork || !inEditMode || isSeriesCompensator}
            />

            <div className="header-btn-container">
              {inEditMode && (
                <>
                  <div className="loading-indicator">{loadingIcon}</div>
                  <IconButton
                    icon={<i className="material-icons-outlined">delete</i>}
                    className="delete-asset"
                    onClick={handleDeleteClick}
                    {...getDeleteAssetProps()}
                  />
                  {selectedNode.class === 'ACLineSegment' && (
                    <IconButton
                      icon={<i className="material-icons-outlined">content_copy</i>}
                      className="duplicate-asset"
                      onClick={handleDuplicateClick}
                      {...getDuplicateAssetProps()}
                    />
                  )}
                </>
              )}

              <IconButton
                icon={<i className="material-icons-outlined">visibility</i>}
                onClick={handleLocateClick}
                tooltip="Locate Asset"
              />
            </div>
          </div>
        )}
        {(editValues === NOTALLOWED || (errors && errors.isError)) && (
          <div className="feedback-container">
            <p className="modify-network-error">
              {editValues === NOTALLOWED && (
                <>
                  <i className="material-icons edit-values-error__i">warning</i>
                  You do not have permission to edit assets.
                </>
              )}
              {errors && errors.isError && (
                <>
                  <i className="material-icons edit-values-error__i">warning</i>
                  {errors.error}
                </>
              )}
            </p>
          </div>
        )}
        {isSeriesCompensator && (
          <div className="feedback-container">
            <p className="modify-network-error">
              <>
                <i className="material-icons edit-values-error__i warning">warning</i>
                OE does not currently support modelling of series compensator and will ignore them.
              </>
            </p>
          </div>
        )}
        {loadingState === SUCCESS && (
          <ListValue
            onClick={toggleFeederPanel}
            values={asset?.container ?? {}}
            label="Associated Feeder/Substation"
            className="header-row"
          />
        )}
        {inEditMode && loadingState === SUCCESS && selectedNode.isShunt && (
          <AssetSelector
            name="Node"
            disabled={!canEditNetwork || saving}
            assetList={asset?.nodes}
            onChange={handleSaveAssetInfo}
            selectedAsset={asset?.node.id}
            linkedAssetType="node"
          />
        )}
        {!inEditMode && loadingState === SUCCESS && selectedNode.isShunt && (
          <div className="node-info-row">
            <ListValue
              onClick={() => actions.setSelectedAssetID(asset?.node.id as string)}
              values={asset?.node ?? {}}
              label="Node"
            />
          </div>
        )}
        {loadingState === SUCCESS &&
          ['linkDevices', 'cables'].includes(mapping('', [])?.[selectedNode.class]?.category) && (
            <NodeDisplayContainer
              disabled={!canEditNetwork || saving || isSeriesCompensator}
              canSwap={inEditMode}
              onNodeClick={({ id: nodeId }) => actions.setSelectedAssetID(nodeId)}
              onSwap={() => handleSaveAssetInfo({ flip_direction: true })}
              leftNode={asset?.nodes[0] ?? {}}
              rightNode={asset?.nodes[1] ?? {}}
            />
          )}
      </div>
    </>
  );
};

export default AssetDetailsHeader;
