import React from 'react';
import PropTypes from 'prop-types';

// Creates the container and header for per phase rows
// Can be rendered with our without a column for totals
const PerPhaseContainer = props => (
  <div className="per-phase-row-container">
    {!props.hideHeader && (
      <div className="phase-row-header">
        <div style={{ width: `${props.showTotal ? '70px' : '125px'}` }} />
        {props.showTotal && (
          <div className="phase-row-header-col">
            <p>ABC</p>
          </div>
        )}
        <div className="phase-row-header-col">
          <p>{props.phaseToPhase ? 'AB' : 'A'}</p>
        </div>
        <div className="phase-row-header-col">
          <p>{props.phaseToPhase ? 'BC' : 'B'}</p>
        </div>
        <div className="phase-row-header-col">
          <p>{props.phaseToPhase ? 'CA' : 'C'}</p>
        </div>
        <div style={{ width: '35px' }} />
      </div>
    )}
    {props.children}
  </div>
);

PerPhaseContainer.defaultProps = {
  showTotal: false,
  children: [],
  phaseToPhase: false,
  hideHeader: false,
};

PerPhaseContainer.propTypes = {
  showTotal: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  phaseToPhase: PropTypes.bool,
  hideHeader: PropTypes.bool,
};

export default PerPhaseContainer;
