const phaseTypes = {
  SINGLE: 'single',
  THREE: 'three',
};

const layerTypes = {
  ACTUAL: 'actual',
  HEADROOM: 'headroom',
  LOADING: 'loading',
};

const aggTypes = {
  AVG: 'avg',
  MIN: 'min',
  MAX: 'max',
  SUM: 'sum',
};

const nonNull = arr => arr.filter(val => val !== null && val !== undefined);

const average = arr => {
  const values = nonNull(arr);
  if (values.length > 0) {
    return values.reduce((p, c) => p + c, 0) / values.length;
  }
  return null;
};

const noValueColor = 'rgba(211, 211, 211, 0.3)';

const PHASE_TO_COLOR = {
  A: '#FF0000',
  B: '#00FF00',
  C: '#0000FF',
  AB: '#FFFF00',
  AC: '#FF00FF',
  BC: '#00FFFF',
};

// Create the range breaks for the given min/max values
const getRangeBreaks = (min, max) =>
  [...Array(4).keys()].map(index => {
    // Each colour to reflect an even fifth of the values, we should scale
    // between them by one fifth.
    const scale = (max - min) / 5;
    const rangeBreak = min + scale * (4 - index);
    return parseFloat(rangeBreak.toFixed(2));
  });

// create range value objects for color gradient checkboxes
const createRangeBuckets = (colors, labels) => [
  {
    id: '0',
    color: colors[0],
    label: labels[0],
  },
  {
    id: '1',
    color: colors[1],
    label: labels[1],
  },
  {
    id: '2',
    color: colors[2],
    label: labels[2],
  },
  {
    id: '3',
    color: colors[3],
    label: labels[3],
  },
  {
    id: '4',
    color: colors[4],
    label: labels[4],
  },
];

const createLabels = (breaks, unit = '') => [
  `> ${breaks[0]}${unit}`,
  `${breaks[1]}${unit}  -  ${breaks[0]}${unit}`,
  `${breaks[2]}${unit}  -  ${breaks[1]}${unit}`,
  `${breaks[3]}${unit}  -  ${breaks[2]}${unit}`,
  `< ${breaks[3]}${unit}`,
];

const createHeadroomLabels = (breaks, unit = '') => [
  `< ${breaks[3]}${unit}`,
  `${breaks[3]}${unit}  -  ${breaks[2]}${unit}`,
  `${breaks[2]}${unit}  -  ${breaks[1]}${unit}`,
  `${breaks[1]}${unit}  -  ${breaks[0]}${unit}`,
  `> ${breaks[0]}${unit}`,
];

const layerColors = [
  '#fddc37',
  '#f9cdac',
  '#9ed5cd',
  '#c0ca33',
  '#ef9c49',
  '#f19a9b',
  '#46c3d3',
  '#7ad514',
  '#e45e2d',
  '#d54d88',
  '#4a90e2',
  '#33b679',
  '#ff6e6e',
  '#ca9ddb',
  '#7986cb',
  '#06afa8',
];

const determineColorIndex = (value, rangeBreaks) => {
  let colorBreakIndex;
  if (
    value === null ||
    value === undefined ||
    value === Number.POSITIVE_INFINITY ||
    value === Number.NEGATIVE_INFINITY
  ) {
    return null;
  }

  for (let i = 0; i < rangeBreaks.length; i += 1) {
    const noIndex = colorBreakIndex === undefined;
    const currentMax = rangeBreaks[i];
    const currentMin = rangeBreaks[i + 1];
    const largerThanMax = value > currentMax;
    const smallerThanMax = currentMax >= value;
    const largerThanMin = currentMin < value;
    if (i === 0 && largerThanMax) {
      colorBreakIndex = 0;
    } else if (noIndex && i === rangeBreaks.length - 1) {
      const totalBreaks = rangeBreaks.length;
      colorBreakIndex = largerThanMax ? totalBreaks - 1 : totalBreaks;
    } else if (noIndex && smallerThanMax && largerThanMin) {
      colorBreakIndex = i + 1;
    }
  }
  return colorBreakIndex;
};

const determineColorByLayerType = (layerOptions, valueLU, ratingLU, breaks, defaultColor) => {
  const { phaseType, phase, layerType, aggType, colors, selected } = layerOptions;
  let value;

  if (!valueLU) return defaultColor;

  if (phaseType === phaseTypes.SINGLE) {
    const key = `${phase}_${layerOptions.aggregation}`;
    // If the layer is a single phase layer, get value from the selected phase
    if (layerType === layerTypes.ACTUAL) {
      value = valueLU[key];
      if (!value) return defaultColor;
    } else if (layerType === layerTypes.HEADROOM) {
      if (!ratingLU || !ratingLU[phase] || !valueLU || !valueLU[key]) return defaultColor;
      value = ratingLU[phase] - valueLU[key];
    } else if (layerType === layerTypes.LOADING) {
      if (!ratingLU || !ratingLU[phase] || !valueLU || !valueLU[key]) return defaultColor;
      value = (valueLU[key] / ratingLU[phase]) * 100;
    }
  } else if (phaseTypes.THREE) {
    // If the layer is a three phase layer, use values from all available phases
    const aggValues = {
      A: valueLU[`A_${layerOptions.aggregation}`],
      B: valueLU[`B_${layerOptions.aggregation}`],
      C: valueLU[`C_${layerOptions.aggregation}`],
    };

    const withValues = Object.entries(aggValues).filter(
      ([, val]) => val !== null && val !== undefined,
    );
    if (!withValues.length) return defaultColor;

    // Get value for 'Three Phase Actual'
    if (layerType === layerTypes.ACTUAL) {
      const key = `ABC_${aggType}_${layerOptions.aggregation}`;
      value = valueLU[key];
    }

    // Get value for 'Three Phase Headroom' or 'Three Phase Loading'
    if (layerType === layerTypes.HEADROOM || layerType === layerTypes.LOADING) {
      const withRatings = Object.entries(ratingLU).filter(([, i]) => i !== null);
      if (!value && !withRatings.length) return defaultColor;

      let layerValues;
      if (layerType === layerTypes.HEADROOM) {
        // Get headroom for each value with ratings
        layerValues = withValues
          .map(([p, i]) => ratingLU[p] - i)
          .filter(val => val !== null && !Number.isNaN(val));
      } else {
        // Get loading for each value with ratings
        layerValues = withValues
          .map(([p, i]) => (i / ratingLU[p]) * 100)
          .filter(val => val !== null && !Number.isNaN(val));
      }

      // Handle the three phase value aggregation
      if (aggType === aggTypes.MIN) {
        value = Math.min(...layerValues);
      } else if (aggType === aggTypes.MAX) {
        value = Math.max(...layerValues);
      } else if (aggType === aggTypes.SUM) {
        value = layerValues.reduce((a, b) => a + b, 0);
      } else if (aggType === aggTypes.AVG) {
        const total = layerValues.reduce((sum, val) => {
          const newTotal = sum + val;
          return newTotal;
        }, 0);
        value = total / layerValues.length;
      }
    }
  }

  let colorBreakIndex = determineColorIndex(value, breaks);

  // Swap the index for headroom as we reverse the color scale
  if (
    layerType === layerTypes.HEADROOM &&
    colorBreakIndex !== null &&
    colorBreakIndex !== undefined
  ) {
    colorBreakIndex = 4 - colorBreakIndex;
  }

  const showNoValueColor =
    colorBreakIndex === null || colorBreakIndex === undefined || !selected[colorBreakIndex];
  return showNoValueColor ? defaultColor : colors[colorBreakIndex];
};

const determineActualResultsValue = (layerOptions, values) => {
  const { phaseType, phase, aggType, aggregation } = layerOptions;
  let resultValue;
  if (phaseType === 'three') {
    const key = `ABC_${aggType}_${aggregation}`;
    resultValue = values?.[key];
  } else {
    resultValue = values?.[`${phase}_${aggregation}`];
  }
  return resultValue;
};

const determineColorByVoltage = (layerOpt, puValues, actualValues, baseVoltage, defaultColor) => {
  const { rangeBreaks, puRangeBreaks, colors, selected, dispType, phase } = layerOpt;

  let colorBreakIndex;

  let voltageValue;
  // puValues is an array with the puVoltages at each cable terminal
  if (dispType === 'pu' && puValues?.length === 2) {
    if (layerOpt.phaseType === 'three') {
      const { aggregation } = layerOpt;

      switch (layerOpt.aggType) {
        case 'min': {
          const minKey = `ABC_min_${aggregation}`;
          const values = nonNull([puValues[0][minKey], puValues[1][minKey]]);
          voltageValue = Math.min(...values);
          break;
        }
        case 'max': {
          const maxKey = `ABC_max_${aggregation}`;
          const values = nonNull([puValues[0][maxKey], puValues[1][maxKey]]);
          voltageValue = Math.max(...values);
          break;
        }
        default: {
          const avg = `ABC_avg_${aggregation}`;
          if (puValues[0] === undefined) {
            voltageValue = puValues[1][avg];
          } else if (puValues[1] === undefined) {
            voltageValue = puValues[0][avg];
          } else {
            const values = [puValues[0][avg], puValues[1][avg]];
            voltageValue = average(values);
          }
        }
      }
    } else {
      const getPhasePUValue = (node1, node2, phaseCode) => {
        const key = `${phaseCode}_${layerOpt.aggregation}`;
        if (node1 && node2 && !!node1[key] && !!node2[key]) {
          Math.min(node1[key], node2[key]);
        }
        if (node1 && node1[key]) return node1[key];
        if (node2 && node2[key]) return node2[key];
        return null;
      };

      voltageValue = getPhasePUValue(puValues[0], puValues[1], phase);
    }
    colorBreakIndex = determineColorIndex(voltageValue, puRangeBreaks);
  } else if (dispType === 'actual') {
    if (baseVoltage !== layerOpt.baseV) return defaultColor;
    voltageValue = determineActualResultsValue(layerOpt, actualValues);
    colorBreakIndex = determineColorIndex(voltageValue, rangeBreaks);
  }
  return colorBreakIndex !== undefined && selected[colorBreakIndex]
    ? colors[colorBreakIndex]
    : defaultColor;
};

const getPowerFactorColor = (powerFactor, options, defaultColor) => {
  let color = defaultColor;
  if (powerFactor) {
    const { aggType, phase, phaseType, rangeBreaks, colors, selected } = options;
    let value;
    if (phaseType === 'three') {
      value = powerFactor[`ABC_${aggType}_${options.aggregation}`];
    } else {
      value = powerFactor[`${phase}_${options.aggregation}`];
    }
    if (value === null || value === undefined) {
      color = defaultColor;
    } else if (value <= 0 && value > rangeBreaks[0]) {
      color = selected[0] ? colors[0] : defaultColor;
    } else if (value >= 0 && value < rangeBreaks[3]) {
      color = selected[4] ? colors[4] : defaultColor;
    } else if (value > rangeBreaks[1] && value <= rangeBreaks[0]) {
      color = selected[1] ? colors[1] : defaultColor;
    } else if (value < rangeBreaks[2] && value >= rangeBreaks[3]) {
      color = selected[3] ? colors[3] : defaultColor;
    } else if (value >= rangeBreaks[2] || value <= rangeBreaks[1]) {
      color = selected[2] ? colors[2] : defaultColor;
    }
  }
  return color;
};

const determineCableColor = (feature, layers, layerOptions, defaultColor, feederColor, result) => {
  const { aggregation } = layerOptions;
  switch (layers) {
    case 'voltage':
      const { voltage } = layerOptions;
      return determineColorByVoltage(
        { ...voltage, aggregation },
        result?.puVoltage,
        result?.voltages,
        feature.properties.nodeBaseVoltage,
        defaultColor,
      );
    case 'current':
      const { current } = layerOptions;
      const { rangeBreaks, percentBreaks, layerType } = current;
      const breaks = layerType === 'loading' ? percentBreaks : rangeBreaks;
      return determineColorByLayerType(
        { ...current, aggregation },
        result?.i,
        feature.properties.ratedCurrent,
        breaks,
        defaultColor,
      );
    case 'real_power':
      const { real_power } = layerOptions;
      const realPowerValue = determineActualResultsValue(
        { ...real_power, aggregation },
        result?.actualP,
      );
      const pIndex = determineColorIndex(realPowerValue, real_power.rangeBreaks);
      const showPColor = pIndex !== null && pIndex !== undefined && real_power.selected[pIndex];
      return showPColor ? real_power.colors[pIndex] : defaultColor;
    case 'reactive_power':
      const { reactive_power } = layerOptions;
      const reactivePowerValue = determineActualResultsValue(
        { ...reactive_power, aggregation },
        result?.actualQ,
      );
      const qIndex = determineColorIndex(reactivePowerValue, reactive_power.rangeBreaks);
      const showQColor = qIndex !== null && qIndex !== undefined && reactive_power.selected[qIndex];
      return showQColor ? reactive_power.colors[qIndex] : defaultColor;
    case 'apparent_power':
      const { apparent_power } = layerOptions;
      if (apparent_power.layerType !== 'actual') return defaultColor;
      return determineColorByLayerType(
        { ...apparent_power, aggregation },
        result?.apparentPower,
        {},
        apparent_power.rangeBreaks,
        defaultColor,
      );
    case 'power_factor':
      return getPowerFactorColor(
        result?.powerFactor,
        { ...layerOptions.power_factor, aggregation: layerOptions.aggregation },
        defaultColor,
      );
    case 'hosting_capacity':
    case 'ev_capacity':
      return defaultColor;
    case 'real_power_losses':
      const { real_power_losses } = layerOptions;
      const actualValue = determineActualResultsValue(
        { ...real_power_losses, aggregation },
        result?.pLosses,
      );
      const index = determineColorIndex(actualValue, real_power_losses.rangeBreaks);
      const showColor = index !== null && index !== undefined && real_power_losses.selected[index];
      return showColor ? real_power_losses.colors[index] : defaultColor;
    case 'phase':
      const phase = feature.properties.phase.replace('N', '');
      return phase === 'ABC' ? defaultColor : PHASE_TO_COLOR[phase];
    case 'topology':
      // If this line is reduced, ie both topological nodes
      // are defined and are the same then we render in red
      // else render in green
      return feature.properties.hasReducedTopology ? '#FD5050' : '#7AD400';
    case 'per_feeder':
      return feederColor;
    default:
      return defaultColor;
  }
};

export const getVoltageRangeBreaks = baseVoltage => [
  baseVoltage * 1.06,
  baseVoltage * 1.03,
  baseVoltage * 0.97,
  baseVoltage * 0.94,
];

const updateLayerRanges = (layerOptions, ratedCurrentRange, baseVoltages, ratedSRange) => {
  const { voltage, current, apparent_power } = layerOptions;
  const { max: currentMax } = ratedCurrentRange;
  const { min: ratedSMin, max: ratedSMax } = ratedSRange;
  const hasBaseVoltages = baseVoltages && baseVoltages.length > 0;

  let voltageRangeBreaks;
  if (hasBaseVoltages) {
    voltageRangeBreaks = getVoltageRangeBreaks(baseVoltages[0]);
  } else {
    voltageRangeBreaks = voltage.rangeBreaks;
  }

  const updatedVoltage = {
    ...voltage,
    baseVoltages,
    range: hasBaseVoltages ? [voltageRangeBreaks[0], voltageRangeBreaks[3]] : voltage.range,
    rangeBreaks: voltageRangeBreaks,
    baseV: baseVoltages.length > 0 ? baseVoltages[0] : '',
  };

  const updatedCurrent = {
    ...current,
    range: currentMax ? [currentMax, 1] : current.range,
    rangeBreaks: currentMax ? getRangeBreaks(1, currentMax) : current.rangeBreaks,
  };

  const updatedApparentPower = {
    ...apparent_power,
    range: ratedSMin && ratedSMax ? [ratedSMax, ratedSMin] : apparent_power.range,
    rangeBreaks:
      ratedSMin && ratedSMax ? getRangeBreaks(ratedSMin, ratedSMax) : apparent_power.rangeBreaks,
  };

  return {
    ...layerOptions,
    current: updatedCurrent,
    voltage: updatedVoltage,
    apparent_power: updatedApparentPower,
  };
};

const getPhaseSelectOptions = (aggType, phase, showThreePhase, addSum = false) => {
  const optionsObj = [
    {
      id: 'three',
      label: 'Three-Phase',
      selectOptions: [
        { value: 'avg', label: 'Average' },
        { value: 'min', label: 'Min' },
        { value: 'max', label: 'Max' },
      ],
      selectValue: aggType,
      disabled: !showThreePhase,
    },
    {
      id: 'single',
      label: 'Single-Phase',
      selectOptions: [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
      ],
      selectValue: phase,
    },
  ];
  if (addSum) {
    optionsObj[0].selectOptions = [...optionsObj[0].selectOptions, { value: 'sum', label: 'Sum' }];
  }
  return optionsObj;
};

const redToBlue = [
  layerColors[12],
  layerColors[0],
  layerColors[7],
  layerColors[6],
  layerColors[10],
];

const greenToRed = [
  layerColors[7],
  layerColors[6],
  layerColors[10],
  layerColors[0],
  layerColors[12],
];

const defaultLayerOptions = {
  aggregation: 'avg',
  hosting_capacity: {
    range: [10000000, 2500000],
    rangeBreaks: [10000000, 7500000, 5000000, 2500000],
    selected: [true, true, true, true, true],
    phase: 'A',
    mode: 'balanced',
    colors: [...greenToRed],
  },
  ev_capacity: {
    range: [10000000, 2500000],
    rangeBreaks: [10000000, 7500000, 5000000, 2500000],
    selected: [true, true, true, true, true],
    phase: 'A',
    mode: 'balanced',
    colors: [...greenToRed],
  },
  operational_envelope: {
    range: [0],
    rangeBreaks: [0],
    colors: [layerColors[7]],
    selected: [true],
  },
  current: {
    range: [1000, 1],
    rangeBreaks: [1000, 250, 100, 1],
    percentBreaks: [100, 90, 80, 70],
    selected: [true, true, true, true, true],
    phase: 'A',
    phaseType: 'single',
    aggType: 'avg',
    layerType: 'loading',
    colors: [...redToBlue],
  },
  apparent_power: {
    range: [400000, 100000],
    rangeBreaks: [400000, 300000, 200000, 100000],
    percentBreaks: [100, 90, 80, 70],
    selected: [true, true, true, true, true],
    phase: 'A',
    phaseType: 'single',
    aggType: 'avg',
    layerType: 'loading',
    colors: [...redToBlue],
  },
  real_power: {
    range: [400000, 100000],
    rangeBreaks: [400000, 300000, 200000, 100000],
    selected: [true, true, true, true, true],
    phase: 'A',
    phaseType: 'single',
    aggType: 'avg',
    colors: [...redToBlue],
    directionalFlow: false,
  },
  reactive_power: {
    range: [400000, 100000],
    rangeBreaks: [400000, 300000, 200000, 100000],
    selected: [true, true, true, true, true],
    phase: 'A',
    phaseType: 'single',
    aggType: 'avg',
    colors: [...redToBlue],
    directionalFlow: false,
  },
  power_factor: {
    range: [-0.94, 0.94],
    rangeBreaks: [-0.94, -0.97, 0.97, 0.94],
    selected: [true, true, true, true, true],
    phase: 'A',
    phaseType: 'single',
    aggType: 'avg',
    colors: [...redToBlue],
  },
  real_power_losses: {
    range: [20000, 100000],
    rangeBreaks: [20000, 15000, 10000, 5000],
    selected: [true, true, true, true, true],
    phase: 'A',
    phaseType: 'single',
    aggType: 'avg',
    colors: [...redToBlue],
  },
  voltage: {
    range: [1.06, 0.94],
    puRangeBreaks: [1.06, 1.03, 0.97, 0.94],
    rangeBreaks: [1000, 250, 100, 1],
    selected: [true, true, true, true, true],
    colors: [...redToBlue],
    phaseType: 'single',
    baseVoltages: [],
    aggType: 'avg',
    phase: 'A',
    dispType: 'pu',
    baseV: '',
  },
  generation_load: {
    selected: [true, true],
    colors: [layerColors[12], layerColors[7]],
  },
  battery_sizing_energy: {
    range: [10000000, 0],
    rangeBreaks: [10000000, 7500000, 5000000, 2500000],
    selected: [true, true, true, true, true],
    colors: [...greenToRed],
  },
  battery_sizing_real_power: {
    range: [10000000, 0],
    rangeBreaks: [10000000, 7500000, 5000000, 2500000],
    selected: [true, true, true, true, true],
    colors: [...greenToRed],
  },
  battery_sizing_reactive_power: {
    range: [10000000, 0],
    rangeBreaks: [10000000, 7500000, 5000000, 2500000],
    selected: [true, true, true, true, true],
    colors: [...greenToRed],
  },
};

const updateLayerStorage = (
  inEditMode,
  branch,
  workspace,
  selectedScenario,
  selectedAnalysis,
  layerOptions,
  displayBranch = '',
) => {
  let updatedOptions;
  const branchName = inEditMode ? displayBranch : branch;
  const storedLayerOptions = localStorage.getItem(`${workspace}_${branchName}`);
  if (storedLayerOptions && JSON.parse(storedLayerOptions)) {
    const storeLayerOptionsObj = JSON.parse(storedLayerOptions);
    updatedOptions = {
      ...storeLayerOptionsObj,
      [`${branchName}_${selectedScenario}_${selectedAnalysis}`]: layerOptions,
    };
  } else {
    updatedOptions = {
      [`${branchName}_${selectedScenario}_${selectedAnalysis}`]: layerOptions,
    };
  }
  localStorage.setItem(`${workspace}_${branchName}`, JSON.stringify(updatedOptions));
};

export {
  layerTypes,
  getRangeBreaks,
  determineCableColor,
  determineColorIndex,
  determineColorByLayerType,
  determineColorByVoltage,
  determineActualResultsValue,
  createRangeBuckets,
  createLabels,
  createHeadroomLabels,
  layerColors,
  updateLayerRanges,
  noValueColor,
  getPowerFactorColor,
  getPhaseSelectOptions,
  defaultLayerOptions,
  greenToRed,
  redToBlue,
  updateLayerStorage,
};
