import React, { useContext, FunctionComponent } from 'react';
import Tooltip from 'components/Tooltip';
import ThemeContext from 'helpers/ThemeContext';

type HelpTooltipProps = {
  message: string | JSX.Element;
  type?: string;
};

const HelpTooltip: FunctionComponent<HelpTooltipProps> = ({ message, type = 'default' }) => {
  const theme = useContext(ThemeContext);
  return (
    <Tooltip placement="right" content={message} theme={theme} className={type}>
      <i className="material-icons help-icon">help_outline</i>
    </Tooltip>
  );
};

export default HelpTooltip;
