import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import CustomScrollBar from 'components/CustomScrollBar';
import AnalysisEvent from './AnalysisEvent';
import './ActivityLogPanel.scss';

const ActivityLogPanel = ({ containers, selectedContainers, expanded, permissions }) => {
  const { logEntries, cancelJob } = useContext(ActivityLogContext);
  return (
    <div className="activity-log-panel">
      <div
        className={classNames({
          'panel-header': true,
          'panel-header--expanded': expanded,
        })}
      >
        <h1 className="title-text">Activity Log</h1>
      </div>
      <div
        className={classNames({
          'activity-log-events': true,
          'activity-log-events--expanded': expanded,
        })}
      >
        <CustomScrollBar alwaysShow={false} className="">
          {logEntries.map(evt => (
            <AnalysisEvent
              event={evt}
              containers={containers}
              selectedContainers={selectedContainers}
              cancelJob={cancelJob}
              key={evt.job_id}
              permissions={permissions}
            />
          ))}
        </CustomScrollBar>
      </div>
    </div>
  );
};

ActivityLogPanel.propTypes = {
  actions: PropTypes.shape({
    getBatteryAnalysisResults: PropTypes.func,
  }).isRequired,
  containers: PropTypes.array.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default ActivityLogPanel;
