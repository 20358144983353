import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from 'components/Card';
import Tooltip from 'components/Tooltip';
import PermissionDisabledTip from 'components/PermissionDisabledTip';

const AnalysisTile = ({ permissionDenied, option, theme, onClick, active, tooltipMessage }) => {
  const button = (
    <button
      className={classNames('analysis-option', 'analysis-type', {
        'analysis-type--active': active,
      })}
      id={option.value}
      disabled={option.disabled}
      onClick={onClick}
      type="button"
    >
      <Card theme={theme} hideTitle>
        <span>{option.label}</span>
      </Card>
    </button>
  );

  if (permissionDenied) {
    return (
      <PermissionDisabledTip
        title={option.label}
        hide={false}
        placement="top"
        theme={theme}
        className="analysis-option"
      >
        {button}
      </PermissionDisabledTip>
    );
  }
  if (option.disabled) {
    return (
      <Tooltip placement="top" content={tooltipMessage} theme={theme} className="analysis-option">
        {button}
      </Tooltip>
    );
  }

  return button;
};

AnalysisTile.defaultProps = {
  permissionDenied: false,
  theme: 'light',
  active: false,
  tooltipMessage: null,
};

AnalysisTile.propTypes = {
  permissionDenied: PropTypes.bool,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    permissions: PropTypes.array,
  }).isRequired,
  theme: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  tooltipMessage: PropTypes.string,
};

export default AnalysisTile;
