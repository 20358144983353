import React, { useContext, FunctionComponent, useState } from 'react';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import useTrackingState from 'hooks/useTrackingState';
import FeederNodeSelectModal from './FeederNodeSelectModal';

interface BackupNodeOption {
  id: string | null;
  name: string;
}

type BackupNodeSelectorProps = {
  handleSaveOnselectedValue: (args: any) => void | null | number;
  existingBackupNode: string;
  allNodes: BackupNodeOption[];
  isDisabled: boolean;
  nodeId: string;
  allFeeders: { [key: string]: string }[];
  workspace: string;
  branch: string;
};

const BackupNodeSelector: FunctionComponent<BackupNodeSelectorProps> = ({
  handleSaveOnselectedValue,
  existingBackupNode,
  allNodes,
  isDisabled,
  nodeId,
  allFeeders,
  workspace,
  branch,
}) => {
  const [selectedBackupNode, setSelectedBackupNode] = useTrackingState(existingBackupNode);
  const theme = useContext(ThemeContext);
  const [modalActive, setModalActive] = useState(false);
  const backupNodeOptions =
    allNodes
      ?.filter(option => option.id !== nodeId)
      .map(option => ({
        value: option.id,
        label: option.name || option.id,
      })) ?? [];

  backupNodeOptions.push({ value: 'search all', label: '...search all' });
  return (
    <>
      <Select
        value={selectedBackupNode}
        className="custom-react-select-theme"
        clearable
        disabled={isDisabled}
        theme={theme}
        onChange={selectedOption => {
          if (selectedOption?.value === 'search all') {
            setModalActive(true);
          } else {
            setSelectedBackupNode(selectedOption ? selectedOption.value : '');
            handleSaveOnselectedValue({
              backup_nodes: selectedOption ? [selectedOption.value] : [],
            });
          }
        }}
        options={backupNodeOptions}
        id="backup-node"
      />
      {modalActive && (
        <FeederNodeSelectModal
          onConfirm={(nodeSelected: string | null) => {
            setModalActive(false);
            setSelectedBackupNode(nodeSelected ?? '');
            handleSaveOnselectedValue({ backup_nodes: [nodeSelected] });
          }}
          onCancel={() => setModalActive(false)}
          nodeId={nodeId}
          allFeeders={allFeeders}
          workspace={workspace}
          branch={branch}
          theme={theme}
        />
      )}
    </>
  );
};

export default BackupNodeSelector;
