import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import Card from '../Card';
import IconButton from '../IconButton';

const NoteCard = ({
  content,
  createdTime,
  disabled,
  onDelete,
  onSave,
  permissions,
  theme,
  updatedTime,
}) => {
  const [editing, setEditing] = useState(false);
  const [markDownSource, setMarkdownSource] = useState(decodeURIComponent(content));

  const canDelete = permissions.has('delete_asset_notes');
  const canEdit = permissions.has('edit_asset_notes');
  const getMenuButtons = () => (
    <span className="button-container">
      <IconButton
        className="delete-note"
        disabled={!canDelete || disabled}
        icon="delete"
        onClick={onDelete}
        theme={theme}
        tooltip={canDelete !== true ? '' : 'Delete Note'}
      />
      {editing === false && (
        <IconButton
          className="edit-note"
          disabled={!canEdit || disabled}
          icon="edit"
          onClick={() => setEditing(true)}
          theme={theme}
          tooltip={canEdit !== true ? '' : 'Edit Note'}
        />
      )}
      {editing === true && (
        <>
          <IconButton
            className="cancel-edit-note"
            disabled={disabled}
            icon="undo"
            onClick={() => {
              setEditing(false);
              setMarkdownSource(decodeURIComponent(content));
            }}
            theme={theme}
            tooltip="Cancel Edit"
          />
          <IconButton
            className="save-edit-note"
            disabled={disabled}
            icon="save"
            onClick={() => {
              setEditing(false);
              onSave(encodeURI(markDownSource));
            }}
            theme={theme}
            tooltip="Save Edit"
          />
        </>
      )}
    </span>
  );

  return (
    <Card
      buttonGroup={getMenuButtons()}
      className="note-card"
      secondaryTitle={`Updated ${moment(updatedTime).fromNow()}`}
      title={`Created ${moment(createdTime).fromNow()}`}
      theme={theme}
    >
      {editing === false && (
        <div className="viewer">
          <div className="markdown-body">
            <Markdown escapeHtml source={markDownSource} />
          </div>
        </div>
      )}
      {editing === true && (
        <div className="editor">
          <textarea
            autoComplete="off"
            className="description-editor"
            onChange={e => setMarkdownSource(e.target.value)}
            placeholder="Enter a component description as Markdown text here"
            spellCheck
            value={markDownSource}
            wrap="soft"
          />
        </div>
      )}
    </Card>
  );
};

NoteCard.defaultProps = {
  content: '',
};

NoteCard.propTypes = {
  content: PropTypes.string,
  createdTime: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  updatedTime: PropTypes.string.isRequired,
};

export default NoteCard;
