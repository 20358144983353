import React, { ReactElement, FunctionComponent, ReactChild, ReactNode } from 'react';
import classNames from 'classnames';
import Tooltip from '../Tooltip';
import Button from '../Button';
import { ThemeProp } from '../../types/index';

import './IconButton.scss';

type IconButtonProps = {
  className?: string;
  id?: string;
  theme?: ThemeProp | string;
  disabled?: boolean;
  icon?: string | ReactElement;
  onClick: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  active?: boolean;
  hasNotification?: boolean;
  round?: boolean;
  rotate?: number;
  tooltip?: ReactElement | string | null;
  children?: ReactChild | ReactNode;
  notificationCount?: number;
  loading?: boolean;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
};

const BaseButton: FunctionComponent<IconButtonProps> = ({
  onClick,
  active = false,
  disabled = false,
  hasNotification = false,
  round = false,
  className = '',
  children = null,
  rotate = null,
  icon = 'transparent', // making icon transparent if no icon passed to make it look like non icon button visually
  id = '',
  notificationCount = null,
  loading = false,
}) => (
  <div className={`icon-btn-container ${className}`}>
    <Button
      type="primary"
      className={classNames({
        'icon-btn': true,
        'icon-btn--active': active,
        [className]: true,
        round,
      })}
      id={id}
      disabled={disabled}
      onClick={onClick}
    >
      {hasNotification && (
        <div className={notificationCount ? 'has-count-notification' : 'has-notification'}>
          {notificationCount && hasNotification ? <span> {notificationCount} </span> : null} &nbsp;
        </div>
      )}
      {icon && icon !== 'transparent' ? (
        <span className="icon-container">
          <i
            {...(rotate && rotate > 0 && rotate < 360
              ? { style: { transform: `rotate(${Math.trunc(rotate)}deg)` } }
              : {})}
            className="material-icons"
          >
            {icon}
          </i>
        </span>
      ) : (
        <div className="icon-container">
          {loading ? <i className="material-icons rotate">refresh</i> : children}
        </div>
      )}
    </Button>
  </div>
);

const IconButton: FunctionComponent<IconButtonProps> = ({
  tooltip = null,
  tooltipPlacement = 'top',
  ...props
}) =>
  tooltip ? (
    <Tooltip content={tooltip} placement={tooltipPlacement}>
      <BaseButton {...props} />
    </Tooltip>
  ) : (
    <BaseButton {...props} />
  );

export default IconButton;
