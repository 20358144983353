import AssetInfo from './AssetInfo';

class InverterInfo extends AssetInfo {
  get ratedPowerFactor() {
    return this.attributes['InverterInfo.ratedPowerFactor'];
  }

  get ratedS() {
    return this.attributes['PowerElectronicsConnectionInfo.ratedS'];
  }

  get ratedU() {
    return this.attributes['PowerElectronicsConnectionInfo.ratedU'];
  }

  get minP() {
    return this.attributes['InverterInfo.minP'];
  }

  get maxP() {
    return this.attributes['InverterInfo.maxP'];
  }

  get minQ() {
    return this.attributes['PowerElectronicsConnectionInfo.minQ'];
  }

  get maxQ() {
    return this.attributes['PowerElectronicsConnectionInfo.maxQ'];
  }
}

export default InverterInfo;
