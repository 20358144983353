import React, { FunctionComponent, useState } from 'react';
import ResultsCard from 'components/ResultsCard';
import { ThemeProp } from 'types/index';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import Button from 'components/Button';
import ValueCard from './ValueCard';
import { useBaseline } from '../context/ResultsComparisonContext';
import { TYPE_MAP } from '../../Network/helpers/NetworkHelpers';
import { SummaryRequestParams } from '../helpers/ResultsComparisonHelpers';

type AssetsViolationsProps = {
  isBaseline: boolean;
  theme: ThemeProp;
  workspace: string;
  branch: string;
  violationsTotal?: number | null;
  analysisDataUpdateTrigger: symbol;
  requestParams: SummaryRequestParams;
};

type ViolationsSummaryResult = {
  violation_assets: { [key: string]: { [key: string]: number } };
};

type ViolationsAssetResult = {
  [key: string]: number;
};

const AssetsViolations: FunctionComponent<AssetsViolationsProps> = ({
  theme,
  workspace,
  branch,
  isBaseline,
  violationsTotal,
  analysisDataUpdateTrigger,
  requestParams,
}) => {
  const assetTypeMap = TYPE_MAP as { [assetClass: string]: { [key: string]: string } };
  const [violationsAssets, setViolationsAssets] = useState<ViolationsAssetResult>({});
  const { baseline, setBaseline } = useBaseline<ViolationsAssetResult>('violationAssetBaseline');
  const { loading } = useRequestEffect<ViolationsSummaryResult>({
    url: `/api/workspace/${workspace}/branch/${branch}/power-flow-results/violations/summary`,
    method: 'get',
    params: {
      ...requestParams,
      group_by_asset_type: true,
    },
    refetchOnChange: [analysisDataUpdateTrigger],
    onSuccess: data => {
      if (data) {
        const violationAssets = Object.entries(data?.violation_assets);
        const vAsset: ViolationsAssetResult = {};
        violationAssets.forEach(([assetType, value_type]) => {
          const vTypes = Object.values(value_type);
          assetType = assetType === 'NONE' ? 'Others' : assetType;
          vAsset[assetType] = vTypes.reduce((countbyVtype, value) => countbyVtype + value, 0);
        });
        // { assetType: count }
        setViolationsAssets(vAsset);
        if (isBaseline) {
          setBaseline(vAsset);
        }
      }
    },
    onError: () => {
      setViolationsAssets({});
    },
  });
  const violationAssetsArr = Object.entries(violationsAssets);
  return (
    <div className="asset-lists">
      <Button type="text" onClick={() => {}} className="pink-underline-section margin-10">
        All assets
      </Button>
      <ResultsCard theme={theme} className="all-asset-lists">
        {loading ? (
          <LoadingSkeleton template="square" width={100} theme={theme} count={4} height={30} />
        ) : (
          <div>
            {!violationsTotal && (
              <p className="no-violation-msg"> No assets found having violations</p>
            )}
          </div>
        )}
        {!loading &&
          violationsTotal &&
          violationAssetsArr.map(([assetType, count]) => (
            <ResultsCard
              theme={theme}
              className="single-asset results-comparison-card"
              withBorder={false}
              key={assetType}
            >
              <div className="one-one grid-columns items-centered">
                <div className="single-asset-name">
                  {assetTypeMap[assetType]?.name || assetType}
                </div>
                <ValueCard
                  value={count}
                  showComparisonPercent
                  loading={loading}
                  baselineValue={baseline?.[assetType] || null}
                />
              </div>
            </ResultsCard>
          ))}
      </ResultsCard>
    </div>
  );
};

export default AssetsViolations;
