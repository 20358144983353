import React, { FunctionComponent, useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import { isDefined } from 'helpers/utils';

import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import {
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
} from '../../helpers/assetModelHelpers';

import Helpers from '../../helpers/EquipmentLibraryHelpers';

import DescriptionEditor from './DescriptionEditor';
import CostEditor from './CostEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

type CompansatorType = {
  description: string;
  name: string;
  ratedReactivePower: number | null;
  AssetModel: Record<string, any>;
  id?: string;
};

type ShuntCompensatorInfoPanelProps = {
  handleCreate: (equipmentType: string, arg: CompansatorType | null, eqClass: string) => void;
  handleEdit: (
    id: string,
    obj: { [key: string]: string | number | null | Record<string, any> },
  ) => void;
  createInstanceReq: number;
  selected: CompansatorType | null;
  isAuthEnabled: boolean;
  permissions: Set<string>;
  match?: {
    params: {
      workspace: string | null;
      branch: string | null;
    };
  };
};

const defaultCompensatorInfo = {
  description: '',
  name: '',
  ratedReactivePower: null,
  AssetModel: defaultAssetModel,
  id: 'add',
};

const ShuntCompensatorInfoPanel: FunctionComponent<ShuntCompensatorInfoPanelProps> = ({
  handleCreate,
  handleEdit,
  createInstanceReq,
  selected,
  isAuthEnabled,
  permissions,
  match,
}) => {
  const [compensatorInfoState, setCompensatorInfoState] = useState<CompansatorType>(
    defaultCompensatorInfo,
  );

  const getInputValues = (inputValues: CompansatorType | null) => {
    let asset: any;
    if (!inputValues) {
      asset = defaultCompensatorInfo;
    } else {
      const { description, name, ratedReactivePower, AssetModel, id } = selected as {
        description: string;
        name: string;
        ratedReactivePower: number | null;
        AssetModel: Record<string, any>;
        id: string;
      };
      asset = {
        description,
        name: name || asset.name,
        ratedReactivePower:
          (ratedReactivePower && ratedReactivePower / 1000) || asset.ratedReactivePower,
        AssetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : defaultAssetModel,
        id,
      };
      return asset;
    }
    return asset;
  };

  useEffect(() => {
    setCompensatorInfoState({ ...getInputValues(selected) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSave = (): void => {
    const diffModel = {
      name: compensatorInfoState.name,
      description: compensatorInfoState.description,
      ratedReactivePower:
        compensatorInfoState?.ratedReactivePower && compensatorInfoState.ratedReactivePower * 1000,
      AssetModel: compensatorInfoState.AssetModel,
    };
    if (compensatorInfoState.id === 'add') {
      handleCreate('shunt_compensator_info', diffModel, 'ShuntCompensatorInfo');
    } else {
      // Only submit the values that have changed
      const editDiffModel = Object.entries(diffModel).reduce(
        (diff: { [key: string]: string | number | null | Record<string, any> }, [key, val]) => {
          if (key === 'AssetModel') {
            if (selected && !areAssetModelsEqual(diffModel[key], selected[key])) {
              diff[key] = diffModel[key];
            }
          } else if (selected && val !== selected[key as keyof CompansatorType]) {
            diff[key] = val;
          }
          return diff;
        },
        {},
      );
      handleEdit(selected?.id ?? '', editDiffModel);
    }
  };

  const handleInputChange = ({ id, value }: { id: string; value: string | number }) => {
    setCompensatorInfoState({ ...compensatorInfoState, [id]: value } as any);
  };

  const valuesUpdated = (selectedField: CompansatorType | null) => {
    if (!selectedField) return true;
    const stringUpdated =
      selectedField.name !== compensatorInfoState.name ||
      selectedField.description !== compensatorInfoState.description;

    const numberUpdated =
      compensatorInfoState.ratedReactivePower &&
      compensatorInfoState.ratedReactivePower * 1000 !== selectedField.ratedReactivePower;

    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selectedField.AssetModel),
      compensatorInfoState.AssetModel,
    );

    return stringUpdated || numberUpdated || assetModelUpdated;
  };

  const formValid = () =>
    compensatorInfoState?.name?.trim().length > 0 &&
    compensatorInfoState.ratedReactivePower &&
    valuesUpdated(selected);

  const ratedReactivePower = Helpers.createDisplayObject(
    'Rated Reactive Power',
    'ratedReactivePower',
    compensatorInfoState.ratedReactivePower,
    'kVAr',
  );
  const isDisabled =
    isAuthEnabled &&
    ((compensatorInfoState.id === 'add' && !permissions.has('create_equipment_type')) ||
      (compensatorInfoState.id !== 'add' && !permissions.has('edit_equipment_type')) ||
      (match?.params.branch === 'master' && !permissions.has('modify_network_as_built')));

  const defaultProps = {
    disabled: isDisabled,
  };

  return (
    <PanelTabs
      submitDisabled={!formValid() || isDisabled}
      onSubmit={handleSave}
      createInstanceReq={createInstanceReq}
      tabs={['General', 'Description', 'Costs']}
      assetID={compensatorInfoState.id}
      showSave
    >
      {[
        <div className="equipment-info-container" key={compensatorInfoState.id || '1'}>
          <div className="right-panel">
            <TextInput
              {...defaultProps}
              id="name"
              label="Name"
              value={compensatorInfoState.name}
              onChange={e =>
                handleInputChange({ id: e?.target.id ?? '', value: e?.target.value ?? '' })
              }
              required
              inputWidth="225px"
            />
            <NumberInput
              onChange={handleInputChange}
              ge={0}
              required
              {...defaultProps}
              {...ratedReactivePower}
              inputStyle="eq-lib"
            />
          </div>
          <div className="column">
            <h2 className="column-title">Description</h2>
            <div className="markdown-body">
              <Markdown escapeHtml source={decodeURIComponent(compensatorInfoState.description)} />
            </div>
          </div>
        </div>,
        <DescriptionEditor
          description={compensatorInfoState.description}
          key={`${compensatorInfoState.id}-description`}
          onChange={(d: string) =>
            setCompensatorInfoState({
              ...compensatorInfoState,
              description: d,
            })
          }
          isDisabled={isDisabled}
        />,
        <CostEditor
          assetModel={compensatorInfoState?.AssetModel}
          key={`${compensatorInfoState.id}-costs`}
          onChange={prop => {
            setCompensatorInfoState(prevState => ({
              ...prevState,
              AssetModel: { ...prevState.AssetModel, ...prop },
            }));
          }}
          isDisabled={isDisabled}
        />,
      ]}
    </PanelTabs>
  );
};

export default ShuntCompensatorInfoPanel;
