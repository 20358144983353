import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import RadioButtonGroup from 'components/RadioButtonGroup';

const SwitchTypes = ['switch', 'disconnector', 'fuse', 'recloser', 'sectionaliser', 'breaker'].map(
  type => ({
    id: type,
    label: capitalize(type),
  }),
);

const selectSwitchType = (values, updateValues) => {
  if (!values) {
    updateValues({
      switch_type: 'switch',
    });
  }

  return e =>
    updateValues({
      ...values,
      switch_type: e.target.value,
    });
};

const SwitchOptions = ({ values, updateValues }) => (
  <div>
    <div className="subheading">
      <h4>Select type of switch</h4>
    </div>
    <div className="options">
      <RadioButtonGroup
        options={SwitchTypes}
        onChange={selectSwitchType(values, updateValues)}
        value={(values && values.switch_type) || 'switch'}
      />
    </div>
  </div>
);

SwitchOptions.propTypes = {
  values: PropTypes.object.isRequired,
  updateValues: PropTypes.func.isRequired,
};

export default SwitchOptions;
