import asyncActionStates from 'helpers/asyncActionStates';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment-timezone';
import ThemeContext from 'helpers/ThemeContext';
import { getAnalyses } from 'routes/WorkspaceLayout/routes/Network/helpers/NetworkHelpers';
import { safeGetErrorMessage } from 'routes/WorkspaceLayout/routes/Network/helpers/AssetScheduleHelpers';
import { Request } from '@opusonesolutions/gridos-app-framework';
import SchedulePanel from './SchedulePanel';

const emptyResult = () => ({ datapoints: [], variables: {}, error: '' });

const AnalysisSchedule = ({
  workspace,
  branch,
  scenario,
  scenarioType,
  asset,
  header,
  legend,
  panelValues,
  timeRange,
  maxRange,
  expanded,
  permissions,
  timeBarZoomLevel,
}) => {
  const theme = useContext(ThemeContext);
  const [loadingStatus, setLoadingStatus] = useState(asyncActionStates.INITIAL);
  const [{ datapoints, variables, error }, setLoadingResult] = useState(emptyResult());
  const [analyses, setAnalyses] = useState([]);
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);

  const fetchAnalyses = async () => {
    let analysisList = [];
    if (scenario) {
      analysisList = await getAnalyses(workspace, branch, scenario, permissions);
    }
    setAnalyses(
      analysisList?.filter(analysis =>
        analysis.containers.includes(asset?.linked_equipment_container),
      ),
    );
  };

  useEffect(() => {
    fetchAnalyses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, scenario, permissions]);

  async function loadScheduleData(request) {
    setLoadingStatus(asyncActionStates.LOADING);
    try {
      const response = await request.get({
        params: {
          scenario_id: scenario,
          start_date: moment(maxRange.start).toISOString(),
          end_date: moment(maxRange.end).toISOString(),
          analysis_name: selectedAnalysis,
        },
      });
      if (!request.wasCancelled) {
        setLoadingStatus(asyncActionStates.SUCCESS);
        setLoadingResult({
          ...emptyResult(),
          ...response.data,
        });
      }
    } catch (err) {
      if (!request.wasCancelled) {
        const errorCode = err && err.response && err.response.status;

        const errorMsg = errorCode === 404 ? '' : safeGetErrorMessage(err);
        setLoadingStatus(asyncActionStates.ERROR);
        setLoadingResult({ ...emptyResult(), error: errorMsg });
      }
    }
  }

  useEffect(() => {
    setLoadingResult(emptyResult());
    let request = null;
    if (scenario && selectedAnalysis) {
      if (!asset.linked.id) {
        setLoadingResult({ ...emptyResult(), error: 'Previous analysis requires a linked asset' });
      }

      request = new Request(
        `/api/workspace/${workspace}/branch/${branch}/analysis_schedule/${asset.linked.id}`,
      );

      loadScheduleData(request);
    }

    return () => {
      if (request) {
        request.cancel('Results are no longer relevant');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario, maxRange, selectedAnalysis, asset.linked.id, branch, workspace]);

  let errorMessage = error;
  if (!scenario) {
    errorMessage = 'Select a timeseries scenario to use previous analysis results';
  } else if (scenarioType === 'SINGLE_POINT') {
    errorMessage = 'The Previous Analysis control mode requires a timeseries scenario';
  } else if (scenario && analyses.length === 0) {
    errorMessage =
      'There are no previously run analyses which can be used as a schedule for this asset';
  } else if (scenario && analyses.length && !selectedAnalysis) {
    errorMessage = 'Select a previous analysis to use as analysis schedule';
  }

  return (
    <SchedulePanel
      header={header}
      variables={variables}
      datapoints={datapoints}
      showChart={datapoints.length > 0 && !errorMessage && !!scenario}
      panelValues={panelValues}
      assetType={asset.class}
      scheduleType="Analysis"
      error={errorMessage}
      editable
      loading={loadingStatus === asyncActionStates.LOADING}
      timeRange={timeRange}
      maxRange={maxRange}
      legend={legend}
      scheduleUploadDisabledMessage="Previous analysis results are based on the selected analyses run on the linked asset"
      scheduleDeleteDisabledMessage="Previous analysis results are based on the selected analyses run on the linked asset"
      expanded={expanded}
      currentAsset={asset}
      theme={theme}
      analyses={analyses}
      selectedAnalysis={selectedAnalysis}
      setSelectedAnalysis={setSelectedAnalysis}
      timeBarZoomLevel={timeBarZoomLevel}
    />
  );
};

AnalysisSchedule.defaultProps = {
  header: null,
  legend: '',
  panelValues: {},
  scenario: null,
};

AnalysisSchedule.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
    linked: PropTypes.object,
    linked_equipment_container: PropTypes.any,
  }).isRequired,
  branch: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  header: PropTypes.string,
  legend: PropTypes.string,
  maxRange: PropTypes.object.isRequired,
  panelValues: PropTypes.object,
  scenario: PropTypes.string,
  scenarioType: PropTypes.string.isRequired,
  timeRange: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
};

export default AnalysisSchedule;
