import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const LayersIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  height,
  width,
  className = '',
}) => (
  <svg width={width} height={height} viewBox="-215 -2302 35 35">
    <g id="layer" transform="translate(-1445 -2317)">
      <g id="layer_symbol" data-name="layer symbol">
        <g id="layers" transform="translate(9038.625 376.8)">
          <path
            id="Path_683"
            data-name="Path 683"
            className={className}
            fill={color}
            d="M-7801.4-346.5l10.5,4.8h.3l10.1-4.8a.313.313,0,0,0,0-.6l-10.1-4.7h-.3l-10.4,4.7C-7801.7-347-7801.7-346.6-7801.4-346.5Z"
          />
          <path
            id="Path_684"
            data-name="Path 684"
            className={className}
            fill={color}
            d="M-7780.6-342l-2.9-1.3-7.1,3.4h-.3l-7.5-3.5-3,1.4a.313.313,0,0,0,0,.6l10.5,4.8h.3l10.1-4.8A.341.341,0,0,0-7780.6-342Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default LayersIcon;
