import IdentifiedObject from '../IdentifiedObject';

class Feeder extends IdentifiedObject {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._color = '';
    this.nodes = [];
    this._energySource = null;
    this._class = 'Feeder';
  }

  get substation() {
    const substationID = this.references['Feeder.NormalEnergizingSubstation'];
    return substationID ? this.cimDict[substationID] : undefined;
  }

  get color() {
    return this._color;
  }

  set color(color) {
    this._color = color;
  }

  set energySource(energySource) {
    this._energySource = energySource;
  }

  get energySource() {
    return this._energySource;
  }

  addNode(node) {
    this.nodes = [...this.nodes, node];
  }
}

export default Feeder;
