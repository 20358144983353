/* eslint-disable import/no-cycle */
import moment from 'moment';
import Analytics from 'helpers/Analytics';
import { Request } from '@opusonesolutions/gridos-app-framework';
import { displayAlertMessage } from 'store/appState';
import { getAllBatterySizingResults } from '../helpers/NetworkHelpers';
import * as PFHelpers from '../helpers/PowerflowHelpers';
import {
  defaultLayerOptions,
  getRangeBreaks,
  updateLayerRanges,
} from '../helpers/VisualizationHelpers';
import { loadForecastActions, UPDATE_SELECTED_SCENARIO_LOADING } from './loadForecast';
import {
  CLEAR_NETWORK_DATA,
  NETWORK_DATA_SUCCESS,
  QSTS_VALUES_ERROR,
  QSTS_VALUES_SUCCESS,
  UPDATE_SELECTED_BRANCH,
} from './network';

// ------------------------------------
// Constants
// ------------------------------------
export const POWERFLOW_LOADING = 'POWERFLOW_LOADING';
export const POWERFLOW_SUCCESS = 'POWERFLOW_SUCCESS';
export const POWERFLOW_ERROR = 'POWERFLOW_ERROR';
export const POWERFLOW_INITIAL = 'POWERFLOW_INITIAL';
export const CLEAR_SELECTED_RANGE_DATA = 'CLEAR_SELECTED_RANGE_DATA';
export const CLEAR_POWERFLOW_RESULTS = 'CLEAR_POWERFLOW_RESULTS';
const HOSTING_CAPACITY_RESULTS_SUCCESS = 'HOSTING_CAPACITY_RESULTS_SUCCESS';
const HOSTING_CAPACITY_RESULTS_ERROR = 'HOSTING_CAPACITY_RESULTS_ERROR';
const EV_CAPACITY_RESULTS_SUCCESS = 'EV_CAPACITY_RESULTS_SUCCESS';
const EV_CAPACITY_RESULTS_ERROR = 'EV_CAPACITY_RESULTS_ERROR';
const OPERATIONAL_ENVP_RESULTS_SUCCESS = 'OPERATIONAL_ENVP_RESULTS_SUCCESS';
const OPERATIONAL_ENVP_RESULTS_ERROR = 'OPERATIONAL_ENVP_RESULTS_ERROR';
const BATTERY_SIZING_RESULTS_SUCCESS = 'BATTERY_SIZING_RESULTS_SUCCESS';
const BATTERY_SIZING_RESULTS_ERROR = 'BATTERY_SIZING_RESULTS_ERROR';
const GET_LAYER_OPTIONS = 'GET_LAYER_OPTIONS';
const UPDATE_LAYER_OPTIONS = 'UPDATE_LAYER_OPTIONS';
const UPDATE_LAYER_AGGREGATION = 'UPDATE_LAYER_AGGREGATION';
const USE_STORED_OPTIONS = 'USE_STORED_OPTIONS';
const RESET_JOB_QUEUED = 'RESET_JOB_QUEUED';
const VIOLATIONS_CLEARED = 'VIOLATIONS_CLEARED';
const TIMEBAR_VIOLATIONS_LOADING = 'TIMEBAR_VIOLATIONS_LOADING';
const TIMEBAR_VIOLATIONS_SUCCESS = 'TIMEBAR_VIOLATIONS_SUCCESS';
const TIMEBAR_VIOLATIONS_ERROR = 'TIMEBAR_VIOLATIONS_ERROR';
const TIMEBAR_VIOLATIONS_CLEARED = 'TIMEBAR_VIOLATIONS_CLEARED';
const GET_GIS_VIOLATIONS = 'GET_GIS_VIOLATIONS';
const CONFLICTING_ANALYSIS_ERROR =
  'A similar analysis is already running. Please check the activity log.';
const UPDATE_SELECTED_VIOLATIONS = 'UPDATE_SELECTED_VIOLATIONS';
const TOGGLE_DIRECTIONAL_FLOW = 'TOGGLE_DIRECTIONAL_FLOW';
const UPDATE_VIOLATION_DISPLAY_MIN = 'UPDATE_VIOLATION_DISPLAY_MIN';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  powerFlowErrorMessage: '',
  hostingCapacityTimepointData: {},
  evCapacityTimepointData: {},
  timebarViolations: [],
  GISViolations: {},
  violationsSelected: PFHelpers.violationsSelected,
  jobQueued: false,
  storedLayerOptions: {},
  layerOptions: { ...defaultLayerOptions },
  layerOptionsUpdated: false,
  violationDisplayMin: 0,
  allBatterySizingData: {},
  operationalData: {},
};

// ------------------------------------
// Actions
// ------------------------------------
function runPowerFlow(
  workspace,
  branch,
  feeders,
  scenario,
  analysisName,
  simulationOptions,
  controlModes,
  permissions,
  logEntries,
) {
  return async dispatch => {
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/powerflow/schedule`);
    dispatch({
      type: POWERFLOW_LOADING,
    });
    Analytics.logEvent('Ran Powerflow', 'Analysis');
    try {
      const { data } = await request.post(
        {
          'simulation-options': simulationOptions,
          'control-modes': PFHelpers.controlModesMapping(controlModes),
        },
        {
          params: {
            feeder: feeders.map(fdr => fdr.id),
            scenario_id: scenario,
            analysis_name: analysisName,
          },
        },
      );
      dispatch(loadForecastActions.setSelectedAnalysis(data.analysis_id, permissions));
      const jobQueued = PFHelpers.checkQueue(data.job_id, logEntries);
      dispatch({
        type: POWERFLOW_SUCCESS,
        jobQueued,
      });
    } catch (error) {
      if (error?.response?.status === 409) {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: CONFLICTING_ANALYSIS_ERROR });
        dispatch(
          displayAlertMessage(
            'Create Analysis Failure',
            'This analysis name has already been used',
          ),
        );
      } else {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: undefined });
      }
    }
  };
}

function runQSTSPowerFlow(
  workspace,
  branch,
  feeders,
  scenario,
  analysisName,
  simulationOptions,
  controlModes,
  range,
  objective,
  permissions,
  logEntries,
) {
  return async dispatch => {
    const url = `/api/workspace/${workspace}/branch/${branch}/powerflow/qsts/schedule`;
    const request = new Request(url);

    dispatch({
      type: POWERFLOW_LOADING,
    });
    Analytics.logEvent(
      objective === 'pf' ? 'Ran QSTS Powerflow' : 'Ran Optimal Powerflow',
      'Analysis',
      objective,
    );
    try {
      const { data } = await request.post(
        {
          'simulation-options': simulationOptions,
          'control-modes': PFHelpers.controlModesMapping(controlModes, range),
        },
        {
          params: {
            feeder: feeders.map(fdr => fdr.id),
            start_date: moment(range.start).utc().toISOString(),
            end_date: moment(range.end).utc().toISOString(),
            scenario_id: scenario,
            analysis_name: analysisName,
            objective,
          },
        },
      );
      dispatch(loadForecastActions.setSelectedAnalysis(data.analysis_id, permissions));
      const jobQueued = PFHelpers.checkQueue(data.job_id, logEntries);
      dispatch({
        type: POWERFLOW_SUCCESS,
        jobQueued,
      });
    } catch (error) {
      if (error?.response?.status === 409) {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: CONFLICTING_ANALYSIS_ERROR });
        dispatch(
          displayAlertMessage(
            'Create Analysis Failure',
            'This analysis name has already been used',
          ),
        );
      } else {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: undefined });
      }
    }
  };
}

function runHostingCapacityAnalysis(
  workspace,
  branch,
  feeders,
  scenario,
  analysisName,
  simulationOptions,
  controlModes,
  phases,
  nodes,
  timerange,
  hc_type,
  permissions,
  logEntries,
) {
  return async dispatch => {
    const analysisType = nodes.length === 1 ? 'hosting-capacity' : 'network-hosting-capacity';
    const phase = nodes.length === 1 ? phases : 'ABC';
    const params = {
      start_date: moment.utc(timerange.start).toISOString(),
      end_date: moment.utc(timerange.end).toISOString(),
      feeder: feeders.map(fdr => fdr.id),
      scenario_id: scenario,
      analysis_name: analysisName,
      phases: phase.toLowerCase(),
      hc_type,
      ...(nodes.length === 1 ? { node: nodes[0] } : {}),
    };
    const url = `/api/workspace/${workspace}/branch/${branch}/${analysisType}/schedule`;
    const request = new Request(url);

    dispatch({
      type: POWERFLOW_LOADING,
    });

    Analytics.logEvent(
      hc_type === 'load' ? 'Ran EV Capacity' : 'Ran Hosting Capacity',
      'Analysis',
      hc_type === 'load' ? analysisType.replace('hosting', 'ev') : analysisType,
    );
    try {
      const { data } = await request.post(
        {
          'simulation-options': simulationOptions,
          'control-modes': PFHelpers.controlModesMapping(controlModes, timerange),
        },
        { params },
      );
      dispatch(loadForecastActions.setSelectedAnalysis(data.analysis_id, permissions));
      const jobQueued = PFHelpers.checkQueue(data.job_id, logEntries);
      dispatch({
        type: POWERFLOW_SUCCESS,
        jobQueued,
      });
    } catch (error) {
      if (error?.response?.status === 409) {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: CONFLICTING_ANALYSIS_ERROR });
        dispatch(
          displayAlertMessage(
            'Create Analysis Failure',
            'This analysis name has already been used',
          ),
        );
      } else {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: undefined });
      }
    }
  };
}

function runOperationalEnvelopeAnalysis(
  workspace,
  branch,
  feeders,
  scenario,
  analysisName,
  simulationOptions,
  controlModes,
  drAssets,
  timerange,
  permissions,
  logEntries,
) {
  return async dispatch => {
    const params = {
      start_date: moment.utc(timerange.start).toISOString(),
      end_date: moment.utc(timerange.end).toISOString(),
      feeder: feeders.map(fdr => fdr.id),
      scenario_id: scenario,
      analysis_name: analysisName,
      asset_id: drAssets,
    };
    const url = `/api/workspace/${workspace}/branch/${branch}/operational-envelope/schedule`;
    const request = new Request(url);

    dispatch({
      type: POWERFLOW_LOADING,
    });

    Analytics.logEvent('Ran Operational Envelope Analysis', 'Analysis');
    try {
      const { data } = await request.post(
        {
          'simulation-options': simulationOptions,
          'control-modes': PFHelpers.controlModesMapping(controlModes, timerange),
        },
        { params },
      );
      dispatch(loadForecastActions.setSelectedAnalysis(data.analysis_id, permissions));
      const jobQueued = PFHelpers.checkQueue(data.job_id, logEntries);
      dispatch({
        type: POWERFLOW_SUCCESS,
        jobQueued,
      });
    } catch (error) {
      if (error?.response?.status === 409) {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: CONFLICTING_ANALYSIS_ERROR });
        dispatch(
          displayAlertMessage(
            'Create Analysis Failure',
            'This analysis name has already been used',
          ),
        );
      } else {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: undefined });
      }
    }
  };
}

function runNodalAnalysis(
  workspace,
  branch,
  feeders,
  scenario,
  analysisName,
  simulationOptions,
  controlModes,
  range,
  selectedNodes,
  permissions,
  logEntries,
) {
  return async dispatch => {
    const url = `/api/workspace/${workspace}/branch/${branch}/nodal-analysis/schedule`;
    const params = {
      start_date: moment.utc(range.start).toISOString(),
      end_date: moment.utc(range.end).toISOString(),
      containers: feeders.map(fdr => fdr.id),
      scenario_id: scenario,
      analysis_name: analysisName,
      analysis_subtype: 'battery',
    };
    const body = {
      'simulation-options': simulationOptions,
      'control-modes': PFHelpers.controlModesMapping(controlModes, range),
      nodes: selectedNodes.map(node => node.id),
    };
    const request = new Request(url);

    dispatch({
      type: POWERFLOW_LOADING,
    });

    Analytics.logEvent('Ran Optimal Battery', 'Analysis');

    try {
      const { data } = await request.post(body, { params });
      dispatch(loadForecastActions.setSelectedAnalysis(data.analysis_id, permissions));
      const jobQueued = PFHelpers.checkQueue(data.job_id, logEntries);
      dispatch({
        type: POWERFLOW_SUCCESS,
        jobQueued,
      });
    } catch (error) {
      if (error?.response?.status === 409) {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: CONFLICTING_ANALYSIS_ERROR });
        dispatch(
          displayAlertMessage(
            'Create Analysis Failure',
            'This analysis name has already been used',
          ),
        );
      } else {
        dispatch({ type: POWERFLOW_ERROR, errorMessage: undefined });
      }
    }
  };
}

export function getBatterySizingResults(workspace, branch, feeder_id) {
  return async (dispatch, getState) => {
    try {
      const { selectedScenario, selectedAnalysis } = getState().loadForecast;
      const data = await getAllBatterySizingResults(
        workspace,
        branch,
        feeder_id,
        selectedScenario,
        selectedAnalysis.name,
      );
      const nodeData = {};
      // min always 0, max > 0
      let largestP = 0;
      let largestQ = 0;
      let largestE = 0;
      if (Object.keys(data).length > 0) {
        // Get all of the values for all phases and determine the min/max values
        Object.entries(data).forEach(([node, phaseValues]) => {
          // throw away phase information and only get the populated value
          const nodeValue = Object.values(phaseValues).filter(x => !!x)[0];
          if (nodeValue.max_p > largestP) {
            largestP = nodeValue.max_p;
          }
          if (nodeValue.max_q > largestQ) {
            largestQ = nodeValue.max_q;
          }
          if (nodeValue.max_energy > largestE) {
            largestE = nodeValue.max_energy;
          }
          nodeData[node] = nodeValue;
        });
      }
      return dispatch({
        type: BATTERY_SIZING_RESULTS_SUCCESS,
        nodes: nodeData,
        pRange: [largestP, 0],
        pRangeBreaks: getRangeBreaks(0, largestP),
        qRange: [largestQ, 0],
        qRangeBreaks: getRangeBreaks(0, largestQ),
        eRange: [largestE, 0],
        eRangeBreaks: getRangeBreaks(0, largestE),
      });
    } catch (err) {
      return dispatch({
        type: BATTERY_SIZING_RESULTS_ERROR,
        nodes: {},
      });
    }
  };
}

export function getOperationalEnvpResults(workspace, branch, feeder_id, timepoint) {
  return async (dispatch, getState) => {
    try {
      if (!timepoint) throw Error('No Timepoint Provided');
      const { selectedScenario, selectedAnalysis } = getState().loadForecast;
      const url = `/api/workspace/${workspace}/branch/${branch}/operational-envelope-results/time/${timepoint}`;
      const request = new Request(url);
      const { data: derAssetResult } = await request.get({
        params: {
          feeder: feeder_id,
          scenario_id: selectedScenario,
          analysis_name: selectedAnalysis.name,
        },
      });
      return dispatch({
        type: OPERATIONAL_ENVP_RESULTS_SUCCESS,
        derAssets: derAssetResult,
      });
    } catch (err) {
      return dispatch({
        type: OPERATIONAL_ENVP_RESULTS_ERROR,
      });
    }
  };
}

export function getHostingCapacityResults(workspace, branch, feeder, timepoint) {
  return async (dispatch, getState) => {
    try {
      if (!timepoint) throw Error('No Timepoint Provided');

      const { selectedScenario, selectedAnalysis } = getState().loadForecast;
      const url = `/api/workspace/${workspace}/branch/${branch}/hosting-capacity-results/time/${timepoint}`;
      const request = new Request(url);
      const { data } = await request.get({
        params: {
          feeder,
          scenario_id: selectedScenario,
          analysis_name: selectedAnalysis.name,
          hc_type: 'generation',
        },
      });
      const { range: currentRange } = getState().powerflow.layerOptions.hosting_capacity;
      const { range: defaultRange } = initialState.layerOptions.hosting_capacity;
      if (currentRange[0] === defaultRange[0]) {
        let nodes = {};
        let min = 0;
        let max = 10000000;
        if (Object.keys(data).length > 0) {
          nodes = data;
          // Get all of the values for all phases and determine the min/max values
          const allValues = Object.values(data).reduce((list, phases) => {
            const values = Object.values(phases).filter(val => val !== null);
            return [...list, ...values];
          }, []);
          max = Math.max(...allValues);
          if (min === max) {
            min = 1;
          }
        }
        return dispatch({
          type: HOSTING_CAPACITY_RESULTS_SUCCESS,
          nodes,
          range: [max, min],
          rangeBreaks: getRangeBreaks(min, max),
        });
      }
      return dispatch({
        type: HOSTING_CAPACITY_RESULTS_SUCCESS,
        nodes: data,
      });
    } catch (err) {
      return dispatch({
        type: HOSTING_CAPACITY_RESULTS_ERROR,
        nodes: {},
      });
    }
  };
}

export function getEVCapacityResults(workspace, branch, feeder, timepoint) {
  return async (dispatch, getState) => {
    try {
      if (!timepoint) throw Error('No Timepoint Provided');

      const { selectedScenario, selectedAnalysis } = getState().loadForecast;
      const url = `/api/workspace/${workspace}/branch/${branch}/hosting-capacity-results/time/${timepoint}`;
      const request = new Request(url);
      const { data } = await request.get({
        params: {
          feeder,
          scenario_id: selectedScenario,
          analysis_name: selectedAnalysis.name,
          hc_type: 'load',
        },
      });
      const { range: currentRange } = getState().powerflow.layerOptions.ev_capacity;
      const { range: defaultRange } = initialState.layerOptions.ev_capacity;
      if (currentRange[0] === defaultRange[0]) {
        let nodes = {};
        let min = 0;
        let max = 10000000;
        if (Object.keys(data).length > 0) {
          nodes = data;
          // Get all of the values for all phases and determine the min/max values
          const allValues = Object.values(data).reduce((list, phases) => {
            const values = Object.values(phases).filter(val => val !== null);
            return [...list, ...values];
          }, []);
          max = Math.max(...allValues);
          if (min === max) {
            min = 1;
          }
        }
        return dispatch({
          type: EV_CAPACITY_RESULTS_SUCCESS,
          nodes,
          range: [max, min],
          rangeBreaks: getRangeBreaks(min, max),
        });
      }
      return dispatch({
        type: EV_CAPACITY_RESULTS_SUCCESS,
        nodes: data,
      });
    } catch (err) {
      return dispatch({
        type: EV_CAPACITY_RESULTS_ERROR,
        nodes: {},
      });
    }
  };
}

const getLayerOptions = () => (dispatch, getState) => {
  const { workspace, branch } = getState().network;
  const { selectedScenario, selectedAnalysis } = getState().loadForecast;
  const storedOptions = localStorage.getItem(`${workspace}_${branch}`);
  const scenarioLayer = `${branch}_${selectedScenario}_${selectedAnalysis?.id}`;

  if (storedOptions && JSON.parse(storedOptions) && JSON.parse(storedOptions)[scenarioLayer]) {
    const optionsObj = JSON.parse(storedOptions)[scenarioLayer];
    // add in any new layers which have been added
    Object.keys(defaultLayerOptions).forEach(key => {
      if (!optionsObj[key]) {
        optionsObj[key] = defaultLayerOptions[key];
      }
    });
    dispatch({ type: GET_LAYER_OPTIONS, optionsObj });
  }
};

const hasLayerOptions = () => (dispatch, getState) => {
  const { workspace, branch } = getState().network;
  const { selectedScenario, selectedAnalysis } = getState().loadForecast;
  const storedOptions = localStorage.getItem(`${workspace}_${branch}`);
  const scenarioLayer = `${branch}_${selectedScenario}_${selectedAnalysis.id}`;

  if (storedOptions && JSON.parse(storedOptions) && JSON.parse(storedOptions)[scenarioLayer]) {
    const optionsObj = JSON.parse(storedOptions)[scenarioLayer];
    return !!optionsObj;
  }

  return false;
};

function updateLayerOptions(layer, updates) {
  return {
    type: UPDATE_LAYER_OPTIONS,
    layer,
    updates,
  };
}

function updateLayerAggregation(aggType) {
  return {
    type: UPDATE_LAYER_AGGREGATION,
    aggType,
  };
}

function dismissPowerflowMessage() {
  return { type: POWERFLOW_INITIAL };
}

function resetJobQueued() {
  return {
    type: RESET_JOB_QUEUED,
  };
}

const clearViolations = () => ({ type: VIOLATIONS_CLEARED });

function getGISViolations(workspace, branch, feeder, start, end, scenario, analysisName) {
  return async (dispatch, getState) => {
    const params = {
      feeder,
      start_date: moment(start).utc().toISOString(),
      end_date: moment(end).utc().toISOString(),
      scenario_id: scenario,
      analysis_name: analysisName,
    };
    let unSelected = 0;
    PFHelpers.violationKeys.forEach(v => {
      if (getState().powerflow.violationsSelected[v] === false) {
        params[PFHelpers.violationCategory[v].param] = false;
        unSelected += 1;
      }
    });
    const url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/violations/count`;
    const request = new Request(url);
    if (unSelected === PFHelpers.violationKeys.length) {
      return dispatch({
        type: GET_GIS_VIOLATIONS,
        violations: {},
      });
    }
    try {
      const { data } = await request.get({ params });
      return dispatch({
        type: GET_GIS_VIOLATIONS,
        violations: data,
      });
    } catch (err) {
      return dispatch({
        type: GET_GIS_VIOLATIONS,
        violations: {},
      });
    }
  };
}

const clearTimebarViolations = () => ({ type: TIMEBAR_VIOLATIONS_CLEARED });

function getTimebarViolations(
  workspace,
  branch,
  feeder,
  timeRange,
  scenario_id,
  analysisName,
  aggregation,
) {
  return async (dispatch, getState) => {
    dispatch(clearTimebarViolations());
    const { start, end } = timeRange;
    aggregation = aggregation || 'day';
    let unSelected = 0;
    const params = {
      feeder,
      start_date: moment(start).utc().toISOString(),
      end_date: moment(end).utc().toISOString(),
      scenario_id,
      analysis_name: analysisName,
      aggregation,
    };
    PFHelpers.violationKeys.forEach(v => {
      if (getState().powerflow.violationsSelected[v] === false) {
        params[PFHelpers.violationCategory[v].param] = false;
        unSelected += 1;
      }
    });
    const url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/violations/per-time`;
    const request = new Request(url);
    dispatch({
      type: TIMEBAR_VIOLATIONS_LOADING,
    });
    if (unSelected === PFHelpers.violationKeys.length) {
      return dispatch({
        type: TIMEBAR_VIOLATIONS_SUCCESS,
        timebarViolations: [],
      });
    }
    try {
      const res = await request.get({ params });
      const { data } = res;
      return dispatch({
        type: TIMEBAR_VIOLATIONS_SUCCESS,
        timebarViolations: data,
      });
    } catch (error) {
      dispatch(clearTimebarViolations());
      return dispatch({
        type: TIMEBAR_VIOLATIONS_ERROR,
      });
    }
  };
}

function updateSelectedViolations(e) {
  return (dispatch, getState) => {
    const newViolationsSelected = {
      ...getState().powerflow.violationsSelected,
      [e.target.value]: !getState().powerflow.violationsSelected[e.target.value],
    };
    dispatch({
      type: UPDATE_SELECTED_VIOLATIONS,
      newViolationsSelected,
    });
    const { workspace, branch, maxRange, timeRange, timeBarZoomLevel } = getState().network;
    const { selected } = getState().feeders;
    const { selectedScenario, selectedAnalysis } = getState().loadForecast;
    dispatch(
      getTimebarViolations(
        workspace,
        branch,
        selected[0].id,
        maxRange,
        selectedScenario,
        selectedAnalysis.name,
        timeBarZoomLevel,
      ),
    );
    dispatch(
      getGISViolations(
        workspace,
        branch,
        selected[0].id,
        timeRange.start,
        timeRange.end,
        selectedScenario,
        selectedAnalysis.name,
      ),
    );
  };
}

function updateSelectedViolationsAllNone(all) {
  return (dispatch, getState) => {
    const newViolationsSelected = Object.keys(PFHelpers.violationCategory).reduce(
      (violations, v) => {
        violations[v] = all;

        return violations;
      },
      {},
    );

    dispatch({
      type: UPDATE_SELECTED_VIOLATIONS,
      newViolationsSelected,
    });
    const { workspace, branch, maxRange, timeRange, timeBarZoomLevel } = getState().network;
    const { selected } = getState().feeders;
    const { selectedScenario, selectedAnalysis } = getState().loadForecast;
    dispatch(
      getTimebarViolations(
        workspace,
        branch,
        selected[0].id,
        maxRange,
        selectedScenario,
        selectedAnalysis.name,
        timeBarZoomLevel,
      ),
    );
    dispatch(
      getGISViolations(
        workspace,
        branch,
        selected[0].id,
        timeRange.start,
        timeRange.end,
        selectedScenario,
        selectedAnalysis.name,
      ),
    );
  };
}

function toggleDirectionalFlow(layer) {
  return (dispatch, getState) => {
    const currentState = getState().powerflow.layerOptions[layer].directionalFlow;
    dispatch({
      type: TOGGLE_DIRECTIONAL_FLOW,
      layer,
      status: !currentState,
    });
  };
}

const updateViolationDisplayMin = val => (dispatch, getState) => {
  const violationDisplayMin =
    Number.isInteger(val) && val >= 0
      ? parseInt(val, 10)
      : getState().powerflow.violationDisplayMin;
  dispatch({
    type: UPDATE_VIOLATION_DISPLAY_MIN,
    violationDisplayMin,
  });
};

export const powerflowActions = {
  runPowerFlow,
  runQSTSPowerFlow,
  dismissPowerflowMessage,
  runHostingCapacityAnalysis,
  runNodalAnalysis,
  getHostingCapacityResults,
  getEVCapacityResults,
  getBatterySizingResults,
  getOperationalEnvpResults,
  getLayerOptions,
  hasLayerOptions,
  updateLayerOptions,
  updateLayerAggregation,
  resetJobQueued,
  clearViolations,
  clearTimebarViolations,
  getTimebarViolations,
  getGISViolations,
  updateSelectedViolations,
  updateSelectedViolationsAllNone,
  toggleDirectionalFlow,
  updateViolationDisplayMin,
  runOperationalEnvelopeAnalysis,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function PowerflowReducer(state = initialState, action) {
  switch (action.type) {
    case POWERFLOW_LOADING:
      return {
        ...state,
        powerFlowErrorMessage: '',
      };
    case POWERFLOW_SUCCESS:
      return {
        ...state,
        jobQueued: action.jobQueued,
      };
    case POWERFLOW_ERROR:
      return {
        ...state,
        powerFlowErrorMessage: action.errorMessage || '',
      };
    case CLEAR_SELECTED_RANGE_DATA:
      return {
        ...state,
        GISViolations: initialState.GISViolations,
      };
    case CLEAR_POWERFLOW_RESULTS:
      return {
        ...initialState,
        layerOptions: {
          ...state.layerOptions,
        },
      };
    case CLEAR_NETWORK_DATA:
      return {
        ...initialState,
      };
    case HOSTING_CAPACITY_RESULTS_SUCCESS: {
      const { range, rangeBreaks } = state.layerOptions.hosting_capacity;
      return {
        ...state,
        hostingCapacityTimepointData: action.nodes,
        layerOptions: {
          ...state.layerOptions,
          hosting_capacity: {
            ...state.layerOptions.hosting_capacity,
            range: action.range || range,
            rangeBreaks: action.rangeBreaks || rangeBreaks,
          },
        },
      };
    }
    case HOSTING_CAPACITY_RESULTS_ERROR:
      return {
        ...state,
        hostingCapacityTimepointData: {},
      };
    case EV_CAPACITY_RESULTS_SUCCESS: {
      const { range, rangeBreaks } = state.layerOptions.ev_capacity;
      return {
        ...state,
        evCapacityTimepointData: action.nodes,
        layerOptions: {
          ...state.layerOptions,
          ev_capacity: {
            ...state.layerOptions.ev_capacity,
            range: action.range || range,
            rangeBreaks: action.rangeBreaks || rangeBreaks,
          },
        },
      };
    }
    case EV_CAPACITY_RESULTS_ERROR:
      return {
        ...state,
        evCapacityTimepointData: {},
      };
    case BATTERY_SIZING_RESULTS_SUCCESS: {
      return {
        ...state,
        batterySizingData: action.nodes,
        layerOptions: {
          ...state.layerOptions,
          battery_sizing_energy: {
            ...state.layerOptions.battery_sizing_energy,
            ...(action.eRange ? { range: action.eRange } : {}),
            ...(action.eRangeBreaks ? { rangeBreaks: action.eRangeBreaks } : {}),
          },
          battery_sizing_real_power: {
            ...state.layerOptions.battery_sizing_real_power,
            ...(action.pRange ? { range: action.pRange } : {}),
            ...(action.pRangeBreaks ? { rangeBreaks: action.pRangeBreaks } : {}),
          },
          battery_sizing_reactive_power: {
            ...state.layerOptions.battery_sizing_reactive_power,
            ...(action.qRange ? { range: action.qRange } : {}),
            ...(action.qRangeBreaks ? { rangeBreaks: action.qRangeBreaks } : {}),
          },
        },
      };
    }
    case OPERATIONAL_ENVP_RESULTS_SUCCESS: {
      return {
        ...state,
        operationalData: action.derAssets,
      };
    }
    case OPERATIONAL_ENVP_RESULTS_ERROR: {
      return {
        ...state,
        operationalData: {},
      };
    }
    case BATTERY_SIZING_RESULTS_ERROR:
      return {
        ...state,
        batterySizingData: {},
        allBatterySizingData: {},
      };
    case QSTS_VALUES_SUCCESS:
    case QSTS_VALUES_ERROR:
      // Update the layer range with first set of SVs loaded
      if (action.pqValues && !action.hasSavedLayers) {
        let minP = Math.min(...action.pqValues.p) || 1;
        const maxP = Math.max(...action.pqValues.p) || 1;
        let minQ = Math.min(...action.pqValues.q) || 1;
        const maxQ = Math.max(...action.pqValues.q) || 1;

        if (minP === maxP) {
          minP = maxP - 1;
        }
        if (minQ === maxQ) {
          minQ = maxQ - 1;
        }

        const minPLoss = minP * 0.05;
        const maxPLoss = maxP * 0.05;

        const options = updateLayerRanges(
          { ...initialState.layerOptions },
          action.ratedCurrentRange,
          action.baseVoltages,
          action.ratedSRange,
        );

        return {
          ...state,
          layerOptions: {
            ...options,
            real_power: {
              ...state.layerOptions.real_power,
              range: [maxP, minP],
              rangeBreaks: getRangeBreaks(minP, maxP),
            },
            reactive_power: {
              ...state.layerOptions.reactive_power,
              range: [maxQ, minQ],
              rangeBreaks: getRangeBreaks(minQ, maxQ),
            },
            real_power_losses: {
              ...state.layerOptions.real_power_losses,
              range: [maxPLoss, minPLoss],
              rangeBreaks: getRangeBreaks(minPLoss, maxPLoss),
            },
          },
        };
      }
      return { ...state };
    case NETWORK_DATA_SUCCESS:
      return { ...state };
    case GET_LAYER_OPTIONS:
      return {
        ...state,
        layerOptions: action.optionsObj,
        layerOptionsUpdated: false,
      };
    case UPDATE_LAYER_OPTIONS:
      return {
        ...state,
        layerOptions: {
          ...state.layerOptions,
          [action.layer]: {
            ...state.layerOptions[action.layer],
            ...action.updates,
          },
        },
        layerOptionsUpdated: true,
      };
    case UPDATE_LAYER_AGGREGATION:
      return {
        ...state,
        layerOptions: {
          ...state.layerOptions,
          aggregation: action.aggType,
        },
        layerOptionsUpdated: true,
      };
    case USE_STORED_OPTIONS:
      return {
        ...state,
        useStored: action.useStored,
      };
    case UPDATE_SELECTED_SCENARIO_LOADING:
    case UPDATE_SELECTED_BRANCH:
      return {
        ...state,
        hostingCapacityResults: {},
        hostingCapacityTimepointData: {},
        evCapacityTimepointData: {},
      };
    case RESET_JOB_QUEUED:
      return {
        ...state,
        jobQueued: false,
      };
    case VIOLATIONS_CLEARED:
      return {
        ...state,
        GISViolations: {},
      };
    case TIMEBAR_VIOLATIONS_LOADING:
      return state;
    case TIMEBAR_VIOLATIONS_SUCCESS:
      return {
        ...state,
        timebarViolations: action.timebarViolations,
      };
    case TIMEBAR_VIOLATIONS_ERROR:
      return state;
    case TIMEBAR_VIOLATIONS_CLEARED:
      return {
        ...state,
        timebarViolations: [],
      };
    case GET_GIS_VIOLATIONS:
      return {
        ...state,
        GISViolations: action.violations,
      };
    case UPDATE_SELECTED_VIOLATIONS:
      return {
        ...state,
        violationsSelected: action.newViolationsSelected,
      };
    case TOGGLE_DIRECTIONAL_FLOW:
      return {
        ...state,
        layerOptions: {
          ...state.layerOptions,
          [action.layer]: {
            ...state.layerOptions[action.layer],
            directionalFlow: action.status,
          },
        },
      };
    case UPDATE_VIOLATION_DISPLAY_MIN:
      return {
        ...state,
        violationDisplayMin: action.violationDisplayMin,
      };
    default:
      return state;
  }
}
