import { combineReducers } from 'redux';

import importCIMReducer from 'routes/ImportCIM/modules/importCIM';
import networkReducer from 'routes/WorkspaceLayout/routes/Network/modules/network';
import editReducer from 'routes/WorkspaceLayout/routes/Network/modules/networkEdit';
import feederReducer from 'routes/WorkspaceLayout/routes/Network/modules/feeders';
import loadForecastReducer from 'routes/WorkspaceLayout/routes/Network/modules/loadForecast';
import validationReducer from 'routes/WorkspaceLayout/routes/Network/modules/validation';
import powerflowReducer from 'routes/WorkspaceLayout/routes/Network/modules/powerflow';

import equipmentLibraryReducer from 'routes/WorkspaceLayout/routes/EquipmentLibrary/modules/equipmentLibrary';

import appReducer from './appState';
import loadingStatesReducer from './loadingStates';

export const makeRootReducer = () => {
  const combine = combineReducers({
    global: appReducer,
    requests: loadingStatesReducer,

    edit: editReducer,
    equipmentLibrary: equipmentLibraryReducer,
    feeders: feederReducer,
    importCIM: importCIMReducer,
    loadForecast: loadForecastReducer,
    network: networkReducer,
    powerflow: powerflowReducer,
    validation: validationReducer,
  });
  return combine;
};

export default makeRootReducer;
