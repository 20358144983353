import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types/index';

import './UploadButton.scss';

type UploadButtonProps = {
  buttonText?: string;
  disabled?: boolean;
  loading?: boolean;
  accept: string;
  theme: ThemeProp;
  onChange: (event?: React.ChangeEvent<HTMLInputElement> | null) => void | null;
};

const UploadButton: FunctionComponent<UploadButtonProps> = ({
  buttonText = 'Upload File',
  disabled = false,
  loading = false,
  accept,
  onChange,
}) => (
  <div className={`file-upload-btn ${disabled || loading ? 'disabled' : ''}`}>
    {loading ? (
      <i className="material-icons rotate">refresh</i>
    ) : (
      <i className="material-icons">cloud_upload</i>
    )}

    {buttonText}
    <input
      type="file"
      name="file-upload"
      accept={accept}
      disabled={disabled || loading}
      onChange={e => {
        onChange(e);
        e.currentTarget.value = '';
      }}
    />
  </div>
);

export default UploadButton;
