import ConductingEquipment from '../ConductingEquipment';

class LinkDevice extends ConductingEquipment {
  get coordinates() {
    return this._terminalPositions;
  }

  get _topologicalNode() {
    const terminal = this.cimDict[this.terminals[0]];
    return terminal ? terminal.references['Terminal.TopologicalNode'] : undefined;
  }

  /* Map Render Helpers */

  get _terminalPositions() {
    try {
      if (this._positionPoints && this._positionPoints.length) {
        return this._positionPoints.map(position => this.cimDict[position].attributes);
      }
      return [];
    } catch (err) {
      return [];
    }
  }
}

export default LinkDevice;
