import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'components/NumberInput';
import { ohms, percent } from 'helpers/units';
import Helpers from '../../../helpers/EquipmentLibraryHelpers';

import './ImpedancePanel.scss';

const calcPercentImpedance = endInfo => {
  const { ratedU, ratedS, R, X } = endInfo;
  // Multiple by 1000 to go from kV and kVA to V and VA
  const zBase = (ratedU * 1000) ** 2 / (ratedS * 1000);
  const percentR = R / zBase;
  const percentX = X / zBase;
  const percentZ = Math.sqrt(R ** 2 + X ** 2) / zBase;

  return {
    R: percentR * 100,
    X: percentX * 100,
    Z: percentZ * 100,
  };
};

const ImpedancePanel = ({ disabled, endInfo, onImpedanceChange }) => {
  const percentImpedance = calcPercentImpedance(endInfo);
  const [percentR, setPercentR] = useState(percentImpedance.R);
  const [percentX, setPercentX] = useState(percentImpedance.X);
  const [percentZ, setPercentZ] = useState(percentImpedance.Z);
  useEffect(() => {
    const newImpedance = calcPercentImpedance(endInfo);
    setPercentR(newImpedance.R);
    setPercentX(newImpedance.X);
    setPercentZ(newImpedance.Z);
  }, [endInfo]);
  const R = Helpers.createDisplayObject('R', 'R', endInfo.R, ohms);
  const R0 = Helpers.createDisplayObject('R0', 'R0', endInfo.R0, ohms);
  const X = Helpers.createDisplayObject('X', 'X', endInfo.X, ohms);
  const X0 = Helpers.createDisplayObject('X0', 'X0', endInfo.X0, ohms);

  const defaultProps = {
    required: true,
    disabled,
  };

  const changePercentR = ({ value }) => {
    const { ratedS, ratedU } = endInfo;
    if (!value || value === '') {
      setPercentR(null);
    } else {
      const zBase = (ratedU * 1000) ** 2 / (ratedS * 1000);
      const val = zBase * (value / 100);
      onImpedanceChange({ R: val, R0: val });
    }
  };
  const changePercentX = ({ value }) => {
    if (!value || value === '') {
      setPercentX(null);
    } else {
      const { ratedS, ratedU } = endInfo;
      const zBase = (ratedU * 1000) ** 2 / (ratedS * 1000);
      const val = zBase * (value / 100);
      onImpedanceChange({ X: val, X0: val });
    }
  };

  return (
    <div className="impedance-panel">
      <div className="impedance-column impedance-column-left">
        <h2>Star Impedance</h2>
        <NumberInput
          {...defaultProps}
          {...R}
          onChange={({ value }) => onImpedanceChange({ R: value.toString() })}
          ge={0}
          required
          inputStyle="eq-lib"
        />
        <NumberInput
          {...defaultProps}
          {...R0}
          onChange={({ value }) => onImpedanceChange({ R0: value.toString() })}
          ge={0}
          required
          inputStyle="eq-lib"
        />
        <NumberInput
          {...defaultProps}
          {...X}
          onChange={({ value }) => onImpedanceChange({ X: value.toString() })}
          ge={0}
          required
          inputStyle="eq-lib"
        />
        <NumberInput
          {...defaultProps}
          {...X0}
          onChange={({ value }) => onImpedanceChange({ X0: value.toString() })}
          ge={0}
          required
          inputStyle="eq-lib"
        />
      </div>
      <div className="impedance-column impedance-column-right">
        <h2>Percent Impedance</h2>
        <NumberInput
          {...defaultProps}
          {...Helpers.createDisplayObject('%R', 'percent-R', percentR, percent)}
          onChange={changePercentR}
          ge={0}
          le={100}
          required
          inputStyle="eq-lib"
          disabled={disabled || endInfo.ratedS === '' || endInfo.ratedU === ''}
        />
        <NumberInput
          {...defaultProps}
          {...Helpers.createDisplayObject('%X', 'percent-X', percentX, percent)}
          onChange={changePercentX}
          ge={0}
          le={100}
          required
          inputStyle="eq-lib"
          disabled={disabled || endInfo.ratedS === '' || endInfo.ratedU === ''}
        />
        <NumberInput
          {...defaultProps}
          {...Helpers.createDisplayObject('%Z', 'percent-Z', percentZ, percent)}
          disabled
          ge={0}
          le={100}
          required
          inputStyle="eq-lib"
        />
      </div>
    </div>
  );
};

ImpedancePanel.defaultProps = {
  disabled: false,
};

ImpedancePanel.propTypes = {
  disabled: PropTypes.bool,
  endInfo: PropTypes.object.isRequired,
  onImpedanceChange: PropTypes.func.isRequired,
};

export default ImpedancePanel;
