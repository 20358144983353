import Agreement from './Agreement';

class CustomerAgreement extends Agreement {
  get name() {
    return this.attributes['IdentifiedObject.name'];
  }

  get auxiliaryAgreement() {
    try {
      return this.cimDict[this.references['CustomerAgreement.AuxiliaryAgreements']];
    } catch (err) {
      return [];
    }
  }
}

export default CustomerAgreement;
