import React, { FunctionComponent, useContext } from 'react';
import ThemeContext from 'helpers/ThemeContext';
import { IconPropsSchema } from '../../types/index';
import { getIconColor } from '../helpers/formatters';

const CloseIcon: FunctionComponent<IconPropsSchema> = ({
  color,
  width = '10px',
  height = '10px',
}) => {
  const theme = useContext(ThemeContext);
  return (
    <svg width={width} height={height} viewBox="0 0 10 10" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="01_IDP_RC-Copy-16" transform="translate(-448.000000, -145.000000)">
          <rect x="0" y="0" width="1366" height="800" />
          <rect id="Mask" stroke="#F6F6F7" x="0.5" y="101.5" width="511" height="709" rx="4" />
          <rect
            id="Rectangle-Copy-48"
            stroke="#EDF0F5"
            x="180.5"
            y="130.5"
            width="320"
            height="100"
            rx="5"
          />
          <g
            id="cancel"
            transform="translate(448.000000, 145.000000)"
            fill={getIconColor(theme, color)}
            fillRule="nonzero"
          >
            <g id="Path">
              <path d="M5.55244955,5.00002197 L9.88558139,0.666860026 C10.0381395,0.514301507 10.0381395,0.266957877 9.88558139,0.114418889 C9.73302324,-0.0381200986 9.48568022,-0.0381396298 9.3331416,0.114418889 L5,4.44758084 L0.666858398,0.114418889 C0.514300251,-0.0381396298 0.266957226,-0.0381396298 0.11441861,0.114418889 C-0.0381200055,0.266977409 -0.0381395367,0.514321038 0.11441861,0.666860026 L4.44755045,5 L0.11441861,9.33316439 C-0.0381395367,9.48572291 -0.0381395367,9.73306654 0.11441861,9.88560552 C0.190687918,9.96187502 0.290668094,10 0.39064827,10 C0.490628445,10 0.59058909,9.96187502 0.666877929,9.88560552 L5,5.55246311 L9.33312207,9.88560552 C9.40939138,9.96187502 9.50937155,10 9.60935173,10 C9.70933191,10 9.80929255,9.96187502 9.88558139,9.88560552 C10.0381395,9.73304701 10.0381395,9.48570338 9.88558139,9.33316439 L5.55244955,5.00002197 Z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
