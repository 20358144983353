import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import SearchInput from 'components/SearchInput';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';

import { useAttachments } from 'contexts/AttachmentsContext';
import FileUploads from 'components/FileUploads';
import HierarchyView from 'components/HierarchyView';

import NewContainerButton from './NewContainerButton';
import ContainerTile from './ContainerTile';
import AttachmentsLayerToggle from '../AttachmentsLayerToggle';

const ContainerTab = ({
  containerTree,
  containerList,
  handleContainerClick,
  openCreateContainerModal,
  feedersPending,
  requestStatus,
  selectedFeeders,
  inEditMode,
  theme,
  canImportNetwork,
  workspace,
  branch,
  permissions,
  filter,
  setFilter,
  fullLeftRail,
  deleteContainer,
  deleteStatus,
}) => {
  let importDisabledMessage = '';

  if (!canImportNetwork) {
    importDisabledMessage = "You don't have permission to access this feature";
  } else if (inEditMode) {
    importDisabledMessage = "You can't import data in edit mode";
  } else if (branch !== 'master') {
    importDisabledMessage = 'Imports can only be done to the as built network version.';
  }

  const [updatedFilter, updateFilter] = useState(filter);
  const debouncedSetFilter = debounce(setFilter, 500);
  const setUpdatedFilter = filterValue => {
    updateFilter(filterValue);
    debouncedSetFilter(filterValue);
  };

  const containerIds = selectedFeeders.map(c => c.id);
  const isActive = id => containerIds.includes(id);

  const { containersWithNotes = [] } = useAttachments();
  const hasNotes = id => containersWithNotes.includes(id);
  return (
    <>
      {fullLeftRail && (
        <>
          <SearchInput
            onChange={({ target: { value } }) => setUpdatedFilter(value)}
            value={updatedFilter}
            placeholder="Search..."
            theme={theme}
          />
          <AttachmentsLayerToggle />
          <div className="feeder-selection">
            <CustomScrollBar alwaysShow={false}>
              {feedersPending && (
                <div className="feeder-list-loading">
                  <i className="material-icons rotate" style={{ fontSize: 70 }}>
                    refresh
                  </i>
                  <p>List Loading</p>
                </div>
              )}
              <HierarchyView
                data={containerTree}
                tileRenderer={container => (
                  <ContainerTile
                    requestStatus={requestStatus}
                    onClick={handleContainerClick}
                    inEditMode={inEditMode}
                    openCreateContainerModal={openCreateContainerModal}
                    key={container.id}
                    containerList={containerList}
                    container={container}
                    isActive={isActive}
                    isLoading={id => requestStatus[id] === asyncActionStates.LOADING}
                    hasFailed={id => requestStatus[id] === asyncActionStates.ERROR}
                    hasNotes={hasNotes}
                    theme={theme}
                    deleteContainer={deleteContainer}
                    deleteStatus={deleteStatus}
                    workspace={workspace}
                    branch={branch}
                  />
                )}
              />

              <NewContainerButton
                id="new-substation"
                type="Substation"
                inEditMode={inEditMode}
                openCreateContainerModal={openCreateContainerModal}
              />
              <NewContainerButton
                id="new-feeder"
                type="Feeder"
                inEditMode={inEditMode}
                openCreateContainerModal={openCreateContainerModal}
              />
              {fullLeftRail && (
                <div className="file-upload-section">
                  <FileUploads
                    workspace={workspace}
                    branch={branch}
                    theme={theme}
                    permissions={permissions}
                  />
                </div>
              )}
            </CustomScrollBar>
            <Link
              className={classNames({
                'import-data': true,
                'import-data--disabled': !canImportNetwork || inEditMode || branch !== 'master',
              })}
              to={`${
                canImportNetwork && branch === 'master' ? `/import-cim/${workspace}/${branch}` : ''
              }`}
              disabled={!canImportNetwork || inEditMode || branch !== 'master'}
            >
              <PermissionDisabledTip
                title="Import Data"
                hide={canImportNetwork && !inEditMode && branch === 'master'}
                theme={theme}
                message={importDisabledMessage}
              >
                <div>
                  <div
                    className={classNames({
                      'import-data-plus': true,
                      'import-data-plus--disabled':
                        !canImportNetwork || inEditMode || branch !== 'master',
                    })}
                  >
                    +
                  </div>
                  <p
                    className={classNames({
                      'import-data-text': true,
                      'import-data-text--disabled':
                        !canImportNetwork || inEditMode || branch !== 'master',
                    })}
                  >
                    Import Data
                  </p>
                </div>
              </PermissionDisabledTip>
            </Link>
          </div>
        </>
      )}
      {!fullLeftRail && <div className="all-feeders">All feeders</div>}
    </>
  );
};

ContainerTab.propTypes = {
  handleContainerClick: PropTypes.func.isRequired,
  openCreateContainerModal: PropTypes.func.isRequired,
  feedersPending: PropTypes.bool.isRequired,
  requestStatus: PropTypes.object.isRequired,
  selectedFeeders: PropTypes.array.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  canImportNetwork: PropTypes.bool.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  containerList: PropTypes.array.isRequired,
  containerTree: PropTypes.array.isRequired,
  setFilter: PropTypes.func.isRequired,
  deleteContainer: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  fullLeftRail: PropTypes.bool.isRequired,
  deleteStatus: PropTypes.number.isRequired,
};

export default ContainerTab;
