import React, { FunctionComponent } from 'react';
import { useInterval } from 'helpers/hooks';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { ThemeProp } from 'types/index';

type HealthCheckStatus = {
  service_status: string;
  db_status?: string;
  queue_status?: string;
  object_store_status?: string;
};

type ServiceStatusTileProps = {
  serviceName: string;
  servicePath: string;
  theme: ThemeProp;
};

const ServiceStatusTile: FunctionComponent<ServiceStatusTileProps> = ({
  serviceName,
  servicePath,
}) => {
  const {
    data: healthCheckStatus,
    loading: statusLoading,
    refetch,
  } = useRequestEffect<HealthCheckStatus>({
    url: `/api/health-check${servicePath}`,
    method: 'get',
    refetchOnChange: [servicePath],
  });

  useInterval(refetch, 5000);

  return (
    <div className="service-status-tile">
      <h2>
        {serviceName} {statusLoading && <i className="material-icons spinner">autorenew</i>}
      </h2>
      <p>HTTP API: {healthCheckStatus?.service_status ?? 'down'}</p>
      {/* only show db and queue status if they are defined in the response,
        otherwise its assumed they aren't used by the specified service  */}
      {healthCheckStatus?.db_status !== undefined && (
        <p>Database Connectivity: {healthCheckStatus?.db_status ?? 'unknown'}</p>
      )}
      {healthCheckStatus?.queue_status !== undefined && (
        <p>Message Queue Connectivity: {healthCheckStatus?.queue_status ?? 'unknown'}</p>
      )}
      {healthCheckStatus?.object_store_status !== undefined && (
        <p>Object/File Store Connectivity: {healthCheckStatus?.object_store_status ?? 'unknown'}</p>
      )}
    </div>
  );
};
export default ServiceStatusTile;
