import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { validate } from '@mapbox/mapbox-gl-style-spec';

import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import asyncActionStates from 'helpers/asyncActionStates';
import CustomCheckbox from 'components/CustomCheckbox';
import HelpTooltip from 'components/HelpTooltip';

const GisSettingsPanel = ({ disabled, displayAlertMessage, theme }) => {
  const { settings, setSettings, settingsModified, saveSettings, loadingState } = useContext(
    WorkspaceSettingsContext,
  );

  const saveGISMapboxConfig = async () => {
    try {
      await saveSettings();
    } catch (er) {
      displayAlertMessage(
        'Failed to save GIS settings',
        'Could not save GIS settings. Please try again.',
        null,
        () => saveGISMapboxConfig(),
      );
    }
  };
  return (
    <div className="workspace-card-tab-panel">
      <div className="gis-tab">
        <div className="flex-vertical-center margin-10">
          <CustomCheckbox
            checked={settings.automaticImport}
            onClick={e => {
              setSettings({ automaticImport: e.target.checked });
            }}
            disabled={disabled || loadingState === asyncActionStates.LOADING}
          />
          <span>Automatic Import Enabled</span>
          <HelpTooltip
            message={
              'Disables most actions which can be performed on the as built network. This is done ' +
              'because we expect that the As Built network could be replaced at any time and any ' +
              'data associated with that network version is likely to become stale or get ' +
              'replaced with no warning'
            }
          />
        </div>
        <span className="gis-title">Mapbox GL Source</span>
        <textarea
          style={{ width: '100%' }}
          autoComplete="off"
          className={`${theme} message`}
          onChange={e => {
            setSettings({ mapboxSource: e.target.value });
            if (validate(e.target.value).length > 0) {
              displayAlertMessage(
                'MapBox Error: Invalid Map source Data',
                'Invalid JSON Blob',
                null,
                null,
              );
            }
          }}
          placeholder="Enter a mapbox gl compatible style source"
          value={settings.mapboxSource}
          disabled={disabled || loadingState === asyncActionStates.LOADING}
          wrap="soft"
        />
        <Button
          label="Apply Changes"
          className="workspace-card-button"
          id="auth-button"
          disabled={
            disabled ||
            loadingState === asyncActionStates.LOADING ||
            (!settingsModified.automaticImport && !settingsModified.mapboxSource) ||
            (settingsModified.mapboxSource && validate(settings.mapboxSource).length > 0)
          }
          onClick={saveGISMapboxConfig}
          theme={theme}
          type="primary"
        />
      </div>
    </div>
  );
};

GisSettingsPanel.defaultProps = {
  disabled: true,
};

GisSettingsPanel.propTypes = {
  disabled: PropTypes.bool,
  displayAlertMessage: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
};

export default GisSettingsPanel;
