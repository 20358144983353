import ThemeContext from 'helpers/ThemeContext';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Card from 'components/Card';
import { kVA, scaleFactorForUnit, kVAr } from 'helpers/units';

import './DroopCurve.scss';

class DroopCurve extends PureComponent {
  generateData() {
    const { powerFactor, apparentPower } = this.props;
    const maxS = apparentPower / scaleFactorForUnit(kVA);
    const Qmax = Math.sin(Math.acos(powerFactor)) * maxS;
    return [
      { v: 0.9, q: -Qmax },
      { v: 1, q: 0 },
      { v: 1.1, q: Qmax },
    ];
  }

  showTooltip({ active, payload }) {
    if (active) {
      const { q, v } = payload[0].payload;
      return (
        <div className="tooltip">
          <p className="label">{`Voltage : ${v} p.u.`}</p>
          <p className="label">{`Q : ${q.toFixed(3)} ${kVAr}`}</p>
        </div>
      );
    }
    return null;
  }

  render() {
    const theme = typeof this.context === 'string' ? this.context : 'dark';
    return (
      <Card hideTitle theme={theme} className="droop-curve-chart">
        <h4>Droop Curve</h4>
        <div
          className={classNames({
            'chart-pane': true,
            'chart-pane--expanded': this.props.expanded,
          })}
        >
          <ResponsiveContainer height="100%" width="100%">
            <ComposedChart
              data={this.generateData()}
              margin={{
                top: 25,
                bottom: 0,
                left: 0,
                right: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
              <XAxis dataKey="v" />
              <YAxis dataKey="q" />
              <Line type="basis" dataKey="q" stroke="#8884d8" />
              <Tooltip content={this.showTooltip} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Card>
    );
  }
}

DroopCurve.contextType = ThemeContext;

DroopCurve.defaultProps = {
  expanded: false,
};

DroopCurve.propTypes = {
  powerFactor: PropTypes.number.isRequired,
  apparentPower: PropTypes.number.isRequired,
  expanded: PropTypes.bool,
};

export default DroopCurve;
