import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import { IntlContext } from 'contexts/IntlContext';
import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';
import { isDefined } from 'helpers/utils';
import { kWh, scaleFactorForUnit } from 'helpers/units';
import Helpers from '../../helpers/EquipmentLibraryHelpers';
import DescriptionEditor from './DescriptionEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

const getNewCustomerProgram = () => ({
  id: 'add',
  name: '',
  description: '',
  auxiliaryAgreement: {
    availabilityPerYear: 0,
    utilizationPaymentPerWattHour: 0,
    utilizationPaymentPerHour: 0,
    administrationCostPerYear: 0,
  },
});

class CustomerProgramsPanel extends Component {
  state = { ...getNewCustomerProgram() };

  UNSAFE_componentWillMount() {
    this.setState({ ...this.extractFormValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.setState({ ...this.extractFormValues(nextProps.selected) });
    }
  }

  extractFormValues = instance => {
    let asset = { ...getNewCustomerProgram() };

    if (instance) {
      const { name, id, description, auxiliaryAgreement } = instance;
      const auxAgreementSelected = Object.keys(asset.auxiliaryAgreement).reduce((aux, auxAg) => {
        if (isDefined(auxiliaryAgreement)) {
          const auxAgSingle = auxiliaryAgreement[auxAg];
          aux[auxAg] =
            !!auxiliaryAgreement && isDefined(auxAgSingle)
              ? auxAgSingle
              : this.state.auxiliaryAgreement[auxAg];
          aux[auxAg] =
            auxAg === 'utilizationPaymentPerWattHour'
              ? aux[auxAg] * scaleFactorForUnit(kWh)
              : aux[auxAg];
          return aux;
        }
        return asset.auxiliaryAgreement;
      }, {});
      asset = {
        auxiliaryAgreement: auxAgreementSelected,
        description,
        name,
        id,
      };
    }
    return asset;
  };

  handleInputChange = ({ target }) => this.setState({ [target.id]: target.value });

  handleAuxChange = e => {
    const { id, value } = e;
    this.setState(prevState => ({
      auxiliaryAgreement: {
        ...prevState.auxiliaryAgreement,
        [id]: parseFloat(value),
      },
    }));
  };

  handleCreate = () => {
    const { name, description, auxiliaryAgreement } = this.state;
    const auxiliaryAgreementScaled = {
      ...auxiliaryAgreement,
      utilizationPaymentPerWattHour:
        auxiliaryAgreement.utilizationPaymentPerWattHour / scaleFactorForUnit(kWh),
    };
    const diffModel = {
      AuxiliaryAgreements: [auxiliaryAgreementScaled],
      description,
      name,
    };
    if (this.state.id === 'add') {
      this.props.handleCreate('customer_agreement', diffModel, 'CustomerAgreement');
    } else {
      // Only create difference model for values that have changed
      const { selected } = this.props;
      const auxAgreement = selected.auxiliaryAgreement;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        if (key === 'AuxiliaryAgreements') {
          const auxObjects = Object.keys(diffModel[key][0]).reduce(
            (list, prop) => {
              if (!(parseFloat(diffModel[key][0]?.[prop]) === parseFloat(auxAgreement?.[prop]))) {
                list[prop] = parseFloat(diffModel[key][0]?.[prop]);
              }
              return list;
            },
            { UUID: auxAgreement.id },
          );
          diff[key] = [auxObjects];
        } else if (diffModel[key] !== selected[key]) {
          diff[key] = diffModel[key];
        }
        return diff;
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  };

  valuesUpdated = selected => {
    if (!selected) return true;
    const { name, description, auxiliaryAgreement } = selected;
    return (
      name !== this.state.name ||
      Object.keys(this.state.auxiliaryAgreement).some(
        auxAg =>
          (isDefined(auxiliaryAgreement) &&
            parseFloat(this.state.auxiliaryAgreement[auxAg]) !==
              parseFloat(auxiliaryAgreement[auxAg]) &&
            auxAg !== 'utilizationPaymentPerWattHour') ||
          (isDefined(auxiliaryAgreement) &&
            parseFloat(this.state.auxiliaryAgreement[auxAg]) !==
              parseFloat(auxiliaryAgreement[auxAg] * scaleFactorForUnit(kWh)) &&
            auxAg === 'utilizationPaymentPerWattHour') ||
          (!isDefined(auxiliaryAgreement) &&
            parseFloat(this.state.auxiliaryAgreement[auxAg]) !== 0),
      ) ||
      description !== this.state.description
    );
  };

  auxiliaryAgreementValid = () =>
    Object.keys(this.state.auxiliaryAgreement).every(
      aux => !Number.isNaN(parseFloat(this.state.auxiliaryAgreement[aux])),
    );

  formValid = () =>
    this.auxiliaryAgreementValid() &&
    this.state.name.trim().length > 0 &&
    this.valuesUpdated(this.props.selected);

  render() {
    const isDisabled =
      this.props.isAuthEnabled &&
      ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type')) ||
        (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type')) ||
        (this.props.match.params.branch === 'master' &&
          !this.props.permissions.has('modify_network_as_built')));
    const defaultProps = {
      disabled: isDisabled,
    };
    // 0 for these values is not valid
    const auxAgreement = this.state.auxiliaryAgreement;
    const availabilityPerYear = Helpers.createDisplayObject(
      'Availability/Capacity Payment',
      'availabilityPerYear',
      auxAgreement.availabilityPerYear,
      '/year',
    );
    const utilizationPaymentPerWattHour = Helpers.createDisplayObject(
      '',
      'utilizationPaymentPerWattHour',
      auxAgreement.utilizationPaymentPerWattHour,
      `/${kWh}`,
    );
    const utilizationPaymentPerHour = Helpers.createDisplayObject(
      '',
      'utilizationPaymentPerHour',
      auxAgreement.utilizationPaymentPerHour,
      '/hour',
    );
    const administrationCostPerYear = Helpers.createDisplayObject(
      'Annual Administration Cost',
      'administrationCostPerYear',
      auxAgreement.administrationCostPerYear,
      '/year',
    );

    const { currencySymbol } = this.context;
    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleCreate}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div
            className="equipment-info-container currency-container"
            key={this.state.id}
            id="general-tab"
          >
            <div className="right-panel large-labels">
              <TextInput
                {...defaultProps}
                id="name"
                label="Name"
                value={this.state.name}
                required
                onChange={this.handleInputChange}
              />
              <NumberInput
                {...defaultProps}
                {...availabilityPerYear}
                required
                ge={0}
                currencySymbol={currencySymbol}
                onChange={this.handleAuxChange}
                inputStyle="eq-lib"
              />
              <div className="">
                <div className="coordinates-table single-column">
                  <div className="label-section">
                    Utilization Payment *
                    <br />
                  </div>
                  <div className="table-section">
                    <NumberInput
                      {...defaultProps}
                      {...utilizationPaymentPerWattHour}
                      required
                      ge={0}
                      currencySymbol={currencySymbol}
                      onChange={this.handleAuxChange}
                      inputStyle="eq-lib"
                      className="utilization-payment"
                    />
                    <NumberInput
                      {...defaultProps}
                      {...utilizationPaymentPerHour}
                      required
                      ge={0}
                      currencySymbol={currencySymbol}
                      onChange={this.handleAuxChange}
                      inputStyle="eq-lib"
                      className="utilization-payment"
                    />
                  </div>
                </div>
              </div>
              <NumberInput
                {...defaultProps}
                {...administrationCostPerYear}
                required
                ge={0}
                currencySymbol={currencySymbol}
                onChange={this.handleAuxChange}
                inputStyle="eq-lib"
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown escapeHtml source={decodeURIComponent(this.state.description)} />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
        ]}
      </PanelTabs>
    );
  }
}

CustomerProgramsPanel.contextType = IntlContext;

CustomerProgramsPanel.defaultProps = {
  selected: undefined,
};

CustomerProgramsPanel.propTypes = {
  selected: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

export default CustomerProgramsPanel;
