import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { networkEditActions } from 'routes/WorkspaceLayout/routes/Network/modules/networkEdit';

import SubstationSelect from '../components/SubstationSelect';

const mapDispatchToProps = dispatch => ({
  updateContainer: bindActionCreators(networkEditActions.updateContainer, dispatch),
});

const mapStateToProps = (state, ownProps) => ({
  theme: state.global.theme,
  status: state.network.applyDifferenceModelRequest.updateFeeder,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubstationSelect);
