import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const MinusIcon: FunctionComponent<IconPropsSchema> = ({ color, height, width }) => (
  <svg
    style={{ pointerEvents: 'none' }}
    height={height}
    width={width}
    x="0px"
    y="0px"
    viewBox="0 0 35 35"
  >
    <g id="zoom_out" transform="translate(-1354.5 -2898)">
      <rect x="1366.5" y="2914.5" fill={color} width="11" height="2" />
    </g>
  </svg>
);

export default MinusIcon;
