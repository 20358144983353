import React, { FunctionComponent, ReactElement, useEffect } from 'react';
import asyncActionStates from 'helpers/asyncActionStates';
import Modal from '../Modal';
import './ConfirmDelete.scss';

type ConfirmDeleteProps = {
  modalActive: boolean;
  closeModal?: () => void;
  deleteStatus?: number;
  deleteItem: null | (() => void);
  deletedItemDesc: string;
  modalBody?: { message1?: string | ReactElement; message2?: string | ReactElement };
};

const { SUCCESS, ERROR, LOADING } = asyncActionStates;
/**
 * Creates the contents of the confirm delete modal
 */
const ConfirmDelete: FunctionComponent<ConfirmDeleteProps> = ({
  modalActive,
  closeModal = () => {},
  deleteStatus = asyncActionStates.INITIAL,
  deleteItem,
  deletedItemDesc,
  modalBody = {
    message1: 'Once an item has been deleted, it cannot be restored.',
    message2: 'Would you like to permanently remove this item?',
  },
}) => {
  useEffect(() => {
    if ((deleteStatus === SUCCESS || deleteStatus === ERROR) && modalActive) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);
  const item = deletedItemDesc;
  const isLoading = deleteStatus === LOADING;
  return (
    <Modal
      active={modalActive}
      title={`Delete ${item} ?`}
      width="500px"
      onCancel={closeModal}
      onConfirm={deleteItem}
      labels={{
        confirm: isLoading ? <i className="material-icons rotate">autorenew</i> : 'Yes, delete',
      }}
      reverseFooterButtons
      disableConfirm={isLoading || !deleteItem}
      disableCancel={isLoading}
      showCancel={!!closeModal}
      modalType="primary"
    >
      <p className="modal-message__p">{modalBody.message1}</p>
      <p className="modal-message__p">{modalBody.message2}</p>
    </Modal>
  );
};

export default ConfirmDelete;
