import { Request } from '@opusonesolutions/gridos-app-framework';
import { BETA_PERMISSION } from 'helpers/permissions';

export const safeGetErrorMessage = error => {
  let errorMessage = '';
  try {
    if (error && error.response && error.response.data && error.response.data.errors) {
      errorMessage = error.response.data.errors.reduce((message, curr) => {
        if (typeof curr.message === 'string') {
          message += `${curr.message}\n`;
        }
        return message;
      }, '');
    } else if (error && error.response && error.response.data && error.response.data.message) {
      if (typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
      }
      if (error.response.data.message === Object(error.response.data.message)) {
        const [errorMessages] = Object.values(error.response.data.message);
        [errorMessage] = errorMessages;
      }
    }
  } catch (ex) {
    errorMessage = '';
  }

  if (!errorMessage) {
    errorMessage = 'Unknown Error';
  }
  return errorMessage.trim();
};

export const getCostOptions = (assetClass, currencySymbol, schedule_type, permissions) => {
  let options = [{}];
  const hasBetaPermission = permissions.has(BETA_PERMISSION);
  if (schedule_type === 'Cost') {
    if (
      [
        'CHP',
        'RunOfRiverHydro',
        'InverterPV',
        'Battery',
        'Wind',
        'SynchronousMachine',
        'EnergyConsumer',
      ].includes(assetClass)
    ) {
      options = [
        { value: 'CostOfEnergy', label: 'Cost of Energy' },
        { value: 'CostOfPower', label: 'Cost of Power' },
      ];
    } else if (['EnergySource'].includes(assetClass)) {
      options = [
        { value: 'CostOfEnergy', label: 'Cost of Energy' },
        { value: 'CostOfPower', label: 'Cost of Power' },
        { value: 'CO2EmissionRate', label: 'CO2 Emissions (g/kWh)' },
        { value: 'CO2EmissionCost', label: `CO2 Emissions (${currencySymbol}/g)` },
      ];
    } else if (['PowerTransformer', 'Regulator'].includes(assetClass) && hasBetaPermission) {
      options = [{ value: 'CostOfTapOperation', label: 'Cost of Tap Operation' }];
    } else if (['LinearShuntCompensator'].includes(assetClass) && hasBetaPermission) {
      options = [{ value: 'CostOfCapacitorOperation', label: 'Cost of Capacitor Operation' }];
    }
  } else if (
    schedule_type === 'Global' &&
    ['ElectricVehicleChargingStation'].includes(assetClass)
  ) {
    options = [
      { value: 'TotalCapacity', label: 'Total Capacity (kWh)' },
      { value: 'PowerFactor', label: 'Power Factor' },
      { value: 'MaxP', label: 'Max Real Power (kW)' },
      { value: 'StartSOC', label: 'Starting SoC (%)' },
    ];
  } else {
    options = '';
  }

  return options;
};

export const initialScheduleData = {
  variables: {},
  datapoints: [],
};
export const fetchAssetSchedule = async (
  workspace,
  branch,
  assetID,
  scenario,
  maxRange,
  timeBarZoomLevel,
  scheduleType,
) => {
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${assetID}`,
  );
  const response = await request.get({
    params: {
      scenario_id: scenario,
      start_date: maxRange.start.toISOString(),
      end_date: maxRange.end.toISOString(),
      schedule_type: scheduleType,
      aggregation: timeBarZoomLevel,
    },
  });
  return response;
};

const helpers = { safeGetErrorMessage, initialScheduleData, fetchAssetSchedule };
export default helpers;
