import React, { FunctionComponent, useState } from 'react';
import Modal from 'components/Modal';
import { modalInfos, NEW_MODAL_TYPES } from 'helpers/EditMode';
import GridLayout from 'layouts/GridLayout';
import Icons from 'components/Icons';
import TextInput from 'components/TextInput';
import ResultsCard from 'components/ResultsCard';

type SaveVersionModalProps = {
  openModalType: string;
  cancelModal: () => void;
  toggleEditMode: () => void;
  handleSaveCurrentBranch: (branchName?: string, notes?: string) => void;
  saveEditBranchLoading: boolean;
  branches: { name: string }[];
};

const SaveVersionModal: FunctionComponent<SaveVersionModalProps> = ({
  openModalType,
  cancelModal,
  toggleEditMode,
  handleSaveCurrentBranch,
  saveEditBranchLoading,
  branches = [],
}) => {
  const { title, caption, cancelLabel, saveLabel } = modalInfos[openModalType];
  const { DISCARD_CHANGES, OVERWRITE, SAVE_NEW_VERSION } = NEW_MODAL_TYPES;
  const { PencilEditorIcon } = Icons;
  const [newVerName, setNewVerName] = useState('');
  const [notes, setNotes] = useState('');
  const applyChanges = async () => {
    try {
      switch (openModalType) {
        case OVERWRITE:
          await handleSaveCurrentBranch();
          break;
        case SAVE_NEW_VERSION:
          await handleSaveCurrentBranch(newVerName, notes);
          break;
        case DISCARD_CHANGES:
        default:
          await toggleEditMode();
      }
    } catch (err) {}
    cancelModal();
  };
  const nameAlreadyExist = branches.some(branch => branch.name === newVerName);
  const invalidBranchName =
    openModalType === SAVE_NEW_VERSION &&
    (!newVerName || !newVerName.match(/^[a-zA-Z0-9-_]*$/) || nameAlreadyExist);
  return (
    <Modal
      active
      title={title}
      subtitle={caption}
      onConfirm={() => applyChanges()}
      onCancel={() => cancelModal()}
      modalType="primary"
      width="800px"
      height="auto"
      labels={{
        confirm: saveEditBranchLoading ? (
          <i className="material-icons rotate">refresh</i>
        ) : (
          saveLabel
        ),
        cancel: cancelLabel,
      }}
      disableConfirm={invalidBranchName || saveEditBranchLoading}
      scrollBody
      disabledOnClickOverlay
    >
      <GridLayout>
        {openModalType === SAVE_NEW_VERSION && (
          <div className="grid-columns one-two margin-10">
            <div className="header flex-end margin-10">
              <div style={{ marginRight: '10px' }}>Version details</div>
              <PencilEditorIcon />
            </div>
            <div>
              <ResultsCard withBorder={false} className="new-version-card">
                <TextInput
                  id="new_version_name"
                  value={newVerName}
                  onChange={e => setNewVerName(e?.target.value ?? '')}
                  invalid={invalidBranchName}
                  validationMessage={
                    nameAlreadyExist
                      ? 'This version name already exists'
                      : 'Must be at least 1 character and can not include special characters or spaces'
                  }
                  label="Network version name"
                  inputStyle="primary"
                  inputWidth="225px"
                />
                <TextInput
                  id="notes"
                  value={notes}
                  onChange={e => setNotes(e?.target.value ?? '')}
                  label="Notes/comments"
                  inputStyle="primary"
                  inputWidth="225px"
                  className="margin-10"
                />
              </ResultsCard>
            </div>
          </div>
        )}
      </GridLayout>
    </Modal>
  );
};

export default SaveVersionModal;
