import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { kWh, scaleFactorForUnit } from 'helpers/units';
import { IntlContext } from 'contexts/IntlContext';
import NumberInput from 'components/NumberInput';

const CostEditor = ({
  assetModel,
  includeCommunityCredit,
  includePerUnitMaintenance,
  isDisabled,
  onChange,
  unit,
}) => {
  const { currencySymbol } = useContext(IntlContext);
  return (
    <div className="equipment-info-container cost-container" id="cost-tab">
      <div className="right-panel">
        <NumberInput
          onChange={e => onChange({ estimatedUnitCost: e.value })}
          required
          precision={2}
          disabled={isDisabled}
          id="estimatedUnitCost"
          label="Equipment Cost"
          value={assetModel.estimatedUnitCost}
          inputStyle="eq-lib"
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <NumberInput
          onChange={e => onChange({ estimatedUnitDisposalCost: e.value })}
          required
          precision={2}
          disabled={isDisabled}
          id="estimatedUnitDisposalCost"
          label="Equipment Disposal Cost"
          value={assetModel.estimatedUnitDisposalCost}
          inputStyle="eq-lib"
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <NumberInput
          onChange={e => onChange({ estimatedUnitInstallationCost: e.value })}
          required
          precision={2}
          disabled={isDisabled}
          id="estimatedUnitInstallationCost"
          label="Installation Labour Cost"
          value={assetModel.estimatedUnitInstallationCost}
          inputStyle="eq-lib"
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <NumberInput
          onChange={e => onChange({ estimatedUnitRemovalCost: e.value })}
          required
          precision={2}
          disabled={isDisabled}
          id="estimatedUnitRemovalCost"
          label="Disposal Labour Cost"
          value={assetModel.estimatedUnitRemovalCost}
          inputStyle="eq-lib"
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <NumberInput
          onChange={e => onChange({ estimatedUnitUpgradeCost: e.value })}
          required
          precision={2}
          disabled={isDisabled}
          id="estimatedUnitUpgradeCost"
          label="Change Labour Cost"
          value={assetModel.estimatedUnitUpgradeCost}
          inputStyle="eq-lib"
          currencySymbol={currencySymbol}
          unit={unit}
        />
        <NumberInput
          onChange={e => onChange({ fixedMaintenanceCost: e.value })}
          required
          precision={2}
          disabled={isDisabled}
          id="fixedMaintenanceCost"
          label="Annual Maintenance Cost"
          value={assetModel.fixedMaintenanceCost}
          inputStyle="eq-lib"
          currencySymbol={currencySymbol}
          unit="/year"
        />
        {includePerUnitMaintenance && (
          <NumberInput
            onChange={e => onChange({ perUnitMaintenanceCost: e.value })}
            required
            precision={2}
            disabled={isDisabled}
            id="perUnitMaintenanceCost"
            label="Maintenance Cost"
            value={assetModel.perUnitMaintenanceCost}
            inputStyle="eq-lib"
            currencySymbol={currencySymbol}
            unit={`${unit} /year`}
          />
        )}
        {/* Use Input to allow for a precision setting */}
        <NumberInput
          onChange={e => onChange({ estimatedUnitInstallationDays: e.value })}
          ge={0}
          precision={0}
          disabled={isDisabled}
          id="estimatedUnitInstallationDays"
          label="Time to construct"
          value={assetModel.estimatedUnitInstallationDays}
          inputStyle="eq-lib"
          unit="days"
        />
        {includeCommunityCredit && (
          <NumberInput
            onChange={e => onChange({ communityGenerationCredit: e.value })}
            required
            divisor={1 / scaleFactorForUnit(kWh)}
            precision={2}
            disabled={isDisabled}
            id="communityGenerationCredit"
            label="Community Generation Credit"
            value={assetModel.communityGenerationCredit}
            inputStyle="eq-lib"
            currencySymbol={currencySymbol}
            unit={`/${kWh}`}
          />
        )}
      </div>
    </div>
  );
};
CostEditor.defaultProps = {
  includeCommunityCredit: false,
  includePerUnitMaintenance: false,
  unit: '',
};
CostEditor.propTypes = {
  assetModel: PropTypes.object.isRequired,
  includeCommunityCredit: PropTypes.bool,
  includePerUnitMaintenance: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.string,
};

export default CostEditor;
