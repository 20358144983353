import React, { FunctionComponent, ReactChild, useState, useEffect } from 'react';
import useTrackingState from 'hooks/useTrackingState';
import { ThemeProp } from 'types';
import classnames from 'classnames';
import './NumberInput.scss';
import getFormattedValue from './NumberInputHelper';

type ShowValidationMessage = 'always' | 'never' | 'after-edit';

type InputStyle = 'default-input' | 'panel' | 'eq-lib' | 'primary';

interface OnChangeEvent {
  id: string;
  value: number;
  phase?: string;
}

type NumberInputProps = {
  className?: string;
  currencySymbol?: string;
  dataTest?: string;
  disabled?: boolean;
  divisor?: number;
  gt?: number;
  htmlFor?: string;
  id?: string;
  inputColor?: string;
  inputStyle?: InputStyle;
  inputWidth?: string;
  invalid?: boolean;
  label?: ReactChild;
  lt?: number;
  onBlur?: (event?: React.ChangeEvent<HTMLElement>) => void;
  onChange: (event: OnChangeEvent) => void;
  onFocus?: (event?: React.ChangeEvent<HTMLElement>) => void;
  onKeyPress?: (event?: React.KeyboardEvent<HTMLElement>) => void;
  ge?: number;
  le?: number;
  negNonZero?: boolean;
  posNonZero?: boolean;
  phase?: string;
  precision?: number;
  realValue?: number;
  required?: boolean;
  rowInput?: boolean;
  showRequiredAsterisk?: boolean;
  showValidationMessage?: ShowValidationMessage;
  step?: string;
  unit?: ReactChild;
  validationMessage?: string | Array<string>;
  value?: string | number | null;
  inputInfo?: string;
  posInteger?: boolean;
  theme?: ThemeProp;
  placeholder?: string;
  edited?: boolean;
};

const NumberInput: FunctionComponent<NumberInputProps> = ({
  className = '',
  currencySymbol = '',
  dataTest = '',
  disabled = false,
  gt = null,
  htmlFor = '',
  id = '',
  inputColor = '',
  inputStyle = 'default-input',
  inputWidth = '',
  invalid = false,
  label = '',
  lt = null,
  ge = null,
  le = null,
  negNonZero = false,
  posNonZero = false,
  posInteger = false,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  phase = '',
  realValue = '',
  required = false,
  rowInput = false,
  showRequiredAsterisk = true,
  showValidationMessage = 'after-edit',
  step = '',
  unit = '',
  validationMessage = '',
  value = '',
  inputInfo = '',
  divisor = null,
  precision = null,
  placeholder = '',
  edited = false,
}) => {
  const [isEdited, setEdited] = useTrackingState(edited);
  const [valueInRange, setValueInRange] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    let val: any = (e.target as HTMLInputElement).value;
    if (!(Number.isNaN(parseFloat(val)) || val.toString().slice(-2) === '.0')) {
      val = parseFloat((e.target as HTMLInputElement).value) * (divisor ?? 1);
    }
    if (!isEdited) {
      setEdited(true);
    }
    const onChangeParams: OnChangeEvent = { value: val, id };
    if (phase) {
      onChangeParams.phase = phase;
    }
    onChange(onChangeParams);
  };

  const onInputBlur = (e: React.ChangeEvent<HTMLElement>) => {
    if (valueInRange && onBlur) {
      onBlur(e);
    }
  };

  const onInputKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (valueInRange && onKeyPress) {
      onKeyPress(e);
    }
  };

  useEffect(() => {
    let valid = true;
    if (
      (!isEdited && showValidationMessage === 'after-edit') ||
      showValidationMessage === 'never'
    ) {
      valid = true;
    } else {
      const numVal = typeof value === 'string' ? parseFloat(value) : value;
      if (required && Number.isNaN(numVal)) {
        valid = false;
      }
      if (posInteger && !Number.isInteger(value)) {
        valid = false;
      }
      if (posNonZero && numVal !== null && numVal <= 0) {
        valid = false;
      }

      if (negNonZero && numVal !== null && numVal >= 0) {
        valid = false;
      }
      if (ge !== null && numVal !== null && numVal < ge) {
        valid = false;
      }

      if (gt !== null && numVal !== null && numVal <= gt) {
        valid = false;
      }

      if (lt !== null && numVal !== null && numVal >= lt) {
        valid = false;
      }
      if (le !== null && numVal !== null && numVal > le) {
        valid = false;
      }
      if (invalid) {
        valid = !invalid;
      }
    }
    setValueInRange(valid);
  }, [
    value,
    isEdited,
    gt,
    lt,
    invalid,
    ge,
    le,
    required,
    posNonZero,
    negNonZero,
    validationMessage,
    showValidationMessage,
    posInteger,
  ]);

  useEffect(() => {
    let message = '';
    if (invalid) {
      if (validationMessage && typeof validationMessage !== 'string') {
        message = validationMessage.join(' ');
      } else {
        message = validationMessage;
      }
    } else {
      const numVal = typeof value === 'string' ? parseFloat(value) : value;
      if (required && (value === '' || Number.isNaN(numVal))) {
        message = 'Field is required.';
      } else if (posInteger && !Number.isInteger(value)) {
        message = 'Value should be non-decimal';
      } else if (posNonZero && numVal !== null && numVal <= 0) {
        message = 'Value must be greater than 0.';
      } else if (negNonZero && numVal !== null && numVal >= 0) {
        message = 'Value must be less than 0.';
      } else if (
        numVal !== null &&
        ((ge !== null && numVal < ge) || (le !== null && numVal > le))
      ) {
        const geMessage = ge !== null ? `smaller than ${ge}` : '';
        const leMessage = le !== null ? `greater than ${le}` : '';
        message = `Value must not be ${geMessage}${
          !!geMessage && !!leMessage ? ' or ' : ''
        }${leMessage}.`;
      } else if ((gt !== null || lt !== null) && numVal !== null) {
        const gtMessage = gt !== null ? `smaller than or equal to ${gt}` : '';
        const ltMessage = lt !== null ? `greater than or equal to ${lt}` : '';
        message = `Value must not be ${gtMessage}${
          !!gtMessage && !!ltMessage ? ' or ' : ''
        }${ltMessage}.`;
      }
    }
    setErrorMessage(message);
  }, [
    ge,
    le,
    gt,
    lt,
    invalid,
    posNonZero,
    negNonZero,
    required,
    validationMessage,
    value,
    posInteger,
  ]);

  const getClassNames = () => {
    let classes = {};
    if (phase) {
      classes = {
        'per-phase-row-input': true,
        'per-phase-row-input--editable': !disabled,
        'per-phase-row-input--edited': isEdited,
        'per-phase-row-input--invalid': invalid,
      };
    }
    if (rowInput) {
      classes = {
        'single-row-input': true,
        'single-row-input--editable': !disabled,
        'single-row-input--edited': isEdited,
        'single-row-input--invalid': invalid,
      };
    }
    return classes;
  };

  const style = () => {
    interface Style {
      width?: string;
      color?: string;
    }
    const styleObj: Style = {};
    if (inputWidth) styleObj.width = inputWidth;
    if (inputColor) styleObj.color = inputColor;
    return styleObj;
  };

  const customProps = {
    phase,
    realvalue: realValue,
  };
  const formattedValue =
    divisor || precision
      ? getFormattedValue(value, divisor || 1, phase && !precision ? 3 : precision || 0)
      : value;
  return (
    <div
      className={classnames({
        'number-input-all': true,
        [className]: true,
        [inputStyle]: true,
        ...getClassNames(),
        'number-input-all--invalid': !valueInRange || (isEdited && invalid),
      })}
    >
      <div className="number-input">
        <div
          className={classnames({
            'input-with-label': true && label,
          })}
        >
          {label && (
            <label
              htmlFor={htmlFor || id}
              className={classnames({
                'number-input__label': true,
              })}
            >
              {label}
              {required && showRequiredAsterisk ? ' *' : ''}
            </label>
          )}
          <div
            className="number-input-container"
            data-currency={currencySymbol}
            data-info={inputInfo}
          >
            <input
              id={id}
              type="number"
              key={id}
              data-test={dataTest}
              step={step}
              className={classnames({
                'number-input__input': true,
                'number-input-with-unit': !!unit,
                'text-optional': !required,
              })}
              value={formattedValue ?? ''}
              style={style()}
              disabled={disabled}
              onChange={e => onInputChange(e)}
              required={required}
              title={valueInRange && value ? value.toString() : errorMessage}
              onBlur={e => onInputBlur(e)}
              onKeyPress={e => onInputKeyPress(e)}
              onFocus={onFocus}
              name={id}
              placeholder={placeholder}
              {...customProps}
            />
            {unit && <span className="number-input-unit">{unit}</span>}
          </div>
        </div>
      </div>
      {!valueInRange && (
        <div className="input-error">
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default NumberInput;
