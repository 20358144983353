import { optionType } from 'types/edit';

export default function getControlModeOptions(
  className: string,
  disablePrevAnalysisMode = true,
): optionType[] {
  const names: { [key: string]: optionType[] } = {
    EnergySource: [
      { value: 'fixedValue', label: 'Fixed' },
      { value: 'attachedSchedule', label: 'Schedule' },
      {
        value: 'analysisSchedule',
        label: 'Previous Analysis Results',
        disabled: disablePrevAnalysisMode,
      },
    ],
    EquivalentSubstation: [
      { value: 'fixedValue', label: 'Allocate' },
      { value: 'attachedSchedule', label: 'Schedule' },
      {
        value: 'analysisSchedule',
        label: 'Previous Analysis Results',
        disabled: disablePrevAnalysisMode,
      },
    ],
    Switch: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    Disconnector: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    Fuse: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    Recloser: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    Sectionaliser: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    Breaker: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    Cut: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    GroundDisconnector: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    Jumper: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    LoadBreakSwitch: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    NonlinearShuntCompensator: [
      { value: 'uncontrolled', label: 'No control' },
      { value: 'locallyControlled', label: 'Local' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    ProtectedSwitch: [
      { value: 'scheduled', label: 'Schedule' },
      { value: 'uncontrolled', label: 'Fixed' },
    ],
    InverterPV: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'locallyControlled', label: 'Local' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    Battery: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    AsynchronousMachine: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    SynchronousMachine: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    ElectricVehicleChargingStation: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    PowerTransformer: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'locallyControlled', label: 'Local' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    LinearShuntCompensator: [
      { value: 'uncontrolled', label: 'No control' },
      { value: 'locallyControlled', label: 'Local' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    CHP: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    RunOfRiverHydro: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    Wind: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    PhotoVoltaic: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    Regulator: [
      { value: 'uncontrolled', label: 'Fixed' },
      { value: 'locallyControlled', label: 'Local' },
      { value: 'globallyOptimized', label: 'Global' },
      { value: 'scheduled', label: 'Schedule' },
    ],
    EnergyConsumer: [{ value: 'scheduled', label: 'Schedule' }],
  };
  return names?.[className] ?? [];
}

export const customModeOptions = [
  { value: 'globallyOptimized', label: 'Global' },
  { value: 'scheduled', label: 'Schedule' },
];
