import ShuntDevice from './ShuntDevice';

class RotatingMachine extends ShuntDevice {
  validationSchema = {
    ratedPowerFactor: { min: -1, max: 1, required: true },
    ratedS: { min: 1, required: true },
    ratedU: { min: 1, required: true },
  };

  get ratedS() {
    return this.attributes['RotatingMachine.ratedS'];
  }

  get totalP() {
    return this.attributes['RotatingMachine.p'] || ' ';
  }
}

export default RotatingMachine;
