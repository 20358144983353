import React from 'react';
import PropTypes from 'prop-types';
import './SelectBadgeItem.scss';

// Constructs a select item with one or more badges
const SelectBadgeItem = ({ item, badgeInfos }) => (
  <div className="select-badge" title={item.label}>
    <p className="select-badge-label">{item.label}</p>
    <div className="multibadge-container">
      {badgeInfos.map(badgeInfo => (
        <p
          className={badgeInfo.noHover ? 'select-badge-badge' : 'select-badge-badge-hover'}
          title={badgeInfo.tooltip}
          key={`badge-${badgeInfo.key}`}
        >
          {badgeInfo.label}
        </p>
      ))}
    </div>
  </div>
);

SelectBadgeItem.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  badgeInfos: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      noHover: PropTypes.bool,
    }),
  ).isRequired,
};

export default SelectBadgeItem;
