import DatePicker from 'components/DatePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import './DateTimeSelector.scss';
import TimeSelector from './TimeSelector';

const getUpdatedDate = (currentValue, updatedValue) => {
  const newDate = moment(currentValue);
  newDate.year(updatedValue.year());
  newDate.month(updatedValue.month());
  newDate.date(updatedValue.date());
  return newDate;
};

const getUpdatedTime = (currentValue, newTime) => {
  const newDate = moment(currentValue);
  const [hour, minute] = newTime.split(':');
  newDate.hour(parseInt(hour, 10));
  newDate.minute(parseInt(minute, 10));
  return newDate;
};

const DateTimeSelector = ({ label, theme, handleChange, date, minDate, maxDate, disabled }) => {
  const min = moment(minDate);
  const max = moment(maxDate);
  const current = moment(date);
  const onDateChange = newValue => {
    handleChange(getUpdatedDate(current, newValue));
  };

  const onTimeChange = newValue => {
    handleChange(getUpdatedTime(current, newValue.value));
  };

  return (
    <div>
      <p className="body-text date-range-label">{label}</p>
      <div className="date-range-controls">
        <DatePicker
          showArrows={false}
          theme={theme}
          dateFormat="Y-m-d"
          options={{
            altFormat: 'Y-m-d',
            minDate: min.toISOString(),
            maxDate: max.toISOString(),
          }}
          onChange={onDateChange}
          date={current}
          useUTC
          disabled={disabled}
          clearable={false}
        />
        <TimeSelector
          minTime={min}
          maxTime={max}
          initialTime={current}
          selectedTime={current}
          disabled={disabled}
          onTimeChange={onTimeChange}
        />
      </div>
    </div>
  );
};

DateTimeSelector.defaultProps = {
  label: '',
  theme: 'light',
  minDate: moment.utc('1900-01-01'),
  maxDate: moment.utc('2999-12-31'),
  disabled: false,
};

DateTimeSelector.propTypes = {
  label: PropTypes.string,
  theme: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
  minDate: PropTypes.object, // moment
  maxDate: PropTypes.object, // moment
  disabled: PropTypes.bool,
};

export default DateTimeSelector;
