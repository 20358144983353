import React, { FunctionComponent } from 'react';
import { ThemeProp } from 'types';

import Tooltip from '../Tooltip';

type IconFileInputProps = {
  rotate?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  icon: string;
  theme: ThemeProp;
  tooltip?: string;
  accept: string;
  id: string;
};

const IconFileInput: FunctionComponent<IconFileInputProps> = ({
  rotate,
  onChange,
  disabled,
  icon,
  theme,
  tooltip,
  accept,
  id,
}) => {
  const renderIconFileInput = () => (
    <div className={`icon-file-input-container ${theme} ${disabled ? 'disabled' : ''}`}>
      <label htmlFor={id}>
        <div>
          <span className="icon-container">
            <i
              {...(rotate && rotate > 0 && rotate < 360
                ? { style: { transform: `rotate(${Math.trunc(rotate)}deg)` } }
                : {})}
              className="material-icons"
            >
              {icon}
            </i>
          </span>
        </div>
        <input
          type="file"
          id={id}
          onChange={event => {
            onChange(event);
            // clear the input so that the file can be uploaded again
            event.currentTarget.value = '';
          }}
          accept={accept}
          disabled={disabled}
        />
      </label>
    </div>
  );
  return tooltip ? (
    <Tooltip content={tooltip} theme={theme}>
      {renderIconFileInput()}
    </Tooltip>
  ) : (
    <>{renderIconFileInput()}</>
  );
};

export default IconFileInput;
