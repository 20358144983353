import React, { useEffect, useState, FunctionComponent, ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import Button from 'components/Button';
import RadioButtonGroup from 'components/RadioButtonGroup';
import LinkDevice from 'helpers/JSCIM/Link/LinkDevice';
import asyncActionStates from 'helpers/asyncActionStates';

import AssetContextProvider from 'contexts/AssetContext';
import AttachmentsProvider from 'contexts/AttachmentsContext';

import { isEmptyObject } from 'helpers/utils';
import ConfirmDelete from 'components/ConfirmDelete/ConfirmDelete';
import ModalPortal from 'components/ModalPortal';
import ConnectivityNode from 'helpers/JSCIM/ConnectivityNode';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import { Moment } from 'moment';

import LayerPanel from './LayerPanel';
import AssetPanel from '../../containers/AssetPanelContainer';
import ContainerPanel from './ContainerPanel';
import WorkspacePanel from './WorkspacePanel';
import ActivityLogPanel from '../../containers/ActivityLogContainer';
import NoAssetSelected from './NoAssetSelected';
import ValidationPanel from '../../containers/ValidationContainer';
import CreateDevicePanel from '../../containers/CreateDevicePanelContainer';
import ViolationsPanel from '../../containers/ViolationsPanelContainer';
import NetworkNavLinks from '../../../../NetworkTopNav/NetworkNavLinks';

import './SlidingInfoPanel.scss';

// Creates a slide out right rail panel for the Network (GIS) view

type SlidingInfoPanelProps = {
  network: {
    deleteStatus: number;
    selectedNode: { id: string; class: string } | null;
    selectedAssetID: string;
    selectedAssetName: string;
    selectedAssetViewModelClass: string;
    selectedAsset: { id: string; feeder: string };
    addAssetRequest: number;
    panelOptions: {
      active: string;
      selectedLayer: string;
      expanded: boolean;
    };
    leftRailPanel: string;
    tapPositionTimeSeriesData: [];
    timeRange: {
      start: Moment | null; // moment datetime
      end: Moment | null; // moment datetime
    };
    scenarioStatus: number;
    branch: string;
    displayBranch: string;
    workspace: string;
  };
  powerflow: {
    layerOptions: { [key: string]: { [key: string]: any } };
    jobQueued: boolean;
  };
  feeders: {
    containerType: string;
    list: { [key: string]: string }[];
    selected: { [key: string]: string }[];
  };
  loadForecast: {
    selectedScenario: string;
    selectedAnalysis: {
      type: string;
      name: string;
    } | null;
    deleteStatus: number;
  };
  actions: {
    resetJobQueued: () => void;
    setActivePanel: (value: string | null) => void;
    deleteContainer: (fdd: { [key: string]: string }, wp: string, br: string) => void;
    deleteAsset: (
      id: string,
      wp: string,
      br: string,
      toMerge: boolean,
      projectID: number | null,
    ) => void;
    expandPanel: () => void;
    toggleFeederPanel: () => void;
    setSelectedLayer: () => void;
    updateLayerOptions: (name: string, updates: { [key: string]: string }) => void;
    updateLayerAggregation: (val: string) => void;
    toggleDirectionalFlow: () => void;
    getLayerOptions: () => void;
    setContainerTypeSelected: (type: string) => void;
  };
  workspace: string;
  zoomToAsset: () => void;
  getMap: () => void;
  match: { params: { feeder_ids: [] } };
  inEditMode: boolean;
  selectedProject: number | null;
  manuallyClosed: boolean;
  setManuallyClosed: (val: boolean) => void;
};

const ACTIVE_TAB = {
  RESULTS: 'results',
  FILES: 'files',
  NOTES: 'notes',
  ASSET: 'asset',
  VALIDATION: 'validation',
  VIOLATIONS: 'violations',
  LAYERS: 'layers',
  ACTIVITYLOG: 'activityLog',
  CREATE_DEVICE_OPTIONS: 'createDeviceOptions',
};

const LEFT_RAIL_PANEL = {
  NETWORK: 'network',
  ASSET: 'asset',
};

const SlidingInfoPanel: FunctionComponent<SlidingInfoPanelProps> = ({
  network,
  feeders,
  powerflow,
  loadForecast,
  actions,
  workspace,
  selectedProject,
  inEditMode,
  zoomToAsset,
  getMap,
  match,
  manuallyClosed,
  setManuallyClosed,
}) => {
  const [modalActive, setModalActive] = useState(false);
  const [deleteCalledBy, setDeleteCalledBy] = useState<string | { type: string; id: string }>('');
  const [deleteMode, setDeleteMode] = useState('none');
  const [notifications, setNotifications] = useState({});
  const [disableButton, setButtonToDisable] = useState({});
  const [itemNumberCount, setItemCount] = useState({});
  const [selectedContainerFromAssetPanel, setSelectedContainerFromAssetPanel] = useState<
    { [key: string]: string }[]
  >([]);
  const [nodeDetailsToBeDeleted, setNodeDetailsToBeDeleted] = useState<{
    nodeName?: string;
    nodeCount?: number;
  }>({});
  const [activeTab, setactiveTab] = useState<{
    tabName?: string | null;
    isActive?: boolean | null;
  }>({
    tabName: network.panelOptions.active,
    isActive: true,
  });
  const [selectedContainer, setSelectedContainer] = useState<{ [key: string]: string }[] | null>(
    null,
  );
  const { isAuthEnabled, permissions } = useAuthContext();

  const closeModal = () => {
    setModalActive(false);
    setDeleteCalledBy('');
    setDeleteMode('none');
  };

  useEffect(() => {
    if (
      deleteCalledBy === 'Asset' &&
      network.deleteStatus === asyncActionStates.SUCCESS &&
      modalActive
    ) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network.deleteStatus, deleteCalledBy]);
  const setNotification = (notification: string, value: boolean) =>
    setNotifications(prevState => ({
      ...prevState,
      [notification]: value,
    }));

  const jobQueuedTimer = (delay: number) => {
    const panel = network.panelOptions.active;
    if (panel !== ACTIVE_TAB.ACTIVITYLOG) {
      setNotification('newActivity', true);
    }
    setTimeout(() => {
      actions.resetJobQueued();
    }, delay);
  };

  useEffect(() => {
    if (powerflow.jobQueued) jobQueuedTimer(5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [powerflow.jobQueued]);

  useEffect(() => {
    const handleTabDisable = (
      notesToDisable: string,
      disableNotes: boolean,
      filesToDisable: string,
      disableFiles: boolean,
      resultsToDisable: string,
      disableResults: boolean,
    ) => {
      setButtonToDisable(prevState => ({
        ...prevState,
        [notesToDisable]: disableNotes,
        [filesToDisable]: disableFiles,
        [resultsToDisable]: disableResults,
      }));
    };
    if (network.leftRailPanel === LEFT_RAIL_PANEL.NETWORK && feeders.selected.length > 0) {
      handleTabDisable('notes-disabled', false, 'files-disabled', false, 'results-disabled', true);
    }

    if (network.leftRailPanel === LEFT_RAIL_PANEL.NETWORK && feeders.selected.length <= 0) {
      handleTabDisable('notes-disabled', true, 'files-disabled', true, 'results-disabled', true);
    }

    if (network.leftRailPanel === LEFT_RAIL_PANEL.ASSET) {
      if (network.selectedNode) {
        if (!loadForecast.selectedAnalysis) {
          handleTabDisable(
            'notes-disabled',
            false,
            'files-disabled',
            false,
            'results-disabled',
            true,
          );
        } else {
          handleTabDisable(
            'notes-disabled',
            false,
            'files-disabled',
            false,
            'results-disabled',
            false,
          );
        }
      } else {
        handleTabDisable('notes-disabled', true, 'files-disabled', true, 'results-disabled', true);
      }
    }
  }, [
    network.selectedNode,
    loadForecast.selectedAnalysis,
    network.leftRailPanel,
    feeders.selected,
  ]);

  useEffect(() => {
    if (
      network.leftRailPanel === LEFT_RAIL_PANEL.NETWORK &&
      (feeders.selected.length <= 0 || !selectedContainer?.length)
    ) {
      setNotifications(prevState => ({
        ...prevState,
        filesNotification: false,
        notesNotification: false,
      }));
      setItemCount(prevState => ({
        ...prevState,
        numberOfFiles: null,
        numberOfNotes: null,
      }));
    }
    // if asset is not selected
    if (network.leftRailPanel === LEFT_RAIL_PANEL.ASSET && !network.selectedNode) {
      setNotifications(prevState => ({
        ...prevState,
        filesNotification: false,
        notesNotification: false,
      }));
      setItemCount(prevState => ({
        ...prevState,
        numberOfFiles: null,
        numberOfNotes: null,
      }));
    }
  }, [
    network.panelOptions.active,
    network.selectedNode,
    network.leftRailPanel,
    feeders.selected,
    selectedContainer,
  ]);

  // set active tab for asset, files, notes and results
  useEffect(() => {
    const { active } = network.panelOptions;
    const { selectedNode } = network;

    // on asset
    if (
      (active === ACTIVE_TAB.FILES ||
        active === ACTIVE_TAB.NOTES ||
        active === ACTIVE_TAB.RESULTS ||
        active === ACTIVE_TAB.ASSET) &&
      network.leftRailPanel === LEFT_RAIL_PANEL.ASSET
    ) {
      // selected node and user is on results tab
      if (network.selectedNode?.id && active === ACTIVE_TAB.RESULTS) {
        // analysis is selected
        if (loadForecast.selectedAnalysis) {
          setactiveTab({ tabName: ACTIVE_TAB.RESULTS, isActive: true });
        } else {
          setactiveTab(prevState => ({
            ...prevState,
            tabName: ACTIVE_TAB.ASSET,
            isActive: true,
          }));
          actions.setActivePanel(ACTIVE_TAB.ASSET);
        }
      }

      if (active === ACTIVE_TAB.NOTES) setactiveTab({ tabName: ACTIVE_TAB.NOTES, isActive: true });
      if (active === ACTIVE_TAB.FILES) setactiveTab({ tabName: ACTIVE_TAB.FILES, isActive: true });

      if (active === ACTIVE_TAB.ASSET && selectedNode)
        setactiveTab({ tabName: ACTIVE_TAB.ASSET, isActive: true });

      if (
        !network.selectedNode &&
        (active === ACTIVE_TAB.RESULTS ||
          active === ACTIVE_TAB.FILES ||
          active === ACTIVE_TAB.NOTES)
      ) {
        setactiveTab(prevState => ({
          ...prevState,
          tabName: ACTIVE_TAB.ASSET,
          isActive: true,
        }));
        actions.setActivePanel(ACTIVE_TAB.ASSET);
      }
    } else if (network.leftRailPanel === LEFT_RAIL_PANEL.NETWORK && active === ACTIVE_TAB.RESULTS) {
      setactiveTab(prevState => ({
        ...prevState,
        tabName: ACTIVE_TAB.ASSET,
        isActive: true,
      }));
      actions.setActivePanel(ACTIVE_TAB.ASSET);
    } else if (network.leftRailPanel === LEFT_RAIL_PANEL.NETWORK && active === ACTIVE_TAB.NOTES) {
      setactiveTab({ tabName: ACTIVE_TAB.NOTES, isActive: true });
    } else if (feeders.selected.length <= 0) {
      setactiveTab(prevState => ({
        ...prevState,
        tabName: ACTIVE_TAB.ASSET,
        isActive: true,
      }));
    } else {
      setactiveTab({});
    }
  }, [
    network.panelOptions.active,
    loadForecast,
    network.selectedNode,
    actions,
    network.leftRailPanel,
    network,
    feeders.selected,
  ]);

  // handle onclick tab
  const handleTabClick = (panel: string) => {
    if (panel === ACTIVE_TAB.ACTIVITYLOG) {
      setNotification('newActivity', false);
    }

    const samePanel = network.panelOptions.active === panel;
    const panelVal = samePanel ? null : panel;
    setManuallyClosed(samePanel && !manuallyClosed);
    actions.setActivePanel(panelVal);
  };

  const showModal = (
    deleteCalledByVal: string,
    nodeDetails: { nodeCount: number; nodeName: string },
  ) => {
    setModalActive(true);
    setDeleteCalledBy(deleteCalledByVal);
    setNodeDetailsToBeDeleted(nodeDetails);
  };

  const deleteItem = () => {
    if (typeof deleteCalledBy === 'object') {
      const { branch } = network;
      const { selected } = feeders;
      actions.deleteContainer(selected[0], workspace, branch);
    } else if (deleteCalledBy === 'Asset') {
      const { selectedAssetID, branch } = network;
      actions.deleteAsset(
        selectedAssetID,
        workspace,
        branch,
        deleteMode === 'merge',
        selectedProject,
      );
    }
  };

  const deleteModalConfig = () => {
    const toDelete = deleteCalledBy;
    const { deleteStatus: currDeleteStatus, selectedNode, selectedAssetName } = network;
    const isConnectivityNode = selectedNode instanceof ConnectivityNode;
    let modalBody = (item: string): { message1: string; message2: string | ReactElement } => ({
      message1:
        !isEmptyObject(nodeDetailsToBeDeleted) && isConnectivityNode
          ? `Node ${nodeDetailsToBeDeleted.nodeName} is a backup node for 
           ${nodeDetailsToBeDeleted.nodeCount} loads. Deleting the node results in those loads having their backup nodes unset.`
          : `Are you sure you want to delete ${item}?`,
      message2: 'This will create discontinuity in the network',
    });
    let item = '';
    const deleteStatus = currDeleteStatus || asyncActionStates.INITIAL;
    let deletedItemDesc = deleteCalledBy as string;
    if (typeof toDelete === 'object') {
      return containerDeleteModalConfig(toDelete, deleteStatus, modalBody);
    }
    if (toDelete === 'Asset') {
      deletedItemDesc = `asset - ${selectedAssetName}`;
      item = `asset - ${selectedAssetName}`;
    }
    if (toDelete === 'Asset' && selectedNode instanceof LinkDevice) {
      modalBody = () => ({
        message1: 'The deletion of this asset may create a discontinuity in the network',
        message2: (
          <>
            <p>How would you like to address this?</p>
            <br />
            <RadioButtonGroup
              id="delete-link-confirmation-option"
              options={[
                { id: 'merge', label: 'Merge the two nodes' },
                { id: 'none', label: 'Leave the nodes as is' },
              ]}
              value={deleteMode}
              listType="column"
              onChange={({ target }: { [key: string]: { value: string } }) =>
                setDeleteMode(target.value)
              }
              radioType="primary"
            />
          </>
        ),
      });
    }

    return {
      modalActive,
      closeModal,
      deleteItem,
      deleteStatus,
      deletedItemDesc,
      modalBody: modalBody(item),
    };
  };

  const containerDeleteModalConfig = (
    { type, id }: { type: string; id: string },
    deleteStatus: number,
    defaultModalBody: (val: string) => { message1: string; message2: string | ReactElement },
  ) => {
    const config = {
      modalActive,
      closeModal,
      deleteStatus,
      deletedItemDesc: type,
    };

    const feedersFiltered = type === 'Substation' && feeders.list.filter(f => f.substation === id);

    if (!feedersFiltered || !feedersFiltered.length) {
      return {
        ...config,
        deleteItem,
        modalBody: defaultModalBody(`a ${type}`),
      };
    }

    return {
      ...config,
      deleteItem: null,
      closeModal: () => setModalActive(false),
      modalBody: {
        message1: 'This Substation has associated Feeders.',
        message2: 'Please delete those first.',
      },
    };
  };

  useEffect(() => {
    if (network.selectedAsset) {
      const feederInAssetPanel = feeders?.selected.filter(
        item => item.id === network?.selectedAsset?.feeder,
      );
      setSelectedContainerFromAssetPanel(feederInAssetPanel);
      actions.setContainerTypeSelected(feederInAssetPanel[0]?.type);
    }
  }, [feeders.selected, actions, network?.selectedAsset?.feeder, network.selectedAsset]);

  const onNetwork = network.leftRailPanel === LEFT_RAIL_PANEL.NETWORK;
  const { active, expanded } = network.panelOptions;
  const { timeRange, selectedNode } = network;
  const layerActive = active === ACTIVE_TAB.LAYERS;
  const validationActive = active === ACTIVE_TAB.VALIDATION;
  const logActive = active === ACTIVE_TAB.ACTIVITYLOG;
  const violationsActive = active === ACTIVE_TAB.VIOLATIONS;
  const workspaceActive =
    feeders.selected.length === 0 &&
    !layerActive &&
    !validationActive &&
    !logActive &&
    !violationsActive;
  const createDeviceOptionsActive = inEditMode && active === ACTIVE_TAB.CREATE_DEVICE_OPTIONS;
  const containerActive =
    onNetwork &&
    !validationActive &&
    !logActive &&
    !layerActive &&
    !createDeviceOptionsActive &&
    !violationsActive &&
    feeders.selected.length > 0;

  const noAssetActive = !onNetwork && active === ACTIVE_TAB.ASSET && !selectedNode;
  const showDeleteModal =
    network.selectedNode ||
    loadForecast.selectedScenario ||
    (feeders.selected &&
      typeof deleteCalledBy === 'object' &&
      (deleteCalledBy.type === 'Feeder' || deleteCalledBy.type === 'Substation'));
  const showTooltip = powerflow.jobQueued && network.panelOptions.active !== ACTIVE_TAB.ACTIVITYLOG;
  const assetID = useMemo(() => {
    if (onNetwork) {
      return selectedContainer && selectedContainer.length > 0 ? selectedContainer[0]?.id : null;
    }
    if (network.leftRailPanel === LEFT_RAIL_PANEL.ASSET) {
      return network.selectedAssetID;
    }
    return null;
  }, [onNetwork, network.leftRailPanel, network.selectedAssetID, selectedContainer]);
  return (
    <AssetContextProvider>
      <AttachmentsProvider
        workspace={workspace}
        branch={network.branch}
        assetID={assetID}
        containerPanel
      >
        <div className="right-panel-wrapper">
          {active && !manuallyClosed && (
            <div className="right-panel-details">
              <div id="sliding-panel" className="sliding-panel">
                <div
                  className={classNames({
                    'expand-panel': true,
                    'expand-panel--expanded': network.panelOptions.expanded,
                  })}
                >
                  <Button type="text" onClick={actions.expandPanel}>
                    <i className="material-icons" style={{ transform: 'rotate(-90deg)' }}>
                      height
                    </i>
                    <span style={{ paddingLeft: '10px' }}>
                      {expanded ? 'Minimize panel' : 'Expand panel'}
                    </span>
                  </Button>
                </div>
                <div className="sliding-panel-container">
                  {createDeviceOptionsActive && <CreateDevicePanel />}
                  {noAssetActive && <NoAssetSelected />}
                  {activeTab.isActive &&
                    network.selectedNode &&
                    network.leftRailPanel &&
                    !containerActive && (
                      <AssetPanel
                        zoomToAsset={zoomToAsset}
                        toggleFeederPanel={actions.toggleFeederPanel}
                        setActivePanel={actions.setActivePanel}
                        workspace={workspace}
                        feeders={match.params.feeder_ids}
                        showModal={showModal}
                        actions={actions}
                        getMap={getMap}
                        panelOptions={network.panelOptions}
                        selectedNode={network.selectedNode}
                        activeTab={activeTab.tabName}
                        leftRailPanel={network.leftRailPanel}
                        setNotifications={setNotifications}
                        setItemCount={setItemCount}
                      />
                    )}
                  {layerActive && (
                    <LayerPanel
                      selectedLayer={network.panelOptions.selectedLayer}
                      setSelectedLayer={actions.setSelectedLayer}
                      updateLayerOptions={actions.updateLayerOptions}
                      updateLayerAggregation={actions.updateLayerAggregation}
                      layerOptions={powerflow.layerOptions}
                      showThreePhase={
                        timeRange?.start &&
                        timeRange?.end &&
                        timeRange?.end?.diff(timeRange?.start, 'h') <= 24
                      }
                      expanded={network.panelOptions.expanded}
                      toggleDirectionalFlow={actions.toggleDirectionalFlow}
                      selectedScenario={loadForecast.selectedScenario}
                      getLayerOptions={actions.getLayerOptions}
                      workspace={network.workspace}
                      branch={network.branch}
                      displayBranch={network.displayBranch}
                      inEditMode={inEditMode}
                      selectedAnalysis={loadForecast.selectedAnalysis}
                    />
                  )}
                  {containerActive &&
                    network.leftRailPanel &&
                    activeTab &&
                    feeders?.selected?.length > 0 && (
                      <ContainerPanel
                        showModal={showModal}
                        onClose={actions.toggleFeederPanel}
                        expanded={network.panelOptions.expanded}
                        selectedAssetID={network.selectedAssetID}
                        selectedAssetViewModelClass={network.selectedAssetViewModelClass}
                        activeTab={network.panelOptions.active}
                        leftRailPanel={network.leftRailPanel}
                        containerType={feeders.containerType}
                        setContainerType={actions.setContainerTypeSelected}
                        selectedContainerOnAssetPanel={selectedContainerFromAssetPanel}
                        selectedContainer={selectedContainer}
                        setSelectedContainer={setSelectedContainer}
                        setNotifications={setNotifications}
                        setItemCount={setItemCount}
                      />
                    )}
                  {workspaceActive && (
                    <WorkspacePanel
                      workspace={workspace}
                      expanded={network.panelOptions.expanded}
                    />
                  )}
                  {validationActive && (
                    <CustomScrollBar className="" alwaysShow={false}>
                      <ValidationPanel
                        isAuthEnabled={isAuthEnabled}
                        permissions={permissions}
                        selectedFeeders={feeders.selected}
                        workspace={workspace}
                        branch={network.branch}
                        expanded={network.panelOptions.expanded}
                      />
                    </CustomScrollBar>
                  )}
                  {logActive && (
                    <ActivityLogPanel actions={actions} expanded={network.panelOptions.expanded} />
                  )}
                  {violationsActive && (
                    <ViolationsPanel actions={actions} expanded={network.panelOptions.expanded} />
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className={classNames({
              'panel-icons-row': true,
              'panel-icons-row--expanded': network.panelOptions.expanded,
            })}
          >
            <NetworkNavLinks
              active={network.panelOptions.active}
              open={!manuallyClosed}
              showTooltip={showTooltip}
              handleTabClick={handleTabClick}
              {...notifications}
              {...disableButton}
              {...itemNumberCount}
            />
          </div>
        </div>
        {showDeleteModal && (
          <ModalPortal id="modal-portal">
            <ConfirmDelete {...deleteModalConfig()} />
          </ModalPortal>
        )}
      </AttachmentsProvider>
    </AssetContextProvider>
  );
};

export default SlidingInfoPanel;
