import AssetInfo from './AssetInfo';

class PowerTransformerInfo extends AssetInfo {
  constructor(id, cimDict) {
    super(id, cimDict);

    this.tankInfos = this.extractReferenceList(id, 'PowerTransformerInfo.TransformerTankInfos');
  }

  get TankInfos() {
    return this.tankInfos;
  }
}

export default PowerTransformerInfo;
