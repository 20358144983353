import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import './CustomCheckbox.scss';

/**
 * Creates a custom styled controlled checked checkbox
 * background color and unchecked border color can be customized
 */

type CustomCheckboxProps = {
  value?: any;
  id?: string;
  checked?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  focusable?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  className?: string;
  dash?: boolean;
  type?: 'primary' | 'default';
  name?: string;
};

const CustomCheckbox: FunctionComponent<CustomCheckboxProps> = ({
  value = ' ',
  checked = false,
  id = 'checkbox',
  backgroundColor,
  borderColor,
  focusable = true,
  onClick,
  disabled = false,
  className = null,
  dash = false,
  type = 'default',
  name = '',
}) => {
  const showCustomBG = !disabled && checked && backgroundColor;
  const showCustomBorder = !disabled && !checked && borderColor;

  return (
    <div
      className={classNames(
        {
          'custom-checkbox--disabled': disabled,
          [`custom-checkbox--checked ${className}--checked`]: checked,
        },
        'custom-checkbox',
        className,
        type,
      )}
    >
      <input
        className="check-input"
        type="checkbox"
        tabIndex={focusable ? undefined : -1}
        value={value}
        id={id}
        readOnly
        checked={checked}
        onClick={onClick}
        disabled={disabled}
        name={name}
      />
      <label
        className="check-container"
        htmlFor={id}
        style={{
          backgroundColor: showCustomBG ? backgroundColor : undefined,
          borderColor: showCustomBorder ? borderColor : undefined,
        }}
      >
        {dash ? (
          <div className="dash" style={{ opacity: checked ? 1 : 0 }} />
        ) : (
          <div className="check" style={{ opacity: checked ? 1 : 0 }} />
        )}
      </label>
    </div>
  );
};

export default CustomCheckbox;
