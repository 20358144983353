import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadForecastActions } from '../modules/loadForecast';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import Allocation from '../components/SlidingInfoPanel/Allocation/Allocation';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(loadForecastActions, dispatch),
});

const mapStateToProps = state => ({
  allocation: state.loadForecast,
  branch: state.network.branch,
  theme: state.global.theme,
  inEditMode: state.edit.inEditMode,
});

export default connect(mapStateToProps, mapDispatchToProps)(Allocation);
