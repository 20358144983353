import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import escapeRegExp from 'lodash/escapeRegExp';
import PropTypes from 'prop-types';
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from 'react-virtualized';

import Card from 'components/Card';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';

import TopNav from 'components/TopNav';
import { Request } from '@opusonesolutions/gridos-app-framework';
import { alphabetize } from 'helpers/utils';
import ConfirmModal from 'components/ConfirmModal';
import WorkspaceCard from './WorkspaceCard';
import './WorkspaceSelection.scss';

const WorkspaceSelection = ({ actions, isAuthEnabled, permissions, theme }) => {
  const _cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 70,
    }),
  );
  const location = useLocation();
  const history = useHistory();
  const listRef = useRef();
  const [deleteWorkspaceState, setDeleteWorkspaceState] = useState(asyncActionStates.INITIAL);
  const [openIndex, setOpenIndex] = useState(null);
  const [filterString, setFilterString] = useState('');
  const [workspaces, setWorkspaces] = useState([]);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState([]);
  const [confirmModalConfig, setConfirmModalConfig] = useState(null);

  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const canGoBack = queryParams.get('canGoBack');
  const currentWorkspace = queryParams.get('currentWorkspace');
  const { state } = location;

  useEffect(() => {
    if (state && state.search) {
      setFilterString(state.search);
    } else if (currentWorkspace) {
      setFilterString(currentWorkspace);
    }
  }, [state, currentWorkspace]);

  const loadWorkspaceList = () => {
    let didCancel = false;

    async function fetchWorkspaces() {
      const request = new Request('/api/workspace');
      try {
        const { data } = await request.get();
        if (didCancel) {
          // Cancelled before the request finished so do nothing
          return;
        }
        const sortedWorkspaces = alphabetize(data.workspaces);
        setWorkspaces(sortedWorkspaces);
      } catch (error) {}
    }

    fetchWorkspaces();
    return () => {
      didCancel = true;
    };
  };
  useEffect(loadWorkspaceList, []);

  const runFilter = () => {
    let filtered;
    if (filterString) {
      const escapedStr = escapeRegExp(filterString);
      const regex = new RegExp(`.*${escapedStr}.*`, 'i');
      filtered = workspaces.filter(workspace => workspace.match(regex));
    } else {
      filtered = workspaces;
    }
    setFilteredWorkspaces(filtered);
  };
  useEffect(runFilter, [filterString, workspaces]);

  const canCreateWorkspace = !isAuthEnabled || permissions.has('create_workspace');
  const canDeleteWorkspace = !isAuthEnabled || permissions.has('delete_workspace');
  const canDownloadWorkspace = !isAuthEnabled || permissions.has('download_CIM_xml');
  const canEditAnalytics = !isAuthEnabled || permissions.has('modify_analytics_configuration');
  const canEditCimDatastore = !isAuthEnabled || permissions.has('cim_datastore_admin_settings');
  const canViewUsers = !isAuthEnabled || permissions.has('view_users');

  const deleteWorkspace = async workspaceToDelete => {
    setDeleteWorkspaceState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace/${workspaceToDelete}`);

    try {
      await request.delete();
      // Mark as success and close the modal
      // Also remove the workspace that was deleted from the list
      setWorkspaces(workspaces.filter(w => w !== workspaceToDelete));
      setDeleteWorkspaceState(asyncActionStates.SUCCESS);
      setConfirmModalConfig(null);
    } catch (error) {
      setDeleteWorkspaceState(asyncActionStates.ERROR);
    }
  };
  const rowRenderer = ({ index, key, parent, style }) => {
    const workspace = filteredWorkspaces[index];
    return (
      <CellMeasurer
        cache={_cache.current}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        <div
          style={{
            ...style,
            padding: '0 10px 20px 0',
          }}
        >
          <WorkspaceCard
            canEditCimDatastore={canEditCimDatastore}
            canEditAnalytics={canEditAnalytics}
            canDelete={canDeleteWorkspace}
            canDownload={canDownloadWorkspace}
            canViewUsers={canViewUsers}
            permissions={permissions}
            displayAlertMessage={actions.displayAlertMessage}
            onDeleteWorkspace={wkspc => {
              setConfirmModalConfig({
                modalActive: true,
                closeModal: () => setConfirmModalConfig(null),
                deleteItem: () => deleteWorkspace(wkspc),
                title: 'Delete Workspace ?',
                modalBody: (
                  <>
                    <p className="modal-message__p">
                      Once a workspace has been deleted, it cannot be restored.
                    </p>
                    <p className="modal-message__p">
                      <span>Would you like to permanently remove </span>
                      <b>{wkspc}</b>?
                    </p>
                  </>
                ),
                theme,
              });
            }}
            onToggle={open => {
              setOpenIndex(open ? index : null);
              _cache.current.clearAll();
            }}
            workspaceCardExpanded={index === openIndex}
            theme={theme}
            workspace={workspace}
            setConfirmModalConfig={setConfirmModalConfig}
            onTabClick={() => {
              _cache.current.clearAll();
              listRef.current.recomputeRowHeights((openIndex || 0) + 1);
            }}
            workspaceCardStartExpanded={workspace === currentWorkspace}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <div className="workspace-selection">
      {canGoBack && <TopNav label="Back" />}
      <div className="page-header">
        <h1 className="page-title">Workspaces</h1>
        <div className="create-button">
          <PermissionDisabledTip
            title="Create New Workspace"
            hide={canCreateWorkspace}
            theme={theme}
          >
            <Button
              disabled={!canCreateWorkspace}
              onClick={() => history.push('/import-cim')}
              theme={theme}
            >
              Create New
            </Button>
          </PermissionDisabledTip>
        </div>
      </div>
      <Card className="import-cim-modal" hideTitle theme={theme}>
        <SearchInput
          placeholder="Search..."
          onChange={e => setFilterString(e.target.value)}
          value={filterString}
          theme={theme}
        />
        <div className="list-container">
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={ref => {
                  listRef.current = ref;
                }}
                className="gray-scrollbars"
                containerStyle={{ overflow: 'visible' }}
                deferredMeasurementCache={_cache.current}
                rowCount={filteredWorkspaces.length}
                rowHeight={_cache.current.rowHeight}
                width={width}
                height={height}
                rowRenderer={rowRenderer}
              />
            )}
          </AutoSizer>
        </div>
      </Card>
      <ConfirmModal {...confirmModalConfig} deleteStatus={deleteWorkspaceState} />
    </div>
  );
};

WorkspaceSelection.propTypes = {
  actions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
};

export default WorkspaceSelection;
