import React, { useState, useEffect, FunctionComponent, CSSProperties } from 'react';
import CustomCheckbox from 'components/CustomCheckbox';
import { CimProperty } from 'types/cim';
import escapeRegExp from 'lodash/escapeRegExp';
import classNames from 'classnames';
import { sortByName } from 'helpers/utils';
import StyledVirtualizedList from 'components/StyledVirtualizedList';
import InstanceTile from './InstanceTile';
import 'components/HierarchyView/HierarchyView.scss';

const nameOrIdMatchesFilter = (filter: string, { id, name }: CimProperty) => {
  if (!filter) return true;
  if (!id && !name) return false;

  const regex = new RegExp(`.*${escapeRegExp(filter)}.*`, 'i');
  const str = name || id[0] || id;
  return str && str.match(regex);
};

type InstanceList = { properties: CimProperty; id: string; name: string }[];
const filterInstances = (
  assets: InstanceList,
  filter: string,
  showOnlyVisible: boolean,
  assetsInView: string[],
): InstanceList => {
  const filteredAssets = [];
  const assetSet = new Set(assetsInView);
  for (let i = 0; i < assets.length; i += 1) {
    const asset = assets[i];
    if (nameOrIdMatchesFilter(filter, asset.properties)) {
      if (showOnlyVisible && assetSet.has(asset.properties.id)) {
        filteredAssets.push(asset);
      }
      if (!showOnlyVisible) {
        filteredAssets.push(asset);
      }
    }
  }

  return filteredAssets.sort(sortByName);
};

type InstanceDropdownProps = {
  feeders: { [key: string]: string }[];
  selectedAssetID: string | undefined | null;
  selectedAssetViewModelClass: string | null;
  setSelectedAssetID: (id: string | null) => void;
  onInstanceHover: (id: string | null) => void;
  filterString?: string;
  showOnlyVisible?: boolean;
  assetsInView: string[];
  setAssetTypeVisibility: (type: string, visible: boolean) => void;
  assetTypeVisibility: { [key: string]: boolean };
  inEditMode: boolean;
  assetInstance: {
    name: string;
    id: string;
    children: InstanceList;
  };
  openAssetTiles: { [key: string]: boolean };
  toggleAssetTile: (arg: { [key: string]: boolean }) => void;
};

/**
 * Creates a toggleable menu that accepts a CIM type and filters in view instance
 * data to display all items of that type as a list.
 */
const InstanceDropdown: FunctionComponent<InstanceDropdownProps> = ({
  assetInstance,
  feeders,
  selectedAssetViewModelClass,
  filterString = '',
  showOnlyVisible = false,
  assetsInView,
  setAssetTypeVisibility,
  assetTypeVisibility,
  inEditMode,
  onInstanceHover,
  selectedAssetID,
  setSelectedAssetID,
  openAssetTiles,
  toggleAssetTile,
}) => {
  /* State */
  const [inFeeder, setInFeeder] = useState<InstanceList>([]);
  const [filteredInstances, setFilteredInstances] = useState<InstanceList>([]);
  const instanceList = assetInstance.children;
  const type = assetInstance.name;
  const opened = openAssetTiles?.[type];
  // Handle updates to asset data
  useEffect(() => {
    const newInFeeder = instanceList.filter(
      item => !item.properties.feeder || feeders.some(({ id }) => item.properties.feeder === id),
    );
    setInFeeder(newInFeeder);
    setFilteredInstances(filterInstances(newInFeeder, filterString, showOnlyVisible, assetsInView));
  }, [feeders, instanceList, filterString, showOnlyVisible, assetsInView]);

  // Don't render dropdown if there are no instances in the selected feeders
  if (inFeeder.length === 0) return null;

  // Only render the dropdown if there are results to display
  // Min height is needed to ensure nested scroll area renders correctly
  // const height = Math.min(200, filteredInstances.length * 32);
  const typeSelected = selectedAssetViewModelClass ? selectedAssetViewModelClass === type : false;
  // Styles for open and selected states
  const selectedStyle = typeSelected ? 'active-tile' : '';
  const height = Math.min(200, filteredInstances.length * 32);
  const rowRenderer = ({ index, style }: { index: number; style: CSSProperties | undefined }) => {
    const instance = filteredInstances[index];
    const { id } = instance;
    return (
      <InstanceTile
        instance={instance}
        selected={selectedAssetID === id}
        style={style}
        key={id}
        onClick={setSelectedAssetID}
        onHover={onInstanceHover}
        icon={
          <div
            role="button"
            onClick={() => {}}
            className={classNames({
              'tree-icon': true,
              'empty-icon': true,
            })}
            tabIndex={0}
            onKeyUp={() => {}}
            id="node-icon"
          >
            close-icon
          </div>
        }
      />
    );
  };
  return (
    <div className="instance-hierarchy hierarchy-tree-view">
      <div key={type} className="asset-type-container">
        <div className="tree-node">
          <div
            role="button"
            onClick={() => toggleAssetTile({ [type]: !opened })}
            className={classNames({
              'tree-icon': true,
              'open-icon': true,
            })}
            tabIndex={0}
            onKeyUp={() => {}}
            id="tree-icon"
          >
            close-icon
          </div>
          <div className={`asset-type-container-header ${selectedStyle}`}>
            {!inEditMode && (
              <CustomCheckbox
                // To avoid having to define all new types in the default state,
                // assume that no state for this type means that it is visible.
                id={`checkbox-${type}`}
                checked={assetTypeVisibility[type] === undefined || assetTypeVisibility[type]}
                disabled={filteredInstances.length === 0}
                onClick={e => setAssetTypeVisibility(type, (e.target as HTMLInputElement).checked)}
                type="primary"
              />
            )}
            <button
              className="asset-type-tile"
              onClick={() => {}}
              disabled={filteredInstances.length === 0}
              type="button"
            >
              <div className="asset-type-tile-contents">
                <p className="asset-type-tile-contents__p">
                  <span>
                    <span className="asset-type">{type} </span>
                    <span className="asset-count">{`${filteredInstances.length}/${inFeeder.length}`}</span>
                  </span>
                </p>
              </div>
            </button>
          </div>
        </div>
        {opened && (
          <ul style={{ height: `${height}px`, width: '240px' }} className="asset-type-list">
            <StyledVirtualizedList
              scrollbarClassName="instance-dropdown-scrollbar"
              height={height}
              width={235}
              color="rgba(6,175,168,0.5)"
              overscanRowCount={10}
              rowCount={filteredInstances.length}
              rowHeight={32}
              rowRenderer={rowRenderer}
              autoHide={false}
            />
          </ul>
        )}
      </div>
    </div>
  );
};

export default InstanceDropdown;
