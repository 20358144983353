import CableInfo from './CableInfo';

class ConcentricNeutralCableInfo extends CableInfo {
  get diameterOverNeutral() {
    return this.attributes['ConcentricNeutralCableInfo.diameterOverNeutral'];
  }

  get neutralStrandCount() {
    return this.attributes['ConcentricNeutralCableInfo.neutralStrandCount'];
  }

  get neutralStrandGmr() {
    return this.attributes['ConcentricNeutralCableInfo.neutralStrandGmr'];
  }

  get neutralStrandRadius() {
    return this.attributes['ConcentricNeutralCableInfo.neutralStrandRadius'];
  }

  get neutralStrandRDC20() {
    return this.attributes['ConcentricNeutralCableInfo.neutralStrandRDC20'];
  }
}

export default ConcentricNeutralCableInfo;
