import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ImportNetwork from './routes/ImportNetwork';
import ReviewNetwork from './routes/ReviewNetwork';
import ValidateNetwork from './routes/ValidateNetwork';

const ImportRoutes = () => (
  <Switch>
    <Route
      exact
      path="/:workspace/:branch/import"
      render={({
        match: {
          params: { branch, workspace },
        },
        history,
      }) => {
        history.push(`/${workspace}/${branch}/import/import`);
      }}
    />
    <Route path="/:workspace/:branch/import/import">
      <ImportNetwork />
    </Route>
    <Route path="/:workspace/:branch/import/review">
      <ReviewNetwork />
    </Route>
    <Route path="/:workspace/:branch/import/validate">
      <ValidateNetwork />
    </Route>
  </Switch>
);

export default ImportRoutes;
