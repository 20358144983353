/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Creates a toggle switch based on Material design trends.
 */
const ToggleSwitch = props => (
  <div className={`toggleswitch ${props.theme}`}>
    <input
      type="checkbox"
      name="toggleswitch"
      className="toggleswitch-checkbox"
      id={props.refId}
      checked={props.checked}
      onChange={props.onChange}
      disabled={props.disabled}
    />
    <label
      className={`toggleswitch-label ${props.disabled ? 'toggleswitch-disabled' : ''}`}
      htmlFor={props.refId}
    />
  </div>
);

ToggleSwitch.defaultProps = {
  checked: false,
  disabled: false,
  onChange: null,
  theme: 'light',
};

ToggleSwitch.propTypes = {
  refId: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  theme: PropTypes.string,
};

export default ToggleSwitch;
