import React, { FunctionComponent, useState } from 'react';
import GridLayout from 'layouts/GridLayout';
import Modal from 'components/Modal';
import { useRequestEffect, useRequest } from '@opusonesolutions/gridos-app-framework';
import LoadingSkeleton from 'components/LoadingSkeleton';
import Accordion from 'components/Accordion';
import RadioButtonGroup from 'components/RadioButtonGroup';
import _ from 'lodash';

type LoadShiftingModalProps = {
  handleLoadShiftingModal: () => void;
  workspace: string;
  editBranch: string;
  selectedProject: string | null;
  loadNetworkData: (workspace: string, branch: string) => Promise<void>;
};

type backupNodestoLoadsType = {
  backup_nodes: { id: string; name: string }[];
  load: { id: string; name: string };
}[];

type shiftLoadType = { load_id: string; node_id: string }[];

const LoadShiftingModal: FunctionComponent<LoadShiftingModalProps> = ({
  handleLoadShiftingModal,
  workspace,
  editBranch,
  selectedProject = null,
  loadNetworkData,
}) => {
  const [loadShiftingData, setLoadShiftingData] = useState<shiftLoadType>([]);
  const url = `/api/workspace/${workspace}/branch/${editBranch}/asset/energy_consumer/backup_nodes`;
  const {
    data: loadShiftingResponseData,
    loading,
    refetch,
  } = useRequestEffect<backupNodestoLoadsType>({
    url,
    method: 'get',
    refetchOnChange: [workspace, editBranch],
    blockRequest: () => !(workspace && editBranch),
    initialData: [],
    onSuccess: data => {
      const backupNodesWithLoads =
        data &&
        data.length &&
        data
          .filter(obj => obj.backup_nodes?.length > 0)
          ?.map(type => ({ load_id: type.load.id, node_id: type.backup_nodes[0].id }));
      setLoadShiftingData(backupNodesWithLoads || []);
    },
  });
  const loadShiftingResponse = loadShiftingResponseData?.filter(
    obj => obj.backup_nodes?.length > 0,
  );
  const { makeRequest: sendReqToShiftLoad, loading: requestingToShiftLoad } = useRequest(url);
  const [networkLoading, setNetworkLoading] = useState(false);
  const shiftLoadRequest = async () => {
    try {
      if (workspace && editBranch) {
        await sendReqToShiftLoad({
          method: 'post',
          body: {
            backup_nodes: loadShiftingData,
            project_id: selectedProject,
          },
          onSuccess: async () => {
            setNetworkLoading(true);
            await loadNetworkData(workspace, editBranch);
            setNetworkLoading(false);
            handleLoadShiftingModal();
          },
          onError: () => {
            refetch();
          },
          toast: {
            error: "Failed to shift Load to it's backup node",
            settings: {
              autoDismiss: true,
            },
          },
        });
      }
    } catch (error) {}
  };
  let selectedNodeTypeByAllLoads = '';
  if (loadShiftingResponse?.length === loadShiftingData.length) {
    selectedNodeTypeByAllLoads = 'backup';
  } else if (!loadShiftingData.length) {
    selectedNodeTypeByAllLoads = 'primary';
  }
  const shifting = requestingToShiftLoad || networkLoading;
  return (
    <div className="load-shifting-modal">
      <Modal
        active
        title="Load Shifting"
        subtitle="Swap loads from their primary node connection to their backup node connection"
        onConfirm={() => shiftLoadRequest()}
        onCancel={() => handleLoadShiftingModal()}
        modalType="primary"
        width="800px"
        height="auto"
        labels={{
          confirm: shifting ? <i className="material-icons rotate">refresh</i> : 'Submit',
          cancel: 'Discard',
        }}
        disableConfirm={!loadShiftingResponse?.length || shifting}
        disableCancel={shifting}
        disabledOnClickOverlay={shifting}
        scrollBody
      >
        <GridLayout>
          {loadShiftingResponse && loadShiftingResponse?.length > 0 ? (
            <div>
              <div className="asset-name" />
              <div className="radio-button-group controlmode-types grid-columns auto small-gap">
                <span />
                <span>Primary node</span>
                <span>Backup node</span>
              </div>
            </div>
          ) : (
            !loading && <span>No Loads found having backup node.</span>
          )}
          {loading ? (
            <LoadingSkeleton template="line" width={100} count={4} />
          ) : (
            loadShiftingResponse &&
            loadShiftingResponse?.length > 0 && (
              <Accordion
                tabs={[
                  {
                    key: 1,
                    title: 'All Loads',
                    tabHeader: (
                      <div className="radio-button-group" style={{ marginLeft: '10px' }}>
                        <RadioButtonGroup
                          options={[{ id: 'primary' }, { id: 'backup' }]}
                          id="all-loads-selector"
                          value={selectedNodeTypeByAllLoads}
                          listType="row"
                          onChange={({ target }: { [key: string]: { value: string } }) => {
                            if (target.value === 'primary') {
                              setLoadShiftingData([]);
                            } else if (target.value === 'backup') {
                              const loadShiftingAllData =
                                loadShiftingResponse?.map(type => ({
                                  load_id: type.load.id,
                                  node_id: type.backup_nodes[0].id,
                                })) || [];
                              setLoadShiftingData(loadShiftingAllData);
                            }
                          }}
                          radioType="primary"
                          style={{ gridTemplateColumns: '1fr 1fr', display: 'grid' }}
                        />
                      </div>
                    ),
                    tabBody: loadShiftingResponse?.map(loadObj => {
                      const loadWithBackupNode = loadShiftingData.filter(
                        loadType => loadType.load_id === loadObj.load.id,
                      );
                      const tooltip = `Backup node: ${loadObj.backup_nodes[0].name}`;
                      return (
                        <div className="asset-group" key={loadObj.load.id}>
                          <div className="asset-name">{loadObj.load.name}</div>
                          <div className="radio-button-group">
                            <RadioButtonGroup
                              options={[{ id: 'primary' }, { id: 'backup', tooltip }]}
                              id={`asset-selector-${loadObj.load.id}`}
                              key={`asset-selector-${loadObj.load.id}`}
                              value={loadWithBackupNode.length ? 'backup' : 'primary'}
                              listType="row"
                              onChange={({ target }: { [key: string]: { value: string } }) => {
                                if (target.value === 'primary' && loadWithBackupNode.length) {
                                  setLoadShiftingData(
                                    _.without(loadShiftingData, loadWithBackupNode[0]),
                                  );
                                } else if (
                                  target.value === 'backup' &&
                                  !loadWithBackupNode.length
                                ) {
                                  setLoadShiftingData([
                                    ...loadShiftingData,
                                    {
                                      load_id: loadObj.load.id,
                                      node_id: loadObj.backup_nodes[0].id,
                                    },
                                  ]);
                                }
                              }}
                              radioType="primary"
                              style={{ gridTemplateColumns: '1fr 1fr', display: 'grid' }}
                            />
                          </div>
                        </div>
                      );
                    }),
                  },
                ]}
              />
            )
          )}
        </GridLayout>
      </Modal>
    </div>
  );
};

export default LoadShiftingModal;
