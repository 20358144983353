import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import browserHistory from 'routes/history';

import Breadcrumbs from 'components/Breadcrumbs';
import { Request } from '@opusonesolutions/gridos-app-framework';

import { ErrorIcon, WarningIcon } from '../../components/Icons';
import ErrorTypeLeftRail from './components/ErrorTypeLeftRail';
import './ErrorType.scss';

const ErrorType = ({ match }) => {
  const { branch, type, id, workspace } = match.params;

  const [loading, setLoading] = useState(true);
  const [leftRailData, setLeftRailData] = useState([]);
  const [selectedID, setSelectedID] = useState(id);
  const [validationData, setValidationData] = useState(null);

  useEffect(() => {
    let didCancel = false;

    async function loadValidationErrors() {
      const request = new Request(`/api/workspace/${workspace}/branch/${branch}/validate/${type}`);
      const { data } = await request.get();
      if (didCancel) {
        // Cancelled before the request finished so do nothing
        return;
      }

      const railData = Object.entries(data.data).map(([inst_id, { messages, name }]) => {
        const hasError = messages.some(m => m.severity === 'ERROR');
        const hasWarning = messages.some(m => m.severity === 'WARNING');

        return {
          hasError,
          hasWarning,
          id: inst_id,
          name,
        };
      });

      const sorter = Intl.Collator(undefined, {
        numeric: true, // make sure 10-foo comes after 2-bar
        sensitivity: 'base', // ignore case
      });
      railData.sort((a, b) => sorter.compare(a.name, b.name));

      setValidationData(data);
      setLeftRailData(railData);

      if (selectedID && !data.data[id]) {
        // Have an id, but its no longer valid
        setSelectedID(null);
      }
      setLoading(false);
    }

    loadValidationErrors();
    return () => {
      didCancel = true;
    };
  }, [branch, type, workspace, id, selectedID]);

  useEffect(() => {
    // When the select ID changes, we need to update the route
    const baseURL = `/${workspace}/${branch}/import/validate/type/${type}`;

    if (selectedID) {
      browserHistory.push(`${baseURL}/${selectedID}`);
    } else {
      browserHistory.push(baseURL);
    }
  }, [selectedID, branch, type, workspace]);

  useEffect(() => {
    if (!id) {
      // Happens when user clears the selection using breadcrumb
      setSelectedID(null);
    }
  }, [id]);

  const parents = [
    {
      to: `/${workspace}/${branch}/import/validate`,
      label: <h2 className="title">Validation</h2>,
    },
  ];
  let currentHeader = validationData?.name;

  if (selectedID && validationData) {
    parents.push({
      to: `/${workspace}/${branch}/import/validate/type/${type}`,
      label: <h2 className="title">{validationData?.name}</h2>,
    });
    currentHeader = validationData.data[selectedID]?.name;
  }

  return (
    <div className="error-type">
      {loading && (
        <i className="material-icons rotate" style={{ fontSize: 70 }}>
          refresh
        </i>
      )}
      {!loading && (
        <>
          <ErrorTypeLeftRail
            data={leftRailData}
            selectedID={selectedID}
            setSelectedID={setSelectedID}
          />
          <div className="content">
            <div className="header">
              <Breadcrumbs parents={parents} separator="/" currentHeader={currentHeader} />
            </div>
            {selectedID && (
              <ul className="message-list">
                {validationData.data[selectedID].messages.map(message => (
                  <li className="message" key={`message-${message.message}`}>
                    {message.severity === 'ERROR' && <ErrorIcon />}
                    {message.severity === 'WARNING' && <WarningIcon />}
                    <span className="message-text">{message.message}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
    </div>
  );
};

ErrorType.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ErrorType);
