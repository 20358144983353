import React from 'react';
import PropTypes from 'prop-types';

/**
 * Creates a loading spinner. There are two variations available:
 *   - circles : spinner made up of circle that change opacity
 *   - bars : 3 vertical bars that change size
 */
const LoadingSpinner = props => <div className={`loading-spinner--${props.type}`}>Loading...</div>;

LoadingSpinner.defaultProps = {
  type: 'circles',
};

LoadingSpinner.propTypes = {
  type: PropTypes.oneOf(['circles', 'bars']),
};

export default LoadingSpinner;
