/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ScheduleValidations.scss';

const ScheduleValidations = ({ theme, variables, panelValues, assetType }) => {
  const [hovered, setHovered] = useState(false);

  const showErrors = () => {
    if (!hovered) {
      setHovered(true);
    }
  };
  const hideErrors = () => {
    if (hovered) {
      setHovered(false);
    }
  };

  // eslint-disable-next-line arrow-body-style
  const renderViolation = (validations, validationID, msg) => {
    if (validations[validationID]) {
      return (
        <div className={`schedule-validations-list-item validation-id-${validationID}`}>
          <p> {msg} </p>
        </div>
      );
    }
    return null;
  };

  const panelValueAtPhase = (panelValueName, phase) => {
    if (panelValues && panelValueName in panelValues && panelValues[panelValueName] !== undefined) {
      if (typeof panelValues[panelValueName] === 'number') {
        // it its just a number we must treat it as a balanced value
        if (phase === '') {
          return panelValues[panelValueName];
        }
      } else {
        if (phase === '') {
          if ('ABC' in panelValues[panelValueName]) {
            return panelValues[panelValueName].ABC;
          }
          if ('ABCN' in panelValues[panelValueName]) {
            return panelValues[panelValueName].ABCN;
          }
          if (
            'A' in panelValues[panelValueName] &&
            'B' in panelValues[panelValueName] &&
            'C' in panelValues[panelValueName] &&
            panelValues[panelValueName].A === panelValues[panelValueName].B &&
            panelValues[panelValueName].B === panelValues[panelValueName].C
          ) {
            return panelValues[panelValueName].A;
          }
        }
        return panelValues[panelValueName][phase];
      }
    }
    return undefined;
  };

  const phaseString = phase => (phase ? `_${phase}` : '');

  const violationTest = (
    variableName,
    variableSuffix,
    panelValueName,
    phase,
    valueOffsetName,
    compareFn,
  ) => {
    const panelValue = panelValueAtPhase(panelValueName, phase);
    let offsetValue = 0;
    if (valueOffsetName) {
      // offsets come from the panel but they are added to the variable
      offsetValue = panelValueAtPhase(valueOffsetName, phase) || 0;
    }
    let violates = false;
    if (panelValue !== undefined) {
      const variableKey = `${variableName}${variableSuffix}${phaseString(phase)}`;
      if (variableKey in variables) {
        const assetValue = variables[variableKey].value + offsetValue;
        try {
          if (compareFn(assetValue, panelValue)) {
            violates = true;
          }
        } catch (err) {
          violates = false;
        }
      }
    }
    return violates;
  };

  const violatesMax = (variableName, panelValueName, phase, valueOffsetName) =>
    violationTest(
      variableName,
      '_max',
      panelValueName,
      phase,
      valueOffsetName,
      (assetVal, panelVal) => assetVal > panelVal,
    );

  const violatesMin = (variableName, panelValueName, phase, valueOffsetName) =>
    violationTest(
      variableName,
      '_min',
      panelValueName,
      phase,
      valueOffsetName,
      (assetVal, panelVal) => assetVal < panelVal,
    );

  const violatesAbsMax = (variableName, panelValueName, phase, valueOffsetName) =>
    violationTest(
      variableName,
      '_max',
      panelValueName,
      phase,
      valueOffsetName,
      (assetVal, panelVal) => Math.abs(assetVal) > Math.abs(panelVal),
    );

  const violatesAbsMin = (variableName, panelValueName, phase, valueOffsetName) =>
    violationTest(
      variableName,
      '_min',
      panelValueName,
      phase,
      valueOffsetName,
      (assetVal, panelVal) => Math.abs(assetVal) < Math.abs(panelVal),
    );

  const violatesAbsMaxIfPositive = (variableName, panelValueName, phase, valueOffsetName) =>
    violationTest(
      variableName,
      '_max',
      panelValueName,
      phase,
      valueOffsetName,
      (assetVal, panelVal) => (assetVal > 0 ? Math.abs(assetVal) > Math.abs(panelVal) : false),
    );

  const violatesAbsMinIfNegative = (variableName, panelValueName, phase, valueOffsetName) =>
    violationTest(
      variableName,
      '_min',
      panelValueName,
      phase,
      valueOffsetName,
      (assetVal, panelVal) => (assetVal < 0 ? Math.abs(assetVal) > Math.abs(panelVal) : false),
    );

  const forAllPhases = (
    violationTestFn,
    variableName,
    panelValueName,
    valueOffsetName = undefined,
  ) =>
    violationTestFn(variableName, panelValueName, '', valueOffsetName) ||
    violationTestFn(variableName, panelValueName, 'A', valueOffsetName) ||
    violationTestFn(variableName, panelValueName, 'B', valueOffsetName) ||
    violationTestFn(variableName, panelValueName, 'C', valueOffsetName);

  const violatesMaxTapPos = () => forAllPhases(violatesMax, 'normalStep', 'highStep');
  const violatesMinTapPos = () => forAllPhases(violatesMin, 'normalStep', 'lowStep');

  const violatesPVUnitRealPowerGeneration = () =>
    forAllPhases(violatesAbsMaxIfPositive, 'p', 'pvUnitMinP'); // |pvUnitMinP| is Generation/Discharge max from units
  const violatesRealPowerGeneration = () => forAllPhases(violatesAbsMaxIfPositive, 'p', 'minP'); // |minP| is Generation/Discharge

  const violatesBessInverterRateOfDischargeReal = () =>
    forAllPhases(violatesAbsMinIfNegative, 'p', 'minP');
  const violatesBessInverterRateOfChargeReal = () =>
    forAllPhases(violatesAbsMaxIfPositive, 'p', 'maxP');

  const violatesBessInverterRateOfDischargeReactive = () =>
    forAllPhases(violatesAbsMinIfNegative, 'q', 'minQ');
  const violatesBessInverterRateOfChargeReactive = () =>
    forAllPhases(violatesAbsMaxIfPositive, 'q', 'maxQ');

  const violatesBessUnitMaxChargeLevel = () =>
    forAllPhases(violatesMax, 'charge_state', 'maxEnergy', 'initalEnergy');
  const violatesBessUnitMinChargeLevel = () =>
    forAllPhases(violatesMin, 'charge_state', 'minEnergy', 'initalEnergy');

  const violatesBessUnitRateOfDischarge = () =>
    forAllPhases(violatesAbsMinIfNegative, 'p', 'sumMinP');
  const violatesBessUnitRateOfCharge = () => forAllPhases(violatesAbsMaxIfPositive, 'p', 'sumMaxP');

  const violatesMaxApparentPower = () => forAllPhases(violatesAbsMax, 's', 'ratedS');
  const violatesRatedPowerFactor = () => forAllPhases(violatesAbsMin, 'pf', 'ratedPowerFactor');

  const panelValueUnbalanced = panelValueName =>
    panelValues[panelValueName] === undefined
      ? undefined
      : typeof panelValues[panelValueName] !== 'number' &&
        (('A' in panelValues[panelValueName] && panelValues[panelValueName].A !== null) ||
          ('B' in panelValues[panelValueName] && panelValues[panelValueName].B !== null) ||
          ('C' in panelValues[panelValueName] && panelValues[panelValueName].C !== null));

  const violatesBalancedUnbalanced = () => {
    let scheduleUnbalanced = false;
    Object.keys(variables).forEach(key => {
      if (key.endsWith('_A') || key.endsWith('_B') || key.endsWith('_C')) {
        scheduleUnbalanced = true;
      }
    });
    const panelUnbalanced =
      panelValues &&
      (panelValues.isBalanced !== undefined
        ? !panelValues.isBalanced
        : panelValueUnbalanced('highStep') ||
          panelValueUnbalanced('lowStep') ||
          panelValueUnbalanced('minP') ||
          panelValueUnbalanced('ratedPowerFactor') ||
          panelValueUnbalanced('ratedS'));
    return panelUnbalanced !== undefined && scheduleUnbalanced !== panelUnbalanced;
  };

  const generateValidations = () => {
    const validations = {};
    if (violatesMaxTapPos()) {
      validations.violatesMaxTapPos = true;
    }
    if (violatesMinTapPos()) {
      validations.violatesMinTapPos = true;
    }
    if (assetType === 'InverterPV') {
      // if we have a battery or pv want to give a different validation message for clarity
      if (violatesPVUnitRealPowerGeneration()) {
        validations.violatesPVUnitRealPowerGeneration = true;
      }
    } else if (assetType === 'Battery') {
      // if we have a battery or pv want to give a different validation message for clarity
      if (violatesBessInverterRateOfDischargeReal()) {
        validations.violatesBessInverterRateOfDischargeReal = true;
      }
      if (violatesBessInverterRateOfChargeReal()) {
        validations.violatesBessInverterRateOfChargeReal = true;
      }
      if (violatesBessInverterRateOfDischargeReactive()) {
        validations.violatesBessInverterRateOfDischargeReactive = true;
      }
      if (violatesBessInverterRateOfChargeReactive()) {
        validations.violatesBessInverterRateOfChargeReactive = true;
      }
      if (violatesBessUnitRateOfDischarge()) {
        validations.violatesBessUnitRateOfDischarge = true;
      }
      if (violatesBessUnitRateOfCharge()) {
        validations.violatesBessUnitRateOfCharge = true;
      }
      if (violatesBessUnitMaxChargeLevel()) {
        validations.violatesBessUnitMaxChargeLevel = true;
      }
      if (violatesBessUnitMinChargeLevel()) {
        validations.violatesBessUnitMinChargeLevel = true;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (violatesRealPowerGeneration()) {
        validations.violatesRealPowerGeneration = true;
      }
    }
    if (violatesMaxApparentPower()) {
      validations.violatesMaxApparentPower = true;
    }
    if (assetType !== 'Battery') {
      // RPF doesnt apply to batteries
      if (violatesRatedPowerFactor()) {
        validations.violatesRatedPowerFactor = true;
      }
    }
    if (violatesBalancedUnbalanced()) {
      validations.violatesBalancedUnbalanced = true;
    }
    return validations;
  };
  const validations = panelValues && variables ? generateValidations() : {};
  const validationsCount = Object.keys(validations).length;
  return (
    <>
      {validationsCount > 0 && (
        <div
          className={`schedule-validations-icon ${theme}`}
          onMouseEnter={() => showErrors()}
          onMouseOver={() => showErrors()}
          onMouseLeave={() => hideErrors()}
        >
          <i className="material-icons">warning</i>
          <span className="schedule-validations-title">{`${validationsCount} ${
            validationsCount === 1 ? 'warning' : 'warnings'
          }`}</span>
        </div>
      )}
      {hovered && validationsCount > 0 && (
        <div className={`schedule-validations-container ${theme}`}>
          <div className="schedule-validations-list">
            {renderViolation(
              validations,
              'violatesBalancedUnbalanced',
              'Balanced/Unbalanced schedule mismatch',
            )}
            {renderViolation(
              validations,
              'violatesMaxTapPos',
              'Tap position maximum limit violated',
            )}
            {renderViolation(
              validations,
              'violatesMinTapPos',
              'Tap position minimum limit violated',
            )}
            {renderViolation(
              validations,
              'violatesPVUnitRealPowerGeneration',
              'Maximum real power generation limit of photovoltaic units violated',
            )}
            {renderViolation(
              validations,
              'violatesBessUnitRateOfDischarge',
              'Maximum rate of discharge of batteries violated',
            )}
            {renderViolation(
              validations,
              'violatesBessInverterRateOfDischargeReal',
              'Maximum rate of discharge (Real) of inverter violated',
            )}
            {renderViolation(
              validations,
              'violatesBessInverterRateOfDischargeReactive',
              'Maximum rate of discharge (Reactive) of inverter violated',
            )}
            {renderViolation(
              validations,
              'violatesRealPowerGeneration',
              'Maximum real power generation limit violated',
            )}
            {renderViolation(
              validations,
              'violatesBessUnitRateOfCharge',
              'Maximum rate of charge of batteries violated',
            )}
            {renderViolation(
              validations,
              'violatesBessInverterRateOfChargeReal',
              'Maximum rate of charge (Real) of inverter violated',
            )}
            {renderViolation(
              validations,
              'violatesBessInverterRateOfChargeReactive',
              'Maximum rate of charge (Reactive) of inverter violated',
            )}
            {renderViolation(
              validations,
              'violatesMaxApparentPower',
              'Maximum apparent power limit violated',
            )}
            {renderViolation(
              validations,
              'violatesRatedPowerFactor',
              'Rated power factor limit violated',
            )}
            {renderViolation(
              validations,
              'violatesBessUnitMaxChargeLevel',
              'Maximum charge level of Battery violated ',
            )}
            {renderViolation(
              validations,
              'violatesBessUnitMinChargeLevel',
              'Minimum charge level of Battery violated ',
            )}
          </div>
        </div>
      )}
    </>
  );
};

ScheduleValidations.defaultProps = {
  theme: 'light',
  panelValues: null,
};

ScheduleValidations.propTypes = {
  theme: PropTypes.string,
  variables: PropTypes.object.isRequired,
  panelValues: PropTypes.object,
  assetType: PropTypes.string.isRequired,
};

export default ScheduleValidations;
