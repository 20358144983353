import { connect } from 'react-redux';
import withAuth from 'helpers/withAuth';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ServiceDashboard from '../components/ServiceDashboard';

const mapStateToProps = state => ({
  theme: state.global.theme,
});

export default withAuth(connect(mapStateToProps)(ServiceDashboard));
