import React, { FunctionComponent, useContext } from 'react';
import ResultsCard from 'components/ResultsCard';
import Button from 'components/Button';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { ThemeProp } from 'types/index';
import { ScenarioTypes } from 'helpers/scenarios';
import AssetSchedule from '../../Network/components/SlidingInfoPanel/AssetPanel/templates/partials/AssetSchedule';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import { views } from '../helpers/ScenarioGenerationHelpers';

type SchedulesProps = {
  workspace: string;
  branch: string;
  theme: ThemeProp;
};

const Schedules: FunctionComponent<SchedulesProps> = ({ theme, workspace, branch }) => {
  const {
    feedersInScenario,
    feedersInSceLoading,
    setView,
    setSelectedContainer,
    scenarioRange,
    selectedScenario: scenario,
    timeBarZoomLevel,
    timeSpanLoading,
  } = useContext(ScenarioGenerationContext);
  return (
    <div data-test="schedules" className={`schedules ${theme}`}>
      {feedersInScenario && feedersInScenario?.length !== 0 && scenario && (
        <div className="three-one-one grid-columns margin-10" id="scedule-graph-section">
          <div>
            <div className="auto-fit grid-columns label-light header">
              <div>Feeder Schedules</div>
              <div>Start date & time</div>
              <div>End date & time</div>
            </div>
          </div>
          <div className="label-light header">Asset Schedules</div>
          <div className="label-light header">Dynamic rating</div>
        </div>
      )}
      {feedersInSceLoading || timeSpanLoading ? (
        <div className="four-two grid-columns">
          <LoadingSkeleton template="square" height={225} width={303} theme={theme} />
          <LoadingSkeleton template="square" height={225} width={200} theme={theme} />
        </div>
      ) : (
        feedersInScenario?.map(container => (
          <div className="three-one-one grid-columns" key={container.id}>
            <ResultsCard theme={theme} withBorder={false} className="schedules-card">
              <div className="auto-fit grid-columns">
                <div className="results-card-header">
                  <span className="title">{container.name}</span>
                </div>
                <div className="timerange-start">
                  {scenarioRange?.start?.format('DD MMM, YYYY HH:mm')}
                </div>
                <div className="timerange-end">
                  {scenarioRange?.end?.format('DD MMM, YYYY HH:mm')}
                </div>
              </div>
              {workspace &&
                branch &&
                scenario &&
                scenario.value &&
                scenarioRange.start &&
                scenarioRange.end &&
                timeBarZoomLevel &&
                scenario.type === ScenarioTypes.timeseries && (
                  <div className="sliding-panel">
                    <AssetSchedule
                      workspace={workspace}
                      branch={branch}
                      scenario={scenario?.value}
                      scenarioType={scenario?.type}
                      asset={{ id: container.id, class: 'feeder' }}
                      scheduleType="Feeder"
                      timeRange={{
                        start: scenarioRange.start || null,
                        end: scenarioRange.start || null,
                      }}
                      maxRange={scenarioRange}
                      timeBarZoomLevel={timeBarZoomLevel}
                      editable={false}
                      expanded
                      displayActionIcons={false}
                    />
                  </div>
                )}
            </ResultsCard>
            <ResultsCard theme={theme} withBorder={false} className="asset-schedules">
              <Button
                type="text"
                onClick={() => {
                  setSelectedContainer(container.id);
                  setView(views.assetSchedule);
                }}
                className="asset-schedule-link"
              >
                View Asset Schedules
              </Button>
            </ResultsCard>
            <ResultsCard theme={theme} withBorder={false} className="asset-schedules">
              <Button
                type="text"
                onClick={() => {
                  setSelectedContainer(container.id);
                  setView(views.seasonalRatings);
                }}
                className="asset-schedule-link"
              >
                View Dynamic Ratings
              </Button>
            </ResultsCard>
          </div>
        ))
      )}
    </div>
  );
};

export default Schedules;
