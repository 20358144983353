import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import TextInput from 'components/TextInput';
import Select from 'components/Select';
import { percent } from 'helpers/units';
import { isDefined } from 'helpers/utils';
import NumberInput from 'components/NumberInput';
import './ReliabilityMetrics.scss';

const restorationTimeUnits = [
  { label: 'minutes', value: 'm' },
  { label: 'hours', value: 'h' },
  { label: 'days', value: 'd' },
];
const defaultShiftFactorValues = {
  1: {
    shiftFactor: 20,
    enabled: true,
    separator: '',
    startRangeVal: 0,
    endRangeVal: 'NA',
  },
  2: {
    shiftFactor: 15,
    enabled: true,
    separator: ' - ',
    startRangeVal: 0,
    endRangeVal: 3,
  },
  3: {
    shiftFactor: 10,
    enabled: true,
    separator: ' - ',
    startRangeVal: 3,
    endRangeVal: 6,
  },
  4: {
    shiftFactor: 5,
    enabled: true,
    separator: ' - ',
    startRangeVal: 6,
    endRangeVal: 9,
  },
  5: {
    shiftFactor: 0,
    static: 0,
    enabled: true,
    separator: '',
    startRangeVal: 9,
    endRangeVal: 'NA',
    gtEndRange: true,
  },
};
const shiftFactorParams = probabilityEq => /[shift_factor]\((.*?)\)/.exec(probabilityEq)?.[1];
const ReliabilityMetrics = ({
  probabilityOfFailureEquation,
  mTTR,
  isDisabled,
  onChange,
  saveLibraryError,
  shiftFactorValid,
}) => {
  const isIncludeShiftFactor =
    probabilityOfFailureEquation && probabilityOfFailureEquation.includes('shift_factor');
  const [unit, setUnit] = useState('h');
  const [tempEq, setTempEq] = useState(
    isIncludeShiftFactor
      ? probabilityOfFailureEquation.replace(
          `(${shiftFactorParams(probabilityOfFailureEquation)})`,
          '',
        )
      : probabilityOfFailureEquation,
  );
  const [shiftFactorFunction, setShiftFactorFunction] = useState('');
  const defaultShiftFactor = failureEq => {
    const params = shiftFactorParams(failureEq);
    if (params) {
      const paramVal = params.match(/[^,]+,[^,]+/g);
      if (paramVal?.length === 4) {
        const shiftFactorSelectedValues = Object.entries(defaultShiftFactorValues).reduce(
          (newObj, [key, value], index) => {
            const values = paramVal[index]?.split(',');
            newObj[key] = {
              startRangeVal: index === 0 ? 0 : paramVal[index - 1]?.split(',')?.[0] || null,
              endRangeVal: value?.endRangeVal === 'NA' ? 'NA' : values?.[0] || value?.endRangeVal,
              shiftFactor: values?.[1] || 0,
              static: value?.static ?? null,
              enabled: value?.enabled ?? false,
              separator: value?.separator ?? null,
            };
            return newObj;
          },
          {},
        );
        return shiftFactorSelectedValues;
      }
    }
    return defaultShiftFactorValues;
  };
  const [shiftFactorValues, setShiftFactorValues] = useState(
    isIncludeShiftFactor ? defaultShiftFactor(probabilityOfFailureEquation) : null,
  );

  const getDivisor = () => {
    switch (unit) {
      case 'm':
        return 1 / 60;
      case 'd':
        return 24;
      case 'h':
      default:
        return 1;
    }
  };
  const handleShiftFactorChange = (row, key, value, keyTochangeVal) => {
    if (keyTochangeVal === 'endRangeVal') {
      for (let i = parseInt(key, 10) + 1; i <= Object.values(shiftFactorValues).length; i += 1) {
        const currRow = shiftFactorValues[i];
        const prevRow = shiftFactorValues[i - 1];
        if (value !== parseInt(currRow.startRangeVal, 10)) {
          if (i === parseInt(key, 10) + 1) {
            if (currRow.endRangeVal !== 'NA' && parseInt(row.startRangeVal, 10) >= value) {
              currRow.enabled = false;
            } else {
              currRow.startRangeVal = value;
              currRow.enabled = true;
              if (
                currRow.endRangeVal !== 'NA' &&
                parseInt(currRow.endRangeVal, 10) <= parseInt(currRow.startRangeVal, 10)
              ) {
                currRow.endRangeVal = null;
              }
            }
          } else if (
            parseInt(prevRow.endRangeVal, 10) > parseInt(currRow.startRangeVal, 10) ||
            (currRow.endRangeVal !== 'NA' &&
              parseInt(currRow.endRangeVal, 10) < parseInt(currRow.startRangeVal, 10)) ||
            parseInt(row.startRangeVal, 10) > parseInt(currRow.startRangeVal, 10) ||
            prevRow.endRangeVal === null
          ) {
            currRow.enabled = false;
          } else {
            if (
              currRow.endRangeVal === 'NA' &&
              parseInt(prevRow.endRangeVal, 10) !== parseInt(currRow.startRangeVal, 10)
            ) {
              currRow.startRangeVal = parseInt(prevRow.endRangeVal, 10);
            }
            currRow.enabled = true;
          }
        } else {
          currRow.enabled = true;
        }
      }
    }
    setShiftFactorValues({
      ...shiftFactorValues,
      [key]: { ...shiftFactorValues[key], [keyTochangeVal]: value },
    });
  };
  const setProbabilityOfEquation = useCallback(
    probabilityEqn => {
      onChange({
        AssetFailureInfo: {
          mTTR,
          probabilityOfFailureEquation: probabilityEqn,
        },
      });
    },
    [mTTR, onChange],
  );

  useEffect(() => {
    if (tempEq !== null) {
      let probabilityEqn = tempEq;
      if (tempEq?.includes('shift_factor')) {
        if (!shiftFactorValues) {
          setShiftFactorValues(defaultShiftFactor(probabilityOfFailureEquation));
        } else {
          probabilityEqn = tempEq.replace('shift_factor', shiftFactorFunction);
        }
      }
      setProbabilityOfEquation(probabilityEqn);
    }
  }, [
    tempEq,
    probabilityOfFailureEquation,
    shiftFactorValues,
    shiftFactorFunction,
    setProbabilityOfEquation,
  ]);
  useEffect(() => {
    if (shiftFactorValues !== null) {
      let shiftFactorStr = '0';
      const isInvalid = Object.values(shiftFactorValues).some(val => {
        let isValid = false;
        if (val.endRangeVal !== 'NA') {
          shiftFactorStr = `${shiftFactorStr},${val.endRangeVal}`;
          isValid =
            val.endRangeVal === '' ||
            val.endRangeVal === null ||
            parseInt(val.endRangeVal, 10) < parseInt(val.startRangeVal, 10) ||
            !val.enabled;
        }
        shiftFactorStr = val.static === 0 ? shiftFactorStr : `${shiftFactorStr},${val.shiftFactor}`;
        isValid = isValid || val.shiftFactor === '' || val.shiftFactor === null;
        return isValid;
      });
      shiftFactorValid(!isInvalid);
      const shiftFactorFunctionStr =
        shiftFactorStr.length > 0 ? `shift_factor(${shiftFactorStr})` : '';
      setShiftFactorFunction(shiftFactorFunctionStr);
      if (shiftFactorFunctionStr !== '') {
        const probabilityEqn = tempEq
          .replace('shift_factor()', 'shift_factor')
          .replace(`(${shiftFactorParams(tempEq)})`, '')
          .replace('shift_factor', shiftFactorFunctionStr);
        setProbabilityOfEquation(probabilityEqn);
      }
    }
  }, [shiftFactorValues, shiftFactorValid, tempEq, setProbabilityOfEquation]);
  return (
    <div className="equipment-info-container reliability-container" id="reliability-tab">
      <div className="right-panel">
        <div className="restoration-section">
          <NumberInput
            onChange={e =>
              onChange({
                AssetFailureInfo: {
                  probabilityOfFailureEquation,
                  mTTR: e.value,
                },
              })
            }
            divisor={getDivisor()}
            ge={0}
            precision={3}
            disabled={isDisabled}
            id="restoration-time"
            label="Restoration Time"
            value={mTTR || ''}
            inputStyle="eq-lib"
          />
          <Select
            id="restoration-time-units"
            options={restorationTimeUnits}
            value={unit}
            onChange={e => setUnit(e.value)}
            className="restoration-units"
            clearable={false}
          />
        </div>
        <TextInput
          id="probability-of-failure-equation"
          className="probability-equation"
          label="Probability of Failure Equation ="
          value={tempEq || ''}
          onChange={e => {
            const { value } = e.target;
            let appendParams = '';
            if (value.includes('shift_factor')) {
              appendParams = value
                .replace('shift_factor()', 'shift_factor')
                .replace(`(${shiftFactorParams(value)})`, '');
            } else {
              appendParams = value;
              setShiftFactorValues(null);
            }
            setTempEq(appendParams);
          }}
          disabled={isDisabled}
          invalid={saveLibraryError && Object.entries(saveLibraryError).length !== 0}
          validationMessage={
            saveLibraryError.AssetFailureInfo?.probabilityOfFailureEquation[0] || 'Invalid Equation'
          }
          unit={percent}
          inputWidth="225px"
        />
        <span className="label-caption">
          Note: The following variables can be used: asset_age, asset_health
          <br />
          Also available is a shift_factor function which accepts exactly 8 parameters (4 sets of
          x,y pairs). See documentation for more details
        </span>
        {shiftFactorValues && (
          <div className="equipment-input-section">
            <div className="coordinates-table">
              <div className="label-section">Shift factor Table</div>
              <div className="table-section">
                <div className="row-inputs">
                  <div className="coord-heading">Avg. % over Threshold (Current)</div>
                  <div className="coord-heading">Shift factor</div>
                </div>
                {Object.entries(shiftFactorValues).map(
                  ([key, row]) =>
                    row.enabled && (
                      <div className="row-inputs" key={`${key}-values`}>
                        <div className="input-with-label">
                          <span className="number-input-group x-input">
                            {`${row.gtEndRange ? '> ' : ''}${row.startRangeVal}${row.separator}`}
                          </span>
                          {row.endRangeVal !== 'NA' && (
                            <NumberInput
                              className="x-input shift-factor-inputs"
                              id={`${key}-x-input`}
                              ge={row.startRangeVal}
                              onChange={({ value }) =>
                                handleShiftFactorChange(row, key, value, 'endRangeVal')
                              }
                              disabled={isDisabled}
                              value={row.endRangeVal}
                              required
                              inputWidth="75px"
                            />
                          )}
                        </div>
                        {isDefined(row.static) ? (
                          <span className="static-value">{row.static}</span>
                        ) : (
                          <NumberInput
                            className="y-input"
                            id={`${key}-y-input`}
                            ge={0}
                            onChange={({ value }) =>
                              handleShiftFactorChange(row, key, value, 'shiftFactor')
                            }
                            disabled={isDisabled}
                            value={row.shiftFactor}
                            required
                            inputWidth="75px"
                          />
                        )}
                      </div>
                    ),
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ReliabilityMetrics.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  shiftFactorValid: PropTypes.func.isRequired,
  saveLibraryError: PropTypes.object.isRequired,
  probabilityOfFailureEquation: nullable(PropTypes.string).isRequired,
  mTTR: nullable(PropTypes.number).isRequired,
};

export default ReliabilityMetrics;
