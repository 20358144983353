import { useState, useCallback } from 'react';

type SetModifiedState<T> = (newValue: T, updateOriginal?: boolean) => void;

/**
 * Holds the state for a single variable but also tracks
 * whether the variable has been modified
 */
export default function useModifiedState<T>(value: T): [T, SetModifiedState<T>, boolean] {
  const [{ original, current }, setModifiedState] = useState({ original: value, current: value });

  function setStateInternal(newValue: T, updateOriginal = false) {
    setModifiedState({
      original: updateOriginal ? newValue : original,
      current: newValue,
    });
  }
  const setState = useCallback(setStateInternal, [original, setModifiedState]);

  return [current, setState, current !== original];
}
