import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/Button';
import asyncStates from 'helpers/asyncActionStates';

import JSCIM from 'helpers/JSCIM';
import { Request } from '@opusonesolutions/gridos-app-framework';
import ThemeContext from 'helpers/ThemeContext';
import { kVAr, kW } from 'helpers/units';
import { alphabetizeByKey } from 'helpers/utils';

import LifecycleSection from './partials/LifecycleSection';
import InverterConstraintDiagram from './partials/InverterConstraintDiagram';
import InverterValues from './partials/InverterValues';
import AssetSchedule from './partials/AssetSchedule';
import ShuntDeviceHeader from './partials/ShuntDeviceHeader';
import EditableControlMode from './partials/EditableControlMode';
import CustomerProgramSelector from './partials/CustomerProgramSelector';
import DroopCurve from './partials/DroopCurve/DroopCurve';
import PVUnit from './partials/PVUnit';

import './Battery.scss';

const controlHelp = {
  uncontrolled:
    "PVs set to 'fixed' mode will be allocated as part of the load" +
    ' allocation process during a timeseries analysis.',
  globallyOptimized:
    "PVs set to 'Global' mode will curtail from their scheduled (or allocated)" +
    ' active/reactive power outputs, when it serves the global objective to do so.',
  scheduled: "PVs set to 'Schedule' will follow the attached timeseries.",
  locallyControlled:
    "PVs set to 'Local' will follow a Volt-Var droop curve, adjusting reactive" +
    ' power in response to voltage at their point-of-connection;' +
    ' active power is determined by the schedule (or allocated from the scenario).',
};

const controlPanelValues = {
  uncontrolled: [
    {
      id: 'p',
      key: 'p',
      label: 'Active Power',
      unit: kW,
      divisor: -1000,
      type: 'number',
    },
    {
      id: 'q',
      key: 'q',
      label: 'Reactive Power',
      unit: kVAr,
      divisor: -1000,
      type: 'number',
    },
  ],
};

const InverterPV = ({
  asset: inverterPV,
  workspace,
  branch,
  displayBranch,
  feeder,
  loadForecast: { selectedScenario, selectedScenarioType, selectedAnalysis },
  timeRange,
  maxRange,
  timeBarZoomLevel,
  selected: { id, class: assetClass },
  toggleFeederPanel,
  inEditMode,
  canEditNetwork,
  setSelectedAssetID,
  editActions,
  expanded,
  isAuthEnabled,
  permissions,
  applyDifferenceModelRequest,
}) => {
  const theme = useContext(ThemeContext);
  const [scheduleGenerating, setScheduleGeneration] = useState(asyncStates.INITIAL);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body =>
    editActions.editSingleEquipment(workspace, branch, 'inverter', id, body);
  const handleUnitSave = (unitID, body) =>
    editActions.editSingleEquipment(workspace, branch, 'pv_unit', unitID, body);

  const generatePVSchedule = async () => {
    const shiftTz = true;
    setScheduleGeneration(asyncStates.LOADING);
    const pvScheduleRequest = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${id}/generate_schedule`,
    );
    try {
      await pvScheduleRequest.post(undefined, {
        params: {
          feeder,
          scenario_id: selectedScenario,
          asset_type: assetClass.toLowerCase(),
          schedule_type: 'Normal',
          shift_tz: shiftTz,
        },
      });
      setScheduleGeneration(asyncStates.SUCCESS);
    } catch (error) {
      setScheduleGeneration(asyncStates.ERROR);
    }
  };

  const controlMode = inverterPV.analysis_control?.mode;
  const controlModeAttributes = { ...inverterPV.attributes };
  return (
    <>
      <div
        className={classNames({
          'asset-panel-values': true,
          'asset-panel-values--expanded': expanded,
        })}
      >
        <ShuntDeviceHeader
          asset={inverterPV}
          assetId={id}
          toggleFeederPanel={toggleFeederPanel}
          inEditMode={inEditMode}
          disabled={!canEditNetwork || saving}
          setSelectedAssetID={setSelectedAssetID}
          handleSave={handleSave}
        />

        <hr className="section-divider" />

        <InverterValues
          validationSchema={JSCIM.InverterPV.validationSchema}
          handleSave={handleSave}
          disabled={saving || !inEditMode}
          editable={canEditNetwork && inEditMode}
          inverter={{ id, class: assetClass }}
          inverterAttributes={inverterPV.attributes}
          inverterInfo={inverterPV.inverter_info}
          eqLibLink={`/${workspace}/${displayBranch}/library/inverters`}
          infos={inverterPV.inverter_infos}
          container={inverterPV.container}
          inEditMode={inEditMode}
        />
        <InverterConstraintDiagram
          label="Gen"
          ratedPowerFactor={
            inverterPV.attributes.ratedPowerFactor ?? inverterPV.inverter_info?.ratedPowerFactor
          }
          ratedS={inverterPV.attributes.ratedS ?? inverterPV.inverter_info?.ratedS}
        />

        <hr className="section-divider" />

        <section id="pv-unit-section">
          <div className="unit-section-header">
            <h2 className="title-text">
              Photovoltaic Units ({inverterPV.power_electronics_units?.length})
            </h2>
            {canEditNetwork && inEditMode && (
              <Button
                id="add-pv-unit"
                label="Add"
                onClick={() => editActions.addNewInstance('pv_unit', id)}
              />
            )}
          </div>
          {alphabetizeByKey(inverterPV.power_electronics_units, 'name').map((unit, idx) => (
            <PVUnit
              key={unit.id}
              infos={inverterPV.power_electronics_unit_infos}
              eqLibLink={`/${workspace}/${displayBranch}/library/photovoltaics`}
              unit={unit}
              position={idx + 1}
              editable={canEditNetwork && inEditMode}
              disabled={!canEditNetwork || !inEditMode || saving}
              validationSchema={JSCIM.InverterPV.validationSchema}
              canDelete={inverterPV.power_electronics_units?.length > 1}
              handleDelete={editActions.deleteReference}
              handleSave={body => handleUnitSave(unit.id, body)}
              container={inverterPV.container}
              inEditMode={inEditMode}
            />
          ))}
        </section>

        <hr className="section-divider" />
        {!inEditMode && permissions.has('get_der_program_mapping') && (
          <section id="customer-program-section">
            <h2 className="title-text">Customer Program</h2>
            <CustomerProgramSelector
              workspace={workspace}
              branch={branch}
              scenarioId={selectedScenario}
              assetId={id}
              customerPrograms={inverterPV.customer_agreements ?? []}
              disabled={!selectedScenario || !permissions.has('alter_der_program_mapping')}
            />
          </section>
        )}
        {!inEditMode && (
          <>
            <hr className="section-divider" />
            <AssetSchedule
              id="inverter-pv-cost"
              workspace={workspace}
              branch={branch}
              scenario={selectedScenario}
              scenarioType={selectedScenarioType}
              asset={{ id, class: assetClass }}
              scheduleType="Cost"
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
              expanded={expanded}
              scheduleGenerated={scheduleGenerating}
            />
          </>
        )}

        <hr className="section-divider" />
        <EditableControlMode
          controlMode={controlMode}
          help={controlHelp[controlMode]}
          asset={assetClass}
          editableValues={controlPanelValues[controlMode]}
          attributes={controlModeAttributes}
          disabled={saving || !canEditNetwork}
          onSave={handleSave}
          theme={theme}
          inEditMode={inEditMode}
          selectedAnalysis={selectedAnalysis}
          selectedAssetId={id}
          workspace={workspace}
          branch={branch}
        >
          {controlMode === 'locallyControlled' && (
            <DroopCurve
              powerFactor={inverterPV.attributes.ratedPowerFactor}
              apparentPower={inverterPV.attributes.ratedS}
              expanded={expanded}
            />
          )}
          {['scheduled', 'globallyOptimized'].includes(controlMode) && !inEditMode && (
            <>
              <div style={{ paddingTop: '10px' }} className="pv-schedule-button">
                <Button
                  id="generate-pv-schedule"
                  label="Generate Schedule"
                  onClick={() => generatePVSchedule()}
                  theme={theme}
                  loading={scheduleGenerating === asyncStates.LOADING}
                  disabled={!selectedScenario}
                />
                {scheduleGenerating === asyncStates.ERROR && (
                  <div>
                    <i
                      style={{ float: 'left', paddingTop: '7px' }}
                      className="material-icons error"
                    >
                      warning
                    </i>
                    <p
                      style={{ float: 'left', paddingTop: '10px', paddingLeft: '5px' }}
                      className="invalid-warning"
                    >
                      Something went wrong.
                    </p>
                  </div>
                )}
                <AssetSchedule
                  id="inverter-pv-normal"
                  workspace={workspace}
                  branch={branch}
                  scenario={selectedScenario}
                  scenarioType={selectedScenarioType}
                  asset={{ id, class: assetClass }}
                  scheduleType="Normal"
                  timeRange={timeRange}
                  maxRange={maxRange}
                  timeBarZoomLevel={timeBarZoomLevel}
                  panelValues={{
                    ...inverterPV.attributes,
                    pvUnitMinP: inverterPV.power_electronics_units?.reduce((sum, pv) => {
                      const { minP } = pv;
                      return minP ? sum + minP : sum;
                    }, 0),
                  }}
                  editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
                  expanded={expanded}
                  scheduleGenerated={scheduleGenerating}
                />
              </div>
            </>
          )}
        </EditableControlMode>

        <hr className="section-divider" />
        <LifecycleSection
          disabled={saving || !inEditMode || !canEditNetwork}
          lifecycle={inverterPV.lifecycle}
          onChange={lifecycle => handleSave({ lifecycle })}
          healthMetric={inverterPV.reliability_info?.healthMetric}
          onHealthMetricChange={handleSave}
        />
      </div>
    </>
  );
};

InverterPV.propTypes = {
  asset: PropTypes.object.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  displayBranch: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  branch: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
    selectedAnalysis: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  editActions: PropTypes.object.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  feeder: PropTypes.string.isRequired,
};

export default InverterPV;
