import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../modules/importCIM';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ImportCIM from '../components/ImportCIM';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

const mapStateToProps = state => ({
  files: state.importCIM.files,
  uploadInProgress: state.importCIM.uploadInProgress,
  importErrors: state.importCIM.importErrors,
  createWorkspaceStatus: state.importCIM.createWorkspaceStatus,
  theme: state.global.theme,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportCIM);
