import React, { FunctionComponent, useState } from 'react';
import { AppearanceTypes } from 'react-toast-notifications';
import IconButton from 'components/IconButton';
import './IconMessage.scss';
import Icons from '../Icons';

type IconMessageProps = {
  type?: AppearanceTypes;
  message?: string | null;
};

const IconMessage: FunctionComponent<IconMessageProps> = ({ type = 'info', message = null }) => {
  const [msgOpen, setMsgOpen] = useState(false);
  const { CloseIcon } = Icons;
  return (
    <div className="icon-message">
      <div className="react-toast-notifications__container">
        <div
          className={`react-toast-notifications__toast react-toast-notifications__toast--${type}`}
        >
          <div className="react-toast-notifications__toast__countdown">
            {!msgOpen && (
              <IconButton
                onClick={() => setMsgOpen(true)}
                tooltip="Click to expand"
                id="icon-msg"
              />
            )}
          </div>
          {message && msgOpen && (
            <div className="toast-message">
              <p>{message}</p>
              <IconButton onClick={() => setMsgOpen(false)} tooltip="Close" id="close-message">
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IconMessage;
