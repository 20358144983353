import RotatingMachine from './RotatingMachine';

class AsynchronousMachine extends RotatingMachine {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'generator';
    this._class = 'AsynchronousMachine';
    this.displayName = 'Asynchronous Machine';

    this.validationSchema = {
      ...this.validationSchema,
      totalP: { min: 0, required: true },
      totalQ: { min: 0, required: true },
    };
  }
}

export default AsynchronousMachine;
