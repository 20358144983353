import React from 'react';
import PropTypes from 'prop-types';

import useTrackingState from 'hooks/useTrackingState';
import { kVA } from 'helpers/units';
import chargerTypes from 'helpers/Constants';

import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';
import AssetSingleSelect from '../../Inputs/AssetSingleSelect';
import SingleEditableValue from '../../Inputs/SingleEditableValue';

const InstanceInfo = ({
  workspace,
  displayBranch,
  disabled,
  inEditMode,
  asset,
  assetId,
  handleSave,
  theme,
}) => {
  const [chargerType, setChargerType, chargerTypeEdited] = useTrackingState(
    asset.attributes?.chargerType,
  );
  const [ratedS, setRatedS, ratedSEdited] = useTrackingState(asset.attributes?.ratedS);
  const [chargerInfo, setChargerInfo, chargerInfoEdited] = useTrackingState(
    asset.electric_vehicle_charging_station_info,
  );
  const chargerOptions = asset.electric_vehicle_charging_station_infos ?? [];
  const mappedEVChargerInfoOptions = [
    {
      value: chargerInfo?.id,
      label: chargerInfo?.name ?? 'Select an EV Charger Info',
      disabled: true,
    },
    ...chargerOptions
      .filter(ev => ev.id !== chargerInfo?.id)
      .map(ev => ({ value: ev.id, label: ev.name || ev.id })),
  ];
  return (
    <>
      <EquipmentInfoSelector
        eqLibURL={`/${workspace}/${displayBranch}/library/evChargingStations`}
        displayName="EV Charging Stations"
        assetId={assetId}
        value={chargerInfo?.id}
        loadOptions={() => mappedEVChargerInfoOptions}
        defaultOptions={mappedEVChargerInfoOptions}
        id="generator-info-select"
        onChange={({ value: id, label: name }) => {
          setChargerInfo({ id, name });
          handleSave({ electric_vehicle_charging_station_info: id });
        }}
        disabled={disabled || !inEditMode}
        edited={chargerInfoEdited}
        feeder={asset.container.id}
      />
      <AssetSingleSelect
        id="chargerType"
        key="chargerType"
        label="Charger Type"
        options={chargerTypes}
        value={chargerType}
        onChange={value => {
          setChargerType(value);
          handleSave({ attributes: { chargerType: value } });
        }}
        edited={chargerTypeEdited}
        disabled={disabled || !inEditMode}
      />
      <SingleEditableValue
        theme={theme}
        disabled={disabled || !inEditMode}
        unit={kVA}
        min={0}
        type="number"
        id="ratedS"
        label="Rated Power"
        divisor={1000}
        onBlur={() => handleSave({ attributes: { ratedS } })}
        onChange={e => setRatedS(e)}
        value={ratedS}
        edited={ratedSEdited}
        precision={3}
      />
    </>
  );
};

InstanceInfo.propTypes = {
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
  assetId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
};
export default InstanceInfo;
