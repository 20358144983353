import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as appStateReducer from 'store/appState';
import { actions } from '../../Network/modules/network';
import { feederActions } from '../../Network/modules/feeders';
import { loadForecastActions } from '../../Network/modules/loadForecast';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import ScenarioGeneration from '../components/ScenarioGeneration';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  actions: bindActionCreators(
    {
      ...actions,
      ...appStateReducer.actions,
      ...feederActions,
      ...loadForecastActions,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: any, ownProps: any) => ({
  maxRange: state.network.maxRange,
  branch: state.network.branch,
  branches: state.network.branches,
  containerList: state.feeders.list,
  containerTree: state.feeders.tree,
  pendingReq: state.feeders.pendingReq,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioGeneration);
