import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RadioButtonSelectGroup from 'components/RadioButtonSelectGroup';
import ExpandableSection from 'components/ExpandableSection';
import {
  getVoltageRangeBreaks,
  createRangeBuckets,
  createLabels,
  getPhaseSelectOptions,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import { getMultiplier } from 'helpers/utils';
import RangeSelector from './RangeSelector';
import RangeSlider from './RangeSlider';
import LayerSelectionButton from './LayerSelectionButton';

class VoltageSection extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      voltageGradient: this.getRangeBreaks(this.props.layerOptions),
      editId: '',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldOptions = this.props.layerOptions;
    const newOptions = nextProps.layerOptions;
    if (
      oldOptions.colors !== newOptions.colors ||
      oldOptions.rangeBreaks !== newOptions.rangeBreaks ||
      oldOptions.puRangeBreaks !== newOptions.puRangeBreaks ||
      oldOptions.dispType !== newOptions.dispType
    ) {
      this.setState({ voltageGradient: this.getRangeBreaks(newOptions), editId: '' });
    }
  }

  getRangeBreaks = options => {
    const { rangeBreaks, puRangeBreaks, colors, dispType } = options;
    const breaks = dispType === 'pu' ? puRangeBreaks : rangeBreaks;
    const labels = dispType === 'pu' ? createLabels(breaks, '') : this.generateActualLabels(breaks);
    return createRangeBuckets(colors, labels);
  };

  generateActualLabels = breaks => {
    const rangeLabels = breaks.map(rangeBreak => {
      const { value, letter } = getMultiplier(rangeBreak);
      return `${(rangeBreak / value).toFixed(2)} ${letter}`;
    });

    return createLabels(rangeLabels, 'V');
  };

  changeAggType = e => {
    const aggType = e.target.value;
    this.props.updateLayerOptions('voltage', { aggType });
  };

  unCheckRange = e => {
    const index = parseInt(e.target.value, 10);
    const newSelection = [...this.props.layerOptions.selected];
    newSelection[index] = !newSelection[index];
    this.props.updateLayerOptions('voltage', { selected: newSelection });
  };

  editCheckRange = e => this.toggleEditPanel(e.target.value);

  toggleEditPanel = id => this.setState({ editId: id });

  handleLayerUpdate = updates => {
    const updatedValues = { ...updates };
    if (updatedValues.rangeBreaks && this.props.layerOptions.dispType === 'pu') {
      updatedValues.puRangeBreaks = updatedValues.rangeBreaks;
      delete updatedValues.rangeBreaks;
    }
    this.props.updateLayerOptions('voltage', updatedValues);
  };

  handlePhaseChange = phaseType => this.handleLayerUpdate({ phaseType });

  handleDispTypeChange = dispType => this.handleLayerUpdate({ dispType });

  handleRangeBreaksChange = rangeBreaks => this.handleLayerUpdate({ rangeBreaks });

  handleBaseVChange = (id, baseV) => {
    const rangeBreaks = getVoltageRangeBreaks(baseV);
    this.handleLayerUpdate({ baseV, rangeBreaks });
  };

  handleAggChange = (id, value) => {
    if (id === 'three') {
      const aggType = value;
      this.handleLayerUpdate({ aggType });
    } else if (id === 'single') {
      const phase = value;
      this.handleLayerUpdate({ phase });
    }
  };

  _renderPhaseSelectors = ({ phaseType, aggType, phase }, showThreePhase) => (
    <RadioButtonSelectGroup
      id="phaseType"
      listType="column"
      value={phaseType}
      onChange={this.handlePhaseChange}
      onSelectChange={this.handleAggChange}
      options={getPhaseSelectOptions(aggType, phase, showThreePhase)}
      theme={this.props.theme}
    />
  );

  _renderUnitSelectors = () => {
    const baseVArray = this.props.layerOptions.baseVoltages.map(el => {
      const { value, letter } = getMultiplier(el);
      return {
        label: `${(el / value).toFixed(2)} ${letter}V`,
        value: el,
      };
    });

    return (
      <RadioButtonSelectGroup
        id="unitType"
        listType="row"
        value={this.props.layerOptions.dispType}
        onChange={this.handleDispTypeChange}
        onSelectChange={this.handleBaseVChange}
        options={[
          {
            id: 'pu',
            label: 'p.u.',
          },
          {
            id: 'actual',
            label: 'Actual (LL)',
            selectOptions: baseVArray,
            selectValue: this.props.layerOptions.baseV,
          },
        ]}
        theme={this.props.theme}
      />
    );
  };

  render() {
    const button = () => (
      <LayerSelectionButton
        selected={this.props.selected}
        option={{ label: 'Voltage', value: 'voltage' }}
        group="results"
        setSelectedLayer={this.props.setSelectedLayer}
      />
    );

    const { showThreePhase } = this.props;
    const { dispType, puRangeBreaks, rangeBreaks } = this.props.layerOptions;

    return (
      <ExpandableSection
        className={classNames({
          'layer-expandable-section': true,
          'selected-layer': this.props.selected,
          'layer-expandable-section--expanded': this.props.expanded,
        })}
        renderHeaderContent={button}
        showToggle={false}
        open={this.props.selected}
      >
        <div className="layer-section">
          <div className="layer-phase-selector">
            {this._renderPhaseSelectors(this.props.layerOptions, showThreePhase)}
          </div>
          <div className="layer-unit-selector">{this._renderUnitSelectors()}</div>
          <RangeSlider
            domain={
              dispType === 'actual'
                ? [rangeBreaks[3] - 100, rangeBreaks[0] + 100]
                : [puRangeBreaks[3] - 0.01, puRangeBreaks[0] + 0.01]
            }
            values={
              dispType === 'actual'
                ? rangeBreaks.slice().reverse()
                : puRangeBreaks.slice().reverse()
            }
            step={dispType === 'actual' ? 5 : 0.005}
            colors={this.props.layerOptions.colors.slice().reverse()}
            onUpdate={e => {
              this.handleRangeBreaksChange(e.reverse());
            }}
          />
          <div className="layer-checkbox-group">
            {this.state.voltageGradient.length &&
              this.state.voltageGradient.map(checkbox => (
                <RangeSelector
                  key={checkbox.id}
                  settings={checkbox}
                  selected={this.props.layerOptions.selected[parseInt(checkbox.id, 10)]}
                  onClick={this.unCheckRange}
                  id={parseInt(checkbox.id, 10)}
                  withEdit
                  toggleEdit={this.toggleEditPanel}
                  showEditPanel={checkbox.id === this.state.editId}
                  layerOptions={this.props.layerOptions}
                  handleUpdate={this.handleLayerUpdate}
                  editDisabled={!!this.state.editId}
                  editColor
                  editRange
                  rangeBreaks={dispType === 'actual' ? rangeBreaks : puRangeBreaks}
                  useMultiplier={this.props.layerOptions.dispType === 'actual'}
                  unit={this.props.layerOptions.dispType === 'actual' ? 'V' : ''}
                  ascending
                />
              ))}
          </div>
        </div>
      </ExpandableSection>
    );
  }
}

VoltageSection.propTypes = {
  layerOptions: PropTypes.object.isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  showThreePhase: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default VoltageSection;
