import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const CircleCrossIcon: FunctionComponent<IconPropsSchema> = ({
  color = '#C03B09',
  height = '14px',
  width = '15px',
}) => (
  <svg width={width} height={height} viewBox="0 0 15 14" version="1.1">
    <defs>
      <rect id="path-1" x="0" y="0" width="1119" height="563" rx="4" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="IDP-home-Copy-38-(b)-Copy-24" transform="translate(-709.000000, -483.000000)">
        <rect x="0" y="0" width="1366" height="800" />
        <g
          id="PV_102_1920-Copy-12-+-Generated-Copy-+-checked-copy-13-Mask"
          transform="translate(237.000000, 227.000000)"
        >
          <mask id="mask-2">
            <use />
          </mask>
          <use id="Mask" fill="#FFFFFF" />
        </g>
        <g id="Group-2" transform="translate(465.000000, 378.000000)" fillRule="nonzero">
          <g id="Group-9" transform="translate(244.500000, 105.000000)">
            <path
              d="M13.9801361,6.99006806 C13.9801361,10.8442461 10.8442461,13.9797696 6.99006806,13.9797696 C3.13552356,13.9797696 -1.77635684e-13,10.8442461 -1.77635684e-13,6.99006806 C-1.77635684e-13,3.13552356 3.13552356,1.30562228e-13 6.99006806,1.30562228e-13 C10.8442461,1.30562228e-13 13.9801361,3.13552356 13.9801361,6.99006806 Z"
              id="Path"
              fill={color}
            />
            <g id="Path" transform="translate(3.990068, 3.989885)" fill="#FFFFFF" stroke="#FFFFFF">
              <path d="M3.33146973,3.00001318 L5.93134883,0.400116016 C6.02288372,0.308580904 6.02288372,0.160174726 5.93134883,0.0686513337 C5.83981395,-0.0228720591 5.69140813,-0.0228837779 5.59988496,0.0686513337 L3,2.6685485 L0.400115039,0.0686513337 C0.308580151,-0.0228837779 0.160174335,-0.0228837779 0.0686511661,0.0686513337 C-0.0228720033,0.160186445 -0.022883722,0.308592623 0.0686511661,0.400116016 L2.66853027,3 L0.0686511661,5.59989863 C-0.022883722,5.69143374 -0.022883722,5.83983992 0.0686511661,5.93136331 C0.114412751,5.97712501 0.174400856,6 0.234388962,6 C0.294377067,6 0.354353454,5.97712501 0.400126757,5.93136331 L3,3.33147787 L5.59987324,5.93136331 C5.64563483,5.97712501 5.70562293,6 5.76561104,6 C5.82559914,6 5.88557553,5.97712501 5.93134883,5.93136331 C6.02288372,5.8398282 6.02288372,5.69142203 5.93134883,5.59989863 L3.33146973,3.00001318 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CircleCrossIcon;
