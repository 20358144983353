import ShuntDevice from './ShuntDevice';

class ElectricVehicleChargingStation extends ShuntDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'ElectricVehicleChargingStation';
    this.displayName = 'EV Station';
  }

  get chargerType() {
    return this.attributes['ElectricVehicleChargingStation.chargerType'];
  }

  get icon() {
    switch (this.chargerType) {
      case 'DC':
        return 'ev_station_dc';
      case 'I':
      case 'II':
      case 'III':
        return 'ev_station_ac';
      default:
        return 'ev_station';
    }
  }
}

export default ElectricVehicleChargingStation;
