import React, { FunctionComponent, ReactElement } from 'react';
import Tooltip from 'components/Tooltip';
import { ThemeProp } from 'types';
import CheckmarkIcon from '../Icons/CheckmarkIcon';
import PermissionDisabledTip from '../PermissionDisabledTip';
/**
 * Component to create a standard dropdown menu item. Can have icon and has check
 * option to denote selected
 */

type StandardMenuItemProps = {
  tooltip?: string;
  height?: number;
  data: {
    contents: string | number;
    id: string | number;
  };
  selected: boolean;
  toggle: null | ((id: string | number) => void);
  icon?: null | ReactElement;
  active: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  theme?: ThemeProp;
};

const StandardMenuItem: FunctionComponent<StandardMenuItemProps> = ({
  selected = false,
  height = 44,
  icon = null,
  disabled = false,
  disabledMessage = undefined,
  tooltip = '',
  theme = 'light',
  data,
  toggle = null,
  active,
}) => (
  <PermissionDisabledTip
    hide={!disabled || !disabledMessage}
    title={data.contents}
    message={disabledMessage}
  >
    <Tooltip content={tooltip} placement="right" theme={theme}>
      <button
        className={`dropdown-item__btn ${selected ? 'selected' : ''}`}
        style={{ height }}
        data-type={data.id}
        onClick={() => toggle && toggle(data.id)}
        tabIndex={active ? 0 : -1}
        disabled={disabled}
        type="button"
      >
        {icon && <div className="dropdown-item__svg--main">{icon}</div>}
        {data.contents}
        {selected && (
          <div className="dropdown-item__svg--check">
            <CheckmarkIcon color="#5A9CDE" height={14} width={14} />
          </div>
        )}
      </button>
    </Tooltip>
  </PermissionDisabledTip>
);

export default StandardMenuItem;
