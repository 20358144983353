import React, { FunctionComponent, useContext, useEffect, useState, useMemo } from 'react';
import { useRequestEffect, useRequest } from '@opusonesolutions/gridos-app-framework';
import { alphabetizeByKey } from 'helpers/utils';
import { ReactSVG } from 'react-svg';
import { ThemeProp } from 'types/index';
import ResultsCard from 'components/ResultsCard';
import { SwitchContingencySettings, unitInfos, optionType } from 'types/edit';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import CalendarPicker from 'components/CalendarPicker';

import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import './SwitchContingencySettings.scss';

type optionsAssetType = {
  value: string;
  label: string;
}[];

type optionsAssetOutage = {
  id: string;
  name: string;
}[];

type optionsRecommendedSwitch = {
  switch_id: string;
  switch_name: string;
  switch_open: boolean;
}[];

const assetTypeOptions: optionsAssetType = [
  { value: 'PowerTransformer', label: 'Transformer' },
  { value: 'SeriesCompensator', label: 'Series compensator' },
  { value: 'Regulator', label: 'Regulator' },
  { value: 'Line', label: 'Line' },
];

type SwitchContingenySettingsProps = {
  theme: ThemeProp;
  contingencySettings: SwitchContingencySettings;
  setContingencySettings: (args: SwitchContingencySettings) => void;
  switchAssetList: unitInfos;
  selectedSwitches: optionType[];
  setSelectedSwitches: (arg: optionType[]) => void;
  loadingMessage?: string;
  selectedContainer?: string | null;
  workspace: string;
  branch: string;
};

const SwitchContingenySettings: FunctionComponent<SwitchContingenySettingsProps> = ({
  theme,
  contingencySettings,
  setContingencySettings,
  switchAssetList,
  selectedSwitches,
  setSelectedSwitches,
  loadingMessage = 'Loading...',
  selectedContainer,
  workspace,
  branch,
}) => {
  const { scenarioRange, scenarios } = useContext(ScenarioGenerationContext);

  const [selectedAssetTypeForSwitch, setSelectedAssetType] = useState<optionType[]>([]);
  const [selectedAssetOutage, setSelectedAssetOutage] = useState('');
  const [recommendSwitchesList, setRecommendSwitchesList] = useState<optionsRecommendedSwitch>([]);
  const [selectedRecommendedSwitches, setSelectedRecommendedSwitches] = useState<optionType[]>([]);

  const [contingencyError, setContingencyError] = useState('');

  useEffect(() => {
    setSelectedAssetType(assetTypeOptions);
  }, []);

  // Asset Outage
  const arrayOfTypeSelected = selectedAssetTypeForSwitch.map(a => a.value);

  const { data: optionsData, loading } = useRequestEffect<optionsAssetOutage>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset`,
    method: 'get',
    params: {
      container: selectedContainer,
      type: arrayOfTypeSelected,
    },
    onError: err => {
      if (err?.response?.data?.message && selectedAssetTypeForSwitch.length <= 1) {
        setContingencyError('No options to show. Please check your selection.');
      } else if (
        optionsData &&
        optionsData?.length < 1 &&
        err?.response?.status === 200 &&
        selectedAssetTypeForSwitch.length >= 1
      ) {
        setContingencyError('Insufficient supply paths found');
      } else {
        setContingencyError('Outage cannot be isolated');
      }

      return contingencyError;
    },
    refetchOnChange: [selectedAssetTypeForSwitch],
    blockRequest: () => !selectedAssetTypeForSwitch,
  });

  const optionsOutageData = useMemo(
    () =>
      alphabetizeByKey(
        (optionsData ?? [])
          ?.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)
          .map(dr => ({ value: dr.id, label: dr.name })),
        'label',
      ),

    [optionsData],
  );

  // Recommend switches

  const { makeRequest: recommendSwitchRequest, loading: loadingSwitches } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/contingency_planning/asset/${selectedAssetOutage}`,
  );

  const handleAndRecommendSwitches = async () => {
    await recommendSwitchRequest({
      method: 'get',
      params: {
        container: selectedContainer,
      },
      onSuccess: data => {
        setRecommendSwitchesList(data.switches_to_invert);
        const recommendedSwitchOptions: any = data.switches_to_invert
          .filter((item: any) => item.switch_open === true)
          .map((a: any) => ({
            value: a.switch_id,
            label: a.switch_name,
          }));
        setSelectedRecommendedSwitches(recommendedSwitchOptions);
        setContingencyError('');
      },
      onError: () => {
        setSelectedRecommendedSwitches([]);
        setContingencyError('Insufficient supply paths found.');
      },
    });
  };

  // Few selected switches have no contingency response. Update selection.

  useEffect(() => {
    if (scenarioRange.start && scenarioRange.end) {
      setContingencySettings({
        ...contingencySettings,
        scenario_start: scenarioRange.start.toISOString(),
        scenario_end: scenarioRange.end.toISOString(),
        contingency_start: scenarioRange.start.toISOString(),
        contingency_end: scenarioRange.end.toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioRange]);

  const hasError =
    selectedAssetTypeForSwitch.length > 0 && optionsOutageData.length < 1 && !loading;
  const recommendSwitchButtonIsDisabled =
    selectedAssetTypeForSwitch.length < 1 ||
    !selectedAssetOutage ||
    optionsOutageData.length < 1 ||
    (selectedAssetTypeForSwitch.length < 1 && !selectedAssetOutage) ||
    (selectedAssetTypeForSwitch.length >= 1 && optionsOutageData.length < 1);
  const selectSwitchHasError = !!contingencyError && !recommendSwitchButtonIsDisabled;

  return (
    <div className="contingency-settings">
      <div className=" section-one grid-columns margin-10">
        <div>
          <span>Scenario name</span>
        </div>
        <div>
          <TextInput
            theme={theme}
            id="scenario-name"
            className="border-color"
            value={contingencySettings.name}
            onChange={e =>
              setContingencySettings({
                ...contingencySettings,
                name: e?.target.value || '',
              })
            }
            inputWidth="476px"
            inputStyle="primary"
            required
            invalid={
              scenarios.some(({ label }) => label === contingencySettings.name) ||
              contingencySettings.name.match(/^[a-zA-Z0-9-_]+( +[a-zA-Z0-9-_]+)*$/g) === null
            }
            validationMessage={
              'Invalid name. Must be unique, at least 1 character, not include ' +
              'special characters or whitespace at the beginning or end.'
            }
          />
        </div>
      </div>
      <div
        className={`contingency-settings section-two grid-columns margin-10 ${
          hasError ? 'has-error' : 'no-error'
        }`}
      >
        <div className="title">
          <div>
            <i>
              Optional: Choose an asset outage to recommend contingency response and select switches
            </i>
          </div>
        </div>
        <div className="content grid-columns auto-fit optional-content-wrapper">
          <div className="asset-type" style={{ backgroundColor: 'transparent' }}>
            <div className="label">
              <p>Asset Type</p>
            </div>
            <Select
              onChange={(option: optionType[]) => {
                setSelectedRecommendedSwitches([]);
                setSelectedAssetType(option ?? []);
              }}
              id="select-asset-type"
              options={assetTypeOptions}
              value={selectedAssetTypeForSwitch}
              theme={theme}
              type="secondary"
              clearable={false}
              multiSelectType="checkbox"
              isMulti
              width={200}
              disabled={loading}
              searchable
            />
          </div>
          <div className="asset-outage" style={{ backgroundColor: 'transparent' }}>
            <div className="label">
              <p>Asset Outage</p>
              <div className="icon-holder">
                <Tooltip
                  content="Select a device that is currently out of service to view recommended switches"
                  placement="right"
                >
                  <ReactSVG className="info" src="/info.svg" />
                </Tooltip>
              </div>
            </div>
            <Select
              onChange={e => {
                setSelectedRecommendedSwitches([]);
                setContingencyError('');
                return setSelectedAssetOutage(e.value);
              }}
              id="select-asset-outage"
              options={optionsOutageData}
              value={selectedAssetOutage}
              theme={theme}
              type="secondary"
              clearable={false}
              width={200}
              invalid={hasError}
              placeholder={loading ? loadingMessage : undefined}
              disabled={loading}
            />
            <p className="error-message">
              {(selectedAssetTypeForSwitch.length > 0 &&
                optionsOutageData.length < 1 &&
                contingencyError) ||
                ''}
            </p>
          </div>
          <div className="recommend-button" style={{ backgroundColor: 'transparent' }}>
            <Tooltip
              content="Automatically select switches that need to be operated to restore power when the specified asset is out of service"
              placement="bottom"
            >
              <Button
                className="recomment-switches-button"
                type="text"
                theme="light"
                label="Recommend switches"
                onClick={() => handleAndRecommendSwitches()}
                disabled={recommendSwitchButtonIsDisabled}
                loading={loadingSwitches}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div
        className={`contingency-settings section-three grid-columns margin-10 ${
          hasError ? 'has-error' : 'no-error-switches'
        }`}
      >
        <div className="title">
          <div>
            <b>Selected switches</b>
          </div>
        </div>

        <div>
          <ResultsCard theme={theme} withBorder={false} className="contingency-settings-card">
            <div className="label">
              <p>Switches</p>
            </div>
            <div className="optional-content">
              <p>
                {recommendSwitchesList.length > 0 &&
                  'Recommended switches are auto-selected. You may edit the selection before generating a schedule.'}
              </p>
            </div>
            <Select
              onChange={(option: optionType[]) => {
                recommendSwitchesList.length > 0
                  ? setSelectedRecommendedSwitches(option ?? [])
                  : setSelectedSwitches(option ?? []);
              }}
              id="select-switches"
              options={
                recommendSwitchesList.length > 0
                  ? recommendSwitchesList.map(sw => ({
                      value: sw.switch_id,
                      label: sw.switch_name,
                    }))
                  : switchAssetList.map(sw => ({ value: sw.id, label: sw.name }))
              }
              value={
                recommendSwitchesList.length > 0 ? selectedRecommendedSwitches : selectedSwitches
              }
              invalid={selectSwitchHasError}
              theme={theme}
              type="secondary"
              clearable={false}
              multiSelectType="checkbox"
              isMulti
              width={200}
              disabled={selectSwitchHasError || loadingSwitches}
              placeholder={loadingSwitches ? loadingMessage : undefined}
            />
            <p className="error-message">{(selectedAssetOutage && contingencyError) || ''}</p>
          </ResultsCard>
        </div>
      </div>
      <div className="contingency-settings section-four grid-columns margin-10">
        <div className="title">
          <div>
            <b>Timeseries range</b>
          </div>
        </div>

        <ResultsCard theme={theme} withBorder={false} className="contingency-settings-card">
          <CalendarPicker
            startDate={contingencySettings?.scenario_start}
            endDate={contingencySettings?.scenario_end}
            theme={theme}
            onApply={(newStart: string, newEnd: string) => {
              setContingencySettings({
                ...contingencySettings,
                scenario_start: newStart,
                scenario_end: newEnd,
              });
            }}
            onViewChanged={() => {}}
            styleFor="topnav"
            inputStyle="primary"
            subHourInterval={5}
          />
        </ResultsCard>

        <div className="title">
          <div>
            <b>Contingency settings</b>
          </div>
        </div>

        <div>
          <ResultsCard theme={theme} withBorder={false} className="contingency-settings-card">
            <CalendarPicker
              startDate={contingencySettings?.contingency_start}
              endDate={contingencySettings?.contingency_end}
              theme={theme}
              onApply={(newStart: string, newEnd: string) => {
                setContingencySettings({
                  ...contingencySettings,
                  contingency_start: newStart,
                  contingency_end: newEnd,
                });
              }}
              styleFor="topnav"
              inputStyle="primary"
              subHourInterval={5}
              onViewChanged={() => {}}
            />
          </ResultsCard>
        </div>
      </div>
    </div>
  );
};

export default SwitchContingenySettings;
