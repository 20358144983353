import { connect } from 'react-redux';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import LoadGenDistanceGraph from '../components/LoadGenDistanceGraph';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapStateToProps = state => ({
  shuntDevices: state.network.networkInstance.shuntDevices,
  nodeSubstationDistances: state.network.nodeSubstationDistances,
  theme: state.global.theme,
  resultsRequest: state.requests.qstsValues,
  distanceRequest: state.requests.getNodeSubstationDistance,
  aggType: state.network.timeBarZoomLevel,
  expanded: state.network.panelOptions.expanded,
  results: state.network.results,
  isAggregatedData: state.network.isAggregatedData,
});

export default connect(mapStateToProps)(LoadGenDistanceGraph);
