import Switch from './Switch';

class Disconnector extends Switch {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'switch';
    this._class = 'Disconnector';
    this.displayName = this.class;
  }
}

export default Disconnector;
