import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import asyncStates from 'helpers/asyncActionStates';
import classNames from 'classnames';

import ThemeContext from 'helpers/ThemeContext';

import { BETA_PERMISSION } from 'helpers/permissions';
import AssetSchedule from '../partials/AssetSchedule';
import EditableControlMode from '../partials/EditableControlMode';
import LifecycleSection from '../partials/LifecycleSection';
import ShuntDeviceHeader from '../partials/ShuntDeviceHeader';
import InstanceInfo from './InstanceInfo';
import UsagePointSection from './UsagePointSectionContainer';
import BackupNodeSelector from '../partials/BackupNodeSelector';

const scheduleHelp = (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <div>
      <b>
        Loads without schedules or outside of schedule limits will be subject to load allocation.
      </b>
    </div>
    <div>
      When uploading new schedule data it will add the new data to any existing schedule. If a
      timepoint in the new data is the same as an existing timepoint, the old data will be replaced.
    </div>
  </div>
);

const controlHelp = {
  scheduled: scheduleHelp,
};

const EnergyConsumer = ({
  asset: load,
  workspace,
  branch,
  displayBranch,
  selected: { id, class: assetClass },
  applyDifferenceModelRequest,
  toggleFeederPanel,
  canEditNetwork,
  inEditMode,
  loadForecast: { selectedScenario, selectedScenarioType, selectedAnalysis },
  setSelectedAssetID,
  editActions,
  expanded,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  isAuthEnabled,
  permissions,
  allFeeders,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'load', id, body);
  const controlMode = 'scheduled';
  const controlModeAttributes = {
    ...load.attributes,
  };

  return (
    <div
      className={classNames({
        'asset-panel-values': true,
        'asset-panel-values--expanded': expanded,
        'energy-consumer': true,
      })}
    >
      <ShuntDeviceHeader
        asset={load}
        assetId={id}
        toggleFeederPanel={toggleFeederPanel}
        inEditMode={inEditMode}
        disabled={saving || !canEditNetwork}
        setSelectedAssetID={setSelectedAssetID}
        handleSave={handleSave}
        phaseOptions={inEditMode ? 'single' : 'readOnly'}
      />
      <hr className="section-divider" />
      <div className="header-container">
        <div className="panel-header-row">
          <div className="left-label">Backup Nodes</div>
          <div className="dropdown">
            {inEditMode && (
              <BackupNodeSelector
                nodeId={load?.node?.id}
                existingBackupNode={load?.backup_nodes[0]?.id}
                handleSaveOnselectedValue={handleSave}
                allNodes={load?.backup_node_options}
                isDisabled={!canEditNetwork}
                allFeeders={allFeeders}
                workspace={workspace}
                branch={branch}
              />
            )}
            {!inEditMode && <span>{load?.backup_nodes[0]?.name}</span>}
          </div>
        </div>
      </div>
      <hr className="section-divider" />
      <InstanceInfo
        key={id}
        asset={load}
        assetId={id}
        toggleFeederPanel={toggleFeederPanel}
        handleSave={handleSave}
        displayBranch={displayBranch}
        branch={branch}
        workspace={workspace}
        inEditMode={inEditMode}
        disabled={!canEditNetwork || saving}
      />
      <UsagePointSection
        assetId={id}
        usagePoints={load.usage_points}
        phases={load.phases}
        disabled={!canEditNetwork || saving || !inEditMode}
      />

      {!inEditMode && permissions.has(BETA_PERMISSION) && (
        <>
          <hr className="section-divider" />
          <AssetSchedule
            id="energy-consumer-cost"
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Cost"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        </>
      )}
      <hr className="section-divider" />

      <EditableControlMode
        controlMode={controlMode}
        help={controlHelp[controlMode]}
        asset={assetClass}
        attributes={controlModeAttributes}
        disabled
        onSave={handleSave}
        theme={theme}
        inEditMode={inEditMode}
        selectedAnalysis={selectedAnalysis}
        selectedAssetId={id}
        workspace={workspace}
        branch={branch}
      >
        {controlMode === 'scheduled' && !inEditMode && (
          <AssetSchedule
            id="energy-consumer-normal"
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Normal"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            panelValues={{ ...controlModeAttributes }}
            editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        )}
      </EditableControlMode>

      <hr className="section-divider" />
      <LifecycleSection
        disabled={saving || !canEditNetwork || !inEditMode}
        lifecycle={load.lifecycle}
        onChange={lifecycle => handleSave({ lifecycle })}
      />
    </div>
  );
};

EnergyConsumer.propTypes = {
  asset: PropTypes.object.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  editActions: PropTypes.object.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
  }).isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
    selectedAnalysis: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  allFeeders: PropTypes.array.isRequired,
};

export default EnergyConsumer;
