import React, { FunctionComponent } from 'react';
import Select from 'components/Select';
import moment, { Moment } from 'moment';

type TimeSelectorType = {
  clearable?: boolean;
  minTime?: Moment;
  maxTime?: Moment;
  initialTime: Moment;
  selectedTime: Moment | null;
  disabled?: boolean;
  onTimeChange: (arg: { value: string }) => void;
  selectType?: 'default' | 'secondary';
  placeholder?: string;
};

const TimeSelector: FunctionComponent<TimeSelectorType> = ({
  clearable = false,
  minTime = null,
  maxTime = null,
  initialTime,
  selectedTime = null,
  disabled = false,
  onTimeChange,
  selectType = 'default',
  placeholder = '',
}) => {
  const isDisabled = (i: number, current: Moment) => {
    const time = moment(current).hour(i);
    return (
      (minTime && time.isBefore(minTime.startOf('hour'))) ||
      (maxTime && time.isAfter(maxTime.endOf('hour')))
    );
  };
  const generateTimeOptions = (current: Moment) =>
    [...Array(24).keys()].map(i => {
      const timeStamp = moment(current).hour(i).minute(current.minutes()).format('HH:mm');
      return {
        value: timeStamp,
        label: timeStamp,
        disabled: isDisabled(i, current) ?? false,
      };
    });
  return (
    <Select
      searchable={false}
      clearable={clearable}
      options={generateTimeOptions(initialTime)}
      value={selectedTime?.format('HH:mm') ?? null}
      width={100}
      onChange={onTimeChange}
      disabled={disabled}
      type={selectType}
      placeholder={placeholder}
    />
  );
};

export default TimeSelector;
