import { connect } from 'react-redux';

import ResultsPanel from '../components/Results/ResultsPanel';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  analysisSettings: state.global.settings.analysis,
  selectedAnalysis: state.loadForecast.selectedAnalysis,
  results: state.network.results,
  subHourInterval: state.loadForecast.subHourInterval,
});

export default connect(mapStateToProps)(ResultsPanel);
