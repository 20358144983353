import Switch from './Switch';

class Sectionaliser extends Switch {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'sectionaliser';
    this._class = 'Sectionaliser';
    this.displayName = this.class;
  }
}

export default Sectionaliser;
