import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import CustomCheckbox from 'components/CustomCheckbox';
import TextInput from 'components/TextInput';
import NumberInput from 'components/NumberInput';

import { isDefined } from 'helpers/utils';
import { amps, kA, kV } from 'helpers/units';

import Helpers from '../../helpers/EquipmentLibraryHelpers';
import {
  areAssetModelPropertiesChanged,
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
  defaultEmergencyRatings,
  isValidEmergencyRatings,
  emergencyRatingsUpdated,
  updateEmRatings,
} from '../../helpers/assetModelHelpers';
import DescriptionEditor from './DescriptionEditor';
import CostEditor from './CostEditor';
import ReliabilityMetrics from './ReliabilityMetrics';
import PanelTabs from './PanelTabs';

import './common.scss';
import EmergencyRatings from '../EmergencyRatings';

const getNewSwitch = () => ({
  id: 'add',
  name: '',
  description: '',
  ratedCurrent: '',
  breakingCapacity: '',
  ratedVoltage: '',
  assetModel: { ...defaultAssetModel },
  isUnganged: false,
  isValidShiftFactor: true,
  emergencyRatings: { ...defaultEmergencyRatings(['emCurrent']) },
});

const supportedEMFields = ['emCurrent'];

class SwitchPanel extends Component {
  state = { ...getNewSwitch() };

  UNSAFE_componentWillMount() {
    this.setState({ ...this.extractFormValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.setState({ ...this.extractFormValues(nextProps.selected) });
    }
  }

  extractFormValues = instance => {
    let asset = { ...getNewSwitch() };

    if (instance) {
      const {
        ratedVoltage,
        ratedCurrent,
        breakingCapacity,
        isUnganged,
        name,
        id,
        description,
        AssetModel,
        alternateLimitSet = {},
      } = instance;
      asset = {
        ratedVoltage: isDefined(ratedVoltage) ? `${ratedVoltage / 1000}` : asset.ratedVoltage,
        breakingCapacity: isDefined(breakingCapacity)
          ? `${breakingCapacity / 1000}`
          : asset.breakingCapacity,
        ratedCurrent: isDefined(ratedCurrent) ? `${ratedCurrent}` : asset.ratedCurrent,
        isUnganged: isUnganged || asset.isUnganged,
        description,
        name,
        id,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        emergencyRatings: {
          longTermEmergency: {
            currentLimit: alternateLimitSet.longTermEmergency?.['CurrentLimit.value'] ?? null,
          },
          shortTermEmergency: {
            currentLimit: alternateLimitSet.shortTermEmergency?.['CurrentLimit.value'] ?? null,
          },
        },
      };
    }
    return asset;
  };

  handleInputChange = ({ id, value }) => this.setState({ [id]: value });

  handleCreate = () => {
    const { isUnganged, name, description, emergencyRatings } = this.state;
    const diffModel = {
      ratedCurrent: parseFloat(this.state.ratedCurrent),
      ratedVoltage: parseFloat(this.state.ratedVoltage) * 1000,
      breakingCapacity: parseFloat(this.state.breakingCapacity) * 1000,
      isUnganged,
      description,
      name,
      AssetModel: { ...this.state.assetModel },
      EmergencyRatings: emergencyRatings,
    };
    if (this.state.id === 'add') {
      this.props.handleCreate('switch_info', diffModel, 'SwitchInfo');
    } else {
      // Only create difference model for values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        if (key === 'AssetModel') {
          if (!areAssetModelsEqual(diffModel[key], selected[key])) {
            diff[key] = diffModel[key];
          }
        } else if (
          (key !== 'EmergencyRatings' && diffModel[key] !== selected[key]) ||
          (key === 'EmergencyRatings' &&
            emergencyRatingsUpdated(selected.alternateLimitSet, diffModel[key], supportedEMFields))
        ) {
          diff[key] = diffModel[key];
        }
        return diff;
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  };

  valuesUpdated = selected => {
    if (!selected) return true;

    const {
      ratedCurrent,
      ratedVoltage,
      name,
      isUnganged,
      breakingCapacity,
      description,
      assetModel,
      emergencyRatings,
    } = this.state;

    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selected.AssetModel),
      assetModel,
    );
    return (
      selected.name !== name ||
      selected.ratedCurrent !== parseFloat(ratedCurrent) ||
      selected.ratedVoltage !== parseFloat(ratedVoltage) * 1000 ||
      selected.breakingCapacity !== parseFloat(breakingCapacity) * 1000 ||
      selected.isUnganged !== isUnganged ||
      selected.description !== description ||
      assetModelUpdated ||
      emergencyRatingsUpdated(selected.alternateLimitSet, emergencyRatings, supportedEMFields)
    );
  };

  shiftFactorValid = (isShiftFactorValid = false) =>
    this.setState({ isValidShiftFactor: isShiftFactorValid });

  formValid = () =>
    parseFloat(this.state.ratedCurrent) > 0 &&
    parseFloat(this.state.ratedVoltage) > 0 &&
    parseFloat(this.state.breakingCapacity) > 0 &&
    this.state.name.trim().length > 0 &&
    isAssetModelValid(this.state.assetModel) &&
    this.valuesUpdated(this.props.selected) &&
    this.state.isValidShiftFactor &&
    isValidEmergencyRatings(this.state.emergencyRatings);

  getInvalid() {
    const { ratedCurrent, ratedVoltage, breakingCapacity } = this.state;
    return {
      ratedCurrent: parseFloat(ratedCurrent) <= 0,
      ratedVoltage: parseFloat(ratedVoltage) <= 0,
      breakingCapacity: parseFloat(breakingCapacity) <= 0,
    };
  }

  updateAssetModel = prop =>
    this.setState(prevState => ({
      assetModel: {
        ...prevState.assetModel,
        ...prop,
      },
    }));

  render() {
    const isDisabled =
      this.props.isAuthEnabled &&
      ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type')) ||
        (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type')) ||
        (this.props.match.params.branch === 'master' &&
          !this.props.permissions.has('modify_network_as_built')));
    const defaultProps = {
      disabled: isDisabled,
    };
    // 0 for these values is not valid
    const ratedCurrent = Helpers.createDisplayObject(
      'Rated Current',
      'ratedCurrent',
      this.state.ratedCurrent,
      amps,
    );
    const ratedVoltage = Helpers.createDisplayObject(
      'Rated Voltage',
      'ratedVoltage',
      this.state.ratedVoltage,
      kV,
    );
    const breakingCapacity = Helpers.createDisplayObject(
      'Breaking Capacity',
      'breakingCapacity',
      this.state.breakingCapacity,
      kA,
    );

    const invalid = this.getInvalid();

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleCreate}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs', 'Reliability Metrics', 'Emergency Ratings']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id} id="general-tab">
            <div className="right-panel">
              <TextInput
                onChange={({ target }) =>
                  this.handleInputChange({ id: target.id, value: target.value })
                }
                {...defaultProps}
                id="name"
                label="Name"
                value={this.state.name}
                required
              />
              <NumberInput
                {...defaultProps}
                {...ratedCurrent}
                required
                invalid={invalid.ratedCurrent}
                validationMessage="Rated current must be greater than 0."
                inputStyle="eq-lib"
                onChange={this.handleInputChange}
              />
              <NumberInput
                {...defaultProps}
                {...breakingCapacity}
                required
                invalid={invalid.breakingCapacity}
                validationMessage="Breaking capacity must be greater than 0."
                inputStyle="eq-lib"
                onChange={this.handleInputChange}
              />
              <NumberInput
                {...defaultProps}
                {...ratedVoltage}
                required
                invalid={invalid.ratedVoltage}
                validationMessage="Rated voltage must be greater than 0."
                inputStyle="eq-lib"
                onChange={this.handleInputChange}
              />
              <div className="checkbox-row">
                <div className="checkbox-label">
                  <p>Ganged</p>
                </div>
                <div className="checkbox">
                  <CustomCheckbox
                    disabled={isDisabled}
                    id="isGanged"
                    onClick={() =>
                      this.setState(prevState => ({ isUnganged: !prevState.isUnganged }))
                    }
                    checked={!this.state.isUnganged}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown escapeHtml source={decodeURIComponent(this.state.description)} />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={
              areAssetModelPropertiesChanged(
                this.state.assetModel,
                getAssetModelProperties(this.props.selected?.AssetModel),
              )[1]
            }
            key={`${this.state.id}-costs`}
            onChange={prop =>
              this.setState(prevState => ({
                assetModel: { ...prevState.assetModel, ...prop },
              }))
            }
            isDisabled={isDisabled}
          />,
          <ReliabilityMetrics
            assetModel={this.state.assetModel || null}
            probabilityOfFailureEquation={
              this.state.assetModel.AssetFailureInfo.probabilityOfFailureEquation || null
            }
            mTTR={this.state.assetModel.AssetFailureInfo.mTTR}
            key={`${this.state.id}-reliability`}
            onChange={this.updateAssetModel}
            isDisabled={isDisabled}
            saveLibraryError={this.props.saveLibraryError}
            shiftFactorValid={this.shiftFactorValid}
          />,
          <EmergencyRatings
            key={`${this.state.id}-emergency-ratings`}
            defaultProps={defaultProps}
            onChange={(arg, limitField) =>
              this.setState(prevState => updateEmRatings(arg, limitField, prevState))
            }
            emergencyRatings={this.state.emergencyRatings}
            supportedFields={supportedEMFields}
          />,
        ]}
      </PanelTabs>
    );
  }
}

SwitchPanel.defaultProps = {
  selected: undefined,
  saveLibraryError: {},
};

SwitchPanel.propTypes = {
  selected: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  saveLibraryError: PropTypes.object,
};

export default SwitchPanel;
