import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withAuth from 'helpers/withAuth';
import { actions } from '../modules/network';
import { powerflowActions } from '../modules/powerflow';
import { networkEditActions } from '../modules/networkEdit';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import DeckGLMap from '../components/DeckGLMap';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...actions,
      ...powerflowActions,
    },
    dispatch,
  ),
  editActions: bindActionCreators(
    {
      ...networkEditActions,
    },
    dispatch,
  ),
});

const mapStateToProps = (state, ownProps) => ({
  selectedFeeders: state.feeders.selected,
  selectedAsset: state.network.selectedNode,
  selectedAssetID: state.network.selectedAssetID,
  hoveredAssetID: state.network.hoveredAssetID,
  assetTypeVisibility: state.network.assetTypeVisibility,
  theme: state.global.theme,
  selectedVisualizationLayer: state.network.panelOptions.selectedLayer,
  layerOptions: state.powerflow.layerOptions,
  networkGeoJSON: state.network.networkGeoJSON,
  flyToAsset: state.network.flyToAsset,
  hostingCapacityTimepointData: state.powerflow.hostingCapacityTimepointData,
  operationalData: state.powerflow.operationalData,
  evCapacityTimepointData: state.powerflow.evCapacityTimepointData,
  batterySizingData: state.powerflow.batterySizingData,
  inEditMode: state.edit.inEditMode,
  inPathEditMode: state.edit.inPathEditMode,
  inPathCreateMode: state.edit.inPathCreateMode,
  newAsset: state.edit.newAsset,
  editLineRequest: state.network.applyDifferenceModelRequest.editLinePosition,
  editNodeRequest: state.network.applyDifferenceModelRequest.editNodePosition,
  editShuntRequest: state.network.applyDifferenceModelRequest.editShuntPosition,
  discardPathEdit: state.edit.discardPathEdit,
  addAssetRequest: state.network.addAssetRequest,
  workspace: state.network.workspace,
  branch: state.network.branch,
  violations: state.powerflow.GISViolations,
  violationDisplayMin: state.powerflow.violationDisplayMin,
  results: state.network.results,
  analysisSettings: state.global.settings.analysis,
  leftRailPanel: state.network.leftRailPanel,
  ...ownProps,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(DeckGLMap));
