import React, { FunctionComponent, useEffect } from 'react';
import { settings, unitInfos } from 'types/edit';
import Select from 'components/Select';
import getControlModeOptions from 'helpers/cim/getControlModeName';

type BulkAssetPVSettingsProps = {
  assetSettings: settings;
  customerTypesofLoads: string[];
  setAssetSettings: (agrs: settings) => void;
  invInfos?: unitInfos;
  pvInfos?: unitInfos;
};

const BulkAssetPVSettings: FunctionComponent<BulkAssetPVSettingsProps> = ({
  assetSettings,
  setAssetSettings,
  customerTypesofLoads,
  invInfos = [],
  pvInfos = [],
}) => {
  // set globallyOptimized by default during loading
  useEffect(() => {
    const newSettings = customerTypesofLoads.reduce<settings>((updatedSettings, type) => {
      const newSetting = assetSettings[type] ?? {};
      newSetting.control_mode = 'scheduled';
      updatedSettings[type] = newSetting;
      return updatedSettings;
    }, {});
    setAssetSettings({ ...assetSettings, ...newSettings });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAssetSettings, customerTypesofLoads]);
  return (
    <div className="pv-settings">
      {customerTypesofLoads.map(type => (
        <div className="asset-type margin-10" key={type}>
          {type !== 'all' && (
            <p className="text-bold type-title">{type === '' ? 'Unspecified loads' : type}</p>
          )}
          <div className="grid-columns one-one">
            <Select
              label="Inverter type"
              onChange={e =>
                setAssetSettings({
                  ...assetSettings,
                  [type]: { ...assetSettings[type], asset_info: e.value },
                })
              }
              id={`select-${type}`}
              options={invInfos.map(inv => ({ value: inv.id, label: inv.name }))}
              value={assetSettings[type]?.asset_info}
              type="secondary"
              clearable={false}
            />
            <Select
              label="PV type"
              onChange={e =>
                setAssetSettings({
                  ...assetSettings,
                  [type]: { ...assetSettings[type], unit_info: e.value },
                })
              }
              id={`select-${type}`}
              options={pvInfos.map(inv => ({ value: inv.id, label: inv.name }))}
              value={assetSettings[type]?.unit_info}
              type="secondary"
              clearable={false}
            />
            <Select
              label="Control Mode"
              onChange={e =>
                setAssetSettings({
                  ...assetSettings,
                  [type]: { ...assetSettings[type], control_mode: e.value },
                })
              }
              options={getControlModeOptions('InverterPV')}
              value={assetSettings[type]?.control_mode}
              type="secondary"
              clearable={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default BulkAssetPVSettings;
