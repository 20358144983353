const assetClass = {
  shunt: [
    'AsynchronousMachine',
    'Battery',
    'CHP',
    'EnergyConsumer',
    'EnergySource',
    'EquivalentSubstation',
    'InverterPV',
    'LinearShuntCompensator',
    'PV',
    'RunOfRiverHydro',
    'SynchronousMachine',
    'Wind',
    'ElectricVehicleChargingStation',
  ],
  link: [
    'ACLineSegment',
    'Breaker',
    'Cut',
    'Disconnector',
    'Fuse',
    'Jumper',
    'Recloser',
    'Regulator',
    'Sectionaliser',
    'Switch',
    'PowerTransformer',
    'SeriesCompensator',
  ],
};

export type AnalysisSettings = {
  invertRealPower: boolean | undefined;
  invertReactivePower: boolean | undefined;
};

export const shouldInvertRealPower = (klass: string, analysisSettings: AnalysisSettings): boolean =>
  (klass === 'EnergySource') !== (analysisSettings.invertRealPower ?? false);
export const shouldInvertReactivePower = (
  klass: string,
  analysisSettings: AnalysisSettings,
): boolean => (klass === 'EnergySource') !== (analysisSettings.invertReactivePower ?? false);
export const expectRealPowerTimeSeriesData = (klass: string): boolean =>
  klass !== 'LinearShuntCompensator' && assetClass.shunt.includes(klass);
export const expectReactivePowerTimeSeriesData = (klass: string): boolean =>
  assetClass.shunt.includes(klass);
export const invertScheduleOnResultsGraph = (klass: string): boolean =>
  ['AsynchronousMachine', 'SynchronousMachine', 'InverterPV'].includes(klass);

export default assetClass;
