import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

const AddAssetButton = ({
  icon,
  name,
  id,
  selected,
  addingAsset,
  addingBulkAsset,
  disabled,
  onClick,
  className,
  active,
  buttonInfoLabel,
}) => (
  <div
    className={classNames('add-icon-container', {
      'add-icon-container--disabled': disabled,
      [className]: className,
    })}
  >
    <button
      onClick={e => onClick(e)}
      id={id}
      className={classNames('icon-button', {
        'icon-button--active': active,
        'btn-overlap-loading': !buttonInfoLabel,
      })}
      disabled={disabled}
      type="button"
    >
      <ReactSVG
        className={classNames(`icon-img icon-img--${id}`, { 'icon-img--disabled': disabled })}
        src={`/${icon}.svg`}
      />
      {buttonInfoLabel && <div className="icon-label">{buttonInfoLabel}</div>}
      {((selected === id && addingAsset) || addingBulkAsset) && (
        <div className="loading-icon">
          <i className="material-icons rotate">refresh</i>
        </div>
      )}
    </button>
    {name && <p className="icon-label">{name}</p>}
  </div>
);

AddAssetButton.defaultProps = {
  selected: null,
  addingAsset: false,
  addingBulkAsset: false,
  disabled: false,
  className: undefined,
  active: false,
  buttonInfoLabel: null,
  name: null,
};

AddAssetButton.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  selected: PropTypes.string,
  addingAsset: PropTypes.bool,
  addingBulkAsset: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
  buttonInfoLabel: PropTypes.string,
};

export default AddAssetButton;
