import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ValidateNetwork from './components/ValidateNetwork';
import ErrorType from './routes/ErrorType';

const ValidateRoutes = () => (
  <Switch>
    <Route exact path="/:workspace/:branch/import/validate/">
      <ValidateNetwork />
    </Route>
    <Route
      path={[
        '/:workspace/:branch/import/validate/type/:type/:id',
        '/:workspace/:branch/import/validate/type/:type',
      ]}
    >
      <ErrorType />
    </Route>
  </Switch>
);

export default ValidateRoutes;
