import React, { FunctionComponent, useState, useEffect, useMemo, useContext } from 'react';
import { useRequestEffect, Request } from '@opusonesolutions/gridos-app-framework';
import ResultsCard from 'components/ResultsCard';
import BranchSection from 'routes/WorkspaceLayout/NetworkTopNav/BranchSection';
import { isEmptyObject } from 'helpers/utils';
import Analytics from 'helpers/Analytics';
import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import moment, { Moment } from 'moment';
import { ThemeProp } from 'types/index';
import { Analysis } from 'types/analysis';
import CalendarPicker from 'components/CalendarPicker';
import ConfirmModal from 'components/ConfirmModal';
import { useToasts } from 'react-toast-notifications';
import asyncActionStates from 'helpers/asyncActionStates';
import ActivityLogContextProvider from 'contexts/ActivityLogContext';
import fileExportSave from 'helpers/FileDownload';
import { Scenario } from 'helpers/scenarios';
import './ResultsComparisonCard.scss';
import ScenarioSelect from 'routes/WorkspaceLayout/NetworkTopNav/ScenarioSelect';
import {
  determineDefaultZoomLevel,
  getEndOfInterval,
} from 'components/ZoomableRangeSlider/intervals';
import {
  getAnalyses,
  ANALYSIS_TYPES,
  determineScenarioTimeSpan,
  getAnalysisTimepoints,
} from '../../Network/helpers/NetworkHelpers';
import ResultsSummary from './ResultsSummary';
import { ResultsComparisonContext } from '../context/ResultsComparisonContext';
import AnalysisSelection from './AnalysisSelection';
import { Views } from '../helpers/ResultsComparisonHelpers';

type ResultsComparisonCardProps = {
  theme?: ThemeProp;
  branches?: [];
  selectedScenario?: string | null;
  selectedAnalysis?: Analysis;
  scenarios?: Scenario[];
  match?: {
    params: {
      workspace: string | null;
      branch: string | null;
    };
  };
  maxRange?: {
    start?: Moment | null;
    end?: Moment | null;
  };
  permissions?: Set<string>;
  removeResultsCard: (id: number) => void | null | number;
  id: number;
  isBaseline: boolean;
  title: string;
  viewType: Views;
};
const ResultsComparisonCard: FunctionComponent<ResultsComparisonCardProps> = ({
  theme = 'light',
  branches = [],
  selectedScenario = null,
  scenarios = [],
  match,
  permissions,
  maxRange,
  selectedAnalysis = null,
  removeResultsCard,
  id,
  isBaseline,
  title,
  viewType,
}) => {
  const { addToast } = useToasts();
  const workspace = match?.params?.workspace || null;
  const [resultsBranch, setBranch] = useState(match?.params?.branch || null);
  const [allScenarios, setScenarios] = useState(scenarios);
  const [scenariosLoading, setScenariosLoading] = useState(false);
  const [scenario, setScenario] = useState(selectedScenario || null);
  const [maxRangeSelected, setMaxRangeSelected] = useState(maxRange || {});
  const [analysesList, setAnalyses] = useState([]);
  const [analysis, setSelectedAnalysis] = useState(selectedAnalysis || null);
  const [modalActive, setModalActive] = useState(false);

  const [reportLoading, setReportLoading] = useState(false);
  type PowerflowResults = {
    analysis_configuration: { interval: number };
  };
  const { data: resultsData } = useRequestEffect<PowerflowResults>({
    url: `/api/workspace/${workspace}/branch/${resultsBranch}/analysis/${analysis?.id}`,
    method: 'get',
    refetchOnChange: [scenario, analysis, analysis?.id, workspace, resultsBranch],
    blockRequest: () => !(scenario && analysis && workspace && resultsBranch),
  });
  const {
    baselineResults: { baselineViolations },
    setBaselineResults,
  } = useContext(ResultsComparisonContext);
  const resultsType = 'baselineViolations';
  const fetchAnalyses = useMemo(
    () => async (scenarioID: string) => {
      let allAnalyses = [];
      try {
        if (scenarioID && workspace && resultsBranch) {
          allAnalyses = await getAnalyses(workspace, resultsBranch, scenarioID, permissions);
          allAnalyses = allAnalyses.filter(
            (analysisSingle: any) =>
              ![
                ANALYSIS_TYPES.HOSTING_CAPACITY,
                ANALYSIS_TYPES.EV_CAPACITY,
                ANALYSIS_TYPES.BATTERY_SIZING,
                ANALYSIS_TYPES.OPERATIONAL_ENVELOPE,
              ].includes(analysisSingle.type),
          );
        }
      } catch (err) {}
      setAnalyses(allAnalyses);
    },
    [permissions, resultsBranch, workspace],
  );

  const updateMaxRange = async (scenarioId: string | null, analysisSel: Analysis | null = null) => {
    let start;
    let end;
    let subHourInterval = 5; // 5 min if no analysis is selected
    try {
      if (workspace && resultsBranch && scenarioId && analysisSel && !isEmptyObject(analysisSel)) {
        subHourInterval = analysisSel.interval;
        const timepoints = await getAnalysisTimepoints(
          workspace,
          resultsBranch,
          scenarioId,
          analysisSel.containers.map(f_id => ({ id: f_id })),
          analysisSel,
        );
        if (timepoints.length > 0) {
          start = moment.utc(timepoints[0]);
          end = moment.utc(timepoints[timepoints.length - 1]);
        }
      } else if (scenarioId) {
        const timeSpan = await determineScenarioTimeSpan(workspace, resultsBranch, scenarioId);
        start = timeSpan.start;
        end = timeSpan.end;
      }
    } catch (err) {
      start = null;
      end = null;
    }
    if (start && end) {
      const zoomLevel = determineDefaultZoomLevel(start, end, subHourInterval);
      end = getEndOfInterval(end, zoomLevel);
    }
    setMaxRangeSelected({ start, end });
  };

  const fetchScenarios = useMemo(
    () => async () => {
      const url = `/api/workspace/${workspace}/branch/${resultsBranch}/qsts_scenarios`;
      setScenariosLoading(true);
      try {
        const results = await new Request(url).get();
        const allScenariosData = results.data.map((sc: any) => ({
          value: sc.id,
          label: sc.name,
          type: sc.scenario_type,
        }));
        setScenarios(allScenariosData);
      } catch (err) {
        setScenarios([]);
      } finally {
        setScenariosLoading(false);
      }
    },
    [resultsBranch, workspace],
  );

  const getTimepoints = async (start: any, end: any, aggregation: any) => {
    const params = {
      start_date: start.toISOString(),
      end_date: end.toISOString(),
      aggregation,
    };

    const violationsTimepointsRequest = new Request(
      `/api/workspace/${workspace}/branch/${resultsBranch}/power-flow-results/violations/per-time`,
    );

    const feederScheduleTimepointsRequest = new Request(
      `/api/workspace/${workspace}/branch/${resultsBranch}/qsts_scenarios/${scenario}/timepoints`,
    );

    let violationsTimepoints = [];
    let feederScheduleTimepoints = [];

    if (
      analysis &&
      [ANALYSIS_TYPES.POWERFLOW, ANALYSIS_TYPES.QSTS, ANALYSIS_TYPES.QSTS_OPF].includes(
        analysis?.type,
      )
    ) {
      try {
        const pfResponse = await violationsTimepointsRequest.get({
          params: {
            ...params,
            scenario_id: scenario,
            analysis_name: analysis.name,
            feeder: analysis.containers,
          },
        });
        violationsTimepoints = pfResponse.data.map((timepoint: any) => moment.utc(timepoint));
      } catch {}
    }

    try {
      const scResponse = await feederScheduleTimepointsRequest.get({ params });
      feederScheduleTimepoints = scResponse.data.map((timepoint: any) => moment.utc(timepoint));
    } catch {}
    return {
      violations: violationsTimepoints,
      scenario: feederScheduleTimepoints,
    };
  };

  const ConfirmModalConfig = () => {
    const modalBody = (
      <p className="modal-message__p">
        You can add another result later, but your current selection will not be retained
      </p>
    );
    let deleteStatus = asyncActionStates.INITIAL;
    return {
      modalActive,
      closeModal: () => setModalActive(false),
      deleteItem: () => {
        const deleteResultCardStatus = removeResultsCard(id) as number;
        deleteStatus = deleteResultCardStatus;
        if (deleteResultCardStatus === asyncActionStates.SUCCESS) {
          addToast(`${title} has been removed!`, { appearance: 'success' });
          setModalActive(false);
        }
      },
      title: `Remove ${title}?`,
      modalBody,
      theme,
      deleteStatus,
    };
  };

  const downloadResultsComparisonReport = async () => {
    const url = `/api/workspace/${workspace}/branch/${resultsBranch}/power-flow-results/results-comparison/report`;
    setReportLoading(true);
    try {
      const request = new Request(url);
      const { data, headers } = await request.getFile({
        params: {
          feeder: analysis?.containers ?? [],
          scenario_id: scenario,
          start_date: maxRangeSelected?.start?.toISOString(),
          end_date: maxRangeSelected?.end?.toISOString(),
          analysis_name: analysis?.name,
        },
      });
      fileExportSave(data, headers);
      setReportLoading(false);
    } catch (err) {
      setReportLoading(false);
    }
  };

  useEffect(() => {
    fetchScenarios();
  }, [workspace, resultsBranch, fetchScenarios]);

  useEffect(() => {
    if (scenario) fetchAnalyses(scenario);
  }, [fetchAnalyses, scenario]);

  const bucketOptions = useMemo(() => {
    const buckets = new Set<number>();
    if (baselineViolations?.violationsMag) {
      Object.values(baselineViolations?.violationsMag).forEach((opts: any) => {
        opts.forEach((violation: any) => {
          buckets.add(violation.name);
        });
      });
    }
    return [...buckets]
      .map(val => ({ label: `${val}%`, value: val }))
      .sort((x, y) => x.value - y.value);
  }, [baselineViolations]);
  return (
    <ActivityLogContextProvider workspace={workspace} branch={resultsBranch}>
      <div data-test={`results-comparison-${title}`}>
        <>
          <ResultsCard
            isExpanded
            theme={theme}
            title={
              isBaseline ? (
                <div className="primary-title">
                  {`Primary ${title}`}
                  <Tooltip placement="top" content="See documentation" theme={theme}>
                    <a
                      href="/documentation/results-comparison/introduction.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="material-icons help-icon primary-help-icon">help_outline</i>
                    </a>
                  </Tooltip>
                </div>
              ) : (
                title
              )
            }
            className="results-comparison-card title-bold top-section"
            onClose={() => {
              if (isBaseline) {
                addToast(`${title} can not be removed!`, { appearance: 'error' });
              } else {
                setModalActive(true);
              }
            }}
            isDownloadDisable={
              !(
                workspace &&
                resultsBranch &&
                scenario &&
                analysis &&
                analysis?.containers?.length &&
                !isEmptyObject(analysis)
              )
            }
            downloadLoading={reportLoading}
            onDownload={() => downloadResultsComparisonReport()}
            expandableContents={
              <>
                <div className="grid-columns one-one margin-10">
                  <ScenarioSelect
                    scenario={scenario}
                    scenarios={allScenarios}
                    onScenarioChange={scenarioId => {
                      // clear max range & analysis first to ensure we don't send
                      // a request with the new scenario but old maxRange/analysis
                      setMaxRangeSelected({ start: null, end: null });
                      setSelectedAnalysis(null);
                      setScenario(scenarioId);
                      updateMaxRange(scenarioId);
                      Analytics.logEvent(`Scenario Selected for ${title}`, 'Scenarios');
                    }}
                    theme={theme}
                    type="secondary"
                    loading={scenariosLoading}
                    label={<p className="select-label">Scenarios</p>}
                  />
                  <CalendarPicker
                    startDate={
                      maxRangeSelected?.start?.toISOString() ||
                      moment.utc().startOf('day').toISOString()
                    }
                    endDate={
                      maxRangeSelected?.end?.toISOString() ||
                      moment.utc().endOf('day').startOf('hour').toISOString()
                    }
                    theme={theme}
                    onApply={(newStart: string, newEnd: string) => {
                      setMaxRangeSelected({ start: moment.utc(newStart), end: moment.utc(newEnd) });
                      Analytics.logEvent(`Timeframe change for ${title}`, 'Timeframe');
                    }}
                    onViewChanged={getTimepoints}
                    dots={['scenario', 'violations']}
                    styleFor="topnav"
                    subHourInterval={
                      resultsData && analysis && analysis?.id
                        ? resultsData.analysis_configuration?.interval
                        : 5
                    }
                  />
                </div>
                <div className="grid-columns one-one margin-10">
                  <AnalysisSelection
                    analysesList={analysesList}
                    analysis={analysis}
                    resultsBranch={resultsBranch}
                    scenario={scenario}
                    setSelectedAnalysis={value => {
                      // clear max range first to ensure we don't send
                      // a request with the new analysis but old maxRange
                      setMaxRangeSelected({ start: null, end: null });
                      setSelectedAnalysis(value);
                      updateMaxRange(scenario, value);
                    }}
                    title={title}
                  />
                  {isBaseline && viewType === Views.overview && (
                    <div>
                      <p className="select-label space-bottom">Over limit (%)</p>
                      <Select
                        onChange={(e: any) => {
                          if (isBaseline) {
                            setBaselineResults(resultsType, {
                              ...baselineViolations,
                              violationLimit: e.value,
                            });
                            Analytics.logEvent(`Violation change for ${title}`, 'Violation Limit');
                          }
                        }}
                        id="limit"
                        options={bucketOptions}
                        value={baselineViolations?.violationLimit ?? 0}
                        theme={theme}
                        disabled={!isBaseline}
                        type="secondary"
                        clearable={false}
                      />
                    </div>
                  )}
                </div>
              </>
            }
          >
            <BranchSection
              workspace={workspace}
              branch={resultsBranch}
              branches={branches}
              theme={theme}
              permissions={new Set([])}
              view="comparison"
              updateSelectedBranch={(e: any) => {
                setBranch(e);
                setScenario('');
                setAnalyses([]);
                setSelectedAnalysis(null);
                setMaxRangeSelected(maxRange || {});
                Analytics.logEvent(`Network Version updated for ${title}`, 'Network Version');
              }}
              selectType="primary"
            />
          </ResultsCard>
          <ConfirmModal {...ConfirmModalConfig()} />
          <ResultsSummary
            key={`results-summary-${title}`}
            theme={theme}
            violationLimit={baselineViolations?.violationLimit ?? 0}
            workspace={workspace}
            branch={resultsBranch}
            scenario={scenario}
            analysis={analysis}
            maxRange={maxRangeSelected}
            setViolationType={(value: string) => {
              if (isBaseline) {
                setBaselineResults(resultsType, {
                  ...baselineViolations,
                  violationType: value,
                });
                Analytics.logEvent(`Violation change for ${title}`, 'Violation Type');
              }
            }}
            violationType={baselineViolations?.violationType ?? 'All'}
            isBaseline={isBaseline}
            viewType={viewType}
          />
        </>
      </div>
    </ActivityLogContextProvider>
  );
};

export default ResultsComparisonCard;
