import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const SortingIcon: FunctionComponent<IconPropsSchema> = ({ color, height, width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width={width}
    height={height}
    viewBox="0 0 16.4 13.4"
  >
    <g>
      <rect y="0" fill={color} width="16.4" height="2" />
      <rect y="5.7" fill={color} width="10.7" height="2" />
      <rect y="11.4" fill={color} width="5" height="2" />
    </g>
  </svg>
);

export default SortingIcon;
