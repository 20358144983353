import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Button from '../Button';

// Simple top nav bar for routes that have no custom navigation/controls.
const TopNav = props => {
  const history = useHistory();

  return (
    <div className="top-nav-container">
      <Button
        type="text"
        onClick={() => {
          if (props.backURL) {
            history.push(props.backURL);
          } else {
            history.goBack();
          }
        }}
      >
        <div className="nav-back__a">
          <i className="material-icons">arrow_back</i>
          <div className="nav-back-name">{props.label}</div>
        </div>
      </Button>
    </div>
  );
};

TopNav.defaultProps = {
  backURL: '',
};

TopNav.propTypes = {
  backURL: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default TopNav;
