import React, { FunctionComponent } from 'react';
import Modal from 'components/Modal';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import { ReactSVG } from 'react-svg';
import { useAuthContext, Request } from '@opusonesolutions/gridos-app-framework';
import fileExportSave from 'helpers/FileDownload';

type AboutProp = {
  onCancel: () => void;
};
const About: FunctionComponent<AboutProp> = ({ onCancel }) => {
  const { productDetails } = useAuthContext();
  const downloadReport = async (baseURL: string, fileName = '') => {
    const request = new Request(baseURL);
    let result;
    try {
      const { data, headers } = await request.getFile();
      fileExportSave(data, headers, fileName);
    } catch (err) {
      result = (err as any)?.response?.status;
    }
    return result;
  };

  return (
    <div className="about">
      <Modal active showFooter={false} onCancel={onCancel} width="500px" height="466px">
        <ReactSVG src="/opus-ge-logo.svg" className="svg-icon opus" />
        <IconButton icon="close" onClick={onCancel} tooltip="Close" className="close-modal-btn" />
        <div className="flex-centered opus-derms">
          <img src="/ge-logo.png" className="svg-icon grid" alt="GE logo" height={48} width={48} />
          Opus One DERMS
          <span className="circle_dot_icon">
            <i className="material-icons" aria-hidden="true">
              circle
            </i>
          </span>
          <strong className="grid-text">Analytics</strong>
        </div>
        <hr className="section-divider" />
        <p className="settings-version">{`Version: ${productDetails.version}`}</p>
        <p>Download packages</p>
        <div className="flex-centered packages">
          <Button
            type="text-blue"
            onClick={() => downloadReport('/api/dependencies/python', 'python-packages')}
          >
            Python
          </Button>
          <span className="circle_dot_icon">
            <i className="material-icons" aria-hidden="true">
              circle
            </i>
          </span>
          <Button
            type="text-blue"
            onClick={() => downloadReport('/api/dependencies/javascript', 'javascript-packages')}
          >
            NPM
          </Button>
          <span className="circle_dot_icon">
            <i className="material-icons" aria-hidden="true">
              circle
            </i>
          </span>
          <Button
            type="text-blue"
            onClick={() => downloadReport('/api/dependencies/linux', 'linux-packages')}
          >
            Operating system
          </Button>
        </div>
        <hr className="section-divider" />
        <a
          href="https://www.opusonesolutions.com/"
          target="_blank"
          rel="noreferrer"
          className="custom-btn text-blue-btn link-url"
        >
          www.opusonesolutions.com
        </a>
        <p>&copy; 2011-{new Date().getFullYear()} Opus One Solutions</p>
      </Modal>
    </div>
  );
};

export default About;
