/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import Select from 'components/Select';
import asyncActionStates from 'helpers/asyncActionStates';

const SubstationSelect = ({ feeder, substations, updateContainer, status, disabled }) => {
  const subIsSelected = sub => feeder.substation === sub;

  const selectSubstation = substation => {
    if (!subIsSelected(substation)) {
      updateContainer('feeder', feeder.id, { substation }, 'updateFeeder');
    }
  };
  const getValueOfSubstation = () => {
    let associatedSubstation = '';
    if (disabled) {
      substations.filter(sub => {
        if (sub.id === feeder.substation) {
          associatedSubstation = sub.name;
        }
        return associatedSubstation;
      });
    }
    return associatedSubstation;
  };
  return (
    <div className="select-row">
      <ReactSVG src="/hydro.svg" className="svg-icon" />
      <label htmlFor="Substation">Associated Substation</label>
      <span>{getValueOfSubstation()}</span>

      {!disabled && (
        <Select
          name="Substation"
          value={feeder.substation}
          className="custom-react-select-theme substation-select"
          disabled={disabled || status === asyncActionStates.LOADING}
          clearable={false}
          searchable
          theme="light"
          onChange={({ value }) => selectSubstation(value)}
          options={[
            { value: null, label: 'None', disabled: subIsSelected(null) },
            ...substations.map(sub => ({
              value: sub.id,
              label: sub.name || sub.id,
              disabled: subIsSelected(sub.id),
            })),
          ]}
        />
      )}
    </div>
  );
};

SubstationSelect.propTypes = {
  feeder: PropTypes.object.isRequired,
  substations: PropTypes.array.isRequired,
  updateContainer: PropTypes.func.isRequired,
  status: PropTypes.number,
  disabled: PropTypes.bool,
};

SubstationSelect.defaultProps = {
  status: undefined,
  disabled: false,
};

export default SubstationSelect;
