import React from 'react';
import { node, string, array, bool, object, func, oneOfType, element, shape } from 'prop-types';
import Tooltip from 'components/Tooltip';
import Select from 'components/Select';
import Analytics from 'helpers/Analytics';
import useTrackingState from 'hooks/useTrackingState';
import getControlModeOptions from 'helpers/cim/getControlModeName';
import { transposePhaseAttributes, diffPerPhaseAttributes } from '../../helpers/valueExtractors';
import SelectedAnalysisControlMode from './SelectedAnalysisMode';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import PerPhaseContainer from './PerPhaseContainer';
import PerPhaseRow from './PerPhaseRow';

export default function EditableControlMode({
  children,
  controlMode,
  attributes,
  perPhaseAttributes,
  editableValues,
  editablePerPhaseValues,
  asset,
  phases,
  balanced,
  help,
  disabled,
  theme,
  onSave,
  attributeTypeMapping,
  inEditMode,
  selectedAnalysis,
  selectedAssetId,
  workspace,
  branch,
}) {
  const [values, setValues] = useTrackingState(attributes);
  const [perPhaseValues, setPerPhaseValues] = useTrackingState(perPhaseAttributes);
  const controlModeOptions = getControlModeOptions(asset);
  const handleSave = (field, value) => {
    onSave({
      [attributeTypeMapping?.[field] || 'attributes']: value,
    });
  };

  const handleControlModeChange = ({ value: mode }) => {
    onSave({ analysis_control: { mode } });
    Analytics.logEvent(`Change ${asset} Control Mode`, 'Control Modes', mode);
  };

  return (
    <div className="control-mode">
      <div className="control-mode-aux-content">
        <span className="control-mode-title">Control Mode</span>
        <div className="control-mode-row">
          <p>Network Version Default</p>
          <div className="row-value">
            <div className="control-mode-select">
              <Select
                name="control-panel-control-type"
                id="control-panel-control-type"
                theme={theme}
                value={controlMode}
                className="custom-react-select-theme"
                disabled={disabled}
                placeholder="Select Control Type"
                options={controlModeOptions}
                clearable={false}
                searchable={false}
                onChange={handleControlModeChange}
              />
              {help && (
                <Tooltip placement="left" content={help}>
                  <i className="material-icons help-icon">help_outline</i>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        {asset !== 'EnergyConsumer' && (
          <SelectedAnalysisControlMode
            branch={branch}
            inEditMode={inEditMode}
            selectedAnalysis={selectedAnalysis}
            selectedAssetId={selectedAssetId}
            workspace={workspace}
            options={controlModeOptions}
          />
        )}
      </div>
      {values && editableValues?.length > 0 && (
        <div className="editable-section">
          {editableValues.map(({ id, ...rest }) => (
            <div className="input-spacing" key={id}>
              <SingleEditableValue
                {...rest}
                id={id}
                key={id}
                name={id}
                value={values[id]}
                onChange={val => setValues({ ...values, [id]: val })}
                edited={values[id] !== attributes[id]}
                onBlur={() => handleSave(id, { [id]: values[id] })}
                precision={3}
                disabled={disabled}
              />
            </div>
          ))}
        </div>
      )}
      {perPhaseValues && editablePerPhaseValues?.length > 0 && balanced && (
        <div className="editable-section">
          {editablePerPhaseValues.map(({ id, ...rest }) => (
            <div className="input-spacing" key={id}>
              <SingleEditableValue
                {...rest}
                id={id}
                key={id}
                name={id}
                value={perPhaseValues[id][phases]}
                onChange={value =>
                  setPerPhaseValues({
                    ...perPhaseValues,
                    [id]: {
                      ...perPhaseValues[id],
                      [phases]: value,
                    },
                  })
                }
                edited={perPhaseValues[id][phases] !== perPhaseAttributes?.[id]?.[phases]}
                onBlur={() => {
                  const attrs = transposePhaseAttributes({ [id]: perPhaseValues[id] });
                  handleSave(id, attrs);
                }}
                precision={3}
                disabled={disabled}
                {...Object.keys(perPhaseValues).reduce(
                  (prev, curr) => Object.assign(prev, { [curr]: perPhaseValues?.[curr]?.[phases] }),
                  {},
                )}
              />
            </div>
          ))}
        </div>
      )}
      {perPhaseValues && editablePerPhaseValues?.length > 0 && !balanced && (
        <PerPhaseContainer>
          {editablePerPhaseValues.map(({ id, ...rest }) => (
            <PerPhaseRow
              {...rest}
              id={id}
              key={id}
              values={perPhaseValues[id]}
              phases={phases}
              editable={!disabled}
              edited={
                perPhaseValues[id] &&
                diffPerPhaseAttributes(perPhaseAttributes[id], perPhaseValues[id])
              }
              onChange={e =>
                setPerPhaseValues({
                  ...perPhaseValues,
                  [id]: {
                    ...perPhaseValues[id],
                    [e.phase]: e.value,
                  },
                })
              }
              onBlur={() => {
                const attrs = transposePhaseAttributes({ [id]: perPhaseValues[id] });
                handleSave(id, attrs);
              }}
              {...perPhaseValues}
              precision={3}
            />
          ))}
        </PerPhaseContainer>
      )}
      {children}
    </div>
  );
}

EditableControlMode.defaultProps = {
  children: undefined,
  attributes: undefined,
  perPhaseAttributes: undefined,
  editableValues: [],
  editablePerPhaseValues: [],
  phases: 'ABC',
  balanced: false,
  help: undefined,
  disabled: false,
  attributeTypeMapping: undefined,
  selectedAnalysis: null,
};

EditableControlMode.propTypes = {
  children: node,
  controlMode: string.isRequired,
  attributes: object,
  editableValues: array,
  asset: string.isRequired,
  help: oneOfType([string, element]),
  disabled: bool,
  onSave: func.isRequired,
  theme: string.isRequired,
  attributeTypeMapping: object,
  // following 4 properties are used together for perPhase fields
  perPhaseAttributes: object,
  editablePerPhaseValues: array,
  phases: string,
  balanced: bool,
  // following 5 props used for currentAnalysisMode
  inEditMode: bool.isRequired,
  selectedAnalysis: shape({
    id: string,
    name: string,
  }),
  selectedAssetId: string.isRequired,
  workspace: string.isRequired,
  branch: string.isRequired,
};
