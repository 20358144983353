import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from 'components/Card';
import Select from 'components/Select';
import asyncActionStates from 'helpers/asyncActionStates';
import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Dot,
  BarChart,
  Bar,
  Brush,
  ResponsiveContainer,
} from 'recharts';
import { isDefined, graphHeight } from 'helpers/utils';
import './DistanceGraph.scss';

const { LOADING } = asyncActionStates;
const MAX_NUM_BUCKETS = 500;

class LoadGenDistanceGraph extends Component {
  state = {
    data: [],
    genData: [],
    loadData: [],
    phase: 'ABC_avg',
  };

  componentDidMount() {
    this.getGraphData();
  }

  componentDidUpdate(prevProps) {
    const newShunt = this.props.shuntDevices !== prevProps.shuntDevices;
    const newSVs = this.props.results !== prevProps.results;
    const newDistances = this.props.nodeSubstationDistances !== prevProps.nodeSubstationDistances;

    const loadingResults =
      this.props.resultsRequest === LOADING && prevProps.resultsRequest !== LOADING;

    if (newShunt || newSVs || newDistances) {
      this.getGraphData();
    } else if (loadingResults) {
      // Reset the graph values in state
      this.setState({ data: [], loadData: [], genData: [] });
    }
  }

  // Get a list of distance and value objects to feed into scatterplot
  getSingleValues(instances) {
    const loadPoints = [];
    const genPoints = [];
    for (let i = 0; i < instances.length; i += 1) {
      const device = instances[i];
      const nodeID = instances[i].nodes[0];
      const distance = this.props.nodeSubstationDistances[nodeID];
      const actualP = this.props.results?.[device.id]?.actualP ?? {};

      const value = actualP[`${this.state.phase}_avg`];
      if (isDefined(distance) && isDefined(value)) {
        const valueType = value >= 0 ? 'Load' : 'Generation';
        const point = {
          value: Math.abs(value / 1000),
          distance,
          type: valueType,
        };
        if (valueType === 'Load') {
          loadPoints.push(point);
        } else {
          genPoints.push(point);
        }
      }
    }
    return { loadPoints, genPoints };
  }

  updateDistanceLookup(lookup, distance, valueList, key) {
    // If distance bucket already exists determine the new min / max values for that bucket
    if (lookup[distance] && lookup[distance][key]) {
      lookup[distance][key] = [
        Math.min(...valueList, ...lookup[distance][key]),
        Math.max(...valueList, ...lookup[distance][key]),
      ];
    } else if (lookup[distance]) {
      lookup[distance][key] = valueList;
    } else {
      lookup[distance] = { [key]: valueList };
    }
  }

  // Get the min and max values of each distance bucket
  getAggregatedValues(instances, bucketSize) {
    const distanceLookup = {};

    for (let i = 0; i < instances.length; i += 1) {
      const device = instances[i];
      const nodeID = device.nodes[0];
      let distance = this.props.nodeSubstationDistances[nodeID];
      if (isDefined(distance)) {
        let values = [];
        const actualP = this.props.results?.[device.id]?.actualP ?? {};
        if (this.state.phase === 'ABC_avg') {
          values = Object.values(actualP);
        } else {
          values = [actualP[`${this.state.phase}_min`], actualP[`${this.state.phase}_max`]];
        }

        values = values.filter(val => isDefined(val)).map(val => val / 1000);

        if (values.length > 0) {
          distance = Math.ceil(distance / bucketSize) * bucketSize;
          // Handle Battery separately from other shunt devices as it can both discharge and charge
          if (device.class === 'Battery') {
            const actualMin = Math.min(...values);
            const actualMax = Math.max(...values);

            let load;
            let gen;
            // If the battery is both charging and discharging make load and gen result ranges
            if (actualMin < 0 && actualMax >= 0) {
              load = [0, actualMax];
              gen = [0, Math.abs(actualMin)];
            } else if (actualMin >= 0 && actualMax >= 0) {
              load = [actualMin, actualMax];
            } else {
              gen = [Math.abs(actualMin), Math.abs(actualMax)];
            }

            if (load) {
              this.updateDistanceLookup(distanceLookup, distance, load, 'Load');
            }

            if (gen) {
              this.updateDistanceLookup(distanceLookup, distance, gen, 'Generation');
            }
          } else {
            const type = Math.min(...values) >= 0 ? 'Load' : 'Generation';
            const absoluteValues = values.map(val => Math.abs(val));
            const valueList = [Math.min(...absoluteValues), Math.max(...absoluteValues)];
            this.updateDistanceLookup(distanceLookup, distance, valueList, type);
          }
        }
      }
    }
    return distanceLookup;
  }

  getGraphData = () => {
    const { shuntDevices, isAggregatedData } = this.props;
    const loadList = Object.values(shuntDevices.EnergyConsumer);
    const devices = [
      'AsynchronousMachine',
      'Battery',
      'InverterPV',
      'PhotoVoltaic',
      'SynchronousMachine',
      'Wind',
    ];
    const genList = devices.reduce(
      (arr, device) =>
        shuntDevices[device] ? [...arr, ...Object.values(shuntDevices[device])] : arr,
      [],
    );

    let data = [];
    let loadData = [];
    let genData = [];

    // Get separate data sets for load and generation if the aggregation type is hourly or subhourly
    if (!isAggregatedData) {
      // If aggregation is single interval, we take just the average value for the selected phase
      const { loadPoints, genPoints } = this.getSingleValues(genList);
      genData = genPoints;
      loadData = [...this.getSingleValues(loadList).loadPoints, ...loadPoints];
      // Generate one dataset for load and generation if aggregation type is anything but hour
    } else if (isAggregatedData) {
      const maxDistance = Math.max(...Object.values(this.props.nodeSubstationDistances));
      // Create the bucket size based on distance
      // Should be a max of 500 buckets and each bucket is rounded to the nearest 10
      // Min bucket size should be 10 meters.
      const bucketSize = Math.max(Math.ceil(maxDistance / MAX_NUM_BUCKETS / 10) * 10, 10);
      // If any other aggregation level is selected, bucket the values into
      // 10 meter buckets and determine the min and max values of each distance bucket

      const aggregatedLoad = this.getAggregatedValues(loadList, bucketSize);
      const aggregatedGen = this.getAggregatedValues(genList, bucketSize);
      // Add the generation values to any existing aggregated load buckets
      // This is required as the bar graph will only accept one source of data
      // where the scatterplot will accept 2
      Object.keys(aggregatedGen).forEach(distance => {
        if (aggregatedLoad[distance]) {
          aggregatedLoad[distance] = { ...aggregatedLoad[distance], ...aggregatedGen[distance] };
        } else {
          aggregatedLoad[distance] = aggregatedGen[distance];
        }
      });

      data = Object.entries(aggregatedLoad).map(([distance, values]) => ({
        ...values,
        distance,
      }));
    }
    this.setState({ data, genData, loadData });
  };

  getScatterTooltip = ({ active, payload }) => {
    if (active) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].name} : ${payload[0].value.toFixed(3)}${
            payload[0].unit
          }`}</p>
          <p className="label">{`${payload[1].payload.type} : ${payload[1].value.toFixed(3)}${
            payload[1].unit
          }`}</p>
        </div>
      );
    }

    return null;
  };

  getBarTooltip = ({ active, payload }) => {
    if (active) {
      const { distance, Load, Generation } = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">{`Distance : ${distance}m`}</p>
          {Load && (
            <p className="label">{`Load Range : ${Load[0].toFixed(3)} kW ~ ${Load[1].toFixed(
              3,
            )} kW`}</p>
          )}
          {Generation && (
            <p className="label">{`Generation Range : ${Generation[0].toFixed(
              3,
            )} kW ~ ${Generation[1].toFixed(3)} kW`}</p>
          )}
        </div>
      );
    }
    return null;
  };

  render() {
    const hideGraph =
      this.state.data.length === 0 &&
      this.state.genData.length === 0 &&
      this.state.loadData.length === 0;

    return (
      <Card theme={this.props.theme} className="distance-graph" hideTitle>
        <p>Phase</p>
        <Select
          options={[
            { label: 'ABC Average', value: 'ABC_avg' },
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'C', value: 'C' },
          ]}
          value={this.state.phase}
          clearable={false}
          searchable={false}
          theme={this.props.theme}
          className="phase-selector"
          onChange={({ value }) => this.setState({ phase: value }, this.getGraphData)}
        />
        <div className="phase-selection-message">
          {this.state.phase === 'ABC_avg' && (
            <span className="caption-text">Average value of all 3 phases</span>
          )}
        </div>
        <div className="legend">
          <div className="legend-entry load-legend">
            <div
              className={classNames({
                'axis-box': this.props.isAggregatedData,
                'axis-circle': !this.props.isAggregatedData,
              })}
            />
            <div>Load</div>
          </div>
          <div className="legend-entry generation-legend">
            <div className="legend-main">
              <p>Generation</p>
              <div
                className={classNames({
                  'axis-box': this.props.isAggregatedData,
                  'axis-circle': !this.props.isAggregatedData,
                })}
              />
            </div>
            <p className="legend-caption">(Excluding Substation)</p>
          </div>
        </div>
        {(this.state.loadData.length > 0 || this.state.genData.length > 0) &&
          !this.props.isAggregatedData && (
            <ResponsiveContainer width="100%" height={graphHeight(this.props.expanded)}>
              <ScatterChart
                margin={{
                  top: 15,
                  right: 30,
                  bottom: 10,
                  left: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" dataKey="distance" name="Distance" unit="m" />
                <YAxis
                  type="number"
                  tickFormatter={tick => Math.floor(tick)}
                  dataKey="value"
                  name="Voltage"
                  unit=" kW"
                  domain={['dataMin', 'dataMax']}
                />
                <Tooltip content={this.getScatterTooltip} cursor={{ strokeDasharray: '3 3' }} />
                <Scatter
                  data={this.state.loadData}
                  fill="#FC5830"
                  name="Load"
                  shape={props => <Dot {...props} r={3} />}
                />
                <Scatter
                  data={this.state.genData}
                  fill="#06AFA8"
                  name="Generation"
                  shape={props => <Dot {...props} r={3} />}
                />
              </ScatterChart>
            </ResponsiveContainer>
          )}
        {this.state.data.length > 0 && this.props.isAggregatedData && (
          <>
            <ResponsiveContainer width="100%" height={graphHeight(this.props.expanded)}>
              <BarChart
                data={this.state.data}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 10,
                  left: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="distance" unit="m" name="Distance from Substation" />
                <YAxis
                  domain={[dataMin => Math.min(dataMin, 0), 'dataMax']}
                  tickFormatter={tick => Math.floor(tick)}
                  unit=" kW"
                  name="Voltage"
                />
                <Tooltip content={this.getBarTooltip} />
                <Bar dataKey="Load" name="Voltage" fill="#FC5830" barSize={4} />
                <Bar dataKey="Generation" name="Voltage" fill="#06AFA8" barSize={4} />
                <Brush dataKey="distance" height={15} stroke="#606060" />
              </BarChart>
            </ResponsiveContainer>
            <p className="caption-text brush-label">Zoom In / Out</p>
          </>
        )}
        {hideGraph && (
          <div className="graph-placeholder">
            {this.props.distanceRequest === LOADING || this.props.resultsRequest === LOADING ? (
              <>
                <h3>Loading...</h3>
                <i className="material-icons rotate" style={{ fontSize: 40 }}>
                  refresh
                </i>
              </>
            ) : (
              <p>No results available for time range</p>
            )}
          </div>
        )}
      </Card>
    );
  }
}

LoadGenDistanceGraph.defaultProps = {
  shuntDevices: {
    AsynchronousMachine: {},
    Battery: {},
    EnergyConsumer: {},
    EnergySource: {},
    InverterPV: {},
    PhotoVoltaic: {},
    SynchronousMachine: {},
    Wind: {},
  },
  nodeSubstationDistances: {},
  resultsRequest: 0,
  distanceRequest: 0,
  aggType: '',
  expanded: false,
  results: {},
};

LoadGenDistanceGraph.propTypes = {
  theme: PropTypes.string.isRequired,
  nodeSubstationDistances: PropTypes.object,
  resultsRequest: PropTypes.number,
  distanceRequest: PropTypes.number,
  aggType: PropTypes.string,
  shuntDevices: PropTypes.object,
  expanded: PropTypes.bool,
  results: PropTypes.object,
  isAggregatedData: PropTypes.bool.isRequired,
};

export default LoadGenDistanceGraph;
