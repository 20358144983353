import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import RadioButtonGroup from 'components/RadioButtonGroup';

import { ReactSVG } from 'react-svg';
import { alphabetizeByKey } from 'helpers/utils';
import './ContainerPanel.scss';

const MultiContainerPanel = ({
  containers,
  setSelectedContainer,
  setContainerType,
  containerType,
  theme,
  containerSelected,
}) => (
  <div className="container-selection-content">
    <div className="radio-check-select">
      <div className="radio-wrap">
        <RadioButtonGroup
          options={[
            {
              id: 'Feeder',
              label: 'Feeder',
              disabled:
                containers.filter(item => item.type === 'Substation').length ===
                  containers.length && true,
            },
            {
              id: 'Substation',
              label: 'Substation',
              disabled:
                containers.filter(item => item.type === 'Feeder').length === containers.length &&
                true,
            },
          ]}
          style={{ color: '#54595e', fontSize: '12px' }}
          id="container-type-selector"
          value={containerType}
          listType="row"
          onChange={e => {
            setContainerType(e.target.value);
          }}
          radioType="primary"
        />
      </div>
    </div>
    <hr className="panel-divider" />
    <div className="panel-header-container">
      <div className="panel-icon-container">
        <ReactSVG
          src={containerType === 'Substation' ? '/hydro.svg' : '/congestion_point.svg'}
          className="svg-icon"
        />
      </div>
      <div className="container-select-section">
        <Select
          className="feeder-select"
          theme={theme}
          options={alphabetizeByKey(
            Object.values(containers)
              .filter(item => item.type === containerType)
              .map(x => ({ value: x.id, label: x.name })),
            'label',
          )}
          value={containerSelected || ''}
          onChange={e =>
            setSelectedContainer(
              e ? containers.filter(container => container.id === e.value) : null,
            )
          }
          clearable
          placeholder="Select Container..."
        />
      </div>
    </div>
  </div>
);

MultiContainerPanel.defaultProps = {
  setContainerType: null,
  containerType: 'Feeder',
  containerSelected: '',
};

MultiContainerPanel.propTypes = {
  containers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  theme: PropTypes.string.isRequired,
  setSelectedContainer: PropTypes.func.isRequired,
  setContainerType: PropTypes.func,
  containerType: PropTypes.string,
  containerSelected: PropTypes.string,
};

export default MultiContainerPanel;
