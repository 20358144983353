export enum Views {
  overview = 'Overview',
  assets = 'Assets',
}

export type SummaryRequestParams = {
  feeder: string[];
  start_date: string;
  end_date: string;
  scenario_id: string;
  analysis_name: string;
};
const helpers = { views: Views };
export default helpers;
