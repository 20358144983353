const navigationURLTextFieldData = [
  {
    id: 'simulation',
    name: 'Simulation',
    error: false,
    icon: '',
  },
  {
    id: 'operations',
    name: 'Operations',
    error: false,
    icon: '',
  },
  {
    id: 'programManager',
    name: 'Program manager',
    error: false,
    icon: '',
  },
  {
    id: 'modelManager',
    name: 'Model manager',
    error: false,
    icon: '',
  },
  {
    id: 'gateway',
    name: 'Gateway',
    error: false,
    icon: '',
  },
  {
    id: 'optimization',
    name: 'Optimization',
    error: false,
    icon: '',
  },
  {
    id: 'forecasting',
    name: 'Forecasting',
    error: false,
    icon: '',
  },
];

export default navigationURLTextFieldData;
