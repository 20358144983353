/* eslint-disable react/no-unused-state, react/no-access-state-in-setstate, prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeckGL, { GeoJsonLayer, PathLayer, ScatterplotLayer, TextLayer, IconLayer } from 'deck.gl';
import { TripsLayer } from '@deck.gl/geo-layers';
import { EditableGeoJsonLayer } from 'nebula.gl';
import debounce from 'lodash/debounce';
import asyncActionStates from 'helpers/asyncActionStates';
import nullable from 'helpers/nullablePropType';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapGL, { FlyToInterpolator } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';

import { AttachmentsContext } from 'contexts/AttachmentsContext';
import ZoomControls from 'components/ZoomControls';
import { isDefined } from 'helpers/utils';
import Helpers from '../../helpers/NetworkHelpers';
import MapLegend from './MapLegend/index';
import MapTooltip from './MapTooltip';

import iconAtlasDark from './icon-map-dark.png';
import assetViolation from './asset_violation.png';
import GridOSIconLayer from './GridOSIconLayer';
import ViolationsLayer from './ViolationsLayer';
import {
  determineCableColor,
  determineActualResultsValue,
  determineColorIndex,
  layerTypes,
} from '../../helpers/VisualizationHelpers';
import {
  convertStringToColor,
  createPlaceholderNode,
  getBounds,
  getVisualizationColor,
  hexToRGB,
  createPlaceholderLine,
  addLineCoordinates,
  createHoverLine,
  convertLinesToBadges,
  makeSelectedAssetFeature,
} from './helpers';
import ICON_MAPPING from './IconMapping';

if (window.WebGLRenderingContext) {
  // Tests need this defined otherwise the vertex array polyfill crashes
  // eslint-disable-next-line global-require
  require('oes-vertex-attrib-array-polyfill');
}

const { INITIAL, LOADING, ERROR } = asyncActionStates;
const SHUNT_TYPE_MAP = {
  AsynchronousMachine: 'asynchronous_machine',
  Battery: 'inverter',
  CHP: 'combined_heat_power',
  ElectricVehicleChargingStation: 'ev_station',
  EnergyConsumer: 'load',
  EquivalentSubstation: 'equivalent_substation',
  EnergySource: 'slack',
  InverterPV: 'inverter',
  LinearShuntCompensator: 'capacitor',
  PhotoVoltaic: 'asynchronous_machine',
  RunOfRiverHydro: 'river_hydro_plants',
  SynchronousMachine: 'synchronous_machine',
  Wind: 'asynchronous_machine',
};

const LEFT_RAIL_PANEL = {
  NETWORK: 'network',
  ASSET: 'asset',
};

const SWITCH_TYPES = new Set(['Cut', 'Disconnector', 'Jumper', 'Switch']);

class DeckGLMap extends Component {
  getInView = debounce(viewport => {
    if (this.deck && this.mapContainer) {
      let { width, height } = viewport;
      if (typeof height === 'string' || typeof width === 'string') {
        const bounds = this.mapContainer.getBoundingClientRect();
        width = bounds.width;
        height = bounds.height;
      }
      const visible = this.deck.pickObjects({
        x: 0,
        y: 0,
        height,
        width,
      });
      this.props.setFilteredView(visible);
    }
  }, 500);

  constructor(props) {
    super(props);

    const { networkData, iconData, maxP, textData, assetData } = this.getNetworkData();
    const badgedAssetData = convertLinesToBadges(assetData);
    const [fileData, noteData] = this.generateAttachmentIcons(badgedAssetData);

    this.state = {
      animating: false,
      dragging: false,
      // Index of the item we are replacing. Deck.gl does not return
      // the correct index, so cache it here for performance
      replacementIndex: null,
      viewState: {
        bearing: 0,
        height: '100%',
        latitude: 43.652190216994434,
        longitude: -79.38868107193969,
        pitch: 0,
        width: '100%',
        zoom: 1,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
      },
      selectedFeatureIndexes: [],

      // Data to pass to the map
      assetData,
      connectorData: [
        ...Object.values(networkData.nodeConnectors),
        ...Object.values(networkData.linkConnectors),
      ],
      fileData,
      iconData,
      lineData: Object.values(networkData.lines),
      nodeData: Object.values(networkData.nodes),
      noteData,
      violationAssetData: badgedAssetData.filter(d => this.isVisible(d)),
      data: networkData,
      textData,
      bounds: getBounds(networkData.nodes, props.selectedFeeders),
      maxP,
      feeders: new Set([...this.props.selectedFeeders.map(fdr => fdr.id)]),
      editLayer: {
        type: 'FeatureCollection',
        features: [],
      },
      time: 0,
      trips: [],
      displayViolations: {},
    };
  }

  componentDidMount() {
    this.resizeListener = window.addEventListener('resize', () => {
      this.handleResize();
    });

    if (this.props.networkGeoJSON && this.state.bounds && this.mapContainer) {
      this.zoomToBounds();
    }
    this.getDisplayViolations();

    if (
      this.props.layerOptions[this.props.selectedVisualizationLayer]?.directionalFlow &&
      this.props.results
    ) {
      this._animate();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Handle update to main network display logic:
    // - Network Data Updates
    // - Selected Feeder Updates
    // - Viz Layer Updates

    const vizLayerUpdated =
      this.props.selectedVisualizationLayer !== prevProps.selectedVisualizationLayer ||
      this.props.branch !== prevProps.branch ||
      this.props.layerOptions !== prevProps.layerOptions ||
      this.props.results !== prevProps.results ||
      this.props.hostingCapacityTimepointData !== prevProps.hostingCapacityTimepointData ||
      this.props.evCapacityTimepointData !== prevProps.evCapacityTimepointData ||
      this.props.batterySizingData !== prevProps.batterySizingData ||
      this.props.operationalData !== prevProps.operationalData ||
      this.props.theme !== prevProps.theme ||
      this.props.results !== prevProps.results;

    const hasCables = this.state.data.lines && Object.keys(this.state.data.lines).length > 0;

    if (this.props.selectedAssetID !== prevProps.selectedAssetID) {
      const { networkData } = this.getNetworkData();
      const objects = Object.values(networkData);
      const categories = objects.map(category => Object.values(category)).flat();
      const assets = categories.map(asset => asset.properties);
      const asset = assets.filter(a => a.id === this.props.selectedAssetID);
      if (this.props.selectedAssetID && asset.length > 0) {
        const [assetProps] = asset;
        this.props.actions.setSelectedAsset(
          this.props.selectedAssetID,
          assetProps?.name,
          assetProps?.asset_type,
          assetProps?.feeder,
        );
      } else {
        this.props.actions.setSelectedAsset(null);
      }
    }

    const { selectedAsset, inPathCreateMode } = this.props;
    // Handle netowrk data update
    if (
      prevProps.networkGeoJSON !== this.props.networkGeoJSON ||
      prevProps.assetTypeVisibility !== this.props.assetTypeVisibility
    ) {
      const { networkData, iconData, maxP, textData, assetData } = this.getNetworkData();
      const bounds = getBounds(networkData.nodes, this.props.selectedFeeders);
      const feeders = new Set([...this.props.selectedFeeders.map(fdr => fdr.id)]);

      const badgedAssetData = convertLinesToBadges(assetData);
      const [fileData, noteData] = this.generateAttachmentIcons(badgedAssetData);

      this.setState(
        {
          assetData,
          connectorData: [
            ...Object.values(networkData.nodeConnectors),
            ...Object.values(networkData.linkConnectors),
          ],
          data: networkData,
          fileData,
          iconData,
          lineData: Object.values(networkData.lines),
          nodeData: Object.values(networkData.nodes),
          noteData,
          textData,
          violationAssetData: badgedAssetData.filter(d => this.isVisible(d)),
          bounds,
          feeders,
          maxP,
        },
        () => this.getInView(this.state.viewState),
      );

      // Handle update to selected (visible) feeders
    } else if (this.props.selectedFeeders !== prevProps.selectedFeeders) {
      this.setState({
        feeders: new Set([...this.props.selectedFeeders.map(fdr => fdr.id)]),
        bounds: getBounds(this.state.data.nodes, this.props.selectedFeeders),
      });
      // Handle update to viz layer
    } else if (vizLayerUpdated && hasCables) {
      const updatedCables = { ...this.state.data.lines };
      Object.keys(updatedCables).forEach(cable => {
        updatedCables[cable].properties.color = this.getColor(updatedCables[cable]);
      });
      const updatedNodeIcons = this.applyIconColors(this.state.data.nodeIcons, this.state.maxP);
      const updatedLinkIcons = this.applyIconColors(this.state.data.linkIcons, this.state.maxP);
      const updatedNodes = this.applyIconColors(this.state.data.nodes, this.state.maxP);

      const newData = {
        ...this.state.data,
        lines: updatedCables,
        nodeIcons: updatedNodeIcons,
        linkIcons: updatedLinkIcons,
        nodes: updatedNodes,
      };
      this.setState({
        assetData: {
          ...newData.nodeIcons,
          ...newData.linkIcons,
          ...newData.nodes,
          ...newData.lines,
        },
        data: newData,
        lineData: Object.values(newData.lines),
        nodeData: Object.values(newData.nodes),
      });
    } else if (!inPathCreateMode && prevProps.inPathCreateMode) {
      // Handle exiting add new line mode
      this.handleAddLine(null, null, true);
    } else if (
      inPathCreateMode &&
      !prevProps.inPathCreateMode &&
      selectedAsset &&
      selectedAsset.class === 'ConnectivityNode'
    ) {
      // Handle entering add new line mode. Make selected node starting point if selected
      const newLines = {
        ...this.state.data.lines,
        _newLine: createPlaceholderLine(this.state.data.nodes[selectedAsset.id]),
      };

      this.setState({
        data: {
          ...this.state.data,
          lines: newLines,
        },
        lineData: Object.values(newLines),
      });
    }

    if (prevProps.inPathEditMode !== this.props.inPathEditMode) {
      // Path edit mode state changed
      if (this.props.inPathEditMode) {
        const { id } = this.props.selectedAsset;
        const asset = this.state.data.lines[id];

        this.setState({
          editLayer: {
            ...this.state.editLayer,
            features: [
              {
                ...asset,
                geometry: {
                  ...asset.geometry,
                  coordinates: [...asset.geometry.coordinates],
                },
              },
            ],
          },
          // Index used by edit layer to determine what is editable
          selectedFeatureIndexes: [0],
          // Remove the selected asset outline until editing is done
          selectedAssetLayer: {
            ...this.state.selectedAssetLayer,
            features: [],
          },
        });
      } else if (this.props.selectedAsset) {
        // Left edit path mode. Dispatch save if we didn't change our selected ID
        const { id } = this.props.selectedAsset;
        let feature = this.state.data.lines[id];

        // If we left edit mode, this counts as a discard
        if (
          prevProps.selectedAsset === this.props.selectedAsset &&
          this.props.inEditMode &&
          !this.props.discardPathEdit
        ) {
          const rawCoordinates = this.state.editLayer.features[0].geometry.coordinates;
          const coordinates = rawCoordinates.slice(1, -1);
          const positions = coordinates.map(([longitude, latitude]) => ({
            longitude,
            latitude,
          }));
          this.props.editActions.editSingleEquipment(
            this.props.workspace,
            this.props.branch,
            'line',
            this.props.selectedAsset.id,
            {
              positions,
            },
            'editLinePosition',
          );

          // Update the feature with the new coordinates
          // so that the map can draw the correct line during save
          feature = {
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: [...rawCoordinates],
            },
          };

          const newLines = {
            ...this.state.data.lines,
            [id]: feature,
          };
          this.setState({
            data: {
              // Fake the state update while we save so that the user does
              // not see a jump
              ...this.state.data,
              lines: newLines,
            },
            editLayer: { ...this.state.editLayer, features: [] },
            lineData: Object.values(newLines),
            selectedFeatureIndexes: [],
          });
        } else {
          this.setState({
            editLayer: { ...this.state.editLayer, features: [] },
            selectedFeatureIndexes: [],
          });
        }
      } else {
        // If for some reason the asset got de-selected, remove it
        // from the selection and edit layers
        this.setState({
          editLayer: { ...this.state.editLayer, features: [] },
          selectedFeatureIndexes: [],
        });
      }
    }

    // Handle Zoom From Asset Panel
    if (this.props.flyToAsset !== prevProps.flyToAsset) {
      this.handleZoomToLocation(this.props.flyToAsset.id);
    }

    if (this.props.inEditMode !== prevProps.inEditMode) {
      this.handleResize();
    }

    if (prevProps.addAssetRequest === LOADING && this.props.addAssetRequest === ERROR) {
      const { _newNode, ...nodes } = this.state.data.nodes;
      const { _newLine, ...lines } = this.state.data.lines;
      const { _drawPlaceholder, ...linkConnectors } = this.state.data.nodes;
      this.setState({
        connectorData: [
          ...Object.values(this.state.data.nodeConnectors),
          ...Object.values(linkConnectors),
        ],
        data: {
          ...this.state.data,
          nodes,
          lines,
          linkConnectors,
        },
        lineData: Object.values(lines),
        nodeData: Object.values(nodes),
      });
    }

    // Get violations to display
    if (
      prevProps.violations !== this.props.violations ||
      prevProps.violationDisplayMin !== this.props.violationDisplayMin
    ) {
      this.getDisplayViolations();
    }

    // The user may have updated to start an animation or to stop one
    const { layerOptions, results, selectedVisualizationLayer } = this.props;
    const needAnimation = layerOptions[selectedVisualizationLayer]?.directionalFlow && results;

    if (
      needAnimation &&
      (!this.state.animating ||
        selectedVisualizationLayer !== prevProps.selectedVisualizationLayer ||
        layerOptions[selectedVisualizationLayer] !==
          prevProps.layerOptions[selectedVisualizationLayer] ||
        this.state.data !== prevState.data ||
        this.props.results !== prevProps.results)
    ) {
      let param;
      switch (selectedVisualizationLayer) {
        case 'reactive_power':
          param = 'actualQ';
          break;
        case 'real_power':
        default:
          param = 'actualP';
      }
      this.setState({
        trips: this.trips(selectedVisualizationLayer, param),
      });
    }

    if (needAnimation && !this.state.animating) {
      this._animate();
    } else if (!needAnimation && this._animationFrame && this.state.animating) {
      this.setState({
        animating: false,
      });
      window.cancelAnimationFrame(this._animationFrame);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
    if (this._animationFrame) {
      window.cancelAnimationFrame(this._animationFrame);
      this.setState({
        animating: false,
      });
    }
  }

  _animate() {
    const loopLength = 400; // unit corresponds to the timestamp in source data
    this.setState({
      animating: true,
      time: (this.state.time + 1) % loopLength,
    });
    this._animationFrame = window.requestAnimationFrame(this._animate.bind(this));
  }

  getDisplayViolations = () => {
    const displayViolations = Object.keys(this.props.violations).reduce((obj, id) => {
      if (this.props.violations[id] > this.props.violationDisplayMin) {
        obj[id] = this.props.violations[id];
      }
      return obj;
    }, {});
    this.setState({ displayViolations });
  };

  handleResize = () => {
    if (this.mapContainer) {
      const { height } = this.mapContainer.getBoundingClientRect();
      this.setState({
        viewState: {
          ...this.state.viewState,
          height,
          width: window.innerWidth - 275,
        },
      });
    }
  };

  getAttachmentsIconOffset = (assetType, stacked) => {
    const zoom = this.state.viewState.zoom ** 1.3;

    let offset;

    switch (assetType) {
      case 'EnergyConsumer':
        offset = [5, -1 * Math.max(30, zoom)];
        break;
      case 'ConnectivityNode':
        offset = [10, -1 * Math.max(30, zoom)];
        break;
      case 'ACLineSegment':
        offset = [0.5, -15];
        break;
      default:
        offset = [15, -1 * Math.max(55, zoom)];
        break;
    }

    if (stacked) {
      offset[1] -= 15;
    }
    return offset;
  };

  generateAttachmentIcons = assetData => {
    const fileData = [];
    const noteData = [];
    Object.values(assetData).forEach(asset => {
      if (!this.context) {
        return;
      }

      const hasFiles = this.context.assetsWithFiles.includes(asset.properties.id);
      const hasNotes = this.context.assetsWithNotes.includes(asset.properties.id);

      if (hasFiles) {
        fileData.push({
          coordinates: asset.geometry.coordinates,
          text: '.',
          properties: asset.properties,
          baseline: asset.properties.asset_type === 'ACLineSegment' ? 'center' : 'top',
        });
      }

      if (hasNotes) {
        noteData.push({
          coordinates: asset.geometry.coordinates,
          text: '.',
          properties: asset.properties,
          baseline: asset.properties.asset_type === 'ACLineSegment' ? 'center' : 'top',
          stacked: hasFiles && hasNotes,
        });
      }
    });
    return [fileData, noteData];
  };

  getNetworkData = () => {
    const networkData = {
      ...(this.props.networkGeoJSON ?? {
        nodeIcons: {},
        nodeConnectors: {},
        linkIcons: {},
        linkConnectors: {},
        nodes: {},
        lines: {},
      }),
    };

    const iconData = [
      ...Object.values(networkData.nodeIcons),
      ...Object.values(networkData.linkIcons),
    ];

    // Associate default color to lines
    Object.keys(networkData.lines || {}).forEach(cable => {
      networkData.lines[cable].properties.color = this.getColor(networkData.lines[cable]);
    });

    const maxP = Object.values(networkData.nodeIcons).reduce(
      ([maxLoad, maxGen], node) => {
        if (!node.properties.totalP) return [maxLoad, maxGen];
        if (node.properties.asset_type === 'EnergyConsumer') {
          return [Math.max(maxLoad, Math.abs(node.properties.totalP)), maxGen];
        }
        return [maxLoad, Math.max(maxGen, Math.abs(node.properties.totalP))];
      },
      [0, 0],
    );

    networkData.nodeIcons = this.applyIconColors(networkData.nodeIcons, maxP);
    networkData.linkIcons = this.applyIconColors(networkData.linkIcons, maxP);
    networkData.nodes = this.applyIconColors(networkData.nodes, maxP);

    const textData = [];
    Object.values(networkData.nodeIcons).forEach(asset => {
      let voltage;
      if (asset.properties.asset_type === 'EnergySource') {
        voltage = asset.properties.nominalVoltage;
      } else if (asset.properties.asset_type === 'EquivalentSubstation') {
        voltage = asset.properties.sourceVoltage;
      }
      if (isDefined(voltage)) {
        textData.push({
          coordinates: asset.geometry.coordinates,
          text: `${Math.round(parseFloat(voltage)) / 1000} kV`,
          properties: {
            asset_type: asset.properties.asset_type,
            feeder: asset.properties.feeder,
          },
        });
      }
    });

    const assetData = {
      ...networkData.nodeIcons,
      ...networkData.linkIcons,
      ...networkData.nodes,
      ...networkData.lines,
    };
    return {
      networkData,
      iconData,
      maxP,
      textData,
      assetData,
    };
  };

  applyIconColors = (icons, maxP) => {
    const updatedIcons = { ...icons };

    Object.keys(updatedIcons).forEach(id => {
      const icon = updatedIcons[id];
      const result = this.props.results?.[icon.properties.id];
      icon.properties.visualizationColor = getVisualizationColor(
        icon,
        maxP,
        this.props.layerOptions,
        this.props.selectedVisualizationLayer,
        this.props.hostingCapacityTimepointData,
        this.props.evCapacityTimepointData,
        this.props.batterySizingData,
        this.props.operationalData,
        result,
      );
    });
    return updatedIcons;
  };

  zoomToBounds = () => {
    if (this.state.viewState.width && this.state.viewState.height) {
      let { width, height } = this.state.viewState;
      if (typeof width !== 'number' || typeof height !== 'number') {
        const newDimensions = this.mapContainer.getBoundingClientRect();
        width = newDimensions.width;
        height = newDimensions.height;
      }
      const viewState = new WebMercatorViewport({
        ...this.state.viewState,
        width,
        height,
      });

      const { longitude, latitude, zoom } = viewState.fitBounds(this.state.bounds, { padding: 40 });

      const updatedViewState = {
        ...this.state.viewState,
        longitude,
        latitude,
        zoom,
        transitionDuration: 750,
        transitionInterpolator: new FlyToInterpolator(),
      };

      this.getInView(updatedViewState);
      this.setState({ viewState: updatedViewState });
    }
  };

  getColor = (obj, isHidden = false) => {
    if (obj && obj.properties) {
      if (obj.properties.asset_type && obj.properties.asset_type !== 'ACLineSegment') {
        return [255, 255, 255, 255];
      }
    }

    // Lines
    const defaultColor = [255, 255, 255, 255];
    const colorWhenLinesAreHidden = [211, 211, 211, 0];
    const colorWhenDirectionalFlow = [96, 96, 96];
    const { selectedVisualizationLayer, layerOptions, results } = this.props;
    if (layerOptions && selectedVisualizationLayer && !isHidden) {
      // Compute the colour for the visualization layer
      // TODO: convert determineCableColor to return arrays directly rather than strings
      const result = results?.[obj.properties.id];
      const color =
        obj.properties.asset_type === 'ACLineSegment' &&
        determineCableColor(
          obj,
          selectedVisualizationLayer,
          layerOptions,
          defaultColor,
          convertStringToColor(obj.properties.feeder ?? ''),
          result,
        );

      if (
        ['real_power', 'reactive_power'].find(
          layer => layer === this.props.selectedVisualizationLayer,
        ) &&
        this.props.layerOptions[this.props.selectedVisualizationLayer].directionalFlow
      ) {
        return colorWhenDirectionalFlow;
      }

      if (Array.isArray(color)) {
        return color;
      }

      return hexToRGB(color);
    }
    return isHidden ? colorWhenLinesAreHidden : defaultColor;
  };

  handleDragStart = ({ object }) => {
    if (!this.props.inEditMode && !this.props.inPathEditMode) {
      // No dragging when out of edit mode
      return false;
    }

    if (!this.props.selectedAsset) {
      // No dragging if nothing is selected
      return false;
    }
    if (this.props.selectedAsset.id !== object.properties.id) {
      // Can only drag the selected asset
      return false;
    }

    const type = object.properties.asset_type;
    if (!(type === 'ConnectivityNode' || SHUNT_TYPE_MAP[type] !== undefined)) {
      // Can only drag nodes & shunt devices
      return false;
    }

    this.setState({ dragging: true });
    return true;
  };

  handleDrag = data => {
    const type = data.object.properties.asset_type;
    return type === 'ConnectivityNode' ? this.handleNodeDrag(data) : this.handleIconDrag(data);
  };

  handleDragEnd = data => {
    const type = data.object.properties.asset_type;
    this.setState({ replacementIndex: null });
    return type === 'ConnectivityNode'
      ? this.handleNodeDragEnd(data)
      : this.handleIconDragEnd(data);
  };

  handleIconDrag = ({ object, coordinate }) => {
    if (!this.state.dragging) {
      return false;
    }

    const shuntID = object.properties.id;
    const shuntFeature = this.state.data.nodeIcons[shuntID];
    const shuntConnector = this.state.data.nodeConnectors[shuntID];
    shuntFeature.geometry.coordinates = coordinate;

    const updatedShuntConnectors = { ...this.state.data.nodeConnectors };
    if (shuntConnector) {
      shuntConnector.geometry.coordinates[0] = coordinate;
      updatedShuntConnectors[shuntID] = shuntConnector;
    }

    // The index provided by Deck.gl is not correct since it comes from
    // the overlay (orange circle) layer and thus is always 0.
    // We compute the index of the icon to replace once and then store it
    // in local state. This eliminates a costly search for every drag event
    let { replacementIndex } = this.state;
    if (replacementIndex === null) {
      replacementIndex = this.state.iconData.findIndex(
        val => val.properties.id === object.properties.id,
      );
      this.setState({ replacementIndex });
    }
    const iconData = [...this.state.iconData];
    iconData[replacementIndex] = shuntFeature;

    this.setState({
      connectorData: [
        ...Object.values(updatedShuntConnectors),
        ...Object.values(this.state.data.linkConnectors),
      ],
      data: {
        ...this.state.data,
        nodeIcons: {
          ...this.state.data.nodeIcons,
          [shuntID]: shuntFeature,
        },
        nodeConnectors: updatedShuntConnectors,
      },
      iconData,
    });
    return true;
  };

  handleIconDragEnd = ({ object, coordinate }) => {
    if (!this.state.dragging) {
      return false;
    }

    this.setState({ dragging: false });

    this.props.editActions.editShuntPosition(
      this.props.workspace,
      this.props.branch,
      SHUNT_TYPE_MAP[object.properties.asset_type],
      object.properties.id,
      coordinate[0],
      coordinate[1],
    );

    return true;
  };

  handleNodeDrag = ({ object, coordinate }) => {
    if (!this.state.dragging) {
      return false;
    }

    const nodeID = object.properties.id;
    const { nodeConnectors, linkConnectors, linkIcons, lines } = this.state.data;

    if (object.properties.connected_devices) {
      // Iterate all of the connected devices and update their coordinates
      object.properties.connected_devices.forEach(id => {
        // If the node is connected to a shunt device, update the shunt device connector line
        if (nodeConnectors[id]) {
          nodeConnectors[id].geometry.coordinates[1] = coordinate;
        }

        // If the node is connected to a line, update the correct end of the line with
        // the new coordinates
        if (lines[id]) {
          // Before determining the coordinates we sort the nodes by terminal sequence.
          // This allows us to know that the node at index 0 is the coordinate at index 0.
          const nodeIndex = lines[id].properties.nodes.indexOf(nodeID);
          if (nodeIndex === 0) {
            lines[id].geometry.coordinates[0] = coordinate;
          } else {
            lines[id].geometry.coordinates[lines[id].geometry.coordinates.length - 1] = coordinate;
          }
        }

        // If the node is connected to a link device we need to update the correct end of the link
        // connector (the underline line) as well as the position of the icon (icon is centered)
        if (linkConnectors[id]) {
          const nodeIndex = linkConnectors[id].properties.nodes.indexOf(nodeID);
          const endIndex = linkConnectors[id].geometry.coordinates.length - 1;
          if (nodeIndex === 0) {
            linkConnectors[id].geometry.coordinates[0] = coordinate;
          } else {
            linkConnectors[id].geometry.coordinates[endIndex] = coordinate;
          }
          const start = linkConnectors[id].geometry.coordinates[0];
          const end = linkConnectors[id].geometry.coordinates[endIndex];
          linkIcons[id].geometry.coordinates = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];
        }
      });
    }

    // Also update the position of the node itself
    const { nodes } = this.state.data;
    nodes[nodeID].geometry.coordinates = coordinate;

    this.setState({
      connectorData: [...Object.values(nodeConnectors), ...Object.values(linkConnectors)],
      data: {
        ...this.state.data,
        nodeConnectors,
        linkConnectors,
        linkIcons,
        nodes,
        lines,
      },
      iconData: [...this.state.iconData],
      lineData: Object.values(lines),
      nodeData: Object.values(nodes),
    });

    return true;
  };

  handleNodeDragEnd = ({ object, coordinate }) => {
    if (!this.state.dragging) {
      return false;
    }

    this.setState({ dragging: false });

    this.props.editActions.editNodePosition(
      this.props.workspace,
      this.props.branch,
      object.properties.id,
      coordinate[0],
      coordinate[1],
    );

    return true;
  };

  handleAddNode = lngLat => {
    const nodes = {
      ...this.state.data.nodes,
      ...createPlaceholderNode(lngLat),
    };
    this.setState({
      data: {
        ...this.state.data,
        nodes,
      },
      nodeData: Object.values(nodes),
    });
    // Make request to add node to feeder
    this.props.actions.createNewNode(lngLat, nodes._newNode.properties);
  };

  handleAddLine = (picked, lngLat, allowEndNodeCreation) => {
    const isNode =
      picked && picked.object && picked.object.properties.asset_type === 'ConnectivityNode';
    const { lines } = this.state.data;
    if (this.state.data.lines._newLine) {
      const coord = isNode ? picked.object.geometry.coordinates : lngLat;
      const { _drawPlaceholder, ...linkConnectors } = this.state.data.linkConnectors;
      const _newLine = coord === null ? lines._newLine : addLineCoordinates(lines._newLine, coord);

      // ensure we have enough points to make a line
      if (_newLine.geometry.coordinates.length >= 2) {
        const newLines = { ...lines, _newLine };
        this.setState({
          connectorData: [
            ...Object.values(this.state.data.nodeConnectors),
            ...Object.values(linkConnectors),
          ],
          data: {
            ...this.state.data,
            lines: newLines,
            linkConnectors,
          },
          lineData: Object.values(newLines),
        });

        if (isNode && lines._newLine.properties.nodes[0] !== picked.object.properties.id) {
          this.props.actions.createNewLine(
            lines._newLine.properties.nodes[0],
            picked.object.properties.id,
            _newLine.geometry.coordinates.slice(1, -1),
            _newLine.properties,
          );
        } else if (!picked && allowEndNodeCreation) {
          this.props.actions.createNewLine(
            lines._newLine.properties.nodes[0],
            null,
            _newLine.geometry.coordinates.slice(1),
            _newLine.properties,
          );
        }
      } else {
        this.setState({
          connectorData: [
            ...Object.values(this.state.data.nodeConnectors),
            ...Object.values(linkConnectors),
          ],
          data: {
            ...this.state.data,
            lines,
            linkConnectors,
          },
          lineData: Object.values(lines),
        });
      }
    } else if (isNode) {
      const _newLine = createPlaceholderLine(picked.object);
      const newLines = { ...lines, _newLine };
      this.setState({
        data: {
          ...this.state.data,
          lines: newLines,
        },
        lineData: Object.values(newLines),
      });
    }
  };

  isVisible = asset => {
    // Users in edit mode want to see everything
    if (this.props.inEditMode) return true;
    return this.props.assetTypeVisibility[asset.properties.display_type] !== false;
  };

  inSelectedFeeders = asset =>
    !asset.properties.feeder || this.state.feeders.has(asset.properties.feeder);

  hexagonCoordinates = coordinates => {
    const coords = [
      [coordinates[0] - 0.5 * 0.0001, coordinates[1] + (Math.sqrt(3) / 2) * 0.0001],
      [coordinates[0] + 0.5 * 0.0001, coordinates[1] + (Math.sqrt(3) / 2) * 0.0001],
      [coordinates[0] + 0.0001, coordinates[1]],
      [coordinates[0] + 0.5 * 0.0001, coordinates[1] - (Math.sqrt(3) / 2) * 0.0001],
      [coordinates[0] - 0.5 * 0.0001, coordinates[1] - (Math.sqrt(3) / 2) * 0.0001],
      [coordinates[0] - 0.0001, coordinates[1]],
      [coordinates[0] - 0.5 * 0.0001, coordinates[1] + (Math.sqrt(3) / 2) * 0.0001],
    ];
    return [coords];
  };

  getPolygonColor = (HC, layerInfo) => {
    let color = '#D4D4D4';
    if (HC > layerInfo.rangeBreaks[0]) {
      color = layerInfo.colors[0];
    } else if (HC > layerInfo.rangeBreaks[1] && HC <= layerInfo.rangeBreaks[0]) {
      color = layerInfo.colors[1];
    } else if (HC > layerInfo.rangeBreaks[2] && HC <= layerInfo.rangeBreaks[1]) {
      color = layerInfo.colors[2];
    } else if (HC > layerInfo.rangeBreaks[3] && HC <= layerInfo.rangeBreaks[2]) {
      color = layerInfo.colors[3];
    } else if (HC <= layerInfo.rangeBreaks[3]) {
      color = layerInfo.colors[4];
    }
    return hexToRGB(color);
  };

  trips = (layer, parameter) => {
    const { layerOptions } = this.props;
    const options = {
      aggregation: layerOptions.aggregation,
      ...layerOptions[layer],
    };
    const definedLines = this.state.lineData.reduce((a, b) => {
      const val = determineActualResultsValue(
        options,
        this.props.results?.[b.properties.id]?.[parameter],
      );
      const selected = options.selected[determineColorIndex(val, layerOptions[layer].rangeBreaks)];
      const line = {
        id: b.properties.id,
        coordinates: b.geometry.coordinates,
        [parameter]: val,
      };
      if (val && selected) a.push(line);
      return a;
    }, []);
    const directionalFlow = definedLines.map(line => {
      const coords = line.coordinates.slice(); // shallow copy
      if (layer !== 'current' && line[parameter] < 0) {
        coords.reverse();
      }
      const timestamps = [...coords.keys()].map(t => t * 100);
      let color =
        layerOptions[layer].colors[
          determineColorIndex(line[parameter], layerOptions[layer].rangeBreaks)
        ] || undefined;
      if (color && !Array.isArray(color)) color = hexToRGB(color);
      return {
        layer,
        path: coords,
        timestamps,
        color,
      };
    });
    return directionalFlow;
  };

  getNebulaLayers = () => {
    // Layer that creats the ConnectivityNode instances
    const nodeLayer = new ScatterplotLayer({
      parameters: { depthTest: false },
      id: 'connectivity-node-layer',
      data: this.state.nodeData,
      pickable: true,
      opacity: 1,
      stroked: false,
      filled: true,
      radiusScale: 1,
      radiusMaxPixels: 10,
      updateTriggers: {
        getPosition: [this.state.data],
        getRadius: [
          this.state.viewState.zoom,
          this.state.feeders,
          this.props.assetTypeVisibility,
          this.props.inEditMode,
        ],
      },
      getFillColor: [255, 255, 255],
      getPosition: d => d.geometry.coordinates,
      getRadius: d => {
        if (this.state.viewState.zoom < 14) return 0;
        if (!this.inSelectedFeeders(d)) return 0;
        if (!this.isVisible(d)) return 0;
        return 5;
      },
      onClick: this.handleAssetClick,
      onDragStart: this.handleDragStart,
      onDrag: this.handleNodeDrag,
      onDragEnd: this.handleNodeDragEnd,
      onHover: this.handleAssetHover,
    });

    // Layer that creates all of the asset icons
    const iconLayer = new GridOSIconLayer({
      id: 'icon-layer',
      data: this.state.iconData,
      pickable: true,
      opacity: 1,
      iconAtlas: iconAtlasDark,
      iconMapping: ICON_MAPPING,
      sizeScale: 3,
      sizeMaxPixels: 45,
      getElevation: 0,
      extruded: false,
      updateTriggers: {
        getSize: [
          this.state.viewState.zoom,
          this.state.feeders,
          this.props.assetTypeVisibility,
          this.props.inEditMode,
        ],
        getAngle: [this.state.viewState.bearing, this.state.iconData],
        getPosition: [this.state.iconData],
      },
      getIcon: d => {
        if (SWITCH_TYPES.has(d.properties.asset_type)) {
          return d.properties.closed ? 'SwitchClosed' : 'SwitchOpen';
        }
        if (d.properties.asset_type === 'LinearShuntCompensator') {
          return d.properties.sub_type;
        }
        if (d.properties.asset_type === 'ElectricVehicleChargingStation') {
          return `${d.properties.asset_type}${d.properties.sub_type}`;
        }
        if (d.properties.asset_type === 'InverterPV') {
          return 'PhotoVoltaic';
        }
        if (d.properties.asset_type === 'Regulator') {
          return d.properties.flipIcon ? 'RegulatorFlipped' : 'Regulator';
        }
        if (d.properties.asset_type === 'EnergyConsumer' && d.properties.sub_type === 'DR') {
          return 'EnergyConsumerDr';
        }
        if (d.properties.asset_type === 'SeriesCompensator') {
          return 'ShuntCapacitor';
        }
        return d.properties.asset_type;
      },
      getPosition: d => [...d.geometry.coordinates, 0],
      // Do not attempt to draw an icon for a cluster or if the zoom level is below 14
      getSize: d => {
        if (this.state.viewState.zoom < 14) return 0;
        if (!this.inSelectedFeeders(d)) return 0;
        if (!this.isVisible(d)) return 0;
        return this.state.viewState.zoom ** 1.3;
      },
      getAngle: 0,
      onClick: this.handleAssetClick,
      onDragStart: this.handleDragStart,
      onDrag: this.handleIconDrag,
      onDragEnd: this.handleIconDragEnd,
      onHover: this.handleAssetHover,
    });

    // Creates layer that draws ACLineSegments
    const pathLayer = new PathLayer({
      parameters: { depthTest: false },
      id: 'cable-layer',
      data: this.state.lineData,
      pickable: true,
      widthScale: 1,
      widthMinPixels: 5,
      widthMaxPixels: 15,
      updateTriggers: {
        getPath: [this.state.data],
        getColor: [
          this.state.data,
          this.props.violations,
          this.state.feeders,
          this.props.assetTypeVisibility,
          this.props.inEditMode,
        ],
      },
      getPath: d => d.geometry.coordinates,
      getColor: d => {
        const isHidden =
          !this.inSelectedFeeders(d) ||
          !this.isVisible(d) ||
          (this.props.inPathEditMode && d.properties.id === this.props.selectedAsset.id);

        return this.getColor(d, isHidden);
      },
      getDashArray: d => {
        if (d.properties.asset_type !== 'ACLineSegment') return [1, 1];
        if (d.properties.phase && d.properties.phase.includes('ABC')) return [0, 0];
        // 1 or 2 phase line. Dashed
        return [5, 5];
      },
      onClick: this.handleAssetClick,
      onHover: this.handleAssetHover,
    });

    const animationLayers =
      this.props.layerOptions[this.props.selectedVisualizationLayer]?.directionalFlow &&
      [0, 100, 200].map(
        val =>
          new TripsLayer({
            id: `trips-layer-${this.props.selectedVisualizationLayer}-${val}`,
            data: this.state.trips,
            getPath: d => d.path,
            getTimestamps: d => d.timestamps.map(t => t + val),
            getColor: d => d.color,
            opacity: 0.8,
            widthMinPixels: 5,
            rounded: true,
            trailLength: 150,
            currentTime: this.state.time,
          }),
      );

    // Creates layer that draws shunt connectors and link connectors
    const connectorLayer = new PathLayer({
      parameters: { depthTest: false },
      id: 'connector-layer',
      data: this.state.connectorData,
      pickable: false,
      widthScale: 1,
      widthMinPixels: 0,
      widthMaxPixels: 15,
      updateTriggers: {
        getPath: [this.state.data],
        getWidth: [this.state.viewState.zoom, this.state.feeders],
        getColor: [this.state.data],
      },
      getPath: d => d.geometry.coordinates,
      getColor: d => {
        if (d.properties.color) return d.properties.color;
        return this.getColor(d);
      },
      getWidth: d => {
        if (this.state.viewState.zoom < 14) return 0;
        if (!this.inSelectedFeeders(d)) return 0;
        if (!this.isVisible(d)) return 0;
        return 5;
      },
      getDashArray: d => (d.properties.asset_type === 'ACLineSegment' ? [0, 0] : [1, 1]),
      onClick: this.handleAssetClick,
      onHover: this.handleAssetHover,
    });

    const textLayer = new TextLayer({
      id: 'text-layer',
      data: this.state.textData,
      pickable: false,
      getPosition: d => d.coordinates,
      getPixelOffset: () => [0, Math.max(30, this.state.viewState.zoom ** 1.3)],
      getColor: [255, 255, 255],
      getText: d => d.text,
      getSize: d => {
        if (this.state.viewState.zoom < 14) return 0;
        if (!this.inSelectedFeeders(d)) return 0;
        if (!this.isVisible(d)) return 0;
        return 24;
      },
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'top',
      updateTriggers: {
        getSize: [this.state.viewState.zoom, this.state.feeders],
      },
    });

    const fileLayer = new TextLayer({
      id: 'file-layer',
      data: this.state.fileData,
      pickable: false,
      getPosition: d => d.coordinates,
      getPixelOffset: d => this.getAttachmentsIconOffset(d.properties.asset_type),
      getColor: [209, 132, 240],
      getText: d => d.text,
      getSize: d => {
        if (this.state.viewState.zoom < 14) return 0;
        if (!this.inSelectedFeeders(d)) return 0;
        if (!this.isVisible(d)) return 0;
        return this.state.viewState.zoom ** 1.5;
      },
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: d => d.baseline,
      updateTriggers: {
        getSize: [this.state.viewState.zoom, this.state.feeders],
        getPixelOffset: [this.state.viewState.zoom],
      },
    });

    const noteLayer = new TextLayer({
      id: 'note-layer',
      data: this.state.noteData,
      pickable: false,
      getPosition: d => d.coordinates,
      getPixelOffset: d => this.getAttachmentsIconOffset(d.properties.asset_type, d.stacked),
      getColor: [68, 68, 166],
      getText: d => d.text,
      getSize: d => {
        if (this.state.viewState.zoom < 14) return 0;
        if (!this.inSelectedFeeders(d)) return 0;
        if (!this.isVisible(d)) return 0;
        return this.state.viewState.zoom ** 1.5;
      },
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: d => d.baseline,
      updateTriggers: {
        getSize: [this.state.viewState.zoom, this.state.feeders],
        getPixelOffset: [this.state.viewState.zoom],
      },
    });

    const selectedAssets = [];

    if (this.props.hoveredAssetID) {
      const selectedAsset = makeSelectedAssetFeature(
        this.state.assetData,
        this.props.hoveredAssetID,
      );
      if (selectedAsset) {
        selectedAssets.push(selectedAsset);
      }
    }

    if (
      this.props.selectedAssetID &&
      !this.props.inPathEditMode &&
      this.props.editLineRequest !== LOADING &&
      this.props.editShuntRequest !== LOADING &&
      this.props.editNodeRequest !== LOADING &&
      !this.state.dragging
    ) {
      const selectedAsset = makeSelectedAssetFeature(
        this.state.assetData,
        this.props.selectedAssetID,
      );
      if (selectedAsset) {
        selectedAssets.push(selectedAsset);
      }
    }

    const features = {
      type: 'FeatureCollection',
      features: selectedAssets,
    };

    // Creates a layer for the orange selection overlay
    const selectionLayer = new GeoJsonLayer({
      id: 'selected-asset-geojson-layer',
      data: features,
      pickable: true,
      stroked: false,
      filled: true,
      extruded: true,
      lineWidthScale: 2,
      lineWidthMinPixels: 2,
      lineWidthMaxPixels: 20,
      pointRadiusMinPixels: 10,
      getFillColor: [248, 148, 6, 100],
      getLineColor: [248, 148, 6, 100],
      pointRadiusMaxPixels: 50,
      getRadius: () => this.state.viewState.zoom ** 1.4,
      updateTriggers: {
        getRadius: [this.state.viewState.zoom, this.props.hoveredAssetID, this.props.selectedAsset],
      },
      getLineWidth: 5,
      getElevation: 30,
      onClick: this.handleAssetClick,
      onDragStart: this.handleDragStart,
      onDrag: this.handleDrag,
      onDragEnd: this.handleDragEnd,
    });

    let visLayerAssets = [];
    const polygonLayerAssets = {
      type: 'FeatureCollection',
      features: [],
    };

    const { selectedVisualizationLayer } = this.props;
    if (['generation_load', 'operational_envelope'].includes(selectedVisualizationLayer)) {
      visLayerAssets = Object.values(this.state.data.nodeIcons);
    } else if (selectedVisualizationLayer === 'current') {
      visLayerAssets = Object.values(this.state.data.linkIcons);
    } else if (
      [
        'apparent_power',
        'real_power',
        'reactive_power',
        'real_power_losses',
        'power_factor',
      ].includes(selectedVisualizationLayer)
    ) {
      visLayerAssets = [
        ...Object.values(this.state.data.nodeIcons),
        ...Object.values(this.state.data.linkIcons),
      ];
    } else if (['hosting_capacity', 'ev_capacity'].includes(selectedVisualizationLayer)) {
      visLayerAssets = Object.values(this.state.data.nodes);
      const data =
        selectedVisualizationLayer === 'hosting_capacity'
          ? this.props.hostingCapacityTimepointData
          : this.props.evCapacityTimepointData;
      const layer =
        selectedVisualizationLayer === 'hosting_capacity'
          ? this.props.layerOptions.hosting_capacity
          : this.props.layerOptions.ev_capacity;

      const phase = layer.mode === 'balanced' ? 'ABC' : layer.phase;
      Object.keys(data).forEach(node => {
        const HCNode = visLayerAssets.find(n => n.properties.id === node);
        const nodeCoords = HCNode.geometry.coordinates;
        polygonLayerAssets.features.push({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: this.hexagonCoordinates(nodeCoords),
          },
          properties: {
            ...HCNode.properties,
            id: HCNode.properties.id,
            name: HCNode.properties.name,
            nodeValue: data[node][phase],
            color: this.getPolygonColor(data[node][phase], layer),
          },
        });
      });
    } else if (
      [
        'battery_sizing_energy',
        'battery_sizing_real_power',
        'battery_sizing_reactive_power',
      ].includes(selectedVisualizationLayer)
    ) {
      visLayerAssets = Object.values(this.state.data.nodes);
      const data = this.props.batterySizingData;
      const layer = this.props.layerOptions[selectedVisualizationLayer];

      let variable;
      switch (selectedVisualizationLayer) {
        case 'battery_sizing_energy':
          variable = 'max_energy';
          break;
        case 'battery_sizing_real_power':
          variable = 'max_p';
          break;
        case 'battery_sizing_reactive_power':
          variable = 'max_q';
          break;
        default:
          variable = '';
      }
      Object.keys(data).forEach(node => {
        const HCNode = visLayerAssets.find(n => n.properties.id === node);
        const nodeCoords = HCNode.geometry.coordinates;
        polygonLayerAssets.features.push({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: this.hexagonCoordinates(nodeCoords),
          },
          properties: {
            ...HCNode.properties,
            id: HCNode.properties.id,
            name: HCNode.properties.name,
            nodeValue: data[node][variable],
            color: this.getPolygonColor(data[node][variable], layer),
          },
        });
      });
    }

    const vizLayer = new ScatterplotLayer({
      parameters: { depthTest: false },
      id: 'load-gen-layer',
      data: visLayerAssets,
      pickable: false,
      opacity: this.props.selectedVisualizationLayer === 'generation_load' ? 1 : 0.25,
      stroked: false,
      filled: true,
      radiusScale: 1,
      radiusMaxPixels: 15,
      updateTriggers: {
        getPosition: [this.state.data],
        getRadius: [
          this.state.viewState.zoom,
          this.state.feeders,
          this.props.assetTypeVisibility,
          this.props.inEditMode,
        ],
      },
      getFillColor: d => d.properties.visualizationColor,
      getPosition: d => d.geometry.coordinates,
      getRadius: d => {
        // if (!d.properties.totalP) return 0;
        if (this.state.viewState.zoom < 14) return 0;
        if (!this.inSelectedFeeders(d)) return 0;
        if (!this.isVisible(d)) return 0;
        return this.state.viewState.zoom ** 1.4;
      },
      onClick: this.handleAssetClick,
      onHover: this.handleAssetHover,
    });

    let polygonLayer;

    if (this.props.mapMode === '3d') {
      // Polygon Layer
      polygonLayer = new GeoJsonLayer({
        id: 'geoJsonPolygon',
        data: polygonLayerAssets,
        opacity: 0.5,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: f => f.properties.nodeValue * 0.0001,
        getFillColor: f => f.properties.color,
        getLineColor: f => f.properties.color,
        pickable: true,
        onHover: this.handleAssetHover,
        onClick: this.handleAssetClick,
      });
    }

    const editPathLayer = new EditableGeoJsonLayer({
      id: 'edit-path-layer',
      data: this.state.editLayer,
      stroked: true,
      filled: true,
      mode: 'modify',
      selectedFeatureIndexes: this.state.selectedFeatureIndexes,
      getRadius: 30,
      lineWidthScale: 1,
      lineWidthMinPixels: 0,
      lineWidthMaxPixels: 15,
      getLineWidth: 5,
      getLineDashArray: () => [0, 0],
      getLineColor: () => [255, 255, 255, 255],
      getFillColor: () => [0, 0, 255, 255],
      onEdit: ({ updatedData }) => {
        // To prevent dragging the endpoints,
        // need to adjust the coordinates
        const currentCoordinates = this.state.editLayer.features[0].geometry.coordinates;
        const newCoordinates = updatedData.features[0].geometry.coordinates;
        updatedData.features[0].geometry.coordinates = [
          currentCoordinates[0],
          ...newCoordinates.slice(1, -1),
          currentCoordinates[currentCoordinates.length - 1],
        ];
        this.setState({ editLayer: updatedData });
      },
    });

    let layers = [pathLayer, connectorLayer, nodeLayer, iconLayer, textLayer, fileLayer, noteLayer];

    if (Object.values(this.props.assetViolationLayers).some(layerEnabled => layerEnabled)) {
      const assetViolationLayer = new IconLayer({
        id: 'asset-violation-layer',
        opacity: 1,
        sizeScale: 1.3,
        data: this.state.violationAssetData.filter(asset =>
          this.props.violatingAssetIds.includes(asset.properties.id),
        ),
        iconAtlas: assetViolation,
        iconMapping: {
          icon: {
            x: 0,
            y: 0,
            height: 276,
            width: 306,
            anchorX: -55,
            anchorY: 490,
          },
          load: {
            x: 0,
            y: 0,
            height: 276,
            width: 306,
            anchorX: -35,
            anchorY: 340,
          },
          line: {
            height: 276,
            width: 306,
            anchorX: 115,
            anchorY: 163,
          },
        },
        getIcon: d => {
          const {
            properties: { asset_type },
          } = d;

          switch (asset_type) {
            case 'ACLineSegment':
              return 'line';
            case 'EnergyConsumer':
              return 'load';
            default:
              return 'icon';
          }
        },
        getPosition: d => d.geometry.coordinates,
        getSize: d => {
          if (this.state.viewState.zoom < 14) return 0;
          if (!this.inSelectedFeeders(d)) return 0;
          if (!this.isVisible(d)) return 0;
          return 30;
        },
      });

      layers.push(assetViolationLayer);
    }

    let violations;

    if (!this.props.inEditMode) {
      violations = new ViolationsLayer({
        id: 'violations-layer',
        data: this.state.violationAssetData,
        pickable: true,
        zoom: this.state.viewState.zoom,
        opacity: 1,
        bearing: this.state.viewState.bearing,
        violations: this.state.displayViolations,
        assetTypeVisibility: this.props.assetTypeVisibility,
        cluster: this.cluster,
        onClick: this.handleAssetClick,
        getAngle: 0,
        map: this.map,
        parameters: {
          depthTest: false,
        },
      });
    }

    const overlayLayers = [selectionLayer, editPathLayer];
    if (
      [
        'hosting_capacity',
        'ev_capacity',
        'battery_sizing_energy',
        'battery_sizing_real_power',
        'battery_sizing_reactive_power',
      ].includes(this.props.selectedVisualizationLayer) &&
      this.props.mapMode === '3d'
    ) {
      overlayLayers.push(polygonLayer);
      overlayLayers.push(violations);
    } else {
      layers = layers.concat(violations);
      overlayLayers.push(vizLayer);
      if (
        animationLayers &&
        this.state.animating &&
        (!this.props.layerOptions[selectedVisualizationLayer].layerType ||
          this.props.layerOptions[selectedVisualizationLayer].layerType === layerTypes.ACTUAL)
      ) {
        overlayLayers.push(animationLayers);
      }
    }

    return layers.concat(overlayLayers);
  };

  _renderTooltip = () => {
    const { hoveredObject, pointerX, pointerY } = this.state || {};
    const { layerOptions, selectedVisualizationLayer, analysisSettings } = this.props;
    let type = '';
    let value = '';
    if (selectedVisualizationLayer === 'hosting_capacity') {
      type = 'hc';
      value = this.props.hostingCapacityTimepointData[hoveredObject?.properties?.id];
    } else if (selectedVisualizationLayer === 'ev_capacity') {
      type = 'ev';
      value = this.props.evCapacityTimepointData[hoveredObject?.properties?.id];
    } else if (selectedVisualizationLayer === 'operational_envelope') {
      type = 'ope';
      value = this.props.operationalData[hoveredObject?.properties?.id];
    }
    return (
      hoveredObject?.properties && (
        <MapTooltip
          aggregation={layerOptions.aggregation}
          dataOverride={type ? { type, value } : undefined}
          displayName={hoveredObject.properties.display_type}
          name={hoveredObject.properties.name}
          assetClass={hoveredObject.properties.asset_type}
          permissions={this.props.permissions}
          phase={hoveredObject.properties.phase}
          results={this.props.results ? this.props.results[hoveredObject.properties.id] : undefined}
          pointerX={pointerX}
          pointerY={pointerY}
          analysisSettings={analysisSettings}
        />
      )
    );
  };

  // Sets the asset as the selected asset
  handleAssetClick = ({ object }) => {
    if (
      object &&
      object.properties &&
      !object.properties.cluster &&
      !this.props.inPathEditMode &&
      object.properties.id !== this.props.selectedAssetID
    ) {
      if (this.props.leftRailPanel === LEFT_RAIL_PANEL.NETWORK) {
        this.props.actions.setActiveLeftPanel(LEFT_RAIL_PANEL.ASSET);
      }
      this.props.actions.setSelectedAssetID(object.properties.id);
    } else {
      this.props.actions.setSelectedAssetID(null);
    }

    return false;
  };

  // Sets the asset as the hovered asset (used for the tooltip)
  handleAssetHover = ({ object, x, y }) => {
    if (object) {
      this.setState({
        hoveredObject: object,
        pointerX: x,
        pointerY: y,
        isHovering: true,
      });
    } else {
      this.setState({ hoveredObject: null, isHovering: false });
    }
  };

  handleZoom = zoomValue => () => {
    this.setState({
      viewState: {
        ...this.state.viewState,
        zoom: this.state.viewState.zoom + zoomValue,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
      },
    });
  };

  handleZoomToLocation = assetId => {
    const coords = Helpers.getCenterCoords(assetId, this.state.data);
    if (!coords) {
      return; // we cannot locate the asset
    }
    this.setState({
      viewState: {
        ...this.state.viewState,
        latitude: coords[0],
        longitude: coords[1],
        zoom: 18,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
      },
    });
  };

  handleUpdateViewport = viewState => {
    if (this.deck) {
      this.getInView(viewState);
    }
    this.setState({ viewState: { ...this.state.viewState, ...viewState } });
  };

  handleResetViewportProp = property =>
    this.setState({
      viewState: {
        ...this.state.viewState,
        [property]: 0,
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
      },
    });

  handleMapClick = ({ lngLat, point }) => {
    const { inEditMode, newAsset, addAssetRequest, inPathCreateMode } = this.props;

    if (inEditMode && newAsset && newAsset.id === 'node' && addAssetRequest !== LOADING) {
      this.handleAddNode(lngLat);
    } else if (inPathCreateMode) {
      const picked = this.deck.pickObject({ x: point[0], y: point[1] });
      this.handleAddLine(picked, lngLat, false);
    }
    return false;
  };

  handleMapDblClick = ({ lngLat, point }) => {
    const { inEditMode, inPathCreateMode } = this.props;
    if (inEditMode && inPathCreateMode) {
      const picked = this.deck.pickObject({ x: point[0], y: point[1] });
      this.handleAddLine(picked, lngLat, true);
      return true;
    }
    return false;
  };

  handleMapHover = ({ lngLat }) => {
    const { linkConnectors, lines } = this.state.data;
    const { inPathCreateMode, addAssetRequest } = this.props;

    if (!inPathCreateMode || !lines._newLine || addAssetRequest === LOADING) {
      return false;
    }

    const newLinkConnectors = {
      ...linkConnectors,
      _drawPlaceholder: createHoverLine(lines._newLine, lngLat),
    };

    this.setState({
      connectorData: [
        ...Object.values(this.state.data.nodeConnectors),
        ...Object.values(newLinkConnectors),
      ],
      data: {
        ...this.state.data,
        linkConnectors: newLinkConnectors,
      },
    });

    return false;
  };

  getMapWidth = () => {
    if (this.mapContainer) {
      const { width } = this.mapContainer.getBoundingClientRect();
      return width + (this.props.leftPanelExpanded ? 300 : 0);
    }
    return this.state.viewState.width;
  };

  render() {
    const background = 'black';
    const mapTileTheme = 'dark-matter';
    let mapSource = null;
    switch (this.props.mapMode) {
      case '2d':
        mapSource = `https://basemaps.cartocdn.com/gl/${mapTileTheme}-gl-style/style.json`;
        break;
      case '3d':
        mapSource = `/3d-style-${mapTileTheme}.json`;
        break;
      case 'custom':
        mapSource = JSON.parse(this.props.customMapBoxSource);
        break;
      case 'off':
      default:
        break;
    }

    const legendEnabled =
      this.context.assetsWithFiles.length || this.context.assetsWithNotes.length;

    const inLineEdit =
      this.props.inPathCreateMode &&
      this.state.data &&
      this.state.data.lines &&
      this.state.data.lines._newLine;

    return (
      <div
        className="deck-gl-map"
        style={{ background, height: '100%', width: '100%' }}
        ref={container => {
          this.mapContainer = container;
        }}
      >
        {legendEnabled ? <MapLegend /> : null}
        <ZoomControls
          theme={this.props.theme}
          position={{ top: '20px', left: '20px' }}
          zoomIn={this.handleZoom(1)}
          zoomOut={this.handleZoom(-1)}
          zoomToBounds={this.zoomToBounds}
          resetTilt={() => this.handleResetViewportProp('pitch')}
          resetOrientation={() => this.handleResetViewportProp('bearing')}
          isTilted={this.state.viewState.pitch !== 0}
          orientationChanged={this.state.viewState.bearing !== 0}
        />
        {this.props.inPathCreateMode && !this.state.data.lines._newLine && (
          <div className="add-line-prompt">Select a node to start adding line.</div>
        )}

        {this.props.newAsset && this.props.newAsset.id === 'node' && (
          <div className="add-line-prompt">Click anywhere to add node.</div>
        )}

        <MapGL
          {...this.state.viewState}
          width={this.getMapWidth()}
          dragPan={!this.state.dragging}
          doubleClickZoom={!inLineEdit}
          dragRotate={!this.state.dragging}
          ref={map => {
            if (!this.map && map) {
              this.map = map;
            }
          }}
          attributionControl={false}
          onHover={this.handleMapHover}
          onClick={this.handleMapClick}
          onDblClick={this.handleMapDblClick}
          onViewportChange={this.handleUpdateViewport}
          mapStyle={mapSource}
          transformRequest={(url, resourceType) => {
            if (resourceType === 'Tile' && url.match('cartocdn.com')) {
              return {
                url: `${url}?api_key=QSztUEhQs9PxFht6TLe9Ec`,
              };
            }

            return { url };
          }}
          visible={this.props.mapMode !== 'off'}
        >
          <DeckGL
            ref={deck => {
              this.deck = deck;
            }}
            viewState={this.state.viewState}
            layers={this.getNebulaLayers()}
            getCursor={() => {
              const inAddNode = this.props.newAsset && this.props.newAsset.id === 'node';
              if (inLineEdit || inAddNode) return 'crosshair';
              return this.state.isHovering ? 'pointer' : 'grab';
            }}
          />
        </MapGL>
        {this._renderTooltip()}
      </div>
    );
  }
}

DeckGLMap.contextType = AttachmentsContext;

DeckGLMap.defaultProps = {
  theme: 'dark',
  selectedAsset: null,
  hoveredAssetID: null,
  assetTypeVisibility: {},
  selectedVisualizationLayer: null,
  layerOptions: null,
  flyToAsset: null,
  selectedFeeders: [],
  hostingCapacityTimepointData: {},
  evCapacityTimepointData: {},
  batterySizingData: {},
  violations: {},
  newAsset: null,
  addAssetRequest: INITIAL,
  editLineRequest: INITIAL,
  editShuntRequest: INITIAL,
  editNodeRequest: INITIAL,
  customMapBoxSource: null,
  results: null,
  operationalData: {},
};

DeckGLMap.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  mapMode: PropTypes.oneOf(['off', '2d', '3d', 'custom']).isRequired,
  editActions: PropTypes.shape({
    editNodePosition: PropTypes.func,
    editSingleEquipment: PropTypes.func,
    editShuntPosition: PropTypes.func,
  }).isRequired,
  actions: PropTypes.shape({
    createNewNode: PropTypes.func,
    createNewLine: PropTypes.func,
    setSelectedAsset: PropTypes.func,
    setSelectedAssetID: PropTypes.func,
    setActiveLeftPanel: PropTypes.func,
  }).isRequired,
  theme: PropTypes.string,
  violations: PropTypes.object,
  newAsset: PropTypes.object,
  addAssetRequest: PropTypes.number,
  selectedAsset: PropTypes.object, // JSCIM object
  selectedAssetID: nullable(PropTypes.string).isRequired,
  hoveredAssetID: PropTypes.string,
  leftRailPanel: PropTypes.string.isRequired,
  assetTypeVisibility: PropTypes.object, // map of type type to visibility setting
  setFilteredView: PropTypes.func.isRequired,
  selectedVisualizationLayer: PropTypes.string,
  layerOptions: PropTypes.object,
  networkGeoJSON: nullable(PropTypes.object).isRequired,
  flyToAsset: PropTypes.object,
  selectedFeeders: PropTypes.array,
  hostingCapacityTimepointData: PropTypes.object,
  evCapacityTimepointData: PropTypes.object,
  batterySizingData: PropTypes.object,
  inEditMode: PropTypes.bool.isRequired,
  inPathEditMode: PropTypes.bool.isRequired,
  discardPathEdit: PropTypes.bool.isRequired,
  inPathCreateMode: PropTypes.bool.isRequired,
  editLineRequest: PropTypes.number,
  editShuntRequest: PropTypes.number,
  editNodeRequest: PropTypes.number,
  assetViolationLayers: PropTypes.object.isRequired,
  violatingAssetIds: PropTypes.array.isRequired,
  violationDisplayMin: PropTypes.number.isRequired,
  customMapBoxSource: PropTypes.string,
  results: PropTypes.object,
  permissions: PropTypes.object.isRequired,
  analysisSettings: PropTypes.object.isRequired,
  leftPanelExpanded: PropTypes.bool.isRequired,
  operationalData: PropTypes.object,
};

export default DeckGLMap;
