import React from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import NumberInput from 'components/NumberInput';
import { amps, kA, kVll } from 'helpers/units';
import AssetSingleSelect from '../../Inputs/AssetSingleSelect';
import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';

const infoValues = [
  {
    id: 'ratedVoltage',
    label: 'Rated Voltage',
    unit: kVll,
    divisor: 1000,
    type: 'number',
  },
  {
    id: 'breakingCapacity',
    label: 'Breaking Capacity',
    unit: kA,
    divisor: 1000,
    type: 'number',
  },
  {
    id: 'ratedCurrent',
    label: 'Rated Current',
    unit: amps,
    divisor: 1,
    type: 'number',
  },
  {
    id: 'short_term_emergency_current_limit',
    label: 'Short Term Emergency Current Limit',
    unit: amps,
    divisor: 1,
    type: 'number',
    nestingKey: 'alternate_limits',
  },
  {
    id: 'long_term_emergency_current_limit',
    label: 'Long Term Emergency Current Limit',
    unit: amps,
    divisor: 1,
    type: 'number',
    nestingKey: 'alternate_limits',
  },
];

const InstanceInfo = ({
  asset: {
    attributes: { id, closed, balanced, phase_closed_status: closedStatus },
    phase,
    container,
    switch_info,
    switch_info_options = [], // Only defined in edit mode
  },
  workspace,
  displayBranch,
  handleSave,
  inEditMode,
  disabled,
  theme,
}) => {
  // Incase switch doesn't have an info set
  if (!switch_info) switch_info = {};

  const handleGangedPhaseStatus = status =>
    Object.keys(closedStatus).reduce(
      (acc, switch_phase) => ({ ...acc, [switch_phase]: status }),
      {},
    );

  const handleInfoSelect = ({ value: selectedId, isUnganged }) => {
    const closeAll = switch_info.isUnganged && !isUnganged;
    // Need to auto save new phase status and switch info
    if (closeAll) {
      // Switch all phases to closed if they're not all the same
      const statuses = uniq(Object.values(closedStatus));
      const status = statuses.length > 1 ? true : statuses[0];
      const newStatus = handleGangedPhaseStatus(status);

      handleSave({
        closed_phases: newStatus,
        switch_info: selectedId,
      });
    } else {
      handleSave({ switch_info: selectedId });
    }
  };

  const handleUnbalancedStatusChange = (value, p) => {
    let closedUnbalanced = { ...closedStatus, [p]: value };

    if (!switch_info.isUnganged) {
      // link switch status across phases
      closedUnbalanced = handleGangedPhaseStatus(value);
    }
    handleSave({ closed_phases: closedUnbalanced });
  };

  // TODO CONFIRM THIS LOGIC
  const handleStatusChange = value => handleSave({ attributes: { open: value } });

  const mappedSwitchInfoOptions = [
    {
      value: switch_info.id || null,
      label: switch_info.name || switch_info.id || 'Select a Switch Info',
      disabled: true,
    },
    ...switch_info_options.map(swi => ({ value: swi.id, label: swi.name || swi.id })),
  ];

  return (
    <div>
      <div className="header-container">
        <EquipmentInfoSelector
          eqLibURL={`/${workspace}/${displayBranch}/library/switches`}
          displayName="Switch"
          assetId={id}
          value={switch_info.id}
          defaultOptions={mappedSwitchInfoOptions}
          loadOptions={() => mappedSwitchInfoOptions}
          id="switch-info-select"
          onChange={handleInfoSelect}
          disabled={disabled || !inEditMode}
          feeder={container.id}
        />
        <hr className="section-divider" />

        {infoValues.map(value => (
          <NumberInput
            className="single-row-input"
            {...value}
            key={value.id}
            value={
              value.nestingKey ? switch_info?.[value.nestingKey]?.[value.id] : switch_info[value.id]
            }
            inputStyle="panel"
            theme={theme}
            disabled
            divisor={value.divisor}
            precision={3}
          />
        ))}

        {balanced && (
          <AssetSingleSelect
            label="Switch Status"
            key="closed"
            defaultValue={false}
            options={[
              { value: true, label: 'Closed' },
              { value: false, label: 'Open' },
            ]}
            value={closed}
            onChange={(value, key) => handleStatusChange(value, key)}
            id="closedBalanced"
            disabled={disabled}
          />
        )}

        {!balanced && (
          <div className="asset-panel-section">
            <h4>Switch Status</h4>
            {phase
              .replace('N', '')
              .split('')
              .map(p => (
                <AssetSingleSelect
                  label={p}
                  key={`closedUnbalanced-${p}`}
                  defaultValue={false}
                  options={[
                    { value: true, label: 'Closed' },
                    { value: false, label: 'Open' },
                  ]}
                  value={closedStatus[p]}
                  onChange={value => handleUnbalancedStatusChange(value, p)}
                  id={`closedUnbalanced${p}`}
                  disabled={disabled}
                  format={false}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

InstanceInfo.defaultProps = {
  disabled: true,
  inEditMode: false,
  theme: 'light',
};

InstanceInfo.propTypes = {
  disabled: PropTypes.bool,
  inEditMode: PropTypes.bool,
  asset: PropTypes.shape({
    attributes: PropTypes.shape({
      id: PropTypes.string,
      balanced: PropTypes.bool,
      closed: PropTypes.bool,
      phase_closed_status: PropTypes.shape({
        A: PropTypes.bool,
        B: PropTypes.bool,
        C: PropTypes.bool,
      }),
      switch_type: PropTypes.string,
    }),
    phase: PropTypes.string,
    container: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    switch_info: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isUnganged: PropTypes.bool,
    }),
    switch_info_options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

export default InstanceInfo;
