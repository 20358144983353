import React from 'react';
import PropTypes from 'prop-types';
import ThemeContext from 'helpers/ThemeContext';
import { isDefined } from 'helpers/utils';
import NumberInput from 'components/NumberInput';

// Create an editable field. Can only be used for single values. Per phase values
// should be rendered with `EditablePhaseValues`

const SingleEditableValue = props => {
  const getValue = () => {
    if (isDefined(props.value)) {
      return props.value;
    }

    return props[props.id] !== undefined ? props[props.id] : '';
  };

  const getValidationMessage = () => {
    if (props.validationMessage && Array.isArray(props.validationMessage)) {
      return props.validationMessage.map(msg => (
        <span className="validation-message" key={msg}>
          {msg}
        </span>
      ));
    }
    const validatorMessage = validationCheck ? validationCheck.message : null;
    return props.validationMessage || validatorMessage;
  };

  const isInvalid = (val, validator) =>
    validator && validator(val, { ...props, [props.id]: getValue() });

  const validationCheck = isInvalid(getValue(), props.customValidation);

  const invalid = props.invalid || (validationCheck && !validationCheck.valid);
  const edited = props.edited[props.id] || props.edited === true;
  // NumberInput
  return (
    <ThemeContext.Consumer>
      {theme => (
        <NumberInput
          key={`${props.id}-editable`}
          inputStyle="panel"
          className="single-row-input"
          label={props.label}
          value={getValue()}
          unit={props.unit}
          onChange={e => props.onChange(e.value, e.id)}
          edited={edited}
          id={props.id}
          htmlFor={props.htmlFor}
          theme={theme}
          disabled={!props.editable || props.disabled}
          onBlur={!invalid && edited ? props.onBlur : null}
          phases={props.phases}
          gt={props.min}
          lt={props.max}
          required={props.required}
          validationMessage={getValidationMessage()}
          showValidationMessage={props.showValidationMessage}
          step={props.step}
          divisor={props.divisor}
          precision={props.precision}
          placeholder={props.placeholder}
          type={props.type}
          realValue={getValue()}
          invalid={invalid}
        />
      )}
    </ThemeContext.Consumer>
  );
};

SingleEditableValue.defaultProps = {
  unit: null,
  min: null,
  max: null,
  required: false,
  step: 'any',
  invalid: false,
  validationMessage: null,
  customValidation: null,
  precision: null,
  divisor: 1,
  editable: true,
  disabled: false,
  onBlur: undefined,
  phases: undefined,
  showValidationMessage: 'after-edit',
  edited: {},
  onChange: null,
  htmlFor: undefined,
  value: undefined,
  placeholder: '',
};

SingleEditableValue.propTypes = {
  id: PropTypes.string.isRequired,
  htmlFor: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  divisor: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  edited: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  type: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  required: PropTypes.bool,
  step: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  invalid: PropTypes.bool,
  validationMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  customValidation: PropTypes.func,
  precision: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  phases: PropTypes.string,
  showValidationMessage: PropTypes.oneOf(['always', 'after-edit', 'never']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholder: PropTypes.string,
};

export default SingleEditableValue;
