/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import Select from 'components/Select';
import SelectedAnalysisControlMode from './SelectedAnalysisMode';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import './ControlPanel.scss';

const ControlPanel = props => (
  <div className="control-mode">
    <div className="control-mode-aux-content">
      <span className="control-mode-title">Control Mode</span>
      <div className="control-mode-row">
        <p>Network Version Default</p>
        <div className="row-value">
          <div className="control-mode-select">
            <Select
              name="control-panel-control-type"
              id="control-panel-control-type"
              value={props.selectedControlType}
              className="custom-react-select-theme"
              disabled={props.disabled}
              placeholder="Select Control Type"
              options={props.controlTypes}
              clearable={false}
              searchable={false}
              onChange={props.onChangeControlType}
            />
            {props.help && (
              <Tooltip placement="left" content={props.help}>
                <i className="material-icons help-icon">help_outline</i>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <SelectedAnalysisControlMode
        branch={props.branch}
        inEditMode={props.inEditMode}
        selectedAnalysis={props.selectedAnalysis}
        selectedAssetId={props.selectedAssetId}
        workspace={props.workspace}
        options={props.displayControlTypes ?? props.controlTypes}
      />
    </div>

    <div className="editable-section">
      {props.controlPanelValues.map(value => (
        <div className="input-spacing" key={value.id}>
          <SingleEditableValue
            {...(props.selected.validationSchema ? props.selected.validationSchema[value.id] : {})}
            {...props}
            {...value}
            key={value.id}
            name={value.id}
            precision={3}
          />
        </div>
      ))}
    </div>
    {props.children}
    <hr className="section-divider" />
  </div>
);

ControlPanel.defaultProps = {
  disabled: false,
  selected: {},
  children: null,
  controlPanelValues: [],
  isRegulator: false,
  help: '',
  controlMode: 'uncontrolled',
  normalStep: null,
  onChangeControlType: undefined,
  selectedAnalysis: null,
  displayControlTypes: null,
};

ControlPanel.propTypes = {
  disabled: PropTypes.bool,
  isRegulator: PropTypes.bool,
  controlMode: PropTypes.string,
  normalStep: PropTypes.object,
  selectedControlType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  controlTypes: PropTypes.array.isRequired,
  displayControlTypes: PropTypes.array,
  controlPanelValues: PropTypes.array,
  onChangeControlType: PropTypes.func,
  selected: PropTypes.shape({
    validationSchema: PropTypes.object,
  }),
  children: PropTypes.node,
  inEditMode: PropTypes.bool.isRequired,
  selectedAnalysis: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  selectedAssetId: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
};

export default ControlPanel;
