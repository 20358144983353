import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from 'store/appState';

import Settings from '../components/Settings';

const mapStateToProps = state => ({
  settings: state.global.settings,
  theme: state.global.theme,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ updateSettings: actions.updateSettings }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
