type CimObject = {
  class: string;
  attributes: { [key: string]: any };
  references: { [key: string]: string[] };
  extractReference: (id: string, ref: string) => CimObject;
};

type returnObjectType = { [key: string]: { [key: string]: number } };

export const extractAlternateLimitSet = (object: CimObject): returnObjectType => {
  const limitInfoRefs = object.references['AssetInfo.AlternateLimitSet'] || [];
  return limitInfoRefs.reduce((obj: returnObjectType, info: string) => {
    const category = object.extractReference(info, 'OperationalLimitSet.OperationalLimitGroup')
      ?.attributes['OperationalLimitGroup.limitCategory'];
    const limitVal = object.extractReference(info, 'OperationalLimitSet.OperationalLimitValue')
      ?.attributes;
    obj[category] = limitVal;
    return obj;
  }, {});
};

export default extractAlternateLimitSet;
