import AssetInfo from './AssetInfo';

class PhotoVoltaicUnitInfo extends AssetInfo {
  get minP() {
    return this.attributes['PowerElectronicsUnitInfo.minP'];
  }

  get maxP() {
    return this.attributes['PowerElectronicsUnitInfo.maxP'];
  }
}

export default PhotoVoltaicUnitInfo;
