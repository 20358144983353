import React, { FunctionComponent, useState, useContext } from 'react';
import ResultsCard from 'components/ResultsCard';
import { ThemeProp } from 'types/index';
import Button from 'components/Button';
import CustomScrollBar from 'components/CustomScrollBar';
import _ from 'lodash';
import { CALENDAR_MONTHS } from 'helpers/calendarHelper';
import { percent } from 'helpers/units';
import { sorter, isEmptyObject } from 'helpers/utils';
import LoadingSkeleton from 'components/LoadingSkeleton';
import CalendarPicker from 'components/CalendarPicker';
import { useRequestEffect, useRequest } from '@opusonesolutions/gridos-app-framework';
import moment from 'moment';
import NumberInput from 'components/NumberInput';
import Tooltip from 'components/Tooltip';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import {
  monthRangesModalConfig,
  bulkRatingsModalConfig,
} from '../helpers/ScenarioGenerationHelpers';
import RatingsByMonthRangesModal from './RatingsByMonthRangesModal';
import BulkRatingsUpdateModal from './BulkRatingsUpdateModal';

type SeasonalRatingsProps = {
  theme: ThemeProp;
  workspace: string;
  branch: string;
};

type assetsRatingsType = {
  [id: string]: {
    name: string;
    ratings: {
      [key: string]: number;
    };
  };
};

type AssetTypeRatingsResponse = {
  assets: assetsRatingsType;
};

type monthRangeType = string[][];

const assetTypes = [
  { label: 'Switches', value: 'switch' },
  { label: 'Lines', value: 'line' },
  { label: 'Cables', value: 'cable' },
  { label: 'Regulators', value: 'regulator' },
  { label: 'Power transformers', value: 'power_transformer' },
];

type monthType = { [month: string]: number | string };
type perAssetRatingsType = {
  [assetId: string]: monthType;
};

const totalMonths = Object.keys(CALENDAR_MONTHS).map(key => key.toUpperCase());
const defaultMonthRanges = totalMonths.map(month => [month]);

const SeasonalRatings: FunctionComponent<SeasonalRatingsProps> = ({ theme, workspace, branch }) => {
  const [selectedAssetType, setSelectedAssetType] = useState<{ [key: string]: string }>(
    assetTypes[0],
  );
  const [perAssetRatings, setPerAssetRatings] = useState<perAssetRatingsType>({});
  const [editedAssetRange, setEditedAssetRange] = useState<{ [key: string]: boolean }>({});
  const [bulkRatingModalConfig, setBulkRatingModalConfig] = useState<bulkRatingsModalConfig>({
    active: false,
  });
  const [combineRangesModalConfig, setCombineRangesModalConfig] = useState<monthRangesModalConfig>({
    active: false,
  });
  const [selectedMonthRanges, setSelectedMonthRanges] = useState<monthRangeType>(
    defaultMonthRanges,
  );
  const { selectedScenario, selectedContainer } = useContext(ScenarioGenerationContext);
  const {
    data: seasonalRatingsData,
    refetch,
    loading: seasonalRatingsDataLoading,
  } = useRequestEffect<AssetTypeRatingsResponse>({
    url: `/api/workspace/${workspace}/branch/${branch}/ratings_schedules`,
    method: 'get',
    params: {
      scenario_id: selectedScenario?.value,
      feeder: selectedContainer,
      asset_type: selectedAssetType.value,
    },
    refetchOnChange: [workspace, branch, selectedContainer, selectedScenario, selectedAssetType],
    blockRequest: () =>
      !(workspace && branch && selectedContainer && selectedScenario && selectedAssetType),
    onSuccess: results => {
      const allAssets = Object.entries(results?.assets ?? {})?.reduce(
        (list: perAssetRatingsType, [id, asset]) => {
          list[id] = totalMonths.reduce((obj: monthType, month) => {
            obj[month] = asset.ratings[month] ?? 100;
            return obj;
          }, {});
          return list;
        },
        {},
      );
      setPerAssetRatings(allAssets);
    },
  });
  const { makeRequest: reqToAddSeasonalRatings, loading: savingSeasonalRatings } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/ratings_schedules`,
  );
  const saveSeasonalRatings = async (id: string, assetID: string, months: string[]) => {
    await reqToAddSeasonalRatings({
      method: 'post',
      body: {
        assets: perAssetRatings,
      },
      params: {
        scenario_id: selectedScenario?.value,
      },
      toast: {
        error: 'Could not save value for seasonal ratings.',
        settings: {
          autoDismiss: true,
        },
      },
      onSuccess: () => {
        const editedAssets =
          assetID === 'all'
            ? Object.keys(seasonalRatingsData?.assets || {}).reduce(
                (obj: { [key: string]: boolean }, key) => {
                  months.forEach(month => {
                    obj[`${month}-${key}`] = true;
                  });
                  return obj;
                },
                {},
              )
            : months.reduce((obj: { [key: string]: boolean }, month) => {
                obj[`${month}-${assetID}`] = true;
                return obj;
              }, {});
        setEditedAssetRange((prevState: { [key: string]: boolean }) => ({
          ...prevState,
          ...editedAssets,
        }));
        refetch();
      },
      onError: () => refetch(),
    });
  };
  const inputField = (id: string, assetID: string, months: string[], ratings: monthType) => {
    const isValueUpdated = ratings[months[0]] !== perAssetRatings[assetID]?.[months[0]];
    let valToShow = null;
    if (assetID !== 'all') {
      valToShow = perAssetRatings[assetID]?.[months[0]] ?? 100;
    } else {
      const perAssetRatingsType = _.map(perAssetRatings, months[0]);
      const perAssetRatingsTypeUniq = _.uniq(perAssetRatingsType);
      if (perAssetRatingsTypeUniq && perAssetRatingsTypeUniq.length === 1) {
        valToShow = perAssetRatingsTypeUniq[0] ?? 100;
      }
    }
    return (
      <NumberInput
        inputStyle="panel"
        id={id}
        value={valToShow}
        onChange={e => {
          if (assetID === 'all') {
            setPerAssetRatings(
              Object.keys(seasonalRatingsData?.assets || {}).reduce(
                (obj: perAssetRatingsType, key) => {
                  obj[key] = {
                    ...perAssetRatings[key],
                    ...months?.reduce((monthObj: monthType, month) => {
                      monthObj[month] = e.value;
                      return monthObj;
                    }, {}),
                  };
                  return obj;
                },
                {},
              ),
            );
          } else {
            setPerAssetRatings({
              ...perAssetRatings,
              ...{
                [assetID]: {
                  ...perAssetRatings[assetID],
                  ...months.reduce((monthObj: monthType, month) => {
                    monthObj[month] = e.value;
                    return monthObj;
                  }, {}),
                },
              },
            });
          }
        }}
        theme={theme}
        disabled={savingSeasonalRatings}
        onBlur={(e: any) => {
          if (isValueUpdated && assetID !== 'all') {
            saveSeasonalRatings(id, assetID, months);
          } else if (assetID === 'all' && e.value && e.value !== '') {
            const originalVal = _.compact(
              _.uniq(
                _.map(
                  _.map(Object.values(seasonalRatingsData?.assets || {}), 'ratings'),
                  months[0],
                ),
              ),
            );
            if (
              (!originalVal.length && parseInt(e.value as string, 10) !== 100) ||
              originalVal.length > 1 ||
              (originalVal.length === 1 && originalVal[0] !== parseInt(e.value as string, 10))
            ) {
              setBulkRatingModalConfig({ active: true, months, newVal: e.value });
            }
          }
        }}
        ge={0}
        required={assetID !== 'all'}
        divisor={1}
        precision={0}
        unit={percent}
        className={editedAssetRange[id] ? 'updated-rating' : assetID}
        placeholder="-"
        rowInput
      />
    );
  };
  return (
    <div data-test="seasonal-ratings" className={`seasonal-ratings ${theme}`}>
      <ResultsCard theme={theme} withBorder={false} className="schedules-card">
        <div className="grid-columns one-five">
          <div>
            {assetTypes.map(assetType => (
              <div className="flex-column" key={assetType.value}>
                <Button
                  type="text"
                  onClick={() => {
                    setSelectedAssetType(assetType);
                    setSelectedMonthRanges(defaultMonthRanges);
                  }}
                  className={`asset-btn ${
                    selectedAssetType.value === assetType.value ? 'active' : ''
                  }`}
                >
                  {assetType.label}
                </Button>
              </div>
            ))}
          </div>
          {seasonalRatingsDataLoading ? (
            <div>
              <div className="margin-10">
                <LoadingSkeleton
                  template="square"
                  theme={theme}
                  count={4}
                  displayStyle="column"
                  height={25}
                />
              </div>
              <LoadingSkeleton template="line" theme={theme} count={10} width={100} />
            </div>
          ) : (
            <div>
              {!isEmptyObject(seasonalRatingsData?.assets || {}) ? (
                <div>
                  <div className="grid-columns auto-fit header">
                    <div />
                    {selectedMonthRanges.map(monthArr => (
                      <div key={monthArr[0]} className="month-ranges">
                        <CalendarPicker
                          startDate={moment.utc(monthArr[0], 'MMMM').toISOString()}
                          endDate={moment.utc(monthArr[monthArr.length - 1], 'MMMM').toISOString()}
                          theme={theme}
                          onApply={(newStart: string, newEnd: string) => {
                            const monthStart = moment(newStart).utc().format('MMMM').toUpperCase();
                            const monthEnd = moment(newEnd).utc().format('MMMM').toUpperCase();
                            const selectedMonths = _.slice(
                              totalMonths,
                              _.indexOf(totalMonths, monthStart),
                              _.indexOf(totalMonths, monthEnd) + 1,
                            );
                            let isSameValuesforEachRange = true;
                            let newMonthRangeGroup = [selectedMonths];
                            const newAfterRangesArr: any = [];
                            _.forEach(selectedMonthRanges, monthArrVal => {
                              if (
                                _.every(monthArrVal, monthVal =>
                                  moment.utc(monthVal, 'MMMM').isAfter(moment(newEnd).utc()),
                                )
                              ) {
                                newAfterRangesArr.push(monthArrVal);
                              }
                            });
                            let splittedMonthsFromCurrRange = [];
                            if (selectedMonths.length < monthArr.length) {
                              splittedMonthsFromCurrRange = _.xor(selectedMonths, monthArr);
                            } else {
                              splittedMonthsFromCurrRange = _.slice(
                                totalMonths,
                                _.indexOf(totalMonths, monthEnd) + 1,
                                newAfterRangesArr.length
                                  ? _.indexOf(totalMonths, newAfterRangesArr[0][0])
                                  : totalMonths.length,
                              );
                              isSameValuesforEachRange =
                                selectedMonths.length === 1 ||
                                _.every(Object.values(perAssetRatings), val =>
                                  _.every(
                                    selectedMonths,
                                    monthVal => val[monthVal] === val[selectedMonths[0]],
                                  ),
                                );
                            }
                            newMonthRangeGroup = _.filter(
                              _.union(
                                [selectedMonths],
                                [splittedMonthsFromCurrRange],
                                newAfterRangesArr,
                              ),
                              val => !_.isEmpty(val),
                            );
                            const newBeforeRangesArr: any = [];
                            _.forEach(selectedMonthRanges, monthArrVal => {
                              if (
                                _.every(monthArrVal, monthVal =>
                                  moment.utc(monthVal, 'MMMM').isBefore(moment(newStart).utc()),
                                )
                              ) {
                                newBeforeRangesArr.push(monthArrVal);
                              }
                            });
                            if (isSameValuesforEachRange) {
                              setSelectedMonthRanges(
                                _.compact([...newBeforeRangesArr, ...newMonthRangeGroup]),
                              );
                            } else {
                              setCombineRangesModalConfig({
                                active: true,
                                months: selectedMonths,
                                setRangesAfterUpdate: () =>
                                  setSelectedMonthRanges(
                                    _.compact([...newBeforeRangesArr, ...newMonthRangeGroup]),
                                  ),
                              });
                            }
                          }}
                          onViewChanged={() => {}}
                          styleFor="topnav"
                          inputStyle="primary"
                          subHourInterval={5}
                          currentView="months"
                          dots={[]}
                          showHeader={false}
                          disabledMonths={_.slice(
                            totalMonths,
                            0,
                            _.indexOf(totalMonths, monthArr[0]),
                          )}
                          validStartMonth={monthArr[0]}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="grid-columns auto-fit asset-list">
                    <div>All assets</div>
                    {selectedMonthRanges.map(monthArr => (
                      <div key={monthArr[0]}>
                        {inputField(`all-asset-${monthArr[0]}`, 'all', monthArr, {})}
                      </div>
                    ))}
                  </div>
                  <div className="asset-lists-items">
                    <div className="asset-lists-items-scrollable">
                      <CustomScrollBar alwaysShow={false} className="">
                        {Object.entries(seasonalRatingsData?.assets || {})
                          .sort(([, aVal], [, bVal]) => sorter.compare(aVal.name, bVal.name))
                          .map(([id, value]) => (
                            <div className="grid-columns auto-fit asset-list" key={id}>
                              <Tooltip content={value.name} placement="top">
                                <div className="asset-name">{value.name}</div>
                              </Tooltip>
                              {selectedMonthRanges.map(monthArr => (
                                <div key={`${monthArr[0]}-${id}`}>
                                  {inputField(`${monthArr[0]}-${id}`, id, monthArr, value.ratings)}
                                </div>
                              ))}
                            </div>
                          ))}
                      </CustomScrollBar>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid-columns items-centered" style={{ minHeight: '50px' }}>
                  No assets found
                </div>
              )}
            </div>
          )}
        </div>
      </ResultsCard>
      <RatingsByMonthRangesModal
        modalActiveConfig={combineRangesModalConfig}
        savingSeasonalRatings={savingSeasonalRatings}
        onCancel={() => {
          setCombineRangesModalConfig({ active: false });
          refetch();
        }}
        onConfirm={() => {
          saveSeasonalRatings('', 'all', combineRangesModalConfig.months ?? []);
          combineRangesModalConfig?.setRangesAfterUpdate &&
            combineRangesModalConfig?.setRangesAfterUpdate();
          setCombineRangesModalConfig({ active: false });
        }}
        combineMonthValue={(value, months) => {
          setPerAssetRatings(
            Object.keys(seasonalRatingsData?.assets || {}).reduce(
              (obj: perAssetRatingsType, key) => {
                obj[key] = {
                  ...perAssetRatings[key],
                  ...months?.reduce((monthObj: monthType, month) => {
                    monthObj[month] =
                      value && value === 'default' ? 100 : perAssetRatings[key][value as string];
                    return monthObj;
                  }, {}),
                };
                return obj;
              },
              {},
            ),
          );
        }}
      />
      <BulkRatingsUpdateModal
        modalActiveConfig={bulkRatingModalConfig}
        savingSeasonalRatings={savingSeasonalRatings}
        onCancel={() => {
          setBulkRatingModalConfig({ active: false });
          refetch();
        }}
        onConfirm={() => {
          saveSeasonalRatings('', 'all', bulkRatingModalConfig.months ?? []);
          setBulkRatingModalConfig({ active: false });
        }}
      />
    </div>
  );
};

export default SeasonalRatings;
