import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withAuth from 'helpers/withAuth';
import { powerflowActions } from '../modules/powerflow';

/*
  This is a container component. This component is only responsible for
  wiring in the actions and state necessary to render a presentational
  component
*/

import AnalysisModal from '../components/AnalysisModal';

/*
  Object of action creators (can also be function that returns object).
  Keys will be passed as props to presentational components.
*/

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...powerflowActions,
    },
    dispatch,
  ),
});

const mapStateToProps = (state, ownProps) => ({
  // From global state
  theme: state.global.theme,

  // From forecast state
  selectedScenario: state.loadForecast.selectedScenario,
  selectedScenarioType: state.loadForecast.selectedScenarioType,
  updateSelectedScenario: state.loadForecast.updateSelectedScenario,
  scenarioHasData: state.loadForecast.scenarioHasData,

  // From network state
  scenarioRange: state.network.scenarioRange,
  maxRange: state.network.maxRange,
  branch: state.network.branch,
  workspace: state.network.workspace,
  determiningTimeRange: state.network.determiningTimeRange,
  selectedAssetID: state.network.selectedAssetID,
  selectedAssetViewModelClass: state.network.selectedAssetViewModelClass,
  selectedAssetName: state.network.selectedAssetName,
  timepoints: state.network.timepoints,
  lookup: state.network.lookup,

  // From feeder state
  selectedFeeders: state.feeders.selected,
  feeders: state.feeders.list,

  // From powerflow state
  layerOptions: state.powerflow.layerOptions,
  ...ownProps,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AnalysisModal));
