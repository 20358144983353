import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const ResetIcon: FunctionComponent<IconPropsSchema> = ({
  color = '#A2A2A2',
  height = '20px',
  width = '20px',
}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" height={height} width={width}>
    <g id="ic_expand_close" transform="translate(1445 2303)">
      <rect id="rectangle" x="-1445" y="-2303" fill="none" width="24" height="24" />
    </g>
    <g id="Symbol_48_6" transform="translate(12198.683 4858.905)">
      <path
        id="path_1_"
        fill={color}
        d="M-12183.5-4842.2c0.7-0.3,1.4-0.7,1.9-1.2c2.4-2.4,2.5-6.3,0.1-8.7c-2.4-2.4-6.4-2.5-8.8-0.1c-1.1,1.2-1.9,2.7-2.2,4.3h-2.2l3.8,3.9l3.9-3.8h-3.3c-0.2-1.2,0.2-2.5,1.1-3.3c1.8-1.8,4.8-1.8,6.6,0.1c1.8,1.8,1.8,4.8-0.1,6.6l0,0c-0.4,0.4-0.9,0.7-1.5,0.9"
      />
      <rect id="Rectangle_820" x="-12191.4" y="-4841.3" fill={color} width="10.8" height="1.5" />
    </g>
  </svg>
);

export default ResetIcon;
