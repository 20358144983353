import React, { lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ProtectedRoute } from '@opusonesolutions/gridos-app-framework';

import CoreLayoutContainer from 'layouts/CoreLayout/CoreLayoutContainer';

import ImportCIM from 'routes/ImportCIM';
import Login from 'routes/Login';
import Profile from 'routes/Profile';
import Settings from 'routes/SettingsMenu/components/Settings';
import ServiceDashboard from 'routes/ServiceDashboard';
import WorkspaceLayout from 'routes/WorkspaceLayout';
import WorkspaceSelection from 'routes/WorkspaceSelection';
import LoadingSpinner from 'components/LoadingSpinner';

const ApiDocs = lazy(() => import('routes/ApiDocs'));

const AppRouter = () => (
  <CoreLayoutContainer>
    <Switch>
      <Route exact path={['/login', '/login/oauth-callback']}>
        <Login />
      </Route>
      <ProtectedRoute exact path="/api-docs">
        <Suspense
          fallback={
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <LoadingSpinner style={{ height: '76px', width: '180px' }} />
            </div>
          }
        >
          <ApiDocs />
        </Suspense>
      </ProtectedRoute>
      <ProtectedRoute exact path="/health-check">
        <ServiceDashboard />
      </ProtectedRoute>
      <ProtectedRoute path={['/settings/:workspace', '/settings']}>
        <Settings />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/import-cim/:workspace?/:branch?"
        requiredPermissions={['import_network']}
      >
        <ImportCIM />
      </ProtectedRoute>
      <ProtectedRoute exact path="/profile">
        <Profile />
      </ProtectedRoute>
      <ProtectedRoute exact path="/">
        <WorkspaceSelection />
      </ProtectedRoute>
      {/*
        This route needs to match both "/:workspace" and "/:workspace/:branch"
        because the outer WorkspaceLayout container needs the branch parameter.

        Routes are matched in order, so the more specific route is listed first
      */}
      <ProtectedRoute path={['/:workspace/:branch', '/:workspace/workspace_overview']}>
        <WorkspaceLayout />
      </ProtectedRoute>
      <Route
        path="*"
        render={({ history }) => {
          history.push('/');
        }}
      />
    </Switch>
  </CoreLayoutContainer>
);

export default withRouter(AppRouter);
