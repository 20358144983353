import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Request } from '@opusonesolutions/gridos-app-framework';
import moment from 'moment';
import {
  ComposedChart,
  ReferenceArea,
  ReferenceLine,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  Area,
} from 'recharts';
import { getNiceTickValues } from 'recharts-scale';
import classNames from 'classnames';
import { isDefined, toISO, addMissingTimepoints, extendDataTimeRange } from 'helpers/utils';
import { kWh } from 'helpers/units';
import { getStartOfInterval } from 'components/ZoomableRangeSlider/intervals';
import ThemeContext from 'helpers/ThemeContext';
import './TimeSeriesChart.scss';
/* eslint-disable react/prop-types */
import ResultsChartCard from './ResultsChartCard';

const getFormatString = (aggregation, defaultFormat) => {
  switch (aggregation) {
    case 'day':
      return 'YYYY/MM/DD';
    case 'month':
      return 'YYYY/MM';
    case 'year':
      return 'YYYY';
    case 'hour':
    default:
      return defaultFormat || 'YYYY/MM/DD HH:mm';
  }
};

const xAxisFormatter = aggregation => getFormatString(aggregation, 'HH:mm');

const customTooltip = ({ payload, label }) => {
  const values = payload && payload[0] ? payload[0].payload : {};

  const generateAggRows = () => (
    <Fragment key="energy">
      <tr>
        <td>Max:</td>
        {values.energy_range !== undefined && (
          <td>{(values.energy_range[1] / 1000.0).toFixed(2)}</td>
        )}
      </tr>
      <tr>
        <td>Average:</td>
        <td>{(values.energy / 1000.0).toFixed(2)}</td>
      </tr>
      <tr>
        <td>Min:</td>
        {values.energy_range !== undefined && (
          <td>{(values.energy_range[0] / 1000.0).toFixed(2)}</td>
        )}
      </tr>
    </Fragment>
  );

  const isAggregated = values.aggregation !== 'none';

  return (
    <div className="tooltip">
      <p>
        <b>{moment.parseZone(label).format(getFormatString(values.aggregation))}</b>
      </p>
      {!isAggregated ? (
        <p>{`Energy (${kWh}) ${(values.energy / 1000.0).toFixed(2)}`}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <td colSpan="2">Energy ({kWh})</td>
            </tr>
          </thead>
          <tbody>{generateAggRows()}</tbody>
        </table>
      )}
    </div>
  );
};

const EnergyTimeSeriesChart = ({
  workspace,
  branch,
  scenario,
  analysisName,
  maxRange,
  highlightRange,
  batteryInverter,
  minEnergy,
  maxEnergy,
  expanded,
  loading,
  timeBarZoomLevel,
}) => {
  const [energies, setEnergies] = useState([]);
  const theme = useContext(ThemeContext);

  const fetchEnergies = () => {
    let didCancel = false;

    const getBatteryEnergies = async () => {
      const url = `/api/workspace/${workspace}/branch/${branch}/power-flow-results/battery`;
      try {
        const request = new Request(url);
        const res = await request.get({
          params: {
            feeder: batteryInverter.container.id,
            scenario_id: scenario,
            analysis_name: analysisName,
            // when bucketing by days or greater in the backend, we need
            // to know what the local timezone is so we know where the local day
            // starts and ends.
            start_date: maxRange.start.toISOString(true),
            end_date: maxRange.end.toISOString(true),
            battery_unit: batteryInverter.power_electronics_units.map(peu => peu.id),
          },
        });
        if (!didCancel) {
          setEnergies(res.data);
        }
      } catch (err) {
        setEnergies([]);
      }
    };

    getBatteryEnergies();
    return () => {
      didCancel = true;
    };
  };

  React.useEffect(fetchEnergies, [
    workspace,
    branch,
    scenario,
    analysisName,
    maxRange,
    batteryInverter,
  ]);

  const getYMin = dataMin => {
    if (minEnergy !== undefined && !!minEnergy) {
      return Math.min(minEnergy, Math.round(dataMin));
    }
    if (dataMin === Infinity || dataMin === -Infinity) {
      return 0;
    }
    const niceTicks = getNiceTickValues([dataMin, 0]);
    const minTick = niceTicks[0];
    if (isDefined(minTick) && typeof minTick === 'number') {
      return Math.min(minTick, 0);
    }
    return 0;
  };

  const getYMax = dataMax => {
    // ensure max is always 1kW or greater
    if (maxEnergy !== undefined && !!maxEnergy) {
      return Math.max(maxEnergy, Math.round(dataMax));
    }
    if (dataMax === Infinity || dataMax === -Infinity) {
      return 1000;
    }
    const niceTicks = getNiceTickValues([0, dataMax]);
    const maxTick = niceTicks[niceTicks.length - 1];
    if (isDefined(maxTick) && typeof maxTick === 'number') {
      return Math.max(maxTick, 1000);
    }
    return 1000;
  };

  const graphData = () => {
    if (!energies?.length) {
      return [];
    }

    let aggregation;

    let data = energies.map(e => {
      const newE = {
        timepoint: e.timepoint,
        aggregation: e.aggregation,
      };

      if (!aggregation) {
        aggregation = e.aggregation !== 'none' ? e.aggregation : 'hour';
      }

      const avg = Object.keys(e.energies)
        .map(battery_unit => e.energies[battery_unit].avg)
        .reduce((a, b) => a + b, 0);
      const min = Object.keys(e.energies)
        .map(battery_unit => e.energies[battery_unit].min)
        .reduce((a, b) => a + b, 0);
      const max = Object.keys(e.energies)
        .map(battery_unit => e.energies[battery_unit].max)
        .reduce((a, b) => a + b, 0);
      newE.energy = avg;
      newE.energy_range = min !== undefined && max !== undefined ? [min, max] : undefined;

      return newE;
    });
    if (maxRange) {
      data = extendDataTimeRange(
        data,
        'timepoint',
        maxRange.start,
        moment(maxRange.end).startOf(aggregation),
      );
    }
    data = addMissingTimepoints(data, 'timepoint');
    return data;
  };

  const data = graphData();
  const highlightStart = toISO(highlightRange.start);
  const highlightEnd = toISO(getStartOfInterval(highlightRange.end, timeBarZoomLevel));

  let cardState = 'initial';
  if (loading) {
    cardState = 'loading';
  } else if (energies.length > 0) {
    cardState = 'loaded';
  }

  return (
    <ResultsChartCard title="Energy" className="time-series-chart" theme={theme} state={cardState}>
      <div className="unit-label-row">
        <p>({kWh})</p>
      </div>
      <div
        className={classNames({
          'chart-pane': true,
          'chart-pane--expanded': expanded,
        })}
      >
        <ResponsiveContainer height="100%" width="100%">
          <ComposedChart syncId="energyTimeChart" data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
            <XAxis
              dataKey="timepoint"
              stroke="#949899"
              scale="point"
              axisLine={false}
              tick={false} // dont show any ticks on the upper graph
              tickFormatter={val =>
                moment.parseZone(val).format(xAxisFormatter(data[0].aggregation))
              }
            />
            <YAxis
              yAxisId="eaxis"
              orientation="left"
              stroke="#949899"
              tickLine={false}
              width={50}
              tickFormatter={val => val / 1000}
              domain={[getYMin, getYMax]}
            />
            <Tooltip content={customTooltip} />
            <Line
              key="energy"
              yAxisId="eaxis"
              name="energy"
              stroke={theme === 'dark' ? '#FFFFFF' : '#262626'}
              strokeWidth={1}
              dataKey="energy"
              isAnimationActive={false}
              dot={false}
              connectNulls={false}
              type="stepAfter"
            />
            <Area
              key="area_energy"
              yAxisId="eaxis"
              name="energy_range"
              stroke="none"
              fill={theme === 'dark' ? '#FFFFFF' : '#262626'}
              fillOpacity={0.25}
              dataKey="energy_range"
              isAnimationActive={false}
              connectNulls={false}
              type="stepAfter"
            />
            {highlightStart === highlightEnd ? (
              <ReferenceLine yAxisId="eaxis" x={highlightStart} stroke="teal" />
            ) : (
              <ReferenceArea
                yAxisId="eaxis"
                x1={highlightStart}
                x2={highlightEnd}
                ifOverflow="visible"
                fillOpacity={0.3}
                fill="teal"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </ResultsChartCard>
  );
};

EnergyTimeSeriesChart.defaultProps = {
  maxEnergy: null,
  minEnergy: null,
};

EnergyTimeSeriesChart.propTypes = {
  highlightRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  maxEnergy: PropTypes.number,
  minEnergy: PropTypes.number,
  branch: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  scenario: PropTypes.string.isRequired,
  analysisName: PropTypes.string.isRequired,
  batteryInverter: PropTypes.object.isRequired, // GET API BatInverter
  expanded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EnergyTimeSeriesChart;
