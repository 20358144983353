import React, { FunctionComponent } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import HelpTooltip from 'components/HelpTooltip';
import LoadingSkeleton from 'components/LoadingSkeleton';
import type { optionType } from 'types/edit.d';

type SelectedAnalysisControlModeProps = {
  branch: string;
  inEditMode: boolean;
  selectedAnalysis?: SelectedAnalysis;
  selectedAssetId: string;
  workspace: string;
  options: optionType[];
};

type SelectedAnalysis = {
  id: string;
  name: string;
};

type AnalysisControlMode = {
  name?: string;
  scheduleAnalysis: string;
};

const SelectedAnalysisControlMode: FunctionComponent<SelectedAnalysisControlModeProps> = ({
  branch,
  inEditMode,
  selectedAnalysis,
  selectedAssetId,
  workspace,
  options,
}) => {
  const {
    data: analysisControlMode,
    loading: analysisControlModeLoading,
  } = useRequestEffect<AnalysisControlMode | null>({
    url: `/api/workspace/${workspace}/branch/${branch}/analysis/${selectedAnalysis?.id}/control_modes`,
    method: 'get',
    params: {
      asset_id: selectedAssetId,
    },
    refetchOnChange: [workspace, branch, selectedAnalysis],
    blockRequest: () => !selectedAnalysis,
    initialData: null,
    dataTransform: data => {
      const controlMode = options.find(({ value }) => value === data[0].control_mode)?.label;
      const scheduleAnalysis = controlMode ? data[0].schedule_analysis?.name : null;
      return { name: controlMode, scheduleAnalysis };
    },
  });

  return (
    <>
      {selectedAnalysis &&
        !inEditMode &&
        (analysisControlModeLoading ? (
          <div className="control-mode-row">
            <LoadingSkeleton template="line" width={100} />
          </div>
        ) : (
          <div className="control-mode-row">
            <p id="control-panel-analysis-name">{`${selectedAnalysis?.name}`}</p>
            <div className="row-value">
              <div className="control-mode-container">
                <p id="control-panel-analysis-control-type">{analysisControlMode?.name || 'N/A'}</p>
                {!analysisControlMode?.name && (
                  <HelpTooltip message="This asset did not participate in the selected analysis" />
                )}
                {analysisControlMode?.scheduleAnalysis && (
                  <HelpTooltip
                    message={`Control mode for "${analysisControlMode?.scheduleAnalysis}"`}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default SelectedAnalysisControlMode;
