import GL from '@luma.gl/constants';
import { Geometry, Model } from '@luma.gl/core';
import { IconLayer } from 'deck.gl';

class GridOSIconLayer extends IconLayer {
  _getModel(gl) {
    // The normal Deck.gl layer has 3d positions. However,
    // the vertext shader begins with:
    //    attribute vec2 positions;
    // Thus, we need to send them to the shader as an array
    // of 2D positions. Why this previously worked on Chrome is unknown,
    // but it did not work on IE11.
    const positions = [-1, -1, -1, 1, 1, 1, 1, -1];

    return new Model(gl, {
      ...this.getShaders(),
      id: this.props.id,
      geometry: new Geometry({
        drawMode: GL.TRIANGLE_FAN,
        attributes: {
          // We specify positions as an object rather than passing
          // raw array. This is due to the need to incidate that we
          // are sending XY data rather than XYZ data. Luma.gl defaults
          // to a vector size of 3 if a size is not specified.
          positions: {
            value: new Float32Array(positions),
            size: 2,
          },
        },
      }),
      isInstanced: true,
    });
  }
}

export default GridOSIconLayer;
