const BETA_PERMISSION = 'access_beta_features';
const DSP_PERMISSIONS = [
  'view_pricing_events',
  'view_DSP_configuration',
  'edit_DSP_configuration',
  'view_customer_information',
  'view_asset_information',
  'view_customer_applications',
  'view_DER_applications',
  'view_kpis',
];

const displayDSP = (permissions: Set<string>): boolean =>
  permissions && DSP_PERMISSIONS.some(permission => permissions.has(permission));

export { BETA_PERMISSION, displayDSP, DSP_PERMISSIONS };
