import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import HelpTooltip from 'components/HelpTooltip';

import SingleEditableValue from '../../Inputs/SingleEditableValue';

const noop = () => undefined;

const UnitField = ({
  onClear,
  validationSchema,
  field,
  edited,
  disabled,
  editable,
  updateValue,
  value,
  handleSave,
  theme,
  showTooltip,
  inEditMode,
  ...props
}) => (
  <div className="inverter-unit-field">
    <SingleEditableValue
      {...validationSchema}
      {...field}
      {...props}
      edited={edited}
      disabled={!editable || disabled}
      onBlur={handleSave}
      onChange={e => updateValue(e)}
      value={value}
      step="any"
    />
    {showTooltip && (
      <HelpTooltip className="" message="Value from Equipment Library" theme={theme} />
    )}
    {inEditMode &&
      !showTooltip && [
        <IconButton
          key="clear"
          icon="close"
          onClick={onClear || noop}
          disabled={!editable || disabled || !onClear || (!value && value !== 0)}
          tooltip={onClear && (value || value === 0) ? 'Clear value' : null}
          className="inverter-unit-field-button"
          theme={theme}
        />,
      ]}
  </div>
);

UnitField.propTypes = {
  onClear: PropTypes.func,
  validationSchema: PropTypes.object,
  field: PropTypes.object.isRequired,
  edited: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  disabled: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  updateValue: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string,
  showTooltip: PropTypes.bool,
  inEditMode: PropTypes.bool.isRequired,
};

UnitField.defaultProps = {
  onClear: undefined,
  validationSchema: undefined,
  edited: undefined,
  value: undefined,
  theme: 'light',
  showTooltip: false,
};

export default UnitField;
