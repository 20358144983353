import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

const LineToggleButton = ({ name, id, disabled, active, onClick, savingAssetEdit }) => (
  <div
    className={classNames('add-icon-container', {
      'add-icon-container--disabled': disabled,
      'add-icon-container--active': active,
    })}
  >
    <button
      onClick={e => onClick(e)}
      id={id}
      className={classNames('icon-button', 'icon-button-line', { 'icon-button--active': active })}
      disabled={disabled}
      type="button"
    >
      <ReactSVG
        className={classNames(`icon-img icon-img--${id}`, { 'icon-img--disabled': disabled })}
        src={`/${id}.svg`}
      />
      {savingAssetEdit && (
        <div className="loading-icon">
          <i className="material-icons rotate">refresh</i>
        </div>
      )}
    </button>
    <p className="icon-label">{name}</p>
  </div>
);

LineToggleButton.defaultProps = {
  active: false,
  disabled: false,
  savingAssetEdit: false,
};

LineToggleButton.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  savingAssetEdit: PropTypes.bool,
};

export default LineToggleButton;
