import AssetModel from './AssetModel';

class ProductAssetModel extends AssetModel {
  get modelNumber() {
    return this.attributes['ProductAssetModel.modelNumber'];
  }

  get modelVersion() {
    return this.attributes['ProductAssetModel.modelVersion'];
  }

  get usageKind() {
    return this.attributes['ProductAssetModel.usageKind'];
  }
}

export default ProductAssetModel;
