import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPES } from 'helpers/EditMode';
import Select from 'components/Select';
import Analytics from 'helpers/Analytics';
import browserHistory from 'routes/history';

import { Request } from '@opusonesolutions/gridos-app-framework';
import { alphabetize, handleTooltipMessageForWorkspace } from 'helpers/utils';
import SelectType from './helpers';
import './DropdownSections.scss';
import ActionMenu from './ActionMenu';

const WorkspaceSection = ({
  actions,
  isAuthEnabled,
  permissions,
  theme,
  workspace,
  inEditMode,
  saveModalOpen,
  handleMenuSelection,
  params,
  view,
}) => {
  // the workspace being confirmed for delete
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedType, setSelectedType] = useState(SelectType.NONE);

  const loadWorkspaceList = () => {
    let didCancel = false;

    async function fetchWorkspaces() {
      if (permissions.has('get_workspaces')) {
        const request = new Request('/api/workspace');
        try {
          const { data } = await request.get();
          if (didCancel) {
            // Cancelled before the request finished so do nothing
            return;
          }
          const sortedWorkspaces = alphabetize(data.workspaces).map(ws => ({
            value: ws,
            label: ws,
          }));
          setWorkspaces(sortedWorkspaces);
        } catch (error) {}
      } else {
        setWorkspaces([{ value: params.workspace, label: params.workspace }]);
      }
    }

    fetchWorkspaces();
    return () => {
      didCancel = true;
    };
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadWorkspaceList, []);

  const canCreateWorkspace = !isAuthEnabled || permissions.has('create_workspace');
  const canDeleteWorkspace = !isAuthEnabled || permissions.has('delete_workspace');

  const getActionMenuOptions = () => [
    {
      id: 'details',
      contents: 'View Details',
      tooltip: handleTooltipMessageForWorkspace(
        'edit',
        canCreateWorkspace,
        inEditMode,
        canDeleteWorkspace,
      ),
      disabled: !canCreateWorkspace,
      type: 'standard',
    },
  ];

  const handleDetailsOnClick = () => {
    handleMenuSelection('workspaces');
    Analytics.logEvent('View Details', 'Workspaces');
  };

  const handleActionMenuItemOnClick = id => {
    if (id === 'details') {
      handleDetailsOnClick();
    }
  };

  const changeWorkspace = value => {
    if (inEditMode) {
      saveModalOpen(MODAL_TYPES.WORKSPACE, 'master', value);
    } else {
      actions.clearNetworkData();
      if (view === 'overview') {
        browserHistory.push(`/${value}`);
      } else {
        browserHistory.push(`/${value}/master/${view}`);
        actions.getContainersList([], value, 'master');
      }
    }
  };

  return (
    <div
      className="branch-scenario-section branch-section workspace-select"
      id="workspace-selector-container"
      data-test="workspace-selector"
    >
      <div className="select-container">
        <div className="select-top-row">
          <p className="select-label">Workspace</p>
        </div>
        <div className="select-control-wrapper">
          <Select
            theme={theme}
            options={workspaces}
            value={workspace}
            onChange={e => changeWorkspace(e.value)}
            clearable={false}
            searchable
            id="workspace-selector"
            disabled={!permissions.has('get_workspaces')}
            onMenuOpen={() => {
              setSelectedType(SelectType.MENU);
            }}
            onMenuClose={() => {
              setSelectedType(SelectType.NONE);
            }}
          />
          <ActionMenu
            dropdownId="workspace"
            dropdownOnClick={id => {
              handleActionMenuItemOnClick(id);
            }}
            menuOptions={getActionMenuOptions()}
            selectedType={selectedType}
            setSelectedType={setSelectedType}
          />
        </div>
      </div>
    </div>
  );
};

WorkspaceSection.propTypes = {
  actions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  saveModalOpen: PropTypes.func.isRequired,
  handleMenuSelection: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
};

export default WorkspaceSection;
