import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from 'components/CustomCheckbox';
import CustomScrollBar from 'components/CustomScrollBar';
import IconButton from 'components/IconButton';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';

import LeftRailExpandableSection from 'components/LeftRailExpandableSection';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { disableMasterWithImportON } from 'helpers/utils';
import './EquipmentLibraryLeftRail.scss';
import EquipmentTypeTile from './EquipmentTypeTile';
import AddEquipmentTile from './AddEquipmentTile';

/**
 * Creates the left rail on the EquipmentLibrary
 */
class EquipmentLibraryLeftRail extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.libraryLoadingState === asyncActionStates.LOADING &&
      nextProps.libraryLoadingState === asyncActionStates.SUCCESS
    ) {
      this.props.actions.updateExpandedCategories(nextProps.selectedEquipment.type, true);
    }
  }

  expandCategoryClick = categoryKey => {
    this.props.actions.updateExpandedCategories(
      categoryKey,
      !this.props.expandedCategories.has(categoryKey),
    );
  };

  openModal = () => {
    this.props.openModal();
  };

  render() {
    const { settings } = this.context;
    const canCreate =
      !this.props.isAuthEnabled || this.props.permissions.has('create_equipment_type');
    const disableDelete = disableMasterWithImportON(
      this.props.match.params.branch,
      settings.automaticImport,
    );
    const canDelete =
      (!this.props.isAuthEnabled || this.props.permissions.has('delete_equipment_type')) &&
      !disableDelete;

    const canEditBranch =
      this.props.match.params.branch !== 'master' ||
      (this.props.match.params.branch === 'master' &&
        this.props.permissions.has('modify_network_as_built'));

    const {
      library,
      selectedEquipment,
      eqInfoCategories,
      actions,
      allSelected,
      someReferenced,
      totalSelected,
      partiallySelected,
      expandedCategories,
    } = this.props;
    const { type, id } = selectedEquipment;

    const tooltipContent = () => {
      if (someReferenced) return 'You can only delete equipment types that are not in use.';

      if (!canEditBranch) return "You don't have permissions to edit the as built branch.";

      return "You don't have delete permissions";
    };

    return (
      <div className="equipment-left-rail">
        <div className="select-all-row">
          <div className="select-all">
            <CustomCheckbox
              id="select-all"
              name="selectAll"
              checked={partiallySelected}
              dash={!allSelected}
              className="equipment-checkbox"
              onClick={() => actions.handleSelectAllClick(library, eqInfoCategories)}
            />
            <div className="select-all-label">All</div>
            <div className="icons">
              <PermissionDisabledTip
                hide={canDelete && canEditBranch && !someReferenced}
                placement="bottom"
                message={tooltipContent()}
                title="Delete"
              >
                <IconButton
                  id="delete-button"
                  icon="delete"
                  disabled={!canDelete || !canEditBranch || someReferenced || totalSelected === 0}
                  onClick={this.openModal}
                />
              </PermissionDisabledTip>
            </div>
          </div>
        </div>
        <div className="library-type-selection">
          <CustomScrollBar>
            {[...eqInfoCategories.keys()].map(cat => {
              const { selected, showAdd, key, label } = eqInfoCategories.get(cat);

              return (
                <LeftRailExpandableSection
                  title={`${label} (${library[key].length})`}
                  id={key}
                  key={key}
                  addCheckbox
                  selectCategory={selected.size > 0}
                  categoryDash={selected.size > 0 && selected.size < library[key].length}
                  handleSelectCategory={() =>
                    actions.handleSelectCategoryClick(key, library[key], eqInfoCategories)
                  }
                  handleExpandCategory={() => this.expandCategoryClick(key)}
                  emptyList={!library[key].length && !showAdd}
                  open={expandedCategories.has(key)}
                >
                  {showAdd && canCreate && canEditBranch && (
                    <AddEquipmentTile
                      equipmentType={key}
                      key={`add_${key}`}
                      addEquipment={() => this.props.selectEquipment(key, 'add')}
                      selected={key === type && id === 'add'}
                      disableCreate={disableDelete}
                    />
                  )}
                  {library[key] &&
                    library[key].map(obj => (
                      <EquipmentTypeTile
                        equipmentInfoItem={obj}
                        key={obj.id}
                        selected={key === type && obj.id === id}
                        bulkSelected={selected.has(obj.id)}
                        selectEquipment={() => this.props.selectEquipment(key, obj.id)}
                        addCheckbox
                        handleSelectEquipment={() =>
                          actions.handleSelectEquipmentClick(key, obj, eqInfoCategories)
                        }
                      />
                    ))}
                </LeftRailExpandableSection>
              );
            })}
          </CustomScrollBar>
        </div>
      </div>
    );
  }
}
EquipmentLibraryLeftRail.contextType = WorkspaceSettingsContext;

EquipmentLibraryLeftRail.defaultProps = {
  partiallySelected: false,
  allSelected: false,
  someReferenced: false,
  totalSelected: 0,
};

EquipmentLibraryLeftRail.propTypes = {
  library: PropTypes.shape({
    batteries: PropTypes.array,
    cables: PropTypes.array,
    conductors: PropTypes.array,
    capacitors: PropTypes.array,
    wireGeometries: PropTypes.array,
    reactors: PropTypes.array,
    switches: PropTypes.array,
    transformers: PropTypes.array,
    inverters: PropTypes.array,
    photovoltaics: PropTypes.array,
    winds: PropTypes.array,
    objects: PropTypes.object,
  }).isRequired,
  selectedEquipment: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    handleSelectAllClick: PropTypes.func,
    handleSelectCategoryClick: PropTypes.func,
    handleSelectEquipmentClick: PropTypes.func,
    updateExpandedCategories: PropTypes.func,
  }).isRequired,
  eqInfoCategories: PropTypes.object.isRequired,
  partiallySelected: PropTypes.bool,
  allSelected: PropTypes.bool,
  libraryLoadingState: PropTypes.number.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  someReferenced: PropTypes.bool,
  totalSelected: PropTypes.number,
  openModal: PropTypes.func.isRequired,
  expandedCategories: PropTypes.instanceOf(Set).isRequired,
  selectEquipment: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default EquipmentLibraryLeftRail;
