import React from 'react';

// Unit shorthand letters should always be lower-case exept for:
//    *  letters that stand for a name (e.g. Ampere -> A, Volta -> V)
//    *  big multipliers  (e.g. Mega -> M)

// current
export const amps = 'A';
export const kA = 'kA';

// power
export const VA = 'VA';
export const VAr = 'VAr';
export const watts = 'W';
export const W = 'W';
export const Wh = 'Wh';

export const kVA = 'kVA';
export const kVAr = 'kVAr';
export const kVArh = 'kVArh';
export const kW = 'kW';
export const kWh = 'kWh';
export const kWDC = 'kW DC';

export const MW = 'MW';
export const MVAr = 'MVAr';

// voltage
export const kV = 'kV';
export const volts = 'V';
export const kVll = (
  <span>
    kV
    <sub>LL</sub>
  </span>
);
export const kVln = (
  <span>
    kV
    <sub>LN</sub>
  </span>
);
export const pu = <span>p.u.</span>;

// physical
export const siemens = 'S';

export const ohms = 'Ω';
export const ohmsPerMeter = 'Ω/m';
export const ohmsPerKiloMeter = 'Ω/km';
export const ohmsPerMile = 'Ω/mile';

export const degrees = '°';

// Percent
export const percent = '%';

// text
export const perMeter = 'per meter';

// CO2
export const gram = 'g';
export const gramPerkWh = 'g/kWh';

export const none = '';

export function scaleFactorForUnit(unit) {
  switch (unit.toString()) {
    case none:
    case amps:
    case VA:
    case VAr:
    case watts:
    case W:
    case Wh:
    case volts:
    case kVll:
    case kVln:
    case pu:
    case siemens:
    case ohms:
    case ohmsPerMeter:
    case perMeter:
      return 1;
    case percent:
      return 1 / 100;
    case kA:
    case kVA:
    case kVAr:
    case kW:
    case kWh:
    case kVArh:
    case kV:
      return 1000;
    case gramPerkWh:
      return 1 / 1000;
    case MW:
    case MVAr:
      return 1000000;
    default:
      throw new Error('Unknown unit');
  }
}
