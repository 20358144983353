import { useEffect, useRef } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useInterval(func, interval) {
  const currentFunc = useRef();

  // Only update the current interval function if the callback changes
  useEffect(() => {
    currentFunc.current = func;
  }, [func]);

  // Set up the interval.
  useEffect(() => {
    if (interval) {
      const id = setInterval(() => currentFunc.current(), interval);

      // Clear interval on unmount
      return () => clearInterval(id);
    }
    return () => null;
  }, [interval]);
}
