import AssetModel from './AssetModel';

class GenericAssetPriceModel extends AssetModel {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = this.instance.class;
  }

  get EmissionRate() {
    const EmissionRate = this.references['GenericAssetModelOrMaterial.EmissionRate'];
    return this.cimDict[EmissionRate];
  }

  get AssetFailureInfo() {
    const AssetFailureInfo = this.references['GenericAssetModelOrMaterial.AssetFailureInfo'];
    return this.cimDict[AssetFailureInfo];
  }

  get estimatedUnitCost() {
    return this.attributes?.['GenericAssetModelOrMaterial.estimatedUnitCost'] ?? 0;
  }

  get estimatedUnitDisposalCost() {
    return this.attributes?.['GenericAssetPriceModel.estimatedUnitDisposalCost'] ?? 0;
  }

  get estimatedUnitInstallationCost() {
    return this.attributes?.['GenericAssetPriceModel.estimatedUnitInstallationCost'] ?? 0;
  }

  get estimatedUnitRemovalCost() {
    return this.attributes?.['GenericAssetPriceModel.estimatedUnitRemovalCost'] ?? 0;
  }

  get estimatedUnitUpgradeCost() {
    return this.attributes?.['GenericAssetPriceModel.estimatedUnitUpgradeCost'] ?? 0;
  }

  get estimatedUnitInstallationDays() {
    return this.attributes?.['GenericAssetPriceModel.estimatedUnitInstallationDays'] ?? '';
  }

  get communityGenerationCredit() {
    return this.attributes?.['GenericAssetPriceModel.communityGenerationCredit'] ?? 0;
  }

  get fixedMaintenanceCost() {
    return this.attributes?.['GenericAssetPriceModel.fixedMaintenanceCost'] ?? 0;
  }

  get perUnitMaintenanceCost() {
    return this.attributes?.['GenericAssetPriceModel.perUnitMaintenanceCost'] ?? 0;
  }
}

export default GenericAssetPriceModel;
