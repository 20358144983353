import Inverter from './Inverter';

class InverterPV extends Inverter {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'InverterPV';
    this.icon = 'photovoltaic';
    this.displayName = 'Photovoltaic';
    this.inverterId = id;
  }

  get totalP() {
    return this.initialP;
  }

  static validationSchema = {
    ...Inverter.validationSchema,
    initialP: {
      required: true,
      customValidation: (value, { pvUnitMinP }) => {
        const GTEPMin = pvUnitMinP <= value;
        const LTEZ = value <= 0;
        const valid = GTEPMin && LTEZ;
        const message = `Value must be in the range [${pvUnitMinP / 1000}, 0]`;

        return {
          valid,
          message: !valid ? message : null,
        };
      },
    },
    initialQ: {
      required: true,
      customValidation: (value, { minQ, maxQ }) => {
        const GTEQMin = minQ <= value;
        const LTEQMax = value <= maxQ;
        const valid = GTEQMin && LTEQMax;
        const message = `Value must be in the range [${minQ / 1000}, ${maxQ / 1000}]`;

        return {
          valid,
          message: !valid ? message : null,
        };
      },
    },
  };
}

export default InverterPV;
