import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from 'components/ExpandableSection';
import { getMultiplier } from 'helpers/utils';
import {
  createRangeBuckets,
  createLabels,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import RangeSelector from './RangeSelector';
import RangeSlider from './RangeSlider';
import LayerSelectionButton from './LayerSelectionButton';

const getBreaks = (options, unit) => {
  const { rangeBreaks, colors } = options;
  const multipliers = rangeBreaks.map(val => getMultiplier(val));
  const adjustedRange = rangeBreaks.map(
    (val, index) => `${(val / multipliers[index].value).toFixed(2)} ${multipliers[index].letter}`,
  );
  return createRangeBuckets(colors, createLabels(adjustedRange, unit));
};

const BatteryAnalysisSection = ({
  layerOptions,
  updateLayerOptions,
  selected,
  setSelectedLayer,
  expanded,
  layerID,
  layerName,
  unit,
}) => {
  const [editId, setEditId] = useState('');
  const [powerGradient, setPowerGradient] = useState(getBreaks(layerOptions, unit));

  useEffect(() => {
    setPowerGradient(getBreaks(layerOptions, unit));
  }, [layerOptions.colors, layerOptions.rangeBreaks, layerOptions, unit]);

  const handleLayerUpdate = updates => {
    updateLayerOptions(updates);
  };

  const unCheckRange = e => {
    const index = parseInt(e.target.value, 10);

    const newSelection = [...layerOptions.selected];
    newSelection[index] = !newSelection[index];
    handleLayerUpdate({ selected: newSelection });
  };

  const toggleEditPanel = id => {
    setEditId(id);
  };

  const button = () => (
    <LayerSelectionButton
      selected={selected}
      option={{ value: layerID, label: layerName }}
      group="results"
      setSelectedLayer={setSelectedLayer}
    />
  );

  return (
    <ExpandableSection
      className={classNames({
        'layer-expandable-section': true,
        'selected-layer': selected,
        'layer-expandable-section--expanded': expanded,
      })}
      renderHeaderContent={button}
      showToggle={false}
      open={selected}
    >
      <div className="layer-section">
        <RangeSlider
          domain={[0, layerOptions.rangeBreaks[0] * 1.1]}
          values={layerOptions.rangeBreaks.slice().reverse()}
          colors={layerOptions.colors.slice().reverse()}
          format={d => `${d / 1000} k${unit}`}
          onUpdate={e => {
            handleLayerUpdate({ rangeBreaks: e.reverse() });
          }}
        />
        <div className="layer-checkbox-group">
          {powerGradient.length &&
            powerGradient.map(checkbox => (
              <RangeSelector
                key={checkbox.id}
                settings={checkbox}
                selected={layerOptions.selected[parseInt(checkbox.id, 10)]}
                onClick={unCheckRange}
                id={parseInt(checkbox.id, 10)}
                withEdit
                toggleEdit={toggleEditPanel}
                showEditPanel={checkbox.id === editId}
                layerOptions={layerOptions}
                handleUpdate={handleLayerUpdate}
                editDisabled={!!editId}
                rangeMax={1000000000000}
                rangeMin={0}
                useMultiplier
                editColor
                editRange
                unit={unit}
                ascending
              />
            ))}
        </div>
      </div>
    </ExpandableSection>
  );
};

BatteryAnalysisSection.propTypes = {
  layerOptions: PropTypes.shape({
    selected: PropTypes.array,
    colors: PropTypes.array,
    rangeBreaks: PropTypes.array,
  }).isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  layerID: PropTypes.string.isRequired,
  layerName: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
};

export default BatteryAnalysisSection;
