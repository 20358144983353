import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { kVA, kVAr, kW } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import { alphabetizeByKey } from 'helpers/utils';

import {
  calculateSum,
  transposePhaseAttributes,
  diffPerPhaseAttributes,
} from '../../helpers/valueExtractors';
import AssetSingleSelect from '../../Inputs/AssetSingleSelect';
import EditablePhaseValues from '../../Inputs/EditablePhaseValues';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';
import PerPhaseContainer from '../partials/PerPhaseContainer';
import PerPhaseRow from '../partials/PerPhaseRow';

// Editable & summed per phase values to be rendered
const pFixed = {
  label: 'Real Power',
  key: 'pfixed',
  unit: kW,
  divisor: 1000,
  type: 'number',
};
const qFixed = {
  label: 'Reactive Power',
  key: 'qfixed',
  unit: kVAr,
  divisor: 1000,
  type: 'number',
};
const connectedCapacity = {
  label: 'Connected Capacity',
  id: 'connectedCapacity',
  unit: kVA,
  divisor: 1000,
  type: 'number',
};

const getUsagePointTotals = usagePoints => {
  if (!usagePoints) {
    return {};
  }

  const totals = usagePoints.reduce(
    (acc, point) => {
      if (point.phaseCode.length > 1) {
        point.phaseCode.split('').forEach(phase => {
          acc[phase] += point.ratedPower / point.phaseCode.length;
        });
      } else {
        acc[point.phaseCode] += point.ratedPower;
      }
      return acc;
    },
    { A: 0, B: 0, C: 0 },
  );
  return totals;
};

const InstanceInfo = ({
  workspace,
  displayBranch,
  handleSave,
  asset,
  assetId,
  disabled,
  inEditMode,
  theme,
}) => {
  const [customerCount, setCustomerCount, customerCountEdited] = useTrackingState(
    asset.attributes.customerCount,
  );
  const [phaseConnection, setPhaseConnection, phaseConnectionEdited] = useTrackingState(
    asset.attributes.phaseConnection,
  );
  const [loadResponse, setLoadResponse, loadResponseEdited] = useTrackingState(asset.load_response);
  const [perPhaseP, setPerPhaseP] = useState({});
  const [perPhaseQ, setPerPhaseQ] = useState({});

  useEffect(() => {
    const phaseAttrs = transposePhaseAttributes(asset.phase_attributes);
    setPerPhaseP(phaseAttrs.pfixed);
    setPerPhaseQ(phaseAttrs.qfixed);
  }, [asset]);

  const chargerOptions = asset.load_responses ?? [];
  const mappedLoadResponseOptions = [
    {
      value: loadResponse?.id,
      label: loadResponse?.name ?? 'Select a Customer Type',
      disabled: true,
    },
    ...alphabetizeByKey(
      chargerOptions
        .filter(load => load.id !== loadResponse?.id)
        .map(load => ({ value: load.id, label: load.name || load.id })),
      'label',
    ),
  ];

  return (
    <>
      <EquipmentInfoSelector
        eqLibURL={`/${workspace}/${displayBranch}/library/customerTypes`}
        displayName="Customer Types"
        assetId={assetId}
        value={loadResponse?.id}
        loadOptions={() => mappedLoadResponseOptions}
        defaultOptions={mappedLoadResponseOptions}
        id="generator-info-select"
        onChange={({ value: id, label: name }) => {
          setLoadResponse({ id, name });
          handleSave({ load_response: id });
        }}
        disabled={disabled || !inEditMode}
        edited={loadResponseEdited}
        feeder={asset.container.id}
      />

      <hr className="section-divider" />

      <AssetSingleSelect
        id="phaseConnection"
        key="phaseConnection"
        label="Phase Connection"
        options={[
          { value: 'D', label: 'D' },
          { value: 'Y', label: 'Y' },
          { value: 'Yn', label: 'Yn' },
        ]}
        value={phaseConnection}
        onChange={value => {
          setPhaseConnection(value);
          handleSave({ attributes: { phaseConnection: value } });
        }}
        edited={phaseConnectionEdited}
        disabled={disabled || !inEditMode}
      />
      <SingleEditableValue
        theme={theme}
        disabled={disabled || !inEditMode}
        min={0}
        precision={1}
        type="number"
        id="customerCount"
        label="Customer Count"
        onBlur={() => handleSave({ attributes: { customerCount } })}
        onChange={e => setCustomerCount(e)}
        value={customerCount}
        edited={customerCountEdited}
      />
      <PerPhaseContainer showTotal phaseToPhase={phaseConnection === 'D'}>
        <EditablePhaseValues
          getTotal={(values, divisor) =>
            Object.values(values).reduce((prev, curr) => prev + curr / divisor, 0) || 0
          }
          id="pfixed"
          type="number"
          inputStyle="panel"
          theme={theme}
          disabled={disabled || !inEditMode}
          phase={asset.phases}
          {...pFixed}
          pfixed={perPhaseP}
          edited={{
            pfixed: diffPerPhaseAttributes(
              perPhaseP,
              transposePhaseAttributes(asset.phase_attributes).pfixed,
            ),
          }}
          onChange={e =>
            setPerPhaseP({
              ...perPhaseP,
              [e.phase]: e.value,
            })
          }
          onBlur={async () => {
            const phase_attributes = transposePhaseAttributes({ pfixed: perPhaseP });
            await handleSave({ phase_attributes });
          }}
          precision={3}
        />
        <EditablePhaseValues
          getTotal={(values, divisor) =>
            Object.values(values).reduce((prev, curr) => prev + curr / divisor, 0) || 0
          }
          id="qfixed"
          type="number"
          inputStyle="panel"
          theme={theme}
          disabled={disabled || !inEditMode}
          phase={asset.phases}
          {...qFixed}
          qfixed={perPhaseQ}
          edited={{
            qfixed: diffPerPhaseAttributes(
              perPhaseQ,
              transposePhaseAttributes(asset.phase_attributes).qfixed,
            ),
          }}
          onChange={e =>
            setPerPhaseQ({
              ...perPhaseQ,
              [e.phase]: e.value,
            })
          }
          onBlur={async () => {
            const phase_attributes = transposePhaseAttributes({ qfixed: perPhaseQ });
            await handleSave({ phase_attributes });
          }}
          precision={3}
        />
        <PerPhaseRow
          getTotal={calculateSum}
          {...connectedCapacity}
          phases={asset.phases}
          values={getUsagePointTotals(asset.usage_points)}
        />
      </PerPhaseContainer>
    </>
  );
};

InstanceInfo.defaultProps = {
  disabled: true,
  theme: 'light',
};

InstanceInfo.propTypes = {
  disabled: PropTypes.bool,
  inEditMode: PropTypes.bool.isRequired,
  assetId: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired, // JSCIM Object
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string,
};

export default InstanceInfo;
