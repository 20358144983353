import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

import './ThreePanelLayout.scss';

type ThreePanelLayoutProps = {
  className?: string;
  renderFooterContent?: () => ReactNode;
  renderHeaderContent?: () => ReactNode;
  renderLeftContent?: () => ReactNode;
  renderMainContent?: () => ReactNode;
  renderModals?: () => ReactNode;
  renderRightContent?: () => ReactNode;
  rightPanelExpanded?: boolean;
  rightPanelActive?: boolean;
  showFooter?: boolean;
  showHeader?: boolean;
  leftPanelExpanded: boolean;
};

const voidFunction = () => null;

const ThreePanelLayout: FunctionComponent<ThreePanelLayoutProps> = ({
  className = '',
  renderFooterContent = voidFunction,
  renderHeaderContent = voidFunction,
  renderLeftContent = voidFunction,
  renderMainContent = voidFunction,
  renderModals = voidFunction,
  renderRightContent = voidFunction,
  rightPanelExpanded = false,
  rightPanelActive = true,
  showFooter = true,
  showHeader = true,
  leftPanelExpanded,
}) => (
  <div className={`three-panel-layout ${className}`}>
    {showHeader && <div className="three-panel-layout-header">{renderHeaderContent()}</div>}
    <div
      className={`three-panel-layout-contents ${classNames({
        'left-panel-expanded': leftPanelExpanded,
      })}`}
    >
      <div className="three-panel-layout-left">{renderLeftContent()}</div>
      <div className="main-container">
        {renderMainContent()}
        <div
          className={classNames({
            'three-panel-layout-right': true,
            'three-panel-layout-right--expanded': rightPanelExpanded && rightPanelActive,
            'three-panel-layout-right--open': !rightPanelExpanded && rightPanelActive,
          })}
        >
          {renderRightContent()}
        </div>
      </div>
    </div>
    {showFooter && <div className="three-panel-layout-footer">{renderFooterContent()}</div>}
    {renderModals()}
  </div>
);

export default ThreePanelLayout;
