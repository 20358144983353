import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withAuth from 'helpers/withAuth';
import * as appStateReducer from 'store/appState';
import { actions } from '../routes/Network/modules/network';
import { loadForecastActions } from '../routes/Network/modules/loadForecast';
import { networkEditActions } from '../routes/Network/modules/networkEdit';
import { feederActions } from '../routes/Network/modules/feeders';
/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import NetworkTopNav from '../NetworkTopNav';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...actions,
      ...appStateReducer.actions,
      ...loadForecastActions,
      ...networkEditActions,
      ...feederActions,
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  theme: state.global.theme,
  view: state.global.view,
  location: state.location,
  workspace: state.network.workspace,
  branch: state.network.branch,
  displayBranch: state.network.displayBranch,
  branches: state.network.branches,
  newBranchReq: state.network.newBranchReq,
  panelOptions: state.network.panelOptions,
  addAssetRequest: state.network.addAssetRequest,
  editAssetRequest: state.network.applyDifferenceModelRequest.editLinePosition || 0,
  editBranchReq: state.edit.editBranchReq,
  saveEditBranchReq: state.edit.saveEditBranchReq,
  canMergeBranch: state.edit.canMergeBranch,
  inEditMode: state.edit.inEditMode,
  selectedScenario: state.loadForecast.selectedScenario,
  scenarios: state.loadForecast.scenarios,
  selectedAnalysis: state.loadForecast.selectedAnalysis,
  selectedProject: state.edit.selectedProject,
  saveModalActive: state.edit.saveModalActive,
  saveModalType: state.edit.saveModalType,
  newView: state.edit.newView,
  newLink: state.edit.newLink,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(NetworkTopNav));
