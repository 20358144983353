import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import asyncStates from 'helpers/asyncActionStates';
import ThemeContext from 'helpers/ThemeContext';

import LifecycleSection from '../partials/LifecycleSection';
import ImpedanceGrid from './ImpedanceGrid';
import valueCheck from '../../helpers/valueCheck';
import ACLineSegmentHeader from './ACLineSegmentHeader';
import InstanceInfo from './InstanceInfo';
import SeasonalRatingsViewer from '../partials/SeasonalRatingsViewer';

// Create a template for ACLineSegment instances.
const ACLineSegment = ({
  asset: line,
  selected: { id },
  displayBranch,
  branch,
  workspace,
  canEditNetwork,
  inEditMode,
  applyDifferenceModelRequest,
  loadForecast: { selectedScenario },
  toggleFeederPanel,
  setSelectedAssetID,
  editActions,
  expanded,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'line', id, body);

  const allPhasesHaveInfo = Object.values(line.phase_wire_info).every(c => c);

  return (
    <>
      <div
        className={classNames({
          'asset-panel-values': true,
          'asset-panel-values--expanded': expanded,
        })}
      >
        <ACLineSegmentHeader
          asset={{ ...line, phases: line.phase }}
          disabled={!canEditNetwork || saving}
          toggleFeederPanel={toggleFeederPanel}
          handleSave={handleSave}
          inEditMode={inEditMode}
          setSelectedAssetID={setSelectedAssetID}
          iconName="add-line"
          theme={theme}
        />

        <InstanceInfo
          key={id}
          asset={line}
          branch={branch}
          displayBranch={displayBranch}
          workspace={workspace}
          inEditMode={inEditMode}
          disabled={!canEditNetwork || !inEditMode || saving}
          setSelectedAssetID={setSelectedAssetID}
          recalculateLength={() => editActions.recalculateLineLength(workspace, branch, id)}
          handleSave={handleSave}
          theme={theme}
        />
        <SeasonalRatingsViewer
          workspace={workspace}
          branch={branch}
          assetId={id}
          scenarioId={selectedScenario}
        />

        {valueCheck.hasKey('impedances', line) && (
          <ImpedanceGrid
            phaseInfosValid={allPhasesHaveInfo}
            impedances={line.impedances}
            disabled={!canEditNetwork || !inEditMode || saving}
            inEditMode={inEditMode}
            handleSave={handleSave}
          />
        )}
        <LifecycleSection
          disabled={saving || !inEditMode || !canEditNetwork}
          lifecycle={line.lifecycle || {}}
          onChange={lifecycle => handleSave({ lifecycle })}
          inServiceOnly
          healthMetric={line.reliability_info?.healthMetric}
          onHealthMetricChange={handleSave}
        />
      </div>
    </>
  );
};

ACLineSegment.propTypes = {
  asset: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  editActions: PropTypes.object.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    validationSchema: PropTypes.object,
  }).isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
  }).isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default ACLineSegment;
