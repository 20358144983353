import React, { FunctionComponent } from 'react';
import ActivityLogContextProvider from 'contexts/ActivityLogContext';
import ScenarioGenerationContextProvider from '../context/ScenarioGenerationContext';
import ScenarioGenerationPanels from './ScenarioGenerationPanels';

const ScenarioGeneration: FunctionComponent = (props: any) => (
  <ActivityLogContextProvider
    workspace={props.match?.params?.workspace}
    branch={props.match?.params?.branch}
  >
    <ScenarioGenerationContextProvider>
      <ScenarioGenerationPanels {...props} />
    </ScenarioGenerationContextProvider>
  </ActivityLogContextProvider>
);
export default ScenarioGeneration;
