/* eslint jsx-a11y/no-static-element-interactions: off */
import useTrackingState from 'hooks/useTrackingState';
import React, { FunctionComponent, ReactNode, useCallback, useEffect } from 'react';
import { ThemeProp } from 'types';
import StandardMenuItem from './StandardMenuItem';

/**
 * Component that creates a generic dropdown menu.
 * Must be passed an element to act as a trigger (this.props.children)
 * @class DropdownMenu
 */

type DropdownMenuProps = {
  menuOptions?: { contents: string; id: string; type: string; disabled: boolean }[];
  dropDownIcon?: boolean;
  selected?: [];
  itemHeight?: number;
  /* Callback for click on menu item */
  onClick?: (id: string | number) => void;
  /* Callback for click on menu icon */
  onToggle?: (value: boolean, id?: any | null) => void;
  open?: boolean;
  /* Color theme class name */
  theme?: ThemeProp;
  id: string | null;
  /* The offset from the positiong of the toggle icon. Used to position menu items */
  contentPosition?: {
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
    zIndex?: number;
  };
  /* If the menu is disabled, used to prevent click events */
  children: (value: any) => void | null | ReactNode;
  className?: string;
};

const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
  menuOptions = [],
  itemHeight = 35,
  selected = [],
  onToggle = null,
  open = false,
  theme = 'light',
  contentPosition = {},
  onClick = null,
  dropDownIcon = true,
  id = null,
  children = null,
}) => {
  const [isOpen, setOpen] = useTrackingState(open);

  const optionTypes = {
    standard: 'standard',
    seperator: 'seperator',
  };

  useEffect(() => {
    setOpen(open);
  }, [open, setOpen]);

  const closeMenu = useCallback(() => {
    setOpen(false);
    document.removeEventListener('click', closeMenu);

    if (onToggle) {
      onToggle(false, id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showMenu = useCallback(() => {
    if (menuOptions.length) {
      setOpen(true);
      document.addEventListener('click', closeMenu);

      if (onToggle) {
        onToggle(true, id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the correct icon type from the dropdown item instance.
  const getIcon = (isSelected: boolean, icons: any) =>
    isSelected ? icons.highlighted : icons.basic;
  /**
   * Take an array of menu item definitions and turns them into JSX representations.
   * @return {Array} List of JSX representing the menu items
   */

  const createMenuItems = () => {
    const options = menuOptions.map(c => {
      switch (c.type) {
        case optionTypes.standard:
          return createStandard(c);
        case optionTypes.seperator:
          return <div className="seperator" key={c.id} />;
        default:
          return null;
      }
    });
    return options;
  };

  /**
   * Builds a standard menu item. Menu item acts as a button and triggers
   * the click callback.
   * Item can have an icon, a set theme, a specific height and a set callback for clicks.
   * @param  {Object}  option  Menu item being constructed.
   */

  const createStandard = (option: any) => {
    const isSelected = selected.some((s: string) => s === option.id);
    const icon = option.icon ? getIcon(isSelected, option.icon) : null;
    const itemProps = {
      selected: isSelected,
      icon,
      toggle: onClick,
      disabled: option.disabled,
      disabledMessage: option.disabledMessage,
      tooltip: option.tooltip,
    };
    return (
      <StandardMenuItem
        active={open}
        key={option.id}
        data={option}
        height={itemHeight}
        theme={theme}
        {...itemProps}
      />
    );
  };

  // Remove no height items from height calculation
  const withContent = menuOptions.filter(
    (o: { contents: string; id: string; type: string; disabled: boolean }) =>
      o.type !== optionTypes.seperator,
  );
  const noContent = menuOptions.filter(
    (o: { contents: string; id: string; type: string; disabled: boolean }) =>
      o.type === optionTypes.seperator,
  );
  const height = withContent.length * itemHeight + noContent.length * 4;
  return (
    <div
      className={`dropdown ${theme} ${dropDownIcon ? 'dropdown-select' : ''} ${
        isOpen ? 'dropdown-open' : ''
      }`}
    >
      {children?.(showMenu)}
      {isOpen ? (
        <div style={{ ...contentPosition, height }} className={`dropdown-content ${theme}`}>
          <div>{createMenuItems()}</div>
        </div>
      ) : null}
    </div>
  );
};

export default DropdownMenu;
