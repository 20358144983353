import { useRequest } from '@opusonesolutions/gridos-app-framework';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import { isDefinedNonEmpty } from 'helpers/utils';
import moment from 'moment';
import React, { FunctionComponent, useContext, useState } from 'react';
import { CustoTypeSettings, optionType, SwitchContingencySettings, unitInfos } from 'types/edit';
import { ThemeProp } from 'types/index';
import { ScenarioGenerationContext } from '../context/ScenarioGenerationContext';
import {
  assetProperties,
  assetTypes,
  assetTypesStataus,
  defaultSwitchContSettings,
  modalTypes,
} from '../helpers/ScenarioGenerationHelpers';
import ScheduleBulkEVSettings from './ScheduleBulkEVSettings';
import SwitchContingenySettings from './SwitchContingenySettings';

type BulkScheduleModalProps = {
  workspace: string;
  branch: string;
  theme: ThemeProp;
  selectedContainer?: string | null;
  bulkScheduleGenLoading: assetTypesStataus;
  setBulkScheduleGenLoading: (value: assetTypesStataus) => void;
  selectedAssetType: assetTypes;
  assetList?: unitInfos;
};

const BulkScheduleModal: FunctionComponent<BulkScheduleModalProps> = ({
  theme,
  workspace,
  branch,
  selectedContainer,
  bulkScheduleGenLoading,
  setBulkScheduleGenLoading,
  selectedAssetType,
  assetList = [],
}) => {
  const [settingsByCustoType, setSettingsByCustoType] = useState<CustoTypeSettings>({});
  const [overwriteExistingEVSchedule, setOverwriteExistingEVSchedule] = useState(true);
  const {
    modalActiveType,
    resetModalType,
    scenarioRange,
    scenarios,
    selectedScenario: scenario,
    setGeneratedContingencyId,
  } = useContext(ScenarioGenerationContext);
  const [contingencySettings, setContingencySettings] = useState<SwitchContingencySettings>(
    defaultSwitchContSettings,
  );
  const [selectedSwitches, setSelectedSwitches] = useState<optionType[]>([]);

  const { makeRequest: bulkScheduleRequest } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/asset_schedules/generate_schedule`,
  );

  const generateBulkAssetSchedule = async () => {
    const evParams =
      selectedAssetType === assetTypes.ev && modalActiveType === modalTypes.bulkSchedule
        ? {
            info_by_load_type: Object.entries(settingsByCustoType).map(([key, evSettings]) => ({
              load_type: key,
              ...evSettings,
            })),
          }
        : {};
    try {
      resetModalType();
      await bulkScheduleRequest({
        method: 'post',
        body: {
          scenario_id: scenario?.value,
          ...evParams,
        },
        params: {
          feeder: selectedContainer,
          scenario_id: scenario?.value,
          asset_type: assetProperties[selectedAssetType].class,
          schedule_type:
            (modalActiveType === modalTypes.pqSchedule && selectedAssetType === assetTypes.ev) ||
            (modalActiveType === modalTypes.bulkSchedule && selectedAssetType === assetTypes.pv) ||
            (modalActiveType === modalTypes.bulkSchedule && selectedAssetType === assetTypes.switch)
              ? 'Normal'
              : 'Global',
          shift_tz: selectedAssetType !== assetTypes.ev,
          ...(selectedAssetType === assetTypes.ev && modalActiveType === modalTypes.bulkSchedule
            ? { overwrite_existing_schedule: overwriteExistingEVSchedule }
            : {}),
        },
        toast: {
          error: 'Could not create bulk schedule.',
          settings: {
            autoDismiss: true,
          },
        },
        onError: () => {
          setBulkScheduleGenLoading({ ...bulkScheduleGenLoading, [selectedAssetType]: false });
        },
      });
    } catch (error) {}
  };

  const { makeRequest: contingencyRequest } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${scenario?.value}/contingency_scenario`,
  );

  const generateContingencySchedule = async () => {
    try {
      resetModalType();
      await contingencyRequest({
        method: 'post',
        body: {
          ...contingencySettings,
          switches: selectedSwitches.map(sw => sw.value),
          feeder: [selectedContainer],
        },
        toast: {
          error: 'Could not create contingency schedule.',
          settings: {
            autoDismiss: true,
          },
        },
        onError: () => {
          setBulkScheduleGenLoading({ ...bulkScheduleGenLoading, [selectedAssetType]: false });
        },
        onSuccess: data => {
          setGeneratedContingencyId(data?.scenario_id);
        },
      });
    } catch (error) {}
  };
  const disabledEV =
    selectedAssetType === assetTypes.ev &&
    modalActiveType === modalTypes.bulkSchedule &&
    (Object.keys(settingsByCustoType).length === 0 ||
      Object.values(settingsByCustoType).some(type => {
        const windowTimeDiff = moment.duration(
          moment(type.end_time, 'HH:mm').diff(moment(type.start_time, 'HH:mm')),
        );
        return (
          !type.start_time ||
          !type.end_time ||
          type.start_time > type.end_time ||
          !isDefinedNonEmpty(type.min_events) ||
          type.min_events < 0 ||
          type.min_events > type.max_events ||
          !type.max_events ||
          type.max_events > 100 ||
          !type.min_charge_length ||
          !type.max_charge_length ||
          type.min_charge_length > type.max_charge_length ||
          moment.duration(type.max_charge_length).asMinutes() > windowTimeDiff.asMinutes()
        );
      }));
  const disableSwitch =
    selectedAssetType === assetTypes.switch &&
    (Object.entries(contingencySettings)
      .filter(([key]) => key !== 'notes')
      .some(([, con]) => !con) ||
      moment(contingencySettings.scenario_end).isAfter(scenarioRange.end) ||
      moment(contingencySettings.scenario_start).isBefore(scenarioRange.start) ||
      moment(contingencySettings.contingency_start).isBefore(
        moment(contingencySettings.scenario_start),
      ) ||
      moment(contingencySettings.contingency_end).isAfter(
        moment(contingencySettings.scenario_end),
      ) ||
      scenarios.some(({ label }) => label === contingencySettings.name));
  let disabledBtnTooltip = '';

  if (disabledEV) disabledBtnTooltip = 'Some EV fields might have invalid value';
  if (disableSwitch)
    disabledBtnTooltip =
      'Invalid switch fields / contingency range should be within timeseries range';

  return (
    <Modal
      active={
        modalActiveType === modalTypes.bulkSchedule || modalActiveType === modalTypes.pqSchedule
      }
      onConfirm={() => {
        setBulkScheduleGenLoading({ ...bulkScheduleGenLoading, [selectedAssetType]: true });
        if (selectedAssetType === assetTypes.switch) generateContingencySchedule();
        else if (modalActiveType === modalTypes.pqSchedule) generateBulkAssetSchedule();
        else generateBulkAssetSchedule();
      }}
      onCancel={() => resetModalType()}
      title={
        selectedAssetType === assetTypes.switch
          ? 'Generate switch contingency schedule'
          : `Bulk Generate Schedules - ${assetProperties[selectedAssetType].label}`
      }
      disableConfirm={
        (selectedAssetType === assetTypes.ev &&
          modalActiveType === modalTypes.bulkSchedule &&
          (Object.keys(settingsByCustoType).length === 0 ||
            Object.values(settingsByCustoType).some(type => {
              const windowTimeDiff = moment.duration(
                moment(type.end_time, 'HH:mm').diff(moment(type.start_time, 'HH:mm')),
              );
              const windowTimeDiffTaken =
                windowTimeDiff.asMinutes() === 59 ? 60 : windowTimeDiff.asMinutes();
              return (
                !type.start_time ||
                !type.end_time ||
                type.start_time > type.end_time ||
                !isDefinedNonEmpty(type.min_events) ||
                type.min_events < 0 ||
                type.min_events > type.max_events ||
                !type.max_events ||
                type.max_events > 100 ||
                !type.min_charge_length ||
                !type.max_charge_length ||
                type.min_charge_length > type.max_charge_length ||
                moment.duration(type.max_charge_length).asMinutes() > windowTimeDiffTaken
              );
            }))) ||
        (selectedAssetType === assetTypes.switch &&
          (Object.entries(contingencySettings)
            .filter(([key]) => key !== 'notes')
            .some(([, con]) => !con) ||
            moment(contingencySettings.scenario_end).isAfter(scenarioRange.end) ||
            moment(contingencySettings.scenario_start).isBefore(scenarioRange.start) ||
            moment(contingencySettings.contingency_start).isBefore(
              moment(contingencySettings.scenario_start),
            ) ||
            moment(contingencySettings.contingency_end).isAfter(
              moment(contingencySettings.scenario_end),
            ) ||
            scenarios.some(({ label }) => label === contingencySettings.name)))
      }
      theme={theme}
      modalType="primary"
      width="800px"
      height={
        selectedAssetType === assetTypes.pv || modalActiveType === modalTypes.pqSchedule
          ? 'auto'
          : '700px'
      }
      labels={{
        confirm: bulkScheduleGenLoading[selectedAssetType] ? (
          <i className="material-icons rotate">refresh</i>
        ) : (
          <Tooltip content={disabledBtnTooltip} theme={theme}>
            <span>Generate now</span>
          </Tooltip>
        ),
      }}
      scrollBody
    >
      <div>
        {((selectedAssetType === assetTypes.pv && modalActiveType === modalTypes.bulkSchedule) ||
          modalActiveType === modalTypes.pqSchedule) && (
          <p className="modal-message__p pv-asset-msg">
            {`${
              modalActiveType === modalTypes.pqSchedule
                ? 'Assets with an existing PQ schedule will be replaced with new schedule'
                : 'PV Assets with existing schedule will be replaced with new schedule.'
            }`}
          </p>
        )}
        {selectedAssetType === assetTypes.ev && modalActiveType === modalTypes.bulkSchedule && (
          <ScheduleBulkEVSettings
            theme={theme}
            settingsByCustoType={settingsByCustoType}
            setSettingsByCustoType={setSettingsByCustoType}
            evAssetList={assetList}
            overwriteExistingEVSchedule={overwriteExistingEVSchedule}
            setOverwriteExistingEVSchedule={setOverwriteExistingEVSchedule}
          />
        )}
        {selectedAssetType === assetTypes.switch && (
          <SwitchContingenySettings
            theme={theme}
            contingencySettings={contingencySettings}
            setContingencySettings={setContingencySettings}
            switchAssetList={assetList}
            selectedSwitches={selectedSwitches}
            setSelectedSwitches={setSelectedSwitches}
            workspace={workspace}
            branch={branch}
            selectedContainer={selectedContainer}
          />
        )}
      </div>
    </Modal>
  );
};

export default BulkScheduleModal;
