// Base Class for all JSCIM objects
class CIMObject {
  constructor(uuid, cimDict) {
    this.id = Array.isArray(uuid) && uuid.length === 1 ? uuid[0] : uuid;
    this.instance = cimDict[uuid];
    this._cimDict = cimDict;
    // Extract attributes/references to ensure the underlying CIM model isn't mutated
    // when the attributes or reference setters are called.
    this._attributes =
      this.instance && this.instance.attributes ? { ...this.instance.attributes } : {};
    this._references =
      this.instance && this.instance.references ? { ...this.instance.references } : {};
  }

  get cimDict() {
    return this._cimDict;
  }

  set cimDict(lookup) {
    this._cimDict = lookup;
  }

  get references() {
    return this._references;
  }

  set references(references) {
    this._references = references;
  }

  get attributes() {
    return this._attributes;
  }

  set attributes(attributes) {
    this._attributes = attributes;
  }

  get class() {
    return this._class;
  }

  get visibilityClass() {
    return this._class;
  }

  /**
   * From the id of an asset, extract the instances of one of its references
   * @param  {String} id   Asset ID
   * @param  {String} ref    Name of the reference to extract
   * @return {Array}         List of instances of the chosen reference
   */
  extractReference(id, refName) {
    const instance = this.cimDict[id];
    if (!instance) return null;
    const ref = instance.references[refName];
    if (!ref) return null;
    return {
      ...this.cimDict[ref],
      id: ref,
    };
  }

  extractReferenceList = (id, refName) => {
    const instance = this.cimDict[id];
    if (!instance) return [];
    const list = instance.references[refName];
    if (!list) return [];
    return list.map(item => ({
      ...this.cimDict[item],
      id: item,
    }));
  };

  /**
   * Convert list of instances (one per phase) and create a lookup of their values
   * @param  {String} type           CIM class name of the instances
   * @param  {Array}  [instances=[]] List of instances
   * @param  {String} attribute      Name of the attribute being extracted
   * @return {Object}                Lookup of the values indexed by phase
   */
  extractAttributeByPhase(type, instances = [], attribute) {
    const values = {};
    instances.forEach(sv => {
      const phase = sv.attributes[`${type}.phase`];
      if (phase) {
        values[phase] = sv.attributes[attribute];
      }
    });
    return values;
  }

  /**
   * Convert list of instances (one per phase) and create a lookup of their values
   * @param  {String} type           CIM class name of the instances
   * @param  {Array}  [instances=[]] List of instances
   * @param  {String} attribute      Name of the attribute being extracted
   * @return {Object}                Lookup of the values indexed by phase
   */
  extractReferenceByPhase(type, instances = [], attribute) {
    const values = {};
    instances.forEach(sv => {
      const phase = sv.attributes[`${type}.phase`];
      if (phase) {
        values[phase] = this.cimDict[sv.references[attribute]];
      }
    });
    return values;
  }
}

export default CIMObject;
