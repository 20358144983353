import React, { FunctionComponent, useState } from 'react';
import { ThemeProp } from 'types/index';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { useToasts } from 'react-toast-notifications';
import navTextBoxURLFields from '../helpers/constants';

type NavigationURLPanelProps = {
  theme: ThemeProp;
  workspace: string;
};

type NavigationURL = {
  id: number | null;
  storedURLAddress: string | undefined;
  textBoxId: string | undefined;
  isValidUrl?: boolean;
};

const NavigationURLPanel: FunctionComponent<NavigationURLPanelProps> = ({ theme }) => {
  const dataInLocalStorage = localStorage.getItem(`navigation_urls`) ?? '';

  const parsedDataInLocalStorage = localStorage.getItem(`navigation_urls`)
    ? JSON.parse(dataInLocalStorage)
    : '';

  const arrayOfDataFromLocalStorage = Object.entries(parsedDataInLocalStorage).map(
    (item, index) => ({
      id: index + 1,
      textBoxId: item[0],
      storedURLAddress: item[1],
    }),
  );

  // type assertion
  const newArrayOfDataFromLocalStorage: NavigationURL[] = arrayOfDataFromLocalStorage as NavigationURL[];

  const [navigationURLs, setNavigationURLs] = useState<NavigationURL[]>(
    newArrayOfDataFromLocalStorage,
  );
  const [isEdited, setIsEdited] = useState(false);
  const [isValid, setIsValid] = useState([{ boxID: '', isItValid: true }]);

  const { addToast } = useToasts();

  const saveNavigationURLs = () => {
    try {
      addToast('Saved URL successfully', { appearance: 'success' });
      const navigationURLObject = Object.fromEntries(
        navigationURLs.map(({ textBoxId, storedURLAddress }) => [textBoxId, storedURLAddress]),
      );
      localStorage.setItem(`navigation_urls`, JSON.stringify(navigationURLObject));

      setIsEdited(false);
    } catch (err) {
      addToast('Failed to save URL', { appearance: 'error' });
      setIsEdited(true);
    }
  };

  const handleOnChange = (e: any, i: number, id: string) => {
    const newNavigationURLs = [...navigationURLs]; // copying the old datas array
    let item = newNavigationURLs[i];
    item = { ...item, storedURLAddress: e.target.value, id: i, textBoxId: id };
    newNavigationURLs[i] = item;

    setNavigationURLs(newNavigationURLs);
    setIsEdited(true);

    // check validity
    const isvalidityCheckArray = [...isValid];
    let validItem = isvalidityCheckArray[i];

    try {
      const storedUrlAddress = e.target.value;
      const storedURLAddressWithURLType: URL = (storedUrlAddress as unknown) as URL;
      const parsedUrl: URL =
        storedURLAddressWithURLType instanceof URL
          ? storedURLAddressWithURLType
          : new URL(storedURLAddressWithURLType);

      const validURL = parsedUrl.searchParams.entries().next().done ?? true;

      validItem = { ...validItem, boxID: id, isItValid: validURL };
      isvalidityCheckArray[i] = validItem;
      setIsValid(isvalidityCheckArray);
    } catch (error) {
      validItem = { ...validItem, boxID: id, isItValid: false };
      isvalidityCheckArray[i] = validItem;
      setIsValid(isvalidityCheckArray);
      setIsEdited(true);
    }
  };

  const renderNavURLFields = () =>
    navTextBoxURLFields.map((item: any, index: number) => {
      const fieldId = item.name.replace(/\s+/g, '');
      return (
        <TextInput
          label={item.name}
          key={item.id}
          value={navigationURLs[index]?.storedURLAddress}
          id={`${fieldId}_url`}
          onChange={(e: any) => handleOnChange(e, index, item.id)}
          theme={theme}
          invalid={item.id === isValid[index]?.boxID && !isValid[index]?.isItValid}
        />
      );
    });

  return (
    <>
      <div className="workspace-card-tab-panel" id="navigation-url-panel">
        <div className="navigation-url-tab">
          {renderNavURLFields()}
          <Button
            label="Apply Changes"
            className="workspace-card-button"
            id="navigation-url-button"
            onClick={() => saveNavigationURLs()}
            theme={theme}
            type="primary"
            disabled={
              navigationURLs.length === 0 ||
              arrayOfDataFromLocalStorage === navigationURLs ||
              !isEdited
            }
          />
        </div>
      </div>
    </>
  );
};

export default NavigationURLPanel;
