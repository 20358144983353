import React, { FunctionComponent, useEffect } from 'react';
import chargerTypes from 'helpers/Constants';
import { settings, unitInfos } from 'types/edit';
import Select from 'components/Select';
import Input from 'components/Input';
import { kVA } from 'helpers/units';
import getControlModeOptions from 'helpers/cim/getControlModeName';

type BulkAssetEVSettingsProps = {
  assetSettings: settings;
  customerTypesofLoads: string[];
  setAssetSettings: (agrs: settings) => void;
  evInfos?: unitInfos;
};

const BulkAssetEVSettings: FunctionComponent<BulkAssetEVSettingsProps> = ({
  assetSettings,
  setAssetSettings,
  customerTypesofLoads,
  evInfos = [],
}) => {
  // set globallyOptimized by default during loading
  useEffect(() => {
    const newSettings = customerTypesofLoads.reduce<settings>((updatedSettings, type) => {
      const newSetting = assetSettings[type] ?? {};
      newSetting.control_mode = 'globallyOptimized';
      updatedSettings[type] = newSetting;
      return updatedSettings;
    }, {});
    setAssetSettings({ ...assetSettings, ...newSettings });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAssetSettings, customerTypesofLoads]);

  return (
    <div className="ev-settings">
      {customerTypesofLoads.map(type => (
        <div className="asset-type margin-10" key={type}>
          {type !== 'all' && (
            <p className="text-bold type-title">{type === '' ? 'Unspecified loads' : type}</p>
          )}
          <div className="grid-columns one-one">
            <Select
              label="EV type"
              onChange={e =>
                setAssetSettings({
                  ...assetSettings,
                  [type]: { ...assetSettings[type], asset_info: e.value },
                })
              }
              id={`select-${type}`}
              options={evInfos.map(ev => ({ value: ev.id, label: ev.name }))}
              value={assetSettings[type]?.asset_info}
              type="secondary"
              clearable={false}
            />
            <Select
              label="Charger type"
              onChange={e =>
                setAssetSettings({
                  ...assetSettings,
                  [type]: { ...assetSettings[type], ev_charger_type: e.value },
                })
              }
              id={`select-${type}`}
              options={chargerTypes}
              value={assetSettings[type]?.ev_charger_type}
              type="secondary"
              clearable={false}
            />
            <Input // eslint-disable-line deprecation/deprecation
              type="number"
              id={`input-${type}`}
              className="ev-rated-power"
              label="Rated power"
              value={assetSettings[type]?.rated_power}
              onChange={(e: any) =>
                setAssetSettings({
                  ...assetSettings,
                  [type]: { ...assetSettings[type], rated_power: e },
                })
              }
              onBlur={() => {}}
              options={{
                divisor: 1000,
                precision: 0,
              }}
              unit={kVA}
              inputStyle="primary"
            />
            <Select
              label="Control Mode"
              onChange={e =>
                setAssetSettings({
                  ...assetSettings,
                  [type]: { ...assetSettings[type], control_mode: e.value },
                })
              }
              options={getControlModeOptions('ElectricVehicleChargingStation')}
              value={assetSettings[type]?.control_mode}
              type="secondary"
              clearable={false}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
export default BulkAssetEVSettings;
