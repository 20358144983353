import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { MODAL_TYPES } from 'helpers/EditMode';
import IconButton from 'components/IconButton';
import Select from 'components/Select';
import classNames from 'classnames';
import ThemeContext from 'helpers/ThemeContext';
import ModalPortal from 'components/ModalPortal';
import Modal from 'components/Modal';
import history from 'routes/history';
import { isDefined } from 'helpers/utils';
import './EquipmentInfoSelector.scss';

// Create a dropdown for selecting equipment infos and a link to the
// info in the equipment library
const EquipmentInfoSelector = props => {
  const { actions } = props;
  const theme = useContext(ThemeContext);
  const [modalActive, setModalActive] = useState(null);
  let linkUrl = `${props.eqLibURL}/${props.value}`;

  if (props.value === '') {
    linkUrl = `${props.eqLibURL}/add`;
  }

  const handleSelect = e => {
    if (e?.value === 'add') {
      history.push(`${props.eqLibURL}/add`);
    } else {
      props.onChange(e);
    }
  };
  return (
    <div className="equipment-header">
      <div className="equipment-type equipment-type-selector">
        <p>{props.displayName ? `Library: ${props.displayName}` : ''}</p>
        <div className="equipment-info-selector">
          <Select
            className="equipment-select primary"
            async
            defaultOptions={props.defaultOptions}
            loadOptions={props.loadOptions}
            name={props.id}
            id={props.id}
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
            clearable={props.clearable}
            onChange={e => {
              props.changeOnModalConfirm && e ? setModalActive(e) : handleSelect(e);
            }}
            theme={theme}
            filterOption={(option, input) => {
              if (input)
                return option?.data?.name?.toLowerCase()?.includes(input.toLowerCase()) ?? false;
              return true;
            }}
          />
          <IconButton
            icon="library_books"
            tooltip="Equipment Library"
            className={classNames({
              'equipment-info-selector-link': true,
              'equipment-info-selector-link--disabled': props.edited || props.value === undefined,
            })}
            onClick={
              props.inEditMode
                ? () =>
                    actions.setSaveModal(
                      true,
                      MODAL_TYPES.VIEW,
                      'library',
                      `${linkUrl}?feeder=${props.feeder}&id=${props.assetId}`,
                    )
                : () => {
                    actions.setView('library');
                    history.push(`${linkUrl}?feeder=${props.feeder}&id=${props.assetId}`);
                  }
            }
            disabled={props.value === undefined}
            theme={theme}
          />
        </div>
      </div>
      <ModalPortal id="modal-portal">
        <Modal
          width="350px"
          active={isDefined(modalActive)}
          title="Warning: update instance values"
          theme={theme}
          onCancel={() => setModalActive(null)}
          onConfirm={() => {
            handleSelect(modalActive);
            setModalActive(null);
          }}
        >
          <p className="modal-message__p">This change will clear the existing instance values.</p>
          <p className="modal-message__p">Would you like to proceed with this change?</p>
        </Modal>
      </ModalPortal>
    </div>
  );
};

EquipmentInfoSelector.defaultProps = {
  disabled: false,
  defaultOptions: [],
  edited: false,
  placeholder: 'Select Equipment Type',
  assetId: '',
  feeder: '',
  value: '',
  onChange: null,
  displayName: null,
  clearable: false,
  changeOnModalConfirm: false,
};

EquipmentInfoSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func, // eslint-disable-line
  value: PropTypes.string,
  eqLibURL: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultOptions: PropTypes.array,
  loadOptions: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  assetId: PropTypes.string,
  feeder: PropTypes.string,
  edited: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  displayName: PropTypes.string,
  clearable: PropTypes.bool,
  changeOnModalConfirm: PropTypes.bool,
};

export default EquipmentInfoSelector;
