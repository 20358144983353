import React, { FunctionComponent, useState } from 'react';
import Select from 'components/Select';
import Modal from 'components/Modal';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { ThemeProp } from 'types';

type FeederNodeSelectModalProps = {
  onConfirm: (arg: string | null) => void;
  onCancel: () => void;
  nodeId: string;
  allFeeders: { [key: string]: string }[];
  workspace: string;
  branch: string;
  theme: ThemeProp;
};

const FeederNodeSelectModal: FunctionComponent<FeederNodeSelectModalProps> = ({
  onConfirm,
  onCancel,
  nodeId,
  allFeeders,
  workspace,
  branch,
  theme,
}) => {
  const [selectedFeeder, setSelectedFeeder] = useState(null);
  const [backupNodeInModal, setBackupNodeInModal] = useState(null);
  const url = `/api/workspace/${workspace}/branch/${branch}/asset/node`;
  const { data: nodeData = [], loading } = useRequestEffect<{ [key: string]: string }[]>({
    url,
    method: 'get',
    params: {
      container: selectedFeeder,
      sort_node: nodeId,
    },
    refetchOnChange: [workspace, branch, selectedFeeder],
    blockRequest: () => !(workspace && branch && selectedFeeder),
  });
  return (
    <Modal
      active
      title="Backup node by Feeder"
      onConfirm={() => onConfirm(backupNodeInModal)}
      onCancel={onCancel}
      theme={theme}
      modalType="primary"
      width="500px"
      height="500px"
      labels={{
        confirm: 'submit',
        cancel: 'Discard',
      }}
      scrollBody
      disableConfirm={loading || !backupNodeInModal || !selectedFeeder}
    >
      <div className="margin-10">
        <Select
          label="Feeder"
          onChange={e => {
            setSelectedFeeder(e.value);
            setBackupNodeInModal(null);
          }}
          id="select-feeder"
          options={allFeeders?.map(feeder => ({ value: feeder.id, label: feeder.name }))}
          value={selectedFeeder}
          theme={theme}
          type="secondary"
          clearable={false}
        />
      </div>
      <div>
        <Select
          label="Backup Node"
          onChange={e => setBackupNodeInModal(e.value)}
          id="select-backup-node"
          options={nodeData?.map(node => ({ value: node.id, label: node.name }))}
          value={backupNodeInModal}
          theme={theme}
          type="secondary"
          clearable={false}
          disabled={loading || !selectedFeeder}
          placeholder={loading ? 'Loading...' : 'Select...'}
        />
      </div>
    </Modal>
  );
};

export default FeederNodeSelectModal;
