import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const MappingIcon: FunctionComponent<IconPropsSchema> = ({
  color = '#000',
  height = '25px',
  width = '25px',
}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 24 24" height={height} width={width}>
    <path fill="none" d="M0,0h24v24H0V0z" />
    <polygon
      fill={color}
      points="21,2.9 16,2.9 16,7.9 17.8,7.9 17.8,11.3 4.8,11.3 4.8,16.1 3,16.1 3,21.1 8,21.1 8,16.1 6.2,16.1 6.2,12.8 19.2,12.8 19.2,7.9 21,7.9 "
    />
  </svg>
);

export default MappingIcon;
