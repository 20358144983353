import React from 'react';
import './Icons.scss';

const WarningIcon = () => (
  <div className="warnings-instance-icon">
    <i style={{ color: 'orange', fontSize: 18 }} className="material-icons" aria-hidden="true">
      warning
    </i>
  </div>
);

const ErrorIcon = () => (
  <div className="errors-instance-icon">
    <i style={{ color: 'red', fontSize: 18 }} className="material-icons" aria-hidden="true">
      highlight_off
    </i>
  </div>
);

export { WarningIcon, ErrorIcon };
