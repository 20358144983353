import ShuntDevice from './ShuntDevice';
import { extractPQ, extractUsagePoints } from '../extractorHelpers';

class EnergyConsumer extends ShuntDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'load';
    this._class = 'EnergyConsumer';
    this.displayName = 'Load';
  }

  get usagePoints() {
    const usagePoints = this.references['Equipment.UsagePoints'];
    if (!usagePoints) {
      return [];
    }
    return extractUsagePoints(usagePoints, this.cimDict);
  }

  // Get the energy consumer's p values per phase
  get pFixed() {
    if (this._phases) {
      return extractPQ('p', this._phases, this.cimDict);
    }
    return {};
  }

  get totalP() {
    const phases = Object.keys(this.pFixed);
    const totalP = phases.reduce((total, phase) => {
      if (!this.pFixed[phase]) return total;
      return total + parseFloat(this.pFixed[phase]);
    }, 0);
    return totalP;
  }

  validationSchema = {
    pFixed: { min: 0, required: true },
    qFixed: { required: true },
    customerCount: {
      min: 0,
      customValidation: value => {
        const valid = Number.isInteger(value);
        const message = 'Value must be a whole number';
        return {
          valid,
          message: !valid ? message : null,
        };
      },
    },
    usagePoints: {
      customValidation: arr => {
        const valid = [];
        const message = 'Rated power must not be smaller than 0';
        arr.forEach(point => {
          if (point.ratedPower < 0) valid.push(false);
        });
        return {
          valid: valid.length === 0,
          message: valid.length > 0 ? message : null,
        };
      },
    },
  };

  /* EnergyConsumer Helpers */
  get _phases() {
    return this.references['EnergyConsumer.EnergyConsumerPhase'];
  }
}

export default EnergyConsumer;
