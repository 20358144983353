/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { AuthContextProvider, apm } from '@opusonesolutions/gridos-app-framework';

import history from 'routes/history';
import createStore from './store/createStore';
import AppContainer from './containers/AppContainer';

import 'typeface-roboto';

// ========================================================
// Store Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState);

// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');

let render = () => {
  ReactDOM.render(
    <Router history={history}>
      <ToastProvider autoDismiss autoDismissTimeout={6000}>
        <AuthContextProvider serviceName="webui">
          <AppContainer store={store} />
        </AuthContextProvider>
      </ToastProvider>
    </Router>,
    MOUNT_NODE,
  );
};

// This code is excluded from production bundle
if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    // Development render functions
    const renderApp = render;
    const renderError = error => {
      const RedBox = require('redbox-react').default;

      ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
    };

    // Wrap render in try/catch
    render = () => {
      try {
        renderApp();
      } catch (error) {
        apm.captureError(error);
        renderError(error);
      }
    };

    // Setup hot module replacement
    module.hot.accept('./routes/index', () =>
      setImmediate(() => {
        ReactDOM.unmountComponentAtNode(MOUNT_NODE);
        render();
      }),
    );
  }
}

// ========================================================
// Go!
// ========================================================
render();
