import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getMultiplier } from 'helpers/utils';
import ThemeContext from 'helpers/ThemeContext';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import ColorPicker from './ColorPicker/ColorPicker';
import './EditRange.scss';

class EditRange extends Component {
  UNSAFE_componentWillMount() {
    let defaultState = {};

    // If editing range, determine the display values and multipliers (if being used)
    if (this.props.editRange) {
      let displayValues;
      let multipliers;
      if (this.props.useMultiplier) {
        multipliers = this.props.layerOptions.rangeBreaks.map(val => getMultiplier(val));
        displayValues = this.props.layerOptions.rangeBreaks.map(val =>
          (val / getMultiplier(val).value).toString(),
        );
      } else {
        displayValues = this.props.layerOptions.rangeBreaks;
      }

      defaultState = {
        ...defaultState,
        displayValues,
        multipliers,
        validInput: ['', '', '', ''],
        rangeBreaks: [...this.props.layerOptions.rangeBreaks],
        range: [...this.props.layerOptions.range],
      };
    }

    // If editing color, add color details to default state
    if (this.props.editColor) {
      defaultState = {
        ...defaultState,
        checkColor: this.props.layerOptions.colors[this.props.editId],
        colors: [...this.props.layerOptions.colors],
      };
    }

    this.setState(defaultState);
  }

  /**
   * Update the color list and chosen color
   * @param  {String} clickColor  Selected hex color
   */
  changeColor = clickColor => {
    this.setState(prevState => {
      const updatedColors = [...prevState.colors];
      updatedColors[this.props.editId] = clickColor;

      return {
        checkColor: clickColor,
        colors: updatedColors,
      };
    });
  };

  /**
   * When a range value is updated, determine if it is valid and update the range
   * and range breaks in state with the selected value
   * @param  {Object} e       Range input value
   * @param  {Number} index  Index in the updated value in the range breaks list
   */
  updateRangeValue = (value, index) => {
    this.setState(prevState => {
      const { rangeMin, rangeMax } = this.props;
      const multiplier = this.props.useMultiplier ? prevState.multipliers[index].value : 1;
      const rangeBreaks = [...prevState.rangeBreaks];
      const validInput = [...prevState.validInput];

      const displayValues = { ...prevState.displayValues };
      displayValues[index] = value;
      const updatedValue = parseFloat(value, 10) * multiplier;
      rangeBreaks[index] = updatedValue;
      const validationCheck = this.props.validationCheck || this.validationCheck;
      let check = validationCheck(updatedValue, index, rangeBreaks);
      check = check ? updatedValue >= rangeMin && updatedValue <= rangeMax : false;
      if (check) {
        validInput[index] = '';
      } else {
        validInput[index] = 'invalid-value';
      }

      return {
        displayValues,
        validInput,
        rangeBreaks,
      };
    });
  };

  validationCheck = (value, index, rangeBreaks) => {
    let check = true;
    // Logic check for ranges that are ascending
    if (this.props.ascending) {
      if (index === 0) {
        check = value && value > rangeBreaks[index + 1];
      } else if (index === rangeBreaks.length - 1) {
        check = value && value < rangeBreaks[index - 1];
      } else {
        check = value && value > rangeBreaks[index + 1] && value < rangeBreaks[index - 1];
      }
      // Logic check for ranges that are descending
    } else if (this.props.descending) {
      if (index === 0) {
        check = value && value < rangeBreaks[index + 1];
      } else if (index === rangeBreaks.length - 1) {
        check = value && value > rangeBreaks[index - 1];
      } else {
        check = value && value < rangeBreaks[index + 1] && value > rangeBreaks[index - 1];
      }
    }
    return check;
  };

  // Handle click on the Accept button
  applyEdits = () => {
    let update = {};
    // Gather range updates
    if (this.props.editRange) {
      update = {
        ...update,
        rangeBreaks: this.state.rangeBreaks,
        range: this.state.range,
      };
    }

    // Add color update
    if (this.props.editColor) {
      update = {
        ...update,
        colors: this.state.colors,
      };
    }

    this.props.handleUpdate(update);
    this.closeEditPanel();
  };

  closeEditPanel = () => this.props.toggleEditPanel('');

  isApplyDisabled = () => {
    let disabled = false;

    if (this.props.editRange) {
      disabled = this.state.validInput.some(val => val === 'invalid-value');
    }

    return disabled;
  };

  getHeaders = () => {
    if (this.props.headers) {
      return this.props.headers;
    }
    return this.props.ascending
      ? ['Greater Than', 'Range', 'Range', 'Range', 'Less Than']
      : ['Less Than', 'Range', 'Range', 'Range', 'Greater Than'];
  };

  render() {
    const index = this.props.editId;
    const Rge = index > 0 && index < 4;
    const single = !Rge;
    const id = this.props.editId === 0 ? index : index - 1;
    const { validInput } = this.state;
    const { unit } = this.props;
    const headers = this.getHeaders();
    const theme = typeof this.context === 'string' ? this.context : 'dark';

    return (
      <div className="edit-container">
        {this.props.editRange && (
          <div className="edit-header">
            <p>{headers[index]}</p>
            <div className="edit-inputs">
              {Rge && this.props.ascending && (
                <>
                  <div className="lower-range">
                    <NumberInput
                      className={`edit-double ${validInput[id + 1]}`}
                      id={(id + 1).toString()}
                      unit={
                        this.state.multipliers
                          ? `${this.state.multipliers[id + 1].letter}${unit}`
                          : unit
                      }
                      value={this.state.displayValues[id + 1]}
                      onChange={e => this.updateRangeValue(e.value, id + 1)}
                      theme={theme}
                    />
                  </div>
                  <div className="dash"> - </div>
                </>
              )}
              <NumberInput
                className={
                  single ? `edit-single ${validInput[id]}` : `edit-double ${validInput[id]}`
                }
                id={id.toString()}
                unit={this.state.multipliers ? `${this.state.multipliers[id].letter}${unit}` : unit}
                value={this.state.displayValues[id]}
                onChange={e => this.updateRangeValue(e.value, id)}
                theme={theme}
              />
              {Rge && this.props.descending && (
                <>
                  <div className="dash"> - </div>
                  <div className="lower-range">
                    <NumberInput
                      className={`edit-double ${validInput[id + 1]}`}
                      id={(id + 1).toString()}
                      unit={
                        this.state.multipliers
                          ? `${this.state.multipliers[id + 1].letter}${unit}`
                          : unit
                      }
                      value={this.state.displayValues[id + 1]}
                      onChange={e => this.updateRangeValue(e.value, id + 1)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {this.props.editColor && (
          <div className="color-picker">
            <ColorPicker currentColor={this.state.checkColor} changeColor={this.changeColor} />
          </div>
        )}
        <div className="edit-footer">
          <Button
            id="cancel"
            className="edit-button"
            onClick={this.closeEditPanel}
            type="text"
            label="Cancel"
            theme={theme}
          />
          <Button
            id="apply"
            className="edit-button"
            onClick={() => this.applyEdits(index.toString())}
            disabled={this.isApplyDisabled()}
            type="text"
            label="Apply"
            theme={theme}
          />
        </div>
      </div>
    );
  }
}

EditRange.contextType = ThemeContext;

EditRange.defaultProps = {
  editRange: false,
  editColor: false,
  useMultiplier: false,
  unit: null,
  validationCheck: null,
  descending: false,
  ascending: false,
  headers: null,
  rangeBreaks: null,
};

EditRange.propTypes = {
  editId: PropTypes.number.isRequired,
  toggleEditPanel: PropTypes.func.isRequired,
  layerOptions: PropTypes.object.isRequired,
  editRange: PropTypes.bool,
  editColor: PropTypes.bool,
  useMultiplier: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  rangeMin: PropTypes.number.isRequired,
  rangeMax: PropTypes.number.isRequired,
  rangeBreaks: PropTypes.array,
  unit: PropTypes.string,
  validationCheck: PropTypes.func,
  ascending: PropTypes.bool,
  descending: PropTypes.bool,
  headers: PropTypes.array,
};

export default EditRange;
