import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import TextInput from 'components/TextInput';

import { isDefined } from 'helpers/utils';

import {
  areAssetModelPropertiesChanged,
  areAssetModelsEqual,
  defaultAssetModel,
  getAssetModelProperties,
  isAssetModelValid,
} from '../../helpers/assetModelHelpers';
import DescriptionEditor from './DescriptionEditor';
import CostEditor from './CostEditor';
import PanelTabs from './PanelTabs';

import './common.scss';

const getNewChargingStation = () => ({
  name: '',
  description: '',
  assetModel: { ...defaultAssetModel },
  id: 'add',
});

class ElectricVehicleCharginStation extends Component {
  state = { ...getNewChargingStation() };

  UNSAFE_componentWillMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selected !== nextProps.selected) {
      this.setState({ ...this.getInputValues(nextProps.selected) });
    }
  }

  getInputValues = selected => {
    let asset = { ...getNewChargingStation() };
    if (selected) {
      const { name, description, id, AssetModel } = selected;
      asset = {
        name: name || asset.name,
        description: description || asset.description,
        assetModel: isDefined(AssetModel) ? getAssetModelProperties(AssetModel) : asset.assetModel,
        id,
      };
    }
    return asset;
  };

  handleSave = () => {
    const diffModel = {
      name: this.state.name,
      description: this.state.description,
      AssetModel: { ...this.state.assetModel },
    };
    if (this.state.id === 'add') {
      this.props.handleCreate(
        'electric_vehicle_charging_station_info',
        diffModel,
        'ElectricVehicleChargingStationInfo',
      );
    } else {
      // Only submit the values that have changed
      const { selected } = this.props;
      const keys = Object.keys(diffModel);
      const editDiffModel = keys.reduce((diff, key) => {
        switch (key) {
          case 'name':
          case 'description':
            if (diffModel[key] !== selected[key]) {
              diff[key] = diffModel[key];
            }
            return diff;
          case 'AssetModel':
            if (!areAssetModelsEqual(diffModel[key], selected[key])) {
              diff[key] = diffModel[key];
            }
            return diff;
          default:
            return diff;
        }
      }, {});
      this.props.handleEdit(selected.id, editDiffModel);
    }
  };

  handleInputChange = ({ target }) => this.setState({ [target.id]: target.value });

  valuesUpdated = selected => {
    if (!selected) return true;
    const stringUpdated = ['name', 'description'].some(val => selected[val] !== this.state[val]);
    const assetModelUpdated = !areAssetModelsEqual(
      getAssetModelProperties(selected.AssetModel),
      this.state.assetModel,
    );
    return stringUpdated || assetModelUpdated;
  };

  formValid = () =>
    isAssetModelValid(this.state.assetModel) &&
    this.state.name.trim().length > 0 &&
    this.valuesUpdated(this.props.selected);

  render() {
    const isDisabled =
      this.props.isAuthEnabled &&
      ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type')) ||
        (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type')) ||
        (this.props.match.params.branch === 'master' &&
          !this.props.permissions.has('modify_network_as_built')));

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              <TextInput
                id="name"
                required
                label="Name"
                value={this.state.name}
                onChange={this.handleInputChange}
                disabled={isDisabled}
              />
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown escapeHtml source={decodeURIComponent(this.state.description)} />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={
              areAssetModelPropertiesChanged(
                this.state.assetModel,
                getAssetModelProperties(this.props.selected?.AssetModel),
              )[1]
            }
            key={`${this.state.id}-costs`}
            onChange={prop =>
              this.setState(prevState => ({
                assetModel: { ...prevState.assetModel, ...prop },
              }))
            }
            isDisabled={isDisabled}
          />,
        ]}
      </PanelTabs>
    );
  }
}

ElectricVehicleCharginStation.defaultProps = {
  selected: null,
};

ElectricVehicleCharginStation.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default ElectricVehicleCharginStation;
