import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import { amps, kVll } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import WireSpacingSelector from './WireSpacingSelector';
import WireConductorSelector from './WireConductorSelector';
import PerPhaseContainer from '../partials/PerPhaseContainer';
import PerPhaseRow from '../partials/PerPhaseRow';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import './InstanceInfo.scss';

const infoValues = [
  {
    id: 'rated_current',
    label: 'Rated Current',
    unit: amps,
    divisor: 1,
    type: 'number',
  },
  {
    id: 'short_term_emergency_current_limit',
    label: 'Short Term Emergency Current Limit',
    unit: amps,
    divisor: 1,
    type: 'number',
    nestingKey: 'alternate_limits',
  },
  {
    id: 'long_term_emergency_current_limit',
    label: 'Long Term Emergency Current Limit',
    unit: amps,
    divisor: 1,
    type: 'number',
    nestingKey: 'alternate_limits',
  },
];

const InstanceInfo = ({
  asset,
  workspace,
  displayBranch,
  theme,
  handleSave,
  recalculateLength,
  disabled,
  inEditMode,
}) => {
  const { attributes, phase_wire_info, phase: phaseCode, base_voltage } = asset;

  const [length, setLength, lengthEdited] = useTrackingState(attributes.length);

  return (
    <div>
      <div className="header-container">
        <WireSpacingSelector
          asset={asset}
          handleSave={handleSave}
          disabled={disabled}
          inEditMode={inEditMode}
          workspace={workspace}
          theme={theme}
          displayBranch={displayBranch}
        />
        <WireConductorSelector
          asset={asset}
          handleSave={handleSave}
          disabled={disabled}
          inEditMode={inEditMode}
          workspace={workspace}
          theme={theme}
          displayBranch={displayBranch}
        />
        <hr className="section-divider" />
        <div className="editable-section">
          <div className="input-spacing">
            <NumberInput
              label="Nominal Voltage"
              value={base_voltage}
              key="nodeBaseVoltage"
              id="nodeBaseVoltage"
              type="number"
              inputStyle="panel"
              unit={kVll}
              theme={theme}
              disabled
              divisor={1000}
              precision={3}
              className="single-row-input"
            />
          </div>
          <div className="input-spacing">
            <SingleEditableValue
              key="length"
              id="length"
              label="Length"
              type="number"
              precision={3}
              unit="m"
              value={length}
              disabled={disabled}
              onBlur={() => handleSave({ length })}
              onChange={setLength}
              min={0}
              required
              edited={lengthEdited}
            />
          </div>
          {inEditMode && (
            <div className="recalc-container input-spacing">
              <Button
                type="text"
                theme={theme}
                label="Recalculate Length"
                className="recalc-button"
                onClick={recalculateLength}
                disabled={disabled}
              />
            </div>
          )}

          <PerPhaseContainer>
            {infoValues.map(value => (
              <PerPhaseRow
                label={value.label}
                id={value.id}
                key={value.id}
                unit={value.unit}
                divisor={value.divisor}
                values={Object.entries(phase_wire_info).reduce(
                  (phase_map, [phase, info]) => ({
                    ...phase_map,
                    [phase]: value.nestingKey
                      ? info?.[value.nestingKey]?.[value.id]
                      : info?.[value.id],
                  }),
                  {},
                )}
                getTotal={() => ''}
                type={value.type}
                phases={phaseCode}
              />
            ))}
          </PerPhaseContainer>
        </div>
      </div>
    </div>
  );
};

InstanceInfo.defaultProps = {
  disabled: true,
  theme: 'light',
  inEditMode: false,
};

InstanceInfo.propTypes = {
  disabled: PropTypes.bool,
  asset: PropTypes.object.isRequired,
  theme: PropTypes.string,
  inEditMode: PropTypes.bool,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  recalculateLength: PropTypes.func.isRequired,
};

export default InstanceInfo;
