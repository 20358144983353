import ShuntDevice from './ShuntDevice';

class EquivalentSubstation extends ShuntDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'equivalent_substation';
    this._class = 'EquivalentSubstation';
    this.displayName = 'Downstream Substation';
  }

  get EnergySource() {
    return this.references['EquivalentSubstation.EnergySource'];
  }
}

export default EquivalentSubstation;
