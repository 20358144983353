import IdentifiedObject from '../IdentifiedObject';

class AssetFailureInfo extends IdentifiedObject {
  get mTTR() {
    return this.attributes?.['AssetFailureInfo.mTTR'] ?? null;
  }

  get probabilityOfFailureEquation() {
    return this.attributes?.['AssetFailureInfo.probabilityOfFailureEquation'] ?? null;
  }
}

export default AssetFailureInfo;
