import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { layerColors } from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';

class ColorPicker extends Component {
  selectColor = e => {
    this.props.changeColor(e.currentTarget.value);
  };

  render() {
    const { currentColor } = this.props;
    return layerColors.map(cell => (
      <button
        className="color-icon"
        onClick={this.selectColor}
        value={cell}
        type="button"
        key={cell}
      >
        <i className="material-icons" style={{ color: cell }}>
          {currentColor === cell ? 'check_circle' : 'brightness_1'}
        </i>
      </button>
    ));
  }
}

export default ColorPicker;

ColorPicker.propTypes = {
  currentColor: PropTypes.string.isRequired,
  changeColor: PropTypes.func.isRequired,
};
