import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import { Request } from '@opusonesolutions/gridos-app-framework';

interface LayerMap {
  age: boolean;
  healthMetric: boolean;
}
type AssetViolationsContextType = {
  layersEnabled: LayerMap;
  setMaxAge: (maxAge: number) => void;
  maxAge: number;
  setMaxHealthMetric: (maxHealthMetric: number) => void;
  maxHealthMetric: number;
  violatingAssetIds: Array<string>;
  refetchOldAssets: (layer?: keyof LayerMap) => void;
  toggleLayer: (layer: keyof LayerMap) => void;
};
export const AssetViolationsContext = createContext<AssetViolationsContextType>({
  layersEnabled: { age: false, healthMetric: false },
  setMaxAge: () => {},
  maxAge: 40,
  setMaxHealthMetric: () => {},
  maxHealthMetric: 3,
  violatingAssetIds: [],
  refetchOldAssets: () => {},
  toggleLayer: () => {},
});

const violationRequest = (workspace: string, branch: string, violation: string) =>
  new Request(`/api/workspace/${workspace}/branch/${branch}/asset_violations/${violation}`);

type AssetHealthMetricViolation = [
  Array<string>,
  number,
  (healthMetric: number) => void,
  () => void,
];
const useAssetHealthMetricViolation = (
  workspace: string,
  branch: string,
  layerEnabled: boolean,
): AssetHealthMetricViolation => {
  const [maxHealthMetric, setMaxHealthMetric] = useState<number>(3);
  const [unhealthyAssets, setUnhealthyAssets] = useState<Array<string>>([]);
  const [refetch, setRefetch] = useState<boolean>(false);
  const isHealthMetricValid = maxHealthMetric >= 1 && maxHealthMetric <= 5;

  const getUnhealthyAssets = useCallback(
    async (didCancel: boolean) => {
      const req = violationRequest(workspace, branch, 'health_metric');
      try {
        if (didCancel) return;

        setUnhealthyAssets([]);
        const { data } = await req.get({
          params: {
            max_health_metric: maxHealthMetric,
          },
        });
        if (!didCancel) {
          setUnhealthyAssets(data);
        }
      } catch (error) {}
    },
    [workspace, branch, maxHealthMetric],
  );

  // Call on Layer toggle and first run
  useEffect(() => {
    let didCancel = false;

    if (didCancel) return () => {};

    if (layerEnabled && isHealthMetricValid) {
      getUnhealthyAssets(didCancel);
    }

    return () => {
      didCancel = true;
    };
  }, [workspace, branch, layerEnabled, isHealthMetricValid, getUnhealthyAssets]);

  // Manual refetch
  useEffect(() => {
    let didCancel = false;

    if (didCancel) return () => {};

    if (layerEnabled && refetch && isHealthMetricValid) {
      getUnhealthyAssets(didCancel);
    }

    setRefetch(false);
    return () => {
      didCancel = true;
    };
  }, [refetch, isHealthMetricValid, layerEnabled, getUnhealthyAssets]);

  return [unhealthyAssets, maxHealthMetric, setMaxHealthMetric, () => setRefetch(true)];
};

type AssetAgeViolation = [Array<string>, number, (age: number) => void, () => void];
const useAssetAgeViolation = (
  workspace: string,
  branch: string,
  endDate: string,
  layerEnabled: boolean,
): AssetAgeViolation => {
  // Default max age requested in IDP-4044
  const [maxAge, setMaxAge] = useState<number>(40);
  const [overAgeAssets, setOverAgeAssets] = useState<Array<string>>([]);
  const [refetch, setRefetch] = useState<boolean>(false);
  const isAgeValid = (maxAge === 0 || maxAge) && maxAge >= 0 && maxAge <= 100;

  const getOverAgeAssets = useCallback(
    async (didCancel: boolean) => {
      const req = violationRequest(workspace, branch, 'age');
      try {
        if (didCancel) return;

        setOverAgeAssets([]);
        const { data } = await req.get({
          params: {
            max_age_years: maxAge,
            end_date: endDate,
          },
        });
        if (!didCancel) {
          setOverAgeAssets(data);
        }
      } catch (error) {}
    },
    [workspace, branch, maxAge, endDate],
  );

  // Call on Layer toggle and first run
  useEffect(() => {
    let didCancel = false;

    if (didCancel) return () => {};

    if (layerEnabled && isAgeValid) {
      getOverAgeAssets(didCancel);
    }

    return () => {
      didCancel = true;
    };
  }, [workspace, branch, layerEnabled, isAgeValid, getOverAgeAssets]);

  // Manual refetch
  useEffect(() => {
    let didCancel = false;

    if (didCancel) return () => {};

    if (layerEnabled && refetch && isAgeValid) {
      getOverAgeAssets(didCancel);
    }

    setRefetch(false);
    return () => {
      didCancel = true;
    };
  }, [refetch, isAgeValid, layerEnabled, getOverAgeAssets]);

  return [overAgeAssets, maxAge, setMaxAge, () => setRefetch(true)];
};

interface ViolationsProvider {
  children: JSX.Element;
}
interface ReduxState {
  network: {
    workspace: string;
    branch: string;
    timeRange: {
      start: Moment;
      end: Moment;
    };
  };
}
interface Selected {
  workspace: string;
  branch: string;
  endDate: string;
}
export default function AssetViolationsProvider({ children }: ViolationsProvider): JSX.Element {
  const { workspace, branch, endDate } = useSelector<ReduxState, Selected>(state => ({
    workspace: state.network.workspace,
    branch: state.network.branch,
    endDate: moment(state.network.timeRange?.end ?? moment.utc()).format('YYYY-MM-DD'),
  }));

  const [layersEnabled, setEnabled] = useState<LayerMap>({
    age: false,
    healthMetric: false,
  });

  const [overAgeAssets, maxAge, setMaxAge, refetchAgeAssets] = useAssetAgeViolation(
    workspace,
    branch,
    endDate,
    layersEnabled.age,
  );

  const [
    unhealthyAssets,
    maxHealthMetric,
    setMaxHealthMetric,
    refetchUnhealthyAssets,
  ] = useAssetHealthMetricViolation(workspace, branch, layersEnabled.healthMetric);

  const allViolatingAssets = (layersEnabled.age ? overAgeAssets : []).concat(
    layersEnabled.healthMetric ? unhealthyAssets : [],
  );
  return (
    <AssetViolationsContext.Provider
      value={{
        layersEnabled,
        setMaxAge,
        maxAge,
        setMaxHealthMetric,
        maxHealthMetric,
        violatingAssetIds: allViolatingAssets,
        refetchOldAssets: layer => {
          switch (layer) {
            case 'age':
              refetchAgeAssets();
              break;
            case 'healthMetric':
              refetchUnhealthyAssets();
              break;
            default:
              refetchAgeAssets();
              refetchUnhealthyAssets();
              break;
          }
        },
        toggleLayer: layer => setEnabled({ ...layersEnabled, [layer]: !layersEnabled[layer] }),
      }}
    >
      {children}
    </AssetViolationsContext.Provider>
  );
}
