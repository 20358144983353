import React, { FunctionComponent, useState, useMemo } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import moment, { Moment } from 'moment';
import Select from 'components/Select';
import {
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Area,
  AreaChart,
} from 'recharts';
import { kW, kVAr } from 'helpers/units';
import classNames from 'classnames';
import ResultsChartCard from './ResultsChartCard';
import PhaseBadgeContainer from '../../templates/partials/PhaseBadgeContainer';

type OperationalEnvpResultsProps = {
  workspace: string;
  branch: string;
  assetID: string;
  scenarioID: string;
  maxRange?: {
    start?: Moment | null;
    end?: Moment | null;
  };
  analysisName: string;
  feeder: string;
};

type OPEResultsType = {
  [key: string]: string | number | null | { [key: string]: string | null | number };
}[];
type OperationalEnvpResultsType = {
  aggregation: 'hour';
  results: OPEResultsType;
};
const OperationalEnvpResults: FunctionComponent<OperationalEnvpResultsProps> = ({
  workspace,
  branch,
  assetID,
  scenarioID,
  maxRange,
  analysisName,
  feeder,
}) => {
  const graphVariables = [
    { label: 'Real Power', value: 'p', unit: kW },
    { label: 'Reactive Power', value: 'q', unit: kVAr },
  ];
  const findMax = (importVal: number | null, exportVal: number | null) => {
    if (importVal && exportVal && importVal > exportVal) return [exportVal, importVal];
    return [importVal, exportVal];
  };
  const { data: operationalData, loading } = useRequestEffect<OperationalEnvpResultsType>({
    url: `/api/workspace/${workspace}/branch/${branch}/operational-envelope-results/asset/${assetID}`,
    method: 'get',
    params: {
      scenario_id: scenarioID,
      analysis_name: analysisName,
      start_date: maxRange?.start?.toISOString(true),
      end_date: maxRange?.end?.toISOString(true),
      feeder,
    },
    refetchOnChange: [workspace, branch, assetID, scenarioID, analysisName],
    blockRequest: () => !(workspace && branch && assetID && scenarioID && analysisName),
  });
  const [selectedGraphVariable, setSelectedGraphVariable] = useState(graphVariables[0].value);
  const opvResults = useMemo(
    () =>
      operationalData?.results
        ? operationalData?.results?.map(obj => ({
            ...obj,
            timepoint: obj.timepoint,
            results: ['A', 'B', 'C', 'ABC'].reduce(
              (newObj: { [key: string]: (number | null)[] }, phase: string) => {
                newObj[phase] = findMax(
                  (obj?.[`computed_max_import_${selectedGraphVariable}`] as {
                    [key: string]: number | null;
                  })?.[phase],
                  (obj?.[`computed_max_export_${selectedGraphVariable}`] as {
                    [key: string]: number | null;
                  })?.[phase],
                );
                return newObj;
              },
              {},
            ),
          }))
        : [],
    [operationalData, selectedGraphVariable],
  );
  const [activePhases, setActivePhases] = useState<{ [key: string]: boolean }>({
    A: false,
    B: false,
    C: false,
    ABC: true,
  });
  const phaseColors: { [key: string]: string } = {
    A: '#639DD1',
    B: '#2dafa8',
    C: '#fd813b',
    ABC: '#262626',
  };
  const phases = Object.keys(activePhases).filter(key => activePhases[key]);
  const resultsHasValues = opvResults[0]?.results;
  const selectedGraphVariableObj = graphVariables.find(obj => obj.value === selectedGraphVariable);
  const customTooltip = ({ payload, label }: any) => {
    const values = payload && payload[0] ? payload[0].payload : {};
    const hasValue = (name: string) =>
      values[`${name}`]?.ABC !== undefined ||
      values[`${name}`]?.A !== undefined ||
      values[`${name}`]?.B !== undefined ||
      values[`${name}`]?.C !== undefined;
    const generateRawRows = (phase: string, key: string) => (
      <tr key={phase}>
        <td>{phase}</td>
        {hasValue(`${key}_${selectedGraphVariable}`) && (
          <td style={{ color: phaseColors?.[phase] }}>
            {(values[`${key}_${selectedGraphVariable}`]?.[phase] / 1000.0).toFixed(2)}
          </td>
        )}
      </tr>
    );
    return (
      <div className="tooltip">
        <p>
          <b>{moment.parseZone(label).format('YYYY/MM/DD HH:mm')}</b>
        </p>
        <table>
          <thead>
            <tr>
              <td colSpan={3}>
                {selectedGraphVariableObj?.label}({selectedGraphVariableObj?.unit})
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Max export</td>
            </tr>
            {phases.map(
              activePhase =>
                values[`computed_max_export_${selectedGraphVariable}`]?.[activePhase] !==
                  undefined && generateRawRows(activePhase, 'computed_max_export'),
            )}
            <tr>
              <td>Max import</td>
            </tr>
            {phases.map(
              activePhase =>
                values[`computed_max_import_${selectedGraphVariable}`]?.[activePhase] !==
                  undefined && generateRawRows(activePhase, 'computed_max_import'),
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const generateAreas = (phaseKey: string | null = null) => {
    const phaseKeyToUse = typeof phaseKey === 'string' ? `.${phaseKey}` : '';
    return [
      <Area
        type="stepAfter"
        dataKey={`results${phaseKeyToUse}`}
        stroke="#8884d8"
        fill="url(#colorImport)"
        key={`area_${phaseKeyToUse}`}
      />,
    ];
  };
  let cardState = 'initial';
  if (loading) {
    cardState = 'loading';
  } else if (resultsHasValues) {
    cardState = 'loaded';
  }
  return (
    <div className="asset-panel-values results-section operational-envp-section">
      <h4>Analysis Results</h4>
      <>
        <h4>Choose parameter to visualize over time</h4>
        <Select
          clearable={false}
          value={selectedGraphVariable}
          options={graphVariables}
          searchable={false}
          width={215}
          onChange={({ value }) => setSelectedGraphVariable(value)}
        />
        <ResultsChartCard
          title={selectedGraphVariableObj?.label ?? ''}
          theme="light"
          state={cardState}
        >
          <div className="unit-label-row">
            <p>({selectedGraphVariableObj?.unit})</p>
          </div>
          <div
            className={classNames({
              'chart-pane': true,
              'chart-pane--expanded': true,
            })}
          >
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart syncId="pqTimeChart" data={opvResults}>
                <defs>
                  <linearGradient id="colorImport" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="20%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="50%" stopColor="#fff" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#82ca9d" stopOpacity={0.5} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis
                  dataKey="timepoint"
                  stroke="#949899"
                  tick={false}
                  scale="point"
                  tickFormatter={val => moment.parseZone(val).format('HH:mm')}
                />
                <YAxis
                  stroke="#949899"
                  tickLine={false}
                  tickFormatter={val => val / 1000}
                  domain={['auto', 'auto']}
                />
                <Tooltip content={customTooltip} />
                {phases.map(phase => generateAreas(phase))}
              </AreaChart>
            </ResponsiveContainer>
          </div>
          {resultsHasValues && (
            <PhaseBadgeContainer
              activePhases={activePhases}
              phaseColors={phaseColors}
              onActivePhaseChange={(value: { [key: string]: boolean }) => {
                setActivePhases({ ...activePhases, ...value });
              }}
              onHoverChange={() => {}}
            />
          )}
        </ResultsChartCard>
      </>
    </div>
  );
};

export default OperationalEnvpResults;
