/* eslint-disable react/no-unused-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomScrollBar from 'components/CustomScrollBar';
import asyncStates from 'helpers/asyncActionStates';
import { useAsset } from 'contexts/AssetContext';
import ThemeContext from 'helpers/ThemeContext';
import { userCanEdit } from 'routes/WorkspaceLayout/routes/Network/helpers/EditHelpers';
import FilesPanel from 'components/Files';
import NotesPanel from 'components/Notes';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { useAttachments } from 'contexts/AttachmentsContext';
import AssetPanelLoading from './components/AssetPanelLoading';
import AssetPanelError from './components/AssetPanelError';
import ResultsPanel from './containers/ResultsPanelContainer';
import Templates from './templates';
import AssetDetailsHeader from './templates/partials/AssetDetailsHeader';
import { TYPE_MAP } from '../../../helpers/NetworkHelpers';

const { SUCCESS, ERROR } = asyncStates;

const AssetPanel = props => {
  const theme = useContext(ThemeContext);
  const { settings } = useContext(WorkspaceSettingsContext);
  const { asset, loading: loadingState, refetch } = useAsset(
    props.selectedNode.id,
    TYPE_MAP[props.selectedNode.class].assetType,
  );
  const [newPanelValues, setNewPanelValues] = useState({
    name:
      loadingState === SUCCESS && !asset?.attributes.name
        ? props.selectedNode.id
        : asset?.attributes.name,
    baseVoltage: asset?.base_voltage,
    phase: asset?.phase || asset?.phases,
  });
  const [edited, setEdited] = useState({});
  const [errors, setErrors] = useState({ isError: false, error: '' });
  const [selectedTab, setSelectedTab] = useState(0);
  const { files, notes } = useAttachments();

  const { editValues } = props.applyDifferenceModelRequest;
  const { inEditMode, selectedNode } = props;
  const hasPermissionToEdit =
    !props.isAuthEnabled || userCanEdit(props.permissions, props.displayBranch);
  const editingAvailableForBranch = props.displayBranch !== 'master' || !settings.automaticImport;
  const canEditNetwork = hasPermissionToEdit && editingAvailableForBranch;
  const checkErrors = () => {
    const err = {
      isError: false,
      error: '',
    };
    const { applyDifferenceModelRequest, deleteStatus, deleteError } = props;
    if (applyDifferenceModelRequest.editValues === ERROR || deleteStatus === ERROR) {
      err.isError = true;
      if (
        applyDifferenceModelRequest.editValues === ERROR &&
        applyDifferenceModelRequest.error &&
        applyDifferenceModelRequest.error.response &&
        applyDifferenceModelRequest.error.response.data
      ) {
        const { message } = applyDifferenceModelRequest.error.response.data;
        err.error = typeof message === 'string' ? message : 'There was an unexpected error!';
      } else if (
        deleteStatus === ERROR &&
        deleteError &&
        deleteError.response &&
        deleteError.response.data
      ) {
        err.error = deleteError.response.data.message;
      } else {
        err.error = 'Failed to update';
      }
    }
    return err;
  };

  useEffect(() => {
    let newPV = {
      name:
        loadingState === SUCCESS && !asset?.attributes.name
          ? props.selectedNode.id
          : asset?.attributes.name,
      baseVoltage: asset?.base_voltage,
      phase: asset?.phase || asset?.phases,
    };
    if (props.selectedNode.class === 'Battery' || props.selectedNode.class === 'InverterPV') {
      newPV = {
        ...newPV,
        minQ: asset?.attributes?.minQ,
        maxQ: asset?.attributes?.maxQ,
      };
    }
    if (props.selectedNode.class === 'Battery') {
      newPV = {
        ...newPV,
        minP: asset?.attributes?.minP,
        maxP: asset?.attributes?.maxP,
        ...asset?.power_electronics_units?.reduce(
          (prev, batteryUnit) => ({
            minEnergy: prev.min_energy + (batteryUnit.min_energy ?? 0),
            maxEnergy: prev.max_energy + (batteryUnit.max_energy ?? 0),
          }),
          {
            minEnergy: 0,
            maxEnergy: 0,
          },
        ),
      };
    }
    if (props.selectedNode.class === 'InverterPV') {
      newPV = {
        ...newPV,
        ratedS: asset?.attributes?.ratedS,
        pvUnitMinP: asset?.power_electronics_unit?.reduce(
          (totalMinP, pv) => totalMinP + pv.minP,
          0,
        ),
      };
    }
    setNewPanelValues(newPV);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedNode.id, asset]);

  useEffect(() => {
    const err = checkErrors();
    if (err.isError !== errors.isError || err.error !== errors.error) {
      setErrors(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applyDifferenceModelRequest, props.deleteStatus, props.deleteError]);

  useEffect(() => {
    if (props.inEditMode && selectedTab === 1) {
      setSelectedTab(0);
    }
  }, [selectedTab, props.inEditMode]);

  useEffect(() => {
    if (!props.loadForecast.selectedAnalysis?.name) {
      setSelectedTab(0);
    }
  }, [props.loadForecast.selectedAnalysis]);

  useEffect(() => {
    if (props.requestStatus[asset?.container?.id]) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.requestStatus, asset?.container?.id]);
  useEffect(() => {
    const panel = props.panelOptions.active;
    const isTabActive = panel === 'files' || panel === 'asset' || panel === 'notes';
    const fileLengthCheck = Array.isArray(files) && !files?.length;
    const notesLengthCheck = Array.isArray(notes) && !notes?.length;
    if (props.selectedFeeders.length > 0) {
      if (isTabActive && props.selectedNode?.id && files && notes) {
        props.setNotifications(prevState => ({
          ...prevState,
          filesNotification: !fileLengthCheck,
          notesNotification: !notesLengthCheck,
        }));
        props.setItemCount(prevState => ({
          ...prevState,
          numberOfNotes: !notesLengthCheck ? notes?.length : null,
          numberOfFiles: !fileLengthCheck ? files?.length : null,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    files,
    notes,
    notes?.length,
    files?.length,
    props.panelOptions.active,
    props.selectedNode,
    props.selectedFeeders,
  ]);
  const handleSaveEdit = ({
    assetType,
    field,
    editedKey = undefined,
    value = undefined,
    isAttribute = false,
    attributeFieldName = 'attributes',
  }) => {
    const editedField = editedKey || field;
    if (!edited[editedField]) return;

    const { workspace, branch } = props;
    const data = { [field]: value || newPanelValues[editedField] };
    const body = isAttribute ? { [attributeFieldName]: data } : data;
    // Allow only name to be empty
    const shouldUpdate = field === 'name' ? true : data[field] !== undefined;

    if (shouldUpdate) {
      props.editActions.editSingleEquipment(
        workspace,
        branch,
        assetType,
        props.selectedNode.id,
        body,
      );
    }
  };

  const _renderTabs = () => {
    const PanelValues = Templates[props.selectedNode.class];
    const canAccessAttachments =
      !props.isAuthEnabled || props.permissions.has('view_asset_attachments');
    const canAccessNotes = !props.isAuthEnabled || props.permissions.has('view_asset_notes');
    const PanelContent = (
      <CustomScrollBar>
        {props.activeTab === 'asset' && (
          <PanelValues
            asset={asset}
            workspace={props.workspace}
            branch={props.branch}
            displayBranch={props.displayBranch}
            feeders={props.feeders}
            edited={edited}
            selected={props.selectedNode}
            applyDifferenceModelRequest={props.applyDifferenceModelRequest}
            toggleFeederPanel={props.toggleFeederPanel}
            setActivePanel={props.setActivePanel}
            timeBarZoomLevel={props.timeBarZoomLevel}
            timeRange={props.timeRange}
            maxRange={props.maxRange}
            permissions={props.permissions}
            isAuthEnabled={props.isAuthEnabled}
            loadForecast={props.loadForecast}
            actions={props.actions}
            editActions={props.editActions}
            theme={theme}
            inEditMode={inEditMode}
            canEditNetwork={canEditNetwork}
            saveEdit={handleSaveEdit}
            expanded={props.panelOptions.expanded}
            selectedFeeders={props.selectedFeeders}
            feeder={asset?.container?.id}
            setSelectedAssetID={props.actions.setSelectedAssetID}
            allFeeders={props.allFeeders}
          />
        )}

        {!inEditMode && props.loadForecast.selectedAnalysis && props.activeTab === 'results' && (
          <ResultsPanel
            assetID={props.selectedNode.id}
            assetClass={props.selectedNode.class}
            asset={asset}
            branch={props.displayBranch}
            expanded={props.panelOptions.expanded}
            maxRange={props.maxRange}
            timeRange={props.timeRange}
            timeBarZoomLevel={props.timeBarZoomLevel}
            newPanelValues={newPanelValues}
            scenarioID={props.loadForecast.selectedScenario}
            analysisName={props.loadForecast.selectedAnalysis?.name}
            theme={theme}
            workspace={props.workspace}
          />
        )}

        {props.activeTab === 'notes' && canAccessNotes && (
          <NotesPanel
            assetID={props.selectedNode.id}
            branch={props.displayBranch}
            inEditMode={props.inEditMode}
            permissions={props.permissions}
            theme={theme}
            workspace={props.workspace}
          />
        )}
        {props.activeTab === 'files' && canAccessAttachments && (
          <FilesPanel
            assetID={props.selectedNode.id}
            branch={props.branch}
            permissions={props.permissions}
            theme={theme}
            workspace={props.workspace}
          />
        )}
      </CustomScrollBar>
    );
    const isLoading = loadingState === asyncStates.INITIAL || loadingState === asyncStates.LOADING;
    const didError = loadingState === asyncStates.ERROR;
    if (isLoading && !asset) return <AssetPanelLoading />;
    if (didError || !asset) return <AssetPanelError retry={refetch} />;
    return <>{PanelContent}</>;
  };

  // Get the correct template for the selected node

  return (
    <div className="asset-panel asset-panel-container">
      <AssetDetailsHeader
        inEditMode={inEditMode}
        canEditNetwork={canEditNetwork}
        asset={asset}
        selectedNode={selectedNode}
        showModal={props.showModal}
        editActions={props.editActions}
        actions={props.actions}
        editValues={editValues}
        panelOptions={props.panelOptions}
        loadingState={loadingState}
        newPanelValues={newPanelValues}
        errors={errors}
        edited={edited}
        setNewPanelValues={setNewPanelValues}
        setEdited={setEdited}
        handleSaveEdit={handleSaveEdit}
        toggleFeederPanel={props.toggleFeederPanel}
        workspace={props.workspace}
        branch={props.branch}
      />
      <div className="asset-panel-instance-info">{_renderTabs()}</div>
    </div>
  );
};

AssetPanel.propTypes = {
  // From Global State
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  selectedNode: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  feeders: PropTypes.string.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  zoomToAsset: PropTypes.func.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  SV: PropTypes.object.isRequired,
  setActivePanel: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.oneOf(['hour', 'day', 'month', 'year']).isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  editActions: PropTypes.object.isRequired,
  loadForecast: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  panelOptions: PropTypes.object.isRequired,
  deleteStatus: PropTypes.number.isRequired,
  deleteError: PropTypes.object.isRequired,
  selectedFeeders: PropTypes.array.isRequired,
  requestStatus: PropTypes.object.isRequired,
  allFeeders: PropTypes.array.isRequired,
  activeTab: PropTypes.string.isRequired,
  setNotifications: PropTypes.func.isRequired,
  setItemCount: PropTypes.func.isRequired,
};

export default AssetPanel;
