import IdentifiedObject from '../IdentifiedObject';

class Agreement extends IdentifiedObject {
  constructor(id, cimDict) {
    super(id, cimDict);

    this._class = this.instance.class;
  }
}

export default Agreement;
