import SynchronousMachine from './SynchronousMachine';

class RunOfRiverHydro extends SynchronousMachine {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'river_hydro_plants';
    this._class = 'RunOfRiverHydro';
    this.displayName = 'Run of River Hydro';
  }
}

export default RunOfRiverHydro;
