import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CustomCheckbox from 'components/CustomCheckbox';
import Tooltip from 'components/Tooltip';
import ConfirmDelete from 'components/ConfirmDelete/ConfirmDelete';
import ModalPortal from 'components/ModalPortal';
import IconButton from 'components/IconButton';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import AttachmentIndicator from '../AttachmentIndicator';

const ChildContainerTile = ({
  container,
  isActive,
  isLoading,
  hasFailed,
  onClick,
  checked,
  theme,
  hasNotes,
  deleteContainer,
  containerList,
  deleteStatus,
  inEditMode,
  workspace,
  branch,
}) => {
  const { permissions } = useAuthContext();
  const active = isActive(container.id);
  const loading = isLoading(container.id);
  const failed = hasFailed(container.id);
  const [modalActive, setModalActive] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const deleteModalConfig = () => {
    const modalBody = item => ({
      message1: `Once ${item} has been deleted, it cannot be restored.`,
      message2: 'Would you like to permanently remove this item?',
    });
    if (typeof container === 'object') {
      const { type, id } = container;
      const config = {
        modalActive,
        theme,
        closeModal: () => setModalActive(false),
        deleteStatus,
        deletedItemDesc: type,
      };
      const feeders = type === 'Substation' && containerList.filter(f => f.substation === id);
      if (!feeders || !feeders.length) {
        return {
          ...config,
          deleteItem: () => deleteContainer(container, workspace, branch),
          modalBody: modalBody(`a ${type}`),
        };
      }
      return {
        ...config,
        deleteItem: null,
        closeModal: () => setModalActive(false),
        modalBody: {
          message1: 'This Substation has associated Feeders.',
          message2: 'Please delete those first.',
        },
      };
    }
    return {};
  };
  return (
    <div className="container-section">
      <Tooltip content={`${container.name || container.id} - ${container.type}`} theme={theme}>
        <button
          className={classNames('feeder-tile', {
            'feeder-tile--active': active,
          })}
          id={`button-${container.id}`}
          onMouseEnter={() => setShowDeleteButton(true)}
          onMouseLeave={() => setShowDeleteButton(false)}
          disabled={loading}
          key={container.id}
          type="button"
        >
          <div className="feeder-tile-contents">
            <button
              type="button"
              className="container-name-tile"
              onClick={() => onClick(container, permissions)}
            >
              <CustomCheckbox
                checked={checked || active}
                id={`checkbox-container-${container.id}`}
                type="primary"
              />
              <p className="feeder-name">
                {container.name || container.id}
                {hasNotes(container.id) && <AttachmentIndicator color="#4444a6" />}
              </p>
            </button>
            <div className="icon-section">
              {loading && (
                <i
                  className="material-icons rotate loading"
                  style={{ marginLeft: '-3px', color: '#ffffff' }}
                >
                  refresh
                </i>
              )}
              {showDeleteButton && inEditMode && (
                <IconButton
                  id="btn-delete-container"
                  icon="delete"
                  className="delete-container"
                  onClick={() => setModalActive(true)}
                  theme={theme}
                />
              )}
              {failed && (
                <span>
                  <i
                    title="Download Failed"
                    className="material-icons"
                    style={{ color: 'yellow', marginLeft: '-3px' }}
                  >
                    warning
                  </i>
                </span>
              )}
            </div>
          </div>
        </button>
        <ModalPortal id="modal-portal">
          <ConfirmDelete {...deleteModalConfig()} />
        </ModalPortal>
      </Tooltip>
    </div>
  );
};

ChildContainerTile.defaultProps = {
  checked: false,
  theme: 'light',
};

ChildContainerTile.propTypes = {
  container: PropTypes.object.isRequired,
  isActive: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  hasFailed: PropTypes.func.isRequired,
  hasNotes: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  theme: PropTypes.string,
  deleteContainer: PropTypes.func.isRequired,
  deleteStatus: PropTypes.number.isRequired,
  containerList: PropTypes.array.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
};

export default ChildContainerTile;
