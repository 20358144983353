import React from 'react';
import PropTypes from 'prop-types';
import PermissionDisabledTip from 'components/PermissionDisabledTip';

const NavigationLink = props => (
  <PermissionDisabledTip
    hide={!props.disabled}
    title={props.disabledMsg.title}
    message={props.disabledMsg.message}
  >
    <button
      className={`navigation-link ${props.theme}`}
      onClick={props.onClick}
      type="button"
      disabled={props.disabled}
    >
      {props.iconPath && (
        <span className="nav-icon-path">
          <img src={props.iconPath} alt={props.label} />
        </span>
      )}
      {props.icon && (
        <span className="nav-icon">
          <i className="material-icons" aria-hidden="true">
            {props.icon}
          </i>
        </span>
      )}
      <span className="nav-text">{props.label}</span>
    </button>
  </PermissionDisabledTip>
);

NavigationLink.defaultProps = {
  icon: null,
  iconPath: null,
  theme: 'light',
  disabled: false,
  disabledMsg: null,
};

NavigationLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string,
  iconPath: PropTypes.string,
  label: PropTypes.string.isRequired,
  theme: PropTypes.oneOf(['light', 'dark']),
  disabled: PropTypes.bool,
  disabledMsg: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
};

export default NavigationLink;
