import React, { FunctionComponent } from 'react';
import IconButton from 'components/IconButton';
import DropdownMenu from 'components/DropdownMenu';

import SelectType from './helpers';
import './DropdownSections.scss';

type DropdownMenuOption = {
  id: string;
  contents: string;
  tooltip: string;
  disabledMessage?: string;
  disabled: boolean;
  type: 'standard' | 'seperator';
};

type ActionMenuProps = {
  dropdownId: string;
  dropdownOnClick: (id: string | number) => void;
  menuOptions: DropdownMenuOption[];
  selectedType: SelectType;
  setSelectedType: (value: React.SetStateAction<SelectType>) => void;
};

const ActionMenu: FunctionComponent<ActionMenuProps> = ({
  dropdownId,
  dropdownOnClick,
  menuOptions,
  selectedType,
  setSelectedType,
}) => (
  <DropdownMenu
    id={dropdownId}
    contentPosition={{ right: '-1px', top: '31.5px', zIndex: 1001 }}
    onClick={dropdownOnClick}
    menuOptions={menuOptions}
    onToggle={() => {
      if (selectedType === SelectType.MENU) {
        setSelectedType(SelectType.NONE);
      } else if (selectedType === SelectType.DROPDOWN || selectedType === SelectType.NONE) {
        setSelectedType(SelectType.DROPDOWN);
      }
    }}
    open={selectedType === SelectType.DROPDOWN}
  >
    {(onClick: (event?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void) => (
      <IconButton
        id={`${dropdownId}-control-btn`}
        icon="more_horiz"
        className="expanded-menu"
        onClick={onClick}
        tooltip="More Navigation"
      />
    )}
  </DropdownMenu>
);

export default ActionMenu;
