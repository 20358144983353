import moment from 'moment';

/* eslint-disable import/prefer-default-export */
export function delay(milliseconds) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
}

// Callback to Array.prototype.sort to
// sort objects by name

export const sorter = Intl.Collator(undefined, {
  numeric: true, // make sure 10-foo comes after 2-bar
  sensitivity: 'base', // ignore case
});

export function sortByName(a, b) {
  return sorter.compare(a.name, b.name);
}

export function alphabetize(list) {
  return list.sort(sorter.compare);
}

export function alphabetizeByKey(list, key) {
  if (!list) return [];
  return list.sort((a, b) => sorter.compare(a[key], b[key]));
}

export function pluralize(word, total, ending = 's') {
  // very naive pluralization
  return total !== 1 ? `${word + ending}` : word;
}

export function getMultiplier(value) {
  const prefixes = {
    [-9]: 'n',
    [-6]: 'µ',
    [-3]: 'm',
    0: '',
    3: 'k',
    6: 'M',
    9: 'G',
    12: 'T',
  };

  let power = Math.floor(Math.log10(Math.abs(value)) / 3) * 3;
  power = Number.isFinite(power) ? power : 0;
  return { letter: prefixes[power], value: 10 ** power };
}

export const isDefined = val => val !== null && val !== undefined;
export const isDefinedNonEmpty = val => val !== null && val !== undefined && val !== '';
export const isDefinedNonZero = val => val !== null && val !== undefined && val !== 0;

export const isEmptyObject = objectVal => objectVal && Object.keys(objectVal).length === 0;

export function toISO(time, add = [0, 's']) {
  return moment
    .parseZone(time)
    .add(...add)
    .format('YYYY-MM-DDTHH:mm:ssZ');
}

const getTimeStep = times => {
  if (times.length === 1) {
    return moment.duration(1, 'hours');
  }

  // assume the minimum timestamp will be 1 year or less
  let minTimeStep = moment.duration(1, 'years');
  // ensure all date modification is done in UTC not localtime
  let storedMinTime = times[0];
  let prevTime = times[0];
  times.slice(1).forEach(time => {
    const newTime = time;
    const duration = moment.duration(time.diff(prevTime));
    if (duration < minTimeStep) {
      minTimeStep = duration;
      storedMinTime = prevTime;
    }
    prevTime = newTime;
  });

  const after = moment(storedMinTime).add(minTimeStep);
  if (after >= moment(storedMinTime).add(1, 'years')) {
    minTimeStep = moment.duration(1, 'years');
  } else if (after >= moment(storedMinTime).add(1, 'months')) {
    minTimeStep = moment.duration(1, 'months');
  } else if (after >= moment(storedMinTime).add(1, 'days')) {
    minTimeStep = moment.duration(1, 'days');
  } else if (after >= moment(storedMinTime).add(1, 'hours')) {
    minTimeStep = moment.duration(1, 'hours');
  }
  return minTimeStep;
};

/**
 * Fills in any timepoints which are missing
 * based on the minimum calculated timestep
 * @param {Array} datapoints the array of data to fill from
 * @param {String} timePropName  the propertyname for the timestamp
 */
export function addMissingTimepoints(datapoints, timePropName) {
  if (datapoints.length === 0) {
    return datapoints;
  }

  const timestep = getTimeStep(datapoints.map(d => moment.utc(d[timePropName])));
  const filledPoints = [];
  for (let i = 0; i < datapoints.length; i += 1) {
    const currentPoint = datapoints[i];
    filledPoints.push(currentPoint);
    if (datapoints.length > i + 1) {
      // ensure all date modification is done in UTC not localtime
      const nextTimePoint = moment.utc(datapoints[i + 1][timePropName]);
      const step = moment.utc(currentPoint[timePropName]);
      step.add(timestep);
      while (step.isBefore(nextTimePoint)) {
        filledPoints.push({ [timePropName]: toISO(step) });
        step.add(timestep);
      }
    }
  }
  return filledPoints;
}

export function isValidValue(value, validation, validationData) {
  const { customValidation, min, max } = validation;
  const validators = {
    customValidation: () => customValidation(value, validationData).valid,
    min: () => value >= min,
    max: () => value <= max,
    required: () => !!value || value === 0,
  };

  return Object.keys(validators).reduce((isValid, field) => {
    if (isValid && isDefined(validation[field])) return validators[field]();
    return isValid;
  }, true);
}

export function extendDataTimeRange(data, timeKey, start, end) {
  let missingStart = !!start;
  let missingEnd = !!end;
  let updated = data;

  data.forEach(point => {
    if (start && moment(point[timeKey]).isSameOrBefore(start)) {
      missingStart = false;
    }
    if (start && moment(point[timeKey]).isSameOrAfter(end)) {
      missingEnd = false;
    }
  });

  if (missingStart) {
    updated = [{ [timeKey]: toISO(start) }, ...updated];
  }
  if (missingEnd) {
    updated = [...updated, { [timeKey]: toISO(end) }];
  }

  return updated;
}

export const formatToHourMinute = value => moment(value).format('HH:mm');
export const flattenContainerTree = container =>
  container.children && container.children.length
    ? [container, ...Array.concat(...container.children.map(flattenContainerTree))]
    : [container];

export const graphHeight = expanded => (expanded ? 350 : 250);

export const chunkArray = (array, chunkSize) => {
  const chunks = [];
  const arrayCopy = Array.from(array);
  while (arrayCopy.length > 0) {
    chunks.push(arrayCopy.splice(0, chunkSize));
  }
  return chunks;
};

export const filterObject = (object, keys) => {
  const obj = { ...object };
  keys.forEach(key => {
    delete obj[key];
  });
  return obj;
};

export const range = (start, stop, step = 1) => {
  const arr = [];
  for (let i = start; i < stop; i += step) {
    arr.push(i);
  }
  return arr;
};

export const displayAsBuilt = branchName => (branchName === 'master' ? 'As Built' : branchName);

export const getPercent = (TotalValue = 1, value = 0) =>
  `${(TotalValue ? (value * 100) / TotalValue : 0).toFixed(2).replace(/\.00$/, '')}%`;

export const disableMasterWithImportON = (branch, automaticImport = false) =>
  branch === 'master' && automaticImport;

export const handleTooltipMessageForWorkspace = (
  action,
  canBeCreated,
  isEditMode,
  canBeDeleted,
) => {
  let message = '';
  if (action === 'delete') {
    if (canBeDeleted && !isEditMode) message = 'Delete workspace';
    else if (!canBeDeleted) message = 'You do not have delete permission';
    else message = 'You cannot delete workspace in edit mode';
  } else if (action === 'add') {
    if (canBeCreated && !isEditMode) message = 'Create workspace ';
    else if (!canBeCreated) message = 'You do not have create permission';
    else message = 'You cannot create workspace in edit mode';
  } else if (action === 'edit') {
    if (!canBeCreated) message = 'You do not have edit permission';
    else message = 'Edit workspace';
  } else {
    message = '';
  }

  return message;
};

export const handleTooltipMessageForScenario = (action, canBeCreated, scenarioProps) => {
  let message = '';
  if (action === 'add') {
    if (canBeCreated) message = 'Create Scenario';
    else message = 'You do not have permission to create scenario';
  } else if (action === 'edit') {
    if (scenarioProps) message = 'Edit scenario';
    else if (!scenarioProps) message = 'Select scenario to edit';
    else message = 'You do not have permission to edit scenario';
  } else if (action === 'delete') {
    if (scenarioProps) message = 'Delete scenario';
    else if (!scenarioProps) message = 'Select scenario to delete';
    else message = 'You do not have permission to delete scenario';
  } else {
    message = '';
  }

  return message;
};

export const handleTooltipMessageForNetworkVersion = (
  action,
  canBeCreated,
  isEditMode,
  canBeDeleted,
  canDownLoad,
  branchName,
) => {
  let message = '';
  if (action === 'add') {
    if (canBeCreated) message = 'Create network version';
    else message = 'You do not have create permission';
  } else if (action === 'delete') {
    if (branchName === 'master' && canBeDeleted && isEditMode)
      message = 'You cannot delete in edit mode';
    else if (!(branchName !== 'master' && canBeDeleted))
      message = 'You do not have delete permission';
    else message = 'Delete network version';
  } else if (action === 'download') {
    if (!canDownLoad) message = 'You do not have download permission';
    else if (isEditMode) message = 'You cannot download in edit mode';
    else message = 'Download network version';
  } else {
    message = '';
  }
  return message;
};
