import IdentifiedObject from '../IdentifiedObject';

class AssetInfo extends IdentifiedObject {
  constructor(id, cimDict) {
    super(id, cimDict);

    this._class = this.instance.class;
  }

  get AssetModel() {
    const assetModelId = this.references['AssetInfo.AssetModel'];
    return assetModelId ? this.cimDict[assetModelId] : null;
  }

  get inUse() {
    return !!this.references['AssetInfo.PowerSystemResources'];
  }
}

export default AssetInfo;
