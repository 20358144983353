import { CimClassAggregation, CimInstanceLookup, CimJSONObject, CimObjectMap } from '../cim';

export const generateName = (
  item: CimJSONObject,
  root: CimClassAggregation,
  attribute: string,
): string => {
  // Determine what name to display for each instance
  let name = '';
  if (item.attributes && item.attributes[attribute]) {
    name = `${item.class} ${item.attributes[attribute]}`;
  } else {
    const instanceNumber = root && root.instanceList ? root.instanceList.length + 1 : 1;
    name = `${item.class} ${instanceNumber}`;
  }
  return name;
};

/**
 * Determine the status of the attributes container. Used to change header color.
 * @param  {object}  selectedInstance  currently selected instance
 */
export const getAttributeStatus = (selectedInstance: CimJSONObject): string => {
  if (selectedInstance && selectedInstance.attributes) {
    return Object.keys(selectedInstance.attributes).length > 0 ? 'active' : 'inactive';
  }
  return 'inactive';
};

export const buildInstanceLookup = (objects: CimObjectMap): CimInstanceLookup => {
  const map: CimInstanceLookup = {};
  const nameAttribute = 'IdentifiedObject.name';

  const objectList = Object.keys(objects);
  objectList.forEach(id => {
    const data = objects[id];
    const className = data.class;
    const name = generateName(data, map[className], nameAttribute);

    if (map[className]) {
      map[className].instanceList.push({ id, name, data });
    } else {
      map[className] = {
        id: className,
        name: className,
        instanceList: [{ id, name, data }],
      };
    }
  });

  return map;
};
