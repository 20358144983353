import React, { FunctionComponent, useState, useEffect, useMemo, useRef, useCallback } from 'react';
import flatpickr from 'flatpickr';
import moment from 'moment';
import IconButton from 'components/IconButton';
import { ThemeProp } from 'types';
import { DateOption } from 'flatpickr/dist/types/options';

/*
 * Creates a date picker instance that has forward and back buttons for the dates
 * and an input that triggers the calendar to open. The default is to allow selection
 * of both date and time
 */

// custom constructor hook
const useConstructor: (callBack?: () => void) => void = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

type DatePickerProps = {
  onChange: (value: moment.Moment) => void;
  date?: moment.Moment; // Controlled Component Date (moment obj)
  defaultDate?: moment.Moment; // Default date only (moment obj)
  disabled?: boolean;
  options?: {
    value?: string;
    minDate?: string | number;
    maxDate?: string | number;
    minDateLimit?: string | number;
    enableTime?: boolean;
    noCalendar?: boolean;
    dateFormat?: string;
    time_24hr?: boolean;
    defaultDate?: DateOption;
    minTime?: string;
    maxTime?: string;
  };
  theme?: ThemeProp;
  showArrows?: boolean;
  dateFormat?: string; // Flatpickr date format string
  useUTC?: boolean;
  clearable?: boolean;
  type?: 'primary' | 'default';
  id?: string;
};

const DatePicker: FunctionComponent<DatePickerProps> = ({
  date = null,
  defaultDate = null,
  disabled = false,
  options,
  theme = 'light',
  showArrows = true,
  dateFormat = 'F j, Y',
  useUTC = true,
  clearable = false,
  type = 'default',
  id = 'date-picker',
  onChange,
}) => {
  const [dateState, setDate] = useState<moment.Moment | null>(defaultDate);
  const dateInput: any = useRef(null);

  useConstructor(() => {
    let defaultDate_ = date;

    if (!defaultDate_) {
      // if uncontrolled use the default date
      defaultDate_ = defaultDate;

      if (!defaultDate_ && !clearable) {
        // if there is no default and its not clearable, set it to now
        defaultDate_ = useUTC ? moment.utc() : moment();
      }
    }
    setDate(defaultDate_);
  });

  const handleDateChange = useCallback(
    (dateValue: moment.Moment) => {
      setDate(dateValue);
      onChange(dateValue);
    },
    [onChange, setDate],
  );

  const createDateField = useMemo(
    () => (inputDate: moment.Moment | null) => {
      const currentValue = moment(inputDate);
      let minDate = moment(options?.minDate);
      const maxDate = moment(options?.maxDate);
      const minDateLimit = moment(options?.minDateLimit);
      const now = new Date(currentValue?.format());
      const timezoneOffsetInMinutes = now?.getTimezoneOffset();

      if (currentValue < minDate) {
        minDate = currentValue;
      }

      if (useUTC) {
        currentValue.add(timezoneOffsetInMinutes, 'm');
      }

      let options_ = { ...options };

      if (options?.minDate) {
        if (useUTC) {
          maxDate.add(timezoneOffsetInMinutes, 'm');
          minDate.add(timezoneOffsetInMinutes, 'm');
        }
        options_ = {
          ...options,
          minDate: minDate.valueOf(),
          maxDate: maxDate.valueOf(),
        };
      }
      if (options?.minDateLimit) {
        if (useUTC) {
          minDate.add(timezoneOffsetInMinutes, 'm');
        }
        options_ = {
          ...options_,
          minDate: minDateLimit.valueOf(),
        };
      }

      if (dateInput.current) {
        flatpickr(dateInput.current, {
          time_24hr: true,
          minuteIncrement: 1,
          dateFormat,
          onChange: date_ => {
            const newDate = moment(date_[0]);
            handleDateChange(newDate);
          },
          defaultDate: currentValue.valueOf(),
          ...options_,
        });
      }

      dateInput?.current?._flatpickr?.calendarContainer.classList.add(`flatpickr-${theme}`);
      dateInput?.current?._flatpickr?.calendarContainer.classList.add(
        'DatePicker-calendar-instance',
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, useUTC, handleDateChange, dateFormat, theme],
  );

  useEffect(() => {
    createDateField(dateState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (options) {
      Object.entries(options).forEach((key: any) => {
        dateInput?.current?._flatpickr?.set(key, { options: key });
      });
    }
  }, [options]);

  const addDate = () => {
    const newDate = moment(dateState).add(1, 'd');
    const maxDate = dateInput?.current?._flatpickr?.config._maxDate;

    // Ensure that we do not go past maxDate if set...
    if (maxDate !== undefined && newDate.isAfter(maxDate, 'day')) {
      return;
    }
    handleDateChange(newDate);
  };

  // Subtract one date from the current date
  const subtractDate = () => {
    const newDate = moment(dateState).subtract(1, 'd');
    handleDateChange(newDate);
  };

  return (
    <div className={`date-picker ${theme} ${type}`}>
      {showArrows && (
        <button
          className="date-picker__btn"
          onClick={subtractDate}
          disabled={disabled}
          type="button"
        >
          <i className="material-icons" aria-hidden="true">
            chevron_left
          </i>
        </button>
      )}
      <input
        className="date-picker__input"
        id={id}
        name="startDate"
        disabled={disabled}
        ref={div => {
          dateInput.current = div;
        }}
      />
      {clearable && (
        <IconButton
          id="clear"
          onClick={() => dateInput?.current?._flatpickr?.clear()}
          icon="close"
          className="close"
          theme={theme}
          tooltip="Clear date"
        />
      )}
      {showArrows && (
        <button className="date-picker__btn" onClick={addDate} disabled={disabled} type="button">
          <i className="material-icons" aria-hidden="true">
            chevron_right
          </i>
        </button>
      )}
    </div>
  );
};

export default DatePicker;
