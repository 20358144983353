/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Legend, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';

import Card from 'components/Card';

import ThemeContext from 'helpers/ThemeContext';

import './ValidationDashboard.scss';

const renderTooltip = ({ payload }) => {
  if (!payload.length) {
    return null;
  }

  return <div className="dashboard-tooltip">{`${payload[0].name}: ${payload[0].value}`}</div>;
};

const ValidationDashboard = ({ branch, types, workspace }) => {
  const theme = useContext(ThemeContext);

  return (
    <div className="validation-dashboard">
      {types.map(type => {
        const data = [
          { fill: 'rgb(255, 99, 132)', name: 'Error', value: type.errorCount },
          { fill: 'rgb(255, 205, 86)', name: 'Warning', value: type.warningCount },
          {
            fill: 'rgb(75, 192, 192)',
            name: 'Valid',
            value: type.count - type.warningCount - type.errorCount,
          },
        ];

        return (
          <Card
            className="dashboard-card"
            key={type.name}
            theme={theme}
            title={
              <Link to={`/${workspace}/${branch}/import/validate/type/${type.id}`}>
                {type.name}
              </Link>
            }
          >
            <div className="dashboard-card-contents">
              <ResponsiveContainer height="100%" width="100%">
                <PieChart>
                  <Pie
                    cx="50%"
                    cy="50%"
                    data={data}
                    endAngle={450}
                    startAngle={90}
                    stroke={theme === 'light' ? '#ffffff' : '#262626'}
                  />
                  <Tooltip content={renderTooltip} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

ValidationDashboard.propTypes = {
  branch: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.object).isRequired,
  workspace: PropTypes.string.isRequired,
};

export default ValidationDashboard;
