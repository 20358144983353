import React, { useContext } from 'react';
import nullable from 'helpers/nullablePropType';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'components/Select';
import asyncStates from 'helpers/asyncActionStates';
import ThemeContext from 'helpers/ThemeContext';
import { pu } from 'helpers/units';
import { BETA_PERMISSION } from 'helpers/permissions';
import AssetSchedule from '../partials/AssetSchedule';
import EditableControlMode from '../partials/EditableControlMode';
import LifecycleSection from '../partials/LifecycleSection';
import ShuntDeviceHeader from '../partials/ShuntDeviceHeader';
import InstanceInfo from './InstanceInfo';

import './LinearShuntCompensator.scss';

const controlPanelValues = {
  locallyControlled: [
    {
      id: 'targetValue',
      label: 'Target Voltage',
      unit: pu,
      type: 'number',
      step: '0.001',
      min: 0,
    },
  ],
};

const shuntCompensatorTypeMode = [
  { value: true, label: 'Balanced' },
  { value: false, label: 'Unbalanced' },
];

const controlHelp = {
  globallyOptimized:
    "During powerflow and timeseries powerflow, capacitors set to 'global' are always connected.",
  uncontrolled:
    "During powerflow and timeseries powerflow, capacitors set to 'no control' are always connected.",
  locallyControlled:
    "Capacitors in 'local' mode try to turn on/off in order to move voltage at their node towards the target",
};

function LinearShuntCompensator({
  asset: shuntCompensator,
  inEditMode,
  canEditNetwork,
  expanded,
  selected: { id, class: assetClass },
  applyDifferenceModelRequest,
  editActions,
  workspace,
  branch,
  displayBranch,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  loadForecast: { selectedScenario, selectedScenarioType, selectedAnalysis },
  setSelectedAssetID,
  toggleFeederPanel,
  isAuthEnabled,
  permissions,
}) {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const saveReq = async body =>
    editActions.editSingleEquipment(workspace, branch, 'capacitor', id, body);
  const controlMode = shuntCompensator.analysis_control.mode;
  const controlModeAttributes = {
    ...shuntCompensator.regulating_control_attributes,
  };

  return (
    <div
      className={classNames({
        'asset-panel-values': true,
        'asset-panel-values--expanded': expanded,
        'linear-shunt-compensator': true,
      })}
    >
      <div className="header-container">
        <ShuntDeviceHeader
          asset={shuntCompensator}
          assetId={id}
          toggleFeederPanel={toggleFeederPanel}
          inEditMode={inEditMode}
          disabled={!canEditNetwork || saving}
          setSelectedAssetID={setSelectedAssetID}
          handleSave={saveReq}
          phaseOptions="many"
        />
        <Select
          name="mode"
          className="custom-react-select-theme"
          value={shuntCompensator.balanced}
          disabled={
            !canEditNetwork ||
            !inEditMode ||
            'ABC'.split('').filter(x => !shuntCompensator.phases.includes(x)).length !== 0 ||
            saving ||
            !canEditNetwork
          }
          placeholder="Unbalanced"
          options={shuntCompensatorTypeMode}
          onChange={({ value }) => saveReq({ balanced: value, phases: shuntCompensator.phases })}
          clearable={false}
          theme={theme}
        />
      </div>

      <hr className="section-divider" />

      <InstanceInfo
        workspace={workspace}
        displayBranch={displayBranch}
        disabled={!canEditNetwork || saving}
        asset={shuntCompensator}
        handleSave={saveReq}
        theme={theme}
        inEditMode={inEditMode}
      />

      {!inEditMode && permissions.has(BETA_PERMISSION) && (
        <>
          <hr className="section-divider" />
          <AssetSchedule
            id="liner-shunt-compensator-cost"
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Cost"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        </>
      )}

      <hr className="section-divider" />

      <EditableControlMode
        controlMode={controlMode}
        help={controlHelp[controlMode]}
        asset={assetClass}
        editableValues={controlPanelValues[controlMode]}
        attributes={controlModeAttributes}
        disabled={saving || !canEditNetwork}
        onSave={saveReq}
        theme={theme}
        attributeTypeMapping={{
          targetValue: 'regulating_control_attributes',
        }}
        inEditMode={inEditMode}
        selectedAnalysis={selectedAnalysis}
        selectedAssetId={id}
        workspace={workspace}
        branch={branch}
      >
        {controlMode === 'scheduled' && !inEditMode && (
          <AssetSchedule
            id="liner-shunt-compensator-normal"
            workspace={workspace}
            branch={branch}
            scenario={selectedScenario}
            scenarioType={selectedScenarioType}
            asset={{ id, class: assetClass }}
            scheduleType="Normal"
            timeRange={timeRange}
            maxRange={maxRange}
            timeBarZoomLevel={timeBarZoomLevel}
            panelValues={{
              isBalanced: shuntCompensator.balanced,
            }}
            editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
            expanded={expanded}
          />
        )}
      </EditableControlMode>

      <hr className="section-divider" />
      <LifecycleSection
        disabled={saving || !inEditMode || !canEditNetwork}
        lifecycle={shuntCompensator.lifecycle}
        onChange={lifecycle => saveReq({ lifecycle })}
        healthMetric={shuntCompensator.reliability_info?.healthMetric}
        onHealthMetricChange={saveReq}
      />
    </div>
  );
}

LinearShuntCompensator.propTypes = {
  asset: PropTypes.object.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  editActions: PropTypes.object.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  timeRange: PropTypes.shape({
    start: nullable(PropTypes.object).isRequired,
    end: nullable(PropTypes.object).isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: nullable(PropTypes.object).isRequired,
    end: nullable(PropTypes.object).isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
    selectedAnalysis: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default LinearShuntCompensator;
