import React from 'react';
import { string } from 'prop-types';

export default function AttachmentIndicator({ color }) {
  return (
    <i className="material-icons" style={{ fontSize: '12px', marginLeft: '10px', color }}>
      fiber_manual_record
    </i>
  );
}

AttachmentIndicator.propTypes = {
  color: string.isRequired,
};
