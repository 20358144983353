/* eslint-disable react/require-default-props, jsx-a11y/mouse-events-have-key-events */
import React, { useMemo, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Select from 'components/Select';
import IconButton from 'components/IconButton';
import Tooltip from 'components/Tooltip';
import Card from 'components/Card';
import DocumentationLink from 'components/DocumentationLink';
import IconFileInput from 'components/IconFileInput';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import UploadButton from 'components/UploadButton';
import nullable from 'helpers/nullablePropType';
import ThemeContext from 'helpers/ThemeContext';
import {
  kVAr,
  kW,
  kVArh,
  kWh,
  MW,
  MVAr,
  percent,
  gramPerkWh,
  none,
  scaleFactorForUnit,
} from 'helpers/units';
import {
  Line,
  Area,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
  ReferenceLine,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Dot,
} from 'recharts';
import { toISO, isDefined } from 'helpers/utils';
import { getStartOfInterval } from 'components/ZoomableRangeSlider/intervals';
import _ from 'lodash';
import PhaseBadgeContainer from './PhaseBadgeContainer';
import ScheduleValidations from './ScheduleValidations';
import './SchedulePanel.scss';

const uploadHelp = 'Add/replace schedule data';
const deleteHelp = 'Delete all schedule data';
const downloadHelp = 'Download schedule data';

const process_timeranges = (timepoints, maxRange, variables) => {
  const dataDict = {};
  const suffixedVariables = {};

  const startPoint = moment.utc(maxRange.start);
  const endPoint = moment.utc(maxRange.end).startOf('hour');
  // the bucket size isn't used for aggregation but we need to know the scale for the graph
  let bucketSize = 'hour';
  if (endPoint.diff(startPoint, 'years') >= 3) {
    bucketSize = 'year';
  } else if (endPoint.diff(startPoint, 'months') >= 3) {
    bucketSize = 'month';
  } else if (endPoint.diff(startPoint, 'days') >= 3) {
    bucketSize = 'day';
  }
  // loop through all the points in the range and prepopulate them
  for (
    let fillPt = moment.utc(startPoint);
    fillPt.isBefore(endPoint);
    fillPt = moment.utc(fillPt).add(1, bucketSize)
  ) {
    const isoFillPt = toISO(fillPt);
    dataDict[isoFillPt] = {
      timestamp: isoFillPt,
      fakeDatapoint: true,
    };
  }
  // now add in the real data points
  for (let i = 0; i < timepoints.length; i += 1) {
    const currentRange = timepoints[i];
    let currentRangeEndTimestamp = currentRange.end_timestamp;
    if (
      toISO(moment(currentRange.end_timestamp)) ===
      toISO(moment(currentRange.end_timestamp).endOf('minute'))
    ) {
      currentRangeEndTimestamp = toISO(
        moment(currentRange.end_timestamp).add(1, 'minute').startOf('minute'),
      );
    }
    if (dataDict[currentRange.timestamp] === undefined) {
      dataDict[currentRange.timestamp] = {
        timestamp: currentRange.timestamp,
      };
    } else {
      dataDict[currentRange.timestamp].fakeDatapoint = false; // not fake anymore
    }
    if (dataDict[currentRangeEndTimestamp] === undefined) {
      dataDict[currentRangeEndTimestamp] = {
        timestamp: currentRangeEndTimestamp,
      };
    } else {
      dataDict[currentRangeEndTimestamp].fakeDatapoint = false; // not fake anymore
    }
    Object.entries(variables).forEach(([name]) => {
      if (currentRange[name] !== undefined) {
        const suffixedName = `${name}_NUM${i + 1}`;
        if (suffixedVariables[suffixedName] === undefined) {
          suffixedVariables[suffixedName] = name;
        }
        const currentRangeEnd = moment.utc(currentRangeEndTimestamp).add(1, 'minute');
        for (
          let localTimepoint = moment.utc(currentRange.timestamp);
          localTimepoint.isBefore(currentRangeEnd);
          localTimepoint = moment.utc(localTimepoint).add(1, bucketSize).startOf('hour')
        ) {
          const isoLocalTimepoint = toISO(localTimepoint);
          if (
            dataDict[isoLocalTimepoint] &&
            !localTimepoint.isAfter(moment.utc(currentRangeEndTimestamp))
          ) {
            dataDict[isoLocalTimepoint][suffixedName] = currentRange[name];
          }
        }
        // when endRange falls in between any ranges and not based on bucket size
        if (
          moment.utc(currentRange.timestamp).isBefore(currentRangeEnd) &&
          !_.findKey(dataDict[currentRangeEndTimestamp], key => _.startsWith(key, `${name}_NUM`))
        ) {
          dataDict[currentRangeEndTimestamp][suffixedName] = currentRange[name];
        }
      }
    });
  }
  const dataArray = Object.values(dataDict);
  dataArray.sort((a, b) => (a.timestamp < b.timestamp ? -1 : 1));
  const suffixedVariablesArray = Object.keys(suffixedVariables);
  suffixedVariablesArray.sort();

  return {
    data: dataArray,
    columns: suffixedVariablesArray,
    aggregation: 'hour',
    integerYValues: false,
    booleanYValues: false,
    zeroOneYValues: false,
  };
};

const process_timepoints = (timepoints, maxRange, variables, timeBarZoomLevel) => {
  if (!timepoints || timepoints.length === 0) {
    return {
      data: [],
      columns: [],
      aggregation: 'hour',
    };
  }

  const shouldPad = timepoints.length && !!maxRange;
  let missingStart = shouldPad && !!maxRange.start;
  let missingEnd = shouldPad && !!maxRange.end;

  let data = [];
  const lastValueForAggBucket = {};
  timepoints.forEach(m => {
    const timestamp = toISO(m.timestamp);
    if (shouldPad && maxRange.start && moment(timestamp).isSameOrBefore(maxRange.start)) {
      missingStart = false;
    }
    if (
      shouldPad &&
      maxRange.end &&
      moment(timestamp).isSameOrAfter(moment(maxRange.end).startOf('hour'))
    ) {
      missingEnd = false;
    }
    let newM;
    Object.keys(m).forEach(key => {
      const v = m[key];
      if (key !== 'timestamp') {
        if (typeof v !== 'number') {
          if (newM === undefined) {
            newM = {};
          }
          newM[`${key}:range`] = [v.min, v.max];
          newM[key] = v.avg;
        }
        if (lastValueForAggBucket[key] === undefined) {
          lastValueForAggBucket[key] = {};
        }
        if (typeof v === 'number') {
          lastValueForAggBucket[key][timestamp] = v;
        }
        lastValueForAggBucket[key][timestamp] = v.last;
      }
    });
    if (newM) {
      data.push({ ...newM, timestamp });
    } else {
      data.push({ ...m, timestamp });
    }
  });
  const columns = new Set();
  for (let i = 0; i < data.length; i += 1) {
    Object.keys(data[i]).forEach(column => columns.add(column));
  }

  if (columns.has('timestamp')) {
    columns.delete('timestamp');
  }

  const bucketSize = timeBarZoomLevel;
  let isAggregated = false;
  Object.entries(variables).forEach(([, info]) => {
    if (info.variable_type === 'aggregated') {
      isAggregated = true;
    }
  });

  if (missingStart) {
    const startPoint = { timestamp: toISO(maxRange.start) };
    Object.entries(variables).forEach(([name, info]) => {
      if (info.maintainsValue && isDefined(info.initialValue)) {
        startPoint[name] = info.initialValue;
        if (info.variable_type === 'aggregated') {
          startPoint[`${name}:range`] = [info.initialValue, info.initialValue];
        }
        startPoint.fakeDatapoint = true;
      }
    });
    data = [startPoint, ...data];
  }
  if (missingEnd) {
    const lastPoint = { timestamp: toISO(moment(maxRange.end).startOf('hour')) };
    Object.entries(variables).forEach(([name, info]) => {
      if (info.maintainsValue) {
        lastPoint[name] = data[data.length - 1][name];
        if (info.variable_type === 'aggregated') {
          const lastVal = lastValueForAggBucket[name][data[data.length - 1]?.timestamp];
          lastPoint[`${name}:range`] = [lastVal, lastVal];
          lastPoint[name] = lastVal;
        }
        lastPoint.fakeDatapoint = true;
      }
    });
    data = [...data, lastPoint];
  }

  {
    // fill in missing points
    const filledPoints = [];
    for (let i = 0; i < data.length; i += 1) {
      const currentPoint = data[i];
      filledPoints.push(currentPoint);
      if (data.length > i + 1) {
        // ensure all date modification is done in UTC not localtime
        const nextTimePoint = moment.utc(data[i + 1].timestamp);
        const step = moment.utc(currentPoint.timestamp);
        if (['Min_5', 'Min_15', 'Min_30'].includes(bucketSize)) {
          step.add(parseInt(bucketSize.replace('Min_', ''), 10), 'minute');
        } else {
          step.add(1, bucketSize);
        }
        let newPoint;
        while (step.isBefore(nextTimePoint)) {
          if (newPoint === undefined) {
            newPoint = {};
            if (isAggregated) {
              newPoint = Object.entries(variables).reduce((prev, [name, info]) => {
                if (info.variable_type === 'aggregated') {
                  const lastValTime = toISO(moment(currentPoint.timestamp));
                  const lastVal = lastValueForAggBucket[name][lastValTime];
                  prev[`${name}:range`] = [lastVal, lastVal];
                  prev[name] = lastVal;
                  prev.fakeDatapoint = true;
                }
                return prev;
              }, {});
            }
          }
          newPoint.timestamp = toISO(step);
          filledPoints.push(newPoint);
          if (['Min_5', 'Min_15', 'Min_30'].includes(bucketSize)) {
            step.add(parseInt(bucketSize.replace('Min_', ''), 10), 'minute');
          } else {
            step.add(1, bucketSize);
          }
          newPoint = {};
        }
      }
    }
    data = filledPoints;
  }

  const integerYValues =
    columns.has('normalStep') ||
    columns.has('normalStep_A') ||
    columns.has('normalStep_B') ||
    columns.has('normalStep_C');

  const booleanYValues =
    columns.has('normalSections') ||
    columns.has('normalSections_A') ||
    columns.has('normalSections_B') ||
    columns.has('normalSections_C') ||
    columns.has('closed') ||
    columns.has('closed_A') ||
    columns.has('closed_B') ||
    columns.has('closed_C');

  // all values between 0 and 1
  const zeroOneYValues = columns.has('lowerSOCLimit') || columns.has('upperSOCLimit');

  return {
    data,
    columns: [...columns],
    integerYValues,
    booleanYValues,
    zeroOneYValues,
    aggregation: bucketSize,
  };
};

const displayInfoForVariable = {
  p: { displayName: 'P', lineStyle: 'solid' },
  q: { displayName: 'Q', lineStyle: 'dashed' },
  normalStep: { displayName: 'Tap Position', lineStyle: 'solid' },
  normalSections: { displayName: 'State', lineStyle: 'solid' },
  closed: { displayName: 'Switch Status', lineStyle: 'solid' },
  voltageMagnitude: { displayName: 'Operating Voltage', lineStyle: 'solid' },
  lowerSOCLimit: { displayName: 'Minimum SoC', lineStyle: 'dashed' },
  upperSOCLimit: { displayName: 'Maximum SoC', lineStyle: 'solid' },
  activeEnergyCost: { lineStyle: 'solid' },
  reactiveEnergyCost: { lineStyle: 'dashed' },
  activePowerCost: { lineStyle: 'solid' },
  reactivePowerCost: { lineStyle: 'dashed' },
  co2emissionRate: { lineStyle: 'solid' },
  co2emissionCost: { lineStyle: 'solid' },
  load: { displayName: 'Load' },
  load_pf: { displayName: 'Load PF' },
  generation: { displayName: 'Generation', lineStyle: 'dashed' },
  generation_pf: { displayName: 'Generation PF' },
  tapChangeCost: { displayName: 'Operation Cost' },
  sectionChangeCost: { displayName: 'Operation Cost' },

  PF: { displayName: 'event ', lineStyle: 'solid' },
  totalBatteryCapacity: { displayName: 'event ', lineStyle: 'solid' },
  startSOC: { displayName: 'event ', lineStyle: 'solid' },
  pMax: { displayName: 'event ', lineStyle: 'solid' },
};

const getDisplayInfoForVariable = varName => {
  const splitName = varName.split('_NUM');
  let displayInfo;
  if (splitName.length === 1) {
    displayInfo = displayInfoForVariable[varName];
  } else {
    displayInfo = {
      ...displayInfoForVariable[splitName[0]],
      displayName: displayInfoForVariable[splitName[0]].displayName + splitName[1],
    };
  }

  return displayInfo ?? { displayName: 'unknown' };
};

const SchedulePanel = ({
  datapoints,
  maxRange,
  timeBarZoomLevel,
  scheduleType,
  expanded,
  panelValues,
  assetType,
  timeRange,
  error = '',
  header = '',
  showChart = false,
  variables = {},
  legend = '',
  editable = true,
  loading = false,
  handleScheduleDelete = null,
  handleScheduleUpload = null,
  handleScheduleDownload = null,
  scheduleDeleteDisabledMessage = null,
  scheduleUploadDisabledMessage = null,
  scheduleDownloadDisabledMessage = null,
  yAxisLabel = '',
  currencySymbol = '$',
  selectedAnalysis = null,
  currentAsset = {},
  analyses = [],
  setSelectedAnalysis = null,
  displayActionIcons = true,
}) => {
  const defaultColors = ['#ff6e6d', '#7ad520'];
  const theme = useContext(ThemeContext);
  const [activePhases, setActivePhases] = useState({ A: true, B: false, C: false });
  const handleActivePhaseChange = value => {
    setActivePhases(prevState => ({ ...prevState, ...value }));
  };
  const [hovered, setHovered] = useState(null);
  const processed_data = useMemo(() => {
    let data = null;
    if (scheduleType === 'Global') {
      data = process_timeranges(datapoints, maxRange, variables);
    } else {
      data = process_timepoints(datapoints, maxRange, variables, timeBarZoomLevel);
    }
    return data;
  }, [datapoints, maxRange, variables, timeBarZoomLevel, scheduleType]);

  const phaseColors = () => ({
    A: '#ff6e6e',
    B: '#2dafa8',
    C: '#fd813b',
    ABC: theme === 'dark' ? '#FFFFFF' : '#262626',
  });

  const getDataColor = (varName, phase) => {
    if (scheduleType === 'Global') {
      switch (Number(varName.slice(-1) - 1) % 4) {
        case 0:
          return '#ff6e6e';
        case 1:
          return '#2dafa8';
        case 2:
          return '#fd813b';
        default:
          return '#262626';
      }
    }
    const themeColor =
      getDisplayInfoForVariable(varName).lineStyle === 'dashed'
        ? defaultColors[0]
        : defaultColors[1];
    return phase && activePhases[phase] ? phaseColors()[phase] : themeColor;
  };

  const generateLine = (varName, varDisplayName, varUnit, dataKey, maintainsValue, phase) => {
    if (!varDisplayName) {
      varDisplayName = varUnit;
      varUnit = '';
    }
    if (scheduleType === 'Global') {
      varUnit = ''; // no units on lines in global mode
    }
    const name = `${varDisplayName}${varUnit ? ` (${varUnit})` : ''}`;
    return (
      <Line
        key={`line_${varDisplayName + phase}`}
        name={name}
        dataKey={dataKey}
        type="stepAfter"
        yAxisId={0}
        stroke={getDataColor(varName, phase)}
        strokeWidth={hovered === phase || hovered === name ? 3 : 2}
        connectNulls={maintainsValue}
        strokeDasharray={getDisplayInfoForVariable(varName).lineStyle === 'dashed' ? '5 5' : null}
        dot={dotProps =>
          dotProps.payload.fakeDatapoint ? null : <Dot {...dotProps} strokeDasharray={null} />
        }
        isAnimationActive={false}
      />
    );
  };

  const generateArea = (varName, varDisplayName, varUnit, dataKey, maintainsValue, phase) => {
    if (!varDisplayName) {
      varDisplayName = varUnit;
      varUnit = '';
    }
    const name = `${varDisplayName}${varUnit ? ` (${varUnit})` : ''}`;

    return (
      <Area
        key={`area_${varDisplayName}${phase}`}
        name={name}
        stroke="none"
        fill={getDataColor(varName, phase)}
        fillOpacity={0.25}
        dataKey={dataKey}
        type="stepAfter"
        yAxisId={0}
        connectNulls={false}
        strokeDasharray={getDisplayInfoForVariable(varName).lineStyle === 'dashed' ? '5 5' : null}
        isAnimationActive={false}
      />
    );
  };

  const generateLines = varNames => {
    const lines = [];
    const unitsForVariable = {
      p: kW,
      q: kVAr,
      normalStep: null,
      status: null,
      activeEnergyCost: kWh,
      reactiveEnergyCost: kVArh,
      activePowerCost: kW,
      reactivePowerCost: kVAr,
      co2emissionRate: gramPerkWh,
      co2emissionCost: `${currencySymbol}/g`,
      tapChangeCost: `${currencySymbol}/step`,
      sectionChangeCost: `${currencySymbol}/op`,
      PF: none,
      pMax: kW,
      startSOC: percent,
      totalBatteryCapacity: kWh,
      load: MW,
      generation: MVAr,
    };
    const scaleFactorForVariable = {
      p: scaleFactorForUnit(unitsForVariable.p),
      q: scaleFactorForUnit(unitsForVariable.q),
      load: scaleFactorForUnit(unitsForVariable.load),
      generation: scaleFactorForUnit(unitsForVariable.generation),
      activeEnergyCost: 1.0 / scaleFactorForUnit(unitsForVariable.activeEnergyCost),
      reactiveEnergyCost: 1.0 / scaleFactorForUnit(unitsForVariable.reactiveEnergyCost),
      activePowerCost: 1.0 / scaleFactorForUnit(unitsForVariable.activePowerCost),
      reactivePowerCost: 1.0 / scaleFactorForUnit(unitsForVariable.reactivePowerCost),
      co2emissionRate: scaleFactorForUnit(unitsForVariable.co2emissionRate),
      co2emissionCost: 1.0,
      tapChangeCost: 1.0,
      sectionChangeCost: 1.0,
      PF: scaleFactorForUnit(unitsForVariable.PF),
      pMax: scaleFactorForUnit(unitsForVariable.pMax),
      startSOC: scaleFactorForUnit(unitsForVariable.startSOC),
      totalBatteryCapacity: scaleFactorForUnit(unitsForVariable.totalBatteryCapacity),
    };
    varNames.forEach(varNameExtended => {
      let generateFn;
      let dataKeyFn;
      let isRange;
      let varName;
      if (varNameExtended.endsWith(':range')) {
        isRange = true;
        varName = varNameExtended.slice(0, -6);
      } else {
        varName = varNameExtended;
        isRange = false;
      }
      let nameSplit;
      let dataVar;
      let scaleFactor;
      if (scheduleType === 'Global') {
        const tempSplit = varName.split('_');
        nameSplit = [tempSplit[0]];
        dataVar = varName;
        scaleFactor = scaleFactorForVariable[tempSplit[0]] ?? 1;
      } else {
        nameSplit = varName.split('_');
        [dataVar] = nameSplit;
        scaleFactor = scaleFactorForVariable[nameSplit[0]] ?? 1;
      }
      if (isRange) {
        generateFn = generateArea;
        dataKeyFn = pt =>
          pt[varNameExtended] && [
            pt[varNameExtended][0] / scaleFactor,
            pt[varNameExtended][1] / scaleFactor,
          ];
      } else {
        generateFn = generateLine;
        dataKeyFn = pt => pt[varNameExtended] / scaleFactor;
      }
      if (nameSplit.length === 1) {
        lines.push(
          generateFn(
            dataVar,
            getDisplayInfoForVariable(dataVar).displayName,
            unitsForVariable[nameSplit[0]],
            dataKeyFn,
            scheduleType === 'Global' || (variables[varName] && variables[varName].maintainsValue),
          ),
        );
      } else if (activePhases[nameSplit[1].replace(':range', '')]) {
        lines.push(
          generateFn(
            dataVar,
            getDisplayInfoForVariable(dataVar).displayName,
            unitsForVariable[nameSplit[0]],
            dataKeyFn,
            variables[varName] && variables[varName].maintainsValue,
            nameSplit[1].replace(':range', ''),
          ),
        );
      }
    });
    return lines;
  };

  const renderLegend = legendProps => {
    if (scheduleType === 'Global') {
      return <div />;
    }
    const { payload } = legendProps;
    const namesUsed = [];
    const legendData = payload
      .map(entry => {
        if (!namesUsed.includes(entry.value)) {
          namesUsed.push(entry.value);
          let color;
          // if we are using our default colours for non-phasd data then use that
          if (defaultColors.includes(entry.payload.stroke)) {
            color = entry.payload.stroke;
          } else if (defaultColors.includes(entry.payload.fill)) {
            color = entry.payload.fill;
          } else {
            // else use a theme based black/grey
            color = theme === 'dark' ? '#FFFFFF' : '#262626';
          }
          return (
            <div key={entry.value} className="schedule-panel-legend">
              <div
                className="schedule-panel-legend-labels"
                onMouseOver={() => setHovered(entry.value)}
                onMouseOut={() => setHovered(null)}
              >
                <div
                  style={{
                    width: '25px',
                    height: '50%',
                    borderBottom: `1px ${color} ${
                      entry.payload.strokeDasharray ? 'dashed' : 'solid'
                    }`,
                  }}
                >
                  &nbsp;
                </div>
                <div style={{ height: '100%', padding: '5px 10px', whiteSpace: 'nowrap' }}>
                  {entry.value}
                </div>
              </div>
            </div>
          );
        }
        return null;
      })
      .filter(x => x !== null);

    return (
      <div
        className={classNames({
          'top-legend': true,
          'top-legend--expanded': expanded,
        })}
      >
        <div className="unit-label-row">
          <div>{yAxisLabel}</div>
        </div>
        <div>
          <ScheduleValidations
            variables={variables}
            panelValues={panelValues}
            theme={theme}
            assetType={assetType}
          />
        </div>
        <div className="legend-group">{legendData}</div>
      </div>
    );
  };

  const customTooltip = useCallback(
    params => {
      const { payload, label } = params;
      const generateRawRows = (rowData, index) => {
        let value = rowData.dataKey(rowData.payload);
        let minValue;
        let maxValue;
        if (typeof value === 'number') {
          if (Number.isNaN(value) || (rowData.payload.fakeDatapoint && scheduleType !== 'Global')) {
            if (scheduleType === 'Global') {
              value = undefined;
            } else {
              value = 'unscheduled';
            }
          } else {
            value = value.toFixed(2);
          }
        } else if (typeof value === 'object') {
          if (
            !rowData.payload.fakeDatapoint &&
            !Number.isNaN(value[0]) &&
            !Number.isNaN(value[1])
          ) {
            if (value[0] !== value[1]) {
              // if min and max are the same we dont need them!
              minValue = value[0].toFixed(2);
              maxValue = value[1].toFixed(2);
            }
          }
          value = undefined;
        }

        const rows = [];
        if (value !== undefined) {
          rows.push(
            <tr key={`${rowData.name}${index}`} style={{ color: rowData.color }}>
              <td>{`${rowData.name}${processed_data.aggregation !== 'hour' ? ' avg' : ''}`}</td>
              <td>{value}</td>
            </tr>,
          );
        }
        if (minValue !== undefined) {
          rows.push(
            <tr key={`${rowData.name}${index}min`} style={{ color: rowData.fill }}>
              <td>{`${rowData.name} min`}</td>
              <td>{minValue}</td>
            </tr>,
          );
        }
        if (maxValue !== undefined) {
          rows.push(
            <tr key={`${rowData.name}${index}max`} style={{ color: rowData.fill }}>
              <td>{`${rowData.name} max`}</td>
              <td>{maxValue}</td>
            </tr>,
          );
        }

        return rows;
      };
      if (!params.payload) {
        return undefined; // dont show a tooltip for the extra padding
      }
      return (
        <div className="tooltip">
          <p>
            <b>{params.labelFormatter(label)}</b>
          </p>
          <table>
            <tbody>
              {payload && label && payload.map((rowData, index) => generateRawRows(rowData, index))}
            </tbody>
          </table>
        </div>
      );
    },
    [scheduleType, processed_data.aggregation],
  );

  const renderChart = () => {
    const colors = phaseColors();

    const hasPhases = processed_data.columns.map(col => col.split('_')[1]).filter(n => n);
    const { aggregation } = processed_data;

    const { start, end } = timeRange;
    const highlightStart = start ? toISO(start) : '';
    const highlightEnd = end ? toISO(getStartOfInterval(end, timeBarZoomLevel)) : '';
    let labelFormat;
    let tickFormat;
    switch (aggregation) {
      case 'day':
        labelFormat = 'YYYY/MM/DD';
        tickFormat = 'DD';
        break;
      case 'month':
        labelFormat = 'YYYY/MM';
        tickFormat = 'MM';
        break;
      case 'year':
        labelFormat = 'YYYY';
        tickFormat = 'YYYY';
        break;
      case 'hour':
      default:
        labelFormat = 'YYYY/MM/DD HH:mm';
        tickFormat = 'HH:mm';
        break;
    }

    const labelFormatter = unixTime => moment.parseZone(unixTime).format(labelFormat);
    const tickFormatter = unixTime => {
      const m = moment.parseZone(unixTime);
      if (!m.isSame(moment(m).startOf(aggregation))) {
        return ''; // dont label the extra padding
      }
      return m.format(tickFormat);
    };
    return (
      <>
        <ResponsiveContainer width="100%">
          <ComposedChart
            data={processed_data.data}
            margin={{
              top: 0,
              bottom: 0,
              left: 0,
              right: 20,
            }}
          >
            <ChartTooltip
              content={customTooltip}
              labelFormatter={labelFormatter}
              labelStyle={{ color: 'black' }}
            />
            <XAxis
              dataKey="timestamp"
              stroke="#949899"
              scale="point"
              tickLine={false}
              domain={maxRange ? Object.values(maxRange).map(t => toISO(t)) : undefined}
              tickFormatter={tickFormatter}
            />
            <YAxis
              stroke="#949899"
              tickLine={false}
              allowDecimals={!processed_data.integerYValues && !processed_data.booleanYValues}
              domain={
                processed_data.booleanYValues || processed_data.zeroOneYValues ? [0, 1] : undefined
              }
              ticks={
                processed_data.booleanYValues || processed_data.zeroOneYValues ? [0, 1] : undefined
              }
              padding={{ top: 5, bottom: 5 }}
              tickFormatter={
                processed_data.columns.includes('closed')
                  ? val => (val === 1 ? 'closed' : 'open')
                  : null
              }
            />
            {highlightStart === highlightEnd ? (
              <ReferenceLine x={highlightStart} stroke="teal" />
            ) : (
              <ReferenceArea
                x1={highlightStart}
                x2={highlightEnd}
                ifOverflow="visible"
                fillOpacity={0.3}
                fill="teal"
              />
            )}
            {generateLines(processed_data.columns, processed_data.isAggregated)}
            <Legend verticalAlign="top" content={renderLegend} />
          </ComposedChart>
        </ResponsiveContainer>
        {hasPhases.length > 0 && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
            <PhaseBadgeContainer
              activePhases={activePhases}
              phaseColors={colors}
              onActivePhaseChange={handleActivePhaseChange}
              onHoverChange={setHovered}
              disabledPhases={['A', 'B', 'C'].filter(phase => !hasPhases.includes(phase))}
            />
          </div>
        )}
        {legend && (
          <div className="schedule-legend">
            <div className="schedule-legend-text">{legend}</div>
          </div>
        )}
      </>
    );
  };

  const canUpload = editable && !!handleScheduleUpload;
  const canDelete = editable && !!handleScheduleDelete;
  const canDownload = !!handleScheduleDownload;
  const options = analyses?.map(x => ({ value: x.name, label: x.name }));
  return (
    <div className="schedule-container">
      <div className="schedule-header">
        <span>{header}</span>
        {displayActionIcons && (
          <PermissionDisabledTip
            hide={editable}
            title="Edit Schedule"
            placement="bottom"
            theme={theme}
          >
            <div className="schedule-buttons">
              <Tooltip content="Help">
                <DocumentationLink
                  documentationPath={
                    scheduleType === 'Feeder'
                      ? 'powerflow/LoadGeneration'
                      : 'editing/device-schedules'
                  }
                >
                  <i className="material-icons help-icon">help_outline</i>
                </DocumentationLink>
              </Tooltip>
              <IconFileInput
                icon="create"
                className="schedule-panel"
                onChange={handleScheduleUpload || (() => {})}
                accept=".csv"
                disabled={!canUpload}
                theme={theme}
                // scheduleType needed to make the id unique otherwise the actions get messed up
                id={`create-schedule-button=${scheduleType}`}
                tooltip={canUpload ? uploadHelp : scheduleUploadDisabledMessage}
              />
              <IconButton
                icon="get_app"
                className="schedule-panel"
                onClick={handleScheduleDownload || (() => {})}
                disabled={!canDownload}
                theme={theme}
                tooltip={canDownload ? downloadHelp : scheduleDownloadDisabledMessage}
              />
              <IconButton
                icon="delete"
                className="schedule-panel"
                onClick={handleScheduleDelete || (() => {})}
                disabled={!canDelete}
                theme={theme}
                tooltip={canDelete ? deleteHelp : scheduleDeleteDisabledMessage}
              />
            </div>
          </PermissionDisabledTip>
        )}
      </div>
      <Card
        hideTitle
        theme={theme}
        className={classNames({
          'schedule-chart-container': true,
          'schedule-chart-container-previous': currentAsset?.control_mode === 'analysisSchedule',
        })}
      >
        {currentAsset?.control_mode === 'analysisSchedule' && (
          <div>
            <p>View a previous analysis:</p>
            <Select
              value={selectedAnalysis}
              options={options}
              theme={theme}
              disabled={analyses.length === 0}
              onChange={e => setSelectedAnalysis(e.label)}
              clearable={false}
            />
          </div>
        )}
        {showChart && (processed_data?.data?.length ?? 0) > 0 && renderChart()}
        {!showChart && (
          <div className="graph-placeholder">
            {loading && (
              <div>
                <h3>Loading...</h3>
                <i className="material-icons rotate" style={{ fontSize: 40 }}>
                  refresh
                </i>
              </div>
            )}
            {!loading && (
              <div className="missing-schedule-placeholder">
                {error?.length > 0 ? (
                  error
                ) : (
                  <>
                    No schedule available for time range
                    {canUpload && <UploadButton onChange={handleScheduleUpload} accept=".csv" />}
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

SchedulePanel.propTypes = {
  error: PropTypes.string,
  header: PropTypes.string,
  legend: PropTypes.string,
  showChart: PropTypes.bool,
  variables: PropTypes.object,
  datapoints: PropTypes.array,
  handleScheduleUpload: PropTypes.func,
  scheduleUploadDisabledMessage: PropTypes.string,
  handleScheduleDelete: PropTypes.func,
  scheduleDeleteDisabledMessage: PropTypes.string,
  handleScheduleDownload: PropTypes.func,
  scheduleDownloadDisabledMessage: PropTypes.string,
  editable: PropTypes.bool,
  loading: PropTypes.bool,
  timeRange: PropTypes.shape({
    start: nullable(PropTypes.object).isRequired,
    end: nullable(PropTypes.object).isRequired,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: nullable(PropTypes.object).isRequired,
    end: nullable(PropTypes.object).isRequired,
  }).isRequired,
  panelValues: PropTypes.object.isRequired,
  assetType: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  scheduleType: PropTypes.string.isRequired,
  yAxisLabel: PropTypes.string,
  currencySymbol: PropTypes.string,
  currentAsset: PropTypes.object,
  theme: PropTypes.string,
  analyses: PropTypes.array,
  selectedAnalysis: PropTypes.string,
  timeBarZoomLevel: PropTypes.string.isRequired,
  setSelectedAnalysis: PropTypes.func,
  displayActionIcons: PropTypes.bool,
};

export default SchedulePanel;
