import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const CheckmarkIcon: FunctionComponent<IconPropsSchema> = ({ color, height, width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 8.2 6.5"
    height={height}
    width={width}
  >
    <g id="Symbol_12_1" transform="translate(-2098)">
      <polygon
        fill={color}
        points="2100.8,6.5 2098,3.7 2099.1,2.6 2100.8,4.4 2105.2,0 2106.3,1.1"
      />
    </g>
  </svg>
);

export default CheckmarkIcon;
