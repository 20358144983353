import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';

const PhaseSelectorComboBox = props => (
  <div className="dropdown-no-title">
    <Select
      value={props.phase.replace('N', '')}
      disabled={props.disabled}
      options={props.allowedPhases}
      onChange={val =>
        val.value !== props.phase.replace('N', '') ? props.onChange(val.value) : null
      }
      clearable={false}
      theme={useContext(ThemeContext)}
    />
  </div>
);

PhaseSelectorComboBox.defaultProps = {
  allowedPhases: [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
    { value: 'C', label: 'C' },
    { value: 'ABC', label: 'ABC' },
  ],
  disabled: false,
};

PhaseSelectorComboBox.propTypes = {
  allowedPhases: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  phase: PropTypes.string.isRequired,
};

export default PhaseSelectorComboBox;
