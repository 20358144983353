import React from 'react';
import PropTypes from 'prop-types';
import CustomCheckbox from 'components/CustomCheckbox';
import EditRange from './EditRange';

const RangeSelector = props => {
  const { id, color, label } = props.settings;
  const layerOptions = {
    ...props.layerOptions,
    rangeBreaks: props.rangeBreaks || props.layerOptions.rangeBreaks,
  };

  return (
    <div className="layer-checkbox-row">
      <div className="layer-checkbox-container">
        <CustomCheckbox
          key={id}
          backgroundColor={color}
          borderColor={color}
          checked={props.selected}
          className="range-checkbox"
          disabled={false}
          focusable
          id={id}
          value={id}
          onClick={props.onClick}
        />
        <div className="layer-checkbox-label">{label}</div>
      </div>
      {props.withEdit && (
        <div className={`edit-panel-container ${props.id === id && 'edit-active'}`}>
          <button
            className="edit-icon-div"
            value={id}
            onClick={e => props.toggleEdit(e.target.value)}
            disabled={props.editDisabled}
            type="button"
          >
            <i className="material-icons edit-icon">edit</i>
          </button>
          {props.showEditPanel && (
            <EditRange
              editId={props.id}
              toggleEditPanel={props.toggleEdit}
              layerOptions={layerOptions}
              handleUpdate={props.handleUpdate}
              editRange={props.editRange}
              editColor={props.editColor}
              useMultiplier={props.useMultiplier}
              rangeMax={props.rangeMax}
              rangeMin={props.rangeMin}
              unit={props.unit}
              validationCheck={props.validationCheck}
              ascending={props.ascending}
              descending={props.descending}
              headers={props.headers}
            />
          )}
        </div>
      )}
    </div>
  );
};

RangeSelector.defaultProps = {
  showEditPanel: false,
  withEdit: false,
  toggleEdit: null,
  layerOptions: {},
  handleUpdate: null,
  editDisabled: false,
  editRange: false,
  editColor: false,
  useMultiplier: false,
  rangeMin: 0,
  rangeMax: Number.POSITIVE_INFINITY,
  unit: null,
  rangeBreaks: null,
  validationCheck: null,
  ascending: false,
  descending: false,
  headers: undefined,
};

RangeSelector.propTypes = {
  selected: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func,
  handleUpdate: PropTypes.func,
  layerOptions: PropTypes.object,
  id: PropTypes.number.isRequired,
  editDisabled: PropTypes.bool,
  showEditPanel: PropTypes.bool,
  withEdit: PropTypes.bool,
  editRange: PropTypes.bool,
  editColor: PropTypes.bool,
  useMultiplier: PropTypes.bool,
  rangeMin: PropTypes.number,
  rangeMax: PropTypes.number,
  unit: PropTypes.string,
  rangeBreaks: PropTypes.array,
  validationCheck: PropTypes.func,
  ascending: PropTypes.bool,
  descending: PropTypes.bool,
  headers: PropTypes.array,
};

export default RangeSelector;
