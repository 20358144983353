import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ThemeContext from 'helpers/ThemeContext';
import useTrackingState from 'hooks/useTrackingState';
import { kVA } from 'helpers/units';
import { isDefined } from 'helpers/utils';
import UnitField from './UnitField';
import EquipmentInfoSelector from './containers/EquipmentInfoSelectorContainer';

const InverterValues = ({
  editableValues,
  disabled,
  editable,
  validationSchema,
  handleSave,
  inverter,
  inverterAttributes,
  inverterInfo,
  eqLibLink,
  infos,
  container,
  inEditMode,
}) => {
  const theme = useContext(ThemeContext);
  const [attributes, setAttributes] = useTrackingState(inverterAttributes);

  const onClear = field => () => handleSave({ attributes: { [field]: null } });

  const fields = [
    ...editableValues,
    {
      precision: 1,
      id: 'ratedS',
      label: 'Rated Power',
      unit: kVA,
      divisor: 1000,
      type: 'number',
      min: 0,
    },
    {
      id: 'ratedPowerFactor',
      label: 'Rated Power Factor',
      divisor: 1,
      type: 'number',
      unit: ' ',
      precision: 3,
    },
  ];
  const onInfoChange = option => {
    const attributeValues = {
      maxP: null,
      minP: null,
      maxQ: null,
      minQ: null,
      ratedPowerFactor: null,
      ratedS: null,
    };
    handleSave({
      inverter_info: option?.value ?? null,
      attributes: attributeValues,
    });
  };
  const mappedInverterInfoOptions = [
    {
      value: inverterInfo?.id,
      label: inverterInfo?.name ?? 'Select Inverter Type',
      disabled: true,
    },
    ...infos
      .filter(invr => invr.id !== inverterInfo?.id)
      .map(invr => ({ value: invr.id, label: invr.name || invr.id })),
  ];
  return (
    <>
      <EquipmentInfoSelector
        id="inverter-select"
        onChange={onInfoChange}
        loadOptions={() => mappedInverterInfoOptions}
        defaultOptions={mappedInverterInfoOptions}
        eqLibURL={eqLibLink}
        value={inverterInfo?.id}
        disabled={disabled}
        assetId={inverter.id}
        feeder={container.id}
        displayName="Inverter"
        changeOnModalConfirm
      />
      <div className="unit-fields-section">
        {fields.map(fieldObj => {
          const { id: field, divisor } = fieldObj;
          const value = attributes[field];
          const clearEnabled = attributes[field] || attributes[field] === 0;
          const EQLHasEmptyValue = inverterInfo && inverterInfo.id && !isDefined(value);
          return (
            <UnitField
              {...fieldObj}
              {...attributes}
              id={field}
              htmlFor={`${field}_inverter`}
              key={field}
              field={fieldObj}
              handleSave={() => handleSave({ attributes: { [field]: value } })}
              editable={editable}
              disabled={disabled}
              onClear={clearEnabled ? onClear(field) : null}
              validationSchema={validationSchema[field]}
              updateValue={val =>
                setAttributes({
                  ...attributes,
                  [field]: val,
                })
              }
              edited={attributes[field] !== inverterAttributes[field]}
              value={value}
              theme={theme}
              showTooltip={EQLHasEmptyValue}
              inEditMode={inEditMode}
              placeholder={inverterInfo?.[field] / (fieldObj.eQLDivisor ?? divisor) || ''}
            />
          );
        })}
      </div>
    </>
  );
};

InverterValues.propTypes = {
  editableValues: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  validationSchema: PropTypes.object.isRequired,
  inverter: PropTypes.object.isRequired,
  inverterAttributes: PropTypes.object.isRequired,
  inverterInfo: PropTypes.object,
  eqLibLink: PropTypes.string.isRequired,
  infos: PropTypes.array,
  editable: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
  container: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool.isRequired,
};

InverterValues.defaultProps = {
  infos: [],
  editableValues: [],
  inverterInfo: null,
};

export default InverterValues;
