import { ThemeProp } from 'types/index';

export const getIconColor: (theme: ThemeProp, color?: null | string) => string = (theme, color) => {
  let iconColor = null;
  if (color) {
    iconColor = color;
  } else {
    iconColor = theme === 'dark' ? '#fff' : '#000';
  }
  return iconColor;
};
export const getIconBGColor: (theme: ThemeProp, color?: null | string) => string = (
  theme,
  color,
) => {
  let iconColor = null;
  if (color) {
    iconColor = color;
  } else {
    iconColor = theme === 'dark' ? '#404040' : '#F2F5F7';
  }
  return iconColor;
};
