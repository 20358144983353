import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const ArrowDown: FunctionComponent<IconPropsSchema> = ({
  height = '20px',
  width = '30px',
  color = '#ffffff',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>C266BFA9-2D30-4726-B977-2514CFBFA1B0</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icon-Library-Copy"
        transform="translate(-1048.000000, -84.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="Chevron-Up" transform="translate(1048.000000, 84.000000)">
          <path
            d="M14.6893391,13.15 C14.4893391,13.15 14.3893391,13.05 14.2893391,12.95 L9.68933909,8.45 L5.08933909,13.05 C4.88933909,13.25 4.48933909,13.25 4.18933909,13.05 C3.88933909,12.85 3.98933909,12.45 4.18933909,12.15 L9.18933909,7.15 C9.38933909,6.95 9.78933909,6.95 10.0893391,7.15 L15.0893391,12.15 C15.2893391,12.35 15.2893391,12.75 15.0893391,13.05 C14.9893391,13.15 14.8893391,13.15 14.6893391,13.15 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowDown;
