import React, { FunctionComponent, useState, useEffect, ReactChild } from 'react';
import { ThemeProp } from 'types/index';
import ReactSelect, { components, createFilter } from 'react-select';
import ArrowDown from 'components/Icons/ArrowDown';
import ArrowUp from 'components/Icons/ArrowUp';

import Tooltip from 'components/Tooltip';
import './Select.scss';
import CustomCheckbox from 'components/CustomCheckbox';
import { ReactSVG } from 'react-svg';

type SelectOption = {
  value: any;
  label: any;
  disabled?: boolean;
};

type SelectProps = {
  value?: any;
  options: SelectOption[];
  defaultOptions?: SelectOption[];
  disabled?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  isMulti?: boolean;
  onChange: (option: any) => void;
  height?: number;
  width?: number;
  onMenuOpen?: (event?: React.FormEvent<HTMLElement>) => void;
  onMenuClose?: (event?: React.FormEvent<HTMLElement>) => void;
  loadOptions?: (event?: React.FormEvent<HTMLElement>) => [];
  async?: boolean;
  tooltip?: string;
  placeholder?: string;
  theme?: ThemeProp;
  className?: string;
  id?: string;
  htmlFor?: string;
  type?: 'default' | 'primary' | 'secondary' | 'text';
  label?: ReactChild;
  labelPosition?: 'top' | 'left';
  invalid?: boolean;
  noOptionsMessage?: string;
  multiSelectType?: 'default' | 'checkbox';
  defaultValue?: any;
};

const Select: FunctionComponent<SelectProps> = ({
  theme = 'light',
  value = '',
  options = [],
  disabled = false,
  searchable = true,
  clearable = true,
  isMulti = false,
  onMenuOpen = null,
  onMenuClose = null,
  loadOptions = () => [],
  defaultOptions = null,
  async = false,
  tooltip = '',
  placeholder = 'Select...',
  onChange,
  className = '',
  type = 'default',
  id = '',
  htmlFor = '',
  label,
  labelPosition = 'top',
  width = null,
  invalid = false,
  noOptionsMessage = 'No options',
  multiSelectType = 'default',
  defaultValue = null,
}) => {
  const themes: any = {
    light: {
      backgroundColor: '#425866',
      hoverColor: { default: '#425866', primary: '#425866', secondary: '#425866' },
      selectedColor: '#425866',
      borderColor: 'transparent',
      disabledColor: '#cccccc',
      highlightColor: '#425866',
      menuColor: '#000000',
      borderRadius: '0',
      textColor: '#8492a6',
    },
  };

  const [selectOptions, setSelectOptions] = useState(defaultOptions || options);
  const [menuOpen, setMenuOpen] = useState(false);
  let actualValue = null;
  let multiSelectValue = null;
  if (value instanceof Array) {
    const selectOptionValues = selectOptions.map(({ value: val }) => val);
    multiSelectValue = value.filter(({ value: val }) => selectOptionValues.includes(val));
  } else {
    actualValue = selectOptions.find(({ value: val }) => val === value) ?? null;
  }

  const defaultOption = selectOptions.find(option => option.value === defaultValue);

  const onSelectMenuOpen = () => {
    if (async) {
      setSelectOptions(loadOptions());
    }
    setMenuOpen(true);
    return onMenuOpen && onMenuOpen();
  };

  const onSelectMenuClose = () => {
    setMenuOpen(false);
    return onMenuClose && onMenuClose();
  };
  const DropdownIndicator = (props: { isDisabled: boolean }) =>
    menuOpen ? (
      <ArrowUp color={themes[theme].textColor} />
    ) : (
      <ArrowDown color={props.isDisabled ? themes[theme].disabledColor : themes[theme].textColor} />
    );
  const CustomOption = (props: any) => (
    <components.Option {...props}>
      <div className="option-row">
        <div title={props.data?.label} className="label-row">
          <CustomCheckbox
            id="empty-workspace"
            checked={
              props &&
              props.selectProps?.value &&
              props?.selectProps?.value?.filter(
                (obj: SelectOption) => obj.value === props.data?.value,
              )?.length !== 0
            }
            type="primary"
          />
          <div className="label-text">{props.data?.label}</div>
        </div>
      </div>
    </components.Option>
  );

  const CustomSingleOption = (props: any) => (
    <components.Option {...props}>
      <div className="icon-option-row">
        <div title={props.data?.label} className="label-row">
          {props.data?.icon ? <ReactSVG src={props.data?.icon} className="custom-icon" /> : null}
          <div className="label-text">{props.data?.label}</div>
          <div className="option-icon">
            {props.selectProps?.value?.value === props.data?.value && props.data?.icon && (
              <svg
                width="10px"
                height="10px"
                viewBox="0 0 10 10"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Custom-Preset-Copy-3" transform="translate(-1271.000000, -1630.000000)">
                    <g id="dropdown/menu/open" transform="translate(1087.000000, 1614.000000)">
                      <g id="dropdown/menu/selected" transform="translate(14.000000, 14.000000)">
                        <g id="checked" transform="translate(170.000000, 2.000000)">
                          <path
                            d="M9.96258342,5.00013108 C9.96258342,7.75709926 7.72788529,10 4.98132075,10 C2.23449505,10 5.80944191e-05,7.75709926 5.80944191e-05,5.00013108 C5.80944191e-05,2.24290074 2.23449505,0 4.98132075,0 C7.72788529,0 9.96258342,2.24290074 9.96258342,5.00013108 Z"
                            id="Path"
                          />
                          <path
                            d="M7.27222642,4.14893939 L4.98132075,6.44890152 L4.77196226,6.65905303 C4.65973585,6.77170455 4.51271698,6.82787879 4.36532075,6.82787879 L4.35664151,6.82787879 C4.21150943,6.82787879 4.06641509,6.7725 3.95566038,6.66136364 L2.7225283,5.42318182 C2.5010566,5.20125 2.5010566,4.84087121 2.7225283,4.61859848 C2.944,4.39632576 3.30301887,4.39628788 3.52407547,4.61859848 L4.35169811,5.44897727 L4.98128302,4.81700758 L6.45966038,3.3330303 C6.68415094,3.10768939 7.04807547,3.10768939 7.27218868,3.3330303 C7.49671698,3.55829545 7.49671698,3.92359848 7.27222642,4.14893939 Z"
                            id="Path"
                            fill="#FFFFFF"
                            fillRule="nonzero"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            )}
          </div>
        </div>
      </div>
    </components.Option>
  );

  const MultiValueContainer = (props: any) => {
    const selectLabel = props.data?.label;
    const allSelected = props.selectProps?.value;
    const index = allSelected?.findIndex(
      (selected: SelectOption) => selected.label === selectLabel,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const selectedMsg = isLastSelected
      ? `${allSelected?.length}/${selectOptions?.length} selected`
      : '';
    return <div className="custom-select-multi-value">{selectedMsg}</div>;
  };
  useEffect(() => {
    if (async && defaultOptions) {
      setSelectOptions(defaultOptions);
    }
  }, [defaultOptions, async]);
  useEffect(() => {
    if (options && !async) {
      setSelectOptions(options);
    }
  }, [options, async]);

  const showMultiselectValue: any = isMulti && multiSelectValue;

  const showSingleSelectValue: any =
    actualValue === null && (defaultOption !== null || defaultOption !== undefined)
      ? defaultOption
      : actualValue;

  return (
    <div className={`select-dropdown-section label-${labelPosition}`}>
      {label && (
        <label htmlFor={htmlFor || id} className={`select__label ${type}`}>
          {label}
        </label>
      )}
      <Tooltip content={tooltip}>
        <ReactSelect
          id={id}
          className={`select-dropdown ${className} ${type} ${theme} ${menuOpen ? 'menu-open' : ''}`}
          menuIsOpen={menuOpen}
          value={showSingleSelectValue || showMultiselectValue}
          isDisabled={disabled}
          onChange={onChange}
          isSearchable={searchable}
          isClearable={clearable}
          isMulti={isMulti}
          onMenuOpen={onSelectMenuOpen}
          onMenuClose={onSelectMenuClose}
          classNamePrefix={actualValue ? 'custom-select' : 'custom-multi-select'}
          isOptionDisabled={(option: SelectOption) => option.disabled || false}
          options={selectOptions}
          filterOption={createFilter({
            matchFrom: 'any',
            stringify: (option: SelectOption) => `${option.label}`,
          })}
          closeMenuOnSelect={!isMulti || (isMulti && multiSelectType !== 'checkbox')}
          blurInputOnSelect={!isMulti || (isMulti && multiSelectType !== 'checkbox')}
          hideSelectedOptions={isMulti && multiSelectType !== 'checkbox'}
          components={{
            Option: isMulti && multiSelectType === 'checkbox' ? CustomOption : CustomSingleOption,
            DropdownIndicator,
            MultiValueContainer:
              isMulti && multiSelectType === 'checkbox'
                ? MultiValueContainer
                : components.MultiValueContainer,
          }}
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage}
          styles={{
            indicatorSeparator: defaults => ({
              ...defaults,
              width: 0,
            }),
            option: (defaults, state) => {
              let color = themes[theme].textColor;
              if (state.isDisabled) {
                color = themes[theme].disabledColor;
              }
              return {
                ...defaults,
                color,
                ':hover': {
                  backgroundColor:
                    (type === 'default' || type === 'primary' || type === 'secondary') &&
                    state.isSelected
                      ? themes[theme].selectedColor
                      : '#425866',
                },
              };
            },
            menu: defaults => ({
              ...defaults,
              marginTop: '4px',
              padding: '3px 0',
              zIndex: 1000,
              borderColor: 'transparent',
              boxShadow: 'none',
              backgroundColor: themes[theme].menuColor,
              borderWidth: '0.5px',
              borderStyle: 'solid',
            }),
            control: defaults => ({
              ...defaults,
              backgroundColor:
                theme === 'light' && type === 'primary' ? '#425866' : themes[theme].backgroundColor,
              borderColor: invalid ? '#FC7169 !important' : themes[theme].borderColor,
              width: width || 'auto',
              boxShadow: 'none',
            }),
            container: defaults => ({
              ...defaults,
              backgroundColor: 'transparent',
              borderColor: themes[theme].borderColor,
              maxWidth: width || 'auto',
            }),
            valueContainer: defaults => ({
              ...defaults,
              padding: '0 8px',
              color: '#fff',
            }),
            clearIndicator: defaults => ({
              ...defaults,
              padding: 0,
            }),
            dropdownIndicator: (defaults, state) => {
              let color = '#ffffff';
              if (state.isDisabled) color = themes[theme].disabledColor;
              return {
                ...defaults,
                paddingLeft: 0,
                color,
              };
            },
            singleValue: (defaults, state) => ({
              ...defaults,
              width: 'calc(100% - 15px)',
              color:
                state.data.disabled && ['primary', 'secondary', 'default'].includes(type)
                  ? '#95a6b8'
                  : '#ffffff',
            }),
            multiValue: styles => ({
              ...styles,
              backgroundColor: theme === 'dark' ? '#606060' : '#fff',
              border: '1px solid #606060',
              borderRadius: '0px',
            }),
            multiValueLabel: styles => ({
              ...styles,
              color: theme === 'dark' ? 'white' : '#606060',
            }),
            multiValueRemove: styles => ({
              ...styles,
              color: theme === 'dark' ? 'white' : '#606060',
              ':hover': {
                color: '#0337A4',
              },
            }),
          }}
          theme={(currentTheme: any) => ({
            ...currentTheme,
            colors: {
              ...currentTheme.colors,
              primary25: themes[theme].hoverColor,
              primary: themes[theme].selectedColor,
              primary50: themes[theme].hoverColor,
              neutral0: themes[theme].backgroundColor.default,
              neutral80: themes[theme].textColor,
            },
          })}
        />
      </Tooltip>
    </div>
  );
};
export default Select;
