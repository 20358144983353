import React, { useContext, FunctionComponent } from 'react';
import CustomCheckbox from 'components/CustomCheckbox';
import Input from 'components/Input';
import Analytics from 'helpers/Analytics';
import { pluralize } from 'helpers/utils';

import ThemeContext from 'helpers/ThemeContext';
import { AssetViolationsContext } from 'contexts/AssetViolationsContext';

import HelpTooltip from 'components/HelpTooltip';

const AssetViolations: FunctionComponent = () => {
  const {
    layersEnabled,
    maxAge,
    setMaxAge,
    maxHealthMetric,
    setMaxHealthMetric,
    refetchOldAssets,
    toggleLayer,
  } = useContext(AssetViolationsContext);

  const theme = useContext(ThemeContext);
  return (
    <>
      <div className="panel-header">
        <h1 className="title-text">Asset Violations</h1>
      </div>
      <div className="violations-checkbox-area">
        <table>
          <tr>
            <td>
              <CustomCheckbox
                id="age"
                checked={layersEnabled.age}
                onClick={() => {
                  toggleLayer('age');
                  Analytics.logEvent('Toggle Asset Lifecycle Violations', 'Asset Lifecycle');
                }}
                disabled={(maxAge !== 0 && !maxAge) || maxAge < 0 || maxAge > 100}
              />
            </td>
            <td>
              <Input // eslint-disable-line deprecation/deprecation
                type="number"
                theme={theme}
                label="Assets older than  "
                value={maxAge}
                options={{
                  precision: 0,
                }}
                validation={{
                  min: 0,
                  max: 100,
                }}
                inputWidth="60px"
                onChange={(value: number) => setMaxAge(Math.trunc(value))}
                onBlur={() => refetchOldAssets('age')}
                unit={pluralize('year', maxAge)}
              />
            </td>
            <td>
              <HelpTooltip message="Asset age will be the difference between the end of the current higlighted range (or today if no scenario is selected) and an asset's in service date." />
            </td>
          </tr>
          <tr>
            <td>
              <CustomCheckbox
                id="health-metric"
                checked={layersEnabled.healthMetric}
                onClick={() => {
                  toggleLayer('healthMetric');
                  Analytics.logEvent('Toggle Health Metric Violations', 'Asset Health Metrics');
                }}
                disabled={!(maxHealthMetric >= 1 && maxHealthMetric <= 5)}
              />
            </td>
            <td>
              <Input // eslint-disable-line deprecation/deprecation
                type="number"
                theme={theme}
                label="Assets health metric over "
                value={maxHealthMetric}
                options={{
                  precision: 0,
                }}
                validation={{
                  min: 1,
                  max: 5,
                }}
                inputWidth="60px"
                onChange={(value: number) => setMaxHealthMetric(Math.trunc(value))}
                onBlur={() => refetchOldAssets('healthMetric')}
              />
            </td>
            <td>
              <HelpTooltip message="Shows all assets with a health metric value greater than or equal to the specified level" />
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
export default AssetViolations;
