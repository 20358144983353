import React, { createContext, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useRequestEffect, Request } from '@opusonesolutions/gridos-app-framework';
import {
  ACTIVITY_LOG_STATUS,
  ANALYSIS_TYPES,
} from 'routes/WorkspaceLayout/routes/Network/helpers/NetworkHelpers';
import { determineTimeRangeForAnalysis } from 'routes/WorkspaceLayout/routes/Network/modules/network';
import { useInterval } from 'helpers/hooks';

type logEntry = {
  workspace: string;
  branch: string;
  job_id: string;
  status: string;
  activity_type: string;
  num_completed_tasks: number;
  num_failed_tasks: number;
  num_tasks: number;
  scenario_id: string;
  additional_info: { [key: string]: string };
  last_changed: string;
};
type ContextState = {
  logEntries: logEntry[];
  analysisActive: (
    branch: string | null,
    scenario: string | null,
    analysis: string | null,
  ) => boolean;
  cancelJob: (jobId: string) => void;
};
interface ReduxState {
  loadForecast: {
    selectedScenario: string;
    selectedAnalysis: { [key: string]: string } | null;
  };
  feeders: {
    selected: { [key: string]: string }[];
  };
}
interface Selected {
  selectedScenario: string;
  selectedAnalysis: { [key: string]: string } | null;
  selectedFeeders: { [key: string]: string }[];
}
export const ActivityLogContext = createContext<ContextState>({
  logEntries: [],
  analysisActive: () => false,
  cancelJob: () => {},
});

interface ActivityLogProvider {
  children: JSX.Element;
  workspace: string | null;
  branch: string | null;
}
export default function ActivityLogContextProvider({
  children,
  workspace,
  branch,
}: ActivityLogProvider): JSX.Element {
  const INTERVAL = 5000;
  const {
    PARTIAL_COMPLETED,
    COMPLETED,
    FAILED,
    CANCELED_PARTIAL_COMPLETED,
    CANCELED,
    CANCELING,
    PENDING,
    POSTPROCESSING,
    RUNNING,
  } = ACTIVITY_LOG_STATUS;
  const { selectedScenario, selectedAnalysis, selectedFeeders } = useSelector<ReduxState, Selected>(
    state => ({
      selectedScenario: state.loadForecast.selectedScenario,
      selectedAnalysis: state.loadForecast.selectedAnalysis,
      selectedFeeders: state.feeders.selected,
    }),
  );
  const [logEntriesData, setLogEntriesData] = useState<logEntry[]>([]);
  const { refetch } = useRequestEffect<logEntry[]>({
    url: `/api/workspace/${workspace}/branch/${branch}/activity-log`,
    method: 'get',
    params: {
      subjob_limit: 10,
    },
    refetchOnChange: [workspace, branch],
    blockRequest: () => !(workspace && branch),
    initialData: [],
    onSuccess: data => {
      setLogEntriesData(data || []);
    },
  });
  const dispatch = useDispatch();
  const getTimeRangeForAnalysis = useCallback(
    () =>
      dispatch(
        determineTimeRangeForAnalysis(
          workspace,
          branch,
          selectedScenario,
          selectedFeeders,
          selectedAnalysis,
        ),
      ),
    [branch, dispatch, selectedAnalysis, selectedFeeders, selectedScenario, workspace],
  );
  useEffect(() => {
    const lastChecked = moment.utc().subtract(INTERVAL, 'ms');
    const activity = logEntriesData.find(
      log =>
        log.workspace === workspace &&
        log.branch === branch &&
        log.scenario_id === selectedScenario &&
        log.additional_info?.analysis_name === selectedAnalysis?.name &&
        [COMPLETED, PARTIAL_COMPLETED, FAILED, CANCELED, CANCELED_PARTIAL_COMPLETED].includes(
          log.status,
        ) &&
        lastChecked.isBefore(moment.utc(log.last_changed)),
    );
    if (activity) {
      getTimeRangeForAnalysis();
    }
    // intentionally disabled exhaustive-deps since want to update based on log data only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logEntriesData]);
  const analysisActive = (
    branchName: string | null,
    scenario_id: string | null,
    analysisName: string | null,
  ) => {
    const entry = logEntriesData.find(
      x =>
        x.branch === branchName &&
        x.scenario_id === scenario_id &&
        x.additional_info.analysis_name === analysisName &&
        ![ANALYSIS_TYPES.POWERFLOW_REPORT].includes(x.activity_type),
    );
    return (
      entry !== undefined && [CANCELING, PENDING, POSTPROCESSING, RUNNING].includes(entry?.status)
    );
  };
  const cancelJob = async (jobId: string) => {
    try {
      const url = `/api/workspace/${workspace}/branch/${branch}/activity/${jobId}/cancel`;
      const req = new Request(url);
      const modifiedLogEntries = logEntriesData.slice();
      const cancelActivity = modifiedLogEntries.find(logEntry => logEntry.job_id === jobId);
      if (cancelActivity) {
        cancelActivity.status = CANCELING;
      }
      setLogEntriesData(modifiedLogEntries);
      await req.post(undefined);
    } catch (err) {}
  };
  useInterval(() => {
    try {
      refetch();
    } catch (err) {}
  }, INTERVAL);
  return (
    <ActivityLogContext.Provider
      value={{
        logEntries: logEntriesData,
        analysisActive,
        cancelJob,
      }}
    >
      {children}
    </ActivityLogContext.Provider>
  );
}
