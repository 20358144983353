import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const SearchIcon: FunctionComponent<IconPropsSchema> = ({ color, height, width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 15.6 15.6"
    width={width}
    height={height}
  >
    <g id="Symbol_3_1" transform="translate(7564 533)">
      <g id="Ellipse_10" transform="translate(1009 205.658)">
        <circle fill="none" cx="-8566.9" cy="-732.5" r="6.1" />
        <path
          fill={color}
          d="M-8566.9-726.4c-3.4,0-6.1-2.8-6.1-6.1c0-3.4,2.8-6.1,6.1-6.1s6.1,2.8,6.1,6.1
          C-8560.7-729.1-8563.5-726.4-8566.9-726.4z M-8566.9-736.7c-2.3,0-4.1,1.9-4.1,4.1s1.9,4.1,4.1,4.1c2.3,0,4.1-1.9,4.1-4.1
          S-8564.6-736.7-8566.9-736.7z"
        />
      </g>
      <rect
        x="-7552.7"
        y="-524.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1843.644 -5492.3853)"
        fill={color}
        width="2"
        height="7.2"
      />
    </g>
  </svg>
);

export default SearchIcon;
