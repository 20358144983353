import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';
import 'react-tabs/style/react-tabs.scss';
import ConfirmDelete from 'components/ConfirmDelete/ConfirmDelete';
import AttachmentsProvider from 'contexts/AttachmentsContext';
import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import { disableMasterWithImportON } from 'helpers/utils';
import Helpers from '../helpers/EquipmentLibraryHelpers';
import EquipmentLibraryLeftRail from './EquipmentLibraryLeftRail';
import InfoInstancePanel from './panels/InfoInstancePanel';
import EmptyLibraryPlaceholder from './panels/EmptyLibraryPlaceholder';
import NetworkTopNav from '../../../containers/NetworkTopNavContainer';
import './EquipmentLibrary.scss';

class EquipmentLibrary extends Component {
  state = {
    modalActive: false,
  };

  componentDidMount() {
    this.props.actions.loadEquipmentLibrary(
      this.props.match.params.workspace,
      this.props.match.params.branch,
      this.props.match.params.equipmentType,
      this.props.match.params.equipmentId,
    );
    this.props.actions.fetchBranches(this.props.match.params.workspace);
  }

  componentDidUpdate(prevProps) {
    // Update library on workspace/branch change
    if (
      prevProps.match.params.branch !== this.props.match.params.branch ||
      prevProps.match.params.workspace !== this.props.match.params.workspace
    ) {
      this.props.actions.loadEquipmentLibrary(
        this.props.match.params.workspace,
        this.props.match.params.branch,
      );
    }

    // Handle browser back button click to go to previously selected assets
    const { workspace, equipmentType, equipmentId } = this.props.match.params;
    const newSelection = prevProps.match.params.equipmentId !== equipmentId;
    const notSelected = this.props.selectedEquipment.id !== equipmentId;
    if (newSelection && notSelected) {
      this.props.actions.selectEquipment(workspace, equipmentType, equipmentId, false);
    }
    // Handle deletion status
    const { LOADING } = asyncActionStates;
    const { deleteStatus } = prevProps;
    const nextDeleteStatus = this.props.deleteStatus;
    if (deleteStatus === LOADING && nextDeleteStatus !== LOADING) {
      this.setState({ modalActive: false });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearEquipmentLibrary();
  }

  handleCreate = (type, values, classType) => {
    // Remove from object if blank (BE doesn't accept property with no value)
    if (type !== 'power_transformer_info') {
      const assetModelValues = { ...values.AssetModel };

      if (values.AssetModel?.estimatedUnitInstallationDays === '') {
        delete assetModelValues.estimatedUnitInstallationDays;
      }

      if (values.AssetModel?.EmissionRate?.emissionRate === '') {
        delete assetModelValues.EmissionRate;
      }
      if (
        values.AssetModel?.AssetFailureInfo?.mTTR === null &&
        values.AssetModel?.AssetFailureInfo?.probabilityOfFailureEquation === null
      ) {
        delete assetModelValues.AssetFailureInfo;
      }
      values = { ...values, AssetModel: { ...assetModelValues } };
    } else {
      if (values.TransformerTankInfos[0].AssetModel?.estimatedUnitInstallationDays === '') {
        delete values.TransformerTankInfos[0].AssetModel.estimatedUnitInstallationDays;
      }
      if (values.TransformerTankInfos[0].AssetModel?.EmissionRate?.emissionRate === '') {
        delete values.TransformerTankInfos[0].AssetModel.EmissionRate;
      }
      if (
        values.TransformerTankInfos[0].AssetModel?.AssetFailureInfo?.mTTR === null &&
        values.AssetModel?.AssetFailureInfo?.probabilityOfFailureEquation === null
      ) {
        delete values.TransformerTankInfos[0].AssetModel.AssetFailureInfo;
      }
    }
    const { workspace } = this.props.match.params;
    this.props.actions.createInfoInstance(workspace, type, values, classType);
  };

  handleEdit = (id, values) => {
    const { workspace } = this.props.match.params;
    // Remove from object if blank (BE doesn't accept property with no value)
    if (values.AssetModel?.estimatedUnitInstallationDays === '') {
      delete values.AssetModel.estimatedUnitInstallationDays;
    }
    if (values.AssetModel?.EmissionRate?.emissionRate === '') {
      delete values.AssetModel.EmissionRate;
    }
    if (
      values.AssetModel?.AssetFailureInfo?.mTTR === null &&
      values.AssetModel?.AssetFailureInfo?.probabilityOfFailureEquation === null
    ) {
      delete values.AssetModel.AssetFailureInfo;
    }
    this.props.actions.updateInfoInstance(workspace, id, values);
  };

  handleSelect = (equipmentType, equipmentId) => {
    const { workspace } = this.props.match.params;
    this.props.actions.selectEquipment(workspace, equipmentType, equipmentId);
  };

  deleteItem = () => {
    const { workspace } = this.props.match.params;

    const categories = [...this.props.eqInfoCategories.values()];

    this.props.actions.deleteEquipmentType(categories, workspace);
  };

  deleteModalConfig = totalSelected => {
    const item = 'an equipment type';
    const { deleteStatus } = this.props;
    const addS = totalSelected > 1 ? 'these items' : 'this item';
    const modalBody = {
      message1: `Once ${item} has been deleted, it cannot be restored.`,
      message2: `Would you like to permanently remove ${addS}?`,
    };

    return {
      modalActive: this.state.modalActive,
      closeModal: () => this.setState({ modalActive: false }),
      deleteItem: this.deleteItem,
      workspace: this.props.match.params.workspace,
      branch: 'master',
      deleteStatus,
      deletedItemDesc: 'Equipment Type',
      modalBody,
    };
  };

  openModal = () => {
    this.setState({ modalActive: true });
  };

  hasInstances = categories => {
    const values = Object.entries(categories);
    return values.reduce((hasInstances, [key, list]) => {
      if (key !== 'objects' && !hasInstances) {
        return list.length > 0;
      }
      return hasInstances;
    }, false);
  };

  getPanelContents = (partiallySelected, allSelected, someReferenced, totalSelected) => {
    const { library, selectedEquipment, libraryLoadingState } = this.props;
    const { settings } = this.context;

    const hasEqInstances = this.hasInstances(library);

    const libraryLoading = libraryLoadingState === asyncActionStates.LOADING;

    const disableDelete = disableMasterWithImportON(
      this.props.match.params.branch,
      settings.automaticImport,
    );
    const canDelete =
      !this.props.isAuthEnabled || this.props.permissions.has('delete_equipment_type');

    const canEditBranch =
      this.props.match.params.branch !== 'master' ||
      this.props.permissions.has('modify_network_as_built');

    const tooltipContent = () => {
      if (someReferenced) return 'You can only delete equipment types that are not in use.';

      if (!canEditBranch) return "You don't have permissions to edit the as built branch.";

      if (disableDelete)
        return 'Can not delete from As built version when automatic Import is enabled';

      return "You don't have delete permissions";
    };

    if (libraryLoading) {
      // Show loading overlay if library request is in progress
      return (
        <div className="loading-overlay">
          <div className="loading-tile">
            <p>Fetching Library</p>
            <i className="material-icons rotate" style={{ fontSize: 40 }}>
              refresh
            </i>
          </div>
        </div>
      );
    }
    if (totalSelected > 0) {
      // Show bulk actions screen if there are infos selected with their checkboxes
      return (
        <div className="info-selection-page bulk-actions-panel">
          <p className="selection-total">
            {totalSelected} equipment type
            {totalSelected > 1 ? 's' : ''} selected
          </p>
          <PermissionDisabledTip
            hide={canDelete && canEditBranch && !someReferenced && !disableDelete}
            placement="bottom"
            message={tooltipContent()}
            title="Delete"
          >
            <button
              id="delete-btn"
              disabled={someReferenced || !canDelete || !canEditBranch || disableDelete}
              className="selected-info-option"
              onClick={this.openModal}
              type="button"
            >
              <i className="material-icons">delete</i>
              Delete
            </button>
          </PermissionDisabledTip>
          <button
            className="selected-info-option"
            id="clear-btn"
            onClick={this.props.actions.handleClearSelections}
            type="button"
          >
            <i className="material-icons">highlight_off</i>
            Clear Selection
          </button>
        </div>
      );
    }
    if (selectedEquipment.id) {
      // Show the info details panel if there is an info selected
      return (
        <InfoInstancePanel
          selected={library.objects[selectedEquipment.id]}
          hasImages={false}
          handleCreate={this.handleCreate}
          handleEdit={this.handleEdit}
          createInstanceReq={this.props.createInstanceReq}
          permissions={this.props.permissions}
          isAuthEnabled={this.props.isAuthEnabled}
          type={selectedEquipment.type}
          match={this.props.match}
          saveLibraryError={this.props.saveLibraryError}
        />
      );
    }
    if (hasEqInstances) {
      // Show a placeholder if there are library info instances but nothing selected
      return (
        <div className="info-selection-page info-selection-placeholder" id="no-selections">
          <ReactSVG src="/asset_info_panel_empty.svg" className="svg-icon large" />
          <p>Click on an equipment type to view its properties.</p>
        </div>
      );
    }

    // Show a placeholder if there are no library info instances
    return (
      <EmptyLibraryPlaceholder
        selectEquipment={this.handleSelect}
        updateExpandedCategories={this.props.actions.updateExpandedCategories}
      />
    );
  };

  render() {
    const {
      actions,
      library,
      selectedEquipment,
      libraryLoadingState,
      eqInfoCategories,
    } = this.props;
    const [
      partiallySelected,
      allSelected,
      someReferenced,
      totalSelected,
    ] = Helpers.getSelectedStatus(eqInfoCategories, library);
    return (
      <>
        <NetworkTopNav params={this.props.match.params} />
        <AttachmentsProvider
          workspace={this.props.match.params.workspace}
          branch={this.props.match.params.branch}
          assetID={selectedEquipment?.id ?? null}
        >
          <div className="equipment-library-container">
            <div className="equipment-library">
              <div className="library-section">
                <EquipmentLibraryLeftRail
                  library={library}
                  actions={actions}
                  selectedEquipment={selectedEquipment}
                  eqInfoCategories={this.props.eqInfoCategories}
                  {...{
                    partiallySelected,
                    allSelected,
                    someReferenced,
                    totalSelected,
                  }}
                  libraryLoadingState={libraryLoadingState}
                  permissions={this.props.permissions}
                  isAuthEnabled={this.props.isAuthEnabled}
                  openModal={this.openModal}
                  expandedCategories={this.props.expandedCategories}
                  selectEquipment={this.handleSelect}
                  match={this.props.match}
                />
                <div className="tab-section">
                  {this.getPanelContents(
                    partiallySelected,
                    allSelected,
                    someReferenced,
                    totalSelected,
                  )}
                </div>
              </div>
            </div>
            <ConfirmDelete {...this.deleteModalConfig(totalSelected)} />
          </div>
        </AttachmentsProvider>
      </>
    );
  }
}

EquipmentLibrary.contextType = WorkspaceSettingsContext;

EquipmentLibrary.defaultProps = {
  deleteStatus: asyncActionStates.INITIAL,
};

EquipmentLibrary.propTypes = {
  actions: PropTypes.shape({
    loadEquipmentLibrary: PropTypes.func,
    selectEquipment: PropTypes.func,
    clearEquipmentLibrary: PropTypes.func,
    createInfoInstance: PropTypes.func,
    updateInfoInstance: PropTypes.func,
    handleClearSelections: PropTypes.func,
    deleteEquipmentType: PropTypes.func,
    updateExpandedCategories: PropTypes.func,
    setCurrentWorkspace: PropTypes.func,
    fetchBranches: PropTypes.func,
    setView: PropTypes.func,
  }).isRequired,
  library: PropTypes.shape({
    batteries: PropTypes.array,
    capacitors: PropTypes.array,
    conductors: PropTypes.array,
    wireGeometries: PropTypes.array,
    reactors: PropTypes.array,
    switches: PropTypes.array,
    transformers: PropTypes.array,
    inverters: PropTypes.array,
    photovoltaics: PropTypes.array,
    winds: PropTypes.array,
    objects: PropTypes.object,
  }).isRequired,
  selectedEquipment: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  eqInfoCategories: PropTypes.object.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  libraryLoadingState: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  deleteStatus: PropTypes.number,
  expandedCategories: PropTypes.instanceOf(Set).isRequired,
  saveLibraryError: PropTypes.object.isRequired,
};

export default EquipmentLibrary;
