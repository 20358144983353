import React, { FunctionComponent, useState } from 'react';
import CustomCheckbox from 'components/CustomCheckbox';
import ExpandableSection from 'components/ExpandableSection';
import { selectedDERType } from 'types/analysis';
import './DERAssetSelector.scss';
import { TYPE_MAP } from '../../helpers/NetworkHelpers';
import SingleDERAssetRow from './SingleDERAssetRow';

type DERAssetSelectorType = {
  workspace: string;
  branch: string;
  selectedContainers?: { id: string }[];
  selectedDERs: selectedDERType;
  setSelectedDERs: React.Dispatch<React.SetStateAction<selectedDERType>>;
};

const derAssetsDefaults = {
  Battery: false,
  InverterPV: false,
  SynchronousMachine: false,
  RunOfRiverHydro: false,
  CHP: false,
};
const DERAssetSelector: FunctionComponent<DERAssetSelectorType> = ({
  workspace,
  branch,
  selectedContainers,
  selectedDERs,
  setSelectedDERs,
}) => {
  const [selDERAssetType, setSelDERAssetType] = useState<{ [key: string]: boolean }>(
    derAssetsDefaults,
  );
  const [openDERAssetType, setOpenDERAssetType] = useState<{ [key: string]: boolean }>(
    derAssetsDefaults,
  );
  const [dERAssetTypeLoaded, setdERAssetTypeLoaded] = useState<{ [key: string]: boolean }>(
    derAssetsDefaults,
  );
  const assetName = TYPE_MAP as { [assetClass: string]: { [key: string]: string } };

  return (
    <div className="der-asset-selector">
      <div className="menu-body">
        <div className="menu-row selected-der-row">
          {Object.keys(derAssetsDefaults).map(assetType => (
            <ExpandableSection
              key={assetType}
              headerClickable={false}
              open={openDERAssetType[assetType]}
              toggleClick={() => {
                setOpenDERAssetType(() => ({
                  ...openDERAssetType,
                  [assetType]: !openDERAssetType[assetType],
                }));
              }}
              renderHeaderContent={() => {
                const selDersArr = Object.values(selectedDERs);
                const selDerAssetInType = selDersArr.filter(
                  der => der.class === assetType && der.isSelected,
                )?.length;

                const assetCheckedViaGIS = selDersArr.find(
                  item => item.disabled && item.isSelected && item.id,
                );

                const totalDerAssetInType = selDersArr.filter(der => der.class === assetType)
                  ?.length;
                return (
                  <div className="flex-vertical-center asset-type-row">
                    <div className="flex-vertical-center">
                      <CustomCheckbox
                        id="#assets-to-all-nodes"
                        checked={selDERAssetType[assetType] || selDerAssetInType > 0}
                        type="primary"
                        onClick={e => {
                          setSelDERAssetType({
                            ...selDERAssetType,
                            [assetType]: (e.target as HTMLInputElement).checked,
                          });
                          const newDERs: selectedDERType = {};
                          Object.entries(selectedDERs).forEach(([id, derObj]) => {
                            if (derObj.class === assetType) {
                              newDERs[id] = {
                                ...derObj,
                                isSelected: (e.target as HTMLInputElement).checked,
                              };
                            }
                          });
                          setSelectedDERs(prevState => ({ ...prevState, ...newDERs }));
                        }}
                        dash={selDerAssetInType !== totalDerAssetInType}
                        disabled={
                          totalDerAssetInType === 0 ||
                          (assetCheckedViaGIS?.disabled &&
                            assetName[assetType]?.name === assetCheckedViaGIS?.class)
                        }
                      />
                      <span>{assetName[assetType]?.name}</span>
                    </div>
                    {dERAssetTypeLoaded[assetType] && (
                      <span className="der-asset-count">
                        {selDerAssetInType}/{totalDerAssetInType} selected
                      </span>
                    )}
                  </div>
                );
              }}
            >
              <SingleDERAssetRow
                workspace={workspace}
                branch={branch}
                selectedContainers={selectedContainers}
                setSelectedDERs={setSelectedDERs}
                selectedDERs={selectedDERs}
                assetClass={assetType}
                setdERAssetTypeLoaded={setdERAssetTypeLoaded}
              />
            </ExpandableSection>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DERAssetSelector;
