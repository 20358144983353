const getFormattedValue = (
  value: string | number | null,
  divisor: number,
  precision: number | null,
): string | number | null => {
  if (value === null || value === '' || Number.isNaN(value)) {
    return value;
  }
  let formattedValue: string | number | null = value;
  // If the value is anything but empty, format to the correct units
  const inUnits = (value as number) / divisor;
  formattedValue =
    inUnits === 0 ? inUnits : inUnits.toFixed(Number.isNaN(precision) ? 3 : precision || 0);
  if (precision) {
    // If a precision is provided ensure the value does not exceed the given precision
    const isOkay = `${formattedValue}`.match(`/^(d{1,10}|d{0,10}.d{1,${precision}})$/`);
    const multiplier = 10 ** precision;
    if (!isOkay && typeof formattedValue === 'string') {
      formattedValue = (parseFloat(formattedValue) * multiplier) / multiplier;
    }
  }
  return formattedValue;
};

export default getFormattedValue;
