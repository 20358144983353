/* eslint-disable react/no-did-update-set-state */
// Disabled until preset is updated to allow for set state with conditionals https://reactjs.org/docs/react-component.html#componentdidupdate
import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';
import { ThemeProp } from 'types/index';
import {
  ZoomLevel,
  SubhourlyRanges,
  UnitOfTime,
  determineDefaultZoomLevel,
  getEndOfInterval,
  getStartOfInterval,
  getIntervalSize,
  getSubHourIntervalType,
  getTickCount,
} from './intervals';
import CalendarPicker from '../CalendarPicker';
import RangeSlider from '../SimpleRangeSlider';

import './ZoomableRangeSlider.scss';

const unitsOfTime: UnitOfTime[] = [
  UnitOfTime.YEARS,
  UnitOfTime.MONTHS,
  UnitOfTime.DAYS,
  UnitOfTime.HOURS,
  UnitOfTime.MINUTES,
];

type ZoomableRangeSliderProps = {
  timebarStart: moment.Moment;
  timebarEnd: moment.Moment;
  selectedStart: moment.Moment;
  selectedEnd: moment.Moment;
  zoomLevel: ZoomLevel;
  theme: ThemeProp;
  violations: any[];
  onSelectedChanged: (startDate: moment.Moment, endDate: moment.Moment) => void;
  onTimebarChanged: (
    timebarStart: moment.Moment,
    timebarEnd: moment.Moment,
    selectedStart: moment.Moment,
    selectedEnd: moment.Moment,
    zoomLevel: ZoomLevel,
  ) => void;
  onViewChanged: (
    start: moment.Moment,
    end: moment.Moment,
    zoomLevel: ZoomLevel,
  ) => Promise<{
    powerflow: moment.Moment[] | null;
    hostingCapacity: moment.Moment[] | null;
    scenario: moment.Moment[] | null;
    evCapacity: moment.Moment[] | null;
    violations: string[] | null;
  }>;
  selectedAnalysis: string | null;
  showCalendarPicker?: boolean;
  subHourInterval?: number;
};

export const ZoomableRangeSlider: FunctionComponent<ZoomableRangeSliderProps> = ({
  timebarStart,
  timebarEnd,
  selectedStart,
  selectedEnd,
  zoomLevel,
  theme,
  violations,
  onSelectedChanged,
  onTimebarChanged,
  onViewChanged,
  selectedAnalysis,
  showCalendarPicker = true,
  subHourInterval = 60,
}) => {
  const [multiplier, unitOfTime] = getIntervalSize(zoomLevel);
  const totalTicks = getTickCount(zoomLevel, timebarStart, timebarEnd, subHourInterval);

  const timepoints = useMemo(() => {
    const tps = [];
    for (let i = 0; i < totalTicks; i += 1) {
      tps.push(moment.utc(timebarStart).add(i * multiplier, unitOfTime));
    }
    return tps;
  }, [multiplier, unitOfTime, timebarStart, totalTicks]);
  const selectedValues = useMemo<[number, number]>(() => {
    const startIdx = timepoints.findIndex(tp => tp.isSame(selectedStart));
    const endIdx =
      timepoints.findIndex(tp => tp.isSame(getStartOfInterval(selectedEnd, zoomLevel))) + 1;
    return [startIdx, endIdx];
  }, [timepoints, zoomLevel, selectedStart, selectedEnd]);

  const showZoom =
    unitOfTime !== UnitOfTime.MINUTES && (unitOfTime !== UnitOfTime.HOURS || subHourInterval < 60);

  const tickFormatter = useMemo(
    () => (value: number): string => {
      let offset: number;
      switch (zoomLevel) {
        case UnitOfTime.YEARS:
          return `Year ${value + 1} (${timebarStart.year() + value})`;
        case UnitOfTime.MONTHS:
          offset = timebarStart.month();
          return moment()
            .month(value + offset)
            .format('MMM');
        case UnitOfTime.DAYS:
          offset = timebarStart.date();
          return moment(timebarStart)
            .date(value + offset)
            .format('DD');
        case UnitOfTime.HOURS:
          offset = timebarStart.hour();
          return moment(timebarStart)
            .hour(value + offset)
            .format('HH');
        case SubhourlyRanges.MINUTES_30:
        case SubhourlyRanges.MINUTES_15:
        case SubhourlyRanges.MINUTES_5:
        case UnitOfTime.MINUTES:
          offset = timebarStart.minute();
          let format = 'mm';
          if (subHourInterval > 1) format = 'HH:mm';
          return moment(timebarStart)
            .minute(offset + value * subHourInterval)
            .format(format);
        default:
          return '';
      }
    },
    [timebarStart, subHourInterval, zoomLevel],
  );

  const setTimebarRange = (
    newTimebarRange: { start: moment.Moment; end: moment.Moment },
    newZoomLevel: ZoomLevel,
    newSelectedRange?: { start: moment.Moment; end: moment.Moment },
  ): void => {
    const newSelectedStart = newSelectedRange?.start ?? moment(newTimebarRange.start);
    const newSelectedEnd =
      newSelectedRange?.end ?? getEndOfInterval(newSelectedStart, newZoomLevel);

    onTimebarChanged(
      newTimebarRange.start,
      newTimebarRange.end,
      newSelectedStart,
      newSelectedEnd,
      newZoomLevel,
    );
  };

  const handleZoomIn = (): void => {
    const currentUnitOfTime = getIntervalSize(zoomLevel)[1];
    const currentZoomIndex = unitsOfTime.indexOf(currentUnitOfTime);
    if (currentZoomIndex === 4 || (currentZoomIndex === 3 && subHourInterval === 60)) {
      return;
    }

    let nextUnitOfTime: ZoomLevel = unitsOfTime[currentZoomIndex + 1];
    if (nextUnitOfTime === UnitOfTime.MINUTES) {
      nextUnitOfTime = getSubHourIntervalType(subHourInterval);
    }

    setTimebarRange(
      {
        start: moment(selectedStart).startOf(currentUnitOfTime),
        end: moment(selectedStart).endOf(currentUnitOfTime),
      },
      nextUnitOfTime,
    );
  };

  const handleZoomOut = (): void => {
    const currentZoomIndex = unitsOfTime.indexOf(getIntervalSize(zoomLevel)[1]);
    if (currentZoomIndex === 0) {
      return;
    }

    const nextZoomLevel = unitsOfTime[currentZoomIndex - 1];
    const nextZoomRange = unitsOfTime[currentZoomIndex - 2];

    const newTimebarRange = {
      start: nextZoomRange
        ? moment(selectedStart).startOf(nextZoomRange)
        : moment(selectedStart).startOf('year'),
      end: nextZoomRange
        ? moment(selectedStart).endOf(nextZoomRange)
        : moment(selectedStart).add(9, 'year').endOf('year'),
    };
    const newSelectedRange = {
      start: moment(selectedStart),
      end: getEndOfInterval(selectedEnd, nextZoomLevel),
    };
    setTimebarRange(newTimebarRange, nextZoomLevel, newSelectedRange);
  };

  const setDisplayRange = (newSelectedIndices: [number, number]): void => {
    onSelectedChanged(
      timepoints[newSelectedIndices[0]],
      getEndOfInterval(timepoints[newSelectedIndices[1] - 1], zoomLevel),
    );
  };

  const applyCalendarPickerChange = (
    startDate: string,
    endDate: string,
    calendarMode: string,
  ): void => {
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);

    const numYears = moment(end).diff(start, 'years');
    const numMonths = moment(end).diff(start, 'months');
    const numDays = moment(end).diff(start, 'days');
    const numHours = moment(end).diff(start, 'hours');
    let newZoomLevel: ZoomLevel = UnitOfTime.HOURS;
    switch (calendarMode) {
      case 'years':
        if (numYears === 0) {
          newZoomLevel = UnitOfTime.MONTHS;
        } else {
          newZoomLevel = UnitOfTime.YEARS;
        }
        break;
      case 'months':
        if (numMonths === 0) {
          newZoomLevel = UnitOfTime.DAYS;
        } else if (numMonths >= 5 * 12) {
          newZoomLevel = determineDefaultZoomLevel(start, end, subHourInterval);
        } else {
          newZoomLevel = UnitOfTime.MONTHS;
        }
        break;
      case 'days':
        if (numDays === 0) {
          newZoomLevel = UnitOfTime.HOURS;
        } else if (numMonths >= 2) {
          newZoomLevel = determineDefaultZoomLevel(start, end, subHourInterval);
        } else {
          newZoomLevel = UnitOfTime.DAYS;
        }
        break;
      case 'hours':
        if (numHours === 0 && subHourInterval < 60) {
          newZoomLevel = getSubHourIntervalType(subHourInterval);
        } else if (numDays >= 2) {
          newZoomLevel = determineDefaultZoomLevel(start, end, subHourInterval);
        } else {
          newZoomLevel = UnitOfTime.HOURS;
        }
        break;
      case 'minutes':
        const fractionOfHour = subHourInterval / 60;
        const numTicks = numHours / fractionOfHour;
        if (subHourInterval === 60 || numTicks >= 24) {
          newZoomLevel = determineDefaultZoomLevel(start, end, subHourInterval);
        } else {
          newZoomLevel = getSubHourIntervalType(subHourInterval);
        }
        break;
    }

    const newTimebarRange = { start, end: getEndOfInterval(end, newZoomLevel) };
    setTimebarRange(newTimebarRange, newZoomLevel);
  };

  return (
    <div className="zoomable-range-slider">
      <div className="range-controls">
        <div className="zoom-placeholder" />
        {showCalendarPicker && (
          <>
            <div>
              <CalendarPicker
                startDate={timebarStart.format()}
                endDate={timebarEnd.format()}
                onApply={applyCalendarPickerChange}
                onViewChanged={onViewChanged}
                theme={theme}
                dots={['powerflow', 'hosting-capacity', 'ev-capacity', 'scenario', 'violations']}
                selectedAnalysis={selectedAnalysis}
                minutesDisabled={subHourInterval === 60}
                subHourInterval={subHourInterval}
              />
            </div>
            <div className="zoom-placeholder">
              {unitsOfTime.indexOf(unitOfTime) !== 0 && (
                <button
                  className="jump-btn"
                  id="zoom-out-btn"
                  onClick={handleZoomOut}
                  type="button"
                >
                  <i className="material-icons">zoom_out</i>
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <div className="icon-row">
        {timepoints.map(date => (
          <div className="icon-container" id={date.toISOString()} key={date.toISOString()}>
            {violations.some(val => date.isSame(moment.utc(val), unitOfTime)) && (
              <i className="material-icons icon">warning</i>
            )}
          </div>
        ))}
      </div>
      <RangeSlider
        highlightColor="rgba(47, 216, 206, 0.3)"
        tickFormatter={tickFormatter}
        totalTicks={totalTicks}
        selectedValues={selectedValues}
        onChange={setDisplayRange}
        onZoomIn={handleZoomIn}
        showZoom={showZoom}
        skipOne={
          unitsOfTime.indexOf(unitOfTime) < 4 ||
          (zoomLevel === UnitOfTime.MINUTES && subHourInterval === 1)
        }
      />
    </div>
  );
};

export default ZoomableRangeSlider;
