import RotatingMachine from './RotatingMachine';

class SynchronousMachine extends RotatingMachine {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'generator';
    this._class = 'SynchronousMachine';
    this.displayName = 'Synchronous Machine';
    this.validationSchema = {
      ...this.validationSchema,
      maxOperatingP: { required: this.maxOperatingP !== undefined },
      minOperatingP: { required: this.minOperatingP !== undefined },
    };
  }
}

export default SynchronousMachine;
