import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const NotificationIcon: FunctionComponent<IconPropsSchema> = ({
  color = '#133E52',
  height,
  width,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Notification</title>
    <desc>Created with Sketch.</desc>
    <g id="Notifications" stroke="none" strokeWidth="1" fill={color} fillRule="evenodd">
      <g
        id="MP---View-notifications"
        transform="translate(-1333.000000, -27.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <path
          d="M1341.5,48 C1342.66875,48 1343.625,47.0307692 1343.625,45.8461538 L1339.375,45.8461538 C1339.375,47.0307692 1340.32063,48 1341.5,48 Z M1347.875,41.5384615 L1347.875,36.1538462 C1347.875,32.8476923 1346.1325,30.08 1343.09375,29.3476923 L1343.09375,28.6153846 C1343.09375,27.7215385 1342.38188,27 1341.5,27 C1340.61812,27 1339.90625,27.7215385 1339.90625,28.6153846 L1339.90625,29.3476923 C1336.85687,30.08 1335.125,32.8369231 1335.125,36.1538462 L1335.125,41.5384615 L1333,43.6923077 L1333,44.7692308 L1350,44.7692308 L1350,43.6923077 L1347.875,41.5384615 Z"
          id="Notification"
        />
      </g>
    </g>
  </svg>
);

export default NotificationIcon;
