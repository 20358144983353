import AsynchronousMachine from './AsynchronousMachine';

class Wind extends AsynchronousMachine {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'wind';
    this._class = 'Wind';
    this.displayName = 'Wind';
  }
}

export default Wind;
