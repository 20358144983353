import CloseIcon from './CloseIcon';
import LayersIcon from './LayersIcon';
import PlusIcon from './PlusIcon';
import MinusIcon from './MinusIcon';
import CheckmarkIcon from './CheckmarkIcon';
import GroupByHierarchyIcon from './GroupByHierarchyIcon';
import GroupByPackageIcon from './GroupByPackageIcon';
import MappingIcon from './MappingIcon';
import NotificationIcon from './NotificationIcon';
import QualifiedNameIcon from './QualifiedNameIcon';
import ResetIcon from './ResetIcon';
import SearchIcon from './SearchIcon';
import ShowEmptyAttributeIcon from './ShowEmptyAttributeIcon';
import SortingIcon from './SortingIcon';
import WarningIcon from './WarningIcon';
import ExpandIcon from './ExpandIcon';
import CollapseIcon from './CollapseIcon';
import CirclePlusIcon from './CirclePlusIcon';
import ArrowDown from './ArrowDown';
import DownloadIcon from './DownloadIcon';
import RefreshIcon from './RefreshIcon';
import TargetIcon from './TargetIcon';
import ControlSettingsIcon from './ControlSettingsIcon';
import PencilEditorIcon from './PencilEditorIcon';
import './Icons.scss';

const Icons = {
  CloseIcon,
  LayersIcon,
  PlusIcon,
  MinusIcon,
  CheckmarkIcon,
  GroupByPackageIcon,
  GroupByHierarchyIcon,
  MappingIcon,
  NotificationIcon,
  QualifiedNameIcon,
  ResetIcon,
  SearchIcon,
  ShowEmptyAttributeIcon,
  SortingIcon,
  WarningIcon,
  ExpandIcon,
  CollapseIcon,
  CirclePlusIcon,
  ArrowDown,
  DownloadIcon,
  RefreshIcon,
  TargetIcon,
  ControlSettingsIcon,
  PencilEditorIcon,
};
export default Icons;
