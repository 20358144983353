import React, { FunctionComponent, ReactNode, ReactChild } from 'react';
import { ThemeProp } from 'types/index';

type LoadingSkeletonProps = {
  theme?: ThemeProp;
  className?: string;
  children?: ReactChild | ReactNode;
  template?: 'head-line' | 'line' | 'square' | 'circle' | null;
  width?: number;
  count?: number;
  height?: number;
  displayStyle?: 'column' | 'row';
};

const LoadingSkeleton: FunctionComponent<LoadingSkeletonProps> = ({
  className = '',
  children = null,
  template = 'line',
  width = 80,
  count = 1,
  height = 15,
  displayStyle = 'row',
}) => {
  const lineStyle = { height: `${height}px` };
  const style = template === 'circle' ? { width: `${width}px`, height: `${width}px` } : lineStyle;
  return (
    <div className={`loading-skeleton ${className}`} data-test="loading-skeleton">
      <div className={`ssc ${displayStyle}`}>
        {template &&
          [...Array(count).keys()].map((e: number) => (
            <div className={`ssc-${template} w-${width}`} style={style} key={e} />
          ))}
        {children && children}
      </div>
    </div>
  );
};

export default LoadingSkeleton;
