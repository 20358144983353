/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import asyncActionStates from 'helpers/asyncActionStates';
import Card from 'components/Card';
import Select from 'components/Select';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { graphHeight } from 'helpers/utils';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { isHourlyOrSubHourly } from 'components/ZoomableRangeSlider/intervals.ts';
import './VoltagePUHistogram.scss';

const { LOADING } = asyncActionStates;

const CustomTooltip = props => {
  const { payload, active, label, bucketSize } = props;

  if (active) {
    const value = parseFloat(label);
    const ibucketSize = 1 / bucketSize;
    const start = (Math.round((value - bucketSize / 2) * ibucketSize) / ibucketSize).toFixed(3);
    const end = (Math.round((value + bucketSize / 2) * ibucketSize) / ibucketSize).toFixed(3);
    return (
      <div className="custom-tooltip">
        <p className="label">{`Voltage [p.u.]: ${start} - ${end}`}</p>
        <p className="label">{`No. of nodes: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.defaultProps = {
  payload: [],
  active: false,
  label: '',
  bucketSize: 0.01,
};

CustomTooltip.propTypes = {
  payload: PropTypes.array,
  active: PropTypes.bool,
  label: PropTypes.string,
  bucketSize: PropTypes.number,
};

const CustomizedAxisTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text className="custom-tick" x={0} y={0} dy={16} textAnchor="end">
        {payload.value}
      </text>
    </g>
  );
};

CustomizedAxisTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};
CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

const emptyHistogramData = () => ({
  ABC: {
    avg: [],
    min: [],
    max: [],
  },
  A: {
    avg: [],
    min: [],
    max: [],
  },
  B: {
    avg: [],
    min: [],
    max: [],
  },
  C: {
    avg: [],
    min: [],
    max: [],
  },
});

class VoltagePUHistogram extends Component {
  state = {
    phase: 'ABC',
    aggregation: 'avg',
    bucketSize: 0.01,
    histogramData: emptyHistogramData(),
  };

  componentDidMount() {
    this.calculateHistogramData();
  }

  componentDidUpdate(prevProps) {
    const newNodes = this.props.nodes !== prevProps.nodes;
    const newSVs = this.props.results !== prevProps.results;

    const loadingResults =
      this.props.resultsRequest === LOADING && prevProps.resultsRequest !== LOADING;

    if (newNodes || newSVs) {
      this.graphPlaceholder();
      this.calculateHistogramData();
    } else if (loadingResults) {
      this.setState({ histogramData: emptyHistogramData() });
    }
  }

  calculateHistogramData = () => {
    let puVoltagesAgg;
    const histogramData = emptyHistogramData();
    const { nodes, results } = this.props;
    if (nodes.length > 0 && results) {
      const puVoltages = nodes
        .map(nodeID => results[nodeID])
        .filter(result => !!result)
        .map(result => result.puVoltage);
      puVoltagesAgg = puVoltages.reduce((arr, node) => {
        const aggType = ['avg', 'min', 'max'];
        aggType.forEach(agg => {
          const phases = ['ABC', 'A', 'B', 'C'];
          phases.forEach(phase => {
            const key = `${phase === 'ABC' ? `${phase}_avg` : `${phase}`}_${agg}`;
            if (node[key]) arr[phase][agg].push(node[key]);
          });
        });
        return arr;
      }, emptyHistogramData());
      Object.keys(puVoltagesAgg).forEach(phase => {
        const { bucketSize } = this.state;
        let phaseAgg_min;
        let phaseAgg_max;
        const aggTypes = ['avg', 'min', 'max'];
        aggTypes.forEach(agg => {
          const phaseAgg = puVoltagesAgg[phase][agg];
          if (phaseAgg && phaseAgg.length > 0) {
            phaseAgg_min = Math.floor(Math.min(...phaseAgg) * (1 / bucketSize)) / (1 / bucketSize);
            phaseAgg_max = Math.ceil(Math.max(...phaseAgg) * (1 / bucketSize)) / (1 / bucketSize);
            const numBuckets = Math.ceil((phaseAgg_max - phaseAgg_min) / bucketSize) || 0;
            const rangeBreaks = [];

            for (let i = 0; i < numBuckets; i += 1) {
              if (phaseAgg_min + i * bucketSize < phaseAgg_max) {
                rangeBreaks.push(phaseAgg_min + i * bucketSize);
              }
            }
            rangeBreaks.push(phaseAgg_max);
            const nodeCounts = rangeBreaks.slice(0, -1).map((rb, i) => ({
              midRange: `${(rangeBreaks[i] + bucketSize / 2).toFixed(3)}`,
              nodes: phaseAgg.filter(v => v >= rb && v < rangeBreaks[i + 1]).length,
            }));
            histogramData[phase][agg] = nodeCounts;
          }
        });
      });
    }
    this.setState({
      histogramData,
    });
  };

  graphPlaceholder = () => {
    const { histogramData, phase, aggregation } = this.state;
    const { aggType, resultsRequest, timeRange } = this.props;
    const isHourlyOrSub = isHourlyOrSubHourly(aggType);
    const oneIntervalInMinutes = aggType === 'hour' ? 60 : aggType.split('_')[1];
    const numMinutesInRange =
      timeRange.start && timeRange.end
        ? Math.ceil(moment.duration(timeRange.end.diff(timeRange.start, 'm')))
        : 0;
    let text = 'No results available for time range';
    if (isHourlyOrSub && numMinutesInRange > oneIntervalInMinutes) {
      text = `Please select a single ${
        aggType === 'hour' ? 'hour' : `${oneIntervalInMinutes} minute interval`
      }`;
    } else if (
      aggType?.length === 0 ||
      !histogramData[phase] ||
      histogramData[phase][aggregation].length === 0
    ) {
      text =
        resultsRequest === LOADING ? (
          <LoadingSkeleton template="square" height={225} width={303} />
        ) : (
          <p>{text}</p>
        );
    }
    return (
      <div className={`graph-placeholder ${resultsRequest === LOADING ? 'loading' : ''}`}>
        {text}
      </div>
    );
  };

  render() {
    const { histogramData, phase, aggregation, bucketSize } = this.state;
    const { aggType } = this.props;
    const isHourlyOrSub = isHourlyOrSubHourly(aggType);
    return (
      <Card theme={this.props.theme} className="voltage-histogram" hideTitle>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <p>Phase</p>
            <Select
              options={[
                { label: 'ABC Average', value: 'ABC' },
                { label: 'A', value: 'A' },
                { label: 'B', value: 'B' },
                { label: 'C', value: 'C' },
              ]}
              value={phase}
              clearable={false}
              searchable={false}
              className="option-selector"
              theme={this.props.theme}
              width={125}
              onChange={({ value }) => this.setState({ phase: value })}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <p>Voltage Range</p>
            <Select
              options={[
                { label: '0.005', value: '0.005' },
                { label: '0.01', value: '0.01' },
                { label: '0.02', value: '0.02' },
                { label: '0.05', value: '0.05' },
                { label: '0.1', value: '0.1' },
              ]}
              value={bucketSize.toString()}
              clearable={false}
              searchable={false}
              className="option-selector"
              theme={this.props.theme}
              width={125}
              onChange={({ value }) =>
                this.setState({ bucketSize: parseFloat(value) }, this.calculateHistogramData)
              }
            />
          </div>
        </div>
        <div className="phase-selection-message">
          {this.state.phase === 'ABC' && (
            <span className="caption-text">Average value of all 3 phases</span>
          )}
        </div>
        <div className="legend">
          <div className="legend-entry pu-distance-legend">
            <div className="axis-box" />
            <div>Per Unit Voltage</div>
          </div>
        </div>
        {histogramData[phase] && histogramData[phase][aggregation].length > 0 && isHourlyOrSub && (
          <ResponsiveContainer width="100%" height={graphHeight(this.props.expanded)}>
            <BarChart
              data={this.state.histogramData[phase][aggregation]}
              margin={{
                top: 15,
                right: 20,
                bottom: 10,
                left: 0,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <XAxis
                dataKey="midRange"
                height={60}
                interval={bucketSize === 0.005 ? 1 : 0}
                tick={<CustomizedAxisTick />}
              />
              <YAxis
                width={25}
                style={{ fontSize: '10px' }}
                allowDecimals={false}
                domain={[0, 'dataMax + 1']}
              />
              <Tooltip content={<CustomTooltip bucketSize={this.state.bucketSize} />} />
              <Bar dataKey="nodes" fill="#de6e00" />
            </BarChart>
          </ResponsiveContainer>
        )}
        {(!histogramData[phase] ||
          histogramData[phase][aggregation].length === 0 ||
          !isHourlyOrSub) &&
          this.graphPlaceholder()}
      </Card>
    );
  }
}

VoltagePUHistogram.defaultProps = {
  resultsRequest: 0,
  nodes: [],
  results: {},
  aggType: '',
  timeRange: {},
  expanded: false,
};

VoltagePUHistogram.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.string.isRequired,
  resultsRequest: PropTypes.number,
  aggType: PropTypes.string,
  timeRange: PropTypes.object,
  expanded: PropTypes.bool,
  results: PropTypes.object,
};

export default VoltagePUHistogram;
