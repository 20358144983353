import React from 'react';
import PropTypes from 'prop-types';
import Helpers from 'routes/WorkspaceLayout/routes/EquipmentLibrary/helpers/EquipmentLibraryHelpers';

import EquipmentBadge from 'routes/WorkspaceLayout/routes/EquipmentLibrary/components/EquipmentBadge.js';
import {
  conductorIsValid,
  getConfigurationType,
  isMulti,
} from 'helpers/JSCIM/AssetInfo/WireSpacingInfo';
import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';

function byTypeThenLabel(a, b) {
  a.class = a.type;
  b.class = b.type;
  return Helpers.byClassThenLabel(a, b);
}

function groupDisabled(options) {
  // bucket by disabled vs enabled
  const groupedOptions = options.reduce(
    (optionMap, el) => {
      if (el.disabled) optionMap.disabled.push(el);
      else optionMap.enabled.push(el);
      return optionMap;
    },
    { disabled: [], enabled: [] },
  );

  // unbucket by group
  return groupedOptions.enabled.concat(groupedOptions.disabled);
}

const WireConductorSelector = ({
  asset: {
    attributes,
    container,
    wire_spacing_info,
    phase_wire_info = {},
    phase_wire_info_options = [],
    phase: phaseCode,
  },
  workspace,
  displayBranch,
  handleSave,
  disabled,
  inEditMode,
}) => {
  if (!wire_spacing_info) wire_spacing_info = {};
  const phaseList = phaseCode.split('');

  phase_wire_info_options.sort(byTypeThenLabel);
  const activePhases = phaseCode.replace('N', '');

  const phaseInfoToOption = ({ id, name, rated_current, type = 'OverheadWireInfo' }) => ({
    value: id,
    label: (
      <div className="instanceinfo-select-item" title={name || id}>
        <p className="instanceinfo-select-item-label">{name || id}</p>
        <EquipmentBadge equipmentInfoItem={{ name: name || id, class: type }} />
      </div>
    ),
    disabled: !conductorIsValid(getConfigurationType(wire_spacing_info), type),
    ratedCurrent: rated_current,
    name,
  });

  const phaseInfoOptions = () => groupDisabled(phase_wire_info_options.map(phaseInfoToOption));

  const conductorSelectors = () => {
    if (isMulti(wire_spacing_info)) {
      const conductorIds = Object.keys(phase_wire_info).reduce((collector, ph) => {
        if (phase_wire_info[ph]?.id && ph !== 'N') collector.add(phase_wire_info[ph].id);
        return collector;
      }, new Set());
      if ([...conductorIds].length <= 1) {
        return phaseCode.includes('N') ? [activePhases, 'N'] : [activePhases];
      } // e.g. ['ABC', 'N']
    }
    return phaseList; // e.g. ['A', 'B', 'C', 'N']
  };

  const handleConductorChange = (phase, value) => {
    if (phase !== 'N' && isMulti(wire_spacing_info)) {
      // always update all active phases to the same conductor
      return handleSave({
        phase_wire_info: activePhases.split('').reduce((collector, ph) => {
          collector[ph] = value;
          return collector;
        }, {}),
      });
    }
    return handleSave({ phase_wire_info: { [phase]: value } });
  };
  return (
    <div className="conductor-section">
      {conductorSelectors().map(phase => {
        const conductor = phase_wire_info[phase] ?? phase_wire_info[phase[0]];
        return (
          <div className="panel-header-row" key={`header-row-conductor-${phase}`}>
            <div className="left-label right-align">{phase}</div>
            <EquipmentInfoSelector
              id={`conductor-${phase}-select`}
              onChange={({ value }) => handleConductorChange(phase, value)}
              loadOptions={phaseInfoOptions}
              defaultOptions={
                inEditMode ? phaseInfoOptions() : [phaseInfoToOption(conductor ?? {})]
              }
              eqLibURL={`/${workspace}/${displayBranch}/library/conductors`}
              value={conductor?.id}
              disabled={disabled}
              assetId={attributes.id}
              feeder={container.id}
            />
          </div>
        );
      })}
    </div>
  );
};

WireConductorSelector.defaultProps = {
  disabled: true,
  inEditMode: false,
};

WireConductorSelector.propTypes = {
  disabled: PropTypes.bool,
  asset: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
};

export default WireConductorSelector;
