import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withAuth from 'helpers/withAuth';
import { loadForecastActions } from '../../routes/Network/modules/loadForecast';
import { actions } from '../../routes/Network/modules/network';

/*  This is a container component. This component is only responsible for
    wiring in the actions and state necessary to render a presentational
    component */

import AnalysisSection from '../AnalysisSection';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. */

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setSelectedAnalysis: loadForecastActions.setSelectedAnalysis,
      setActivePanel: actions.setActivePanel,
    },
    dispatch,
  ),
});

const mapStateToProps = (state, ownProps) => ({
  // From forecast state
  selectedScenario: state.loadForecast.selectedScenario,
  selectedScenarioType: state.loadForecast.selectedScenarioType,
  selectedAnalysis: state.loadForecast.selectedAnalysis,

  // From network state
  workspace: state.network.workspace,
  branch: state.network.branch,

  ...ownProps,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(AnalysisSection));
