import React from 'react';
import AsynchronousMachine from './AsynchronousMachine';

const controlHelp = {
  uncontrolled:
    "During timeseries powerflow, generators set to 'fixed' are allocated by the load allocation process.",
  globallyOptimized:
    "Winds set to 'Global' mode will curtail from their scheduled (or allocated)" +
    ' active/reactive power outputs, when it serves the global objective to do so.',
};

const generatorType = 'Wind';

// Creates a value template for Wind instances
// Uses AsynchronousMachine as that template includes all Wind values\
const Wind = props => {
  const newProps = {
    ...props,
    controlHelp,
    generatorType,
  };
  return <AsynchronousMachine {...newProps} />;
};

export default Wind;
