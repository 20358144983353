import React, { Component } from 'react';
import { alphabetize } from 'helpers/utils';

import { CimSchema, CimJSONObject } from '../cim';

type CimClassAttributeProps = {
  schema?: CimSchema;
  selectedInstance?: CimJSONObject;
  showEmpty: boolean;
};

type SchemaAttributes = {
  [key: string]: { type: string; value?: string };
};

class CIMClassAttributes extends Component<CimClassAttributeProps> {
  getSelectedAttributes = (schema: CimSchema, className: string): SchemaAttributes => {
    const selectedSchema = schema[className];

    // Merge the attributes of the class and all of its super classes
    // to find out all of the attributes an instance of this class could have
    let attributes: { [key: string]: string } = {};
    selectedSchema.ancestry.forEach(ancestor => {
      attributes = { ...attributes, ...schema[ancestor].attributes };
    });
    attributes = { ...attributes, ...selectedSchema.attributes };

    const formattedAttributes: SchemaAttributes = {};
    Object.keys(attributes).forEach(type => {
      formattedAttributes[type] = {
        type: attributes[type],
        value: undefined,
      };
    });
    return formattedAttributes;
  };

  // Take the schema attributes and combine them with the instance to include values
  mergeAttributes = (
    schemaAttributes: SchemaAttributes,
    instance: CimJSONObject,
    showEmpty: boolean,
  ): SchemaAttributes => {
    let attributes: SchemaAttributes = {};

    if (instance) {
      if (showEmpty) {
        // Show all attributes - empty and with values
        // Return instance values added to schema attribute definitions
        attributes = { ...schemaAttributes };
        const instanceTypes = Object.keys(instance.attributes);
        instanceTypes.forEach(type => {
          const typeName = type.split('.')[1];
          if (attributes[typeName]) {
            attributes[typeName].value = instance.attributes[type].toString();
          }
        });
      } else {
        // Show only attributes with values
        // Get the attribute types from the schema and add to instance attributes
        const instanceTypes = Object.keys(instance.attributes);
        instanceTypes.forEach(type => {
          const typeName = type.split('.')[1];
          const dataType = schemaAttributes[typeName] ? schemaAttributes[typeName].type : '';
          attributes[typeName] = {
            value: instance.attributes[type].toString(),
            type: dataType,
          };
        });
      }
    }
    return attributes;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createAttributeElements = (attributes: SchemaAttributes) => {
    // Create a flat list of attribute types
    const types = alphabetize(Object.keys(attributes));
    const attributeList = types.map((a: string) => {
      const color = attributes[a].value ? '#000' : 'gray';
      const value = attributes[a].value ? attributes[a].value : '';
      return (
        <div className="cim-attributes" style={{ color }} key={a}>
          <i className="material-icons">panorama_fish_eye</i>
          <p className="cim-attributes__p">
            {a} = {value}{' '}
          </p>
        </div>
      );
    });
    return attributeList;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    const { schema, selectedInstance, showEmpty } = this.props;
    let itemAttributes = {};
    if (schema && selectedInstance) {
      const schemaAttributes = this.getSelectedAttributes(schema, selectedInstance.class);
      itemAttributes = this.mergeAttributes(schemaAttributes, selectedInstance, showEmpty);
    }
    return <div> {this.createAttributeElements(itemAttributes)} </div>;
  }
}

export default CIMClassAttributes;
