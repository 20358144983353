import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/Card';
import ThemedTabs from 'components/ThemedTabs';
import TopNav from 'components/TopNav';

import AnalysisSettingsTab from './AnalysisSettingsTab';
import CostSettingsTab from './CostSettingsTab';
import NPVSettingsTab from './NPVSettingsTab';
import './Settings.scss';

const Settings = ({
  settings,
  theme,
  updateSettings,
  match: {
    params: { workspace },
  },
}) => {
  const tabs = ['Analysis Results', 'Cost Settings', 'NPV Settings'];

  const tabPanelMap = {
    'Analysis Results': (
      <AnalysisSettingsTab settings={settings} theme={theme} updateSettings={updateSettings} />
    ),
    'Cost Settings': <CostSettingsTab theme={theme} />,
    'NPV Settings': <NPVSettingsTab theme={theme} workspace={workspace} />,
  };

  return (
    <div className="settings-view-container">
      <TopNav label="Back" />
      <div className="settings-card-container">
        <Card className="settings-card" theme={theme} title="Settings">
          <ThemedTabs
            className="settings-tabs"
            theme={theme}
            tabs={tabs.map(tab => ({
              disabled: ['NPV Settings', 'Cost Settings'].includes(tab) && workspace === undefined,
              name: tab,
            }))}
          >
            {TabPanel =>
              tabs.map(tabName => <TabPanel key={tabName}>{tabPanelMap[tabName]}</TabPanel>)
            }
          </ThemedTabs>
        </Card>
      </div>
    </div>
  );
};

Settings.propTypes = {
  settings: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  updateSettings: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      workspace: PropTypes.string,
    }),
  }).isRequired,
};

export default Settings;
