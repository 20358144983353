import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import asyncActionStates from 'helpers/asyncActionStates';
import { perMeter } from 'helpers/units';
import {
  areAssetModelPropertiesChanged,
  getAssetModelProperties,
  updateEmRatings,
} from 'routes/WorkspaceLayout/routes/EquipmentLibrary/helpers/assetModelHelpers';
import {
  areBaseCableValuesUpdated,
  createBaseCableDiffModel,
  createEditDiffModel,
  getBaseCableInputValues,
  isBaseCableValid,
  getNewCable,
  renderCableInputs,
} from '../../../helpers/cablePanelHelpers';
import DescriptionEditor from '../DescriptionEditor';
import CostEditor from '../CostEditor';
import ReliabilityMetrics from '../ReliabilityMetrics';
import PanelTabs from '../PanelTabs';
import EmergencyRatings from '../../EmergencyRatings';

import '../common.scss';

class CablePanel extends Component {
  state = { ...getNewCable() };

  UNSAFE_componentWillMount() {
    this.setState({ ...this.getInputValues(this.props.selected) });
  }

  UNSAFE_componentWillReceiveProps({ createInstanceReq, selected }) {
    const { LOADING, SUCCESS } = asyncActionStates;
    const newSelected = this.props.selected !== selected;
    const saved = this.props.createInstanceReq === LOADING && createInstanceReq === SUCCESS;

    if (newSelected || saved) {
      this.setState({ ...this.getInputValues(selected) });
    }
  }

  getInputValues = selected => getBaseCableInputValues(selected);

  handleSave = () => {
    const diffModel = createBaseCableDiffModel(this.state);

    if (this.state.id === 'add') {
      this.props.handleCreate('cable_info', diffModel, 'CableInfo');
    } else {
      const { selected } = this.props;
      const editDiffModel = createEditDiffModel(selected, diffModel);
      this.props.handleEdit(selected.id, editDiffModel);
    }
  };

  handleInputChange = ({ id, value }) => this.setState({ [id]: value });

  handleSelectChange = (id, value) => this.setState({ [id]: value });

  handleCheckChange = e => {
    const { id } = e.target;
    this.setState(prevState => ({ [id]: !prevState[id] }));
  };

  valuesUpdated = selected => areBaseCableValuesUpdated(this.state, selected);

  shiftFactorValid = (isShiftFactorValid = false) =>
    this.setState({ isValidShiftFactor: isShiftFactorValid });

  formValid = () =>
    isBaseCableValid(this.state) &&
    areBaseCableValuesUpdated(this.state, this.props.selected) &&
    this.state.isValidShiftFactor;

  updateAssetModel = prop =>
    this.setState(prevState => ({
      assetModel: {
        ...prevState.assetModel,
        ...prop,
      },
    }));

  render() {
    const isDisabled =
      this.props.isAuthEnabled &&
      ((this.state.id === 'add' && !this.props.permissions.has('create_equipment_type')) ||
        (this.state.id !== 'add' && !this.props.permissions.has('edit_equipment_type')) ||
        (this.props.match.params.branch === 'master' &&
          !this.props.permissions.has('modify_network_as_built')));

    const defaultProps = {
      disabled: isDisabled,
    };

    const inputDefaultProps = { ...defaultProps, onChange: this.handleInputChange };

    return (
      <PanelTabs
        submitDisabled={!this.formValid() || isDisabled}
        onSubmit={this.handleSave}
        createInstanceReq={this.props.createInstanceReq}
        tabs={['General', 'Description', 'Costs', 'Reliability Metrics', 'Emergency Ratings']}
        assetID={this.state.id}
        showSave
      >
        {[
          <div className="equipment-info-container" key={this.state.id}>
            <div className="right-panel">
              {this.props.children}
              <h2>Cable</h2>
              {renderCableInputs(
                inputDefaultProps,
                isDisabled,
                this.state,
                this.handleSelectChange,
                this.handleCheckChange,
              )}
            </div>
            <div className="column">
              <h2 className="column-title">Description</h2>
              <div className="markdown-body">
                <Markdown escapeHtml source={decodeURIComponent(this.state.description)} />
              </div>
            </div>
          </div>,
          <DescriptionEditor
            description={this.state.description}
            key={`${this.state.id}-description`}
            onChange={d => this.setState({ description: d })}
            isDisabled={isDisabled}
          />,
          <CostEditor
            assetModel={this.state.assetModel}
            assetModelDiff={
              areAssetModelPropertiesChanged(
                this.state.assetModel,
                getAssetModelProperties(this.props.selected?.AssetModel),
              )[1]
            }
            key={`${this.state.id}-costs`}
            onChange={prop =>
              this.setState(prevState => ({
                assetModel: { ...prevState.assetModel, ...prop },
              }))
            }
            isDisabled={isDisabled}
            unit={perMeter}
            includePerUnitMaintenance
          />,
          <ReliabilityMetrics
            assetModel={this.state.assetModel || null}
            probabilityOfFailureEquation={
              this.state.assetModel.AssetFailureInfo.probabilityOfFailureEquation || null
            }
            mTTR={this.state.assetModel.AssetFailureInfo.mTTR}
            key={`${this.state.id}-reliability`}
            onChange={this.updateAssetModel}
            isDisabled={isDisabled}
            saveLibraryError={this.props.saveLibraryError}
            shiftFactorValid={this.shiftFactorValid}
          />,
          <EmergencyRatings
            key={`${this.state.id}-emergency-ratings`}
            defaultProps={defaultProps}
            onChange={(arg, limitField) =>
              this.setState(prevState => updateEmRatings(arg, limitField, prevState))
            }
            emergencyRatings={this.state.emergencyRatings}
            supportedFields={['emCurrent']}
          />,
        ]}
      </PanelTabs>
    );
  }
}

CablePanel.defaultProps = {
  selected: null,
  children: null,
  saveLibraryError: {},
};

CablePanel.propTypes = {
  children: PropTypes.object,
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  createInstanceReq: PropTypes.number.isRequired,
  selected: PropTypes.object,
  isAuthEnabled: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  saveLibraryError: PropTypes.object,
};

export default CablePanel;
