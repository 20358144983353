import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from 'components/ExpandableSection';
import RadioButtonSelectGroup from 'components/RadioButtonSelectGroup';
import {
  createRangeBuckets,
  getPhaseSelectOptions,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import RangeSelector from './RangeSelector';
import LayerSelectionButton from './LayerSelectionButton';

class PowerFactorSection extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      powerGradient: this.getRangeBreaks(this.props.layerOptions),
      editId: '',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldOptions = this.props.layerOptions;
    const newOptions = nextProps.layerOptions;
    if (
      oldOptions.colors !== newOptions.colors ||
      oldOptions.rangeBreaks !== newOptions.rangeBreaks
    ) {
      this.setState({ powerGradient: this.getRangeBreaks(newOptions), editId: '' });
    }
  }

  /**
   * Create the correct range break labels based on layer type
   * @param  {Object} options Layer options
   * @return {Array}          List of layer labels
   */
  getRangeBreaks = options => {
    const { rangeBreaks } = options;
    const labels = [
      `<  ${Math.abs(rangeBreaks[0])} Leading`,
      `${Math.abs(rangeBreaks[0])}   -   ${Math.abs(rangeBreaks[1])} Leading`,
      `${Math.abs(rangeBreaks[1])} Leading   -   ${rangeBreaks[2]} Lagging`,
      `${rangeBreaks[2]}   -   ${rangeBreaks[3]} Lagging`,
      `<  ${rangeBreaks[3]} Lagging`,
    ];
    return createRangeBuckets(options.colors, labels);
  };

  unCheckRange = e => {
    const index = parseInt(e.target.value, 10);
    const newSelection = [...this.props.layerOptions.selected];
    newSelection[index] = !newSelection[index];
    this.updateLayer({ selected: newSelection });
  };

  toggleEditPanel = id => this.setState({ editId: id });

  handleLayerOptionSelection = (phaseType, option) => {
    const updateType = phaseType === 'single' ? 'phase' : 'aggType';
    this.updateLayer({ [updateType]: option });
  };

  updateLayer = update => {
    const updatedOptions = { ...update };
    if (update.rangeBreaks) {
      updatedOptions.rangeBreaks[0] = -Math.abs(update.rangeBreaks[0]);
      updatedOptions.rangeBreaks[1] = -Math.abs(update.rangeBreaks[1]);
    }
    this.props.updateLayerOptions('power_factor', updatedOptions);
  };

  editRangeValidationCheck = (value, index, rangeBreaks) => {
    let check = true;

    if (Math.sign(value) === -1) {
      check = false;
    } else if (index === 0) {
      check = value < rangeBreaks[1] && value > 0 && value < 1;
    } else if (index === 1) {
      check = value > rangeBreaks[0] && value < 1 && value > 0;
    } else if (index === 2) {
      check = value > rangeBreaks[3] && value > 0 && value < 1;
    } else {
      check = value < rangeBreaks[2] && value < 1 && value > 0;
    }
    return check;
  };

  render() {
    const { layerOptions, showThreePhase } = this.props;
    const { rangeBreaks, phaseType, aggType, phase, selected } = layerOptions;
    const breaks = rangeBreaks.map(rangeBreak => Math.abs(rangeBreak));

    const button = () => (
      <LayerSelectionButton
        selected={this.props.selected}
        option={{ label: 'Power Factor', value: 'power_factor' }}
        group="results"
        setSelectedLayer={this.props.setSelectedLayer}
      />
    );

    return (
      <ExpandableSection
        className={classNames({
          'layer-expandable-section': true,
          'selected-layer': this.props.selected,
          'layer-expandable-section--expanded': this.props.expanded,
        })}
        renderHeaderContent={button}
        showToggle={false}
        open={this.props.selected}
      >
        <div className="layer-section apparent-power-section">
          <div className="selection-section layer-phase-selector">
            <RadioButtonSelectGroup
              id="power-factor-phase-selector"
              value={phaseType}
              listType="column"
              theme={this.props.theme}
              onSelectChange={this.handleLayerOptionSelection}
              onChange={type => this.updateLayer({ phaseType: type })}
              options={getPhaseSelectOptions(aggType, phase, showThreePhase)}
            />
          </div>
          <div className="layer-checkbox-group">
            {this.state.powerGradient.length &&
              this.state.powerGradient.map(checkbox => (
                <RangeSelector
                  key={`power-factor-range-${checkbox.id}`}
                  settings={checkbox}
                  selected={selected[parseInt(checkbox.id, 10)]}
                  onClick={this.unCheckRange}
                  id={parseInt(checkbox.id, 10)}
                  withEdit
                  toggleEdit={this.toggleEditPanel}
                  showEditPanel={checkbox.id === this.state.editId}
                  layerOptions={layerOptions}
                  handleUpdate={this.updateLayer}
                  editDisabled={!!this.state.editId}
                  editColor
                  editRange
                  rangeBreaks={breaks}
                  validationCheck={this.editRangeValidationCheck}
                  rangeMin={-1}
                  rangeMax={1}
                  descending
                  headers={['Less Than', 'Range', 'Range', 'Range', 'Less Than']}
                />
              ))}
          </div>
        </div>
      </ExpandableSection>
    );
  }
}

PowerFactorSection.propTypes = {
  layerOptions: PropTypes.shape({
    selected: PropTypes.array,
    mode: PropTypes.string,
    phase: PropTypes.string,
    colors: PropTypes.array,
    rangeBreaks: PropTypes.array,
    phaseType: PropTypes.string,
    aggType: PropTypes.string,
  }).isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  showThreePhase: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default PowerFactorSection;
