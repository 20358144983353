import ShuntDevice from './ShuntDevice';

export const validationSchema = {
  nominalVoltage: {
    customValidation: (value, baseVoltage) => {
      let messages = [];
      const valid = value !== '';
      const equalBase = value === baseVoltage;

      if (!equalBase) {
        messages = [
          ...messages,
          'Nominal Voltage does not match base voltage.',
          'Validate and apply fixers to update.',
        ];
      }

      return {
        valid,
        equalBase,
        message: !equalBase ? messages : null,
      };
    },
  },
};

class EnergySource extends ShuntDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'energy_source';
    this._class = 'EnergySource';
    this.displayName = 'Energy Source';
  }

  get nominalVoltage() {
    return this.attributes['EnergySource.nominalVoltage'] || ' ';
  }

  get EquivalentSubstation() {
    return this.references['EnergySource.EquivalentSubstation'];
  }
}

export default EnergySource;
