import asyncActionStates from 'helpers/asyncActionStates';

const toCamelCase = actionName => {
  const words = actionName.split('_');
  return words.reduce((str, word) => {
    if (!str.length) {
      return word.toLowerCase();
    }
    return str + word[0] + word.slice(1).toLowerCase();
  }, '');
};

const CLEAR_LOADING_STATES = 'CLEAR_LOADING_STATES';

export function clearLoadingStates() {
  return { type: CLEAR_LOADING_STATES };
}

const loadingReducer = (state = {}, action) => {
  const { type } = action;
  const matches = /(.*)_(INITIAL|LOADING|SUCCESS|ERROR|NOT_ALLOWED)/.exec(type);

  if (type === CLEAR_LOADING_STATES) return {};

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store the value of the current status
    // e.g. GET_USERS_LOADING will become getUsers: asyncActionStates[LOADING]
    [toCamelCase(requestName)]: asyncActionStates[requestState],
  };
};

export default loadingReducer;
