import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'components/NumberInput';

import { kVAr, kVln, siemens, kV, amps, VA } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import { transposePhaseAttributes } from '../../helpers/valueExtractors';
import EditablePhaseValues from '../../Inputs/EditablePhaseValues';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import PerPhaseContainer from '../partials/PerPhaseContainer';
import AssetSingleSelect from '../../Inputs/AssetSingleSelect';
import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';

const shuntCompensatorInfoFields = [
  {
    label: 'Rated Voltage',
    key: 'ratedVoltage',
    unit: kV,
    options: { divisor: 1000 },
  },
  { label: 'Rated Current', key: 'ratedCurrent', unit: amps },
  {
    label: 'Rated Reactive Power',
    key: 'ratedReactivePower',
    unit: kVAr,
    options: { divisor: 1000 },
  },
  { label: 'Max Power Loss', key: 'maxPowerLoss', unit: VA },
];

const bPerSectionFields = {
  label: 'Susceptance',
  key: 'bPerSection',
  unit: siemens,
  precision: 5,
};
const nominalQFields = {
  label: 'Nominal Power',
  key: 'nominalQ',
  unit: kVAr,
  precision: 3,
};

const getPhaseAttributeDiff = (edited, original) => {
  const phaseDiffs = {};
  Object.keys(original).forEach(phase => {
    if (original[phase] !== edited[phase]) {
      phaseDiffs[phase] = true;
    }
  });
  return phaseDiffs;
};

function InstanceInfo({
  asset: {
    attributes,
    balanced,
    phase_attributes,
    phases,
    container,
    shunt_compensator_info,
    shunt_compensator_infos = [], // Only defined in edit mode
  },
  inEditMode,
  disabled,
  theme,
  handleSave,
  workspace,
  displayBranch,
}) {
  const [nominalVoltage, setNominalVoltage] = useState('');
  const [bPerSection, setBPerSection] = useState('');
  const [inService, setInService, setInServiceEdited] = useTrackingState(
    attributes.normallyInService,
  );

  const shuntCompensatorInfoOptions = useMemo(() => {
    if (shunt_compensator_infos.length > 0) {
      return shunt_compensator_infos.map(swi => ({
        value: swi.id,
        label: swi.name || swi.id,
        disabled: swi.id === shunt_compensator_info?.id,
      }));
    }

    if (shunt_compensator_info) {
      return [
        {
          value: shunt_compensator_info.id,
          label: shunt_compensator_info.name || shunt_compensator_info.id,
          disabled: true,
        },
      ];
    }

    return [];
  }, [shunt_compensator_infos, shunt_compensator_info]);

  useEffect(() => {
    setNominalVoltage(attributes.nomU);
    if (balanced) {
      setBPerSection(attributes?.bPerSection);
    } else {
      setBPerSection(transposePhaseAttributes(phase_attributes).bPerSection);
    }
  }, [attributes, phase_attributes, balanced]);

  return (
    <>
      <EquipmentInfoSelector
        eqLibURL={`/${workspace}/${displayBranch}/library/capacitors`}
        displayName="Capacitor"
        assetId={attributes.id}
        value={shunt_compensator_info?.id}
        defaultOptions={shuntCompensatorInfoOptions}
        loadOptions={() => shuntCompensatorInfoOptions}
        id="shunt-compensator-info-select"
        onChange={({ value: selectedId }) => {
          handleSave({ shunt_compensator_info: selectedId });
        }}
        disabled={disabled || !inEditMode}
        feeder={container.id}
      />
      <hr className="section-divider" />

      <SingleEditableValue
        theme={theme}
        type="number"
        id="nomU"
        label="Nominal Voltage"
        value={nominalVoltage}
        edited={nominalVoltage !== attributes.nomU}
        onChange={value => setNominalVoltage(value)}
        onBlur={() => {
          if (nominalVoltage !== attributes.nomU) {
            handleSave({ attributes: { nomU: nominalVoltage } });
          }
        }}
        disabled={disabled || !inEditMode}
        min={1}
        unit={kVln}
        divisor={1000}
        precision={3}
      />

      {shuntCompensatorInfoFields.map(field_info => (
        <NumberInput // eslint-disable-line deprecation/deprecation
          id={field_info.key}
          type="number"
          inputStyle="panel"
          className="single-row-input"
          theme={theme}
          disabled
          key={field_info.key}
          {...field_info}
          value={shunt_compensator_info?.[field_info.key] ?? ''}
          precision={3}
        />
      ))}

      <AssetSingleSelect
        id="normallyInService"
        label="Status"
        value={inService ?? true}
        options={[
          { label: 'In Service', value: true },
          { label: 'Out of Service', value: false },
        ]}
        onChange={value => {
          setInService(value);
          handleSave({ attributes: { normallyInService: value } });
        }}
        edited={setInServiceEdited}
        disabled={disabled || !inEditMode}
      />

      {balanced ? (
        <>
          {[nominalQFields, bPerSectionFields].map(fields => (
            <SingleEditableValue
              id={fields.key}
              type="number"
              inputStyle="panel"
              theme={theme}
              disabled={disabled || !inEditMode}
              {...fields}
              value={bPerSection}
              key={fields.key}
              divisor={fields.key === 'nominalQ' ? 1000 / nominalVoltage ** 2 : 1}
              edited={bPerSection !== attributes.bPerSection}
              onChange={value => setBPerSection(value)}
              onBlur={() => {
                if (bPerSection !== attributes.bPerSection) {
                  handleSave({ attributes: { bPerSection } });
                }
              }}
            />
          ))}
        </>
      ) : (
        <PerPhaseContainer showTotal>
          {[nominalQFields, bPerSectionFields].map(fields => (
            <EditablePhaseValues
              getTotal={(values, divisor) =>
                Object.values(values).reduce((prev, curr) => prev + curr / divisor, 0)
              }
              id={fields.key}
              key={fields.key}
              type="number"
              inputStyle="panel"
              theme={theme}
              disabled={disabled || !inEditMode}
              {...fields}
              phase={phases}
              {...{ [fields.key]: bPerSection }}
              divisor={fields.key === 'nominalQ' ? 1000 / nominalVoltage ** 2 : 1}
              edited={{
                [fields.key]: getPhaseAttributeDiff(
                  bPerSection,
                  transposePhaseAttributes(phase_attributes).bPerSection,
                ),
              }}
              onChange={e =>
                setBPerSection({
                  ...bPerSection,
                  [e.phase]: e.value,
                })
              }
              onBlur={async () => {
                await handleSave({ phase_attributes: transposePhaseAttributes({ bPerSection }) });
              }}
            />
          ))}
        </PerPhaseContainer>
      )}
    </>
  );
}

InstanceInfo.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
};

export default InstanceInfo;
