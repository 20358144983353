import React, { FunctionComponent, ReactElement, useContext, ReactText } from 'react';
import Tooltip from 'components/Tooltip';
import ThemeContext from 'helpers/ThemeContext';
import { ReactSVG } from 'react-svg';
import { IntlContext } from 'contexts/IntlContext';
import LoadingSkeleton from 'components/LoadingSkeleton';

type ValueCardProps = {
  value?: number | undefined | null;
  baselineValue?: number | null;
  showComparisonPercent?: boolean;
  isCurrency?: boolean;
  unit?: string;
  includeWarning?: boolean;
  label?: string | ReactElement | null;
  labelOnly?: boolean;
  primary?: boolean;
  warnLowSeverity?: boolean;
  tooltipMsg?: string | ReactElement;
  hoverMsg?: string;
  className?: string;
  loading?: boolean;
  error?: boolean;
};

const ValueCard: FunctionComponent<ValueCardProps> = ({
  label = null,
  value,
  baselineValue = null,
  showComparisonPercent = false,
  unit,
  isCurrency = false,
  includeWarning = false,
  primary = false,
  tooltipMsg = '',
  hoverMsg = null,
  warnLowSeverity = false,
  className = null,
  loading = false,
  error = false,
  labelOnly = false,
}) => {
  const theme = useContext(ThemeContext);
  const { currencyFormatter, unitFormatter } = useContext(IntlContext);
  const formatValue = (val: number | undefined | null): string | ReactText => {
    let formatted = val ?? '--';
    if (typeof formatted === 'number' && isFinite(formatted)) {
      if (error) {
        formatted = '--';
      } else if (isCurrency) {
        formatted = currencyFormatter.format(val);
      } else {
        formatted = unitFormatter(val, unit);
      }
    }
    return formatted;
  };
  const formattedValue = formatValue(value);
  const formattedBaselineValue = formatValue(baselineValue);

  const difference = (): 'increased' | 'decreased' | null => {
    if (value != null && baselineValue != null && formattedValue !== formattedBaselineValue) {
      if (value > baselineValue) return 'increased';
      if (value < baselineValue) return 'decreased';
    }
    return null;
  };
  const diff = difference();

  const percentDifference =
    formattedValue !== formattedBaselineValue &&
    value &&
    typeof value === 'number' &&
    baselineValue &&
    baselineValue > 0 &&
    value !== baselineValue
      ? ((value - baselineValue) / baselineValue) * 100
      : null;
  const formattedPercentDifference = () => {
    if (percentDifference) {
      let percentDiff = percentDifference > 0 ? '+' : '';
      percentDiff = `${percentDiff}${
        Math.abs(percentDifference) <= 10
          ? percentDifference.toFixed(2)
          : Math.round(percentDifference)
      }`;
      return percentDiff;
    }
    return percentDifference;
  };

  return (
    <div className={`summary-results-row-item ${primary ? 'primary-results' : ''} ${className}`}>
      {label && (
        <div className="item-text">
          <div className="item-text item-text-title">{label}</div>
          {hoverMsg && (
            <Tooltip placement="top" content={hoverMsg}>
              <i className="material-icons help-icon">help_outline</i>
            </Tooltip>
          )}
        </div>
      )}
      <div className="item-number">
        {loading ? (
          <LoadingSkeleton theme={theme} template="line" />
        ) : (
          <>
            <div className="warning-value">
              {includeWarning && (
                <Tooltip enabled={tooltipMsg !== ''} content={tooltipMsg}>
                  <i className={`material-icons violation-icon ${warnLowSeverity ? 'low' : ''}`}>
                    warning
                  </i>
                </Tooltip>
              )}
              {!labelOnly && <span className="item-value">{formattedValue}</span>}
            </div>
            <div className="comparison-percentage">
              {diff !== null && (
                <div id="comparison-icon" data-increased={diff === 'increased'}>
                  <ReactSVG src="/arrow-down-new.svg" />
                </div>
              )}
              {showComparisonPercent && percentDifference && (
                <div className={`percentage ${diff}`}>{`${formattedPercentDifference()}%`}</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ValueCard;
