import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import IconButton from 'components/IconButton';
import './NodeDisplayContainer.scss';

const NodeDisplayContainer = ({ disabled, onSwap, canSwap, onNodeClick, leftNode, rightNode }) => (
  <div className="node-display-container list-value-row">
    <div className="list-value-row-label">Associated Nodes</div>
    <div className="swap-container">
      <div className="display-row">
        <button
          className="list-value-row-value node-display-container-node"
          onClick={() => onNodeClick(leftNode)}
          type="button"
          title={leftNode.name}
        >
          {leftNode.name}
        </button>
        <div className="between-nodes">
          <div className="icon-container">
            {canSwap && (
              <IconButton tooltip="Swap Nodes" onClick={onSwap} disabled={disabled}>
                <ReactSVG className="node-display-container-icon" src="/repeat.svg" />
              </IconButton>
            )}
          </div>
        </div>
        <button
          className="list-value-row-value node-display-container-node"
          onClick={() => onNodeClick(rightNode)}
          type="button"
          title={rightNode.name}
        >
          {rightNode.name}
        </button>
      </div>
    </div>
  </div>
);

NodeDisplayContainer.defaultProps = {
  disabled: false,
  canSwap: false,
  onNodeClick: () => {},
  onSwap: () => {},
};

NodeDisplayContainer.propTypes = {
  disabled: PropTypes.bool,
  onSwap: PropTypes.func,
  onNodeClick: PropTypes.func,
  canSwap: PropTypes.bool,
  leftNode: PropTypes.object.isRequired,
  rightNode: PropTypes.object.isRequired,
};

export default NodeDisplayContainer;
