import React, { FunctionComponent, useContext } from 'react';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import Analytics from 'helpers/Analytics';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import { Analysis } from 'types/analysis';
import getBadgedAnalysisOption from '../../Network/components/getBadgedAnalysisOption';

type AnalysisSelectionProps = {
  analysesList: Analysis[];
  analysis: Analysis | null;
  resultsBranch: string | null;
  scenario: string | null;
  setSelectedAnalysis: (arg: Analysis | null) => void;
  title: string;
};
const AnalysisSelection: FunctionComponent<AnalysisSelectionProps> = ({
  analysesList,
  analysis,
  resultsBranch,
  scenario,
  setSelectedAnalysis,
  title,
}) => {
  const theme = useContext(ThemeContext);
  const { analysisActive } = useContext(ActivityLogContext);
  return (
    <div className="analysis-selection">
      <p className="select-label space-bottom">Analysis</p>
      <Select
        id="results-analysis-section"
        theme={theme}
        options={analysesList.map(opt => {
          const isAnalysisActive = analysisActive(resultsBranch, scenario, opt.name);
          return getBadgedAnalysisOption(opt, isAnalysisActive);
        })}
        value={analysis?.id}
        onChange={(e: { analysis: Analysis }) => {
          setSelectedAnalysis(e?.analysis);
          Analytics.logEvent(`Analysis change for ${title}`, 'Analysis');
        }}
        type="secondary"
      />
    </div>
  );
};

export default AnalysisSelection;
