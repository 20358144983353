import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import Select from 'components/Select';
import { ThemeProp } from 'types/index';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { isEmptyObject } from 'helpers/utils';
import {
  violationCategory,
  violationsCountCreator,
} from 'routes/WorkspaceLayout/routes/Network/helpers/PowerflowHelpers';
import { ReactSVG } from 'react-svg';
import LoadingSkeleton from 'components/LoadingSkeleton';
import asyncActionStates from 'helpers/asyncActionStates';
import { IntlContext } from 'contexts/IntlContext';

const { INITIAL, LOADING, SUCCESS, ERROR } = asyncActionStates;

const TOTAL_BUCKETS = 5; // The total number of buckets to display

type ViolationsType = {
  name: number;
  value: number;
};

type ViolationChartProps = {
  theme: ThemeProp;
  violationsMag?: { [key: string]: Array<ViolationsType> };
  baselineViolations: any;
  violationType: string;
  setViolationType: (e: any) => void;
  isBaseline: boolean;
  loading: number;
};

type ViolationRangeProps = {
  name: string;
  value: number;
  baselineValue: number;
  gt?: number;
  lte?: number;
};

const ViolationChart: FunctionComponent<ViolationChartProps> = ({
  theme,
  violationsMag = {},
  violationType,
  setViolationType,
  isBaseline,
  loading,
  baselineViolations,
}) => {
  const [violationsRange, setViolationsRange] = useState<ViolationRangeProps[]>([]);
  const [rangeLoading, setRangeLoading] = useState(INITIAL);
  const [violationsEmpty, setViolationsEmpty] = useState({ empty: false, message: '' });
  const { unitFormatter } = useContext(IntlContext);
  const violationsOptions = () => {
    const categories = Object.values(violationCategory).map(cat => cat.label);
    const vMag = categories.reduce(
      (cats: { [key: string]: Array<ViolationsType> }, cat: string) => {
        cats[cat] = [];
        return cats;
      },
      {},
    );
    const vLimit = baselineViolations?.violationLimit ?? 0;
    let vTotal = 0;
    const violationsOverLimit = Object.keys(violationsMag).reduce(
      (obj: { [key: string]: Array<ViolationsType> }, type: string) => {
        const filteredByLimit = violationsMag[type].filter(
          (val: ViolationsType) => val.name >= vLimit,
        );
        obj[type] = filteredByLimit;
        vTotal += filteredByLimit.reduce((value, vio) => value + vio.value, 0);
        return obj;
      },
      vMag,
    );
    const vCount = violationsCountCreator(violationsOverLimit);
    const optionsArr = [
      { label: `All types (${unitFormatter(vTotal)})`, value: 'All', disabled: false },
    ];
    const optionsViolationTypes = vCount.map(type => ({
      label: `${type.name} (${type.value})`,
      value: type.name,
      disabled: type.value === 0,
    }));
    return optionsArr.concat(optionsViolationTypes);
  };

  const formatPercentage = (num: number, firstLast = false) => {
    if (num) {
      if (num < 100 || firstLast) {
        return num.toFixed(2);
      }
      return Math.round(num);
    }
    return num;
  };

  const getViolationsByLimit = (violations: ViolationsType[], limit: number) =>
    violations
      .filter((v: ViolationsType) => limit === null || (limit >= 0 && v.name >= limit))
      .sort((a: ViolationsType, b: ViolationsType) => a.name - b.name);

  useEffect(() => {
    const vRange: ViolationRangeProps[] = [];
    const setVRange = () => {
      try {
        setRangeLoading(LOADING);
        const violationsMagAll = {
          ...violationsMag,
          All: Array.prototype.concat.apply([], Object.values(violationsMag)),
        } as { [key: string]: Array<ViolationsType> };

        const baselineViolationsMagAll = {
          ...baselineViolations?.violationsMag,
          All: Array.prototype.concat.apply([], Object.values(baselineViolations?.violationsMag)),
        } as { [key: string]: Array<ViolationsType> };

        const vLimit = baselineViolations?.violationLimit ?? 0;
        const vType = baselineViolations?.violationType ?? 'All';

        const violationsByLimit = getViolationsByLimit(violationsMagAll[vType], vLimit);

        const baselineViolationsByLimit = getViolationsByLimit(
          baselineViolationsMagAll[vType],
          vLimit,
        );

        setViolationsEmpty({
          empty: isEmptyObject(violationsByLimit) || isEmptyObject(baselineViolationsByLimit),
          message: isEmptyObject(violationsByLimit)
            ? 'This analysis has no violations'
            : 'The baseline analysis has no violations',
        });
        if (!(isEmptyObject(violationsByLimit) || isEmptyObject(baselineViolationsByLimit))) {
          const violationBounds = {
            min: baselineViolationsByLimit?.[0]?.name,
            max: baselineViolationsByLimit[baselineViolationsByLimit?.length - 1]?.name,
          };
          if (violationBounds?.min === violationBounds?.max && violationsByLimit.length === 1) {
            const totalFirstViolationValue = violationsByLimit.reduce(
              (totalVal: number, v: ViolationsType) => {
                if (v.name <= violationBounds.min) {
                  totalVal += v.value;
                }
                return totalVal;
              },
              0,
            );
            const totalBaselineFirstViolationValue = baselineViolationsByLimit.reduce(
              (totalVal: number, v: ViolationsType) => {
                if (v.name <= violationBounds.min) {
                  totalVal += v.value;
                }
                return totalVal;
              },
              0,
            );
            vRange.push({
              name: `${formatPercentage(baselineViolationsByLimit[0]?.name, true)}%`,
              baselineValue: totalBaselineFirstViolationValue,
              value: totalFirstViolationValue,
              lte: baselineViolationsByLimit[0]?.name,
            });
          } else {
            // create TOTAL_BUCKETS - 1 buckets
            const buckets = baselineViolationsByLimit
              .map(obj => obj.name)
              .filter((value, index, self) => self.indexOf(value) === index);
            const numBuckets = buckets.length - 1; // exclude last (max)
            const bucketSize = Math.ceil(
              numBuckets < TOTAL_BUCKETS - 1 ? buckets.length : numBuckets / (TOTAL_BUCKETS - 1),
            );
            for (let i = 0; i < numBuckets; i += bucketSize) {
              const bucketStart = buckets[i];
              const nextBucketStart = Math.min(
                buckets[i + bucketSize] ?? Infinity,
                violationBounds.max,
              );

              const totalViolationValue = violationsByLimit.reduce((totalVal, v) => {
                if (v.name >= bucketStart && v.name < nextBucketStart) {
                  totalVal += v.value;
                }
                return totalVal;
              }, 0);
              const totalBaselineViolationValue = baselineViolationsByLimit.reduce(
                (totalVal, v) => {
                  if (v.name >= bucketStart && v.name < nextBucketStart) {
                    totalVal += v.value;
                  }
                  return totalVal;
                },
                0,
              );
              vRange.push({
                name: `> ${formatPercentage(bucketStart)}% - ${formatPercentage(
                  nextBucketStart,
                  nextBucketStart === violationBounds.max,
                )}%`,
                baselineValue: totalBaselineViolationValue,
                value: totalViolationValue,
                gt: bucketStart,
                lte: nextBucketStart,
              });
            }

            // create the last bucket
            const totalLastViolationValue = violationsByLimit.reduce((totalVal, v) => {
              if (v.name >= violationBounds.max) {
                totalVal += v.value;
              }
              return totalVal;
            }, 0);
            const totalLastBaselineValue = baselineViolationsByLimit.reduce((totalVal, v) => {
              if (v.name >= violationBounds.max) {
                totalVal += v.value;
              }
              return totalVal;
            }, 0);
            vRange.push({
              name: `> ${formatPercentage(violationBounds.max, true)}%`,
              gt: violationBounds.max,
              baselineValue: totalLastBaselineValue,
              value: totalLastViolationValue,
            });
          }
          setRangeLoading(SUCCESS);
        } else {
          setRangeLoading(ERROR);
        }
      } catch (err) {
        setRangeLoading(ERROR);
      }
      setViolationsRange(vRange);
    };
    setVRange();
  }, [baselineViolations, violationsMag]);

  const GT = violationsRange[violationsRange.length - 1]?.gt ?? 100;

  const highMaxPercentage = GT < 1000;
  const renderCustomizedLabel = (props: any) => {
    const { x, y, payload, index } = props;
    const { value } = payload;
    const difference = () => {
      let comparison;
      if (value > violationsRange[index].baselineValue) comparison = 'increased';
      if (value < violationsRange[index].baselineValue) comparison = 'decreased';
      return comparison;
    };

    return (
      <g>
        <foreignObject x={x} y={`${y - 7.5}px`} width={60} height={15}>
          <div className="comparison">
            <div className="counts">{value}</div>
            {!isBaseline && value !== violationsRange[index].baselineValue && (
              <div id="comparison-icon" data-increased={difference() === 'increased'}>
                <ReactSVG src="/arrow-down-new.svg" />
              </div>
            )}
          </div>
        </foreignObject>
      </g>
    );
  };
  return (
    <>
      {([INITIAL, LOADING].includes(loading) || [INITIAL, LOADING].includes(rangeLoading)) && (
        <div className="chart-skeleton">
          <div className="skeleton-select-row">
            <LoadingSkeleton template="line" theme={theme} width={50} key="select-skeleton" />
          </div>
          <div className="skeleton-chart-row">
            <LoadingSkeleton
              template="line"
              theme={theme}
              width={100}
              count={5}
              height={20}
              key="chart-skeleton"
            />
          </div>
        </div>
      )}
      {loading === SUCCESS && rangeLoading === SUCCESS && (
        <>
          {baselineViolations?.violationLimit > 0 && (
            <div className="over-limit-note">
              <div id="limit-note">
                <strong>&nbsp;Note:&nbsp;</strong>
                Values in parentheses display number of violations over limit
              </div>
            </div>
          )}
          <div className="one-one grid-columns violation-chart margin-10">
            <Select
              options={violationsOptions()}
              value={violationType}
              onChange={(e: any) => setViolationType(e.value)}
              theme={theme}
              clearable={false}
              className="select-bg-blue"
              type="secondary"
              disabled={!isBaseline}
            />
          </div>
          <ResponsiveContainer width="100%" height={150}>
            <BarChart
              data={violationsRange}
              layout="vertical"
              barCategoryGap={2}
              margin={{
                top: 0,
                right: 10,
                left: highMaxPercentage ? -40 : -20,
                bottom: 0,
              }}
              barSize={2}
            >
              <XAxis type="number" hide axisLine={false} domain={['dataMin', 'dataMax']} />
              <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} width={145} />
              <YAxis
                orientation="right"
                axisLine={false}
                tickLine={false}
                type="category"
                yAxisId="2"
                dataKey="value"
                tick={renderCustomizedLabel}
              />
              <Bar dataKey="value" fill="#213AB8" background={{ fill: '#DDDFE6' }} />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
      {(loading === ERROR || rangeLoading === ERROR) &&
        loading !== LOADING &&
        rangeLoading !== LOADING && (
          <>
            <div className="one-one grid-columns violation-chart margin-10">
              <Select
                options={violationsOptions()}
                value={violationType}
                onChange={(e: any) => setViolationType(e.value)}
                theme={theme}
                clearable={false}
                className="select-bg-blue"
                type="secondary"
                disabled={!isBaseline}
              />
            </div>
            <div className="chart-placeholder">
              {violationsEmpty.empty
                ? violationsEmpty.message
                : 'There was an error loading the chart data'}
            </div>
          </>
        )}
    </>
  );
};
export default ViolationChart;
