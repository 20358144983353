import React, { FunctionComponent } from 'react';
import { amps, kVA, scaleFactorForUnit } from 'helpers/units';
import NumberInput from 'components/NumberInput';

type EmergencyRatingsProps = {
  defaultProps: { disabled: boolean };
  onChange: (
    arg: { id: string; value: number },
    limitField: 'currentLimit' | 'apparentPowerLimit',
  ) => void;
  emergencyRatings: { [key: string]: { [key: string]: null | number } };
  supportedFields?: string[];
};

const EmergencyRatings: FunctionComponent<EmergencyRatingsProps> = ({
  defaultProps,
  onChange,
  emergencyRatings = {},
  supportedFields = [],
}) => {
  const validationMsg = 'Value must be greater than 0.';
  const { longTermEmergency, shortTermEmergency } = emergencyRatings;
  return (
    <div className="equipment-info-container ratings-container" id="ratings-tab">
      <div className="right-panel">
        {supportedFields.includes('emCurrent') && (
          <div>
            <NumberInput
              {...defaultProps}
              label="Long-Term Emergency Rated Current"
              id="longTermEmergency"
              value={longTermEmergency.currentLimit ?? ''}
              unit={amps}
              invalid={
                typeof longTermEmergency.currentLimit === 'number' &&
                longTermEmergency.currentLimit < 0
              }
              validationMessage={validationMsg}
              inputStyle="eq-lib"
              onChange={e => onChange(e, 'currentLimit')}
            />
            <NumberInput
              {...defaultProps}
              label="Short-Term Emergency Rated Current"
              id="shortTermEmergency"
              value={shortTermEmergency.currentLimit ?? ''}
              unit={amps}
              invalid={
                typeof shortTermEmergency.currentLimit === 'number' &&
                shortTermEmergency.currentLimit < 0
              }
              validationMessage={validationMsg}
              inputStyle="eq-lib"
              onChange={e => onChange(e, 'currentLimit')}
            />
          </div>
        )}
        {supportedFields.includes('emApparentPower') && (
          <div>
            <NumberInput
              {...defaultProps}
              label="Long-Term Emergency Rated Apparent Power"
              id="longTermEmergency"
              value={
                (longTermEmergency.apparentPowerLimit &&
                  longTermEmergency.apparentPowerLimit / scaleFactorForUnit(kVA)) ??
                ''
              }
              unit={kVA}
              invalid={
                typeof longTermEmergency.apparentPowerLimit === 'number' &&
                longTermEmergency.apparentPowerLimit < 0
              }
              validationMessage={validationMsg}
              inputStyle="eq-lib"
              onChange={e => onChange(e, 'apparentPowerLimit')}
              required={false}
            />
            <NumberInput
              {...defaultProps}
              label="Short-Term Emergency Rated Apparent Power"
              id="shortTermEmergency"
              value={
                (shortTermEmergency.apparentPowerLimit &&
                  shortTermEmergency.apparentPowerLimit / scaleFactorForUnit(kVA)) ??
                ''
              }
              unit={kVA}
              invalid={
                typeof shortTermEmergency.apparentPowerLimit === 'number' &&
                shortTermEmergency.apparentPowerLimit < 0
              }
              validationMessage={validationMsg}
              inputStyle="eq-lib"
              onChange={e => onChange(e, 'apparentPowerLimit')}
              required={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmergencyRatings;
