/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import CustomScrollBar from 'components/CustomScrollBar';
import DocumentationLink from 'components/DocumentationLink';
import FileForm from 'components/FileForm';
import Modal from 'components/Modal';
import RadioButtonGroup from 'components/RadioButtonGroup';
import TextInput from 'components/TextInput';
import asyncActionStates from 'helpers/asyncActionStates';
import { alphabetizeByKey, pluralize } from 'helpers/utils';
import { ScenarioTypes } from 'helpers/scenarios';
import DateTimeSelector from '../routes/Network/components/AnalysisModal/DateTimeSelector';

const { LOADING, SUCCESS, ERROR } = asyncActionStates;

class ScenarioModal extends Component {
  state = {
    scenarioType: ScenarioTypes.snapshot,
    scenarioTimepoint: moment.utc().startOf('hour'),
    scenarioFiles: {},
    scenarioFilenames: {},
    assetScheduleFile: null,
    assetScheduleFilename: '',
    assetScheduleFileDirty: false,
    invalidName: false,
    scenarioName: '',
    scenarioNameDirty: false,
  };

  componentDidMount() {
    if (this.props.scenario && this.props.scenarios) {
      const scenarioType = this.props.scenarios.find(sc => sc.value === this.props.scenario)?.type;
      this.setState({ scenarioType });
    }
  }

  componentDidUpdate(prevProps) {
    // if we successfully create/update the scenario, mark it clean
    if (this.props.scenarioReq === SUCCESS && prevProps.scenarioReq === LOADING) {
      this.setState({ scenarioNameDirty: false });
    }

    // if we successfully upload the schedule, mark it clean
    if (
      this.props.bulkAssetScheduleUpload === SUCCESS &&
      prevProps.bulkAssetScheduleUpload === LOADING
    ) {
      this.setState({ assetScheduleFileDirty: false });
    }

    const scenarioUpdated = this.props.scenarios !== prevProps.scenarios;
    // if the list of scenarios changed or there is no scenario yet, look for a new scenario name
    if (!this.state.scenarioName || scenarioUpdated) {
      const scenarioInfo = this.props.scenarios.find(sc => sc.value === this.props.scenario);
      if (scenarioInfo) {
        this.setState({
          scenarioName: scenarioInfo.label,
          scenarioNameDirty: false,
          invalidName: false,
        });
      }
    }
  }

  handleChangeScenarioType = ({ target }) => {
    this.setState({ scenarioType: target.value });
  };

  handleScenarioTimestampChange = value => {
    this.setState({ scenarioTimepoint: value });
  };

  handleKeyPress = e => {
    if (e.charCode === 13) {
      this.handleScenarioCreation();
    }
  };

  handleNameChange = ({ target }) => {
    const name = target.value;
    const invalidName =
      name.length === 0 ||
      name.match(/^[a-zA-Z0-9-_]+( +[a-zA-Z0-9-_]+)*$/g) === null ||
      this.props.scenarios.some(({ label }) => label === name);

    this.setState({
      scenarioName: name,
      invalidName,
      scenarioNameDirty: true,
    });
  };

  handleFileSelection = e => {
    const { files, id } = e.target;

    // If there not exactly one files attached, do nothing
    if (files.length !== 1) return;

    // Only 1 file should exist / only the first file matters
    const file = files[0];

    // Assume .csv file is valid, allow API to reject
    const formData = new FormData();
    formData.set('asset_schedule', file);

    this.setState(prevState => ({
      scenarioFiles: {
        ...prevState.scenarioFiles,
        [id]: formData,
      },
      scenarioFilenames: {
        ...prevState.scenarioFilenames,
        [id]: file.name,
      },
      hideErrors: true,
    }));
  };

  handleAssetScheduleSelection = e => {
    const { files } = e.target;

    // If there not exactly one files attached, do nothing
    if (files.length !== 1) return;

    const file = files[0];

    const formData = new FormData();
    formData.set('asset_schedule', file);

    this.setState({
      assetScheduleFile: formData,
      assetScheduleFilename: file.name,
      assetScheduleFileDirty: true,
    });
  };

  // Allow users to upload scenarios for at most 10 selected feeders
  getFeederScheduleUploadForm = feeders => (
    <div className="scenario-upload-section">
      <div className="scenario-upload-section-header">
        <h2 className="body-text">{`Upload substation or feeder ${pluralize(
          'schedule',
          feeders.length,
        )}`}</h2>
        <p className="caption-text">
          Visualize your load and generation data for a specific time period.{' '}
          <DocumentationLink className="help-link" documentationPath="powerflow/LoadGeneration">
            Learn More
          </DocumentationLink>
        </p>
      </div>
      <CustomScrollBar>
        <div style={{ maxHeight: 200 }}>
          {feeders.map(fdr => {
            const fileUploading = this.props.scenarioFileUploads[fdr.id] === LOADING;
            const fileComplete = this.props.scenarioFileUploads[fdr.id] === SUCCESS;
            const uploadError = !this.state.hideErrors && this.props.fileUploadErrors[fdr.id];
            return (
              <div key={fdr.id} className="feeder-file-upload">
                <p className="upload-feeder-name">{fdr.name || fdr.id}</p>
                <FileForm
                  accept="*.csv"
                  id={fdr.id}
                  onChange={this.handleFileSelection}
                  disabled={fileUploading || fileComplete}
                >
                  <Button
                    disabled={fileComplete}
                    title={this.state.scenarioFilenames[fdr.id] || 'Select File'}
                  >
                    <span className="feeder-file-upload-btn-label">
                      {this.cutText(this.state.scenarioFilenames[fdr.id] || 'Select File')}
                    </span>
                  </Button>
                  {fileUploading && <i className="material-icons rotate">refresh</i>}
                  {fileComplete && <i className="material-icons success">done</i>}
                  {uploadError && <i className="material-icons error">warning</i>}
                </FileForm>
                {uploadError && <p className="invalid-warning">{uploadError}</p>}
              </div>
            );
          })}
        </div>
      </CustomScrollBar>
    </div>
  );

  getAssetBulkUploadForm = () => {
    const fileUploading = this.props.bulkAssetScheduleUpload === LOADING;
    const fileComplete = this.props.bulkAssetScheduleUpload === SUCCESS;
    const uploadError =
      this.state.assetScheduleFileDirty && this.props.bulkAssetScheduleUploadError;

    return (
      <div className="bulk-upload-section">
        <h2 className="body-text">Upload a bulk asset schedule</h2>
        <FileForm
          accept="*.csv"
          id="bulkScheduleUpload"
          onChange={this.handleAssetScheduleSelection}
          disabled={fileUploading || fileComplete}
        >
          <Button disabled={fileComplete} title={this.state.assetScheduleFilename || 'Select File'}>
            <span className="feeder-file-upload-btn-label">
              {this.cutText(this.state.assetScheduleFilename || 'Select File')}
            </span>
          </Button>
          {fileUploading && <i className="material-icons rotate">refresh</i>}
          {fileComplete && <i className="material-icons success">done</i>}
          {uploadError && <i className="material-icons error">warning</i>}
        </FileForm>
        {uploadError && <p className="invalid-warning">{uploadError}</p>}
      </div>
    );
  };

  cutText = text => {
    // Returns text cut to fit in the 250px btn
    // There was a strong desire to have the button text appear as
    // Name….csv
    const maxChars = 24; // guestimate of what fits on the screen

    if (text.length <= maxChars) {
      return text;
    }

    const parts = text.split('.');
    const extension = parts[parts.length - 1];
    // Add +1 to include the '.' before the extension
    const numFormatChars = extension.length + 1;
    const name = text.substr(0, text.length - numFormatChars);

    const cutName = name.substr(0, Math.min(maxChars - numFormatChars, name.length));

    return `${cutName}….${extension}`;
  };

  getConfirmBtn = isUpdating => {
    let label = 'Save';
    if (isUpdating) {
      label = <i className="material-icons rotate">refresh</i>;
    }

    return { confirm: label };
  };

  getConfirmAction = scenarioExists => {
    const {
      scenarioName,
      scenarioNameDirty,
      scenarioFiles,
      assetScheduleFile,
      scenarioType,
      scenarioTimepoint,
    } = this.state;
    const { scenario, selectedFeeders } = this.props;

    if (!scenarioExists) {
      // create a new scenario
      return () => {
        if (scenarioType === ScenarioTypes.timeseries) {
          this.props.handleScenarioCreation(scenarioName, scenarioFiles, assetScheduleFile);
        } else {
          this.props.handleSnapshotScenarioCreation(
            scenarioName,
            moment(scenarioTimepoint),
            selectedFeeders,
          );
        }
        this.setState({ hideErrors: false });
      };
    }

    // identify failed or new files to retry
    const feederSchedulesToRetry = Object.keys(this.state.scenarioFiles).reduce((lu, feeder) => {
      const status = this.props.scenarioFileUploads[feeder];
      if (status === ERROR || status === undefined) {
        lu[feeder] = this.state.scenarioFiles[feeder];
      }
      return lu;
    }, {});
    const assetScheduleToRetry = this.state.assetScheduleFileDirty ? assetScheduleFile : null;

    if (scenarioNameDirty) {
      // edit scenario and send any files
      return () => {
        this.props.handleScenarioEdit(
          scenario,
          scenarioName,
          feederSchedulesToRetry,
          assetScheduleToRetry,
        );
        this.setState({ hideErrors: false });
      };
    }

    return () => {
      // resend any failed or unsent files
      this.props.handleReUpload(feederSchedulesToRetry, assetScheduleToRetry);
      this.setState({ hideErrors: false });
    };
  };

  render() {
    const {
      scenarioName,
      invalidName,
      scenarioFiles,
      assetScheduleFile,
      scenarioType,
    } = this.state;
    const {
      scenarioErr,
      selectedFeeders,
      scenarioFileUploads,
      bulkAssetScheduleUpload,
    } = this.props;

    const scenarioExists = !!this.props.scenario;

    const hasFileUploads = Object.keys(scenarioFileUploads).length > 0 || assetScheduleFile != null;
    const uploadProgressValues = Object.values(scenarioFileUploads);
    const anyFilesUploading =
      hasFileUploads &&
      (uploadProgressValues.some(val => val === LOADING) || bulkAssetScheduleUpload === LOADING);
    const anyFilesFailed =
      hasFileUploads &&
      (uploadProgressValues.some(val => val === ERROR) || bulkAssetScheduleUpload === ERROR);
    const anyFilesSuccess =
      hasFileUploads &&
      (uploadProgressValues.some(val => val === SUCCESS) || bulkAssetScheduleUpload === SUCCESS);
    const anyFilesPending = Object.keys(scenarioFiles).some(
      val => !Object.keys(scenarioFileUploads).includes(val),
    );
    const sortedFeeders = alphabetizeByKey(this.props.selectedFeeders, 'name');

    const anyUpdating = anyFilesUploading || this.props.scenarioReq === LOADING;
    const anyFailed = anyFilesFailed || this.props.scenarioReq === ERROR;
    const anySuccess = anyFilesSuccess || this.props.scenarioReq === SUCCESS;
    const isDone =
      anySuccess &&
      !anyUpdating &&
      !anyFailed &&
      !anyFilesPending &&
      !this.state.hideErrors &&
      !this.state.assetScheduleFileDirty &&
      !this.state.scenarioNameDirty;

    if (isDone) {
      this.props.handleClose(true);
      return null;
    }

    return (
      <Modal
        active
        width="330px"
        title={this.props.scenario ? 'Edit Scenario' : 'Create Scenario'}
        theme={this.props.theme}
        onCancel={() => this.props.handleClose()}
        onConfirm={this.getConfirmAction(scenarioExists)}
        disableConfirm={!scenarioName || invalidName || anyUpdating}
        labels={this.getConfirmBtn(anyUpdating)}
      >
        <div className="create-modal">
          <label htmlFor="scenario-name">Enter New Scenario Name:</label>
          <TextInput
            id="scenario-name"
            value={scenarioName}
            onChange={this.handleNameChange}
            onKeyPress={this.handleKeyPress}
            required
            invalid={invalidName}
            validationMessage={
              'Invalid name. Must be unique, at least 1 character, not include ' +
              'special characters or whitespace at the beginning or end.'
            }
            theme={this.props.theme}
          />
          {scenarioErr && <p className="invalid-warning">{scenarioErr}</p>}
          <br />
          <RadioButtonGroup
            options={[
              { id: ScenarioTypes.snapshot, label: 'Snapshot', disabled: scenarioExists },
              { id: ScenarioTypes.timeseries, label: 'Timeseries', disabled: scenarioExists },
            ]}
            id="scenario-type-selector"
            theme={this.props.theme}
            value={scenarioType}
            listType="row"
            onChange={this.handleChangeScenarioType}
          />

          {scenarioType === ScenarioTypes.snapshot && !scenarioExists && (
            <DateTimeSelector
              label="Timestamp"
              theme={this.props.theme}
              handleChange={this.handleScenarioTimestampChange}
              date={this.state.scenarioTimepoint}
            />
          )}
          {scenarioType === ScenarioTypes.timeseries && (
            <>
              {selectedFeeders.length > 0 && this.getFeederScheduleUploadForm(sortedFeeders)}
              {this.getAssetBulkUploadForm()}
            </>
          )}
        </div>
      </Modal>
    );
  }
}

ScenarioModal.defaultProps = {
  theme: 'light',
  scenarioReq: 0,
  scenarioErr: null,
  scenarioFileUploads: {},
  bulkAssetScheduleUpload: 0,
  fileUploadErrors: {},
  bulkAssetScheduleUploadError: '',
  scenario: '',
  scenarios: [],
  selectedFeeders: [],
};

ScenarioModal.propTypes = {
  scenario: PropTypes.string,
  scenarios: PropTypes.array,
  selectedFeeders: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  handleReUpload: PropTypes.func.isRequired,
  handleScenarioCreation: PropTypes.func.isRequired,
  handleSnapshotScenarioCreation: PropTypes.func.isRequired,
  handleScenarioEdit: PropTypes.func.isRequired,
  theme: PropTypes.string,
  scenarioReq: PropTypes.number,
  scenarioErr: PropTypes.string,
  scenarioFileUploads: PropTypes.object,
  bulkAssetScheduleUpload: PropTypes.number,
  fileUploadErrors: PropTypes.object,
  bulkAssetScheduleUploadError: PropTypes.string,
};

export default ScenarioModal;
