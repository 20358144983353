import React, { Fragment } from 'react';
import IconButton from 'components/IconButton';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import Modal from 'components/Modal';
import ModalPortal from 'components/ModalPortal';
import { kW, kWh, kVAr, scaleFactorForUnit } from 'helpers/units';
import { isDefined } from 'helpers/utils';
import './ActivityModal.scss';

const ActivityModal = ({
  theme,
  toggleModal,
  batteryResults,
  resultsLoading,
  workspace,
  branch,
}) => {
  const { data: nodeLookup, loading } = useRequestEffect({
    url: `/api/workspace/${workspace}/branch/${branch}/class/ConnectivityNode`,
    method: 'get',
    refetchOnChange: [workspace, branch],
    initialData: {},
  });

  return (
    <ModalPortal id="modal-portal">
      <div className="activity-modal">
        <Modal
          active
          onCancel={() => toggleModal(false)}
          showFooter={false}
          showHeader={false}
          theme={theme}
        >
          <div className="header activity-modal-header">
            <h2>Battery Evaluation Summary</h2>
            <IconButton
              icon="close"
              onClick={() => toggleModal(false)}
              tooltip="Close"
              theme={theme}
              id="close-modal-btn"
            />
          </div>
          {resultsLoading ? (
            <div className="flex-centered">
              <i className="material-icons rotate" style={{ fontSize: 70 }}>
                refresh
              </i>
            </div>
          ) : (
            <>
              <div className="node-list">
                <div className="node-option node-header" key="node-header">
                  <span className="node-item node-item-title-name">Node</span>
                  <span className="node-item node-item-title-phase">Phase</span>
                  <span className="node-item node-item-title-phase">Real Power ({kW})</span>
                  <span className="node-item node-item-title-phase">Reactive Power ({kVAr})</span>
                  <span className="node-item node-item-title-phase">Energy ({kWh})</span>
                </div>
                {Object.keys(batteryResults).map(node => {
                  const singleNode = batteryResults[node] || {};
                  const isValidNodeResults =
                    singleNode !== undefined && Object.keys(singleNode).length !== 0;
                  return (
                    <div
                      className={`node-option node-results ${
                        isValidNodeResults ? 'valid-node-results' : 'invalid-node-results'
                      }`}
                      id={`node-options--${node}`}
                      key={node}
                    >
                      <span className="node-item node-option-item-name">
                        {nodeLookup[node]?.attributes['IdentifiedObject.name'] || node}
                      </span>
                      {isValidNodeResults ? (
                        Object.entries(singleNode).map(
                          ([phase, values]) =>
                            values && (
                              <Fragment key={phase}>
                                <span className="node-item node-option-item-phase">{phase}</span>
                                <span className="node-item node-option-item-max_p">
                                  {isDefined(values.max_p)
                                    ? (values.max_p / scaleFactorForUnit(kVAr)).toFixed(2)
                                    : ''}
                                </span>
                                <span className="node-item node-option-item-max_q">
                                  {isDefined(values.max_q)
                                    ? (values.max_q / scaleFactorForUnit(kW)).toFixed(2)
                                    : ''}
                                </span>
                                <span className="node-item node-option-item-energy">
                                  {isDefined(values.max_energy) &&
                                    (values.max_energy / scaleFactorForUnit(kWh)).toFixed(2)}
                                  {isDefined(values.max_value) &&
                                    (values.max_value / scaleFactorForUnit(kWh)).toFixed(2)}
                                  {!isDefined(values.max_energy) &&
                                    !isDefined(values.max_value) &&
                                    ''}
                                </span>
                              </Fragment>
                            ),
                        )
                      ) : (
                        <span className="node-item node-option-invalid-result">
                          There is no battery size that can solve all violations.
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
              {loading && <span>* Showing node ids while node names load.</span>}
              {!loading && Object.keys(nodeLookup).length === 0 && (
                <span>* Showing node ids as node names failed to load.</span>
              )}
            </>
          )}
        </Modal>
      </div>
    </ModalPortal>
  );
};

ActivityModal.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  batteryResults: nullable(PropTypes.object).isRequired,
  resultsLoading: PropTypes.bool.isRequired,
};
export default ActivityModal;
