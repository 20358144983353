import React, { FunctionComponent } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { alphabetizeByKey } from 'helpers/utils';
import CustomCheckbox from 'components/CustomCheckbox';
import LoadingSkeleton from 'components/LoadingSkeleton';
import { List, AutoSizer, CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import { selectedDERType } from 'types/analysis';

type SingleDERAssetRowType = {
  workspace: string;
  branch: string;
  selectedContainers?: { id: string }[];
  selectedDERs: selectedDERType;
  setSelectedDERs: React.Dispatch<React.SetStateAction<selectedDERType>>;
  assetClass: string;
  setdERAssetTypeLoaded: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
};
type DRAssets = {
  id: string;
  name: string;
}[];
const SingleDERAssetRow: FunctionComponent<SingleDERAssetRowType> = ({
  workspace,
  branch,
  selectedContainers,
  selectedDERs,
  setSelectedDERs,
  assetClass,
  setdERAssetTypeLoaded,
}) => {
  const _cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 40,
  });
  const selectedDerAssetData = alphabetizeByKey(
    Object.values(selectedDERs)?.filter(derObj => derObj.class === assetClass),
    'name',
  );
  const { loading: drAssetDataLoading } = useRequestEffect<DRAssets>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset`,
    method: 'get',
    params: {
      container: selectedContainers?.map(x => x.id),
      type: [assetClass],
    },
    refetchOnChange: [workspace, branch, selectedContainers],
    blockRequest: () =>
      !(
        workspace &&
        branch &&
        selectedContainers &&
        selectedContainers?.length &&
        !selectedDerAssetData?.length
      ),
    onSuccess: data => {
      if (data) {
        const derData: selectedDERType = selectedDERs;
        data?.forEach(der => {
          derData[der.id] = {
            isSelected: false,
            class: assetClass,
            name: der.name,
            id: der.id,
          };
        });
        setSelectedDERs((prevState: selectedDERType) => ({
          ...derData,
          ...prevState,
        }));
        setdERAssetTypeLoaded(prevState => ({ ...prevState, [assetClass]: true }));
      }
    },
  });
  return (
    <div
      className="asset-section"
      style={{ height: Math.min(selectedDerAssetData.length * 38, 120) }}
    >
      {drAssetDataLoading && <LoadingSkeleton width={100} className="text-light" count={5} />}
      {!drAssetDataLoading && selectedDerAssetData.length ? (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="gray-scrollbars"
              containerStyle={{ overflow: 'visible' }}
              deferredMeasurementCache={_cache}
              rowCount={selectedDerAssetData.length}
              rowHeight={_cache.rowHeight}
              width={width}
              height={height}
              rowRenderer={({ index, key, parent }) => {
                const assetObj = selectedDerAssetData[index];
                return (
                  <CellMeasurer
                    cache={_cache}
                    columnIndex={0}
                    key={key}
                    rowIndex={index}
                    parent={parent}
                  >
                    <div className="flex-vertical-center asset-row">
                      <CustomCheckbox
                        id={`#assets-to-all-nodes-${assetObj?.id}`}
                        checked={assetObj.isSelected ?? false}
                        type="primary"
                        disabled={assetObj.disabled}
                        onClick={() => {
                          setSelectedDERs((prevState: selectedDERType) => ({
                            ...prevState,
                            [assetObj.id]: { ...assetObj, isSelected: !assetObj.isSelected },
                          }));
                        }}
                      />
                      <span className="asset-name">{assetObj?.name}</span>
                    </div>
                  </CellMeasurer>
                );
              }}
            />
          )}
        </AutoSizer>
      ) : (
        <p className="asset-row">No asset found</p>
      )}
    </div>
  );
};

export default SingleDERAssetRow;
