import React, { FunctionComponent, Fragment } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import HelpTooltip from 'components/HelpTooltip';
import { CALENDAR_MONTHS } from 'helpers/calendarHelper';
import './SeasonalRatingsViewer.scss';
import { isEmptyObject } from 'helpers/utils';

interface SeasonalRatingsData {
  ratings: { [key: string]: number };
}

type SeasonalRatingsViewerProps = {
  assetId: string;
  workspace: string;
  branch: string;
  scenarioId: string;
};

const SeasonalRatingsViewer: FunctionComponent<SeasonalRatingsViewerProps> = ({
  workspace,
  branch,
  assetId,
  scenarioId,
}) => {
  const { data: seasonalRatings, error } = useRequestEffect<SeasonalRatingsData>({
    url: `/api/workspace/${workspace}/branch/${branch}/ratings_schedules/${assetId}`,
    method: 'get',
    params: {
      scenario_id: scenarioId,
    },
    refetchOnChange: [workspace, branch, assetId, scenarioId],
    blockRequest: () => !(workspace && branch && assetId && scenarioId),
    initialData: { ratings: {} },
  });

  const hasSeasonalRatings = !error && !isEmptyObject(seasonalRatings?.ratings);

  const ratings: { startMonth: string; endMonth: string; value: number }[] = [];
  Object.keys(CALENDAR_MONTHS).forEach(month => {
    const prev = ratings[ratings.length - 1];
    const curr = seasonalRatings?.ratings?.[month.toUpperCase()] ?? 100;
    if (prev?.value === curr) {
      prev.endMonth = month;
    } else {
      ratings.push({
        startMonth: month,
        endMonth: month,
        value: curr,
      });
    }
  });

  return (
    <div className="seasonal-ratings-viewer">
      <div className="header">Nominal Rating</div>
      <div className="header-value">100%</div>
      <div className="header">
        Seasonal Rating
        {scenarioId && hasSeasonalRatings && (
          <HelpTooltip message="To edit the seasonal ratings go to the scenario generation view" />
        )}
      </div>
      <div className="header-value">
        {!scenarioId && 'Scenario not selected'}
        {scenarioId && (hasSeasonalRatings ? 'Active' : 'Not Available')}
      </div>
      {scenarioId && !hasSeasonalRatings && (
        <div className="row-info">
          To edit the seasonal ratings go to the scenario generation view
        </div>
      )}
      {hasSeasonalRatings &&
        ratings.map(month => (
          <Fragment key={month.startMonth}>
            <div className="row-header">
              {month.startMonth === month.endMonth
                ? month.startMonth
                : `${month.startMonth} - ${month.endMonth}`}
            </div>
            <div className="row-value">{`${month.value}%`}</div>
          </Fragment>
        ))}
    </div>
  );
};

export default SeasonalRatingsViewer;
