import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import nullable from 'helpers/nullablePropType';
import moment from 'moment';
import classNames from 'classnames';
import { Request, apm } from '@opusonesolutions/gridos-app-framework';

import asyncActionStates from 'helpers/asyncActionStates';
import Analytics from 'helpers/Analytics';
import fileDownload from 'helpers/FileDownload';
import { displayAsBuilt } from 'helpers/utils';
import { useAttachments } from 'contexts/AttachmentsContext';

import FeederPanel from '../FeederPanel';
import SubstationPanel from '../SubstationPanel';
import PanelHeader from '../PanelHeader';
import MultiContainerPanel from './MultiContainerPanel';
import ContainerHeader from './ContainerHeader';
import ContainerTitle from './ContainerTitle';
import './ContainerPanel.scss';

const CONTAINER_PANELS = {
  Substation: {
    panel: SubstationPanel,
    icon: '/congestion_point.svg',
  },
  Feeder: {
    panel: FeederPanel,
    icon: '/congestion_point.svg',
  },
};

const PERMISSIONS_TO_DELETE = {
  master: 'delete_feeder_as_built',
  default: 'delete_feeder',
};

const deletePermission = branch => PERMISSIONS_TO_DELETE[branch] || PERMISSIONS_TO_DELETE.default;

const ContainerPanel = ({
  selectedContainers,
  selectedScenario,
  selectedScenarioType,
  selectedAnalysis,
  subHourInterval,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  showModal,
  onClose,
  containers,
  permissions,
  isAuthEnabled,
  workspace,
  branch,
  status,
  inEditMode,
  theme,
  actions,
  timepoints,
  expanded,
  allBatterySizingData,
  selectedAssetID,
  selectedAssetViewModelClass,
  activeTab,
  setContainerType,
  containerType,
  selectedContainerOnAssetPanel,
  selectedContainer,
  setSelectedContainer,
  setNotifications,
  setItemCount,
}) => {
  const [container] = selectedContainer ?? [];
  const panelConfig = CONTAINER_PANELS[container?.type];
  const Panel = panelConfig?.panel;
  const { files, notes } = useAttachments();

  const permissionToDeleteFeeders = !isAuthEnabled || permissions.has(deletePermission(branch));
  const handleDeleteFn = permissionToDeleteFeeders ? () => showModal(container) : null;
  useEffect(() => {
    const isTabActive = activeTab === 'files' || activeTab === 'asset' || activeTab === 'notes';
    const fileLengthCheck = Array.isArray(files) && !files?.length;
    const notesLengthCheck = Array.isArray(notes) && !notes?.length;
    if (isTabActive) {
      setNotifications(prevState => ({
        ...prevState,
        filesNotification: !fileLengthCheck,
        notesNotification: !notesLengthCheck,
      }));
      setItemCount(prevState => ({
        ...prevState,
        numberOfNotes: !notesLengthCheck ? notes?.length : null,
        numberOfFiles: !fileLengthCheck ? files?.length : null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, notes, notes?.length, files?.length, activeTab]);

  useEffect(() => {
    if (selectedContainers.length === 1) {
      setSelectedContainer(selectedContainers);
    } else if (
      !selectedContainer ||
      (selectedContainer &&
        selectedContainer.length >= 1 &&
        !selectedContainers.some(con => con.id === selectedContainer[0]?.id))
    ) {
      setSelectedContainer([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainer, selectedContainers]);

  const downloadReport = async (baseURL, additionalParams = null) => {
    const request = new Request(baseURL);
    let result;

    try {
      const { data, headers } = await request.getFile({
        params: {
          feeder: selectedContainer.map(fdr => fdr.id),
          scenario_id: selectedScenario,
          ...(additionalParams ?? {}),
        },
      });
      fileDownload(data, headers);
    } catch (err) {
      apm.captureError(err);
      result = err.response?.status;
    }
    return result;
  };

  const downloadPowerFlowReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/powerflow/report`;
      Analytics.logEvent('Downloaded Powerflow Report', 'Analysis');

      await downloadReport(baseURL, {
        analysis_name: selectedAnalysis.name,
        start_date: timeRange.start.toISOString(),
        end_date: timeRange.end.toISOString(),
      });
    }
  };

  const generatePowerflowReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/powerflow/report/schedule`;
      Analytics.logEvent('Generate Hourly Powerflow Report', 'Analysis');
      const request = new Request(baseURL);
      await request.post(
        {},
        {
          params: {
            feeder: selectedContainer.map(fdr => fdr.id),
            scenario_id: selectedScenario,
            analysis_name: selectedAnalysis.name,
            start_date: timeRange.start.toISOString(),
            end_date: timeRange.end.toISOString(),
          },
        },
      );
      actions.setActivePanel('activityLog');
    }
  };

  const downloadHostingCapacityReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/hosting-capacity-results/report`;
      Analytics.logEvent('Downloaded Hosting Capacity Report', 'Analysis');

      await downloadReport(baseURL, {
        analysis_name: selectedAnalysis.name,
        start_date: timeRange.start.toISOString(),
        end_date: timeRange.end.toISOString(),
      });
    }
  };

  const downloadCostTrackingReport = async () => {
    if (selectedContainer.length === 1) {
      const baseURL = `/api/workspace/${workspace}/branch/${branch}/track`;
      Analytics.logEvent('Downloaded Cost Tracking Report', 'Analysis');
      const result = await downloadReport(baseURL);
      if (result === 404) {
        actions.displayAlertMessage(
          'Download Network Change and Costing',
          `No changes have been made to the network in the ${displayAsBuilt(
            branch,
          )} network version so no report is available.`,
        );
      }
    }
  };

  useEffect(() => {
    if (selectedContainer?.length === 1) {
      actions.getNodeSubstationDistances(workspace, branch, selectedContainer[0].id);
    }
  }, [workspace, branch, selectedContainer, actions]);

  /* 
    when either feeder or substation is selected, 
    do not show the previously selected option's info in the to be be selected feeder panel 
  */
  useEffect(() => {
    if (selectedContainers.length > 1 && selectedContainer) {
      if (selectedContainer[0]?.type === 'Feeder' && containerType === 'Substation')
        setSelectedContainer(null);

      if (selectedContainer[0]?.type === 'Substation' && containerType === 'Feeder')
        setSelectedContainer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainer, containerType, selectedContainers]);

  useEffect(() => {
    if (selectedAssetID && selectedContainers.length > 1) {
      setSelectedContainer(selectedContainerOnAssetPanel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetID, selectedContainers, selectedContainerOnAssetPanel, actions]);

  /* 
    when the user selects only substation, default radio 
    select is switched from feeder to substaion and vice versa
  */
  useEffect(() => {
    if (
      selectedContainers.filter(item => item.type === 'Feeder').length === selectedContainers.length
    ) {
      actions.setContainerTypeSelected('Feeder');
    } else if (
      selectedContainers.filter(item => item.type === 'Substation').length ===
      selectedContainers.length
    ) {
      actions.setContainerTypeSelected('Substation');
    } else {
      actions.setContainerTypeSelected(containerType);
    }
  }, [selectedContainers, containerType, actions]);

  const { start, end } = timeRange;
  const anyResults =
    timepoints.results?.some(val => moment(val).isBetween(start, end, null, '[]')) ?? false;
  const containerInAnalysis =
    selectedContainer &&
    selectedContainer.length !== 0 &&
    selectedAnalysis?.containers?.includes(selectedContainer[0]?.id);
  return (
    <>
      {['asset', 'notes', 'files'].includes(activeTab) && (
        <div className="container-panel">
          <div
            className={classNames({
              'container-panel-header': true,
              'container-panel-header--expanded': expanded,
            })}
          >
            {selectedContainers?.length > 0 && (
              <ContainerTitle selectedContainers={selectedContainers} />
            )}
            {selectedContainers?.length > 1 && (
              <div
                className={classNames({
                  'feeder-selected-content': containerType === 'Feeder',
                  'substation-selected-content': containerType === 'Substation',
                })}
              >
                <MultiContainerPanel
                  containers={selectedContainers}
                  containerSelected={
                    selectedContainer?.[0]?.id ||
                    (selectedAssetID &&
                      selectedContainer &&
                      selectedContainer.length > 1 &&
                      selectedContainerOnAssetPanel?.[0]?.id) ||
                    ''
                  }
                  setSelectedContainer={setSelectedContainer}
                  theme={theme}
                  setContainerType={setContainerType}
                  containerType={containerType}
                />
              </div>
            )}

            {inEditMode &&
              selectedContainers?.length > 1 &&
              (containerType === 'Feeder' || containerType === 'Substation') &&
              container &&
              activeTab === 'asset' && (
                <ContainerHeader
                  className={classNames({
                    'delete-container-edit-mode-feeder': containerType === 'Feeder',
                    'delete-container-edit-mode-substation': containerType === 'Substation',
                  })}
                  container={container}
                  renameContainer={name =>
                    actions.updateContainer(
                      container.type,
                      container.id,
                      { name },
                      'renameContainer',
                    )
                  }
                  branch={branch}
                  inEditMode={inEditMode}
                  permissions={permissions}
                  theme={theme}
                  toggleEditMode={actions.toggleEditMode}
                  deleteFn={handleDeleteFn}
                  loading={
                    (status.renameContainer || status.updateFeeder) === asyncActionStates.LOADING
                  }
                />
              )}
            {selectedContainer &&
              container &&
              selectedContainers.length <= 1 && ( // donot show header if its multiselect feeder
                <PanelHeader
                  name={
                    <ContainerHeader
                      container={container}
                      renameContainer={name =>
                        actions.updateContainer(
                          container.type,
                          container.id,
                          { name },
                          'renameContainer',
                        )
                      }
                      branch={branch}
                      inEditMode={inEditMode}
                      permissions={permissions}
                      theme={theme}
                      toggleEditMode={actions.toggleEditMode}
                      deleteFn={handleDeleteFn}
                      loading={
                        (status.renameContainer || status.updateFeeder) ===
                        asyncActionStates.LOADING
                      }
                    />
                  }
                  type={container.type}
                  icon={panelConfig.icon}
                  showClose={false}
                  onClose={() => onClose(null)}
                  className="support-tabs"
                  customIconClass="teal"
                  id={container.id}
                />
              )}
          </div>
          {selectedContainer && selectedContainer.length === 1 && (
            <Panel
              theme={theme}
              showModal={showModal}
              selectedContainers={selectedContainer}
              selectedAnalysis={selectedAnalysis}
              subHourInterval={subHourInterval}
              selectedScenario={selectedScenario}
              selectedScenarioType={selectedScenarioType}
              containers={containers}
              downloadHostingCapacityReport={downloadHostingCapacityReport}
              downloadCostTrackingReport={downloadCostTrackingReport}
              downloadPowerFlowReport={downloadPowerFlowReport}
              generatePowerflowReport={generatePowerflowReport}
              anyResults={anyResults}
              containerInAnalysis={containerInAnalysis}
              permissions={permissions}
              isAuthEnabled={isAuthEnabled}
              expanded={expanded}
              workspace={workspace}
              branch={branch}
              inEditMode={inEditMode}
              refreshNetworkData={actions.loadNetworkData}
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              allBatterySizingData={allBatterySizingData}
              selectedAssetID={selectedAssetID}
              selectedAssetViewModelClass={selectedAssetViewModelClass}
              updateSelectedScenario={actions.updateSelectedScenario}
              activeTab={activeTab}
              setContainerType={setContainerType}
              containerType={containerType}
              totalContainersSelected={selectedContainers.length}
            />
          )}
        </div>
      )}{' '}
    </>
  );
};

ContainerPanel.defaultProps = {
  setContainerType: null,
  containerType: 'Feeder',
  selectedContainerOnAssetPanel: null,
  selectedContainer: null,
};

ContainerPanel.propTypes = {
  activeTab: PropTypes.string.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  containers: PropTypes.array.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  timepoints: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedScenarioType: PropTypes.string.isRequired,
  selectedAnalysis: nullable(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  subHourInterval: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  allBatterySizingData: PropTypes.object.isRequired,
  selectedAssetID: nullable(PropTypes.string).isRequired,
  selectedAssetViewModelClass: nullable(PropTypes.string).isRequired,
  setContainerType: PropTypes.func,
  containerType: PropTypes.string,
  selectedContainerOnAssetPanel: nullable(PropTypes.array),
  selectedContainer: nullable(PropTypes.array),
  setSelectedContainer: PropTypes.func.isRequired,
  setNotifications: PropTypes.func.isRequired,
  setItemCount: PropTypes.func.isRequired,
};

export default ContainerPanel;
