import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/IconButton';
import { ReactSVG } from 'react-svg';

import './NetworkNavLinks.scss';

const NAV_BUTTONS = [
  {
    name: 'asset',
    activeFor: ['asset', 'feeder'],
    tooltip: 'View Details',
    className: 'information-tab',
    children: <ReactSVG className="validation" src="/info.svg" />,
  },
  {
    name: 'results',
    activeFor: ['results'],
    tooltip: 'Results',
    className: 'information-tab',
    isDisabled: 'results-disabled',
    children: <ReactSVG className="validation" src="/trending_up.svg" />,
  },
  {
    name: 'notes',
    activeFor: ['notes'],
    tooltip: 'Notes',
    className: 'information-tab',
    isDisabled: 'notes-disabled',
    notificationKey: 'notesNotification',
    content: 'numberOfNotes',
    children: <ReactSVG className="validation" src="/file-text.svg" />,
  },
  {
    name: 'files',
    activeFor: ['files'],
    tooltip: 'Files',
    className: 'information-tab',
    isDisabled: 'files-disabled',
    notificationKey: 'filesNotification',
    content: 'numberOfFiles',
    children: <ReactSVG className="validation" src="/folder.svg" />,
  },
  {
    name: 'validation',
    activeFor: ['validation'],
    tooltip: 'Network Validation',
    className: 'validation-tab',
    children: <ReactSVG className="validation" src="/network-error-24px.svg" />,
  },
  {
    name: 'violations',
    activeFor: ['violations'],
    tooltip: 'Network Violations',
    className: 'warning-tab',
    children: <ReactSVG className="validation" src="/alert-triangle.svg" />,
  },

  {
    name: 'layers',
    activeFor: ['layers'],
    tooltip: 'Visual Layers',
    className: 'layer-tab',
    children: <ReactSVG className="validation" src="/layers.svg" />,
  },
  {
    name: 'activityLog',
    activeFor: ['activityLog'],
    tooltip: 'Activity Log',
    className: 'activity-log',
    notificationKey: 'newActivity',
    children: <ReactSVG className="validation" src="/activity.svg" />,
  },
];

// eslint-disable-next-line react/prop-types
const AnalysisMessage = ({ closeMessage }) => (
  <div className="running-tooltip">
    <IconButton className="close" icon="close" onClick={closeMessage} />
    <p className="running-message">Analysis is pending. Check activity log for progress.</p>
  </div>
);

export default function NetworkNavLinks(props) {
  const { active, open, showTooltip, handleTabClick, theme } = props;
  const [hideMessage, setMessageHidden] = useState(false);
  return (
    <div className="asset-panel-icons" id="asset-panel-icons">
      {NAV_BUTTONS.map(button => (
        <IconButton
          key={button.name}
          tooltip={button.tooltip}
          icon={button.icon ? button.icon : null}
          hasNotification={props[button.notificationKey]}
          notificationCount={props[button.content]}
          className={`sliding-panel-tab ${button.className}`}
          active={button.activeFor.includes(active) && open}
          onClick={() => handleTabClick(button.name)}
          tooltipPlacement="left"
          disabled={props[button.isDisabled]}
        >
          {button.children ? button.children : null}
        </IconButton>
      ))}
      {showTooltip && !hideMessage && (
        <AnalysisMessage theme={theme} closeMessage={() => setMessageHidden(true)} />
      )}
    </div>
  );
}

NetworkNavLinks.propTypes = {
  active: PropTypes.string,
  open: PropTypes.bool,
  showTooltip: PropTypes.bool,
  handleTabClick: PropTypes.func.isRequired,
  theme: PropTypes.string,
};
NetworkNavLinks.defaultProps = {
  active: 'asset',
  open: false,
  showTooltip: false,
  theme: 'light',
};
