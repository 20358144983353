import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import asyncStates from 'helpers/asyncActionStates';

import Analytics from 'helpers/Analytics';
import ThemeContext from 'helpers/ThemeContext';

import LifecycleSection from '../partials/LifecycleSection';
import InstanceInfo from './InstanceInfo';
import ControlPanel from '../partials/ControlPanel';
import AssetSchedule from '../partials/AssetSchedule';
import LinkDeviceHeader from '../partials/LinkDeviceHeader';
import SeasonalRatingsViewer from '../partials/SeasonalRatingsViewer';

const controlTypes = [
  { value: false, disabled: false, label: 'Fixed' },
  { value: true, disabled: false, label: 'Schedule' },
];

const displayControlTypes = [
  { value: 'fixed', disabled: false, label: 'Fixed' },
  { value: 'scheduled', disabled: false, label: 'Schedule' },
  { value: 'uncontrolled', disabled: false, label: 'Fixed' },
];

const controlHelp = {
  scheduled:
    'When uploading new schedule data it will add the new data to any existing schedule.' +
    'If a timepoint in the new data is the same as an existing timepoint,' +
    'the old data will be replaced',
};

// Creates a value template for Switch instances
const Switch = ({
  asset: switch_,
  toggleFeederPanel,
  setSelectedAssetID,
  workspace,
  branch,
  displayBranch,
  selected: { id, class: assetClass },
  timeRange,
  maxRange,
  timeBarZoomLevel,
  editActions,
  applyDifferenceModelRequest,
  canEditNetwork,
  inEditMode,
  loadForecast: { selectedScenario, selectedScenarioType, selectedAnalysis },
  permissions,
  isAuthEnabled,
  expanded,
}) => {
  const theme = useContext(ThemeContext);
  const saving = applyDifferenceModelRequest.editValues === asyncStates.LOADING;

  const handleSave = body => editActions.editSingleEquipment(workspace, branch, 'switch', id, body);

  const switch_type = switch_?.attributes?.switch_type;
  return (
    <>
      <div
        className={classNames({
          'asset-panel-values': true,
          'asset-panel-values--expanded': expanded,
        })}
      >
        <LinkDeviceHeader
          asset={{ ...switch_, phases: switch_.phase }}
          disabled={!canEditNetwork || saving}
          toggleFeederPanel={toggleFeederPanel}
          handleSave={handleSave}
          inEditMode={inEditMode}
          setSelectedAssetID={setSelectedAssetID}
          iconName={switch_type === 'Disconnector' ? 'switch' : switch_type.toLowerCase()}
          theme={theme}
        />

        <InstanceInfo
          key={id}
          asset={switch_}
          assetId={id}
          handleSave={handleSave}
          branch={branch}
          displayBranch={displayBranch}
          workspace={workspace}
          disabled={!canEditNetwork || saving}
          inEditMode={inEditMode}
          theme={theme}
        />
        <SeasonalRatingsViewer
          workspace={workspace}
          branch={branch}
          assetId={id}
          scenarioId={selectedScenario}
        />
        <hr className="section-divider" />

        <ControlPanel
          selectedControlType={switch_.attributes.retained}
          help={switch_.attributes.retained ? controlHelp.scheduled : ''}
          onChangeControlType={value => {
            handleSave({ attributes: { retained: value.value } });
            Analytics.logEvent('Change Switch Control Mode', 'Control Modes', value.label);
          }}
          controlTypes={controlTypes}
          displayControlTypes={displayControlTypes}
          disabled={saving || !canEditNetwork}
          inEditMode={inEditMode}
          selectedAnalysis={selectedAnalysis}
          selectedAssetId={id}
          workspace={workspace}
          branch={branch}
        >
          {!inEditMode && switch_.attributes.retained && (
            <AssetSchedule
              workspace={workspace}
              branch={branch}
              scenario={selectedScenario}
              scenarioType={selectedScenarioType}
              asset={{ id, class: assetClass }}
              scheduleType="Normal"
              timeRange={timeRange}
              maxRange={maxRange}
              timeBarZoomLevel={timeBarZoomLevel}
              editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
              expanded={expanded}
            />
          )}
        </ControlPanel>
        <LifecycleSection
          disabled={saving || !inEditMode || !canEditNetwork}
          lifecycle={switch_.lifecycle || {}}
          onChange={lifecycle => handleSave({ lifecycle })}
          inServiceOnly
          healthMetric={switch_.reliability_info?.healthMetric}
          onHealthMetricChange={handleSave}
        />
      </div>
    </>
  );
};

Switch.propTypes = {
  asset: PropTypes.object.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    class: PropTypes.string,
  }).isRequired,
  timeRange: PropTypes.shape({
    start: PropTypes.object,
    end: PropTypes.object,
  }).isRequired,
  maxRange: PropTypes.shape({
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }).isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  editActions: PropTypes.object.isRequired,
  applyDifferenceModelRequest: PropTypes.object.isRequired,
  canEditNetwork: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  loadForecast: PropTypes.shape({
    selectedScenario: PropTypes.string.isRequired,
    selectedScenarioType: PropTypes.string.isRequired,
    selectedAnalysis: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  permissions: PropTypes.object.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
};

export default Switch;
