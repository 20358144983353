import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import DropdownMenu from 'components/DropdownMenu';
import Button from 'components/Button';

const EmptyLibraryPlaceholder = ({ selectEquipment, updateExpandedCategories }) => (
  <div className="info-selection-page info-selection-placeholder" id="empty-library">
    <ReactSVG src="/asset_info_panel_empty.svg" className="svg-icon large" />
    <p>
      The equipment library lets you add, edit, view, and export equipment types. Start by adding an
      equipment type.
    </p>
    <DropdownMenu
      id="categories"
      contentPosition={{ top: '70px', left: '-15px' }}
      menuOptions={[
        { type: 'standard', id: 'batteries', contents: 'Battery' },
        { type: 'standard', id: 'capacitors', contents: 'Capacitors' },
        { type: 'standard', id: 'conductors', contents: 'Conductors' },
        { type: 'standard', id: 'customerTypes', contents: 'Customer Type' },
        { type: 'standard', id: 'inverters', contents: 'Inverters' },
        { type: 'standard', id: 'wireGeometries', contents: 'Wire Geometries' },
        { type: 'standard', id: 'switches', contents: 'Switch' },
        { type: 'standard', id: 'thermalGenerators', contents: 'Thermal Generator' },
        { type: 'standard', id: 'inverters', contents: 'Inverters' },
        { type: 'standard', id: 'photovoltaics', contents: 'Photovoltaic Panels' },
        { type: 'standard', id: 'winds', contents: 'Wind' },
        {
          type: 'standard',
          id: 'transformers',
          contents: 'Transformer',
          disabled: true,
        },
        { type: 'standard', id: 'evChargingStations', contents: 'EV Charging Stations' },
        { type: 'standard', id: 'customerPrograms', contents: 'Customer Programs' },
      ]}
      onClick={type => {
        selectEquipment(type, 'add');
        updateExpandedCategories(type, true);
      }}
    >
      {onClick => <Button type="primary" onClick={onClick} label="Add an equipment type" />}
    </DropdownMenu>
  </div>
);

EmptyLibraryPlaceholder.propTypes = {
  selectEquipment: PropTypes.func.isRequired,
  updateExpandedCategories: PropTypes.func.isRequired,
};

export default EmptyLibraryPlaceholder;
