import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import 'what-input';
import 'formdata-polyfill';

import AppRouter from 'routes';

const AppContainer = ({ store }) => (
  <Provider store={store}>
    <div style={{ height: '100%' }}>
      <AppRouter />
    </div>
  </Provider>
);

AppContainer.propTypes = {
  store: PropTypes.shape({
    asyncReducers: PropTypes.object,
    dispatch: PropTypes.func,
    getState: PropTypes.func,
    liftedStore: PropTypes.object,
    replaceReducer: PropTypes.func,
    subscribe: PropTypes.func,
    unsubscribeHistory: PropTypes.func,
    'Symbol(observable)': PropTypes.object,
  }).isRequired,
};

export default AppContainer;
