const MODAL_TYPES = {
  TOGGLE: 'toggle',
  SAVEAS: 'saveAs',
  VERSION: 'networkVersion',
  VIEW: 'view',
  WORKSPACE: 'workspace',
};

const NEW_MODAL_TYPES = {
  DISCARD_CHANGES: 'discardChanges',
  OVERWRITE: 'overwrite',
  SAVE_NEW_VERSION: 'saveNewVersion',
  BULK_ASSET: 'bulkAsset',
  LOAD_SHIFTING: 'loadshifting',
};

const NEW_BRANCH = 'new_branch';
const OLD_BRANCH = 'old_branch';
const DISCARD = 'discard';

const modalInfos = {
  [NEW_MODAL_TYPES.DISCARD_CHANGES]: {
    title: 'Are you sure you want to cancel and exit editor mode?',
    cancelLabel: 'No, continue editing',
    saveLabel: 'Yes, cancel',
  },
  [NEW_MODAL_TYPES.OVERWRITE]: {
    title: 'Are you sure you want to overwrite the changes to current network version?',
    caption: 'All analysis saved in the original network version will be deleted',
    cancelLabel: 'Cancel',
    saveLabel: 'Yes, overwrite',
  },
  [NEW_MODAL_TYPES.SAVE_NEW_VERSION]: {
    title: 'Save as new network version',
    cancelLabel: 'Cancel',
    saveLabel: 'Confirm',
  },
};

export { MODAL_TYPES, NEW_MODAL_TYPES, NEW_BRANCH, OLD_BRANCH, DISCARD, modalInfos };
