import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandableSection from 'components/ExpandableSection';
import RadioButtonSelectGroup from 'components/RadioButtonSelectGroup';
import ToggleSwitch from 'components/ToggleSwitch';
import { getMultiplier } from 'helpers/utils';
import {
  createRangeBuckets,
  createLabels,
  getPhaseSelectOptions,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import RangeSelector from './RangeSelector';
import RangeSlider from './RangeSlider';
import LayerSelectionButton from './LayerSelectionButton';

class PowerSection extends Component {
  UNSAFE_componentWillMount() {
    this.setState({
      powerGradient: this.getRangeBreaks(this.props.layerOptions),
      editId: '',
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldOptions = this.props.layerOptions;
    const newOptions = nextProps.layerOptions;
    if (
      oldOptions.colors !== newOptions.colors ||
      oldOptions.rangeBreaks !== newOptions.rangeBreaks
    ) {
      this.setState({ powerGradient: this.getRangeBreaks(newOptions), editId: '' });
    }
  }

  /**
   * Create the correct range break labels based on layer type
   * @param  {Object} options Layer options
   * @return {Array}          List of layer labels
   */
  getRangeBreaks = options => {
    const { rangeBreaks } = options;
    const formattedBreaks = rangeBreaks.map(val => {
      const { letter, value } = getMultiplier(val);
      return `${(val / value).toFixed(2)} ${letter}`;
    });
    return createRangeBuckets(options.colors, createLabels(formattedBreaks, this.props.unit));
  };

  unCheckRange = e => {
    const index = parseInt(e.target.value, 10);
    const newSelection = [...this.props.layerOptions.selected];
    newSelection[index] = !newSelection[index];
    this.updateLayer({ selected: newSelection });
  };

  toggleEditPanel = id => this.setState({ editId: id });

  handleLayerOptionSelection = (phaseType, option) => {
    const updateType = phaseType === 'single' ? 'phase' : 'aggType';
    this.updateLayer({ [updateType]: option });
  };

  updateLayer = update => this.props.updateLayerOptions(this.props.layerID, update);

  render() {
    const { layerOptions, showThreePhase, min, theme, toggleDirectionalFlow, layerID } = this.props;
    const { phaseType, aggType, phase, rangeBreaks, selected } = layerOptions;

    const button = () => (
      <LayerSelectionButton
        selected={this.props.selected}
        option={{ label: this.props.layerName, value: layerID }}
        group="results"
        setSelectedLayer={this.props.setSelectedLayer}
      />
    );

    return (
      <ExpandableSection
        className={classNames({
          'layer-expandable-section': true,
          'selected-layer': this.props.selected,
          'layer-expandable-section--expanded': this.props.expanded,
        })}
        renderHeaderContent={button}
        showToggle={false}
        open={this.props.selected}
      >
        <div className="layer-section apparent-power-section">
          {Object.prototype.hasOwnProperty.call(layerOptions, 'directionalFlow') && (
            <div className="toggle-section">
              <span>Directional flow on map</span>
              <ToggleSwitch
                checked={layerOptions.directionalFlow}
                onChange={() => toggleDirectionalFlow(layerID)}
                theme={theme}
                refId={this.props.layerID}
              />
            </div>
          )}
          <div className="selection-section layer-phase-selector">
            <RadioButtonSelectGroup
              id={`${this.props.layerID}-phase-selector`}
              value={phaseType}
              listType="column"
              theme={this.props.theme}
              onSelectChange={this.handleLayerOptionSelection}
              onChange={type => this.updateLayer({ phaseType: type })}
              options={getPhaseSelectOptions(aggType, phase, showThreePhase, this.props.addSum)}
            />
          </div>
          <RangeSlider
            domain={[min, rangeBreaks[0] * 1.1]}
            values={rangeBreaks.slice().reverse()}
            colors={this.props.layerOptions.colors.slice().reverse()}
            format={d => `${d / 1000} k${this.props.unit}`}
            onUpdate={e => {
              this.updateLayer({ rangeBreaks: e.reverse() });
            }}
          />
          <div className="layer-checkbox-group">
            {this.state.powerGradient.length &&
              this.state.powerGradient.map(checkbox => (
                <RangeSelector
                  key={`${this.props.layerID}-range-${checkbox.id}`}
                  settings={checkbox}
                  selected={selected[parseInt(checkbox.id, 10)]}
                  onClick={this.unCheckRange}
                  id={parseInt(checkbox.id, 10)}
                  withEdit
                  toggleEdit={this.toggleEditPanel}
                  showEditPanel={checkbox.id === this.state.editId}
                  layerOptions={layerOptions}
                  handleUpdate={this.updateLayer}
                  editDisabled={!!this.state.editId}
                  editColor
                  editRange
                  unit={this.props.unit}
                  useMultiplier
                  ascending
                />
              ))}
          </div>
        </div>
      </ExpandableSection>
    );
  }
}

PowerSection.defaultProps = {
  addSum: false,
  toggleDirectionalFlow: null,
};

PowerSection.propTypes = {
  layerOptions: PropTypes.shape({
    selected: PropTypes.array,
    mode: PropTypes.string,
    phase: PropTypes.string,
    colors: PropTypes.array,
    rangeBreaks: PropTypes.array,
    directionalFlow: PropTypes.bool,
    phaseType: PropTypes.string,
    aggType: PropTypes.string,
  }).isRequired,
  updateLayerOptions: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelectedLayer: PropTypes.func.isRequired,
  layerName: PropTypes.string.isRequired,
  layerID: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  addSum: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  showThreePhase: PropTypes.bool.isRequired,
  min: PropTypes.number.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleDirectionalFlow: PropTypes.func,
};

export default PowerSection;
