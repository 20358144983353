import React, { Component } from 'react';

const formatValue = WrappedComponent =>
  class FormattedValue extends Component {
    getValue = (value, divisor, edited, precision) => {
      let formattedValue = '';

      // Determine if any transformations need to be applied to the value to display it
      if (Number.isNaN(value)) {
        // If it is a non-numerical value, display as is
        formattedValue = value;
      } else if (value !== null) {
        if (value === '') {
          // Allow users to clear the input completely, prevents 0 from conversion
          // below from persisting.
          formattedValue = value;
        } else {
          // If the value is anything but empty, format to the correct units
          const inUnits = value / divisor;
          formattedValue =
            edited || inUnits === 0
              ? inUnits
              : inUnits.toFixed(Number.isNaN(Number.parseInt(precision, 10)) ? 3 : precision);
          if (precision) {
            // If a precision is provided ensure the value does not exceed the given precision
            const isOkay = `${formattedValue}`.match(`/^(d{1,10}|d{0,10}.d{1,${precision}})$/`);
            const multiplier = 10 ** precision;
            formattedValue = isOkay
              ? formattedValue
              : parseInt(parseFloat(formattedValue) * multiplier, 10) / multiplier;
          }
        }
      }
      return formattedValue;
    };

    render() {
      return <WrappedComponent {...this.props} formatValue={this.getValue} />;
    }
  };

export default formatValue;
