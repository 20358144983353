import { useState } from 'react';

/**
 * similar to use state except it additionally returns a reset function
 */
export default function useResetableState<T>(
  field: T,
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
  const [value, setValue] = useState<T>(field);
  return [value, setValue, () => setValue(field)];
}
