const asyncActionStates = Object.freeze({
  INITIAL: 0,
  LOADING: 1,
  SUCCESS: 2,
  CANCELLED: 3,
  ERROR: 4,
  NOTALLOWED: 5,
});

export default asyncActionStates;
