import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import LoadingSkeleton from 'components/LoadingSkeleton';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey, isDefined } from 'helpers/utils';
import Accordion from 'components/Accordion';
import getControlModeOptions, { customModeOptions } from 'helpers/cim/getControlModeName';
import moment from 'moment';
import HelpTooltip from 'components/HelpTooltip';
import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import { List, WindowScroller } from 'react-virtualized';
import _ from 'lodash';
import { TYPE_MAP } from '../../helpers/NetworkHelpers';
import TimeSelector from './TimeSelector';

type AssetsByControlModes = {
  [asset: string]: {
    [id: string]: {
      cim_class: string;
      control_mode: string;
      id: string;
      linked_equipment_container: null | string;
      name: string;
      typeName: string;
    };
  };
};

type controlModeByAssetId = {
  [assetid: string]: {
    assetClass: string;
    default: string;
    scheduleAnalysis?: string;
    customMode?: string | null;
  };
};

type controlModeObjectType = {
  values: controlModeByAssetId;
  customTime: {
    start: moment.Moment | null;
    end: moment.Moment | null;
  };
};
type ControlModesProps = {
  selectedContainers?: { id: string }[];
  workspace: string;
  branch: string;
  analyses?: [];
  selectedAnalysisInterval: number;
  showSection: boolean;
  setControlModes: (args: controlModeObjectType) => void;
  controlModes: controlModeObjectType;
  supportCustomMode: boolean;
  startRange: moment.Moment;
  endRange: moment.Moment;
};

type ControlModeResults = [
  {
    cim_class: string;
    control_mode: string;
    id: string;
    linked_equipment_container: null | string;
    name: string;
  },
];

const assetSupportPrevAnalysis = ['EnergySource', 'EquivalentSubstation'];
const assetsSupportCutomMode = [
  'SynchronousMachine',
  'CHP',
  'RunOfRiverHydro',
  'InverterPV',
  'Battery',
];
const selectProps = {
  searchable: false,
  clearable: false,
  width: 250,
};

const ControlModesMenu: FunctionComponent<ControlModesProps> = ({
  workspace,
  branch,
  selectedContainers,
  analyses = [],
  selectedAnalysisInterval,
  showSection,
  setControlModes,
  controlModes,
  supportCustomMode,
  startRange,
  endRange,
}) => {
  const theme = useContext(ThemeContext);
  const { data: ControlModesData, loading } = useRequestEffect<AssetsByControlModes>({
    url: `/api/workspace/${workspace}/branch/${branch}/asset/control_modes`,
    method: 'get',
    params: {
      container: selectedContainers?.map(x => x.id),
    },
    refetchOnChange: [workspace, branch, selectedContainers],
    blockRequest: () =>
      !(
        workspace &&
        branch &&
        selectedContainers &&
        selectedContainers &&
        selectedContainers?.length
      ),
    initialData: {},
    dataTransform: results => {
      const resultsData = results as ControlModeResults;
      const allAssetsByClass: AssetsByControlModes = {};
      const assetsByID: controlModeByAssetId = {};
      alphabetizeByKey(resultsData, 'cim_class')?.forEach((asset: ControlModeResults[0]) => {
        const assetClass = asset.cim_class;
        const assetId = asset.id;
        const assetName = TYPE_MAP as { [assetClass: string]: { [key: string]: string } };
        allAssetsByClass[assetClass] = {
          ...allAssetsByClass[assetClass],
          [assetId]: {
            ...asset,
            typeName: assetName[assetClass]?.name,
          },
        };
        assetsByID[assetId] = {
          assetClass,
          default: asset.control_mode,
          ...(assetsSupportCutomMode.includes(assetClass)
            ? {
                customMode: null,
              }
            : {}),
        };
      });
      setControlModes({ values: assetsByID, customTime: { start: null, end: null } });
      return allAssetsByClass;
    },
  });
  const customModesupportedArr = customModeOptions.map(mode => mode.value);
  useEffect(() => {
    if (!supportCustomMode && (controlModes.customTime?.start || controlModes.customTime?.end))
      setControlModes({ ...controlModes, customTime: { start: null, end: null } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportCustomMode]);
  return (
    <div className="controlmodes-selection">
      {showSection && (
        <>
          <p>Control Modes</p>
          <div>
            <div className="controlmode-types title-row">
              <div className="asset-name" style={{ width: '165px' }}>
                Asset type/name
              </div>
              <div className="control-mode-title-col">Default control mode</div>
              {supportCustomMode && (
                <div className="control-mode-title-col">
                  <p>Custom control mode window</p>
                  <div className="controlmode-types">
                    <TimeSelector
                      initialTime={startRange}
                      selectedTime={controlModes.customTime.start}
                      onTimeChange={e =>
                        setControlModes({
                          ...controlModes,
                          customTime: {
                            ...controlModes.customTime,
                            start: e?.value ? moment(e?.value, 'HH:mm') : null,
                          },
                        })
                      }
                      selectType="secondary"
                      placeholder="start"
                      clearable
                    />
                    <TimeSelector
                      initialTime={endRange}
                      selectedTime={controlModes.customTime.end}
                      onTimeChange={e => {
                        setControlModes({
                          ...controlModes,
                          customTime: {
                            ...controlModes.customTime,
                            end: e?.value ? moment(e?.value, 'HH:mm') : null,
                          },
                        });
                      }}
                      selectType="secondary"
                      placeholder="end"
                      clearable
                    />
                  </div>
                </div>
              )}
            </div>
            {loading ? (
              <LoadingSkeleton template="line" width={100} theme={theme} count={4} />
            ) : (
              <Accordion
                theme={theme}
                tabs={Object.entries(ControlModesData ?? [])?.map(
                  ([key, assetObj], index: number) => {
                    const controlModesValues = Object.values(controlModes.values);
                    const controlModesByAssetType = controlModesValues.filter(
                      asset => asset.assetClass === key,
                    );
                    const defaultModeByAssetType = _.uniqBy(controlModesByAssetType, 'default');
                    const customModeByAssetType = _.uniqBy(controlModesByAssetType, 'customMode');
                    const validControlModesByAsset = getControlModeOptions(key)?.map(
                      modeObj => modeObj.value,
                    );
                    const unsupportedModeSelAssetLevel = controlModesByAssetType?.some(
                      asset => !validControlModesByAsset.includes(asset.default),
                    );
                    const assetObjValues = alphabetizeByKey(Object.values(assetObj) ?? {}, 'name');
                    const defaultOptions = [{ label: 'Mixed', value: 'mixed', disabled: true }];
                    const customModeByAsset =
                      customModeByAssetType && customModeByAssetType.length === 1
                        ? customModeByAssetType[0].customMode
                        : 'mixed';
                    const customModesSelByAssetType = customModeByAssetType
                      ?.filter(mode => mode.customMode)
                      .map(mode => mode.customMode);
                    const controlModeOptionsByAsset =
                      customModesSelByAssetType && customModesSelByAssetType.length >= 1
                        ? customModeOptions
                            .map(mode => ({
                              ...mode,
                              disabled: customModesSelByAssetType.includes(mode.value),
                            }))
                            .concat(defaultOptions)
                        : getControlModeOptions(
                            key,
                            assetObjValues?.some(
                              (asset: AssetsByControlModes[0][0]) =>
                                !asset?.linked_equipment_container,
                            ),
                          ).concat(defaultOptions);
                    const customModesAssetLvlOptions = customModeOptions.concat(defaultOptions);
                    return {
                      key: index,
                      title: assetObjValues[0]?.typeName ?? key,
                      tabHeader: (
                        <div className="radio-button-group controlmode-types">
                          <Select
                            {...selectProps}
                            options={controlModeOptionsByAsset}
                            id={`asset-type-selector-${key}`}
                            key={`asset-type-selector-${key}`}
                            value={
                              defaultModeByAssetType && defaultModeByAssetType.length === 1
                                ? defaultModeByAssetType[0].default
                                : 'mixed'
                            }
                            onChange={e => {
                              const assetByIds = Object.values(assetObj).reduce(
                                (obj, assetType) => {
                                  obj = {
                                    ...obj,
                                    [assetType.id]: {
                                      ...controlModes.values[assetType.id],
                                      default: e?.value,
                                    },
                                  };
                                  return obj;
                                },
                                {},
                              );
                              setControlModes({
                                ...controlModes,
                                values: { ...controlModes.values, ...assetByIds },
                              });
                            }}
                            invalid={unsupportedModeSelAssetLevel}
                            tooltip={
                              unsupportedModeSelAssetLevel
                                ? `Select valid control mode for ${key} assets`
                                : ''
                            }
                            type="secondary"
                          />
                          {supportCustomMode && assetsSupportCutomMode.includes(key) && (
                            <Select
                              {...selectProps}
                              options={customModesAssetLvlOptions}
                              id={`custom-mode-type-selector-${key}`}
                              key={`custom-mode-type-selector-${key}`}
                              value={customModeByAsset}
                              onChange={e => {
                                const assetByIds = Object.values(assetObj).reduce(
                                  (obj, assetType) => {
                                    const defaultModeAssetVal =
                                      controlModes.values[assetType.id].default;
                                    obj = {
                                      ...obj,
                                      [assetType.id]: {
                                        ...controlModes.values[assetType.id],
                                        default:
                                          customModesupportedArr.includes(defaultModeAssetVal) &&
                                          e?.value !== defaultModeAssetVal
                                            ? defaultModeAssetVal
                                            : null,
                                        customMode: e?.value ?? null,
                                      },
                                    };
                                    return obj;
                                  },
                                  {},
                                );
                                setControlModes({
                                  ...controlModes,
                                  values: { ...controlModes.values, ...assetByIds },
                                });
                              }}
                              type="secondary"
                              clearable
                              placeholder="Select custom mode"
                            />
                          )}
                        </div>
                      ),
                      tabBody: (
                        <div>
                          <WindowScroller key={key}>
                            {({ height, width, isScrolling, onChildScroll }) => {
                              const assetRowLenKey =
                                assetObjValues?.length > 4 ||
                                (assetSupportPrevAnalysis.includes(key) &&
                                  defaultModeByAssetType?.filter(
                                    asset => asset.default === 'analysisSchedule',
                                  )?.length > 0);
                              const assetRowHeight = assetRowLenKey
                                ? '195px'
                                : `${assetObjValues?.length * 42}px`;
                              return (
                                <div>
                                  <List
                                    autoHeight
                                    autoWidth
                                    isScrolling={isScrolling}
                                    rowCount={assetObjValues?.length}
                                    className={`gray-scrollbars ${
                                      assetRowLenKey ? 'auto-scroll' : 'unset-scroll'
                                    }`}
                                    containerStyle={{
                                      overflowY: 'visible',
                                      height: assetRowHeight,
                                      maxHeight: assetRowHeight,
                                    }}
                                    overscanColumnCount={10}
                                    overscanRowCount={2}
                                    onScroll={onChildScroll}
                                    rowHeight={40}
                                    width={width}
                                    height={height}
                                    rowRenderer={({ index: rowIndex, style, key: rowKey }) => {
                                      const asset = assetObjValues[rowIndex];
                                      const defaultAssetModeObj = controlModes.values[asset.id];
                                      const defaultModeValue = defaultAssetModeObj?.default;
                                      const unsupportedModeSelected = !validControlModesByAsset.includes(
                                        defaultModeValue,
                                      );
                                      const customModeOfAsset = defaultAssetModeObj?.customMode;
                                      const unsupportedCustomModeSelected =
                                        isDefined(customModeOfAsset) &&
                                        customModeOfAsset !== '' &&
                                        !validControlModesByAsset.includes(
                                          customModeOfAsset as string,
                                        );
                                      const controlModeAssetOptions = customModeOfAsset
                                        ? customModeOptions.filter(
                                            mode => mode.value !== customModeOfAsset,
                                          )
                                        : getControlModeOptions(
                                            key,
                                            !asset?.linked_equipment_container,
                                          );
                                      return (
                                        <div
                                          style={{
                                            ...style,
                                            height: 'auto',
                                            position: 'relative',
                                            top: 0,
                                          }}
                                          key={rowKey}
                                        >
                                          <div className="asset-group" key={asset.id}>
                                            <Tooltip content={asset.name}>
                                              <div className="asset-name">{asset.name}</div>
                                            </Tooltip>
                                            <div
                                              className="radio-button-group"
                                              style={{
                                                gridTemplateColumns: '1fr 1fr',
                                                display: 'grid',
                                              }}
                                            >
                                              <div>
                                                <Select
                                                  {...selectProps}
                                                  options={controlModeAssetOptions}
                                                  value={defaultModeValue}
                                                  onChange={e => {
                                                    const newAssetObj = {
                                                      ...controlModes.values,
                                                      [asset.id]: {
                                                        ...defaultAssetModeObj,
                                                        default: e?.value,
                                                      },
                                                    };
                                                    setControlModes({
                                                      ...controlModes,
                                                      values: newAssetObj,
                                                    });
                                                  }}
                                                  invalid={unsupportedModeSelected}
                                                  tooltip={
                                                    unsupportedModeSelected
                                                      ? `Select valid control mode for ${asset.name}`
                                                      : ''
                                                  }
                                                  type="secondary"
                                                  id={`asset-selector-${asset.id}`}
                                                />
                                                {assetSupportPrevAnalysis.includes(key) &&
                                                  defaultModeValue === 'analysisSchedule' && (
                                                    <div className="flex-centered margin-10">
                                                      <Select
                                                        {...selectProps}
                                                        value={
                                                          defaultAssetModeObj?.scheduleAnalysis
                                                        }
                                                        id={`analysis-selector-${asset.id}`}
                                                        options={analyses
                                                          ?.filter(
                                                            (analysis: {
                                                              [key: string]: [string | null];
                                                            }) =>
                                                              analysis.containers.includes(
                                                                asset?.linked_equipment_container,
                                                              ),
                                                          )
                                                          .map(
                                                            (x: {
                                                              id: string;
                                                              name: string;
                                                              interval: number;
                                                            }) => ({
                                                              value: x.id,
                                                              label: x.name,
                                                              disabled:
                                                                x.interval !==
                                                                selectedAnalysisInterval,
                                                            }),
                                                          )}
                                                        onChange={analysis => {
                                                          const newAssetObj = {
                                                            ...controlModes.values,
                                                            [asset.id]: {
                                                              ...defaultAssetModeObj,
                                                              scheduleAnalysis: analysis?.value,
                                                            },
                                                          };
                                                          setControlModes({
                                                            ...controlModes,
                                                            values: newAssetObj,
                                                          });
                                                        }}
                                                        type="secondary"
                                                        invalid={
                                                          !defaultAssetModeObj?.scheduleAnalysis
                                                        }
                                                        noOptionsMessage="No analyses available"
                                                        placeholder="Select an analysis..."
                                                      />
                                                      <HelpTooltip
                                                        message="This asset is configured to use the results of a previous analysis on the same interval for its schedule.
                                      Select which analysis should be used or change the control mode."
                                                      />
                                                    </div>
                                                  )}
                                              </div>
                                              {supportCustomMode &&
                                                assetsSupportCutomMode.includes(key) && (
                                                  <Select
                                                    {...selectProps}
                                                    options={customModeOptions}
                                                    value={defaultAssetModeObj?.customMode ?? null}
                                                    onChange={e => {
                                                      const newAssetObj = {
                                                        ...controlModes.values,
                                                        [asset.id]: {
                                                          ...defaultAssetModeObj,
                                                          default:
                                                            customModesupportedArr.includes(
                                                              defaultModeValue,
                                                            ) && e?.value !== defaultModeValue
                                                              ? defaultModeValue
                                                              : null,
                                                          customMode: e?.value ?? null,
                                                        },
                                                      };
                                                      setControlModes({
                                                        ...controlModes,
                                                        values: newAssetObj,
                                                      });
                                                    }}
                                                    invalid={unsupportedCustomModeSelected}
                                                    tooltip={
                                                      unsupportedCustomModeSelected
                                                        ? `Select valid control mode for ${asset.name}`
                                                        : ''
                                                    }
                                                    type="secondary"
                                                    id={`custom-mode-selector-${asset.id}`}
                                                    placeholder="Select custom mode"
                                                    clearable
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  />
                                </div>
                              );
                            }}
                          </WindowScroller>
                        </div>
                      ),
                    };
                  },
                )}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default ControlModesMenu;
