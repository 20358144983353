import React from 'react';
import PropTypes from 'prop-types';

import './MapLegend.scss';

const LEGEND_OPTIONS = {
  NOTES: {
    color: '#4444a6',
    label: 'Notes',
  },
  FILES: {
    color: '#d184f0',
    label: 'Files',
  },
};

function Indicator({ color, label }) {
  return (
    <div className="indicator">
      <span className="indicator-color" style={{ color }}>
        <i className="material-icons">fiber_manual_record</i>
      </span>
      <p className="indicator-label">{label}</p>
    </div>
  );
}
Indicator.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function MapLegend({ enabledIndicators }) {
  return (
    enabledIndicators.length && (
      <div className="map-legend">
        {enabledIndicators.map(indicator => (
          <Indicator key={indicator} {...LEGEND_OPTIONS[indicator]} />
        ))}
      </div>
    )
  );
}

MapLegend.defaultProps = {
  enabledIndicators: ['NOTES', 'FILES'],
};
MapLegend.propTypes = {
  enabledIndicators: PropTypes.arrayOf(PropTypes.string),
};

export default MapLegend;
