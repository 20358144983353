import { createBrowserHistory } from 'history';

// Need this to be the same object every where it's imported to
// maintain the history state so we define it as a singleton module
// following along with https://derickbailey.com/2016/03/09/creating-a-true-singleton-in-node-js-with-es6-symbols/

const HISTORY_KEY = Symbol.for('webui.history');

const globalSymbols = Object.getOwnPropertySymbols(global);
const hasHistory = globalSymbols.indexOf(HISTORY_KEY) > -1;

if (!hasHistory) {
  global[HISTORY_KEY] = createBrowserHistory();
}

const history = {};

Object.defineProperty(history, 'instance', {
  get() {
    return global[HISTORY_KEY];
  },
});

Object.freeze(history);

export default history.instance;
