import React from 'react';
import PropTypes from 'prop-types';

import InverterConstraintDiagramLegend from './Legend';
import './InverterConstraintDiagram.scss';

const InverterConstraintDiagram = ({
  label,
  inverterMaxP,
  inverterMinP,
  battProperties,
  inverterMaxQ,
  inverterMinQ,
  ratedPowerFactor,
  ratedS,
}) => {
  // Get the sum of minP and maxP of the batteries
  const batteryConstraints = { minP: null, maxP: null };
  Object.values(battProperties).forEach(v => {
    Object.entries(v).forEach(([m, f]) => {
      if (m === 'minP' || m === 'maxP') {
        if (f !== null) {
          batteryConstraints[m] += f;
        }
      }
    });
  });

  // minP values are negative so get the max (to get the min)
  let minP = inverterMinP;
  if (batteryConstraints.minP !== null) {
    minP = Math.max(batteryConstraints.minP, inverterMinP);
  }
  let maxP = inverterMaxP;
  if (batteryConstraints.maxP !== null) {
    maxP = Math.min(batteryConstraints.maxP, inverterMaxP);
  }

  if (
    maxP === null &&
    minP === null &&
    inverterMaxQ === null &&
    inverterMinQ === null &&
    ratedS === null &&
    ratedPowerFactor === null
  ) {
    // If no props, render nothing
    return null;
  }

  // This is the numerical length of the side of the square
  // assuming all the units are the same. We need this to
  // scale the values correctly. Computing `Math.max()` on
  // the half sizes, lets us place the 0,0 coordinate in the center of the SVG
  // We expand by 10% to ensure that all lines are drawn without clipping
  // We set a minimum of 1k to ensure that in a scenario where all ratings are 0 it works.
  const halfSize =
    1.1 *
    Math.max(
      ratedS,
      Math.abs(maxP),
      Math.abs(minP),
      Math.abs(inverterMaxQ),
      Math.abs(inverterMinQ),
      1000,
    );
  const size = 2 * halfSize;
  const dimension = 250;
  const halfDimension = dimension / 2;
  const sRatingRadius = halfDimension * (ratedS / halfSize);

  // Default is no powerfactor constraints
  let xPF = null;
  let yPF = null;

  if (ratedPowerFactor !== null) {
    const angle = Math.acos(ratedPowerFactor);

    xPF = halfDimension + sRatingRadius * ratedPowerFactor;
    yPF = sRatingRadius * Math.sin(angle);
  }

  // Assumes that values increase up and to the right
  const valueToPixel = value => ((halfSize - value) / size) * dimension;

  // Neeed to flip minQ to match valueToPixel assumptions
  const xMaxP = valueToPixel(-1 * maxP);
  const xMinP = valueToPixel(-1 * minP);

  const yMaxQ = valueToPixel(inverterMaxQ);
  const yMinQ = valueToPixel(inverterMinQ);

  const showPowerLimits =
    maxP !== null && minP !== null && inverterMaxQ !== null && inverterMinQ !== null;

  return (
    <div className="inverter-constraint-diagram">
      <h4 className="title">Constraint Diagram</h4>
      <InverterConstraintDiagramLegend
        showPowerFactor={ratedPowerFactor !== null}
        showPowerLimits={showPowerLimits}
      />
      <div className="diagram">
        <svg width={dimension} height={dimension}>
          <defs>
            <clipPath id="operating-region-clip">
              {showPowerLimits && (
                <rect x={xMinP} y={yMaxQ} width={xMaxP - xMinP} height={yMinQ - yMaxQ} />
              )}
              {ratedPowerFactor !== null && (
                <path
                  d={
                    // Start in the center
                    `M ${halfDimension},${halfDimension}` +
                    // Upper PF line
                    `L ${xPF},${halfDimension - yPF}` +
                    // Move to the right edge and down to the intersection of the lower pf line
                    `L ${dimension}, ${halfDimension - yPF}` +
                    `L ${dimension}, ${halfDimension + yPF}` +
                    // Lower PF line
                    `L ${xPF}, ${halfDimension + yPF}` +
                    'z'
                  }
                />
              )}
            </clipPath>
          </defs>
          <line
            id="x-axis"
            className="axis"
            x1={0}
            y1={halfDimension}
            x2={dimension - 1}
            y2={halfDimension}
          />
          <text
            id="x-axis-label"
            className="axis-label"
            x={halfDimension + 10}
            y={halfDimension - 12}
          >
            Active Power
          </text>
          <text
            id="x-axis-chg"
            className="axis-label"
            x={0}
            y={0}
            dominantBaseline="baseline"
            textAnchor="start"
            transform={`translate(${dimension - 10}, ${halfDimension - 30}) rotate(90)`}
          >
            {label}
          </text>
          <path
            className="axis-arrow"
            d={
              `M${dimension},${halfDimension} ` +
              `L${dimension - 10},${halfDimension - 5} ` +
              `L${dimension - 10},${halfDimension + 5} z`
            }
          />
          <line
            id="y-axis"
            className="axis"
            x1={halfDimension}
            y1={1}
            x2={halfDimension}
            y2={dimension}
          />
          <text
            id="y-axis-label"
            className="axis-label"
            x={0}
            y={0}
            transform={`translate(${halfDimension - 15}, ${halfDimension - 5}) rotate(-90)`}
          >
            Reactive Power
          </text>
          <path
            className="axis-arrow"
            d={`M${halfDimension},0 L${halfDimension + 5},10 L${halfDimension - 5},10 z`}
          />
          <text
            id="y-axis-chg"
            className="axis-label"
            x={halfDimension + 10}
            y={10}
            dominantBaseline="baseline"
            textAnchor="start"
          >
            {label}
          </text>
          {ratedS !== null && (
            <circle className="s-rating" cx={halfDimension} cy={halfDimension} r={sRatingRadius} />
          )}
          {maxP !== null && (
            <line
              id="max-p-line"
              className="p-q-rating"
              x1={xMaxP}
              y1={0}
              x2={xMaxP}
              y2={dimension}
            />
          )}
          {minP !== null && (
            <line
              id="min-p-line"
              className="p-q-rating"
              x1={xMinP}
              y1={0}
              x2={xMinP}
              y2={dimension}
            />
          )}
          {inverterMaxQ !== null && (
            <line
              id="max-q-line"
              className="p-q-rating"
              x1={0}
              y1={yMaxQ}
              x2={dimension}
              y2={yMaxQ}
            />
          )}
          {inverterMinQ !== null && (
            <line
              id="min-q-line"
              className="p-q-rating"
              x1={0}
              y1={yMinQ}
              x2={dimension}
              y2={yMinQ}
            />
          )}
          {xPF !== null && yPF !== null && (
            <>
              <line
                id="upper-pf-line"
                className="pf-rating"
                x1={halfDimension}
                y1={halfDimension}
                x2={xPF}
                y2={halfDimension - yPF}
              />
              <line
                id="lower-pf-line"
                className="pf-rating"
                x1={halfDimension}
                y1={halfDimension}
                x2={xPF}
                y2={halfDimension + yPF}
              />
            </>
          )}
          <circle
            id="operating-region"
            className="operating-region"
            clipPath="url(#operating-region-clip)"
            cx={halfDimension}
            cy={halfDimension}
            r={sRatingRadius}
          />
        </svg>
      </div>
    </div>
  );
};

InverterConstraintDiagram.defaultProps = {
  label: 'Chg',
  inverterMaxP: null,
  inverterMinP: null,
  battProperties: [],
  inverterMaxQ: null,
  inverterMinQ: null,
  ratedPowerFactor: null,
  ratedS: null,
};

InverterConstraintDiagram.propTypes = {
  label: PropTypes.string,
  inverterMaxP: PropTypes.number,
  inverterMinP: PropTypes.number,
  battProperties: PropTypes.array,
  inverterMaxQ: PropTypes.number,
  inverterMinQ: PropTypes.number,
  ratedPowerFactor: PropTypes.number,
  ratedS: PropTypes.number,
};

export default InverterConstraintDiagram;
