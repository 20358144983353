import AssetInfo from './AssetInfo';

class ShuntCompensatorInfo extends AssetInfo {
  get maxPowerLoss() {
    return this.attributes['ShuntCompensatorInfo.maxPowerLoss'];
  }

  get ratedCurrent() {
    return this.attributes['ShuntCompensatorInfo.ratedCurrent'];
  }

  get ratedReactivePower() {
    return this.attributes['ShuntCompensatorInfo.ratedReactivePower'];
  }

  get ratedVoltage() {
    return this.attributes['ShuntCompensatorInfo.ratedVoltage'];
  }
}

export default ShuntCompensatorInfo;
