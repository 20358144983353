import React, { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import ExpandableSection from 'components/ExpandableSection';
import LayerSelectionButton from './LayerSelectionButton';
import RangeSelector from './RangeSelector';

type OperationalEnvpSecProps = {
  setSelectedLayer: () => void;
  layerOptions: { [key: string]: any };
  updateLayerOptions: (updates: { [key: string]: string }) => void;
  selected: boolean;
  expanded: boolean;
};

const OperationalEnvpSection: FunctionComponent<OperationalEnvpSecProps> = ({
  setSelectedLayer,
  layerOptions = {},
  updateLayerOptions,
  selected,
  expanded,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const button = () => (
    <LayerSelectionButton
      selected={selected}
      option={{ value: 'operational_envelope', label: 'Operational Envelope' }}
      setSelectedLayer={setSelectedLayer}
    />
  );
  const updateLayer = (update: { [key: string]: any }) => updateLayerOptions(update);
  const handleLayerUpdate = (updates: { [key: string]: string }) => {
    updateLayerOptions(updates);
  };
  const unCheckRange = (e: { target: { value: string } }) => {
    const index = parseInt(e.target.value, 10);
    const newSelection = [...layerOptions.selected];
    newSelection[index] = !newSelection[index];
    updateLayer({ selected: newSelection });
  };
  return (
    <ExpandableSection
      className={classNames({
        'layer-expandable-section': true,
        'selected-layer': selected,
        'layer-expandable-section--expanded': expanded,
      })}
      renderHeaderContent={button}
      showToggle={false}
      open={selected}
    >
      <div className="layer-section">
        <div className="layer-checkbox-group">
          <RangeSelector
            key={0}
            settings={{
              id: '0',
              color: layerOptions.colors[0],
              label: 'Max export/import result',
            }}
            selected={layerOptions.selected[0]}
            onClick={unCheckRange}
            id={0}
            withEdit
            toggleEdit={() => setEditOpen(!editOpen)}
            showEditPanel={editOpen}
            layerOptions={layerOptions}
            handleUpdate={handleLayerUpdate}
            editColor
            ascending
          />
        </div>
      </div>
    </ExpandableSection>
  );
};

export default OperationalEnvpSection;
