import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import asyncActionStates from 'helpers/asyncActionStates';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import { IntlContext } from 'contexts/IntlContext';
import fileDownload from 'helpers/FileDownload';
import { Request, useRequest, useAuthContext } from '@opusonesolutions/gridos-app-framework';
import ThemeContext from 'helpers/ThemeContext';
import { isDefined } from 'helpers/utils';
import moment from 'moment';
import Analytics from 'helpers/Analytics';
import useTrackingState from 'hooks/useTrackingState';
import { ScenarioTypes } from 'helpers/scenarios';
import SchedulePanel from './SchedulePanel';
import {
  safeGetErrorMessage,
  initialScheduleData,
  fetchAssetSchedule,
  getCostOptions,
} from '../../../../../helpers/AssetScheduleHelpers';

const filterData = (scheduleData, scheduleType, variablesFilter, maxRange) => {
  const variables = {};
  let allowedVariables;
  switch (variablesFilter) {
    case 'CostOfEnergy':
      allowedVariables = ['activeEnergyCost', 'reactiveEnergyCost'];
      break;
    case 'CostOfPower':
      allowedVariables = ['activePowerCost', 'reactivePowerCost'];
      break;
    case 'CostOfTapOperation':
      allowedVariables = ['tapChangeCost'];
      break;
    case 'CostOfCapacitorOperation':
      allowedVariables = ['sectionChangeCost'];
      break;
    case 'CO2EmissionRate':
      allowedVariables = ['co2emissionRate'];
      break;
    case 'CO2EmissionCost':
      allowedVariables = ['co2emissionCost'];
      break;
    case 'MaxP':
      allowedVariables = ['pMax'];
      break;
    case 'StartSOC':
      allowedVariables = ['startSOC'];
      break;
    case 'TotalCapacity':
      allowedVariables = ['totalBatteryCapacity'];
      break;
    case 'PowerFactor':
      allowedVariables = ['PF'];
      break;
    default:
      allowedVariables = undefined;
      break;
  }

  const dataVariables = {
    ...(scheduleData ? scheduleData.variables : []),
  };
  Object.keys(dataVariables).forEach(val => {
    const variable = dataVariables[val];
    if (
      variable.schedule_type === scheduleType &&
      (!allowedVariables || allowedVariables.includes(val))
    ) {
      variables[val] = variable;
    }
  });
  // Disallow graphing of aggregated variables which hold their value
  // until the backend properly supports it
  let aggregate_bug = false;
  let datapoints = [];
  if (scheduleType === 'Global') {
    datapoints = scheduleData.datapoints
      .map(datapoint => {
        const filtered_values = {};
        Object.keys(datapoint).forEach(datakey => {
          if (
            isDefined(variables[datakey]) ||
            datakey === 'timestamp' ||
            datakey === 'end_timestamp'
          ) {
            filtered_values[datakey] = datapoint[datakey];
          }
        });
        // Limited graph to display data not more than 24 hours to avoid overrriden datapoints
        const bucketSize = moment.duration(
          moment.utc(maxRange.end).diff(moment.utc(maxRange.start)),
        );
        if (bucketSize.asHours() >= 24) {
          aggregate_bug = true;
        }
        return filtered_values;
      })
      .filter(datapoint => Object.keys(datapoint).length > 1);
  } else {
    datapoints = scheduleData.datapoints
      .map(datapoint => {
        const filtered_values = {};
        Object.keys(datapoint).forEach(datakey => {
          if (isDefined(variables[datakey]) || datakey === 'timestamp') {
            filtered_values[datakey] = datapoint[datakey];
            if (
              !['Hour', 'Min_30', 'Min_15', 'Min_5'].includes(variables[datakey]?.bucket_size) &&
              variables[datakey]?.maintainsValue
            ) {
              aggregate_bug = true;
            }
          }
        });
        return filtered_values;
      })
      .filter(datapoint => Object.keys(datapoint).length > 1);
  }
  return {
    variables,
    datapoints,
    aggregate_bug,
  };
};

const downloadAssetSchedule = async (
  workspace,
  branch,
  scenario,
  asset,
  scheduleType,
  timeRange,
) => {
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${asset.id}/export`,
  );
  try {
    const { data, headers } = await request.getFile({
      params: {
        scenario_id: scenario,
        schedule_type: scheduleType,
        start_date: timeRange.start.toISOString(),
        end_date: timeRange.end.toISOString(),
      },
    });
    fileDownload(data, headers);
  } catch (error) {
    // no error displayed
  }
};

const AssetSchedule = ({
  workspace,
  branch,
  scenario,
  scenarioType,
  asset,
  scheduleType,
  header,
  legend,
  panelValues,
  maxRange,
  editable,
  expanded,
  timeRange,
  timeBarZoomLevel,
  scheduleGenerated,
  onScheduleUploadSuccess,
  displayActionIcons,
  id,
}) => {
  const { currencySymbol } = useContext(IntlContext);
  const [scheduleActionMessage, setActionMessage] = useState(null);
  const [status, setStatus] = useState({
    all: asyncActionStates.INITIAL,
    uploadDeleteStatus: asyncActionStates.INITIAL,
  });

  const [assetScheduleData, setAssetScheduleData] = useState(initialScheduleData);

  const assetClass = ['CHP', 'RunOfRiverHydro'].includes(asset.class)
    ? 'SynchronousMachine'
    : asset.class;

  const theme = useContext(ThemeContext);
  const { permissions } = useAuthContext();

  // set default select value for TCO
  const costOptionsForGlobalAndCost = useMemo(
    () => getCostOptions(asset.class, currencySymbol, scheduleType, permissions),
    [asset.class, currencySymbol, scheduleType, permissions],
  );
  const defaultVariablesFilter = useMemo(() => costOptionsForGlobalAndCost[0]?.value, [
    costOptionsForGlobalAndCost,
  ]);

  const [variablesFilter, setVariablesFilter] = useTrackingState(defaultVariablesFilter);
  const isTimeseries = !!scenario && scenarioType === ScenarioTypes.timeseries;
  let scheduleUploadDisabledMessage = null;
  let scheduleDeleteDisabledMessage = null;
  let scheduleDownloadDisabledMessage = null;
  const uploadAssetSchedule = async file => {
    setStatus({ all: asyncActionStates.LOADING, uploadDeleteStatus: asyncActionStates.LOADING });
    setAssetScheduleData(initialScheduleData);
    Analytics.logEvent('Uploaded Asset Schedule', 'Asset Schedules', scheduleType);
    const formData = new FormData();
    formData.append('asset_schedule', file);
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${asset.id}`,
    );
    try {
      await request.post(formData, {
        params: {
          scenario_id: scenario,
          asset_type: assetClass,
          schedule_type: scheduleType,
        },
      });
      setActionMessage(null);
      setStatus({ all: asyncActionStates.SUCCESS, uploadDeleteStatus: asyncActionStates.SUCCESS });
      onScheduleUploadSuccess();
    } catch (error) {
      setActionMessage(`Could not upload schedule\n${safeGetErrorMessage(error)}`);
      setStatus({ all: asyncActionStates.ERROR, uploadDeleteStatus: asyncActionStates.ERROR });
    }
  };
  const getAssetSchedule = useCallback(async () => {
    if (
      asset.id &&
      branch &&
      maxRange.start &&
      maxRange.end &&
      scenario &&
      timeBarZoomLevel &&
      workspace
    ) {
      setStatus({ all: asyncActionStates.LOADING, uploadDeleteStatus: asyncActionStates.INITIAL });
      setAssetScheduleData(initialScheduleData);
      try {
        const response = await fetchAssetSchedule(
          workspace,
          branch,
          asset.id,
          scenario,
          maxRange,
          timeBarZoomLevel,
          scheduleType,
        );
        setStatus({
          all: asyncActionStates.SUCCESS,
          uploadDeleteStatus: asyncActionStates.INITIAL,
        });
        setAssetScheduleData(response.data);
        setActionMessage(null);
      } catch (error) {
        const errorCode = error && error.response && error.response.status;
        const errorMsg = errorCode === 404 ? '' : safeGetErrorMessage(error);
        setStatus({ all: asyncActionStates.ERROR, uploadDeleteStatus: asyncActionStates.INITIAL });
        setActionMessage(errorMsg);
      }
    }
  }, [asset.id, branch, maxRange, scenario, timeBarZoomLevel, workspace, scheduleType]);

  const { makeRequest: runDeleteAssetSchedule, loading: deleting } = useRequest(
    `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${asset.id}`,
  );
  const deleteAssetSchedule = async () => {
    await runDeleteAssetSchedule({
      method: 'delete',
      params: {
        scenario_id: scenario,
        schedule_type: scheduleType,
      },
      onSuccess: () => {
        getAssetSchedule();
      },
      onError: error => {
        setActionMessage(`Could not delete schedule\n${safeGetErrorMessage(error)}`);
      },
      toast: {
        error: 'Could not delete Asset Schedule.',
        settings: {
          autoDismiss: true,
        },
      },
      // Axios Options
      headers: {
        'Cache-Control': 'no-cache, no-store',
        Pragma: 'no-cache',
        Expires: '0',
      },
      responseType: 'blob',
    });
  };
  const noTimeSeriesErr = 'Schedules require a timeseries scenario.';
  const aggregateBugErr =
    'Cannot display >24 hours for this asset.\nSelect 24 hours or fewer to display schedule.';

  const filteredData = useMemo(
    () => filterData(assetScheduleData, scheduleType, variablesFilter, maxRange),
    [assetScheduleData, scheduleType, variablesFilter, maxRange],
  );
  if (!isTimeseries && scheduleActionMessage !== noTimeSeriesErr) {
    scheduleUploadDisabledMessage = noTimeSeriesErr;
    scheduleDeleteDisabledMessage = noTimeSeriesErr;
    scheduleDownloadDisabledMessage = noTimeSeriesErr;
    setActionMessage(noTimeSeriesErr);
  } else if (filteredData.aggregate_bug && scheduleActionMessage !== aggregateBugErr) {
    setActionMessage(aggregateBugErr);
  }
  useEffect(() => {
    if (status.uploadDeleteStatus === asyncActionStates.SUCCESS && scenario) {
      getAssetSchedule();
    }
  }, [getAssetSchedule, scenario, status.uploadDeleteStatus]);
  useEffect(() => {
    if (scheduleGenerated === asyncActionStates.SUCCESS && scenario) {
      getAssetSchedule();
    }
  }, [getAssetSchedule, scenario, scheduleGenerated]);
  useEffect(() => {
    if (maxRange.start && maxRange.end && scenario && asset.id) {
      getAssetSchedule();
    }
  }, [getAssetSchedule, maxRange, scenario, asset.id]);

  const getYAxisLabel = () => {
    if (scheduleType === 'Cost') {
      return `(${currencySymbol})`;
    }
    if (scheduleType === 'Global') {
      if (variablesFilter === 'StartSOC' || variablesFilter === 'totalBatteryCapacity') {
        return '(%)';
      }
      if (variablesFilter === 'PowerFactor') {
        return '';
      }
      if (variablesFilter === 'MaxP') {
        return '(kW)';
      }
      if (variablesFilter === 'TotalCapacity') {
        return '(kWh)';
      }
    }
    return '';
  };

  return (
    <div id={id}>
      <>
        {costOptionsForGlobalAndCost.length > 0 && (
          <Select
            theme={theme}
            clearable={false}
            value={variablesFilter}
            onChange={v => {
              setVariablesFilter(v.value);
            }}
            className="schedule-filter-select"
            options={costOptionsForGlobalAndCost}
          />
        )}
      </>

      <SchedulePanel
        yAxisLabel={getYAxisLabel()}
        header={header}
        variables={filteredData.variables}
        datapoints={filteredData.datapoints}
        showChart={
          filteredData.datapoints.length > 0 &&
          !scheduleActionMessage &&
          !!scenario &&
          !filteredData.aggregate_bug
        }
        panelValues={panelValues}
        assetType={asset.class}
        scheduleType={scheduleType}
        handleScheduleUpload={
          isTimeseries
            ? event => {
                const file = event.target.files[0];
                return uploadAssetSchedule(file);
              }
            : null
        }
        handleScheduleDelete={
          isTimeseries && filteredData.datapoints.length > 0 ? () => deleteAssetSchedule() : null
        }
        handleScheduleDownload={
          isTimeseries && filteredData.datapoints.length > 0
            ? () =>
                downloadAssetSchedule(workspace, branch, scenario, asset, scheduleType, maxRange)
            : null
        }
        error={scheduleActionMessage}
        editable={editable}
        loading={status.all === asyncActionStates.LOADING || deleting}
        timeRange={timeRange}
        maxRange={maxRange}
        legend={legend}
        scheduleUploadDisabledMessage={scheduleUploadDisabledMessage}
        scheduleDeleteDisabledMessage={scheduleDeleteDisabledMessage}
        scheduleDownloadDisabledMessage={scheduleDownloadDisabledMessage}
        expanded={expanded}
        currencySymbol={currencySymbol}
        theme={theme}
        timeBarZoomLevel={timeBarZoomLevel}
        displayActionIcons={displayActionIcons}
      />
    </div>
  );
};

AssetSchedule.defaultProps = {
  editable: true,
  scenario: null,
  scenarioType: null,
  header: null,
  legend: '',
  panelValues: {},
  scheduleGenerated: asyncActionStates.INITIAL,
  onScheduleUploadSuccess: () => {},
  displayActionIcons: true,
  id: '',
};

AssetSchedule.propTypes = {
  workspace: PropTypes.string.isRequired,
  branch: PropTypes.string.isRequired,
  scenario: PropTypes.string,
  scenarioType: PropTypes.string,
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    class: PropTypes.string.isRequired,
  }).isRequired,
  scheduleType: PropTypes.oneOf(['Normal', 'SoC', 'Cost', 'Global', 'Feeder']).isRequired,
  editable: PropTypes.bool,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  header: PropTypes.string,
  legend: PropTypes.string,
  panelValues: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  scheduleGenerated: PropTypes.number,
  onScheduleUploadSuccess: PropTypes.func,
  displayActionIcons: PropTypes.bool,
  id: PropTypes.string,
};

export default AssetSchedule;
