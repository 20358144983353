import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import withAuth from 'helpers/withAuth';
import * as appStateReducer from 'store/appState';
import { actions } from 'routes/WorkspaceLayout/routes/Network/modules/network';
import { networkEditActions } from 'routes/WorkspaceLayout/routes/Network/modules/networkEdit';
import { powerflowActions } from 'routes/WorkspaceLayout/routes/Network/modules/powerflow';
import { loadForecastActions } from 'routes/WorkspaceLayout/routes/Network/modules/loadForecast';
import { feederActions } from 'routes/WorkspaceLayout/routes/Network/modules/feeders';

import ContainerPanel from './ContainerPanel';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      downloadHostingCapacityReport: powerflowActions.downloadHostingCapacityReport,
      updateContainer: networkEditActions.updateContainer,
      toggleEditMode: networkEditActions.toggleEditMode,
      getNodeSubstationDistances: actions.getNodeSubstationDistances,
      loadNetworkData: actions.loadNetworkData,
      displayAlertMessage: appStateReducer.actions.displayAlertMessage,
      updateSelectedScenario: loadForecastActions.updateSelectedScenario,
      setActivePanel: actions.setActivePanel,
      setContainerTypeSelected: feederActions.setContainerTypeSelected,
    },
    dispatch,
  ),
});

const mapStateToProps = (state, ownProps) => ({
  containerType: state.feeders.containerType,
  selectedContainers: state.feeders.selected,
  containers: state.feeders.list,
  branch: state.network.branch,
  workspace: state.network.workspace,
  status: state.network.applyDifferenceModelRequest,
  inEditMode: state.edit.inEditMode,
  selectedScenario: state.loadForecast.selectedScenario,
  selectedScenarioType: state.loadForecast.selectedScenarioType,
  selectedAnalysis: state.loadForecast.selectedAnalysis,
  subHourInterval: state.loadForecast.subHourInterval,
  timeRange: state.network.timeRange,
  maxRange: state.network.maxRange,
  timeBarZoomLevel: state.network.timeBarZoomLevel,
  timepoints: state.network.timepoints,
  allBatterySizingData: state.powerflow.allBatterySizingData,

  theme: state.global.theme,
  ...ownProps,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(ContainerPanel));
