import IdentifiedObject from '../IdentifiedObject';

class LoadResponseCharacteristic extends IdentifiedObject {
  constructor(id, cimDict) {
    super(id, cimDict);
    this._class = 'LoadResponseCharacteristic';
  }

  get exponentModel() {
    return this.attributes['LoadResponseCharacteristic.exponentModel'];
  }

  get pVoltageExponent() {
    return this.attributes['LoadResponseCharacteristic.pVoltageExponent'];
  }

  get qVoltageExponent() {
    return this.attributes['LoadResponseCharacteristic.qVoltageExponent'];
  }

  get pConstantCurrent() {
    return this.attributes['LoadResponseCharacteristic.pConstantCurrent'];
  }

  get pConstantImpedance() {
    return this.attributes['LoadResponseCharacteristic.pConstantImpedance'];
  }

  get pConstantPower() {
    return this.attributes['LoadResponseCharacteristic.pConstantPower'];
  }

  get qConstantCurrent() {
    return this.attributes['LoadResponseCharacteristic.qConstantCurrent'];
  }

  get qConstantImpedance() {
    return this.attributes['LoadResponseCharacteristic.qConstantImpedance'];
  }

  get qConstantPower() {
    return this.attributes['LoadResponseCharacteristic.qConstantPower'];
  }
}

export default LoadResponseCharacteristic;
