import React from 'react';

export default function AssetPanelLoading() {
  return (
    <div
      className="asset-loading"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: '0.5',
        marginTop: '50px',
      }}
    >
      Loading Asset data...
    </div>
  );
}
