import React, { useRef, useEffect } from 'react'; //eslint-disable-line
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Allows you to insert your modal at a different point in the DOM
// Usually used to prevent styling issues (overflow, position, etc)
const ModalPortal = ({ id, children }) => {
  const element = useRef(document.createElement('div'));
  const modalRoot = useRef(null);

  useEffect(() => {
    modalRoot.current = document.getElementById(id);
    modalRoot.current.appendChild(element.current);

    const el = element.current;
    return () => modalRoot.current.removeChild(el);
  }, [id]);

  return ReactDOM.createPortal(children, element.current);
};

ModalPortal.propTypes = {
  children: PropTypes.node.isRequired, // Modal and contents
  id: PropTypes.string.isRequired, // id of the dom element to insert at
};

export default ModalPortal;
