import React, { FunctionComponent, useContext } from 'react';
import Modal from 'components/Modal';
import ThemeContext from 'helpers/ThemeContext';
import { bulkRatingsModalConfig } from '../helpers/ScenarioGenerationHelpers';

type BulkRatingsUpdateModalProps = {
  modalActiveConfig: bulkRatingsModalConfig;
  savingSeasonalRatings: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const BulkRatingsUpdateModal: FunctionComponent<BulkRatingsUpdateModalProps> = ({
  modalActiveConfig,
  savingSeasonalRatings,
  onCancel,
  onConfirm,
}) => {
  const theme = useContext(ThemeContext);
  const monthToDisplay = `${modalActiveConfig.months?.[0]}-${
    modalActiveConfig.months?.[modalActiveConfig.months.length - 1]
  }`;
  return (
    <Modal
      active={modalActiveConfig.active}
      title={`Are you sure you want to update nominal rating for
      ${monthToDisplay} as ${modalActiveConfig.newVal} to all assets ?`}
      onConfirm={onConfirm}
      onCancel={onCancel}
      theme={theme}
      modalType="primary"
      labels={{
        confirm: savingSeasonalRatings ? (
          <i className="material-icons rotate">refresh</i>
        ) : (
          'Confirm'
        ),
        cancel: 'Discard',
      }}
    >
      <p className="modal-message__p pv-asset-msg">
        Note: This will overwrite all exisiting asset level nominal changes for the selected month.
      </p>
    </Modal>
  );
};

export default BulkRatingsUpdateModal;
