import React, { useContext, useState, FunctionComponent } from 'react';
import { List, AutoSizer } from 'react-virtualized';
import { useSelector } from 'react-redux';
import { Request, useAuthContext } from '@opusonesolutions/gridos-app-framework';

import Analytics from 'helpers/Analytics';
import ThemeContext from 'helpers/ThemeContext';
import { useAttachments } from 'contexts/AttachmentsContext';
import { AttachmentsType, FilesAndNotesType } from '../../types/index';

import FileForm from '../FileForm';

import FileCard from './FileCard';
import './Files.scss';

type FilesPanelProps = {
  assetID?: string | null;
};

interface ReduxState {
  network: {
    workspace: string;
    branch: string;
  };
}

interface Selected {
  workspace: string;
  branch: string;
}

const FilesPanel: FunctionComponent<FilesPanelProps> = ({ assetID }) => {
  const { workspace, branch } = useSelector<ReduxState, Selected>(state => ({
    workspace: state.network.workspace,
    branch: state.network.branch,
  }));
  const { permissions } = useAuthContext();
  const [isLoading, setLoadingState] = useState(false);
  const [isError, setError] = useState(false);
  const theme = useContext(ThemeContext);
  const {
    loading: filesLoading,
    error: filesError,
    files,
    deleteAssetAttachment,
    refetch,
  }: AttachmentsType = useAttachments();

  // For adding files
  const createAttachment = async (e: any) => {
    // If there not exactly one files attached, do nothing
    if (e.target.files.length !== 1) return;

    // Only 1 file should exist / only the first file matters
    const file: any = e.target.files[0];

    // First POST to create object
    const request = new Request(
      `/api/workspace/${workspace}/branch/${branch}/asset/${assetID}/attachment`,
    );
    setLoadingState(true);

    Analytics.logEvent('Attachment Created', 'File Panel');

    try {
      const fileName: string = file.name;
      const { data } = await request.post({ file_name: fileName });

      const { upload_target } = data;

      const bucket = upload_target.url.split('/').reverse()[0];
      const saveRequest = new Request(`/api/files/${bucket}`);

      const formData = new FormData();
      formData.set('file', file);
      Object.entries(upload_target.fields).forEach(([k, v]: any) => formData.set(k, v));

      await saveRequest.post(formData);

      refetch?.();
    } catch (err) {
      setError(true);
    }
    setLoadingState(false);
  };

  const loadingState = filesLoading.attachments || isLoading;
  const errorState = filesError.attachments || isError;
  const wasSuccessful = !loadingState && !errorState;

  // Renderer for each row of the virtualized list
  const renderRow = ({ index, style }: any) => {
    const file: FilesAndNotesType = files[index];
    return (
      <div key={`file-${file.id}`} style={{ ...style, paddingRight: '10px' }}>
        <FileCard
          createdTime={file.created_time}
          disabled={loadingState}
          onDelete={() => deleteAssetAttachment?.({ type: 'attachment', id: file.id })}
          path={file.path}
          permissions={permissions}
          theme={theme}
        />
      </div>
    );
  };

  return (
    <div className="files-panel">
      <FileForm
        id="upload-attachment"
        onChange={createAttachment}
        disabled={!permissions.has('edit_asset_attachments') || loadingState}
      >
        <div className={`create-button ${theme}`} title="Upload">
          Upload
        </div>
      </FileForm>
      {loadingState && <div className="load-message">Loading Attachments</div>}
      {errorState && <div className="error-message">Error retrieving attachments.</div>}
      {wasSuccessful && (
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="gray-scrollbars"
              overscanColumnCount={10}
              rowCount={files?.length}
              rowHeight={120}
              width={width}
              height={height}
              rowRenderer={renderRow}
            />
          )}
        </AutoSizer>
      )}
    </div>
  );
};

export default FilesPanel;
