/* eslint react/no-unused-prop-types: 0 */ // Disabled as it errors on destructured props
// Based off implementation here : https://github.com/bvaughn/react-virtualized/issues/692
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';
import CustomScrollBar from 'components/CustomScrollBar';

class StyledVirtualizedList extends Component {
  List = null;

  /**
   * Combine the scroll events of the custom scroll bar and virtualized list
   * @param  {Event} target  Scroll event on custom scroll bar area
   */
  handleScroll = () => {
    const { scrollTop, scrollLeft } = this._scrollRef;
    const { Grid: grid } = this.List;

    grid.handleScrollEvent({ scrollLeft, scrollTop });
  };

  hideHorizontalScroll = props => (
    <div {...props} style={{ display: 'none' }} className="track-horizontal" />
  );

  render() {
    const { scrollbarClassName, listClassName, alwaysShow, ...props } = this.props;

    return (
      <CustomScrollBar
        onScrollY={this.handleScroll}
        containerRef={ref => {
          this._scrollRef = ref;
        }}
        className={scrollbarClassName}
        alwaysShow={alwaysShow}
      >
        <List
          {...props}
          className={listClassName}
          ref={instance => {
            this.List = instance;
          }}
          style={{
            overflowX: false,
            overflowY: false,
          }}
        />
      </CustomScrollBar>
    );
  }
}

StyledVirtualizedList.defaultProps = {
  alwaysShow: false,
  status: null,
  listClassName: '',
  scrollbarClassName: '',
};

StyledVirtualizedList.propTypes = {
  // Number of rows to pre-render outside of viewable area
  overscanRowCount: PropTypes.number.isRequired,
  // Total number of rows in list
  rowCount: PropTypes.number.isRequired,
  // Row height in px
  rowHeight: PropTypes.number.isRequired,
  // Function that returns the jsx you need for each row
  rowRenderer: PropTypes.func.isRequired,
  // Width in px
  width: PropTypes.number.isRequired,
  // Height in px
  height: PropTypes.number.isRequired,
  // Whether the scrollbar should auto hide
  alwaysShow: PropTypes.bool,
  // Optional prop to trigger refresh of scrollbars
  status: PropTypes.string,
  listClassName: PropTypes.string,
  scrollbarClassName: PropTypes.string,
};

export default StyledVirtualizedList;
