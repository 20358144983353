import { Request } from '@opusonesolutions/gridos-app-framework';
import Analytics from 'helpers/Analytics';
import asyncActionStates from 'helpers/asyncActionStates';
import browserHistory from 'routes/history';

// ------------------------------------
// Constants
// ------------------------------------
const BEGIN_NETWORK_IMPORT = 'BEGIN_NETWORK_IMPORT';
const NETWORK_IMPORT_COMPLETE = 'NETWORK_IMPORT_COMPLETE';
const CREATE_WORKSPACE_SUCCESS = 'CREATE_WORKSPACE_SUCCESS';
const CREATE_WORKSPACE_FAILED = 'CREATE_WORKSPACE_FAILED';
const NETWORK_IMPORT_FINISHED = 'NETWORK_IMPORT_FINISHED';
const NETWORK_IMPORT_ERROR = 'NETWORK_IMPORT_ERROR';
const ADD_TO_FILE_LIST = 'ADD_TO_FILE_LIST';
const REMOVE_FROM_FILE_LIST = 'REMOVE_FROM_FILE_LIST';
const CLEAR_FILE_DETAILS = 'CLEAR_FILE_DETAILS';
const CREATE_EMPTY_LOADING = 'CREATE_EMPTY_LOADING';
const CREATE_EMPTY_SUCCESS = 'CREATE_EMPTY_SUCCESS';
const CREATE_EMPTY_FAILURE = 'CREATE_EMPTY_FAILURE';
// ------------------------------------
// Actions
// ------------------------------------

// eslint-disable-next-line consistent-return
function updateFileUploadStatus(workspace, branch, jobId) {
  try {
    // Update upload status
    const updateFileStatus = new Request(
      `/api/workspace/${workspace}/branch/${branch}/import/${jobId}/upload-success`,
    );
    return updateFileStatus.patch();
  } catch (error) {}
}

function uploadNetworkModel(files, fileType, workspace, branch, coordinatesystem) {
  return async dispatch => {
    const fileData = files.reduce(
      (fileMap, [fileName, fileInfo]) => ({ ...fileMap, [fileName]: fileInfo }),
      {},
    );
    const req = new Request(`/api/workspace/${workspace}/branch/${branch}/import`);
    const { data: importJob } = await req.post({
      file_names: Object.keys(fileData),
      file_type: fileType,
      coordinate_system: coordinatesystem,
    });

    const uploadFile = async (fileName, file) => {
      dispatch({
        type: BEGIN_NETWORK_IMPORT,
        file: fileName,
      });

      const target = importJob.upload_targets[fileName];
      const bucket = target.url.split('/').reverse()[0]; // Uh...
      const saveRequest = new Request(`/api/files/${bucket}`);
      const formData = new FormData();
      formData.set('file', file);

      Object.entries(target.fields).forEach(([k, v]) => {
        formData.set(k, v);
      });

      try {
        const response = await saveRequest.post(formData);
        if (response.status >= 200 && response.status < 300) {
          Analytics.logEvent('Network Model Uploaded', 'File Imports', fileType);
          return dispatch({
            type: NETWORK_IMPORT_FINISHED,
            file: fileName,
          });
        }
        Analytics.logEvent('Network Model Upload Failed', 'File Imports', fileType);

        let errorMsg = '';
        if (response.data) {
          if (response.data.message) {
            errorMsg = response.data.message;
          } else if (response.data.errors) {
            [errorMsg] = response.data.errors;
          }
        }
        return dispatch({
          type: NETWORK_IMPORT_ERROR,
          payload: errorMsg,
          file: fileName,
        });
      } catch (error) {
        Analytics.logEvent('Network Model Upload Failed', 'File Imports', fileType);
        return dispatch({
          type: NETWORK_IMPORT_ERROR,
          payload: error,
          file: fileName,
        });
      }
    };

    const uploadRequests = Object.entries(fileData).map(([fileName, { file }]) =>
      uploadFile(fileName, file),
    );

    await Promise.all(uploadRequests);
    return importJob.id;
  };
}

function beginNetworkImport(files, workspace, branch, fileType = 'cim', coordinatesystem) {
  return async (dispatch, getState) => {
    try {
      // Create the workspace if no branch is provided
      if (!branch) {
        const createWorkspace = new Request(`/api/workspace/${encodeURIComponent(workspace)}`);
        await createWorkspace.post();
        dispatch({ type: CREATE_WORKSPACE_SUCCESS });
      }

      // Process all of the unprocessed files one by one until all complete
      const fileData = Object.entries(files).filter(file => !file[1].processed);
      const branchName = branch || 'master';

      const jobId = await dispatch(
        uploadNetworkModel(fileData, fileType, workspace, branchName, coordinatesystem),
      );

      const newlyProcessedFiles = fileData.map(file => file[0]);
      const processedFiles = getState().importCIM.files;
      const allUplodsSuccessful = newlyProcessedFiles.every(
        file => processedFiles[file].loadingState === asyncActionStates.SUCCESS,
      );

      // If there are no new errors after all files have been completed, redirect to workspace
      if (allUplodsSuccessful) {
        browserHistory.push(`/${workspace}/${branchName}/gis`);
        if (jobId) {
          updateFileUploadStatus(workspace, branchName, jobId);
        }
      }

      return dispatch({ type: NETWORK_IMPORT_COMPLETE });
    } catch (err) {
      return dispatch({ type: CREATE_WORKSPACE_FAILED });
    }
  };
}

// Add files to queue to be processed.
function createEmptyWorkspace(workspace) {
  return async dispatch => {
    const createWorkspace = new Request(`/api/workspace/${encodeURIComponent(workspace)}`);
    dispatch({ type: CREATE_EMPTY_LOADING });
    try {
      await createWorkspace.post();
      browserHistory.push(`/${workspace}/master/gis`);
      return dispatch({ type: CREATE_EMPTY_SUCCESS });
    } catch (err) {
      return dispatch({ type: CREATE_EMPTY_FAILURE });
    }
  };
}

// Add additional files to the upload.
function addToFileList(addedFiles) {
  return {
    type: ADD_TO_FILE_LIST,
    addedFiles,
  };
}

function removeFromFileList(removedFileID) {
  return {
    type: REMOVE_FROM_FILE_LIST,
    removedFileID,
  };
}

// Action to remove all progress data when component unmounts
function clearFileDetails() {
  return {
    type: CLEAR_FILE_DETAILS,
  };
}

export const actions = {
  beginNetworkImport,
  addToFileList,
  removeFromFileList,
  clearFileDetails,
  createEmptyWorkspace,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  files: {},
  importErrors: {},
  uploadInProgress: false,
  createWorkspaceStatus: asyncActionStates.INITIAL,
};

export default function networkSelectorReducer(state = initialState, action) {
  switch (action.type) {
    case BEGIN_NETWORK_IMPORT:
      return {
        ...state,
        uploadInProgress: true,
        files: {
          ...state.files,
          [action.file]: {
            ...state.files[action.file],
            loadingState: asyncActionStates.LOADING,
            processed: true,
          },
        },
      };
    case CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        createWorkspaceStatus: asyncActionStates.SUCCESS,
      };
    case CREATE_WORKSPACE_FAILED:
      return {
        ...state,
        createWorkspaceStatus: asyncActionStates.ERROR,
      };
    case NETWORK_IMPORT_COMPLETE:
      return {
        ...state,
        uploadInProgress: false,
      };
    case NETWORK_IMPORT_FINISHED:
      return {
        ...state,
        files: {
          ...state.files,
          [action.file]: {
            ...state.files[action.file],
            loadingState: asyncActionStates.SUCCESS,
          },
        },
      };
    case NETWORK_IMPORT_ERROR:
      return {
        ...state,
        files: {
          ...state.files,
          [action.file]: {
            ...state.files[action.file],
            loadingState: asyncActionStates.ERROR,
            status: 'error',
          },
        },
        importErrors: {
          ...state.importErrors,
          [action.file]: action.payload,
        },
      };
    case ADD_TO_FILE_LIST:
      return {
        ...state,
        files: { ...state.files, ...action.addedFiles },
      };
    case REMOVE_FROM_FILE_LIST:
      const files = { ...state.files };
      delete files[action.removedFileID];
      return {
        ...state,
        files,
      };
    case CREATE_EMPTY_LOADING:
      return {
        ...state,
        uploadInProgress: true,
      };
    case CREATE_EMPTY_SUCCESS:
    case CREATE_EMPTY_FAILURE:
      return {
        ...state,
        uploadInProgress: false,
      };
    case CLEAR_FILE_DETAILS: {
      return initialState;
    }
    default:
      return state;
  }
}
