import React, { useState, useEffect, useContext } from 'react';
import propTypes from 'prop-types';

import HelpTooltip from 'components/HelpTooltip';
import Select from 'components/Select';
import { Request } from '@opusonesolutions/gridos-app-framework';
import ThemeContext from 'helpers/ThemeContext';
import { alphabetizeByKey } from 'helpers/utils';
import './CustomerProgramSelector.scss';

const CustomerProgramSelector = ({
  workspace,
  branch,
  scenarioId,
  assetId,
  customerPrograms,
  disabled,
}) => {
  const theme = useContext(ThemeContext);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/der-program/${assetId}`,
  );
  const setSelectedProgram = async programId => {
    setErrorMsg(null);
    try {
      await request.put(
        { program_id: programId },
        {
          params: {
            scenario_id: scenarioId,
          },
        },
      );
      setSelectedProgramId(programId);
    } catch (error) {
      setErrorMsg(error?.response?.data?.message);
    }
  };
  const getSelectedProgram = async cancellationToken => {
    setErrorMsg(null);
    if (!scenarioId) {
      setSelectedProgramId(null);
      return;
    }
    try {
      const response = await request.get({
        params: {
          scenario_id: scenarioId,
        },
      });
      if (!cancellationToken.cancelled) {
        setSelectedProgramId(response.data.program_id);
      }
    } catch (error) {
      setErrorMsg(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const cancellationToken = { cancelled: false };
    getSelectedProgram(cancellationToken);
    return () => {
      cancellationToken.cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, scenarioId, assetId, customerPrograms]);

  return (
    <div className="customer-program-selector">
      <Select
        theme={theme}
        options={alphabetizeByKey(
          Object.values(customerPrograms).map(x => ({ value: x.id, label: x.name })),
          'label',
        )}
        disabled={disabled}
        value={selectedProgramId}
        onChange={event => setSelectedProgram(event ? event.value : null)}
        clearable
      />
      <HelpTooltip message="Add or remove a customer program to this asset for the selected scenario" />
      <br />
      {errorMsg && <span className="input-error-message">{errorMsg}</span>}
    </div>
  );
};

CustomerProgramSelector.propTypes = {
  workspace: propTypes.string.isRequired,
  branch: propTypes.string.isRequired,
  scenarioId: propTypes.string.isRequired,
  assetId: propTypes.string.isRequired,
  customerPrograms: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.string.isRequired,
      name: propTypes.string.isRequired,
    }),
  ).isRequired,
  disabled: propTypes.bool.isRequired,
};

export default CustomerProgramSelector;
