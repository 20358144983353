import AssetInfo from './AssetInfo';
import TransformerEndInfo from './TransformerEndInfo';

class TransformerTankInfo extends AssetInfo {
  constructor(id, cimDict) {
    super(id, cimDict);

    this.powerTransformerInfo = this.extractReference(
      id,
      'TransformerTankInfo.PowerTransformerInfo',
    );
  }

  get EndInfos() {
    const endInfoRefs = this.references['TransformerTankInfo.TransformerEndInfos'] || [];
    return endInfoRefs
      .map(info => new TransformerEndInfo(info, this.cimDict))
      .sort((a, b) => a.endNumber - b.endNumber);
  }

  get PowerTransformerInfo() {
    return this.powerTransformerInfo;
  }
}

export default TransformerTankInfo;
