import React, { FunctionComponent, useContext, useState } from 'react';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import Select from 'components/Select';
import ThemeContext from 'helpers/ThemeContext';
import { pu } from 'helpers/units';
import CustomCheckbox from 'components/CustomCheckbox';
import { SimulationOptions } from 'types/analysis';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import OPFOptions from './OPFOptions';
import './QSTSMenu.scss';

const { QSTS_OPF, QSTS_NWE } = ANALYSIS_TYPES;

const longDescriptions: { [key: string]: string | undefined } = {
  loss: 'Optimize assets to reduce system losses.',
  'peak-shaving': 'Optimize assets to reduce load peaks',
  cvr: 'Optimize assets to lower average system voltage',
  'voltage-deviation': 'Optimize assets to reduce voltage deviation across the system',
  cost: 'Optimize assets to lower total system cost',
};

type QSTSMenuProps = {
  substations: { id: string }[];
  simulationOptions: SimulationOptions;
  setSimulationOptions: React.Dispatch<React.SetStateAction<SimulationOptions>>;
  selectedAnalysisType: string;
  onSubmit: (objective: string) => void;
  onPreviousClick: () => void;
  disableSubmit: boolean;
  errorMessage: string;
};

const QSTSMenu: FunctionComponent<QSTSMenuProps> = ({
  substations,
  simulationOptions,
  setSimulationOptions,
  selectedAnalysisType,
  onSubmit,
  onPreviousClick,
  disableSubmit,
  errorMessage,
}) => {
  const theme = useContext(ThemeContext);
  const [objective, setObjective] = useState(
    selectedAnalysisType === QSTS_NWE ? 'no-violations' : '',
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const objectives = [
    { value: 'loss', label: 'Loss' },
    { value: 'peak-shaving', label: 'Peak Shaving' },
    { value: 'cvr', label: 'CVR' },
    { value: 'voltage-deviation', label: 'Voltage Optimization' },
    { value: 'cost', label: 'Cost' },
  ];

  return (
    <div className="qsts-menu config-panel">
      {selectedAnalysisType === QSTS_OPF && (
        <>
          <p className="title-text">Optimal Powerflow</p>
          <div className="menu-row">
            <p className="label">Select Objective</p>
            <Select
              theme={theme}
              searchable={false}
              clearable={false}
              options={objectives}
              value={objective}
              onChange={({ value }) => setObjective(value)}
            />
          </div>

          <p className={`info-text ${objective ? '' : 'info-text-placeholder'}`}>
            {longDescriptions[objective] ?? 'Select objective to view description'}
          </p>
        </>
      )}

      {objective === 'voltage-deviation' && (
        <NumberInput
          label="Voltage Optimization Target"
          value={
            simulationOptions.voltage_deviation_target !== undefined
              ? simulationOptions.voltage_deviation_target
              : '1.0'
          }
          unit={pu}
          onChange={({ value }) =>
            setSimulationOptions({
              ...simulationOptions,
              voltage_deviation_target: value,
            })
          }
          id="voltage-deviation-target-input"
          step="0.01"
          theme={theme}
        />
      )}
      {selectedAnalysisType === QSTS_NWE && (
        <div className="checkbox-rerun-failed-pf">
          <CustomCheckbox
            checked={simulationOptions.RERUN_PF_ON_FAILURE}
            onClick={() =>
              setSimulationOptions({
                ...simulationOptions,
                RERUN_PF_ON_FAILURE: !simulationOptions.RERUN_PF_ON_FAILURE,
              })
            }
          />
          <p className="selection-text">
            Run timeseries powerflow for timeframes where selected optimization has failed
          </p>
        </div>
      )}
      <OPFOptions
        simulationOptions={simulationOptions}
        setSimulationOptions={setSimulationOptions}
        // Todo: Support multi substation analysis
        substationId={substations && substations.length ? substations[0].id : undefined}
        disableButton={setButtonDisabled}
        selectedAnalysisType={selectedAnalysisType}
      />
      <div className="menu-footer">
        <Button
          className="back-btn"
          id="back-btn"
          type="secondary"
          theme={theme}
          onClick={onPreviousClick}
          label="Previous"
        />
        <Button
          label="Run Analysis"
          id="submit-qsts"
          type="primary"
          theme={theme}
          disabled={disableSubmit || buttonDisabled || !objective}
          onClick={() => onSubmit(objective)}
          title={errorMessage}
        />
      </div>
    </div>
  );
};

export default QSTSMenu;
