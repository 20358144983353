import Switch from './Switch';

class Fuse extends Switch {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'fuse';
    this._class = 'Fuse';
    this.displayName = this.class;
  }
}

export default Fuse;
