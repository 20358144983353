import React, { FunctionComponent, ReactElement, useMemo } from 'react';
import './ContainerPanel.scss';

type ContainerTitleProps = {
  selectedContainers: { [key: string]: string }[];
};

/**
    Creates info for number of feeders and substations selected.
*/
const ContainerTitle: FunctionComponent<ContainerTitleProps> = ({ selectedContainers }) => {
  const feedersSelected = useMemo(() => {
    let feeders = 0;
    if (selectedContainers?.length > 0) {
      feeders = selectedContainers.filter(item => item.type === 'Feeder').length;
    }
    return feeders;
  }, [selectedContainers]);

  const substationsSelected = useMemo(() => {
    let substation = 0;
    if (selectedContainers?.length > 0) {
      substation = selectedContainers.filter(item => item.type === 'Substation').length;
    }
    return substation;
  }, [selectedContainers]);

  const handleSummaryInfo = () => {
    let countMessage: ReactElement | null = null;
    if (substationsSelected > 0) {
      countMessage = <h3> {substationsSelected} Substation(s)</h3>;
    }
    if (feedersSelected > 0) {
      countMessage = <h3>{feedersSelected} Feeder(s)</h3>;
    }
    if (substationsSelected > 0 && feedersSelected > 0) {
      countMessage = (
        <>
          <h3>{feedersSelected} Feeder(s)</h3> <span>&nbsp;and&nbsp;</span>{' '}
          <h3>{substationsSelected} Substation(s) </h3>
        </>
      );
    }
    return countMessage;
  };

  return (
    <div className="panel-header-new">
      {((feedersSelected > 0 && substationsSelected > 0) ||
        substationsSelected > 0 ||
        feedersSelected > 0) && (
        <div className="panel-header-contents">
          <div className="panel-header-title">
            <h3 className="header-name">Selected network details</h3>
          </div>

          <div className="panel-header-summary">{handleSummaryInfo()} &nbsp;selected</div>
        </div>
      )}
    </div>
  );
};

export default ContainerTitle;
