import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ExpandableSection from 'components/ExpandableSection';
import CustomScrollBar from 'components/CustomScrollBar';
import NonExpandableSection from 'components/NonExpandableSection';
import ThemeContext from 'helpers/ThemeContext';
import './ViolationsSection.scss';

const ViolationsSection = ({ violations }) => {
  const theme = useContext(ThemeContext) || 'dark';
  return (
    <div className={`${theme} violation-summary-container`}>
      <NonExpandableSection
        sectionTitle="Violations Summary"
        className="violation-section"
        theme={theme}
      >
        {Object.keys(violations).length > 0 &&
          Object.keys(violations).map(type => {
            const count = violations[type].reduce((acc, curr) => {
              // get the count or use 1 if not specified
              const current_count = curr.count || 1;
              if (curr.phases) {
                // if phases are specified multiply the count by
                // the number of violating phases
                return acc + current_count * curr.phases.length;
              }

              return acc + current_count;
            }, 0);
            return (
              <ExpandableSection
                sectionName={`${type} (${count})`}
                className="category-section"
                key={type}
              >
                <div className="violations-box">
                  <CustomScrollBar>
                    <div className="violations-list">
                      {violations[type].length > 0 &&
                        violations[type].map(v => {
                          const message = `${
                            v.violation_value ? `${v.violation_value.toFixed(3)}%` : ''
                          } ${v.violation.split('_')[0]} threshold`;
                          const phases = v.phases ? `${v.phases.toUpperCase()}` : '';
                          return (
                            <div className="violation" key={`${type}-${v.timepoint}-${phases}`}>
                              <div
                                className="violation-time"
                                key={`${type}-${v.timepoint}-${phases}-time`}
                              >
                                {v.timepoint}
                              </div>
                              <div
                                className="violation-value"
                                key={`${type}-${v.timepoint}-${phases}-value`}
                              >
                                {phases ? (
                                  <div>
                                    {phases}
                                    :&nbsp;
                                  </div>
                                ) : (
                                  <div />
                                )}
                                <div>{message}</div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </CustomScrollBar>
                </div>
              </ExpandableSection>
            );
          })}
        {Object.keys(violations).length === 0 && (
          <div className="violations-box-empty">No violations</div>
        )}
      </NonExpandableSection>
    </div>
  );
};

ViolationsSection.defaultProps = {
  violations: {},
};

ViolationsSection.propTypes = {
  violations: PropTypes.object,
};

export default ViolationsSection;
