import React from 'react';
import PropTypes from 'prop-types';
import NumberInput from 'components/NumberInput';
import { kVA, kVll } from 'helpers/units';
import useTrackingState from 'hooks/useTrackingState';
import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';
import SingleEditableValue from '../../Inputs/SingleEditableValue';
import ShuntDeviceHeader from '../partials/ShuntDeviceHeader';

const infoValues = [
  {
    label: 'Fuel Type',
    id: 'fossilFuelType',
    key: 'fossilFuelType',
    type: 'text',
  },
  {
    label: 'Fuel Heat Content',
    id: 'fuelHeatContent',
    key: 'fuelHeatContent',
    unit: (
      <span>
        W<sub>t</sub>
        /L
      </span>
    ),
    type: 'number',
  },
];

const editableValues = [
  {
    id: 'ratedU',
    key: 'ratedU',
    label: 'Rated Voltage',
    unit: kVll,
    divisor: 1000,
    type: 'number',
  },
  {
    id: 'ratedS',
    key: 'ratedS',
    label: 'Rated Power',
    unit: kVA,
    divisor: 1000,
    type: 'number',
  },
  {
    id: 'ratedPowerFactor',
    label: 'Rated Power Factor',
    unit: ' ',
    divisor: 1,
    type: 'number',
  },
];

const validationSchema = {
  ratedPowerFactor: { min: 0, max: 1, required: true },
  ratedS: { min: 1, required: true },
  ratedU: { min: 1, required: true },
};

const getUnitType = assetClass => {
  let type = {};
  switch (assetClass) {
    case 'CHP':
      type = {
        label: 'Combined Heat & Power Unit',
        category: 'CHPs',
        displayName: 'Combined Heat & Power',
      };
      break;
    case 'RunOfRiverHydro':
      type = {
        label: 'Run of River Hydro Plant',
        category: 'hydroGeneratingUnits',
        displayName: 'Run of River Hydro',
      };
      break;
    default:
      type = {
        label: 'Thermal Generating Unit',
        category: 'thermalGenerators',
        displayName: 'Synchronous Machine',
      };
  }
  return type;
};

const InstanceInfo = props => {
  const {
    attributes,
    container,
    generating_unit,
    generating_unit_infos: options = [],
  } = props.asset;
  const { theme, disabled, inEditMode, assetClass } = props;

  const info = props.asset.generating_unit_info ?? {};
  const [values, setValues] = useTrackingState({ ...attributes });

  const handleInfoSelect = ({ value: id }) => props.handleSave({ generating_unit_info: id });

  const isGen =
    generating_unit &&
    ['ThermalGeneratingUnit', 'HydroGeneratingUnit'].includes(generating_unit.class);

  const unitType = getUnitType(assetClass);

  const infoOptions = [
    {
      value: info.id,
      label: info.name || info.id || `Select a ${unitType.label}`,
      disabled: true,
    },
    ...options.map(({ id, name }) => ({ value: id, label: name })),
  ];

  return (
    <>
      <div className="header-container">
        <ShuntDeviceHeader
          asset={props.asset}
          assetId={props.assetId}
          toggleFeederPanel={props.toggleFeederPanel}
          inEditMode={inEditMode}
          disabled={disabled}
          setSelectedAssetID={props.setSelectedAssetID}
          handleSave={props.handleSave}
        />
      </div>

      <hr className="section-divider" />

      <div className="editable-section">
        {isGen && (
          <EquipmentInfoSelector
            eqLibURL={`/${props.workspace}/${props.displayBranch}/library/${unitType.category}`}
            displayName={unitType.displayName}
            feeder={container.id}
            assetId={props.assetId}
            value={info.id}
            loadOptions={() => infoOptions}
            defaultOptions={infoOptions}
            id="generator-info-select"
            onChange={handleInfoSelect}
            disabled={disabled || !inEditMode}
          />
        )}
        {infoValues.map(config => (
          <div className="input-spacing" key={config.key}>
            <NumberInput
              {...config}
              value={info[config.key]}
              inputStyle="panel"
              disabled
              theme={theme}
              className="single-row-input"
              precision={3}
            />
          </div>
        ))}
        <hr className="section-divider" />

        {editableValues.map(field => (
          <div className="input-spacing" key={field.id}>
            <SingleEditableValue
              {...field}
              {...validationSchema[field.id]}
              value={values[field.id]}
              key={field.id}
              onChange={val => setValues({ ...values, [field.id]: val })}
              edited={values[field.id] !== attributes[field.id]}
              onBlur={() => props.handleSave({ attributes: values })}
              disabled={disabled || !inEditMode}
              precision={3}
            />
          </div>
        ))}
      </div>
    </>
  );
};

InstanceInfo.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  toggleFeederPanel: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  setSelectedAssetID: PropTypes.func.isRequired,
  assetId: PropTypes.string.isRequired,
  assetClass: PropTypes.string.isRequired,
};

export default InstanceInfo;
