import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandableSection from 'components/ExpandableSection';
import IconButton from 'components/IconButton';
import Button from 'components/Button';
import NonExpandableSection from 'components/NonExpandableSection';
import JSCIM from 'helpers/JSCIM';
import PerPhaseContainer from '../partials/PerPhaseContainer';
import PerPhaseRow from '../partials/PerPhaseRow';
import { transposePhaseAttributes, diffPerPhaseAttributes } from '../../helpers/valueExtractors';
import SingleEditableValue from '../../Inputs/SingleEditableValue';

const { validationSchema } = JSCIM.PowerTransformer;

// Regulator values
const editablePhaseValues = [
  {
    label: 'Max Tap Position',
    id: 'highStep',
    step: '1',
    precision: 1,
    type: 'number',
    divisor: 1,
    customValidation: validationSchema.highStep.customValidation,
  },
  {
    label: 'Neutral Tap Position',
    id: 'neutralStep',
    step: '1',
    precision: 1,
    divisor: 1,
    type: 'number',
    customValidation: validationSchema.neutralStep.customValidation,
  },
  {
    label: 'Min Tap Position',
    id: 'lowStep',
    step: '1',
    precision: 1,
    type: 'number',
    divisor: 1,
    customValidation: validationSchema.lowStep.customValidation,
  },
  {
    label: 'Voltage Increment',
    id: 'stepVoltageIncrement',
    step: '0.001',
    unit: '%V',
    precision: 3,
    type: 'number',
    customValidation: validationSchema.stepVoltageIncrement.customValidation,
  },
];

const useMemoizedTransposedValue = value => {
  const [original, setOriginal] = useState(value);
  const [transposed, setTransposed] = useState({ ...transposePhaseAttributes(value) });
  if (JSON.stringify(value) !== JSON.stringify(original)) {
    setOriginal(value);
    setTransposed({ ...transposePhaseAttributes(value) });
  }

  return [transposed, setTransposed];
};

const TapChangerSection = ({
  handleSave,
  asset: { balanced, ratio_tap_changer_attributes: perPhaseTapChangerAttributes, phases },
  disabled,
  inEditMode,
  theme,
  isRegulator,
}) => {
  const [tapChangerOpen, toggleTapChangerSection] = useState(false);
  const [values, setValues] = useMemoizedTransposedValue(perPhaseTapChangerAttributes);
  const hasTapChanger = Object.keys(perPhaseTapChangerAttributes).length > 0;
  return !hasTapChanger ? (
    <NonExpandableSection
      sectionTitle="Tap Changer"
      className="tap-changer-button"
      theme={theme}
      renderTitleAuxContent={() => (
        <Button
          id="add-tap-changer"
          type="primary"
          theme={theme}
          label="Add"
          onClick={() => {
            handleSave({ has_tap_changer: true });
            toggleTapChangerSection(true);
          }}
          disabled={disabled || !inEditMode}
        />
      )}
    />
  ) : (
    <ExpandableSection
      className="step-details"
      open={tapChangerOpen}
      renderHeaderContent={() => (
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <div>Tap Changer Settings</div>
          {!isRegulator && (
            <div style={{ marginLeft: '120px' }}>
              <IconButton
                id="delete-tap-changer"
                icon="delete"
                theme={theme}
                onClick={() => {
                  handleSave({ has_tap_changer: false });
                  toggleTapChangerSection(false);
                }}
                disabled={disabled || !inEditMode}
              />
            </div>
          )}
        </div>
      )}
    >
      <div className="editable-section">
        {balanced ? (
          <div>
            {/* TODO: restore validation */}
            {editablePhaseValues.map(({ id, ...rest }) => (
              <div className="input-spacing" key={id}>
                <SingleEditableValue
                  {...rest}
                  id={id}
                  key={id}
                  name={id}
                  precision={3}
                  value={values[id]?.[phases]}
                  edited={values[id]?.[phases] !== perPhaseTapChangerAttributes?.[phases]?.[id]}
                  onChange={value =>
                    setValues({
                      ...values,
                      [id]: {
                        ...values[id],
                        [phases]: value,
                      },
                    })
                  }
                  onBlur={() => {
                    const attrs = transposePhaseAttributes({ [id]: values[id] });
                    handleSave({ ratio_tap_changer_attributes: attrs });
                  }}
                  disabled={disabled || !inEditMode}
                  {...Object.keys(values).reduce(
                    (prev, curr) => Object.assign(prev, { [curr]: values[curr][phases] }),
                    {},
                  )}
                />
              </div>
            ))}
          </div>
        ) : (
          <PerPhaseContainer>
            {/* TODO: restore validation */}
            {editablePhaseValues.map(({ id, ...rest }) => (
              <PerPhaseRow
                {...rest}
                id={id}
                key={id}
                values={values[id]}
                phases={phases}
                editable={!disabled && inEditMode}
                edited={
                  values[id]
                    ? diffPerPhaseAttributes(
                        transposePhaseAttributes(perPhaseTapChangerAttributes)[id],
                        values[id],
                      )
                    : {}
                }
                onChange={e =>
                  setValues({
                    ...values,
                    [id]: {
                      ...values[id],
                      [e.phase]: e.value,
                    },
                  })
                }
                onBlur={() => {
                  const attrs = transposePhaseAttributes({ [id]: values[id] });
                  handleSave({ ratio_tap_changer_attributes: attrs });
                }}
                {...values}
                precision={3}
              />
            ))}
          </PerPhaseContainer>
        )}
      </div>
    </ExpandableSection>
  );
};

TapChangerSection.propTypes = {
  asset: PropTypes.object.isRequired,
  isRegulator: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default TapChangerSection;
