/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, createContext } from 'react';
import { useSelector } from 'react-redux';

import { Request } from '@opusonesolutions/gridos-app-framework';

const initialState = {
  loading: false,
  files: [],
  notes: [],
  assetsWithFiles: [],
  assetsWithNotes: [],
  containersWithFiles: [],
  containersWithNotes: [],
};

export const AttachmentsContext = createContext(initialState);

export const useAttachments = () => useContext(AttachmentsContext);

const getContainersWithAttachments = async (workspace, branch, setContainersWithAttachments) => {
  const requests = [
    new Request(`/api/workspace/${workspace}/branch/${branch}/attachments`),
    new Request(`/api/workspace/${workspace}/branch/${branch}/notes`),
  ];
  const { data: withFiles } = await requests[0].get();
  const { data: withNotes } = await requests[1].get();
  setContainersWithAttachments({
    files: withFiles,
    notes: withNotes,
  });
};

const getAssetsWithAttachments = async (
  workspace,
  branch,
  containerIds,
  setAssetsWithAttachments,
) => {
  if (!containerIds) {
    return;
  }
  const requests = [
    new Request(`/api/workspace/${workspace}/branch/${branch}/attachments`),
    new Request(`/api/workspace/${workspace}/branch/${branch}/notes`),
  ];
  const { data: withFiles } = await requests[0].get({ params: { feeder: containerIds } });
  const { data: withNotes } = await requests[1].get({ params: { feeder: containerIds } });

  setAssetsWithAttachments({
    files: withFiles,
    notes: withNotes,
  });
};

const getAssetAttachments = async (workspace, branch, assetID, setAttachments) => {
  setAttachments({
    files: [],
    notes: [],
  });
  const requests = [
    new Request(`/api/workspace/${workspace}/branch/${branch}/asset/${assetID}/attachment`),
    new Request(`/api/workspace/${workspace}/branch/${branch}/asset/${assetID}/note`),
  ];
  const { data: files } = await requests[0].get();
  const { data: notes } = await requests[1].get();
  setAttachments({
    files,
    notes,
  });
};

const deleteAttachment = async (workspace, branch, assetID, attachment) => {
  const request = new Request(
    `/api/workspace/${workspace}/branch/${branch}/asset/${assetID}/${attachment.type}/${attachment.id}`,
  );
  return request.delete();
};

// eslint-disable-next-line react/prop-types
const AttachmentsProvider = ({ children, workspace, branch, assetID, containerPanel = false }) => {
  const { containers, focus, view } = useSelector(state => {
    if (state.global.view === 'gis') {
      return {
        containers: state.feeders,
        focus: state.network.leftRailPanel,
        view: state.global.view,
      };
    }
    return {
      containers: {},
      focus: 'asset',
      view: state.global.view,
    };
  });
  const [loading, setLoading] = useState({
    attachments: false,
    assetsWithAttachments: false,
    containersWithAttachments: false,
  });
  const [error, setError] = useState({});
  const [refetch, setRefetch] = useState(false);

  const [layerEnabled, setLayerEnabled] = useState(true);
  const onGIS = view === 'gis';
  const showVisualizationLayer = onGIS && layerEnabled;

  const [attachments, setAttachments] = useState({});
  const [assetsWithAttachments, setAssetsWithAttachments] = useState({});

  const [containersWithAttachments, setContainersWithAttachments] = useState({});

  const selectedItem = (() => {
    if (focus === 'asset' || (focus === 'network' && containerPanel)) {
      return assetID || undefined;
    }
    return containers.selected.length ? containers.selected[0].id : undefined;
  })();

  const fetch = async (key, req, shouldRefetch = false) => {
    setLoading({ ...loading, [key]: true });
    setError({ ...error, [key]: null });

    try {
      await req();
    } catch (err) {
      setError({ ...loading, [key]: error.message });
    }

    setRefetch(shouldRefetch);
    setLoading({ ...loading, [key]: shouldRefetch });
  };

  const deleteAssetAttachment = async attachment => {
    await fetch(
      'attachments',
      () => deleteAttachment(workspace, branch, selectedItem, attachment),
      true,
    );
  };

  const makeRequest = (key, condition, req) => () => {
    let didCancel = false;

    if (condition || refetch) {
      if (didCancel) {
        didCancel = false;
        return null;
      }
      fetch(key, req);
    }
    return () => {
      didCancel = true;
    };
  };

  useEffect(
    selectedItem
      ? makeRequest('attachments', !!selectedItem, () =>
          getAssetAttachments(workspace, branch, selectedItem, setAttachments),
        )
      : () => {},
    [workspace, branch, selectedItem, refetch],
  );

  useEffect(
    showVisualizationLayer
      ? makeRequest(
          'assetsWithAttachments',
          containers.selected.length && !Object.keys(assetsWithAttachments).length,
          () =>
            getAssetsWithAttachments(
              workspace,
              branch,
              containers.selected.map(({ id }) => id),
              setAssetsWithAttachments,
            ),
        )
      : () => {
          setAssetsWithAttachments({});
        },
    [workspace, branch, containers.selected, showVisualizationLayer, refetch],
  );

  useEffect(
    showVisualizationLayer
      ? makeRequest(
          'containersWithAttachments',
          containers.list.length && !Object.keys(containersWithAttachments).length,
          () => getContainersWithAttachments(workspace, branch, setContainersWithAttachments),
        )
      : () => {
          setContainersWithAttachments({});
        },
    [workspace, branch, containers.selected, focus, showVisualizationLayer, refetch],
  );

  return (
    <AttachmentsContext.Provider
      value={{
        loading,
        error,
        files: attachments.files,
        notes: attachments.notes,
        assetsWithFiles: assetsWithAttachments.files || [],
        assetsWithNotes: assetsWithAttachments.notes || [],
        containersWithFiles: containersWithAttachments.files || [],
        containersWithNotes: containersWithAttachments.notes || [],
        deleteAssetAttachment,
        attachmentsLayerEnabled: layerEnabled,
        refetch: () => setRefetch(true),
        toggleAttachmentsLayer: status => setLayerEnabled(status),
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};

export default AttachmentsProvider;
