import LinkDevice from './LinkDevice';

class SeriesCompensator extends LinkDevice {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'shunt_capacitor';
    this._class = 'SeriesCompensator';
    this.displayName = 'Series compensator';
  }
}

export default SeriesCompensator;
