import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import { ReactSVG } from 'react-svg';
import nullable from 'helpers/nullablePropType';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import FilesPanel from 'components/Files';
import AssetSchedule from '../AssetPanel/templates/partials/AssetSchedule';
import ReportList from '../ContainerPanel/ReportList';
import AnalysisResultsSection from '../FeederPanel/components/AnalysisResultsSection';
import '../FeederPanel/FeederPanel.scss';

const SubstationPanel = ({
  anyResults,
  containers,
  selectedAnalysis,
  subHourInterval,
  selectedScenario,
  selectedScenarioType,
  downloadHostingCapacityReport,
  downloadPowerFlowReport,
  generatePowerflowReport,
  downloadCostTrackingReport,
  workspace,
  branch,
  isAuthEnabled,
  selectedContainers,
  inEditMode,
  permissions,
  expanded,
  timeRange,
  maxRange,
  timeBarZoomLevel,
  containerInAnalysis,
  updateSelectedScenario,
  activeTab,
  containerType,
  totalContainersSelected,
}) => {
  const substation = selectedContainers[0];
  const { analysisActive } = useContext(ActivityLogContext);
  const updateScenario = useCallback(
    () => updateSelectedScenario(workspace, branch, selectedScenario, selectedScenarioType),
    [workspace, branch, selectedScenario, selectedScenarioType, updateSelectedScenario],
  );

  let analysisRunning = false;
  if (selectedAnalysis) {
    analysisRunning = analysisActive(branch, selectedScenario, selectedAnalysis.name);
  }
  const canAccessAttachments = !isAuthEnabled || permissions.has('view_asset_attachments');
  return (
    <CustomScrollBar>
      {(activeTab === 'asset' ||
        (activeTab === 'asset' &&
          containerType === 'Substation' &&
          selectedContainers[0].type === 'Substation')) && (
        <div
          className={classNames({
            'container-panel-tab-contents': true,
            'container-panel-tab-contents--expanded': expanded,
          })}
        >
          {inEditMode && totalContainersSelected > 1 && (
            <div className="delete-substation-container">
              <div className="icon-container">
                <ReactSVG src="/hydro.svg" className="svg-icon" />
              </div>
            </div>
          )}
          <ReportList
            workspace={workspace}
            branch={branch}
            containers={containers}
            selectedAnalysis={selectedAnalysis}
            selectedScenario={selectedScenario}
            inEditMode={inEditMode}
            downloadPowerFlowReport={downloadPowerFlowReport}
            generatePowerflowReport={generatePowerflowReport}
            downloadHostingCapacityReport={downloadHostingCapacityReport}
            downloadCostTrackingReport={downloadCostTrackingReport}
            anyResults={anyResults}
            containerInAnalysis={containerInAnalysis}
            analysisRunning={analysisRunning}
            timeRange={timeRange}
            subHourInterval={subHourInterval}
          />
          {!inEditMode && (
            <>
              <AssetSchedule
                workspace={workspace}
                branch={branch}
                scenario={selectedScenario}
                scenarioType={selectedScenarioType}
                header="Substation Schedule"
                asset={{ id: substation.id, class: 'feeder' }}
                scheduleType="Feeder"
                timeRange={timeRange}
                maxRange={maxRange}
                timeBarZoomLevel={timeBarZoomLevel}
                editable={!isAuthEnabled || permissions.has('modify_asset_schedule')}
                expanded={expanded}
                onScheduleUploadSuccess={updateScenario}
              />
              <AnalysisResultsSection />
            </>
          )}
        </div>
      )}
      <>
        {activeTab === 'notes' && (
          <div className="container-panel-tab-contents">
            <p>Functionality to create notes is not supported for substation.</p>
            <p>Please select feeder to create notes.</p>
            <br />
          </div>
        )}
        {activeTab === 'files' && canAccessAttachments && (
          <FilesPanel
            assetID={selectedContainers[0].id}
            branch={branch}
            permissions={permissions}
            workspace={workspace}
          />
        )}
      </>
    </CustomScrollBar>
  );
};

SubstationPanel.defaultProps = {
  containerType: '',
};

SubstationPanel.propTypes = {
  anyResults: PropTypes.bool.isRequired,
  containers: PropTypes.array.isRequired,
  selectedContainers: PropTypes.array.isRequired,
  selectedScenario: PropTypes.string.isRequired,
  selectedScenarioType: PropTypes.string.isRequired,
  selectedAnalysis: nullable(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  subHourInterval: PropTypes.number.isRequired,
  timeRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
  workspace: PropTypes.array.isRequired,
  branch: PropTypes.string.isRequired,
  downloadPowerFlowReport: PropTypes.func.isRequired,
  generatePowerflowReport: PropTypes.func.isRequired,
  downloadHostingCapacityReport: PropTypes.func.isRequired,
  downloadCostTrackingReport: PropTypes.func.isRequired,
  isAuthEnabled: PropTypes.bool.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  containerInAnalysis: PropTypes.bool.isRequired,
  updateSelectedScenario: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  containerType: PropTypes.string,
  totalContainersSelected: PropTypes.number.isRequired,
};

export default SubstationPanel;
