import React from 'react';
import PropTypes from 'prop-types';
import PerPhaseRow from '../templates/partials/PerPhaseRow';

// Create a row of editable values shown by phase
const EditablePhaseValues = props => {
  const { id } = props;

  // Check if any keys are phases
  const hasPhaseValue = values =>
    Object.keys(values).some(val => val === 'A' || val === 'B' || val === 'C');

  // Don't render if props[props.id] is undefined or null. props[props.id] should be an
  // object with the phases `A,B,C` as keys
  if (!props[id] && typeof props[id] === 'object' && hasPhaseValue(props[id])) {
    return null;
  }

  return (
    <PerPhaseRow
      {...props}
      phases={props.phase}
      key={`${props.label}-${props.id}`}
      values={props[props.id]}
      editable={props.editable}
      edited={props.edited[props.id]}
      id={props.id}
      onChange={props.onChange}
      submitted={props.submitted}
      disabled={props.disabled}
    />
  );
};

EditablePhaseValues.defaultProps = {
  submitted: false,
  editable: true,
  disabled: false,
  edited: {},
};

EditablePhaseValues.propTypes = {
  phase: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  edited: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default EditablePhaseValues;
