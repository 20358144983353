import React, { createContext, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import useResetableState from 'hooks/useResetableState';
import moment, { Moment } from 'moment';
import { useSelector } from 'react-redux';
import { useRequestEffect, Request } from '@opusonesolutions/gridos-app-framework';
import { isEmptyObject } from 'helpers/utils';
import {
  determineDefaultZoomLevel,
  getEndOfInterval,
  ZoomLevel,
} from 'components/ZoomableRangeSlider/intervals';
import { Scenario } from 'helpers/scenarios';
import { views, modalTypes } from '../helpers/ScenarioGenerationHelpers';
import { determineScenarioTimeSpan } from '../../Network/helpers/NetworkHelpers';

type ContextState = {
  selectedScenario: Scenario | null;
  setSelectedScenario: (scenario: Scenario) => void;
  selectedContainer: string | null;
  setSelectedContainer: (container: string | null) => void;
  feedersInScenario?: { [key: string]: string }[];
  feedersInSceLoading: boolean;
  view: views;
  setView: (type: views) => void;
  scenarios: Scenario[];
  scenariosLoading: boolean;
  resetScenario: () => void;
  modalActiveType: modalTypes | null;
  setModalActiveType: (type: modalTypes) => void;
  resetModalType: () => void;
  scenarioRange: { start: Moment | null; end: Moment | null };
  setGeneratedContingencyId: (value: string) => void;
  generatedContingencyId?: string | null;
  timeBarZoomLevel?: ZoomLevel | null;
  timeSpanLoading: boolean;
};
interface ReduxState {
  feeders: {
    list: { [key: string]: string }[];
  };
}
interface Selected {
  containerList: { [key: string]: string }[];
}
type containersListHasSchedules = {
  [container: string]: { [key: string]: string };
};
export const ScenarioGenerationContext = createContext<ContextState>({
  selectedScenario: null,
  setSelectedScenario: () => {},
  selectedContainer: null,
  setSelectedContainer: () => {},
  feedersInScenario: [],
  feedersInSceLoading: false,
  view: views.scenario,
  setView: () => {},
  scenarios: [],
  scenariosLoading: false,
  resetScenario: () => {},
  modalActiveType: null,
  setModalActiveType: () => {},
  resetModalType: () => {},
  scenarioRange: { start: null, end: null },
  setGeneratedContingencyId: () => {},
  generatedContingencyId: null,
  timeBarZoomLevel: null,
  timeSpanLoading: false,
});

interface ScenarioGenerationProvider {
  children: JSX.Element;
}

export default function ScenarioGenerationContextProvider({
  children,
}: ScenarioGenerationProvider): JSX.Element {
  const [scenario, setScenario, resetScenario] = useResetableState<Scenario | null>(null);
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [scenariosLoading, setScenariosLoading] = useState(false);
  const [timeSpanLoading, setTimeSpanLoading] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState<string | null>(null);
  const [
    modalActiveType,
    setModalActiveType,
    resetModalType,
  ] = useResetableState<modalTypes | null>(null);
  const [view, setView] = useState<views>(views.scenario);
  const [generatedContingencyId, setGeneratedContingencyId] = useState<string | null>(null);
  const setSelectedScenario = (value: Scenario | null) =>
    value ? setScenario(value) : resetScenario();
  const { workspace, branch } = useParams<{ workspace: string; branch: string }>();
  const { containerList } = useSelector<ReduxState, Selected>(state => ({
    containerList: state.feeders.list,
  }));
  const [scenarioRange, setScenarioRange] = useState<{ start: Moment | null; end: Moment | null }>({
    start: null,
    end: null,
  });
  const [timeBarZoomLevel, setTimeBarZoomLevel] = useState<ZoomLevel | null>(null);
  const fetchScenarios = useMemo(
    () => async () => {
      const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`;
      setScenariosLoading(true);
      try {
        const results = await new Request(url).get();
        const allScenariosData = results.data.map((sc: { [key: string]: string }) => ({
          value: sc.id,
          label: sc.name,
          type: sc.scenario_type,
        }));
        setScenarios(allScenariosData);
      } catch (err) {
        setScenarios([]);
      } finally {
        setScenariosLoading(false);
      }
    },
    [workspace, branch],
  );
  useEffect(() => {
    if (scenario && workspace && branch) {
      resetScenario();
    }
    fetchScenarios();
    setSelectedContainer(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch]);
  useEffect(() => {
    if (generatedContingencyId && scenario?.value !== generatedContingencyId) {
      fetchScenarios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedContingencyId]);
  const { data: allContainers, reset, loading: feedersInSceLoading } = useRequestEffect<
    { [key: string]: string }[]
  >({
    url: `/api/workspace/${workspace}/branch/${branch}/asset_schedule`,
    method: 'get',
    dataTransform: (data: containersListHasSchedules) => {
      const dataTransformed =
        data && !isEmptyObject(data) && scenario && containerList
          ? containerList.filter(container => Object.keys(data)?.includes(container?.id))
          : [];
      return dataTransformed;
    },
    params: {
      scenario_id: scenario?.value,
      schedule_type: 'Feeder',
    },
    refetchOnChange: [workspace, branch, scenario],
    blockRequest: () => !(workspace && branch && scenario),
    initialData: [],
  });
  useEffect(() => {
    if (!scenario && allContainers?.length !== 0) {
      reset();
    }
  }, [allContainers, scenario, reset]);
  useEffect(() => {
    const fetchScenarioRange = async () => {
      let timeRange: { start: Moment | null; end: Moment | null } = { start: null, end: null };
      try {
        setTimeSpanLoading(true);
        const timeSpan = await determineScenarioTimeSpan(workspace, branch, scenario?.value);
        if (timeSpan && timeSpan.start && timeSpan.end) {
          const zoomLevel = determineDefaultZoomLevel(
            moment.utc(timeSpan.start),
            moment.utc(timeSpan.end),
            5,
          );
          setTimeBarZoomLevel(zoomLevel);
          const maxEnd = getEndOfInterval(moment.utc(timeSpan.end), zoomLevel);
          timeRange = { start: moment.utc(timeSpan.start), end: maxEnd };
        }
      } catch (err) {}
      setScenarioRange(timeRange);
      setTimeSpanLoading(false);
    };
    if (workspace && branch && scenario) {
      fetchScenarioRange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace, branch, scenario]);
  return (
    <ScenarioGenerationContext.Provider
      value={{
        selectedScenario: scenario,
        setSelectedScenario,
        selectedContainer,
        setSelectedContainer,
        feedersInScenario: allContainers,
        feedersInSceLoading,
        view,
        setView,
        scenarios,
        scenariosLoading,
        resetScenario,
        modalActiveType,
        setModalActiveType,
        resetModalType,
        scenarioRange,
        setGeneratedContingencyId,
        generatedContingencyId,
        timeBarZoomLevel,
        timeSpanLoading,
      }}
    >
      {children}
    </ScenarioGenerationContext.Provider>
  );
}
