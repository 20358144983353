import React, { FunctionComponent, useState, Fragment } from 'react';
import { ThemeProp } from 'types/index';
import CircleCheckMarkIcon from 'components/Icons/CircleCheckMarkIcon';
import CircleCrossIcon from 'components/Icons/CircleCrossIcon';
import LoadingSkeleton from 'components/LoadingSkeleton';
import Button from 'components/Button';
import { Request } from '@opusonesolutions/gridos-app-framework';
import Tooltip from 'components/Tooltip';
import { assetTypes } from '../helpers/ScenarioGenerationHelpers';

type AssetRowProps = {
  workspace: string;
  branch: string;
  theme: ThemeProp;
  scenario?: { [key: string]: string } | null;
  selectedContainer?: string | null;
  asset: { [key: string]: string };
  bulkScheduleGenLoading: boolean;
  isAnalysisInProgress: boolean;
  refetchSchedule: () => void;
  refetchGlobalSchedule: () => void;
  assetsArrHaveGlobalSchedule: string[];
  assetsArrHaveSchedule: string[];
  selectedAssetType: assetTypes;
  scheduleTypeGlobal?: boolean;
  scheduleTypeNormal?: boolean;
};

const AssetRow: FunctionComponent<AssetRowProps> = ({
  theme,
  workspace,
  branch,
  selectedContainer,
  scenario,
  asset,
  bulkScheduleGenLoading,
  isAnalysisInProgress,
  refetchSchedule,
  assetsArrHaveSchedule,
  assetsArrHaveGlobalSchedule,
  refetchGlobalSchedule,
  selectedAssetType,
  scheduleTypeGlobal,
  scheduleTypeNormal,
}) => {
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const generateAssetSchedule = async (assetID: string) => {
    try {
      setScheduleLoading(true);
      const pvScheduleRequest = new Request(
        `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${assetID}/generate_schedule`,
      );
      await pvScheduleRequest.post(undefined, {
        params: {
          feeder: selectedContainer,
          scenario_id: scenario?.value,
          asset_type: selectedAssetType === 'ev' ? 'electric_vehicle' : 'inverterpv',
          schedule_type: 'Normal',
          shift_tz: true,
        },
      });
    } catch (error) {}
    setScheduleLoading(false);
    refetchSchedule();
    refetchGlobalSchedule();
  };

  const loadingPVorSwitch = scheduleLoading || bulkScheduleGenLoading;

  const renderGenerateNowButton = (id: string, disabled = false) => (
    <Tooltip
      theme={theme}
      content={isAnalysisInProgress ? 'Analysis for selected scenario is in progress' : ''}
    >
      <Button
        type="text"
        onClick={() => generateAssetSchedule(id)}
        className="asset-schedule-link"
        disabled={isAnalysisInProgress || disabled}
      >
        Generate now
      </Button>
    </Tooltip>
  );

  const evScheduleStatus = () => {
    let getScheduleStatus: any;
    if (scheduleTypeGlobal) {
      getScheduleStatus = <i className="material-icons rotate">refresh</i>;
    } else if (
      assetsArrHaveGlobalSchedule.length === 0 ||
      !assetsArrHaveGlobalSchedule.includes(asset.id)
    ) {
      getScheduleStatus = <CircleCrossIcon />;
    } else {
      getScheduleStatus = <CircleCheckMarkIcon />;
    }
    return getScheduleStatus;
  };

  const pqScheduleStatus = () => {
    let getScheduleStatus: any;
    if (scheduleLoading || (bulkScheduleGenLoading && scheduleTypeNormal)) {
      getScheduleStatus = (
        <>
          <p>
            <i className="material-icons rotate">refresh</i>
          </p>
          <LoadingSkeleton theme={theme} />
        </>
      );
    } else if (!scheduleLoading && assetsArrHaveSchedule.includes(asset.id)) {
      getScheduleStatus = (
        <>
          <p>
            <CircleCheckMarkIcon />
          </p>
          {renderGenerateNowButton(asset.id, !assetsArrHaveGlobalSchedule.includes(asset.id))}
        </>
      );
    } else {
      getScheduleStatus = (
        <>
          <p>
            <CircleCrossIcon />
          </p>
          {renderGenerateNowButton(asset.id, !assetsArrHaveGlobalSchedule.includes(asset.id))}
        </>
      );
    }
    return getScheduleStatus;
  };

  const renderEachRow = () => {
    if (selectedAssetType === 'ev') {
      return (
        <div className="ev-asset" key={asset.id}>
          <div className="grid-columns one-six asset-row">
            <p className="asset-name">{asset.name}</p>
            <div className="grid-columns auto">
              {/* EV schedule and generate EV schedule */}
              <p>{evScheduleStatus()}</p>
              {/* PQ schedule and generate PQ schedule */}
              {pqScheduleStatus()}
            </div>
          </div>
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else {
      return (
        <div className="pv-asset" key={asset.id}>
          <div className="grid-columns one-two asset-row">
            <p className="asset-name">{asset.name}</p>
            <div className="grid-columns auto">
              {loadingPVorSwitch &&
                (selectedAssetType === assetTypes.pv ||
                  selectedAssetType === assetTypes.switch) && (
                  <>
                    <p>
                      <i className="material-icons rotate">refresh</i>
                    </p>
                    <LoadingSkeleton theme={theme} />
                  </>
                )}
              {/* Event schedule and generate event schedule for pv and switch */}
              {!loadingPVorSwitch &&
                (selectedAssetType === assetTypes.pv || selectedAssetType === assetTypes.switch) &&
                (assetsArrHaveSchedule.includes(asset.id) ? (
                  <>
                    <p>
                      <CircleCheckMarkIcon />
                    </p>
                    <p>Generated</p>
                  </>
                ) : (
                  <>
                    <p>
                      <CircleCrossIcon />
                    </p>
                    {selectedAssetType === assetTypes.pv ? (
                      renderGenerateNowButton(asset.id)
                    ) : (
                      <p> Not generated</p>
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
      );
    }
  };

  return renderEachRow();
};
export default AssetRow;
