import moment from 'moment';

export enum views {
  scenario = 'Scenario',
  assetSchedule = 'Asset Schedule',
  seasonalRatings = 'Seasonal ratings',
}
export enum assetTypes {
  pv = 'pv',
  ev = 'ev',
  switch = 'switch',
}
export enum modalTypes {
  bulkSchedule = 'bulkSchedule',
  externalSource = 'externalSource',
  pqSchedule = 'pqSchedule',
}
export const assetProperties = {
  [assetTypes.pv]: {
    class: 'inverterpv',
    label: 'PV Solar panels',
  },
  [assetTypes.ev]: {
    class: 'electric_vehicle',
    label: 'EV charging stations',
  },
  [assetTypes.switch]: {
    class: 'switch',
    label: 'Switch',
  },
};

export type assetTypesStataus = {
  [assetTypes.pv]: boolean;
  [assetTypes.ev]: boolean;
  [assetTypes.switch]: boolean;
};
export type bulkRatingsModalConfig = {
  active: boolean;
  months?: string[];
  newVal?: number | string;
};
export type monthRangesModalConfig = {
  active: boolean;
  months?: string[];
  setRangesAfterUpdate?: () => void;
};
export type ExternalScenarioDataType = {
  external_scenario: string;
  priority: number;
  scenario_id: string;
  schedule_source_id: number;
}[];
export const defaultSettings = {
  start_time: '00:00',
  end_time: '23:59',
  min_events: 0,
  max_events: 100,
  min_charge_length: '00:15',
  max_charge_length: '23:00',
};

export const defaultSwitchContSettings = {
  contingency_start: moment.utc().startOf('day').toISOString(),
  contingency_end: moment.utc().endOf('day').startOf('hour').toISOString(),
  name: '',
  scenario_start: moment.utc().startOf('day').toISOString(),
  scenario_end: moment.utc().endOf('day').startOf('hour').toISOString(),
  notes: '',
};

const helpers = { views };
export default helpers;
