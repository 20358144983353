import { gramPerkWh, scaleFactorForUnit } from 'helpers/units';

export const defaultAssetModel = Object.freeze({
  estimatedUnitCost: 0,
  estimatedUnitDisposalCost: 0,
  estimatedUnitInstallationCost: 0,
  estimatedUnitRemovalCost: 0,
  estimatedUnitUpgradeCost: 0,
  estimatedUnitInstallationDays: '',
  communityGenerationCredit: 0,
  fixedMaintenanceCost: 0,
  perUnitMaintenanceCost: 0,
  EmissionRate: { emissionRate: '' },
  AssetFailureInfo: {
    mTTR: null,
    probabilityOfFailureEquation: null,
  },
});

/**
 * Gets the CostEditor properties from the JSCIM asset model
 * @param {*} assetModel the JSCIM asset model to read from
 */
export const getAssetModelProperties = assetModel => ({
  estimatedUnitCost: assetModel?.estimatedUnitCost ?? 0,
  estimatedUnitDisposalCost: assetModel?.estimatedUnitDisposalCost ?? 0,
  estimatedUnitInstallationCost: assetModel?.estimatedUnitInstallationCost ?? 0,
  estimatedUnitRemovalCost: assetModel?.estimatedUnitRemovalCost ?? 0,
  estimatedUnitUpgradeCost: assetModel?.estimatedUnitUpgradeCost ?? 0,
  estimatedUnitInstallationDays: assetModel?.estimatedUnitInstallationDays ?? '',
  communityGenerationCredit: assetModel?.communityGenerationCredit ?? 0,
  fixedMaintenanceCost: assetModel?.fixedMaintenanceCost ?? 0,
  perUnitMaintenanceCost: assetModel?.perUnitMaintenanceCost ?? 0,
  EmissionRate: {
    emissionRate: assetModel?.EmissionRate?.emissionRate
      ? assetModel?.EmissionRate?.emissionRate / scaleFactorForUnit(gramPerkWh)
      : '',
  },
  AssetFailureInfo: {
    mTTR: assetModel?.AssetFailureInfo?.mTTR ?? null,
    probabilityOfFailureEquation:
      assetModel?.AssetFailureInfo?.probabilityOfFailureEquation ?? null,
  },
});

export const areAssetModelPropertiesChanged = (assetModel1, assetModel2) => {
  let costsEdited = false;
  const editedPropsDict = {};
  Object.keys(assetModel1)
    .filter(
      key =>
        key !== 'estimatedUnitInstallationDays' &&
        key !== 'EmissionRate' &&
        key !== 'AssetFailureInfo',
    )
    .forEach(prop => {
      // cim stringifies money values but the input fields are numbers so convert to numbers
      editedPropsDict[prop] = !(
        Number(assetModel1?.[prop] ?? 0) === Number(assetModel2?.[prop] ?? 0)
      );
      costsEdited = costsEdited || editedPropsDict[prop];
    });
  editedPropsDict.estimatedUnitInstallationDays =
    (assetModel1?.estimatedUnitInstallationDays ?? '') !==
    (assetModel2?.estimatedUnitInstallationDays ?? '');
  editedPropsDict.EmissionRate = {
    emission:
      (assetModel1?.EmissionRate?.emissionRate ?? '') !==
      (assetModel2?.EmissionRate?.emissionRate ?? ''),
  };
  editedPropsDict.AssetFailureInfo = {
    mTTR:
      (assetModel1?.AssetFailureInfo?.mTTR ?? null) !==
      (assetModel2?.AssetFailureInfo?.mTTR ?? null),
    probabilityOfFailureEquation:
      (assetModel1?.AssetFailureInfo?.probabilityOfFailureEquation ?? null) !==
      (assetModel2?.AssetFailureInfo?.probabilityOfFailureEquation ?? null),
  };
  const anyPropsChanged =
    costsEdited ||
    editedPropsDict.estimatedUnitInstallationDays ||
    editedPropsDict.EmissionRate.emission ||
    editedPropsDict.AssetFailureInfo.mTTR ||
    editedPropsDict.AssetFailureInfo.probabilityOfFailureEquation;
  return [anyPropsChanged, editedPropsDict];
};

export const areAssetModelsEqual = (assetModel1, assetModel2) => {
  const [areEqual] = areAssetModelPropertiesChanged(assetModel1, assetModel2);
  return !areEqual;
};

export const emergencyRatingsUpdated = (
  existingRatings = {},
  updatedRatings,
  supportedEMFields,
) => {
  const emLTCurrent = existingRatings.longTermEmergency?.['CurrentLimit.value'] ?? null;
  const emSTCurrent = existingRatings.shortTermEmergency?.['CurrentLimit.value'] ?? null;
  const existEmLTApparentPower =
    existingRatings.longTermEmergency?.['ApparentPowerLimit.value'] ?? null;
  const existEmSTApparentPower =
    existingRatings.shortTermEmergency?.['ApparentPowerLimit.value'] ?? null;
  const { currentLimit: emLTRatedCurrent } = updatedRatings.longTermEmergency;
  const { currentLimit: emSTRatedCurrent } = updatedRatings.shortTermEmergency;
  const { apparentPowerLimit: emLTApparentPower } = updatedRatings.longTermEmergency;
  const { apparentPowerLimit: emSTApparentPower } = updatedRatings.shortTermEmergency;
  return (
    (supportedEMFields.includes('emCurrent') &&
      (emLTCurrent !== emLTRatedCurrent || emSTCurrent !== emSTRatedCurrent)) ||
    (supportedEMFields.includes('emApparentPower') &&
      (existEmLTApparentPower !== emLTApparentPower ||
        existEmSTApparentPower !== emSTApparentPower))
  );
};

export const isAssetModelValid = assetModel => {
  const costsValid = Object.keys(assetModel)
    .filter(key => key !== 'estimatedUnitInstallationDays')
    .filter(key => key !== 'EmissionRate')
    .filter(key => key !== 'AssetFailureInfo')
    .every(prop => !Number.isNaN(parseFloat(assetModel[prop])));
  const daysValid =
    !Number.isNaN(parseInt(assetModel.estimatedUnitInstallationDays, 10)) ||
    assetModel.estimatedUnitInstallationDays?.length === 0;
  let restorationTimeValid = true;
  if (assetModel.AssetFailureInfo) {
    restorationTimeValid =
      !Number.isNaN(parseFloat(assetModel.AssetFailureInfo.mTTR)) ||
      assetModel.AssetFailureInfo.mTTR === null;
  }
  return costsValid && daysValid && restorationTimeValid;
};

export const defaultEmergencyRatings = includeStates => {
  let defaultEmState = {};
  if (includeStates.includes('emCurrent')) {
    defaultEmState = {
      longTermEmergency: { currentLimit: null },
      shortTermEmergency: { currentLimit: null },
    };
  }
  if (includeStates.includes('emApparentPower')) {
    defaultEmState = {
      longTermEmergency: { apparentPowerLimit: null },
      shortTermEmergency: { apparentPowerLimit: null },
    };
  }
  return defaultEmState;
};

export const isValidEmergencyRatings = ratingsValues => {
  const isValid = Object.values(ratingsValues).every(value => {
    const limitVal = Object.values(value)[0];
    return limitVal === null || (limitVal && limitVal > 0);
  });
  return isValid;
};

export const updateEmRatings = ({ id, value }, limitField, prevState, multiplier = 1) => ({
  emergencyRatings: {
    ...prevState.emergencyRatings,
    [id]: { [limitField]: value === '' ? null : value * multiplier },
  },
});
