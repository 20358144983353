import React, { FunctionComponent, ReactChild, ReactNode } from 'react';
import { ThemeProp } from 'types/index';
import asyncActionStates from 'helpers/asyncActionStates';
import IconButton from 'components/IconButton';
import Modal from '../Modal';

const { LOADING } = asyncActionStates;
type ConfirmModalProps = {
  modalActive: boolean;
  closeModal: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  deleteStatus?: number;
  deleteItem: (event?: React.MouseEvent<HTMLElement, MouseEvent> | null) => void | null;
  title: string | null;
  modalBody?: ReactChild | ReactNode;
  theme?: ThemeProp;
  confirmLabel?: string;
};

const ConfirmModal: FunctionComponent<ConfirmModalProps> = ({
  modalActive = false,
  closeModal = null,
  deleteStatus = asyncActionStates.INITIAL,
  deleteItem = null,
  title = null,
  modalBody = null,
  theme,
  confirmLabel = 'Remove',
}) => {
  const isLoading = deleteStatus === LOADING;
  return (
    <>
      <Modal
        active={modalActive}
        title={`${title || 'Remove Item'}`}
        width="490px"
        onCancel={closeModal}
        onConfirm={deleteItem}
        labels={{
          confirm: isLoading ? <i className="material-icons rotate">refresh</i> : confirmLabel,
        }}
        theme={theme}
        disableConfirm={isLoading || !deleteItem}
        disableCancel={isLoading}
        className="delete-modal"
      >
        <IconButton
          icon="close"
          onClick={closeModal as any}
          tooltip="Close"
          theme={theme}
          id="close-modal-btn"
        />
        {modalBody}
      </Modal>
    </>
  );
};

export default ConfirmModal;
