import React, { FunctionComponent, useContext, useState } from 'react';
import Modal from 'components/Modal';
import ThemeContext from 'helpers/ThemeContext';
import RadioButtonGroup from 'components/RadioButtonGroup';
import { monthRangesModalConfig } from '../helpers/ScenarioGenerationHelpers';

type RatingsByMonthRangesModalProps = {
  modalActiveConfig: monthRangesModalConfig;
  savingSeasonalRatings: boolean;
  combineMonthValue: (arg: string | null, months?: string[]) => void;
  onCancel: () => void;
  onConfirm: () => void;
};

const RatingsByMonthRangesModal: FunctionComponent<RatingsByMonthRangesModalProps> = ({
  modalActiveConfig,
  savingSeasonalRatings,
  combineMonthValue,
  onCancel,
  onConfirm,
}) => {
  const theme = useContext(ThemeContext);
  const [selMonthToCombine, setSelMonthToCombine] = useState<string | null>(null);
  const monthToDisplay = `${modalActiveConfig.months?.[0]}-${
    modalActiveConfig.months?.[modalActiveConfig.months.length - 1]
  }`;
  return (
    <Modal
      active={modalActiveConfig.active}
      title={`Combining ratings for ${monthToDisplay} ?`}
      onConfirm={onConfirm}
      onCancel={onCancel}
      theme={theme}
      modalType="primary"
      labels={{
        confirm: savingSeasonalRatings ? (
          <i className="material-icons rotate">refresh</i>
        ) : (
          'Confirm'
        ),
        cancel: 'Discard',
      }}
      disableConfirm={!selMonthToCombine}
    >
      <div className="ratings-modal-body">
        <p className="modal-message__p pv-asset-msg">
          Choose which rating to apply for the combined value?
        </p>
        <div className="margin-10">
          <RadioButtonGroup
            id="delete-link-confirmation-option"
            options={[
              { id: 'default', label: 'Default 100 %' },
              ...(modalActiveConfig.months?.map(month => ({
                id: month,
                label: month.toLowerCase(),
              })) ?? []),
            ]}
            theme={theme}
            value={selMonthToCombine ?? ''}
            onChange={({ target }: any) => {
              setSelMonthToCombine(target.value);
              combineMonthValue(target.value, modalActiveConfig.months);
            }}
            radioType="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default RatingsByMonthRangesModal;
