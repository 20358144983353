import React, { useState, FunctionComponent } from 'react';
import classNames from 'classnames';
import { useLocation, useParams } from 'react-router-dom';

import Icons from 'components/Icons';

import useQueryState from 'hooks/useQueryState';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';

import { CimInstanceLookup, CimJSONDocument, CimSchema } from '../cim';

import { buildInstanceLookup, getAttributeStatus } from '../helpers/CIMHelpers';
import CIMClassAttributes from './CIMClassAttributes';
import CIMClassReferences from './CIMClassReferences';
import InstancesView from './InstancesView';
import LeafNodeView from './LeafNodeView';
import NetworkTopNav from '../../../containers/NetworkTopNavContainer';
import './CimExplorer.scss';

const { ShowEmptyAttributeIcon } = Icons;

const CimExplorer: FunctionComponent = () => {
  const { search } = useLocation();
  const { branch, workspace } = useParams<{ workspace: string; branch: string }>();
  const params = new URLSearchParams(search);

  const [expandedInstances, setExpandedInstances] = useState<{ [key: string]: boolean }>({});
  const [hoverID, setHoverID] = useState<string | null>(null);
  const [instanceLookup, setInstanceLookup] = useState<CimInstanceLookup | null>(null);
  const [selectedClass, setSelectedClass] = useQueryState<string | null>(
    params.get('class'),
    'class',
  );
  const [selectedID, setSelectedID] = useQueryState<string | null>(params.get('id'), 'id');
  const [showEmpty, setShowEmpty] = useState(false);

  const { data: instances, loading: loadingCIM } = useRequestEffect<CimJSONDocument>({
    url: `/api/workspace/${workspace}/branch/${branch}/json`,
    method: 'get',
    dataTransform: data => {
      Object.keys(data.objects).forEach(rdfID => {
        data.objects[rdfID].attributes['IdentifiedObject.mRID'] = rdfID;
      });
      return data;
    },
    onSuccess: data => {
      if (data) {
        setInstanceLookup(buildInstanceLookup(data.objects));

        // We might have initial state from query params. Need to validate it
        if (selectedID && data.objects[selectedID]) {
          // Make sure the class is right
          setSelectedClass(data.objects[selectedID].class);
        } else {
          setSelectedID(null);
        }
      }
    },
  });
  const { data: schema, loading: loadingSchema } = useRequestEffect<CimSchema>({
    url: `/api/workspace/${workspace}/schema`,
    method: 'get',
  });

  const changeSelectedClass = (name: string) => {
    setSelectedID(null);
    setSelectedClass(name);
    setExpandedInstances({});
  };

  const setSelectedInstance = (id: string | null) => {
    if (id) {
      const instanceData = instances?.objects[id];

      if (instanceData) {
        setSelectedClass(instanceData.class);
        setSelectedID(id);
        setExpandedInstances({ [id]: true });
      }
    } else {
      setSelectedID(null);
    }
  };

  const attributeStatus =
    instances && selectedID ? getAttributeStatus(instances.objects[selectedID]) : 'inactive';
  const loading = loadingCIM || loadingSchema;
  const hoverInstance = hoverID ? instances?.objects[hoverID] : undefined;
  const selectedInstance = selectedID ? instances?.objects[selectedID] : undefined;

  return (
    <>
      <NetworkTopNav params={useParams()} />
      <div className="cim-explorer">
        <div className="cim-explorer-container">
          <div className="cim-explorer-contents">
            <div className="cim-explorer-content">
              <div className="cim-explorer-content-main">
                <div className="cim-explorer-column">
                  <div className="cim-explorer-column-header cim-explorer-column-header--active">
                    <h2 className="cim-explorer-column-header__h2">Types</h2>
                  </div>
                  {instanceLookup && (
                    <LeafNodeView
                      instanceLookup={instanceLookup}
                      selectedClass={selectedClass}
                      setSelectedClass={changeSelectedClass}
                    />
                  )}
                  {loading && (
                    <div className="cim-explorer-column-content loading-placeholder">
                      <div className="loading-card">
                        <i className="material-icons rotate" style={{ fontSize: 40 }}>
                          refresh
                        </i>
                        <p>Fetching Data...</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="cim-explorer-column instances-column">
                  <InstancesView
                    expandedInstances={expandedInstances}
                    instanceTree={instanceLookup}
                    selectedClass={selectedClass}
                    selectedID={selectedID}
                    selectedInstance={selectedInstance}
                    setSelectedInstance={setSelectedInstance}
                    setHoveredInstance={setHoverID}
                  />
                </div>
                <div className="cim-explorer-column attributes-column">
                  <div className="cim-types-container">
                    <div
                      className={`cim-explorer-column-header cim-explorer-column-header--${attributeStatus}`}
                    >
                      <h2 className="cim-explorer-column-header__h2">Attributes</h2>
                      <div className="cim-attributes-controls">
                        <button
                          className={classNames({
                            'cim-attributes-controls__button': true,
                            'cim-attributes-controls__button--show-empty': showEmpty,
                          })}
                          onClick={() => setShowEmpty(!showEmpty)}
                          title={`${showEmpty ? 'Hide' : 'Show'} Empty Attributes`}
                          type="button"
                        >
                          <ShowEmptyAttributeIcon height={22} width={22} color="white" />
                        </button>
                      </div>
                    </div>
                    <div className="gray-scrollbars cim-explorer-column-content cim-explorer-indented-list cim-attributes-container">
                      <CIMClassAttributes
                        selectedInstance={selectedInstance || hoverInstance}
                        schema={schema}
                        showEmpty={showEmpty}
                      />
                    </div>
                    <CIMClassReferences
                      instance={selectedInstance || hoverInstance}
                      columnStatus={attributeStatus}
                      cimObjectMap={instances ? instances.objects : {}}
                      followReferenceLink={setSelectedInstance}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CimExplorer;
