import React from 'react';
import { func } from 'prop-types';
import Button from 'components/Button';

export default function AssetPanelError({ retry }) {
  return (
    <div
      className="asset-loading"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: '50px',
        height: '85px',
      }}
    >
      An unexpected error occurred!
      {retry && <Button onClick={retry}>Try Again</Button>}
    </div>
  );
}

AssetPanelError.propTypes = { retry: func };
AssetPanelError.defaultProps = { retry: undefined };
