import React, { FunctionComponent } from 'react';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Select from 'components/Select';
import NumberInput from 'components/NumberInput';
import { SimulationOptions } from 'types/analysis';
import { optionType } from 'types/edit';
import NodePhases from './NodePhases';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';

type NodeSelectorProps = {
  selectedAnalysisType: string;
  nodeOptions: optionType[];
  simulationOptions: SimulationOptions;
  setSimulationOptions: React.Dispatch<React.SetStateAction<SimulationOptions>>;
  singleOrAll: string;
  setSingleOrAll: React.Dispatch<React.SetStateAction<string>>;
  phase: string;
  setPhase: React.Dispatch<React.SetStateAction<string>>;
  selectedNodes: optionType[];
  setSelectedNodes: React.Dispatch<React.SetStateAction<optionType[]>>;
};

const NodeSelector: FunctionComponent<NodeSelectorProps> = ({
  selectedAnalysisType,
  nodeOptions,
  simulationOptions,
  setSimulationOptions,
  singleOrAll,
  setSingleOrAll,
  phase,
  setPhase,
  selectedNodes,
  setSelectedNodes,
}) => {
  const { permissions } = useAuthContext();

  const { HOSTING_CAPACITY, EV_CAPACITY, BATTERY_SIZING } = ANALYSIS_TYPES;
  const canRunMultinode =
    permissions.has('run_multinode_battery_sizing') ||
    permissions.has('run_multinode_battery_sizing_day');
  const disableAllNodeOption = () => {
    let disableOption = false;
    switch (selectedAnalysisType) {
      case HOSTING_CAPACITY:
      case EV_CAPACITY:
        disableOption = !(
          permissions &&
          (permissions.has('run_network_hosting_capacity') ||
            permissions.has('run_network_hosting_capacity_day'))
        );
        break;
      case BATTERY_SIZING:
        disableOption = !canRunMultinode;
        break;
      default:
        disableOption = false;
    }
    return disableOption;
  };

  const isBatterySizeAnalysis = selectedAnalysisType === BATTERY_SIZING;
  const handleNodeTypeChange = (e: any) => {
    const { value } = e.target;
    setSingleOrAll(value);
    if (!isBatterySizeAnalysis) setPhase('');
  };
  return (
    <div className="node-selector menu-body">
      <div className="menu-row">
        <p className="label">Nodes</p>
        <RadioButtonGroup
          id="analysis-type"
          options={[
            { id: 'single', label: 'Single' },
            { id: 'all', label: 'All', disabled: disableAllNodeOption() },
          ]}
          listType="column"
          value={singleOrAll}
          onChange={handleNodeTypeChange}
        />
        {permissions.has('modify_nodes_per_job') &&
          singleOrAll === 'all' &&
          !isBatterySizeAnalysis && (
            <NumberInput
              htmlFor="Number of Nodes per Job"
              id="nodes_per_HC_job"
              value={
                simulationOptions.nodes_per_HC_job === undefined
                  ? 2
                  : simulationOptions.nodes_per_HC_job
              }
              onChange={({ value }) => {
                const val = Math.floor(value);
                setSimulationOptions({
                  ...simulationOptions,
                  nodes_per_HC_job: val,
                });
              }}
              label="Number of Nodes per Job"
              ge={1}
            />
          )}
      </div>
      {singleOrAll === 'single' && (
        <>
          <div className="menu-row selected-node-row">
            <p className="label">Selected Node</p>
            <Select
              options={nodeOptions}
              value={
                isBatterySizeAnalysis && canRunMultinode
                  ? selectedNodes
                  : (selectedNodes[0] as optionType)?.value
              }
              clearable
              searchable
              onChange={newNodes => {
                if (isBatterySizeAnalysis && canRunMultinode && newNodes) {
                  setSelectedNodes(newNodes);
                } else if (newNodes) {
                  setSelectedNodes([newNodes]);
                } else {
                  setSelectedNodes([]);
                }
                if (!isBatterySizeAnalysis) setPhase('');
              }}
              isMulti={isBatterySizeAnalysis && canRunMultinode}
            />
          </div>
          {!isBatterySizeAnalysis && selectedNodes[0]?.value && (
            <NodePhases node_id={selectedNodes[0]?.value} phase={phase} setPhase={setPhase} />
          )}
        </>
      )}
    </div>
  );
};

export default NodeSelector;
