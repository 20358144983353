import React, { FunctionComponent, useContext, useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import ThemeContext from 'helpers/ThemeContext';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import { ANALYSIS_TYPES, getAllBatterySizingResults } from '../../../helpers/NetworkHelpers';
import ActivityModal from '../ActivityLogPanel/ActivityModal';
import './ReportList.scss';

type ReportListProps = {
  workspace: string;
  branch: string;
  containers: { id: string }[];
  selectedScenario: string;
  inEditMode: boolean;
  selectedAnalysis: {
    id: string;
    type: string;
    name: string;
  };
  subHourInterval: number;
  downloadPowerFlowReport: () => Promise<void>;
  generatePowerflowReport: () => Promise<void>;
  downloadHostingCapacityReport: () => Promise<void>;
  downloadCostTrackingReport: () => Promise<void>;
  anyResults: boolean;
  analysisRunning: boolean;
  containerInAnalysis: boolean;
  timeRange: {
    start: moment.Moment;
    end: moment.Moment;
  };
};

const getResultsTooltip = (
  hasRes: boolean,
  jobActive: boolean,
  jobType: string,
  validRange?: boolean,
) => {
  if (jobActive) {
    return `${jobType} results are not available while the selected analysis is running`;
  }
  if (hasRes && !validRange && jobType === 'Hosting capacity') {
    return `Specify a time range less than 1 week to download the ${jobType} report`;
  }
  if (hasRes) {
    return '';
  }

  return 'No results available';
};

const ReportList: FunctionComponent<ReportListProps> = ({
  workspace,
  branch,
  containers,
  selectedScenario,
  inEditMode,
  selectedAnalysis,
  subHourInterval,
  downloadPowerFlowReport,
  generatePowerflowReport,
  downloadHostingCapacityReport,
  downloadCostTrackingReport,
  anyResults,
  analysisRunning,
  containerInAnalysis,
  timeRange,
}) => {
  const theme = useContext(ThemeContext);
  const [downloadingPFReport, setDownloadingPFReport] = useState(false);
  const [downloadingHCReport, setDownloadingHCReport] = useState(false);
  const [downloadingCTReport, setDownloadingCTReport] = useState(false);
  const [downloadingEVCapacityReport, setDownloadingEVCapacityReport] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [batteryResults, setBatteryResults] = useState(null);
  const [batteryModalActive, setBatteryModalActive] = useState(false);

  useEffect(() => {
    async function fetchBatteryAnalysisResults() {
      setResultsLoading(true);
      try {
        const rawBatteryResults = await getAllBatterySizingResults(
          workspace,
          branch,
          containers.map(x => x.id),
          selectedScenario,
          selectedAnalysis.name,
        );
        setBatteryResults(rawBatteryResults);
        setResultsLoading(false);
      } catch (err) {
        setResultsLoading(false);
      }
    }

    if (selectedAnalysis && selectedAnalysis.type === ANALYSIS_TYPES.BATTERY_SIZING) {
      fetchBatteryAnalysisResults();
    }
  }, [workspace, branch, containers, selectedScenario, selectedAnalysis]);

  const isValidCapacityReportTimeRange = () => {
    // disable EV Capacity or HC reports > 2 weeks selected
    const { start, end } = timeRange;
    if (start && end) {
      return start.clone().add(1, 'weeks') >= end;
    }

    return false;
  };

  return (
    <div className="reports-section">
      <h3 className="reports-title">Reports</h3>
      <div className="reports-container">
        {!inEditMode && (
          <>
            {/* row 1 */}
            <div className="row pl pr mb">
              <div className="column">
                <div className="row">
                  <div className="report-title">
                    <Button
                      id="powerflow-btn"
                      disabled={
                        !selectedAnalysis ||
                        !anyResults ||
                        analysisRunning ||
                        downloadingPFReport ||
                        !containerInAnalysis ||
                        ![
                          ANALYSIS_TYPES.POWERFLOW,
                          ANALYSIS_TYPES.QSTS,
                          ANALYSIS_TYPES.QSTS_OPF,
                        ].includes(selectedAnalysis.type)
                      }
                      onClick={async () => {
                        setDownloadingPFReport(true);
                        await downloadPowerFlowReport();
                        setDownloadingPFReport(false);
                      }}
                      loading={downloadingPFReport}
                      className="report-download-btn"
                      title={getResultsTooltip(anyResults, analysisRunning, 'Powerflow')}
                      theme={theme}
                      type="text"
                    >
                      <div className="row">
                        <div className="icon-holder">
                          <ReactSVG className="download" src="/download_report.svg" />
                        </div>
                        <div className="button-title"> Powerflow report</div>
                      </div>
                    </Button>
                  </div>
                  <div className="icon-holder">
                    <Tooltip
                      content="Downloads a powerflow report for the selected time range. For long time ranges, results will be aggregated."
                      placement="right"
                    >
                      <ReactSVG className="info" src="/info.svg" />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="row  ml">
                  <div className="report-title">
                    <Button
                      onClick={async () => {
                        setDownloadingHCReport(true);
                        await downloadHostingCapacityReport();
                        setDownloadingHCReport(false);
                      }}
                      disabled={
                        !selectedAnalysis ||
                        !anyResults ||
                        analysisRunning ||
                        downloadingHCReport ||
                        !containerInAnalysis ||
                        !isValidCapacityReportTimeRange() ||
                        selectedAnalysis.type !== ANALYSIS_TYPES.HOSTING_CAPACITY
                      }
                      loading={downloadingHCReport}
                      className="report-download-btn"
                      title={getResultsTooltip(
                        anyResults,
                        analysisRunning,
                        'Hosting capacity',
                        isValidCapacityReportTimeRange(),
                      )}
                      theme={theme}
                      type="text"
                      id="hosting-capacity-btn"
                    >
                      <div className="row">
                        <div className="icon-holder">
                          <ReactSVG className="download" src="/download_report.svg" />
                        </div>
                        <div className="button-title">Hosting capacity</div>
                      </div>
                    </Button>
                  </div>
                  <div className="icon-holder">
                    <Tooltip
                      content="Downloads an hour-by-hour report for the selected time range. This report is currently only available for timeranges of 14 days or less."
                      placement="right"
                    >
                      <ReactSVG className="info" src="/info.svg" />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {/* row 2 */}
            <div className="row pl pr mb">
              <div className="column">
                <div className="row">
                  <div className="report-title">
                    <Button
                      id="hourly-powerflow-btn"
                      disabled={
                        !selectedAnalysis ||
                        subHourInterval !== 60 ||
                        !anyResults ||
                        analysisRunning ||
                        downloadingPFReport ||
                        !containerInAnalysis ||
                        ![ANALYSIS_TYPES.QSTS, ANALYSIS_TYPES.QSTS_OPF].includes(
                          selectedAnalysis.type,
                        )
                      }
                      onClick={generatePowerflowReport}
                      className="report-download-btn"
                      title={getResultsTooltip(anyResults, analysisRunning, 'Powerflow')}
                      theme={theme}
                      type="text"
                    >
                      <div className="row">
                        <div className="icon-holder">
                          <ReactSVG className="download" src="/plus-add.svg" />
                        </div>
                        <div className="button-title">Generate hourly PF report</div>
                      </div>
                    </Button>
                  </div>
                  <div className="icon-holder">
                    <Tooltip
                      content="Generates an hour-by-hour report for the selected time range. This may take a long time and is currently only supported at hourly intervals"
                      placement="right"
                    >
                      <ReactSVG className="info" src="/info.svg" />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="row  ml">
                  <div className="report-title">
                    <Button
                      onClick={async () => {
                        setDownloadingEVCapacityReport(true);
                        await downloadHostingCapacityReport();
                        setDownloadingEVCapacityReport(false);
                      }}
                      disabled={
                        !selectedAnalysis ||
                        !anyResults ||
                        analysisRunning ||
                        downloadingEVCapacityReport ||
                        !containerInAnalysis ||
                        !isValidCapacityReportTimeRange() ||
                        selectedAnalysis.type !== ANALYSIS_TYPES.EV_CAPACITY
                      }
                      loading={downloadingEVCapacityReport}
                      className="report-download-btn"
                      title={getResultsTooltip(
                        anyResults,
                        analysisRunning,
                        'EV hosting capacity',
                        isValidCapacityReportTimeRange(),
                      )}
                      theme={theme}
                      type="text"
                      id="ev-hosting-capacity-btn"
                    >
                      <div className="row">
                        <div className="icon-holder">
                          <ReactSVG className="download" src="/download_report.svg" />
                        </div>
                        <div className="button-title">EV hosting capacity</div>
                      </div>
                    </Button>
                  </div>
                  <div className="icon-holder">
                    <Tooltip
                      content="Downloads an hour-by-hour report for the selected time range. This report is currently only available for timeranges of 14 days or less."
                      placement="right"
                    >
                      <ReactSVG className="info" src="/info.svg" />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* row 3 */}
        <div className="row pl pr mb">
          <div className="column">
            <div className="row">
              <div className="report-title">
                <Button
                  onClick={async () => {
                    setDownloadingCTReport(true);
                    await downloadCostTrackingReport();
                    setDownloadingCTReport(false);
                  }}
                  disabled={downloadingCTReport}
                  loading={downloadingCTReport}
                  className="report-download-btn"
                  title={getResultsTooltip(true, false, 'Network change and costing', true)}
                  theme={theme}
                  type="text"
                  id="cost-tracking-btn"
                >
                  <div className="row">
                    <div className="icon-holder">
                      <ReactSVG className="download" src="/download_report.svg" />
                    </div>
                    <div className="button-title">Network change and costing</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="row  ml">
              <div className="report-title">
                <Button
                  id="battery-sizing-btn"
                  onClick={() => {
                    setBatteryModalActive(true);
                  }}
                  disabled={
                    !selectedAnalysis ||
                    !batteryResults ||
                    analysisRunning ||
                    selectedAnalysis.type !== ANALYSIS_TYPES.BATTERY_SIZING
                  }
                  className="report-download-btn"
                  title={getResultsTooltip(
                    !!batteryResults,
                    analysisRunning,
                    'Battery sizing',
                    true,
                  )}
                  theme={theme}
                  type="text"
                >
                  <div className="row">
                    <div className="icon-holder">
                      <ReactSVG className="download" src="/download_report.svg" />
                    </div>
                    <div className="button-title">Battery sizing</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {batteryModalActive && (
        <ActivityModal
          toggleModal={setBatteryModalActive}
          batteryResults={batteryResults}
          theme={theme}
          resultsLoading={resultsLoading}
          workspace={workspace}
          branch={branch}
        />
      )}
    </div>
  );
};

export default ReportList;
