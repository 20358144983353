import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import NumberInput from 'components/NumberInput';
import TextInput from 'components/TextInput';
import Select from 'components/Select';
import { kV, kVll, kVln, kVA } from 'helpers/units';
import { alphabetizeByKey } from 'helpers/utils';
import { calculateSum, transposePhaseAttributes } from '../../helpers/valueExtractors';
import PerPhaseRow from '../partials/PerPhaseRow';
import PerPhaseContainer from '../partials/PerPhaseContainer';
import EquipmentInfoSelector from '../partials/containers/EquipmentInfoSelectorContainer';

const PHASES = ['A', 'B', 'C'];

const MODE_TYPES = [
  { value: 'balanced', label: 'Balanced' },
  { value: 'unbalanced', label: 'Unbalanced' },
];

const infoValues = [
  { id: 'connectionKindPrimary', label: 'Primary Winding', type: 'text' },
  { id: 'connectionKindSecondary', label: 'Secondary Winding', type: 'text' },
  {
    id: 'ratedUPrimary',
    label: 'Rated Voltage Primary',
    unit: kV,
    divisor: 1000,
    type: 'number',
  },
  {
    id: 'ratedUSecondary',
    label: 'Rated Voltage Secondary',
    unit: kV,
    divisor: 1000,
    type: 'number',
  },
  {
    id: 'r',
    label: 'R',
    unit: 'Ω',
    divisor: 1,
    type: 'number',
  },
  {
    id: 'r0',
    label: 'R0',
    unit: 'Ω',
    divisor: 1,
    type: 'number',
  },
  {
    id: 'x',
    label: 'X',
    unit: 'Ω',
    divisor: 1,
    type: 'number',
  },
  {
    id: 'x0',
    label: 'X0',
    unit: 'Ω',
    divisor: 1,
    type: 'number',
  },
];

const summedPerPhase = [
  {
    id: 'ratedSPrimary',
    label: 'Rated Power',
    unit: kVA,
    divisor: 1000,
    type: 'number',
    precision: 0,
  },
  {
    id: 'shortTermEmergencyApparentPowerLimitPrimary',
    label: 'Short Term Emergency Apparent Power Limit',
    unit: kVA,
    divisor: 1000,
    type: 'number',
    precision: 0,
  },
  {
    id: 'longTermEmergencyApparentPowerLimitPrimary',
    label: 'Long Term Emergency Apparent Power Limit',
    unit: kVA,
    divisor: 1000,
    type: 'number',
    precision: 0,
  },
];

const getPerPhaseInfoValues = (perPhaseEndInfo, impedances, phases) => {
  const perPhaseInfoValues = {};
  phases?.forEach(phase => {
    const phaseDefined = !impedances[phase] && impedances[`${phase}N`] ? `${phase}N` : phase;
    perPhaseInfoValues[phase] = { ...impedances[phaseDefined] };
    if (perPhaseEndInfo[phaseDefined]) {
      Object.values(perPhaseEndInfo[phaseDefined]).forEach(endInfo => {
        const endType = endInfo.endNumber === 1 ? 'Primary' : 'Secondary';
        perPhaseInfoValues[phase][`ratedS${endType}`] = endInfo.ratedS;
        perPhaseInfoValues[phase][`shortTermEmergencyApparentPowerLimit${endType}`] =
          endInfo.alternate_limits?.short_term_emergency_apparent_power_limit;
        perPhaseInfoValues[phase][`longTermEmergencyApparentPowerLimit${endType}`] =
          endInfo.alternate_limits?.long_term_emergency_apparent_power_limit;
        perPhaseInfoValues[phase][`ratedU${endType}`] = endInfo.ratedU;
        perPhaseInfoValues[phase][`connectionKind${endType}`] = endInfo.connectionKind;
      });
    }
  });
  return perPhaseInfoValues;
};

const HELP_MESSAGES = {
  phaseCount: '"Balanced" is only supported for 3-phase devices.',
  differentTanks: 'All phases must be identical to switch to balanced.',
};

const getModeDisabledReason = (phases, validTankConfig) => {
  if (!phases.join('').includes('ABC')) return HELP_MESSAGES.phaseCount;
  if (!validTankConfig) return HELP_MESSAGES.differentTanks;
  return 'Cannot change mode with current configuration.';
};

const InstanceInfo = ({
  assetId,
  asset: {
    balanced,
    container,
    phases,
    tank_infos: tankInfos,
    transformer_tanks_info: perPhaseTankInfo,
    transformer_ends_info: perPhaseEndInfo,
    impedances: perPhaseImpedances,
  },
  theme,
  disabled,
  inEditMode,
  workspace,
  displayBranch,
  expanded,
  handleSave,
}) => {
  const infoPhases = balanced ? [phases] : phases.replace('N', '').split('');
  const selectedMode = balanced ? 'balanced' : 'unbalanced';
  const voltageLabel = balanced ? kVll : kVln;
  const handleBalancedChanged = selectedItem =>
    handleSave({
      phases,
      balanced: selectedItem === MODE_TYPES[0],
    });

  // get both a list of all tankInfos as well as a list of unique ids
  const tankInfoList = Object.values(perPhaseTankInfo).filter(x => x !== null);
  const uniqueTankInfoIds = new Set(tankInfoList.map(info => info.id));

  // can only change to unbalanced to balanced if there are 3 phases
  // and they are all configured to be identical
  const hasValidTankConfiguration =
    (tankInfoList.length === 0 || tankInfoList.length === 3) &&
    (uniqueTankInfoIds.size === 0 || uniqueTankInfoIds.size === 1);
  const canChangeToBalanced =
    !balanced && hasValidTankConfiguration && PHASES.filter(x => !phases.includes(x)).length === 0;

  // can always change from balanced to unbalanced
  const canChangeToUnbalanced = balanced;

  const infoValueMap = getPerPhaseInfoValues(perPhaseEndInfo, perPhaseImpedances, infoPhases);

  return (
    <>
      <div className="header-container">
        <div className="panel-header-row mode-dropdown" key="header-row-2">
          <div className="dropdown-no-title">
            <Select
              name="mode"
              value={selectedMode}
              theme={theme}
              disabled={disabled || !inEditMode || !(canChangeToBalanced || canChangeToUnbalanced)}
              placeholder="Unbalanced"
              options={MODE_TYPES}
              onChange={handleBalancedChanged}
              clearable={false}
            />
            <span className="help-placeholder">
              {!(canChangeToBalanced || canChangeToUnbalanced) && (
                <Tooltip
                  className="info-tooltip"
                  placement="left"
                  content={getModeDisabledReason(
                    infoPhases.filter(phase => phase !== 'N'),
                    hasValidTankConfiguration,
                  )}
                >
                  <i className="material-icons help-icon">help_outline</i>
                </Tooltip>
              )}
            </span>
          </div>
        </div>
        {infoPhases
          .filter(val => val !== 'N')
          .map(phase => {
            const info = perPhaseTankInfo[phase] || { id: '', name: '' };
            return (
              <div className="panel-header-row" key={`tankinfo${phase}`}>
                <div className="dropdown">
                  <div className="left-label right-align">{phase}</div>
                  <EquipmentInfoSelector
                    id={`tank-info-${phase}-select`}
                    onChange={newInfo =>
                      handleSave({
                        transformer_tanks_info: { [phase]: newInfo?.value ?? null },
                      })
                    }
                    loadOptions={() =>
                      alphabetizeByKey(
                        tankInfos.map(infoOption => ({
                          label: infoOption.name,
                          value: infoOption.id,
                          disabled: infoOption.id === info.id,
                        })),
                        'label',
                      )
                    }
                    defaultOptions={info.id ? [{ value: info.id, label: info.name }] : []}
                    eqLibURL={`/${workspace}/${displayBranch}/library/transformers`}
                    value={info.id}
                    disabled={disabled || !inEditMode}
                    assetId={assetId}
                    feeder={container.id}
                    expanded={expanded}
                    clearable
                  />
                </div>
              </div>
            );
          })}
      </div>
      <hr className="section-divider" />
      {selectedMode === 'balanced' ? (
        infoValues.concat(summedPerPhase).map(value => {
          if (value.type === 'number') {
            return (
              <NumberInput
                key={value.id}
                value={infoValueMap[phases][value.id]}
                type={value.type}
                inputStyle="panel"
                theme={theme}
                {...{
                  ...value,
                  unit: value.unit === kV ? voltageLabel : value.unit,
                }}
                divisor={value.divisor}
                disabled
                phase={phases}
                precision={3}
                className="single-row-input"
                rowInput
              />
            );
          }
          if (value.type === 'text') {
            return (
              <TextInput
                key={value.id}
                value={infoValueMap[phases][value.id]}
                type={value.type}
                inputStyle="panel"
                theme={theme}
                disabled
                phase={phases}
                className="single-row-input"
                rowInput
                label={value.label}
              />
            );
          }
          return true;
        })
      ) : (
        <>
          <PerPhaseContainer>
            {infoValues.map(value => (
              <PerPhaseRow
                key={value.id}
                type={value.type}
                {...value}
                values={transposePhaseAttributes(infoValueMap)[value.id] ?? {}}
                unit={value.unit === kV ? voltageLabel : value.unit}
                disabled
                phases={phases}
                precision={3}
              />
            ))}
          </PerPhaseContainer>
          <PerPhaseContainer showTotal>
            {summedPerPhase.map(value => (
              <PerPhaseRow
                key={value.id}
                {...value}
                values={transposePhaseAttributes(infoValueMap)[value.id] ?? {}}
                getTotal={calculateSum}
                disabled
                phases={phases}
                precision={3}
              />
            ))}
          </PerPhaseContainer>
        </>
      )}
    </>
  );
};

InstanceInfo.propTypes = {
  assetId: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  workspace: PropTypes.string.isRequired,
  displayBranch: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default InstanceInfo;
