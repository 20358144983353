import React, { FunctionComponent, ReactNode, useCallback, ReactChild } from 'react';
import { ThemeProp } from 'types/index';
import classNames from 'classnames';
import Tree, { useTreeState, treeHandlers } from 'react-hyper-tree';
import CircleCheckMarkIcon from '../Icons/CircleCheckMarkIcon';

type DataProps = {
  id: string;
  name: string;
  children: Array<{ [key: string]: any }>;
};
type HierarchyViewProps = {
  theme?: ThemeProp;
  data: DataProps[];
  renderNode?: ReactNode;
  feedersInScenario?: { [key: string]: string }[];
  tileRenderer?: (obj: any) => ReactChild;
  defaultOpened?: boolean;
};

const HierarchyView: FunctionComponent<HierarchyViewProps> = ({
  theme = 'light',
  data = [],
  feedersInScenario = [],
  tileRenderer,
  defaultOpened = true,
}) => {
  const { required, handlers } = useTreeState({
    id: 'tree',
    data,
    defaultOpened,
    multipleSelect: false,
  });
  const renderNode = useCallback(
    ({ node, onToggle }) => (
      <div
        className={classNames({ 'tree-node': true, 'node-disabled': node.data.disabled })}
        key={node.data.name}
      >
        <div
          role="button"
          onClick={!node.data.disabled ? onToggle : null}
          className={classNames({
            'tree-icon': true,
            'empty-icon': !node.hasChildren(),
            [node.options.opened ? 'close-icon' : 'open-icon']: node.hasChildren(),
          })}
          tabIndex={0}
          onKeyUp={() => {}}
          id="tree-icon"
        >
          close-icon
        </div>
        <div
          role="button"
          className={classNames({
            'node-content-wrapper': true,
            'node-selected': node.isSelected(),
          })}
          onClick={() =>
            !node.data.disabled
              ? treeHandlers.trees.tree.handlers.setSelected(node, !node.isSelected())
              : null
          }
          tabIndex={-1}
          onKeyUp={() => {}}
        >
          <div className="titles">
            <div className="node-title">
              {tileRenderer ? tileRenderer(node.data) : node.data.name}
            </div>
          </div>
          {feedersInScenario?.find(feeder => feeder.id === node.data.id) && (
            <div className="node-check">
              <CircleCheckMarkIcon />
            </div>
          )}
        </div>
      </div>
    ),
    [feedersInScenario, tileRenderer],
  );
  const lineStyles = {
    stroke: '#95A6B8',
    strokeDasharray: '0',
    strokeWidth: 1,
  };
  return (
    <div className={`hierarchy-tree-view ${theme}`}>
      <Tree
        {...required}
        {...handlers}
        horizontalLineStyles={lineStyles}
        verticalLineStyles={lineStyles}
        draggable={false}
        gapMode="padding"
        depthGap={20}
        disableLines={false}
        disableHorizontalLines={false}
        disableVerticalLines={false}
        verticalLineTopOffset={-12}
        verticalLineOffset={4}
        renderNode={renderNode}
        staticNodeHeight={35}
        disableTransitions
      />
    </div>
  );
};

export default HierarchyView;
