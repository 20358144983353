const chargerTypes = [
  { value: 'I', label: 'I' },
  { value: 'II', label: 'II' },
  { value: 'III', label: 'III' },
  { value: 'DC', label: 'DC' },
];

export default chargerTypes;

export const viewOptions = {
  import: { title: 'Import and validate', value: 'import' },
  scenario: { title: 'Scenarios and schedules', value: 'scenario' },
  gis: { title: 'GIS View', value: 'gis' },
  comparison: { title: 'Results Comparison', value: 'comparison' },
  library: { title: 'Equipment library', value: 'library' },
  cim: { title: 'CIM Data', value: 'cim' },
  overview: { title: 'Workspace Overview', value: 'overview' },
};
