import React from 'react';
import PropTypes from 'prop-types';
import PhaseSelectorCheckboxes from './PhaseSelectorCheckboxes';

const LinkDeviceHeader = ({
  asset: { phases, balanced },
  inEditMode,
  disabled,
  allowNeutral,
  handleSave,
}) => (
  <div className="header-container">
    <PhaseSelectorCheckboxes
      disabled={disabled || balanced || !inEditMode}
      onClick={p => {
        let newPhases = p;
        if (phases.includes(p)) {
          newPhases = phases.replace(p, '');
        } else {
          const phaseArray = phases.split('');
          phaseArray.push(p);
          phaseArray.sort();
          newPhases = phaseArray.join('');
        }
        handleSave({ balanced, phases: newPhases });
      }}
      phases={phases}
      allowNeutral={allowNeutral}
    />
  </div>
);

LinkDeviceHeader.defaultProps = {
  allowNeutral: false,
};

LinkDeviceHeader.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  allowNeutral: PropTypes.bool,
};

export default LinkDeviceHeader;
