import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withAuth from 'helpers/withAuth';
import { actions as networkActions } from '../modules/network';
import { networkEditActions } from '../modules/networkEdit';
import CreateDevicePanel from '../components/SlidingInfoPanel/CreateDevicePanel';

/*
  Object of action creators (can also be function that returns object).
  Keys will be passed as props to presentational components.
*/

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...networkActions,
      ...networkEditActions,
    },
    dispatch,
  );

const mapStateToProps = (state, ownProps) => ({
  // From global state
  theme: state.global.theme,

  // From network state
  workspace: state.network.workspace,
  branch: state.network.branch,
  lookup: state.network.lookup,
  selectedNode: state.network.selectedNode,
  selectedFeeders: state.feeders.selected,
  newAsset: state.edit.newAsset,
  newAssetFeeder: state.edit.newAssetFeeder,
  inEditMode: state.edit.inEditMode,
  ...ownProps,
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CreateDevicePanel));
