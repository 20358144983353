import React from 'react';
import PropTypes from 'prop-types';
import CustomerClassPanel from './CustomerClass';
import {
  CablePanel,
  ConcentricNeutralCablePanel,
  MultiConductorPanel,
  NewConductorPanel,
  OverheadConductorPanel,
  TapeShieldCablePanel,
} from './Conductors';
import WireGeometryPanel from './WireGeometry';
import SwitchPanel from './Switch';
import ThermalGeneratorPanel from './ThermalGenerator';
import TransformerPanel from './Transformer';
import InverterPanel from './Inverter';
import PhotoVoltaicUnitPanel from './PhotoVoltaicUnit';
import BatteryPanel from './Battery';
import EVChargingStationPanel from './ElectricVehicleChargingStation';
import CustomerProgramsPanel from './CustomerPrograms';
import CHPPanel from './CHPs';
import HydroGeneratingUnitPanel from './HydroGeneratingUnit';
import WindUnitPanel from './WindUnit';
import ShuntCompensatorInfoPanel from './ShuntCompensatorInfo';

const InfoInstancePanel = ({ type, ...baseProps }) => {
  // Return the correct panel as JSX for the given selected equipment
  switch (type) {
    case 'batteries':
      return <BatteryPanel {...baseProps} />;
    case 'capacitors':
      return <ShuntCompensatorInfoPanel {...baseProps} />;
    case 'conductors':
      switch (baseProps.selected?._class) {
        case 'OverheadWireInfo':
          return <OverheadConductorPanel {...baseProps} />;
        case 'ConcentricNeutralCableInfo':
          return <ConcentricNeutralCablePanel {...baseProps} />;
        case 'CableInfo':
          return <CablePanel {...baseProps} />;
        case 'TapeShieldCableInfo':
          return <TapeShieldCablePanel {...baseProps} />;
        case 'WireInfo':
          return <MultiConductorPanel {...baseProps} />;
        default:
          return <NewConductorPanel {...baseProps} />;
      }
    case 'customerTypes':
      return <CustomerClassPanel {...baseProps} />;
    case 'wireGeometries':
      return <WireGeometryPanel {...baseProps} />;
    case 'switches':
      return <SwitchPanel {...baseProps} />;
    case 'transformers':
      return <TransformerPanel {...baseProps} />;
    case 'thermalGenerators':
      return <ThermalGeneratorPanel {...baseProps} />;
    case 'inverters':
      return <InverterPanel {...baseProps} />;
    case 'photovoltaics':
      return <PhotoVoltaicUnitPanel {...baseProps} />;
    case 'evChargingStations':
      return <EVChargingStationPanel {...baseProps} />;
    case 'customerPrograms':
      return <CustomerProgramsPanel {...baseProps} />;
    case 'CHPs':
      return <CHPPanel {...baseProps} />;
    case 'hydroGeneratingUnits':
      return <HydroGeneratingUnitPanel {...baseProps} />;
    case 'winds':
      return <WindUnitPanel {...baseProps} />;
    default:
      return null;
  }
};

InfoInstancePanel.propTypes = {
  type: PropTypes.string.isRequired,
};

export default InfoInstancePanel;
