import Switch from './Switch';

class Recloser extends Switch {
  constructor(id, cimDict) {
    super(id, cimDict);
    this.icon = 'recloser';
    this._class = 'Recloser';
    this.displayName = this.class;
  }
}

export default Recloser;
