import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';

import { WorkspaceSettingsContext } from 'contexts/WorkspaceSettingsContext';
import NumberInput from 'components/NumberInput';
import TextInput from 'components/TextInput';
import asyncActionStates from 'helpers/asyncActionStates';

const NPVSettingsTab = ({ theme }) => {
  const [errMsgInvalidValues, setErrMsgInvalidValues] = useState('');
  const { settings, setSettings, settingsModified, saveSettings, loadingState } = useContext(
    WorkspaceSettingsContext,
  );

  useEffect(() => {
    if (loadingState === asyncActionStates.ERROR) {
      setErrMsgInvalidValues('There was an error getting the NPV settings');
    } else if (loadingState === asyncActionStates.SUCCESS) {
      setErrMsgInvalidValues('');
    }
  }, [loadingState]);

  const handleInputChange = ({ target }) =>
    setSettings({
      [target.id]: target.value,
    });

  const handleDiscountChange = ({ id, value }) =>
    setSettings({
      [id]: value,
    });

  const saveSettingValues = async () => {
    try {
      await saveSettings('npv');
      setErrMsgInvalidValues('');
    } catch (err) {
      const msg =
        err.response.status === 400 ? err.response.data.message : 'Failed to save changes';
      setErrMsgInvalidValues(msg);
    }
  };

  const invalidDiscount = () => {
    const validationResult = {
      invalid: false,
      validationMessage: '',
    };
    if (settings.discountRate === '') {
      validationResult.invalid = true;
      validationResult.validationMessage = 'Enter a number for a Discount Rate';
    } else if (settings.discountRate < 0) {
      validationResult.invalid = true;
      validationResult.validationMessage = 'Discount rate cannot be negative';
    }
    return validationResult;
  };

  const discountValidationResult = invalidDiscount();

  return (
    <div className="settings-tab">
      <div className="setting-container">
        <NumberInput
          id="discountRate"
          label="Discount Rate (%)"
          value={settings.discountRate}
          onChange={handleDiscountChange}
          theme={theme}
          invalid={discountValidationResult.invalid}
          validationMessage={discountValidationResult.validationMessage}
          inputWidth="196px"
        />
        <TextInput
          id="remainingValueEquation"
          label={
            <span>
              Remaining Value of Replaced Assets
              <sup>1</sup>
              &nbsp;=
            </span>
          }
          value={settings.remainingValueEquation}
          onChange={handleInputChange}
          theme={theme}
        />
        <div className="setting-text">
          <sup>1</sup>
          <span>The following variables can be used: asset_age, equipment_cost</span>
        </div>
        <div className="save-container">
          <Button
            disabled={
              discountValidationResult.invalid ||
              (!settingsModified.discountRate && !settingsModified.remainingValueEquation)
            }
            className="save-btn"
            onClick={saveSettingValues}
            label="Save"
            theme={theme}
          />
          {errMsgInvalidValues !== '' ? (
            <div className="error-text">{errMsgInvalidValues}</div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};

NPVSettingsTab.propTypes = {
  theme: PropTypes.string.isRequired,
};

export default NPVSettingsTab;
