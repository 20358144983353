import React, { FunctionComponent, useEffect } from 'react';
import { ThemeProp } from 'types/index';
import { W, Wh, VAr } from 'helpers/units';
import classNames from 'classnames';
import CustomScrollBar from 'components/CustomScrollBar';
import Select from 'components/Select';
import Button from 'components/Button';
import {
  updateLayerStorage,
  defaultLayerOptions,
} from 'routes/WorkspaceLayout/routes/Network/helpers/VisualizationHelpers';
import { useAuthContext } from '@opusonesolutions/gridos-app-framework';
import { BETA_PERMISSION } from 'helpers/permissions';
import LayerSelectionButton from './LayerSelectionButton';
import HostingCapacitySection from './HostingCapacitySection';
import OperationalEnvpSection from './OperationalEnvpSection';
import BatteryAnalsisSection from './BatteryAnalysisSection';
import VoltageSection from './VoltageSection';
import CurrentSection from './CurrentSection';
import ApparentPowerSection from './ApparentPowerSection';
import PowerSection from './PowerSection';
import PowerFactorSection from './PowerFactorSection';
import GenerationLoadSection from './GenerationLoadSection';

import './LayerPanel.scss';
import { ANALYSIS_TYPES } from '../../../helpers/NetworkHelpers';

// Create the panel to select which layer gets applied to GIS view
type LayerPanelProps = {
  selectedLayer?: string | null;
  expanded?: boolean;
  theme?: ThemeProp;
  selectedAnalysis: {
    [key: string]: string;
  } | null;
  layerOptions: { [key: string]: { [key: string]: any } };
  showThreePhase: boolean | null;
  selectedScenario: string;
  workspace: string;
  branch: string;
  displayBranch: string;
  inEditMode: boolean;
  setSelectedLayer: () => void;
  updateLayerOptions: (name: string, updates: { [key: string]: string }) => void;
  updateLayerAggregation: (val: string) => void;
  toggleDirectionalFlow: () => void;
  getLayerOptions: () => void;
};
const LayerPanel: FunctionComponent<LayerPanelProps> = ({
  selectedLayer = null,
  expanded = false,
  theme = 'light',
  selectedAnalysis,
  setSelectedLayer,
  layerOptions,
  updateLayerOptions,
  updateLayerAggregation,
  showThreePhase,
  toggleDirectionalFlow,
  selectedScenario,
  getLayerOptions,
  workspace,
  branch,
  displayBranch,
  inEditMode,
}) => {
  const { isAuthEnabled, permissions } = useAuthContext();
  useEffect(
    () => () => {
      if (selectedAnalysis && selectedScenario) {
        updateLayerStorage(
          inEditMode,
          branch,
          workspace,
          selectedScenario,
          selectedAnalysis?.id,
          layerOptions,
          displayBranch,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layerOptions, workspace, branch, selectedScenario, selectedAnalysis],
  );

  const getMinValue = (arr: string | boolean | []) =>
    Array.isArray(arr) ? arr[arr.length - 1] : 0;
  const optionList = [{ label: 'Per Feeder', value: 'per_feeder' }];
  if (!isAuthEnabled || permissions.has(BETA_PERMISSION)) {
    optionList.push({ label: 'Reduced Topology', value: 'topology' });
    optionList.push({ label: 'Phasing', value: 'phase' });
  }
  return (
    <div className="layer-panel">
      <div
        className={classNames({
          'layer-panel-container': true,
          'layer-panel-container--expanded': expanded,
        })}
      >
        <div className="layer-panel-container-header">
          <h3 className="layer-panel-container-header__h3">Visual Layers</h3>
          <Button
            type="text"
            onClick={() => {
              updateLayerStorage(
                inEditMode,
                branch,
                workspace,
                selectedScenario,
                selectedAnalysis?.id,
                defaultLayerOptions,
                displayBranch,
              );
              getLayerOptions();
            }}
            id="reset-options"
          >
            Reset Layer Options
          </Button>
        </div>
        {selectedAnalysis?.type !== ANALYSIS_TYPES.BATTERY_SIZING && (
          <div className="agg-options">
            <Select
              options={[
                { label: 'Average', value: 'avg' },
                { label: 'Min', value: 'min' },
                { label: 'Max', value: 'max' },
              ]}
              value={layerOptions.aggregation}
              onChange={({ value }) => updateLayerAggregation(value)}
              clearable={false}
              searchable={false}
              width={100}
            />
          </div>
        )}
      </div>
      <CustomScrollBar className="" alwaysShow={false}>
        {selectedAnalysis && [ANALYSIS_TYPES.POWERFLOW].includes(selectedAnalysis?.type) && (
          <GenerationLoadSection
            setSelectedLayer={setSelectedLayer}
            layerOptions={layerOptions.generation_load}
            updateLayerOptions={updateLayerOptions}
            selected={selectedLayer === 'generation_load'}
            expanded={expanded}
          />
        )}
        {selectedAnalysis &&
          [ANALYSIS_TYPES.POWERFLOW, ANALYSIS_TYPES.QSTS, ANALYSIS_TYPES.QSTS_OPF].includes(
            selectedAnalysis?.type,
          ) && (
            <>
              <VoltageSection
                setSelectedLayer={setSelectedLayer}
                layerOptions={layerOptions.voltage}
                updateLayerOptions={updateLayerOptions}
                selected={selectedLayer === 'voltage'}
                theme={theme}
                showThreePhase={showThreePhase}
                expanded={expanded}
              />
              <CurrentSection
                setSelectedLayer={setSelectedLayer}
                layerOptions={layerOptions.current}
                updateLayerOptions={updateLayerOptions}
                selected={selectedLayer === 'current'}
                theme={theme}
                showThreePhase={showThreePhase}
                expanded={expanded}
              />
              <ApparentPowerSection
                setSelectedLayer={setSelectedLayer}
                layerOptions={layerOptions.apparent_power}
                updateLayerOptions={updateLayerOptions}
                selected={selectedLayer === 'apparent_power'}
                theme={theme}
                showThreePhase={showThreePhase}
                expanded={expanded}
              />
              <PowerSection
                setSelectedLayer={setSelectedLayer}
                layerOptions={layerOptions.real_power}
                updateLayerOptions={updateLayerOptions}
                selected={selectedLayer === 'real_power'}
                layerID="real_power"
                layerName="Real Power"
                unit={W}
                theme={theme}
                showThreePhase={showThreePhase}
                min={getMinValue(layerOptions.real_power.rangeBreaks)}
                expanded={expanded}
                toggleDirectionalFlow={toggleDirectionalFlow}
              />
              <PowerSection
                setSelectedLayer={setSelectedLayer}
                layerOptions={layerOptions.reactive_power}
                updateLayerOptions={updateLayerOptions}
                selected={selectedLayer === 'reactive_power'}
                layerID="reactive_power"
                layerName="Reactive Power"
                unit={VAr}
                theme={theme}
                showThreePhase={showThreePhase}
                min={getMinValue(layerOptions.reactive_power.rangeBreaks)}
                expanded={expanded}
                toggleDirectionalFlow={toggleDirectionalFlow}
              />
              <PowerFactorSection
                setSelectedLayer={setSelectedLayer}
                layerOptions={layerOptions.power_factor}
                updateLayerOptions={updateLayerOptions}
                selected={selectedLayer === 'power_factor'}
                theme={theme}
                showThreePhase={showThreePhase}
                expanded={expanded}
              />
              <PowerSection
                setSelectedLayer={setSelectedLayer}
                layerOptions={layerOptions.real_power_losses}
                updateLayerOptions={updateLayerOptions}
                selected={selectedLayer === 'real_power_losses'}
                layerID="real_power_losses"
                layerName="Real Power Losses"
                unit={W}
                addSum
                theme={theme}
                showThreePhase={showThreePhase}
                min={0}
                expanded={expanded}
              />
            </>
          )}
        {selectedAnalysis?.type === ANALYSIS_TYPES.HOSTING_CAPACITY && (
          <HostingCapacitySection
            setSelectedLayer={setSelectedLayer}
            layerOptions={layerOptions.hosting_capacity}
            updateLayerOptions={(updates: { [key: string]: string }) =>
              updateLayerOptions('hosting_capacity', updates)
            }
            selected={selectedLayer === 'hosting_capacity'}
            theme={theme}
            expanded={expanded}
            layerName="Hosting Capacity"
            layerID="hosting_capacity"
          />
        )}
        {selectedAnalysis?.type === ANALYSIS_TYPES.EV_CAPACITY && (
          <HostingCapacitySection
            setSelectedLayer={setSelectedLayer}
            layerOptions={layerOptions.ev_capacity}
            updateLayerOptions={(updates: { [key: string]: string }) =>
              updateLayerOptions('ev_capacity', updates)
            }
            selected={selectedLayer === 'ev_capacity'}
            theme={theme}
            expanded={expanded}
            layerName="EV Capacity"
            layerID="ev_capacity"
          />
        )}
        {selectedAnalysis?.type === ANALYSIS_TYPES.OPERATIONAL_ENVELOPE && (
          <OperationalEnvpSection
            setSelectedLayer={setSelectedLayer}
            layerOptions={layerOptions.operational_envelope}
            updateLayerOptions={(updates: { [key: string]: any }) =>
              updateLayerOptions('operational_envelope', updates)
            }
            selected={selectedLayer === 'operational_envelope'}
            expanded={expanded}
          />
        )}
        {selectedAnalysis?.type === ANALYSIS_TYPES.BATTERY_SIZING && (
          <>
            <BatteryAnalsisSection
              setSelectedLayer={setSelectedLayer}
              layerOptions={layerOptions.battery_sizing_energy}
              updateLayerOptions={updates => updateLayerOptions('battery_sizing_energy', updates)}
              selected={selectedLayer === 'battery_sizing_energy'}
              expanded={expanded}
              layerName="Energy"
              layerID="battery_sizing_energy"
              unit={Wh}
            />
            <BatteryAnalsisSection
              setSelectedLayer={setSelectedLayer}
              layerOptions={layerOptions.battery_sizing_real_power}
              updateLayerOptions={updates =>
                updateLayerOptions('battery_sizing_real_power', updates)
              }
              selected={selectedLayer === 'battery_sizing_real_power'}
              expanded={expanded}
              layerName="Real Power"
              layerID="battery_sizing_real_power"
              unit={W}
            />
            <BatteryAnalsisSection
              setSelectedLayer={setSelectedLayer}
              layerOptions={layerOptions.battery_sizing_reactive_power}
              updateLayerOptions={updates =>
                updateLayerOptions('battery_sizing_reactive_power', updates)
              }
              selected={selectedLayer === 'battery_sizing_reactive_power'}
              expanded={expanded}
              layerName="Reactive Power"
              layerID="battery_sizing_reactive_power"
              unit={VAr}
            />
          </>
        )}
        {optionList.map(option => (
          <LayerSelectionButton
            key={option.value}
            selected={option.value === selectedLayer}
            option={option}
            setSelectedLayer={setSelectedLayer}
          />
        ))}
      </CustomScrollBar>
    </div>
  );
};

export default LayerPanel;
