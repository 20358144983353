import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { Request } from '@opusonesolutions/gridos-app-framework';
import asyncActionStates from 'helpers/asyncActionStates';
import { ScenarioTypes } from 'helpers/scenarios';
import ZoomableRangeSlider from 'components/ZoomableRangeSlider';
import { ZoomLevel } from 'components/ZoomableRangeSlider/intervals';
import { ThemeProp } from 'types/index';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';

import './NetworkBottomBar.scss';

type NetworkBottomBarProps = {
  workspaceName: string;
  branch: string;
  selectedFeeders: { id: string }[];
  timepoints: { results: [] };
  actions: {
    dismissPowerflowMessage: () => void;
    setTimeRange: (start: moment.Moment, end: moment.Moment) => void;
    setMaxRange: (
      timebarStart: moment.Moment,
      timebarEnd: moment.Moment,
      selectedStart?: moment.Moment,
      selectedEnd?: moment.Moment,
      zoomLevel?: ZoomLevel,
    ) => void;
  };
  powerflowReq: number;
  powerFlowErrorMessage: string;
  selectedScenario: string;
  selectedScenarioType: ScenarioTypes;
  selectedAnalysis: {
    id: string;
    name: string;
    type: string;
  } | null;
  maxRange: {
    start: moment.Moment | null;
    end: moment.Moment | null;
  };
  timeRange: {
    start: moment.Moment | null;
    end: moment.Moment | null;
  };
  timeBarZoomLevel: ZoomLevel;
  determiningTimeRange: boolean;
  theme: ThemeProp;
  timebarViolations: string[];
  subHourInterval: 5 | 15 | 30 | 60;
};

const NetworkBottomBar: FunctionComponent<NetworkBottomBarProps> = ({
  workspaceName,
  branch,
  selectedFeeders,
  selectedScenario,
  selectedScenarioType,
  selectedAnalysis,
  timebarViolations,
  maxRange,
  timeRange,
  timeBarZoomLevel,
  subHourInterval,
  determiningTimeRange,
  theme,
  powerflowReq,
  powerFlowErrorMessage,
  timepoints,
  actions,
}) => {
  const handleViewChanged = async (
    start: moment.Moment,
    end: moment.Moment,
    aggregation: ZoomLevel,
  ) => {
    const params = {
      feeder: selectedFeeders.map(x => x.id),
      start_date: start.toISOString(),
      end_date: end.toISOString(),
      aggregation,
    };
    const pfTimepointsRequest = new Request(
      `/api/workspace/${workspaceName}/branch/${branch}/power-flow-results/timepoints`,
    );
    const hcTimepointsRequest = new Request(
      `/api/workspace/${workspaceName}/branch/${branch}/hosting-capacity-results/timepoints`,
    );
    const scTimepointsRequest = new Request(
      `/api/workspace/${workspaceName}/branch/${branch}/qsts_scenarios/${selectedScenario}/timepoints`,
    );
    const evTimepointsRequest = new Request(
      `/api/workspace/${workspaceName}/branch/${branch}/hosting-capacity-results/timepoints`,
    );
    const timeBarViolations = new Request(
      `/api/workspace/${workspaceName}/branch/${branch}/power-flow-results/violations/per-time`,
    );
    let pfTimepoints: moment.Moment[] | null = null;
    let hcTimepoints: moment.Moment[] | null = null;
    let scTimepoints: moment.Moment[] | null = null;
    let evTimepoints: moment.Moment[] | null = null;
    let violationsInRange: string[] | null = timebarViolations;

    if (
      selectedAnalysis &&
      [ANALYSIS_TYPES.POWERFLOW, ANALYSIS_TYPES.QSTS, ANALYSIS_TYPES.QSTS_OPF].includes(
        selectedAnalysis.type,
      )
    ) {
      try {
        const pfResponse = await pfTimepointsRequest.get<string[]>({
          params: {
            ...params,
            scenario_id: selectedScenario,
            analysis_name: selectedAnalysis.name,
          },
        });
        pfTimepoints = pfResponse.data.map(timepoint => moment.utc(timepoint));
      } catch {
        // this is fine
      }
    }

    if (selectedAnalysis?.type === ANALYSIS_TYPES.HOSTING_CAPACITY) {
      try {
        const hcResponse = await hcTimepointsRequest.get<string[]>({
          params: {
            ...params,
            scenario_id: selectedScenario,
            analysis_name: selectedAnalysis.name,
            hc_type: 'generation',
          },
        });
        hcTimepoints = hcResponse.data.map(timepoint => moment.utc(timepoint));
      } catch {
        // this is fine
      }
    }

    if (selectedAnalysis?.type === ANALYSIS_TYPES.EV_CAPACITY) {
      try {
        const evResponse = await evTimepointsRequest.get<string[]>({
          params: {
            ...params,
            scenario_id: selectedScenario,
            analysis_name: selectedAnalysis.name,
            hc_type: 'load',
          },
        });
        evTimepoints = evResponse.data.map(timepoint => moment.utc(timepoint));
      } catch {
        // this is fine
      }
    }

    try {
      const scResponse = await scTimepointsRequest.get<string[]>({ params });
      scTimepoints = scResponse.data.map(timepoint => moment.utc(timepoint));
    } catch {
      // this is fine
    }

    if (selectedAnalysis) {
      try {
        const violationResponse = await timeBarViolations.get<string[]>({
          params: {
            ...params,
            scenario_id: selectedScenario,
            analysis_name: selectedAnalysis.name,
          },
        });
        violationsInRange = violationResponse.data;
      } catch {
        // this is fine
      }
    }

    return {
      powerflow: pfTimepoints,
      hostingCapacity: hcTimepoints,
      scenario: scTimepoints,
      evCapacity: evTimepoints,
      violations: violationsInRange,
    };
  };

  return (
    <div className="powerflow-slider-container">
      {maxRange.start && maxRange.end && timeRange.start && timeRange.end && (
        <div className="powerflow-slider">
          <ZoomableRangeSlider
            timebarStart={maxRange.start}
            timebarEnd={maxRange.end}
            selectedStart={timeRange.start}
            selectedEnd={timeRange.end}
            zoomLevel={timeBarZoomLevel}
            onTimebarChanged={actions.setMaxRange}
            onSelectedChanged={actions.setTimeRange}
            onViewChanged={handleViewChanged}
            theme={theme}
            violations={timebarViolations}
            showCalendarPicker={selectedScenarioType === ScenarioTypes.timeseries}
            selectedAnalysis={selectedAnalysis?.id ?? null}
            subHourInterval={subHourInterval}
          />
        </div>
      )}
      {determiningTimeRange && (
        <p className="time-range-msg">
          Determining Time Range... <i className="material-icons rotate">refresh</i>
        </p>
      )}
      {powerflowReq === asyncActionStates.ERROR && !powerFlowErrorMessage && (
        <p className="powerflow-error">
          <i className="material-icons powerflow-error__i">warning</i>
          An error has occured while running power flow. Please try again.
          <button
            className="overlay-button"
            onClick={actions.dismissPowerflowMessage}
            type="button"
          >
            {timepoints.results.length > 0 ? 'View Previous Results' : 'Dismiss'}
          </button>
        </p>
      )}
      {powerflowReq === asyncActionStates.ERROR && powerFlowErrorMessage && (
        <p className="powerflow-error">
          <i className="material-icons powerflow-error__i">warning</i>
          {powerFlowErrorMessage.replace(/'/g, '')}
          <button
            className="overlay-button"
            onClick={actions.dismissPowerflowMessage}
            type="button"
          >
            {timepoints.results.length > 0 ? 'View Previous Results' : 'Dismiss'}
          </button>
        </p>
      )}
    </div>
  );
};

export default NetworkBottomBar;
