import TextInput from 'components/TextInput';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ThemeContext from 'helpers/ThemeContext';

import PhaseSelectorComboBox from './PhaseSelectorComboBox';
import PhaseSelectorCheckboxes from './PhaseSelectorCheckboxes';

const ShuntDeviceHeader = ({
  asset: { phases, balanced },
  inEditMode,
  disabled,
  handleSave,
  phaseOptions,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className="header-container">
      <div className="panel-header-row">
        {phaseOptions === 'single' && (
          <>
            <div className="left-label">Phase</div>
            <PhaseSelectorComboBox
              disabled={disabled || !inEditMode}
              onChange={p => handleSave({ phases: p })}
              phase={phases}
            />
          </>
        )}
        {phaseOptions === 'many' && (
          <PhaseSelectorCheckboxes
            disabled={disabled || balanced || !inEditMode}
            onClick={p => {
              let newPhases = p;
              if (phases.includes(p)) {
                newPhases = phases.replace(p, '');
              } else {
                const phaseArray = phases.split('');
                phaseArray.push(p);
                phaseArray.sort();
                newPhases = phaseArray.join('');
              }
              handleSave({ balanced, phases: newPhases });
            }}
            phases={phases}
          />
        )}
        {phaseOptions === 'readOnly' && (
          <TextInput
            label="Phase"
            className="display-only"
            value={phases}
            id="phase"
            inputStyle="panel"
            theme={theme}
            disabled
          />
        )}
      </div>
    </div>
  );
};

ShuntDeviceHeader.defaultProps = {
  phaseOptions: 'single',
};

ShuntDeviceHeader.propTypes = {
  disabled: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  inEditMode: PropTypes.bool.isRequired,
  phaseOptions: PropTypes.oneOf(['single', 'many', 'readOnly']),
};

export default ShuntDeviceHeader;
