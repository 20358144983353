import asyncActionStates from 'helpers/asyncActionStates';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  BarChart,
  ReferenceArea,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  Tooltip as ChartTooltip,
  Legend,
} from 'recharts';
import classNames from 'classnames';
import { toISO } from 'helpers/utils';
import { IntlContext } from 'contexts/IntlContext';
import { getStartOfInterval } from 'components/ZoomableRangeSlider/intervals';
import ThemeContext from 'helpers/ThemeContext';
import './TimeSeriesChart.scss';
import ResultsChartCard from './ResultsChartCard';

const xAxisFormatter = aggregation => {
  switch (aggregation) {
    case 'day':
      return 'YYYY/MM/DD';
    case 'month':
      return 'YYYY/MM';
    case 'year':
      return 'YYYY';
    case 'hour':
    default:
      return 'HH:mm';
  }
};

const getFormatString = aggregation => {
  switch (aggregation) {
    case 'day':
      return 'YYYY/MM/DD';
    case 'month':
      return 'YYYY/MM';
    case 'year':
      return 'YYYY';
    case 'hour':
    default:
      return 'YYYY/MM/DD HH:mm';
  }
};

export const customTooltip = (tooltipInfo, formatter) => {
  const { payload, label } = tooltipInfo;
  const values = payload && payload[0] ? payload[0].payload : {};

  return (
    <div className="tooltip">
      <p>
        <b>{moment.parseZone(label).format(getFormatString(values.aggregation))}</b>
      </p>
      <table>
        <tbody>
          {payload.map(entry => (
            <tr key={entry.dataKey}>
              <td>{entry.name}</td>
              <td>{formatter.format(entry.value)}</td>
            </tr>
          ))}
          <tr>
            <td>Total</td>
            <td>{formatter.format(payload.reduce((sum, entry) => sum + entry.value, 0))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const customLegend = (legendProps, currencySymbol) => {
  const { payload } = legendProps;
  const legend = payload.map(entry => (
    <div className="legend-entry" key={entry.value}>
      <div
        style={{
          background: entry.color,
          width: '8px',
          height: '8px',
          marginRight: '5px',
        }}
      />
      <div style={{ marginRight: '10px' }}>{entry.value}</div>
    </div>
  ));
  return (
    <div className="legend" style={{ paddingBottom: '10px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <div>({currencySymbol})</div>
      </div>
      <div
        className="legend-group"
        style={{
          display: 'flex',
          whiteSpace: 'nowrap',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
        }}
      >
        {legend}
      </div>
    </div>
  );
};

const OperationCostChart = ({
  highlightRange,
  maxRange,
  expanded,
  costData,
  costDataRequestStatus,
  timeBarZoomLevel,
}) => {
  const { datapoints, error } = costData;
  const highlightStart = toISO(highlightRange.start);
  let highlightEnd;
  if (highlightRange.end !== maxRange) {
    highlightEnd = toISO(getStartOfInterval(highlightRange.end, timeBarZoomLevel));
  }

  const theme = useContext(ThemeContext);
  const { currencyFormatter, currencySymbol } = useContext(IntlContext);

  let cardState = 'initial';
  let message;
  if (costDataRequestStatus === asyncActionStates.LOADING) {
    cardState = 'loading';
  } else if (costDataRequestStatus === asyncActionStates.ERROR) {
    cardState = 'error';
    message = error;
  } else if (datapoints.length > 0) {
    cardState = 'loaded';
  }

  return (
    <ResultsChartCard
      title="Cost of Operation"
      className="time-series-chart"
      theme={theme}
      state={cardState}
      message={message}
    >
      <>
        <div
          className={classNames({
            'chart-pane': true,
            'chart-pane--expanded': expanded,
          })}
        >
          <ResponsiveContainer height="100%" width="100%">
            <BarChart syncId="energyTimeChart" data={datapoints}>
              <ChartTooltip
                content={tooltipInfo => customTooltip(tooltipInfo, currencyFormatter)}
                labelStyle={{ color: 'black' }}
                cursor={{ fill: 'transparent' }}
              />
              <Legend
                content={legendProps => customLegend(legendProps, currencySymbol)}
                verticalAlign="top"
                align="right"
              />
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#606060" />
              <XAxis
                dataKey="timepoint"
                stroke="#949899"
                tickFormatter={val =>
                  moment.parseZone(val).format(xAxisFormatter(datapoints[0].aggregation))
                }
              />
              <YAxis orientation="left" stroke="#949899" width={50} />
              <Bar dataKey="active_energy_cost" stackId="a" fill="#004586" name="Active Energy" />
              <Bar
                dataKey="reactive_energy_cost"
                stackId="a"
                fill="#FF420E"
                name="Reactive Energy"
              />
              <Bar dataKey="active_power_cost" stackId="a" fill="#FFD320" name="Active Power" />
              <Bar dataKey="reactive_power_cost" stackId="a" fill="#579D1C" name="Reactive Power" />
              <ReferenceArea
                x1={highlightStart}
                x2={highlightEnd}
                fillOpacity={0.3}
                fill="teal"
                ifOverflow="extendDomain"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </>
    </ResultsChartCard>
  );
};

OperationCostChart.propTypes = {
  highlightRange: PropTypes.object.isRequired,
  maxRange: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  costData: PropTypes.object.isRequired,
  costDataRequestStatus: PropTypes.number.isRequired,
  timeBarZoomLevel: PropTypes.string.isRequired,
};

export default OperationCostChart;
