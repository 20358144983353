import React, { FunctionComponent } from 'react';
import CIMReferenceListItem from './CIMReferenceListItem';
import { CimClassReference } from '../references';

type CimReferenceListProps = {
  followReferenceLink: (id: string) => void;
  references: CimClassReference[];
};

const CIMReferenceList: FunctionComponent<CimReferenceListProps> = ({
  references,
  followReferenceLink,
}) => (
  <div>
    {references.map(r => (
      <CIMReferenceListItem
        key={r.type}
        references={r.refs}
        property={r.type.split('.')[1]}
        followReferenceLink={followReferenceLink}
      />
    ))}
  </div>
);

export default CIMReferenceList;
