import AssetInfo from './AssetInfo';

class EnergyStorageUnitInfo extends AssetInfo {
  get minP() {
    return this.attributes['PowerElectronicsUnitInfo.minP'];
  }

  get maxP() {
    return this.attributes['PowerElectronicsUnitInfo.maxP'];
  }

  get minEnergy() {
    return this.attributes['EnergyStorageUnitInfo.minEnergy'];
  }

  get maxEnergy() {
    return this.attributes['EnergyStorageUnitInfo.maxEnergy'];
  }

  get ratedEnergy() {
    return this.attributes['EnergyStorageUnitInfo.ratedEnergy'];
  }

  get chargeEfficiency() {
    return this.attributes['EnergyStorageUnitInfo.chargeEfficiency'];
  }

  get dischargeEfficiency() {
    return this.attributes['EnergyStorageUnitInfo.dischargeEfficiency'];
  }
}

export default EnergyStorageUnitInfo;
