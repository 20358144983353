import React, { FunctionComponent, ReactElement } from 'react';
import Select from 'components/Select';
import { ScenarioTypes, Scenario } from 'helpers/scenarios';
import './DropdownSections.scss';
import { ThemeProp } from 'types';
import SelectBadgeItem from '../../../components/SelectBadgeItem';

type ScenarioSelectProps = {
  scenario: string | null;
  scenarios: Scenario[];
  theme: ThemeProp;
  className?: string;
  loading: boolean;
  loadingMessage?: string;
  onScenarioChange: (scenarioId: string | null, scenarioType: ScenarioTypes | null) => void;
  type?: 'default' | 'primary' | 'secondary' | 'text';
  label?: string | ReactElement;
  disableToSelectScenario?: boolean;
  handleMenuClose?: () => void;
  handleMenuOpen?: () => void;
  id?: string;
};
const ScenarioSelect: FunctionComponent<ScenarioSelectProps> = ({
  scenario,
  scenarios,
  theme,
  className = '',
  loading,
  loadingMessage = 'Loading...',
  onScenarioChange,
  type = 'default',
  label,
  disableToSelectScenario = false,
  handleMenuClose,
  handleMenuOpen,
  id,
}) => {
  const scenarioMap = scenarios.map(m => ({
    ...m,
    label: (
      <SelectBadgeItem
        item={m}
        badgeInfos={[
          {
            tooltip: m.type === ScenarioTypes.timeseries ? 'Timeseries' : 'Snapshot',
            key: m.value,
            label: m.type === ScenarioTypes.timeseries ? 'T' : 'S',
          },
        ]}
      />
    ),
  }));
  return (
    <Select
      className={className}
      theme={theme}
      options={scenarioMap}
      value={scenario}
      onChange={e => onScenarioChange(e?.value ?? null, e?.type ?? null)}
      clearable
      searchable={false}
      disabled={loading || disableToSelectScenario}
      placeholder={loading ? loadingMessage : undefined}
      type={type}
      label={label}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      id={id}
    />
  );
};

export default ScenarioSelect;
