/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select';
import './RadioButtonSelectGroup.scss';

const RadioButtonSelectGroup = props => (
  <div
    className={`radio-btn-select-group ${props.theme} radio-btn-select-group--${props.listType}`}
  >
    {props.options.map(option => (
      <div className="option-group" key={option.id}>
        <input
          className="radio-btn-input"
          type="radio"
          value={option.id}
          id={`${props.id}-${option.id}`}
          name={props.id}
          onChange={e => props.onChange(e.target.value)}
          disabled={option.disabled}
          checked={props.value === option.id}
        />
        <label htmlFor={`${props.id}-${option.id}`}>
          <div className="option-icon-group">
            <div className={`radio-btn ${props.value === option.id ? 'radio-btn--selected' : ''}`}>
              <div className="radio-btn-fill" />
            </div>
            <span>{option.label}</span>
          </div>
        </label>
        {option.selectOptions && option.selectOptions.length > 0 && (
          <Select
            joinValues
            name="select"
            className="radio-select"
            value={option.selectValue}
            onChange={e => props.onSelectChange(option.id, e.value)}
            options={option.selectOptions}
            disabled={props.value !== option.id}
            clearable={false}
            searchable={false}
            theme={props.theme}
          />
        )}
      </div>
    ))}
  </div>
);

RadioButtonSelectGroup.defaultProps = {
  theme: 'light',
  listType: 'row',
};

RadioButtonSelectGroup.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  theme: PropTypes.string,
  listType: PropTypes.oneOf(['row', 'column']),
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
};

export default RadioButtonSelectGroup;
