import WireInfo from './WireInfo';

class CableInfo extends WireInfo {
  get constructionKind() {
    return this.attributes['CableInfo.constructionKind'];
  }

  get diameterOverCore() {
    return this.attributes['CableInfo.diameterOverCore'];
  }

  get diameterOverInsulation() {
    return this.attributes['CableInfo.diameterOverInsulation'];
  }

  get diameterOverJacket() {
    return this.attributes['CableInfo.diameterOverJacket'];
  }

  get diameterOverScreen() {
    return this.attributes['CableInfo.diameterOverScreen'];
  }

  get isStrandFill() {
    return this.attributes['CableInfo.isStrandFill'];
  }

  get nominalTemperature() {
    return this.attributes['CableInfo.nominalTemperature'];
  }

  get outerJacketKind() {
    return this.attributes['CableInfo.outerJacketKind'];
  }

  get sheathAsNeutral() {
    return this.attributes['CableInfo.sheathAsNeutral'];
  }

  get shieldMaterial() {
    return this.attributes['CableInfo.shieldMaterial'];
  }
}

export default CableInfo;
